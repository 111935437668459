import React from 'react';
import styled from 'styled-components';

import noResultsImg from '../../../assets/images/no-results.png';
import emptySearchImg from '../../../assets/images/empty-search.png';
import noNotesImage from '../../../assets/images/no-notes.png';

import * as palette from '../../../components/General/Variables';
import { getString } from '../../../services/api/store';

const EmptyState = styled.div`
    text-align: center;
    padding-top: 50px;
    border-top: 1px solid #e5e5e5;
    width: 100%;
    height: 100%;
    h4 {
        font-size: 18px;
        font-weight: 500;
        color: ${palette.COLOR_TEXT};
    }
    p {
        font-size: 14px;
        color: ${palette.COLOR_TEXT};
    }
`;

const PlaceholderImage = styled.div`
    background-image: url(${props => props.image});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 500px;
`;

const NO_RESULTS_TITLE_DEFAULT = 'No results found';

/**
 * @summary Returns a component with text and image for empty state on the search page
 * @param type - string - could be emptySearch or noResults
 * @param introText - string - comes from Search page intro set in the Configurator
 * @returns React component
 */
const Placeholder = ({ type, introText }) => {
    const NO_RESULTS_TITLE = getString('searchNoResults') || NO_RESULTS_TITLE_DEFAULT;
    const EMPTY_SEARCH_TITLE_DEFAULT =
        getString('searchPageCallToAction') ||
        `Search for (part of) the name of a session, person, topic, company or anything else in the event programme.`;
    const EMPTY_SEARCH_TITLE = introText || EMPTY_SEARCH_TITLE_DEFAULT;

    let placeHolderImage = type === 'emptySearch' ? emptySearchImg : noResultsImg;
    let placeholderTitle =
        type === 'emptySearch' ? EMPTY_SEARCH_TITLE : introText || NO_RESULTS_TITLE;
    if (type === 'emptyList') {
        placeHolderImage = noNotesImage;
        placeholderTitle = EMPTY_SEARCH_TITLE;
    }

    return (
        <EmptyState>
            <h4>{placeholderTitle}</h4>
            <PlaceholderImage image={placeHolderImage} />
        </EmptyState>
    );
};

export default Placeholder;
