import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as palette from '../../components/General/Variables.js';
import ThemeContext from '../../components/Theme/ThemeContext';
import { Button, FontIcon } from 'react-md';
import { getString } from '../../services/api/store';

const HeaderBar = styled.div`
    height: 48px;
    width: 100%;
    background-color: ${props => props.background || props.secondary};
    font-size: 16px;
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)};
    line-height: 48px;
    text-align: center;
    ${props => props.background && 'border-radius: 12px'};
`;
const HeaderBarTitle = styled.div`
    display: inline-block;
    font-weight: 400;
    padding-left: ${props => (props.hasBackButton ? '0px' : '48px')};
    max-width: 80%;
    vertical-align: middle;
    height: 48px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)};
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    font-weight: 600;
`;
const HeaderBarRight = styled.div`
    display: inline-block;
    float: right;
    margin-right: 5px;
    height: 48px;
    vertical-align: middle;
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)} !important;
`;
const HeaderBarBack = styled.div`
    display: inline-block;
    float: left;
    margin-left: 5px;
    height: 48px;
    vertical-align: middle;
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)} !important;
`;

const Icon = styled(FontIcon)`
    color: ${props => (props.background ? 'rgba(0, 0, 0, 0.87)' : palette.COLOR_WHITE)} !important;
    vertical-align: top;
    font-size: 20px;
`;
const ButtonStyled = styled(Button)`
    margin-top: 4px;
    margin-bottom: 4px;
    height: 40px !important;
    width: 40px !important;
    padding: 10px !important;
    vertical-align: middle;
`;

class NavigationBar extends React.Component {
    render() {
        const props = this.props;
        let title = props.typeName;
        if (props.typeName === 'Profile') {
            title = getString('profileTitle') || props.typeName;
        }
        return (
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <HeaderBar background={props.background} secondary={theme.primary}>
                        {props.goBack && (
                            <HeaderBarBack background={props.background}>
                                <ButtonStyled
                                    icon
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (typeof props.goBack === 'function') {
                                            props.goBack();
                                        }
                                    }}
                                >
                                    <Icon background={props.background}>{palette.ICON_BACK}</Icon>
                                </ButtonStyled>
                            </HeaderBarBack>
                        )}
                        <HeaderBarTitle hasBackButton={props.goBack} background={props.background}>
                            {title}{' '}
                        </HeaderBarTitle>
                        {(typeof props.onClose === 'function' || props.closePath) && (
                            <HeaderBarRight background={props.background}>
                                {props.closePath ? (
                                    <Link to={props.closePath}>
                                        <ButtonStyled icon>
                                            <Icon background={props.background}>
                                                {palette.ICON_CLOSE}
                                            </Icon>
                                        </ButtonStyled>
                                    </Link>
                                ) : (
                                    <ButtonStyled
                                        icon
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (typeof props.onClose === 'function') {
                                                props.onClose();
                                            }
                                        }}
                                        dat-qa={props.closeDataQa}
                                    >
                                        <Icon background={props.background}>
                                            {palette.ICON_CLOSE}
                                        </Icon>
                                    </ButtonStyled>
                                )}
                            </HeaderBarRight>
                        )}
                        <HeaderBarRight background={props.background}>
                            {props.customRightButton}
                        </HeaderBarRight>
                    </HeaderBar>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default NavigationBar;
