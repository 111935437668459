import React from 'react';
import { getTabsElements } from './services/tabsPage';
import Loader from '../../components/General/Loader.js';
import { NavLink } from 'react-router-dom';
import PageSelector from '../../components/PageSelector';
import styled from 'styled-components';
import ThemeContext from '../../components/Theme/ThemeContext';
import queryString from 'query-string';
import {
    TabElement,
    TabsContainerStyled,
    TabsWrapper,
    ToolbarContainer,
} from '../Programme/style/style';

const StyledLink = styled(NavLink)`
    font-weight: 500;
`;
const StyledLinkNotNav = styled.div`
    font-weight: 500;
`;

class TabsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabsData: [],
            selectedTab: 0,
            loading: true,
            closeLinkPath: props.closeLink ? props.closeLink : '/programme',
            pageId: this.props.pageId,
            childrenId: this.props.childrenId ? this.props.childrenId : '',
            childrenType: '',
            initialItem: 0,
        };
        this.props.setTitle();
    }

    getQueryParamsTab = () => {
        const parsed = queryString.parse(this.props.location.search);
        const activeTab = parseInt(parsed.tab);

        return activeTab;
    };

    getTabsInfo = (isInitial = false) => {
        getTabsElements(this.state.pageId, (err, result) => {
            if (err) {
                console.log(err);
            } else {
                const activeTab = this.getQueryParamsTab();
                const initialItem = isInitial
                    ? activeTab || result.initialItem
                    : result.initialItem;
                let childrenId = this.props.childrenId;
                let childrenType = this.props.childrenType;
                let selectedTab = 0;

                if (
                    !this.props.childrenId &&
                    result.items.length &&
                    result.items[initialItem] &&
                    result.items[initialItem].page
                ) {
                    childrenId = result.items[initialItem].page;
                    childrenType = result.items[initialItem].screen;
                    selectedTab = parseInt(initialItem, 10);
                }

                this.setState({
                    tabsData: result.items,
                    selectedTab: selectedTab,
                    loading: false,
                    childrenId: childrenId,
                    childrenType: childrenType,
                    initialItem: parseInt(initialItem, 10),
                });

                if (this.props.updateTitle) {
                    this.props.updateTitle(result.title);
                }
            }
        });
    };

    componentDidMount() {
        this.getTabsInfo(true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.pageId !== prevState.pageId) {
            this.getTabsInfo();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.pageId !== prevState.pageId ||
            nextProps.closeLink !== prevState.closeLink ||
            nextProps.childrenId !== prevState.childrenId
        ) {
            let stateObj = {
                pageId: nextProps.pageId,
                closeLinkPath: nextProps.closeLink ? nextProps.closeLink : '/programme',
            };
            if (nextProps.childrenId && nextProps.detailPage) {
                let childrenType = prevState.childrenType;
                let selectedTab = prevState.selectedTab;

                if (prevState.tabsData.length && nextProps.childrenId) {
                    prevState.tabsData.forEach(function (item, index) {
                        if (item.page === nextProps.childrenId) {
                            childrenType = item.screen;
                            selectedTab = index;
                        }
                    });
                }
                stateObj.childrenType = childrenType;
                stateObj.selectedTab = selectedTab;
                stateObj.childrenId = nextProps.childrenId;
            } else if (
                prevState.tabsData.length &&
                prevState.tabsData[prevState.initialItem] &&
                prevState.tabsData[prevState.initialItem].page &&
                nextProps.detailPage
            ) {
                stateObj.childrenId = prevState.tabsData[prevState.initialItem].page;
                stateObj.childrenType = prevState.tabsData[prevState.initialItem].screen;
                stateObj.selectedTab = prevState.initialItem;
            }
            return stateObj;
        } else {
            return null;
        }
    }

    tabChanged = newActiveTabIndex => {
        if (!this.props.detailPage) {
            let childrenId = this.state.tabsData[newActiveTabIndex].page;
            let childrenType = this.state.tabsData[newActiveTabIndex].screen;
            this.setState({
                childrenId: childrenId,
                childrenType: childrenType,
                selectedTab: newActiveTabIndex,
            });
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: `?tab=${newActiveTabIndex}`,
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loader />}
                {!this.state.loading && (
                    <ThemeContext.Consumer>
                        {({ theme }) => (
                            <React.Fragment>
                                <ToolbarContainer>
                                    <TabsContainerStyled
                                        activeTabIndex={this.state.selectedTab}
                                        panelClassName="md-grid"
                                        onTabChange={this.tabChanged}
                                        colored
                                    >
                                        <TabsWrapper
                                            background={theme.primary}
                                            tabId="simple-tab"
                                            mobile
                                            alignToKeyline={false}
                                        >
                                            {this.state.tabsData.map((tab, i) => {
                                                let tabLink =
                                                    this.props.rootRoute +
                                                    '/page/tabs/' +
                                                    this.state.pageId +
                                                    '/' +
                                                    tab.page;
                                                let componentTab = StyledLink;
                                                const activeTabIndex = this.state.selectedTab;

                                                if (!this.props.detailPage) {
                                                    tabLink = null;
                                                    componentTab = StyledLinkNotNav;
                                                }
                                                return (
                                                    <TabElement
                                                        key={i}
                                                        to={tabLink}
                                                        component={componentTab}
                                                        label={tab.commonTitle || tab.name}
                                                        primary={theme.primary}
                                                        active={activeTabIndex === i}
                                                    />
                                                );
                                            })}
                                        </TabsWrapper>
                                    </TabsContainerStyled>
                                </ToolbarContainer>
                                <PageSelector
                                    fromTabs
                                    matchUrl={this.state.closeLinkPath}
                                    rootRoute={this.props.rootRoute}
                                    pageId={this.state.childrenId}
                                    pageType={this.state.childrenType}
                                />
                            </React.Fragment>
                        )}
                    </ThemeContext.Consumer>
                )}
            </React.Fragment>
        );
    }
}

export default TabsPage;
