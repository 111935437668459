import SocialLinksModal from '../../containers/profile/SocialLinksModal';
import ContactInfoModal from '../../containers/profile/ContactInfoModal';
import React from 'react';

export const ProfileContent = ({
    isDesktop,
    showSocialLinksModal,
    theme,
    fieldValues,
    setFieldValues,
    setShowSocialLinksModal,
    onChange,
    renderForm,
    closeIcon,
    setCloseIcon,
    editedLinks,
    setEditedLinks,
    showContactInfoModal,
    setShowContactInfoModal,
    countries,
    onSelectChange,
    afterSignUp,
    suggestedContactEmail,
}) => (
    <React.Fragment>
        {showSocialLinksModal && (
            <SocialLinksModal
                theme={theme}
                fieldValues={fieldValues}
                setFieldValues={setFieldValues}
                onClose={() => setShowSocialLinksModal(false)}
                onChange={onChange}
                closeIcon={closeIcon}
                setCloseIcon={setCloseIcon}
                editedLinks={editedLinks}
                setEditedLinks={setEditedLinks}
                afterSignUp={afterSignUp}
            />
        )}
        {showContactInfoModal && (
            <ContactInfoModal
                theme={theme}
                fieldValues={fieldValues}
                setFieldValues={setFieldValues}
                onClose={() => setShowContactInfoModal(false)}
                onChange={onChange}
                closeIcon={closeIcon}
                setCloseIcon={setCloseIcon}
                onSelectChange={onSelectChange}
                countries={countries}
                afterSignUp={afterSignUp}
                suggestedContactEmail={suggestedContactEmail}
            />
        )}
        {!showSocialLinksModal && !showContactInfoModal && renderForm(theme, isDesktop)}
    </React.Fragment>
);
