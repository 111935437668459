import React from 'react';

import {
    ContainerRightRoundTable,
    RoundTableSecondaryScreen,
    RoundTableVideoWrapper,
} from '../../../styles';
import { useGlobalMutation } from '../../../../../utils/container';
import RoundTableVideo from '../RoundTableVideo';
import { goBackFromVirtualSession } from '../../common/goBack';
import * as palette from '../../../../../components/General/Variables';

const SideContent = props => {
    const mutationCtx = useGlobalMutation();

    const { timeslot, match } = props;
    const { timeslotId } = match.params;

    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();

    const goBackFunction = () => goBackFromVirtualSession(props.history, timeslotId);

    const goBackWithCapacity = async () => {
        await goBackFunction();
        mutationCtx.setShowCapacityModal(true);
    };

    return (
        <ContainerRightRoundTable size={4} tabletSize={4} mobile={mobile}>
            <RoundTableSecondaryScreen
                className="secondary"
                size={12}
                tabletSize={12}
                mobile={mobile}
            >
                <RoundTableVideoWrapper>
                    <RoundTableVideo
                        goBackWithCapacity={goBackWithCapacity}
                        timeslot={timeslot}
                        timeslotId={timeslotId}
                    />
                </RoundTableVideoWrapper>
            </RoundTableSecondaryScreen>
        </ContainerRightRoundTable>
    );
};

export default SideContent;
