import React, { useState } from 'react';

import * as StyledComponent from '../../style/modalsStyle';
import { Button } from 'react-md';
import * as palette from '../../../../components/General/Variables';
import { StyledForm } from '../../../../components/SignIn/styles';
import { getString } from '../../../../services/api/store';
import { CONTACT_INFO_TITLE } from '../../constants';
import ComplexDialog from '../../../../components/Dialog/ComplexDialog';
import { Flex } from '../../../common/Flex';
import { FormWrapper } from '../../style/modalsStyle';

const ContactInfoModal = ({
    theme,
    fieldValues,
    suggestedContactEmail,
    onClose,
    closeIcon,
    setCloseIcon,
    setFieldValues,
    onSelectChange,
    countries,
    afterSignUp,
}) => {
    const [fieldValuesContact, setFieldValuesContact] = useState(fieldValues);
    const [touched, setTouched] = useState(false);
    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const onChange = (value, event) => {
        setTouched(true);
        const { name } = event.target;

        setFieldValuesContact({
            ...fieldValuesContact,
            [name]: value,
        });
    };

    const onSave = () => {
        const fieldValuesContactCopy = { ...fieldValuesContact };

        if (!touched && suggestedContactEmail && !fieldValuesContactCopy.email) {
            fieldValuesContactCopy.email = suggestedContactEmail;
        }

        setFieldValues(fieldValuesContactCopy);
        onClose();
    };

    return (
        <ComplexDialog
            title={
                <Flex>
                    <Button
                        style={{
                            color: palette.COLOR_TEXT,
                            marginTop: -10,
                        }}
                        icon
                        onClick={onClose}
                    >
                        arrow_back
                    </Button>
                    {CONTACT_INFO_TITLE}
                </Flex>
            }
            visible={true}
            contentStyle={{ padding: 0 }}
            onClose={null}
            displayBoxShadow
            options={[
                {
                    title: getString('saveButton') || 'Save',
                    variant: 'text',
                    type: 'save',
                    onClick: onSave,
                },
            ]}
        >
            <StyledForm
                maincolor={theme.primary}
                horizontalMargin={isDesktop && '0px'}
                minHeight="100px"
            >
                <FormWrapper>
                    <StyledComponent.StyledTextField
                        id="profile-email"
                        name="email"
                        label={getString('emailHintText') || 'Email'}
                        value={
                            suggestedContactEmail && !touched && !fieldValuesContact.email
                                ? suggestedContactEmail
                                : fieldValuesContact.email
                        }
                        onChange={onChange}
                        afterSignUp={afterSignUp}
                        leftIcon={
                            <StyledComponent.LinkIcon>{palette.ICON_MAIL}</StyledComponent.LinkIcon>
                        }
                        onFocus={() => setTimeout(() => setCloseIcon('email'), 100)}
                        onBlur={() => setTimeout(() => setCloseIcon(null), 100)}
                        rightIcon={
                            closeIcon === 'email' && (
                                <StyledComponent.LinkIcon
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFieldValuesContact({
                                            ...fieldValuesContact,
                                            ['email']: '',
                                        });
                                    }}
                                >
                                    {palette.ICON_CANCEL}
                                </StyledComponent.LinkIcon>
                            )
                        }
                    />
                    <StyledComponent.StyledTextField
                        id="profile-phone"
                        name="phone"
                        label={getString('profileContactPhone') || 'Phone number'}
                        value={fieldValuesContact.phone}
                        onChange={onChange}
                        afterSignUp={afterSignUp}
                        type="tel"
                        leftIcon={
                            <StyledComponent.LinkIcon>
                                {palette.ICON_PHONE_OUTLINE}
                            </StyledComponent.LinkIcon>
                        }
                        onFocus={() => setTimeout(() => setCloseIcon('phone'), 100)}
                        onBlur={() => setTimeout(() => setCloseIcon(null), 100)}
                        rightIcon={
                            closeIcon === 'phone' && (
                                <StyledComponent.LinkIcon
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFieldValuesContact({
                                            ...fieldValuesContact,
                                            ['phone']: '',
                                        });
                                    }}
                                >
                                    {palette.ICON_CANCEL}
                                </StyledComponent.LinkIcon>
                            )
                        }
                    />
                    <StyledComponent.SectionText>Address</StyledComponent.SectionText>
                    <StyledComponent.FlexContainerWithTwoChildren flex1={3} flex2={1}>
                        <StyledComponent.StyledTextField
                            id="profile-streetName"
                            name="streetName"
                            label={getString('profileContactAddressStreetName') || 'Street'}
                            value={fieldValuesContact.streetName}
                            onChange={onChange}
                            afterSignUp={afterSignUp}
                        />
                        <StyledComponent.StyledTextField
                            id="profile-streetNumber"
                            name="streetNumber"
                            label={getString('profileContactAddressStreetNumber') || 'Number'}
                            value={fieldValuesContact.streetNumber}
                            onChange={onChange}
                            afterSignUp={afterSignUp}
                        />
                    </StyledComponent.FlexContainerWithTwoChildren>
                    <StyledComponent.FlexContainerWithTwoChildren flex1={1} flex2={1}>
                        <StyledComponent.StyledTextField
                            id="profile-zip"
                            name="zip"
                            label={getString('profileContactAddressZip') || 'Zip code'}
                            value={fieldValuesContact.zip}
                            onChange={onChange}
                            afterSignUp={afterSignUp}
                        />
                        <StyledComponent.StyledTextField
                            id="profile-city"
                            name="city"
                            label={getString('profileContactAddressCity') || 'City/State'}
                            value={fieldValuesContact.city}
                            onChange={onChange}
                            afterSignUp={afterSignUp}
                        />
                    </StyledComponent.FlexContainerWithTwoChildren>
                    <StyledComponent.StyledSelectField
                        id="profile-country"
                        name="country"
                        label={getString('profileCountry') || 'Country'}
                        menuItems={countries}
                        value={fieldValuesContact.country}
                        onChange={onSelectChange}
                        fullWidth
                        afterSignUp={afterSignUp}
                    />
                </FormWrapper>
            </StyledForm>
        </ComplexDialog>
    );
};

export default ContactInfoModal;
