import React from 'react';

import sortBy from 'lodash/sortBy';
import { Link, withRouter } from 'react-router-dom';
import { List } from 'react-md';
import styled from 'styled-components';
import TimeslotListItem from '../../../components/ObjectListItem/Timeslot';
import { formatDate, getDateWithLocale, getFormatTime } from '../../../services/api/data';
import moment from 'moment';
import { getString } from '../../../services/api/store';
import flatten from 'lodash/flatten';
import { getImageSource } from '../../../services/api/db';

const LinkStyled = styled(Link)`
    text-decoration: none !important;
    display: block;
    margin: 0 8px;
    border-radius: 4px;
    ${props => props.selectedItem && 'background: #EBEDF0 !important'};
`;

const ListStyled = styled(List)`
    width: 100%;
    height: calc(100vh - 140px);
    overflow: scroll;
    max-width: ${props => window.innerWidth}px;
`;

const isNumber = string => !isNaN(string);

const sortStringsFn = (left, right) => {
    const leftValue = left ? left.toString() : '-';
    const rightValue = right ? right.toString() : '-';
    return leftValue.localeCompare(rightValue);
};

const ListView = props => {
    const propsItems = [];
    props.items.map(i => propsItems.push(i.items));
    let displayItems = (propsItems && flatten(propsItems)) || [];
    const baseURL = props.matchUrl;
    const timeArray = [];

    if (props.groupingOption === 'byClassifier') {
        displayItems = sortBy(displayItems, ['start']);
    }

    const sortByOrderingName = (firstItem, secondItem) => {
        const itemsHaveOrderingName = !!firstItem.orderingName && !!secondItem.orderingName;
        const itemsHaveSameStartDateTime =
            firstItem.dateTime &&
            secondItem.dateTime &&
            firstItem.dateTime.startDateToDisplay === secondItem.dateTime.startDateToDisplay &&
            firstItem.dateTime.startTimeToDisplay === secondItem.dateTime.startTimeToDisplay;
        const itemsHaveSameStartTime =
            firstItem.start && secondItem.start && firstItem.start === secondItem.start;

        if (itemsHaveOrderingName && (itemsHaveSameStartDateTime || itemsHaveSameStartTime)) {
            if (isNumber(firstItem.orderingName) && isNumber(secondItem.orderingName)) {
                return parseInt(firstItem.orderingName) - parseInt(secondItem.orderingName);
            }
            return sortStringsFn(firstItem.orderingName, secondItem.orderingName);
        }

        return 0;
    };

    let listItems = displayItems.sort(sortByOrderingName).map((item, i) => {
        item.time = formatDate(item);

        let start = moment.utc(item.start);
        if (!start.isValid()) {
            start = moment.unix(item.start);
        }
        const timeFormat = getFormatTime();
        const timetoDisplay = moment(start).format(timeFormat);
        const dateSettings = getString('datetime');
        const datePattern =
            dateSettings && dateSettings.dayMonthDateFormat
                ? dateSettings.dayMonthDateFormat
                : 'MMMM D';
        const day = getDateWithLocale(start);
        const dateToDisplay = day.format(datePattern);

        item.dateTime = {
            startTimeToDisplay: timetoDisplay,
            startDateToDisplay: dateToDisplay,
        };
        timeArray.push({ dateTime: item.dateTime });

        if (item && item.params && item.params.sponsorLogo) {
            getImageSource(item.params.sponsorLogo, (err, img) => {
                item.sponsorLogo = img;
            });
        }
        const objectClass = item.type === 'appointment' ? 'appointment' : 'timeslot';

        return (
            <LinkStyled
                key={'link_' + i}
                to={`${baseURL}/${objectClass}/${item.id}`}
                selectedItem={props.location.pathname.includes(item.id)}
            >
                <TimeslotListItem timeslot={item} />
            </LinkStyled>
        );
    });

    return <ListStyled id={'timetable-scrollable-container-list'}>{listItems}</ListStyled>;
};

export default withRouter(ListView);
