import React from 'react';
import { FontIcon } from 'react-md';
import styled from 'styled-components';

import { useTheme } from '../../../components/Theme/ThemeContext';
import Button from '../../common/Button';

const Wrapper = styled.div`
    display: flex;
    padding: 16px 8px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
        background-color: #f5f5f5;
    }
`;

const ImageContainer = styled.div`
    margin-right: 16px;
`;

const ImageWrapper = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #b3b3b3;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

const StyledFontIcon = styled(FontIcon)`
    font-size: 24px !important;
    color: #ffffff !important;
`;

const Content = styled.div`
    margin: auto auto auto 0;
`;

const PrimaryText = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
`;

const SecondaryText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TimeText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.54);
`;

const BadgeWrapper = styled.div`
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Badge = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props => props.color};
`;

const ButtonsWrapper = styled.div`
    display: flex;
    margin: 8px 0;

    & > *:not(:last-child) {
        margin-right: 8px;
    }
`;

const NotificationItem = ({
    primaryText,
    secondaryText,
    timeText,
    imageUrl,
    icon,
    badge,
    onClick,
    buttons = [],
}) => {
    const { theme } = useTheme();

    const imageOrIcon = imageUrl ? (
        <ImageWrapper>
            <Image src={imageUrl} alt="Notification" />
        </ImageWrapper>
    ) : (
        <ImageWrapper>
            <StyledFontIcon iconClassName="material-icons-outlined">{icon}</StyledFontIcon>
        </ImageWrapper>
    );

    return (
        <Wrapper onClick={onClick}>
            <ImageContainer>{imageOrIcon}</ImageContainer>
            <Content>
                <PrimaryText>{primaryText}</PrimaryText>
                <SecondaryText>{secondaryText}</SecondaryText>
                <TimeText>{timeText}</TimeText>
                {buttons.length > 0 && (
                    <ButtonsWrapper>
                        {buttons.map((button, index) => (
                            <Button
                                key={`notification-item-button-${index}`}
                                type={button.type}
                                text={button.text}
                                onClick={button.onClick}
                                background={button.background}
                            />
                        ))}
                    </ButtonsWrapper>
                )}
            </Content>
            {badge && (
                <BadgeWrapper>
                    <Badge color={theme.contrast} />
                </BadgeWrapper>
            )}
        </Wrapper>
    );
};

export default NotificationItem;
