import { each } from 'async';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';
import {
    executeListQuery,
    executeQueryWithCallback,
    getItem,
} from '../../../services/api/graphQlRepository';

const getSearchPage = function (pageId, next) {
    getItem('pages', pageId, (err, page) => {
        if (page) {
            next(null, page);
        } else {
            next(err, null);
        }
    });
};

/**
 * @summary Returns a filtered collection of elements for a search query.
 * @returns {Promise<any>}
 * @param target
 */

const translateTargetToQuery = target => {
    return `find${target}s`;
};

const searchData = (queryString, sections, eventId, next) => {
    try {
        const cleanTerm = queryString.replace(/([*+?^${}()|[]\/\\])/gi, '');

        let hasResults = false;
        let searchResults = [];

        each(
            sections,
            (section, callback) => {
                executeQueryWithCallback(
                    'getTypes',
                    {
                        id: section.typeId,
                    },
                    async (err, type) => {
                        if (!type) {
                            return callback();
                        }

                        const listQuery = translateTargetToQuery(type.target);
                        const params = {
                            type: { eq: type.id },
                            searchTerms: { contains: cleanTerm.toLowerCase() },
                        };

                        const result = await executeListQuery(listQuery, params);

                        if (result && result.length) {
                            const orderNumber = findIndex(
                                sections,
                                section => section.typeId === type.id,
                            );

                            searchResults.push({
                                listQuery,
                                type,
                                orderNumber,
                                items: sortBy(result, ['orderingName', 'name']),
                            });

                            hasResults = true;
                        } else {
                            console.log(`NO results found for: ${type.singular}`);
                        }

                        callback();
                    },
                );
            },
            function (err) {
                if (err) {
                    return next({ hasResults: false, searchResults: [] });
                }

                next({
                    hasResults,
                    searchResults: sortBy(searchResults, 'orderNumber'),
                });
            },
        );
    } catch (err) {
        throw new Error(err);
    }
};

export { getSearchPage, searchData };
