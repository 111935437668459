import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import moment from "moment";
import Auth from "../../services/api/auth";
import {default as Store} from "../../services/api/store";
import {getAllProgramFavoritesAsync} from "../../services/api/data";
import {getItemAsync} from "../../services/api/graphQlRepository";
import AppointmentService from "../Appointments/services/AppointmentService";
const SCHEDULED_REMINDERS_KEY = 'scheduled-reminders';

class ReminderServiceClass {
    getScheduledReminderItems() {
        try {
            return JSON.parse(localStorage.getItem(SCHEDULED_REMINDERS_KEY)) || [];
        } catch (_) {
            return [];
        }
    }

    saveScheduledReminderItems(items) {
        localStorage.setItem(SCHEDULED_REMINDERS_KEY, JSON.stringify(items));
    }

    removeScheduledReminderItems() {
        localStorage.removeItem(SCHEDULED_REMINDERS_KEY);
    }

    async addItemToScheduledList(item, { isAppointment, isFavorite }) {
        if (isAppointment) {
            item = this.parseAppointment(item);
        }

        if (isFavorite) {
            item = await this.parseFavorite(item);
        }

        if (!item) {
            return;
        }

        const storedItems = this.getScheduledReminderItems();
        const index = storedItems.findIndex(storedItem => storedItem.id === item.id);

        if (index >= 0 && isEqual(storedItems[index], item)) {
            return;
        }

        if (index >= 0) {
            storedItems[index] = item;
        } else {
            storedItems.push(item);
        }

        this.saveScheduledReminderItems(storedItems);
    }

    getItemsStartingInNext10Minutes() {
        const storedItems = this.getScheduledReminderItems();
        return storedItems.filter(item => {
            const start = moment.utc(item.start);
            const beforeStartWith10Minutes = moment.utc(start).subtract(10, 'minutes');
            const now = moment.utc();

            return now.isBetween(beforeStartWith10Minutes, start);
        });
    }
    
    getItemsWithEvaluationReminders() {
        const storedItems = this.getScheduledReminderItems();

        return storedItems.filter(item => {
            const sections = get(item, 'typeObj.sections', []);
            if (sections.length === 0) {
                return false;
            }

            const hasEvaluationReminderEnabled = sections
                .filter(section => section.kind === 'evaluations')
                .some(section => section.query?.setReminder);
            if (!hasEvaluationReminderEnabled) {
                return false;
            }

            const afterEndWith10Minutes = moment.utc(item.end).add(10, 'minutes');
            const afterEndWith20Minutes = moment.utc(item.end).add(20, 'minutes');
            const now = moment.utc();
            
            return now.isBetween(afterEndWith10Minutes, afterEndWith20Minutes);
        });
    }

    addFetchedItemsToScheduledList(items) {
        const storedItems = this.getScheduledReminderItems();

        items.forEach(item => {
           if (storedItems.findIndex(storedItem => storedItem.id === item.id) < 0) {
               storedItems.push(item);
           }
        });

        this.saveScheduledReminderItems(storedItems);
    }

    async fetchReminderItems() {
        const user = Auth.getUser();

        if (!user) {
            this.removeScheduledReminderItems();
            return;
        }

        try {
            const favorites = await this.getFavoriteItems();
            const appointments = await this.getAppointments();
            const items = [...favorites, ...appointments];

            this.addFetchedItemsToScheduledList(items);
        } catch (e) {
            console.log(e);
        }
    }

    convertUTCToTimezone(date, offset) {
        return moment(date).subtract(offset, 'minutes').toISOString();
    }

    async parseFavorite(item) {
        if (!Store || !Store.argument) {
            return null;
        }

        const eventOffset = Store.argument.offsetTimezone;
        const timeslot = await getItemAsync('timeslots', item.id);

        if (!timeslot) {
            return null;
        }

        const typeObj = await getItemAsync('types', timeslot.type);
        const start = this.convertUTCToTimezone(timeslot.start, eventOffset);
        const end = this.convertUTCToTimezone(timeslot.end, eventOffset);

        return {
            ...timeslot,
            typeObj,
            start,
            end,
        };
    }

    async getFavoriteItems() {
        const self = this;
        const favoriteItems = await getAllProgramFavoritesAsync();
        const extendedItems = await Promise.all(favoriteItems.map(function (favItem) {
            return self.parseFavorite(favItem);
        }));
        return extendedItems.filter(item => !!item);
    };

    parseAppointment(appointment) {
        let start = moment.utc(appointment.start);
        if (!start.isValid()) {
            start = moment.unix(appointment.start);
        }
        start = start.toISOString();

        let end = moment.utc(appointment.end);
        if (!end.isValid()) {
            end = moment.unix(appointment.end);
        }
        end = end.toISOString();

        const typeObj = {
            target: 'Appointment',
            singular: 'Appointment',
        };

        return {
            ...appointment,
            start,
            end,
            typeObj,
        };
    }

    async getAppointments() {
        const self = this;
        const user = Auth.getUser();
        const appointments = await AppointmentService.getAllAppointments(user.id);
        return appointments.map(function (appointment) {
            return self.parseAppointment(appointment);
        });
    };
}

const ReminderService = new ReminderServiceClass();

export default ReminderService;