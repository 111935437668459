import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import Presenter from './common/Presenter';
import bestFitLayout from '../../../../utils/bestFitLayout';
import { canJoinRoundTable } from '../../../../services/api/eureka';
import { goBackFromVirtualSession } from '../common/goBack';

const Wrapper = styled.div`
    flex-shrink: 0;
    padding: 16px;
    overflow: scroll;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    align-items: center;
    aspect-ratio: 16 / 9;

    ${props =>
        props.inverse
            ? `
        width: calc(100% - 32px);
        `
            : `
        height: calc(100% - 16px);
    `}
`;

const GridForUsers = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    overflow: hidden;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
`;

const VIEWPORT_HEIGHT_EXTRAS = 176; // = 80 (footer) + 80 (header) + 16 (vertical padding)

const NoSlidesView = props => {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const {
        virtualEventUser,
        virtualEventSession,
        peers,
        currentVirtualEventUser,
        localStream,
    } = stateCtx;
    const { match } = props;
    const { timeslotId } = match.params;
    const isHostOnline = peers.findIndex(peer => peer === virtualEventUser.UserId) !== -1;
    const desiredAspectRatio = 16 / 9;
    const windowAspectRatio = window.innerWidth / (window.innerHeight - VIEWPORT_HEIGHT_EXTRAS);
    const isDesiredAspectRatio = windowAspectRatio > desiredAspectRatio;

    const [isWindowDesiredAspectRatio, setIsWindowDesiredAspectRatio] = useState(isDesiredAspectRatio);

    const virtualEventUsers = useMemo(() => {
        let result = get(virtualEventSession, 'VirtualEventUsers', []);

        result.sort((a, b) => {
            const aLastName = a.User.lastName.toLowerCase();
            const bLastName = b.User.lastName.toLowerCase();

            if (aLastName > bLastName) {
                return 1;
            }
            if (aLastName < bLastName) {
                return -1;
            }
            return 0;
        });

        result = result.filter(vUser => {
            const hasJoined = peers.find(peerId => peerId === vUser.UserId);

            if (localStream && hasJoined && vUser.UserId === localStream.streamId) {
                vUser.muted = true;
            }

            if (vUser.id === get(virtualEventUser, 'id')) {
                // if it's the host
                return false;
            }

            return hasJoined;
        });

        return [virtualEventUser, ...result];
    }, [virtualEventSession, localStream, peers, virtualEventUser]);

    const virtualEventUsersToDisplay = virtualEventUsers.filter(vUser => {
        const isHost = vUser.role === 'roundTableHost';
        return isHost ? isHostOnline : true;
    });

    const gridLayout = bestFitLayout(virtualEventUsersToDisplay.length);

    const goBackFunction = () => goBackFromVirtualSession(props.history, timeslotId);

    const goBackWithCapacity = async () => {
        await goBackFunction();
        mutationCtx.setShowCapacityModal(true);
    };

    const testJoining = async () => {
        mutationCtx.setCanJoin(false);

        try {
            await canJoinRoundTable(timeslotId, currentVirtualEventUser.UserId, true);

            mutationCtx.setCanJoin(true);
        } catch (err) {
            goBackWithCapacity();
        }
    };

    const toggleUserProfile = id => {
        const { history, location } = props;

        if (location.pathname.includes('profile')) {
            return history.push({
                pathname: `${id}`,
                state: {
                    timeslot: props.timeslot,
                },
            });
        } else {
            return history.push({
                pathname: `${location.pathname}/profile/${id}`,
                state: {
                    timeslot: props.timeslot,
                },
            });
        }
    };

    const onResize = debounce(() => {
        const desiredAspectRatio = 16 / 9;
        const windowAspectRatio = window.innerWidth / (window.innerHeight - VIEWPORT_HEIGHT_EXTRAS);

        setIsWindowDesiredAspectRatio(windowAspectRatio > desiredAspectRatio);
    }, 500);

    useEffect(() => {
        window.addEventListener('resize', onResize, true);

        (async () => {
            if (timeslotId && get(currentVirtualEventUser, 'UserId')) {
                await testJoining();
            }
        })();

        return () => window.removeEventListener('resize', onResize, true);
    }, []);

    return (
        <Wrapper inverse={!isWindowDesiredAspectRatio ? 1 : 0}>
            <GridForUsers
                style={{
                    gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(gridLayout),
                }}
            >
                {virtualEventUsersToDisplay.map((virtualEventUserData, index) => (
                    <Presenter
                        key={index}
                        index={index}
                        virtualEventUser={virtualEventUserData}
                        toggleUserProfile={() => toggleUserProfile(virtualEventUserData.UserId)}
                        muted={currentVirtualEventUser.UserId === virtualEventUserData.UserId}
                        noFixedAspectRatio
                    />
                ))}
            </GridForUsers>
        </Wrapper>
    );
};

export default NoSlidesView;
