import React from 'react';

import { Avatar, FontIcon, ListItem } from 'react-md';
import { NavLink, Route } from 'react-router-dom';
import styled from 'styled-components';
import * as palette from '../General/Variables';
import { useTheme } from '../Theme/ThemeContext';
import { Tooltip } from '../../scenes/common/Button';

const StyledLink = styled(NavLink)`
    font-weight: 500;
`;

const ListItemStyled = styled(ListItem)`
    .md-tile-text--primary {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        line-height: 24px;
        color: ${props => (props.active ? props.primary : 'rgba(0, 0, 0, 0.6)')} !important;
    }

    .md-tile-content--left-icon {
        padding-left: 16px;
        margin-top: 5px;
    }

    &:hover {
        color: ${props => props.primary} !important;
    }

    .md-tile-addon--icon {
        height: 20px;
        width: 20px;
        color: ${props => (props.active ? props.primary : 'rgba(0, 0, 0, 0.6)')} !important;
    }

    .md-list-tile {
        margin: 0 8px;
        ${props => props.displayOnlyIcons && 'padding-right: 4px !important;'}

        &:hover {
            border-radius: 6px;
            i {
                color: ${props => props.primary} !important;
            }
            .md-tile-text--primary {
                color: ${props => props.primary} !important;
            }
            background: ${props => props.primary + '20'} !important;
        }
        &:active {
            background: ${props => props.primary + '20'} !important;
        }
    }

    .md-avatar {
        border: unset !important;
        background-color: transparent;
    }
`;

const PageAvatar = styled(Avatar)`
    .md-avatar {
        border: unset !important;
        background-color: transparent;
    }
`;

const Icon = styled(FontIcon)`
    font-size: 24px !important;
`;

const NavItemLink = ({ item, setTitle, getWebappIconUsingClass, visibleFullSideMenu }) => {
    const { theme } = useTheme();

    if (item.hidden) {
        return '';
    }

    const iconWebbapp =
        item.materialIcon &&
        (item.materialIcon.webappIcon || getWebappIconUsingClass(item.materialIcon.class));

    const getPos = (name, to) => {
        var e = window.event;

        var posY = e.clientY;
        var context = document.getElementById('custom-tooltip');
        var item = document.getElementById(`sidemenu-item-${to}`);
        if (context) {
            var dv = document.createElement('span');
            dv.innerHTML = name;
            context.style.top = (item?.getBoundingClientRect()?.top || posY) + 15 + 'px';
            context.style.left = '80px';
            context.style.display = 'block';
            context.style.background = 'rgba(0, 0, 0, 0.87)';
            context.innerHTML = name;
        }
    };

    const deletePos = () => {
        var context = document.getElementById('custom-tooltip');
        if (context) {
            context.style.top = 'unset';
            context.style.left = 'unset';
            context.style.display = 'block';
            context.style.background = 'transparent';
            context.innerHTML = '';
        }
    };

    return (
        <Route exact path={item.to || `/${item.name}`}>
            {({ match }) => {
                const leftAvatar = iconWebbapp ? (
                    <Icon iconClassName={'material-icons material-icons-round'}>{iconWebbapp}</Icon>
                ) : item.imageUrl ? (
                    <PageAvatar src={item.imageUrl} iconSized={true} />
                ) : (
                    <Icon iconClassName="material-icons-outlined">description</Icon>
                );
                if (!visibleFullSideMenu) {
                    return (
                        <>
                            <Tooltip
                                id="custom-tooltip"
                                bottom={'unset'}
                                background={'transparent'}
                            />
                            <ListItemStyled
                                id={`sidemenu-item-${item.to}`}
                                component={StyledLink}
                                active={!!match}
                                to={item.to || `/${item.name}`}
                                primaryTextStyle={{
                                    fontSize: '14px',
                                    color: palette.COLOR_TEXT,
                                }}
                                data-tooltip={item.name}
                                leftIcon={leftAvatar}
                                onClick={() => setTitle && setTitle(item.title)}
                                primary={theme.primary}
                                displayOnlyIcons={!visibleFullSideMenu}
                                onMouseEnter={() => {
                                    getPos(item.title, item.to);
                                }}
                                onMouseLeave={deletePos}
                            ></ListItemStyled>
                        </>
                    );
                }
                return (
                    <ListItemStyled
                        component={StyledLink}
                        active={!!match}
                        to={item.to || `/${item.name}`}
                        primaryText={item.title}
                        primaryTextStyle={{
                            fontSize: '14px',
                            color: palette.COLOR_TEXT,
                        }}
                        leftIcon={leftAvatar}
                        onClick={() => setTitle && setTitle(item.title)}
                        primary={theme.primary}
                    />
                );
            }}
        </Route>
    );
};

export default NavItemLink;
