import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import { FontIcon } from 'react-md';

import {
    ContainerRight,
    MakeMainScreenButton,
    SecondaryScreen,
    UserInteractionContainer,
} from '../../../styles';
import MediaPlayer from '../../../../../components/MediaPlayer';
import { useGlobalMutation, useGlobalState } from '../../../../../utils/container';
import ShareContent from '../common/ShareContent';
import Video from '../Video';
import { checkIfTimeslotHasOnlyOneUploadedVideo, getPlayerLinkByType } from '../../../utils/Utils';
import UserInteraction from '../UserInteraction';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import * as palette from '../../../../../components/General/Variables';

const StyledRightContainer = styled(ContainerRight)`
    overflow: hidden;
    margin: 0 !important;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
`;

const SideContent = props => {
    const { authUser, isVotingActive } = props;
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();

    const { eurekaChatUrl, mainMedia, timeslot, virtualEventSession } = stateCtx;
    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const wrapperRef = useRef(null);
    const [showMainButton, setShowMainButton] = useState(false);
    const [interactionWindowHeight, setInteractionWindowHeight] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isSecondaryScreenActive =
        wrapperRef.current && wrapperRef.current.firstChild.hasChildNodes();
    const hasOnlyOneUploadedVideo = checkIfTimeslotHasOnlyOneUploadedVideo(timeslot);
    const forceUnmuteSlidesForUploadedVideo = hasOnlyOneUploadedVideo && mainMedia === 'video';
    const forceNotMuted = !!get(virtualEventSession, 'recordingInfo.playBothVideosNormally');
    const muted =
        forceNotMuted || forceUnmuteSlidesForUploadedVideo ? false : mainMedia !== 'slides';

    const handleResize = debounce(() => {
        setInteractionWindowHeight(window.innerHeight < 900 ? 225 : 425);
        setWindowWidth(window.innerWidth);
    }, 500);

    useEffect(() => {
        if (wrapperRef.current) {
            handleResize();
        }
    }, [wrapperRef.current]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return async () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const shouldRenderMediaPlayer =
        timeslot && timeslot.params && timeslot.params.virtualSession === 'on-demand';
    const shouldRenderLiveSession = !shouldRenderMediaPlayer;
    const mainIsSlides = mainMedia === 'slides';

    return (
        <StyledRightContainer size={4} tabletSize={4} mobile={mobile}>
            <SecondaryScreen
                innerRef={wrapperRef}
                className="secondary"
                size={12}
                tabletSize={12}
                mobile={mobile}
                onMouseOver={() => setShowMainButton(true)}
                onMouseLeave={() => setShowMainButton(false)}
            >
                {shouldRenderMediaPlayer && (
                    <MediaPlayer
                        url={getPlayerLinkByType({
                            mainMedia,
                            timeslot,
                            primaryScreen: false,
                        })}
                        muted={muted}
                    />
                )}
                {shouldRenderLiveSession &&
                    (mainIsSlides ? <Video timeslot={timeslot} /> : <ShareContent {...props} />)}
                {showMainButton && isSecondaryScreenActive && !isVotingActive && (
                    <MakeMainScreenButton onClick={mutationCtx.userSwitchMain}>
                        <FontIcon>picture_in_picture</FontIcon>
                        <span>Make main screen</span>
                    </MakeMainScreenButton>
                )}
            </SecondaryScreen>
            <UserInteractionContainer
                mobile={mobile}
                interactionwindowheight={interactionWindowHeight}
                interactioninitialheight={!interactionWindowHeight ? window.innerHeight : 0}
                windowwidth={windowWidth}
            >
                <UserInteraction
                    timeslot={timeslot}
                    userData={authUser}
                    eurekaChatUrl={eurekaChatUrl}
                    sessionType={
                        timeslot && timeslot.params && timeslot.params.virtualSession
                            ? timeslot.params.virtualSession
                            : ''
                    }
                />
            </UserInteractionContainer>
        </StyledRightContainer>
    );
};

export default SideContent;
