import { normalize, schema } from 'normalizr';
import get from 'lodash/get';

import Auth from '../../../services/api/auth';
import {
    getConversations,
    getEurekaInfoWithJid,
    getGroupChatMessages,
    getPrivateChatMessages,
    registerChatUser as registerEurekaChatUser,
    getGroupChatParticipants,
    startGroupChat,
    sendPushNotificationMessage,
} from '../api/eureka';
import {
    getGroupChatParticipantsSelector,
    getJoinedRooms,
    getConversationWithRoomId,
    getParticipantStatus,
    getAppointmentMessageText,
    getIsLoadingConversations,
} from '../selectors';
import talkManager from '../services';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { APPOINTMENT_STATE } from '../constants';
import AppointmentService from '../../Appointments/services/AppointmentService';
import eventBus from '../../../utils/eventBus';
import { logChat } from '../utils';

// action types configurations
const REQUEST_TYPE = 'REQUEST';
const SUCCESS_TYPE = 'SUCCESS';
const FAILURE_TYPE = 'FAILURE';

const createRequestTypes = base => {
    const types = [REQUEST_TYPE, SUCCESS_TYPE, FAILURE_TYPE];
    return types.reduce((acc, type) => {
        acc[type] = `${base}_${type}`;
        return acc;
    }, {});
};

// actions
export const LOAD_GROUP_CONVERSATIONS = createRequestTypes('LOAD_GROUP_CONVERSATIONS');
export const LOAD_GROUP_CHAT_MESSAGES = createRequestTypes('LOAD_GROUP_CHAT_MESSAGES');
export const LOAD_GROUP_CHAT_PARTICIPANTS = createRequestTypes('LOAD_GROUP_CHAT_PARTICIPANTS');
export const LOAD_PRIVATE_CHAT_MESSAGES = createRequestTypes('LOAD_PRIVATE_CHAT_MESSAGES');
export const REGISTER_CHAT_USER = createRequestTypes('REGISTER_CHAT_USER');
export const UPDATE_PRIVATE_CONVERSATIONS = createRequestTypes('UPDATE_PRIVATE_CONVERSATIONS');
export const SET_USER = 'SET_USER';
export const SET_EVENT_ID = 'SET_EVENT_ID';
export const SET_CHAT_URL = 'SET_CHAT_URL';
export const SET_CHAT_ROOM_ID = 'SET_CHAT_ROOM_ID';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const UPDATE_PARTICIPANT_STATUS = 'UPDATE_PARTICIPANT_STATUS';
export const UPDATE_SERVER_STATUS = 'UPDATE_SERVER_STATUS';
export const SET_LAST_SEEN_TIMESTAMP = 'SET_LAST_SEEN_TIMESTAMP';
export const SET_JOINED_ROOMS = 'SET_JOINED_ROOMS';

// schemas
const participantSchema = new schema.Entity('participants');
const messageSchema = new schema.Entity('messages', {
    participant: participantSchema,
});
const conversationSchema = new schema.Entity('privateConversations', {
    lastMessage: messageSchema,
    pendingMessages: [messageSchema],
    participant: participantSchema,
});
const groupChatParticipantSchema = new schema.Entity('participants', {}, { idAttribute: 'UserId' });
const groupConversationSchema = new schema.Entity('groupConversations', {
    lastMessage: messageSchema,
    participants: [groupChatParticipantSchema],
});

// helpers

export const getNickAsInt = nick => {
    let parsed;
    if (typeof nick === 'string' && nick.indexOf('_') !== -1) {
        const splitted = nick.split('_');
        if (splitted[0]) {
            parsed = parseInt(splitted[0], 10);
        }
    } else {
        parsed = parseInt(nick, 10);
    }
    return parsed;
};

export const parseGroupConversation = (c, state) => {
    const { user } = state.talk;
    const { chatUrl } = state.talk.settings;
    const eventId = c.ExternalObject.Event.reference;

    // TODO: Check if lastMessage is "removedFromUser" or "removedFromModerator"
    // if "removedFromUser" use LS.translate('groupChatDeleteMessageRemoved') as txt
    // don't know yet what to do if it's "removedFromModerator"
    const lastMessage =
        c.lastMessage && c.lastMessage.user
            ? {
                  ...c.lastMessage,
                  participant: {
                      id: getNickAsInt(c.lastMessage.nick),
                      ...c.lastMessage.user,
                  },
              }
            : null;
    let unreadCount = 0;
    if (lastMessage && lastMessage.nick && getNickAsInt(lastMessage.nick) === user.id) {
        unreadCount = c.lastMessage.full_count;
    }
    return {
        id: c.id,
        jid: `${c.id}@conference.${chatUrl}`,
        title: c.ExternalObject.title,
        // image: isTopicalChat ? null : c.ExternalObject.Event.icon,
        roomId: c.ExternalObject.reference,
        eventId,
        participants: c.ChatRoomUsers,
        lastMessage,
        unreadCount,
    };
};

// action creators

export const enrichBuddies = jids => async dispatch => {
    const actionParams = { jids };

    dispatch({ type: UPDATE_PRIVATE_CONVERSATIONS.REQUEST, payload: { actionParams } });

    try {
        const eurekaBuddies = await getEurekaInfoWithJid({ jids });
        const conversations = [];

        eurekaBuddies.forEach(b => {
            const participant = { ...b };
            delete participant.lastMessage;
            delete participant.pendingMessages;

            if (b.lastMessage && b.lastMessage.xml) {
                conversations.push({
                    id: b.id,
                    jid: b.jid,
                    title: b.displayName || `${b.firstName} ${b.lastName}`,
                    image: b.imageUrl,
                    participant,
                    lastMessage: {
                        ...b.lastMessage,
                        participant: {
                            id: participant.id,
                        },
                        nick: b.lastMessage.xml.split("from='")[1].split('@')[0],
                    },
                    pendingMessages: b.pendingMessages,
                    messages: [b.lastMessage.id],
                });
            }
        });

        const privateConversations = normalize(conversations, [conversationSchema]).entities;

        dispatch({
            type: UPDATE_PRIVATE_CONVERSATIONS.SUCCESS,
            payload: { actionParams, privateConversations },
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PRIVATE_CONVERSATIONS.FAILURE,
            payload: { error, actionParams },
        });
    }
};

export const updateParticipantStatus = ({ participantId, online }) => ({
    type: UPDATE_PARTICIPANT_STATUS,
    payload: { participantId, online },
});

export const updateServerStatus = ({ online, error }) => ({
    type: UPDATE_SERVER_STATUS,
    payload: { online, error },
});

export const addNewMessage = ({
    isPrivateMessage = true,
    data,
    incrementUnreadCount = true,
}) => async (dispatch, getState) => {
    const state = getState();
    const { user } = state.talk;
    const { chatUrl } = state.talk.settings;

    const conversationId = data.conversation.id;
    if (data.message.nick) {
        data.message.nick = getNickAsInt(data.message.nick);
    }
    const participantId = getNickAsInt(data.message.nick);
    const participantJid = data.message.bare_peer || `${participantId}@${chatUrl}`;

    const stateKey = isPrivateMessage ? 'privateConversations' : 'groupConversations';
    const conversationState = get(state, `talk.${stateKey}.${conversationId}`);

    let lastFullCount = 0;
    if (user.id !== participantId && incrementUnreadCount) {
        lastFullCount = get(state, `talk.unreadMessagesCount.${conversationId}`, 0);
        lastFullCount += 1;
    }

    const newMessageAction = {
        type: NEW_MESSAGE,
        payload: {
            isPrivateMessage,
            data,
            unreadCount: lastFullCount,
        },
    };

    /*
        If a message comes from an user that was not a participant in this room,
        we need to ask Eureka (getEurekaInfoWithJid) for her/his information and
        add this info to the participants list so this message has proper user info like firstName and avatar
    */
    const currentParticipants = get(conversationState, 'participants', []);
    const isNewGroupParticipant =
        !isPrivateMessage && currentParticipants.indexOf(participantId) < 0;

    if (!conversationState || isNewGroupParticipant) {
        const response = await getEurekaInfoWithJid({ jids: [participantJid] });
        const participant = response[0];
        if (participant) {
            delete participant.lastMessage;
            dispatch({
                type: UPDATE_PARTICIPANT,
                payload: {
                    [participant.id]: {
                        ...participant,
                        displayName: `${participant.firstName} ${participant.lastName}`,
                    },
                },
            });
            // Adds participant to conversation so this is not asked again
            currentParticipants.push(participant.id);
            const conversationWithParticipant = {
                ...conversationState,
                participants: currentParticipants,
            };
            updateConversation(conversationWithParticipant);
        }
    }

    return dispatch(newMessageAction);
};

export const loadGroupConversations = () => async (dispatch, getState) => {
    const state = getState();
    const { eventId } = state.talk.settings;

    if (getIsLoadingConversations(state)) {
        return;
    }

    const data = { event: eventId, moderatorOnly: false };
    const lastTimestamps = state.talk.lastMessagesSeen;
    const timestampKeys = Object.keys(lastTimestamps);
    if (timestampKeys.length) {
        const lastStamps = {};
        timestampKeys.forEach(key => {
            lastStamps[key] = parseFloat(lastTimestamps[key], 10);
        });
        data.lastTimestamps = lastStamps;
    }

    const actionParams = { ...data };

    dispatch({ type: LOAD_GROUP_CONVERSATIONS.REQUEST, payload: { actionParams } });

    try {
        const response = await getConversations(data);
        const conversations = response.map(c => parseGroupConversation(c, state));

        const groupConversations = normalize(conversations, [groupConversationSchema]).entities;

        const unreadMessagesCount = {};
        response.forEach(c => {
            unreadMessagesCount[c.id] = c.lastMessage.full_count;
        });

        dispatch({
            type: LOAD_GROUP_CONVERSATIONS.SUCCESS,
            payload: { actionParams, groupConversations, unreadMessagesCount },
        });
    } catch (error) {
        dispatch({ type: LOAD_GROUP_CONVERSATIONS.FAILURE, payload: { error, actionParams } });
    }
};

export const updateUser = userData => ({
    type: SET_USER,
    payload: { userData },
});

export const updateEvent = eventId => ({
    type: SET_EVENT_ID,
    payload: { eventId },
});

export const updateChatUrl = chatUrl => ({
    type: SET_CHAT_URL,
    payload: { chatUrl },
});

export const updateChatRoomId = roomId => ({
    type: SET_CHAT_ROOM_ID,
    payload: { chatRoomId: roomId },
});

export const updateConversation = conversation => dispatch => {
    const { lastMessage } = conversation;
    if (!lastMessage) {
        dispatch({ type: UPDATE_CONVERSATION, payload: { conversation } });
        return;
    }

    const lastMessageId = typeof lastMessage === 'object' ? lastMessage.id : lastMessage;
    const conv = {
        ...conversation,
        lastMessage: lastMessageId,
    };

    dispatch({
        type: UPDATE_CONVERSATION,
        payload: { conversation: conv },
    });
};

export const clearSettings = () => ({
    type: 'CLEAR_SETTINGS',
});

export const setLastSeenTimestamp = ({ conversationId, messageId, timestamp, isPrivate }) => ({
    type: SET_LAST_SEEN_TIMESTAMP,
    payload: { conversationId, messageId, timestamp, isPrivate },
});

export const loadGroupChatMessages = ({
    conversation,
    limit = 100,
    lastTimestamp,
}) => async dispatch => {
    const room = conversation.jid;
    const actionParams = { conversation, limit, lastTimestamp };
    dispatch({ type: LOAD_GROUP_CHAT_MESSAGES.REQUEST, payload: { actionParams } });

    try {
        const response = await getGroupChatMessages({ room, limit, lastTimestamp });
        const messagesResponse = response
            .map(m => ({
                ...m,
                participant: {
                    id: getNickAsInt(m.nick),
                },
            }))
            .filter(m => !m.removedFromModerator);
        const messages = normalize(messagesResponse, [messageSchema]);
        if (!lastTimestamp) {
            actionParams.resetOnSuccess = true;
        }
        dispatch({
            type: LOAD_GROUP_CHAT_MESSAGES.SUCCESS,
            payload: { actionParams, response: messages },
        });
    } catch (error) {
        dispatch({ type: LOAD_GROUP_CHAT_MESSAGES.FAILURE, payload: { error, actionParams } });
    }
};

const getAppointmentText = (currentUser, appointment) => {
    const displayName = currentUser
        ? currentUser.displayName || `${currentUser.firstName} ${currentUser.lastName}`
        : '';

    const text = getAppointmentMessageText(appointment);

    return `${displayName} ${text}`;
};

const getAppointmentData = (currentUser, appointment) => ({
    objectId: appointment.reference,
    eurekaId: appointment.id,
    creator: appointment.UserId,
    from: currentUser.jid,
});

export const setStatus = active => async () => {
    talkManager.updateMyStatus(active);
};

export const sendPrivateMessage = ({
    eventId,
    conversation,
    message,
    messageType = 'chat',
}) => async (dispatch, getState) => {
    const state = getState();
    const currentUser = state.talk.user;
    const conversationId = conversation.id;
    const conversationJid = conversation.jid;

    if (!currentUser.id) {
        await logChat(
            ` error sending message: no currentUser.id ${JSON.stringify(
                currentUser,
                null,
                '    ',
            )} `,
        );
        return;
    }

    if (!conversation || !conversation.id || !conversation.jid) {
        await logChat(
            `error sending message. conversation: ${JSON.stringify(conversation, null, '    ')}`,
        );
        return;
    }

    const isOnline = getParticipantStatus(state, conversationId); //useful for push notifications for example

    const text = typeof message === 'string' ? message : JSON.stringify(message);

    const sentPromise = await talkManager.sendMessage({
        eventId,
        to: conversationJid,
        message: text,
        isPrivateMessage: true,
    });

    if (sentPromise === null) {
        await logChat(`XMPP error sending message: ${JSON.stringify(sentPromise, null, '    ')}`);
        return;
    }

    const existingConversation = !!state.talk.privateConversations[conversationId];
    const conversationHasId =
        existingConversation && state.talk.privateConversations[conversationId].id; // some conversations have no ID

    const noConversation = !existingConversation || !conversationHasId;
    // if there's no conversation with the other user, start it

    if (noConversation) {
        await logChat(` no previous conversation, creating one...`);
        try {
            await talkManager.addToRoster(conversationJid);
            talkManager.subscribe(conversationJid);
        } catch (error) {
            console.error('error starting new conversation');
        }
    }

    // send a push notification so the user receives the message
    if (!isOnline || noConversation) {
        const { id, configuratorUrl } = await getLocalAppStateAsync();

        const pushDataObj = {
            configuratorUrl,
            to: `${conversationId}`,
            message: text,
            type: messageType,
            event: null,
        };

        if (messageType === 'appointment') {
            pushDataObj.event = id;
            pushDataObj.message = getAppointmentText(currentUser, message);
            pushDataObj.data = getAppointmentData(currentUser, message.data);
        }

        await sendPushNotificationMessage({ data: pushDataObj });
    }

    // get the proper message id
    const lastMessages = await getPrivateChatMessages({
        userId: currentUser.id,
        from: conversationJid,
        limit: 1,
    });
    const lastMessage = lastMessages[0];

    if (!lastMessage) {
        await logChat(`error sending message. No message from getPrivateChatMessages`);
        await logChat(`currentUser: ${JSON.stringify(currentUser, null, '    ')}`);
        await logChat(`conversation: ${JSON.stringify(conversation, null, '    ')}`);
        return;
    }

    // Check to see if last message is the new one
    if (conversation.lastMessage && conversation.lastMessage.id === lastMessage.id) {
        await logChat(
            `error sending message. Last message from server is the same as the one on stored conversation`,
        );
        await logChat(`lastMessage: ${JSON.stringify(lastMessage, null, '    ')}`);
        await logChat(`conversation: ${JSON.stringify(conversation, null, '    ')}`);
        return;
    }

    dispatch(
        addNewMessage({
            isPrivateMessage: true,
            data: {
                conversation,
                message: {
                    ...lastMessage,
                    nick: `${currentUser.id}`,
                    participant: currentUser.id,
                },
            },
        }),
    );

    return lastMessage;
};

export const loadTimeslotChatCardLastMessages = itemId => async (dispatch, getState) => {
    const CHAT_HOST = getState().talk.settings.chatUrl;
    const data = { objectId: itemId };
    const roomParticipants = await getGroupChatParticipants(data);

    if (roomParticipants.length) {
        const roomId = roomParticipants[0].ChatRoomId;
        const room = `${roomId}@conference.${CHAT_HOST}`;

        const participantsArr = roomParticipants.map(r => ({
            active: r.active,
            createAt: r.createdAt,
            updatedAt: r.updatedAt,
            ...r.User,
        }));
        const participants = normalize(participantsArr, [participantSchema]);
        dispatch({
            type: LOAD_GROUP_CHAT_PARTICIPANTS.SUCCESS,
            payload: {
                actionParams: { conversationId: roomId, roomId: itemId },
                response: participants,
            },
        });

        const groupChatMessagesResponse = await getGroupChatMessages({ room, limit: 10 });
        let groupChatLastMessage = groupChatMessagesResponse.length
            ? groupChatMessagesResponse[0]
            : {};
        groupChatLastMessage.nick = getNickAsInt(groupChatLastMessage.nick);

        const conv = {
            jid: room,
            id: roomId,
            roomId: itemId,
            lastMessage: groupChatLastMessage,
        };

        dispatch(updateConversation(conv));
        groupChatMessagesResponse.forEach(m => {
            dispatch(
                addNewMessage({
                    isPrivateMessage: false,
                    data: {
                        conversation: conv,
                        message: m,
                    },
                }),
            );
        });
    }
};

export const loadPrivateChatMessages = ({ from, limit = 100, lastTimestamp }) => async (
    dispatch,
    getState,
) => {
    const userId = getState().talk.user.id;
    const conversationId = from && parseInt(from.split('@')[0], 10);

    const actionParams = {
        conversation: { id: conversationId },
        limit,
        lastTimestamp,
        userId,
    };

    dispatch({ type: LOAD_PRIVATE_CHAT_MESSAGES.REQUEST, payload: { actionParams } });

    try {
        const messagesHistoryResponse = await getPrivateChatMessages({
            userId,
            from,
            limit,
            lastTimestamp,
        });
        const messagesData = messagesHistoryResponse.map(m => ({
            ...m,
            nick: m.xml.split("from='")[1].split('@')[0],
            removedFromUser: m.txt === '__removed__',
        }));
        const messages = normalize(messagesData, [messageSchema]);

        dispatch({
            type: LOAD_PRIVATE_CHAT_MESSAGES.SUCCESS,
            payload: { actionParams, response: messages },
        });
    } catch (error) {
        dispatch({
            type: LOAD_PRIVATE_CHAT_MESSAGES.FAILURE,
            payload: { actionParams, error },
        });
    }
};

export const loadGroupChatParticipants = ({ id: conversationId, roomId }) => async dispatch => {
    const actionParams = { conversationId, roomId };
    if (conversationId) {
        dispatch({ type: LOAD_GROUP_CHAT_PARTICIPANTS.REQUEST, payload: { actionParams } });
    }

    const data = { objectId: roomId };
    const response = await getGroupChatParticipants(data);

    if (response && response.length) {
        actionParams.conversationId = get(response, '[0].ChatRoomId');

        const participantsArr = response.map(r => ({
            active: r.active,
            createAt: r.createdAt,
            updatedAt: r.updatedAt,
            ...r.User,
        }));

        const participants = normalize(participantsArr, [participantSchema]);

        dispatch({
            type: LOAD_GROUP_CHAT_PARTICIPANTS.SUCCESS,
            payload: { actionParams, response: participants },
        });
        return response;
    } else {
        // dispatch({ type: LOAD_GROUP_CHAT_PARTICIPANTS.FAILURE, payload: { error, actionParams } });
        return [];
    }
};

export const sendGroupMessage = ({ conversation, message }) => async (dispatch, getState) => {
    const state = getState();
    const currentUser = state.talk.user;
    const joinedRooms = getJoinedRooms(state);
    const { chatUrl, eventId } = state.talk.settings;

    const conversationId = conversation.id;
    const conversationJid = conversation.jid;

    if (!currentUser.id) {
        await logChat(
            `error sending group message: no currentUser.id ${JSON.stringify(
                currentUser,
                null,
                '    ',
            )} `,
        );
        return;
    }

    if (!conversation || !conversation.id || !conversation.jid) {
        await logChat(
            `error sending group message. Conversation: ${JSON.stringify(
                conversation,
                null,
                '    ',
            )} `,
        );
        return;
    }

    const participants = getGroupChatParticipantsSelector(state, conversationId);
    const inRoom = participants.find(p => p.id === currentUser.id) !== undefined;

    const xmppStatus = talkManager.getXmppOnline();
    if (!xmppStatus) {
        await logChat(
            `error sending group message, xmppStatus is false. Conversation: ${JSON.stringify(
                conversation,
                null,
                '    ',
            )} `,
        );
        return;
    }

    // if current user is in room, send the message, otherwise join the room and then send the message
    if (inRoom) {
        const sentPromise = await talkManager.sendMessage({
            to: conversationJid,
            message,
            isPrivateMessage: false,
        });
        if (sentPromise === null) {
            await logChat(`XMPP error sending group message`);
            return;
        }
    } else {
        const updatedConversation = await startGroupConversation({
            conversation,
            chatUrl,
            eventId,
        });
        dispatch({
            type: UPDATE_PARTICIPANT,
            payload: {
                [currentUser.id]: {
                    ...currentUser,
                    displayName: `${currentUser.firstName} ${currentUser.lastName}`,
                },
            },
        });

        const joinedAsWatcher = joinedRooms.find(room => room === conversationJid) !== undefined;
        if (joinedAsWatcher) {
            const sentPromise = await talkManager.sendMessage({
                to: conversationJid,
                message,
                isPrivateMessage: false,
            });
            if (sentPromise === null) {
                return;
            }
        } else {
            talkManager.cachedMessageToBeSent = {
                to: conversationJid,
                message,
            };
            dispatch(joinRooms([updatedConversation]));
        }

        updatedConversation.participants = [
            ...conversation.participants.map(p => p.id),
            currentUser.id,
        ];
        dispatch(updateConversation(updatedConversation));
    }

    return { success: true };
};

export const loadGroupChatRoomIdAndParticipants = ({ data, roomId }) => async dispatch => {
    let actionParams = {
        objectId: roomId,
    };
    if (data && data.length) {
        actionParams.conversationId = data[0].ChatRoomId;
        dispatch(updateChatRoomId(data[0].ChatRoomId));
    }
    const participantsArr = data.map(r => ({
        active: r.active,
        createAt: r.createdAt,
        updatedAt: r.updatedAt,
        ...r.User,
    }));
    const participants = normalize(participantsArr, [participantSchema]);
    dispatch({
        type: LOAD_GROUP_CHAT_PARTICIPANTS.SUCCESS,
        payload: { actionParams, response: participants },
    });
};

export const registerChatUser = () => async (dispatch, getState) => {
    const { user } = getState().talk;
    dispatch({ type: REGISTER_CHAT_USER.REQUEST });
    try {
        const resp = await registerEurekaChatUser();
        dispatch({ type: REGISTER_CHAT_USER.SUCCESS, payload: { ...resp } });
        const updatedUser = {
            ...user,
            jid: resp.jid,
            jpassword: resp.password,
        };
        Auth.registerChatUser(resp.jid, resp.password);
        dispatch({
            type: SET_USER,
            payload: { userData: updatedUser },
        });
    } catch (error) {
        dispatch({ type: REGISTER_CHAT_USER.FAILURE, payload: { error } });
    }
};

const startGroupConversation = async ({ conversation, chatUrl, eventId, isNew = true }) => {
    const data = {
        objectId: conversation.roomId,
        objectTitle: conversation.objectTitle || conversation.roomId,
        eventId: eventId,
        eventTitle: conversation.eventTitle,
    };
    const response = await startGroupChat(data);
    const conv = response[0];
    delete conv.User;
    const id = conv.ChatRoomId;

    const updatedConversation = {
        id,
        jid: `${id}@conference.${chatUrl}`,
        title: conversation.objectTitle,
        roomId: conversation.roomId,
        objectId: conversation.roomId,
    };

    if (isNew) {
        updatedConversation.lastMessage = undefined;
    }

    return updatedConversation;
};

/**
 * @method checkShouldStartConversation
 * @description Checks if is needed to create a new conversation
 * @param {Object} conversation complete conversation object with all infos needed to start one
 * @param {String} conversation.objectId External object ID: Institution or Time Slot ID for example
 * @param {String} conversation.roomId Same as conversation.objectId
 * @param {String} conversation.objectTitle External object name: Conference Compass or Session title for example
 * @param {String} conversation.eventId ID of the current event
 * @param {String} conversation.eventTitle Type of chat to register on Eureka
 */
export const checkShouldStartConversation = conversation => async (dispatch, getState) => {
    const state = getState();
    const currentUser = state.talk.user;
    const stateParticipants = state.talk.participants;
    const userId = currentUser.id;
    const { chatUrl, eventId } = state.talk.settings;
    const { roomId } = conversation;

    try {
        const roomParticipants = await dispatch(
            loadGroupChatParticipants({ id: conversation.id, roomId }),
        );

        const onStartConversation = async () => {
            try {
                const updatedConversation = await startGroupConversation({
                    conversation,
                    chatUrl,
                    eventId,
                });

                const userIsParticipant =
                    Object.values(stateParticipants).find(p => p.id === userId) !== undefined;
                if (!userIsParticipant) {
                    dispatch({
                        type: UPDATE_PARTICIPANT,
                        payload: {
                            [userId]: {
                                ...currentUser,
                                displayName: `${currentUser.firstName} ${currentUser.lastName}`,
                            },
                        },
                    });
                }
                dispatch(joinRooms([updatedConversation]));
                updatedConversation.participants = [userId];
                dispatch(updateConversation(updatedConversation));
            } catch (error) {
                console.error('Error starting conversation');
            }
        };

        if (!roomParticipants || !roomParticipants.length) {
            onStartConversation();
        } else {
            const chatRoomId = roomParticipants[0].ChatRoomId;
            const chatRoom = `${chatRoomId}@conference.${chatUrl}`;

            const participants = roomParticipants.map(p => p.UserId);
            const updatedConversation = {
                ...conversation,
                participants,
                jid: chatRoom,
                id: chatRoomId,
            };

            // const currentUserIsInRoom = participants.indexOf(userId) > -1;
            // if (currentUserIsInRoom) {
            //     dispatch(joinRooms([updatedConversation]));
            // }
            dispatch(joinRooms([updatedConversation]));
            dispatch(updateConversation(updatedConversation));
        }
    } catch (err) {
        console.error('Error checking if should start conversation');
    }
};

export const joinRooms = conversations => (dispatch, getState) => {
    const state = getState();
    const currentUser = state.talk.user;
    const joinedRooms = getJoinedRooms(state) || [];

    const roomsToJoin = conversations.filter(c => joinedRooms.indexOf(c.jid) < 0 && !c.isPrivate);
    if (roomsToJoin.length) {
        const newJoinedRooms = [...joinedRooms, ...roomsToJoin.map(r => r.jid)];
        dispatch({ type: SET_JOINED_ROOMS, payload: { joinedRooms: newJoinedRooms } });

        talkManager.joinRooms({ rooms: roomsToJoin, userId: currentUser.id });
    }
};

/**
 * @param conversations - if this is not passed, leave all rooms
 * @returns {function(...[*]=)}
 */
export const leaveRooms = conversations => (dispatch, getState) => {
    const state = getState();
    const currentUser = state.talk.user;
    const joinedRooms = getJoinedRooms(state);

    if (conversations && conversations.length) {
        // get conversation with objectId only. This happens with just created conversations
        conversations.map(c =>
            !c.jid && c.roomId ? getConversationWithRoomId(state, c.roomId) : c,
        );
    }

    const newJoinedRooms = conversations
        ? joinedRooms.filter(jid => !conversations.find(c => c.jid === jid))
        : [];

    dispatch({ type: SET_JOINED_ROOMS, payload: { joinedRooms: newJoinedRooms } });

    const toLeave = conversations || joinedRooms.map(jid => ({ jid }));
    talkManager.leaveRooms({
        rooms: toLeave,
        userId: currentUser.id,
    });
};

/**
 * @method addRepresentativesToConversation
 * @description Adds an array of user IDs (representatives) to a group conversation
 * @param {Int} conversationId complete conversation object
 * @param {Array} representatives list of representative IDs
 */
export const addRepresentativesToConversation = (conversationId, representatives) => (
    dispatch,
    getState,
) => {
    const state = getState();
    const conversation = state.talk.groupConversations[conversationId];
    if (conversation) {
        const conversationWithRepresentatives = {
            ...conversation,
            representatives,
        };
        dispatch(updateConversation(conversationWithRepresentatives));
    }
};

export const sendChatNotification = ({
    userId,
    appointment,
    action = APPOINTMENT_STATE.SCHEDULED,
}) => (dispatch, getState) => {
    if (!userId) {
        return;
    }

    const { chatUrl } = getState().talk.settings;

    const xmppIsOnline = talkManager.getXmppOnline();
    if (!xmppIsOnline || !chatUrl) {
        console.log(` >>>>>>>>>>>>>>>>>>>>>>>>>>>>> cannot send Appointment message`);
        return;
    }

    const notificationData = {
        type: 'appointment',
        data: {
            ...appointment,
            action,
        },
    };

    dispatch(
        sendPrivateMessage({
            conversation: {
                id: userId,
                jid: `${userId}@${chatUrl}`,
            },
            message: notificationData,
            messageType: 'appointment',
        }),
    );
};

const convertEurekaAppointmentToChatObject = ({ appointment, action, currentEvent }) => ({
    // Local database id or eureka reference
    id: appointment.reference,
    eurekaId: appointment.id,
    creator: appointment.UserId,

    // Appointment details
    name: appointment.name,
    start: appointment.start,
    end: appointment.end,
    location: appointment.contentLocation,

    // Status
    action,
    event: appointment.ownerId,
    eventName: currentEvent.title,

    isVirtual: appointment.isVirtual,
});

const respondAppointmentInvite = (appointment, action) => async dispatch => {
    const currentEvent = await getLocalAppStateAsync();

    if (action === APPOINTMENT_STATE.ACCEPTED) {
        await AppointmentService.acceptAppointment(appointment.id, currentEvent.id);
    } else {
        await AppointmentService.declineAppointment(appointment.id, currentEvent.id);
    }

    const updatedAppointment = await AppointmentService.getAppointmentById(appointment.id);

    const chatAppointment = await convertEurekaAppointmentToChatObject({
        appointment: updatedAppointment,
        action,
        currentEvent,
    });

    dispatch(
        sendChatNotification({
            userId: updatedAppointment.UserId,
            action,
            appointment: chatAppointment,
        }),
    );

    return updatedAppointment;
};

export const acceptAppointment = appointment => async dispatch =>
    dispatch(respondAppointmentInvite(appointment, APPOINTMENT_STATE.ACCEPTED));

export const declineAppointment = appointment => async dispatch =>
    dispatch(respondAppointmentInvite(appointment, APPOINTMENT_STATE.DECLINED));

export const deleteAppointment = appointment => async dispatch => {
    if (!appointment.reference) {
        const { reference } = await AppointmentService.getAppointmentById(appointment.id);
        appointment.reference = reference;
    }
    const currentEvent = await getLocalAppStateAsync();
    await AppointmentService.cancelAppointment(appointment.reference, currentEvent.id);
    const updatedAppointment = await AppointmentService.getAppointmentById(appointment.id);

    const buddyId =
        (appointment.members && appointment.members[0]?.id) ||
        (updatedAppointment.members && updatedAppointment.members[0]?.id);

    if (buddyId) {
        const chatAppointment = convertEurekaAppointmentToChatObject({
            appointment: updatedAppointment,
            action: APPOINTMENT_STATE.DELETED,
            currentEvent,
        });

        dispatch(
            sendChatNotification({
                userId: buddyId,
                appointment: chatAppointment,
                action: APPOINTMENT_STATE.DELETED,
            }),
        );
    }
    eventBus.emit('refreshAppointments');
};
