import styled from 'styled-components';
import { ListItem } from 'react-md';

export const StyledListItem = styled(ListItem)`
    .md-tile-text--primary {
        font-size: 15px;
        font-family: Cabin, sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
        color: rgba(30, 30, 31, 0.87);
        ${props => props.maxwidth && `max-width: ${props.maxwidth}`};
    }

    .md-tile-text--secondary {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.57);
    }

    .md-avatar {
        width: 57px;
        height: 57px;
    }

    .md-tile-addon--avatar {
        height: 57px;
    }

    .md-list-tile--two-lines {
        height: ${props => props.height || '72px'};
    }

    .md-list-tile {
        margin: 0 8px;
    }

    .md-list-tile--active {
        background: #f5f5f5;
        border-radius: 8px;
    }

    list-style-type: none !important;
`;
