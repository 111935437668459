import React, { useContext, useEffect, useState } from 'react';

import { Link, withRouter } from 'react-router-dom';
import { List } from 'react-md';
import styled from 'styled-components';
import ObjectListItem from '../../components/ObjectListItem';
import ThemeContext from '../../components/Theme/ThemeContext';
import { getString } from '../../services/api/store';
import moment from 'moment';
import {
    getDateWithLocale,
    getFormatTime,
    getItemAsync,
    makeItemAsync,
} from '../../services/api/data';
import { TimezoneContext } from '../../scenes/Timezone/context';
import sortBy from 'lodash/sortBy';
import Auth from '../../services/api/auth';

const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.87);
    font-size: 17px;
    line-height: 24px;
    margin: 20px 0 8px 16px;
    font-family: 'Cabin', sans-serif;
`;
const ListStyled = styled(List)`
    padding-bottom: 0px;
    padding-top: 0px;
    ${props =>
        props.flex &&
        `
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px !important;
  `};
`;
const NoLink = styled.div`
    padding-bottom: 0px;
    padding-top: 0px;
`;

const LinkStyled = styled(Link)`
    text-decoration: none !important;

    .md-fake-btn {
        ${props => props.selectedItem && 'background: #EBEDF0 !important'};
    }
`;
const AStyled = styled.a`
    text-decoration: none !important;
`;

const ListItem = ({ item, sectionType, displayIconsForLinks }) => (
    <span>
        <ObjectListItem
            item={item}
            type={sectionType}
            key={`${sectionType}_${item.id}`}
            displayIconsForLinks={displayIconsForLinks}
        />
    </span>
);

function Section(props) {
    const [displayIconsForLinks, setDisplayIconsForLinks] = useState(false);
    const [items, setItems] = useState(props.items || []);

    const sectionType = props.type;
    const url = props.matchUrl;
    const timeArray = [];
    const { timeslotToTimezone } = useContext(TimezoneContext);
    const isAuthenticated = Auth.isUserAuthenticated();

    useEffect(() => {
        (async () => {
            if (sectionType === 'institution') {
                let itemsWithBooth = items;
                if (props.items && props.items.length > 0) {
                    itemsWithBooth = await Promise.all(
                        items.map(async item => {
                            const institutionObject = await makeItemAsync(item.id, sectionType);
                            return {
                                ...item,
                                showClickBooth: institutionObject?.typeParams?.showClickBooth,
                            };
                        }),
                    );
                    setItems(itemsWithBooth);
                }
            }
            if (sectionType === 'link') {
                if (props.items && props.items.length > 0) {
                    const linkObject = await getItemAsync('types', props.items[0].type);
                    setDisplayIconsForLinks(
                        linkObject && linkObject.params && linkObject.params.displayIcons,
                    );
                }
            }
        })();
    }, []);
    const orderingString =
        (items && items[0] && Object.keys(items[0]).find(key => key.startsWith('order'))) ||
        'ordering';
    const renderListItems = embed =>
        items
            ? sortBy(items, [orderingString]).map(function (it, index) {
                  const item = timeslotToTimezone(it);
                  let LinkRouter = LinkStyled;
                  let linkTo = `${url}/${sectionType}/${item.id}`;
                  if (sectionType === 'place') {
                      if (item.floorplan && !embed) {
                          linkTo = `/floorplan/${item.floorplan}/${item.id}`;
                      } else {
                          LinkRouter = NoLink;
                          linkTo = '';
                      }
                  } else if (sectionType === 'person' && item.eurekaId && isAuthenticated) {
                      linkTo = `${url}/profile/${item.eurekaId}/${item.id}`;
                  }
                  if (item.showClickBooth) {
                      linkTo = `/exhibitorbooth/${item.id}`;
                  }
                  let start = moment.utc(item.start);
                  if (!start.isValid()) {
                      start = moment.unix(item.start);
                  }
                  const timeFormat = getFormatTime();
                  const timetoDisplay = moment(start).format(timeFormat);
                  const dateSettings = getString('datetime');
                  const datePattern =
                      dateSettings && dateSettings.dayMonthDateFormat
                          ? dateSettings.dayMonthDateFormat
                          : 'MMMM D';
                  const day = getDateWithLocale(start);
                  const dateToDisplay = day.format(datePattern);

                  item.dateTime = {
                      startTimeToDisplay: timetoDisplay,
                      startDateToDisplay: dateToDisplay,
                  };
                  timeArray.push({ dateTime: item.dateTime });

                  return (
                      <div key={index}>
                          {sectionType !== 'link' && (
                              <LinkRouter
                                  key={'no_link_' + index}
                                  to={{
                                      pathname: linkTo,
                                      state: { prevPath: props.prevPath },
                                  }}
                                  selectedItem={props.location.pathname.includes(item.id)}
                              >
                                  <ListItem sectionType={sectionType} item={item} />
                              </LinkRouter>
                          )}
                          {sectionType === 'link' && (
                              <AStyled
                                  key={'link_' + index}
                                  target="_blank"
                                  href={item.url ? item.url.replace('mailto://', 'mailto:') : '#'}
                              >
                                  <ListItem
                                      sectionType={sectionType}
                                      item={item}
                                      displayIconsForLinks={displayIconsForLinks}
                                  />
                              </AStyled>
                          )}
                      </div>
                  );
              })
            : [];

    return (
        <div>
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <div>
                        {!!props.title && (
                            <SectionTitle secondary={theme.secondary}>{props.title}</SectionTitle>
                        )}
                        <ListStyled flex={props.type === 'classifier'}>
                            {renderListItems(theme.embed)}
                        </ListStyled>
                    </div>
                )}
            </ThemeContext.Consumer>
        </div>
    );
}

export default withRouter(Section);
