import React, { useMemo, useRef } from 'react';
import get from 'lodash/get';
import { useGlobalState } from '../../../../../utils/container';
import StreamPlayer from '../../common/StreamPlayer';
import styled, { css } from 'styled-components';
import ActivePresenter from './ActivePresenter';
import entities from '../../../constants/entities';
import useResize from '../../../hooks/useResize';
import { withRouter } from 'react-router-dom';
import cameraOffImage from '../../../../../assets/images/camera-off-02.png';
import { UserWrapperNoVideo } from '../../../styles';
import { FIFTY_MILL, TEN_MILL, THIRTY_MILL } from '../../../constants/values';

const MainVideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 20;
`;

const Img = styled.img`
    max-height: 100%;
    max-width: 100%;
    display: block;
`;

const ImageWrapper = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: center;
    max-height: calc(100vh - 100px);
    height: 100%;
`;

const Wrapper = styled.div`
    height: 100%;
    position: relative;
    ${props =>
        !!props.zerominmaxheight &&
        `
        max-height: 0 !important;
        min-height: 0 !important;
        `}
    ${props =>
        !props.isShare &&
        css`
            margin-bottom: 8px;
        `}

    .stream-player.video-on {
        height: 100%;
        width: 100%;

        [id*='player'] {
            background: transparent !important;
            border-radius: 8px;
        }

        video {
            background: #fff;

            /* This used to work for the parent element of button divs */
            /* But it does not work with newer browsers, the below doesn't hide the play button parent div */
            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
            }

            /* Old shadow dom for play button */

            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
            }

            /* New shadow dom for play button */

            /* This one works! */

            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
            }
        }
    }
`;

const HostWrapperNoVideo = styled(UserWrapperNoVideo)`
    z-index: 10;
    border-radius: 8px;
`;

const Video = ({ right, ...props }) => {
    const stateCtx = useGlobalState();
    const { peers, streams, virtualEventUser, virtualEventSession } = stateCtx;
    const { activeVideoId } = virtualEventSession;

    const isVideoMode =
        (get(virtualEventUser, 'mode') || 'video') === entities.virtualEventUserEntity.mode.video;
    const isRoundTable =
        get(virtualEventSession, 'roomType') ===
        entities.virtualEventSessionEntity.roomType.roundTable;
    const isShare = useRef(false);

    const streamToUse = useMemo(() => {
        const hasVideoActive = get(virtualEventUser, 'hasVideoActive');
        let streamId = get(virtualEventUser, 'UserId', 0);

        if (hasVideoActive) {
            streamId += THIRTY_MILL;
        } else if (activeVideoId) {
            streamId = virtualEventSession.id;
            streamId += FIFTY_MILL;
        } else {
            streamId += TEN_MILL;
        }

        return (streams || []).find(
            stream =>
                virtualEventSession.status === 'broadcasting' &&
                stream &&
                (virtualEventUser || activeVideoId) &&
                stream.streamId === streamId,
        );
    }, [get(virtualEventSession, 'status'), virtualEventUser, streams, activeVideoId]);

    //Host stream. virtualEventUser is the active user
    const activeStream = useMemo(() => {
        if (!streams.length || !virtualEventUser) {
            return null;
        }

        const shareId = virtualEventUser.UserId + 10000000;
        const streamId = virtualEventUser.UserId;

        const foundStreams = (streams || []).reduce(
            (obj, stream) => {
                if (get(stream, 'streamId') === streamId) {
                    obj.video = stream;
                }

                if (get(stream, 'streamId') === shareId) {
                    obj.share = stream;
                }
                return obj;
            },
            { share: null, video: null },
        );

        let foundStream = foundStreams.video;

        isShare.current = false;

        if (!right && isVideoMode && foundStreams.share) {
            foundStream = foundStreams.share;
            isShare.current = true;
        }

        if (foundStream) {
            foundStream.virtualEventUser = virtualEventUser;
            return foundStream;
        } else {
            return null;
        }
    }, [virtualEventUser, peers, streams, isVideoMode, isRoundTable]);

    const placeHolderUrl = '/images/slide-placeholder.png';
    const notBroadcasting = get(virtualEventSession, 'status') !== 'broadcasting';
    const showPlaceHolder =
        !!streamToUse && !(virtualEventUser.isMicrophoneOn || virtualEventUser.isVideoOn);
    const mainVideoWrapper = useResize();

    const toggleUserProfile = id => {
        const { history, location } = props;

        if (location.pathname.includes('profile')) {
            return history.push({
                pathname: `${id}`,
                state: {
                    timeslot: props.timeslot,
                },
            });
        } else {
            return history.push({
                pathname: `${location.pathname}/profile/${id}`,
                state: {
                    timeslot: props.timeslot,
                },
            });
        }
    };

    return (
        <Wrapper
            innerRef={!isShare.current ? mainVideoWrapper : undefined}
            isShare={isShare.current}
            zerominmaxheight={!streamToUse ? 1 : 0}
        >
            {notBroadcasting && (
                <ImageWrapper>
                    <Img alt="" src={placeHolderUrl} />
                </ImageWrapper>
            )}
            {showPlaceHolder && <HostWrapperNoVideo src={cameraOffImage} />}
            {activeStream && (
                <MainVideoWrapper>
                    <StreamPlayer
                        stream={activeStream}
                        isVideoOn={true}
                        uid={activeStream.getId()}
                        domId={`stream-player-${activeStream.getId()}`}
                    >
                        {activeStream.virtualEventUser && (
                            <ActivePresenter
                                activeStream={activeStream}
                                toggleUserProfile={toggleUserProfile}
                            />
                        )}
                    </StreamPlayer>
                </MainVideoWrapper>
            )}
        </Wrapper>
    );
};

export default withRouter(Video);
