import React, { useEffect, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import { TOAST_TYPES } from './toasts';

StreamPlayer.propTypes = {
    stream: PropTypes.object,
};
const Container = styled.div`
    position: relative;
    ${props =>
        props.maxwidthandheight &&
        `
        height: 100%;
        width: 100%;
    `}

    &.video-on,
    [id*='player'] {
        min-height: inherit;
        background-color: #fff !important;
    }

    video {
        display: block !important;
        ${props =>
            props.containContainer &&
            `
            object-fit: contain !important;
            background: #000 !important;
            `};
    }
`;

function StreamPlayer(props) {
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();

    const { subscribeTimestamp } = stateCtx;
    const { stream, domId, isVideoOn, muted, roundTableNoSlides } = props;
    const containerRef = useRef(null);
    const isPlaying = useRef(false);

    AgoraRTC.onAudioAutoplayFailed = () => {
        mutationCtx.setShowToast(TOAST_TYPES.AUDIO);

        const resumeHandler = () => {
            try {
                stream.resume();
            } catch (err) {
                //
            }

            mutationCtx.setShowToast(false);
            window.removeEventListener('click', resumeHandler);
        };

        window.addEventListener('click', resumeHandler);
    };

    useEffect(() => {
        const playStream = () => {
            isPlaying.current = true;
            stream.play({
                domId,
                muted,
            });

            if (containerRef.current) {
                const video = containerRef.current.querySelector('video');

                if (video) {
                    video.removeAttribute('controls');
                }
            }
        };
        if (!stream) return;

        playStream();
    }, [
        subscribeTimestamp,
        get(stream, 'user.audioTrack'),
        get(stream, 'user.videoTrack'),
        domId,
        muted,
    ]);

    useEffect(() => {
        return () => {
            if (stream.isPlaying()) {
                stream.stop();
                isPlaying.current = false;
            }
        };
    }, []);

    return (
        <Container
            innerRef={containerRef}
            className={`stream-player ${isVideoOn ? 'video-on' : ''}`}
            id={domId}
            data-qa={props['data-qa']}
            containContainer={
                props.location.pathname && props.location.pathname.includes('virtual-session-room')
            }
            maxwidthandheight={roundTableNoSlides ? 1 : 0}
        >
            {props.children}
        </Container>
    );
}

export default withRouter(StreamPlayer);
