import React, { useEffect, useState } from 'react';
import async from 'async';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import get from 'lodash/get';

import ConversationListItem from '../../components/ObjectListItem/Conversation/';
import {
    loadGroupConversations as loadGroupConversationsAction,
    leaveRooms as leaveAllRoomsAction,
    setStatus as setStatusAction,
} from '../Talk/actions';
import {
    getParticipatingConversations,
    getIsLoadingConversations,
    getServerOnline,
} from '../Talk/selectors';
import { getObjectClassWithId } from '../../services/api/db';
import eventBus from '../../utils/eventBus';
import Loader from '../../components/General/Loader';
import Placeholder from './placeholder';
import { getString } from '../../services/api/store';
import NotificationButton from '../Notifications/components/NotificationButton';
import NotificationContentHeader from '../Notifications/components/NotificationContentHeader';

const LinkStyled = styled(Link)`
    text-decoration: none !important;
    list-style: none;
`;
const MyConversations = ({
    loadGroupConversations,
    conversations,
    leaveAllRooms,
    loading,
    setStatus,
    chatServerOnline,
    location,
    history,
    match,
    light = false,
}) => {
    const TEXT_REPRESENTATIVE_ROLE = getString('representativeRole') || 'Representative of';
    const CONVERSATIONS_TEXT = getString('talkWindowTitle', 'Conversations');

    const [visible, setVisible] = useState(false);

    const [extendedConversations, setExtendedConversations] = useState([]);
    const [extendedConversationsLoaded, setExtendedConversationsLoaded] = useState(false);
    const [loadRequested, setLoadRequested] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const makeVisible = () => setVisible(true);

    useEffect(() => {
        eventBus.on('openChatNotifications', makeVisible);

        return () => eventBus.removeListener('openChatNotifications', makeVisible);
    }, []);

    useEffect(() => {
        if (chatServerOnline) {
            setStatus(true);
            getGroupConversations();
        }

        // leave joined rooms on unmount
        return () => {
            leaveAllRooms();
        };
    }, [chatServerOnline]);

    const getGroupConversations = async () => {
        loadGroupConversations();
        setLoadRequested(true);
    };

    useEffect(() => {
        if (loadRequested === true && loading === false) {
            if (conversations && conversations.length) {
                // Gonna extend conversations with their objects props
                const extendedItems = [];
                async.eachSeries(
                    conversations,
                    (item, cb) => {
                        if (item.kind === 'chat') {
                            extendedItems.push(item);
                            cb();
                        }
                        if (item.roomId) {
                            // Filters out group chats where the linked object is not on the event release anymore
                            getObjectClassWithId(item.roomId, (err, objectClass, objectItem) => {
                                if (!err) {
                                    extendedItems.push({
                                        ...item,
                                        externalObjectClass: objectClass,
                                        externalObject: objectItem,
                                    });
                                }
                                cb();
                            });
                        }
                    },
                    err => {
                        if (!err) {
                            setExtendedConversations(extendedItems);
                            setExtendedConversationsLoaded(true);
                        }
                    },
                );
            } else {
                setExtendedConversationsLoaded(true);
            }
            eventBus.emit('updateConversationsCount');
        }
    }, [conversations, loading]);

    useEffect(() => {
        const { pathname } = location;
        const chatRegex = /\/(privateChat|talkRoom)\/[0-9a-z]+($|\/)/;
        const isChatOpened = pathname.match(chatRegex);

        // If a private or group chat is already opened, we have to close it when open the chat notifications
        if (visible && isChatOpened) {
            let newUrl = pathname.replace(chatRegex, '/');
            if (newUrl[newUrl.length - 1] === '/') {
                newUrl = newUrl.substr(0, newUrl.length - 1);
            }

            history.push(newUrl);
        }
    }, [visible]);

    const onVisibilityChange = newVisible => setVisible(newVisible);

    const sortConversationsByDate = conversations =>
        conversations.sort((x, y) => y.lastMessage.timestamp - x.lastMessage.timestamp);

    const filterConversationsByText = (conversations, text) =>
        conversations.filter(
            conversation =>
                conversation.title &&
                conversation.title.toLowerCase().includes(text.toLowerCase().trim()),
        );

    const ChatLink = ({ isPrivate = false, conversation, item }) => {
        const urlRegex = /\/(privateChat|talkRoom|appointment)\/[0-9a-z]+.*/;
        const { pathname: currentPathname } = location;
        const chatOrAppointmentAlreadyOpened = currentPathname.match(urlRegex);
        let pathname;
        const chatUrlSegment = isPrivate ? `privateChat/${conversation.id}` : `talkRoom/${item.id}`;

        if (chatOrAppointmentAlreadyOpened) {
            pathname = currentPathname.replace(urlRegex, `/${chatUrlSegment}`);
        } else {
            pathname = `${currentPathname}/${chatUrlSegment}`;
        }

        const state = isPrivate ? { conversation } : { item };
        state.openedFromNotificationCenter = true;

        return (
            <LinkStyled key={conversation.id} to={{ pathname, state }}>
                <ConversationListItem conversation={conversation} />
            </LinkStyled>
        );
    };

    const renderConversation = conversation => {
        if (conversation.kind === 'chat') {
            return (
                <ChatLink
                    key={`private_${conversation.id}`}
                    isPrivate
                    conversation={conversation}
                />
            );
        }

        if (!conversation.lastMessage) {
            return;
        }

        const representativesList = get(conversation, 'externalObject.params.representatives', []);
        let representatives = [];
        if (representativesList.length) {
            representatives = representativesList.map(user => ({
                id: user.userId,
                title: `${TEXT_REPRESENTATIVE_ROLE} ${conversation.externalObject.name}`,
            }));
        }

        const item = {
            id: conversation.roomId,
            name: conversation.title,
            objectClass: conversation.externalObjectClass || 'webapp_group_chat',
            type: conversation.externalObjectClass || 'webapp_group_chat',
            representatives,
        };

        return (
            <ChatLink key={`group_${conversation.id}`} conversation={conversation} item={item} />
        );
    };

    const getMenuItems = () => {
        if (loading || !extendedConversationsLoaded) {
            return [<Loader key={'loader'} />];
        }

        if (extendedConversations.length === 0) {
            return [<Placeholder key={'placeholder'} />];
        }

        const filteredConversations = filterConversationsByText(extendedConversations, searchValue);
        const sortedConversations = sortConversationsByDate(filteredConversations);
        const conversationItems = sortedConversations.map(renderConversation);

        return [
            <NotificationContentHeader
                key="conversations-content-header"
                title={CONVERSATIONS_TEXT}
                displayClearAll={false}
                hasSearch={true}
                onSearchChange={text => setSearchValue(text)}
                searchValue={searchValue}
                onMenuClose={() => setVisible(false)}
            />,
            ...conversationItems,
        ];
    };

    const getUnreadCount = () => {
        const { pathname } = location;
        const isVirtualSessionRoom = pathname.includes('/virtual-session');
        const isRoundTableRoom = pathname.includes('/virtual-session-round');
        let currentSessionId = null;

        if (isVirtualSessionRoom || isRoundTableRoom) {
            currentSessionId = get(match, 'params.timeslotId', null);
        }

        return (extendedConversations || []).reduce((acc, conversation) => {
            const conversationSessionId = get(conversation, 'externalObject.id', null);

            // If the user is visiting a session's virtual room,
            // do not increase unread messages count for that session
            if (
                conversationSessionId &&
                currentSessionId &&
                conversationSessionId === currentSessionId
            ) {
                return acc;
            }

            return acc + conversation.unreadCount;
        }, 0);
    };

    const unreadCount = getUnreadCount();

    return (
        <NotificationButton
            icon={'forum'}
            items={getMenuItems()}
            unreadNotificationsCount={unreadCount}
            visible={visible}
            onVisibilityChange={onVisibilityChange}
            light={light}
        />
    );
};

const mapStateToProps = state => ({
    conversations: getParticipatingConversations(state),
    loading: getIsLoadingConversations(state),
    chatServerOnline: getServerOnline(state),
});

export default withRouter(
    connect(mapStateToProps, {
        loadGroupConversations: loadGroupConversationsAction,
        leaveAllRooms: leaveAllRoomsAction,
        setStatus: setStatusAction,
    })(MyConversations),
);
