import React from 'react';
import useResizeListener from './useResizeListener';

const useResize = (props, shouldNotResize) => {
    const handleResize = wrapper => {
        if (!wrapper) {
            return;
        }

        if (shouldNotResize) {
            wrapper.style.height = 'inherit';
            wrapper.style.minHeight = 'inherit';
            wrapper.style.maxHeight = 'inherit';
            return;
        }

        const aspectRatio = (props && props.ratio) || 16 / 9;
        const height = wrapper.offsetWidth / aspectRatio;

        // doing the resizing changes
        if (height) {
            wrapper.style.height = `${height}px`;
            wrapper.style.minHeight = `${height}px`;
            wrapper.style.maxHeight = `${height}px`;
        }
    };

    return useResizeListener(handleResize, shouldNotResize);
};

export default useResize;
