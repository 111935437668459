import async from 'async';
import axios from 'axios';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import Cookies from 'js-cookie';
import AnalyticsService from '../../features/analytics/services';
import { EVENT_NOT_FOUND_MESSAGE, EVENT_NOT_CONFIGURED_MESSAGE } from '../../App';
import { USE_CONFIGURATOR, USE_LOCAL } from '../../config';
import { addLocalItem, getLocalAppState, getLocalItem } from './db';
import { clearLocalStorage } from './graphQlCache';
import { executeQuery } from './graphQlRepository';
import * as eureka from './eureka';

async function getConfigurations(eventId, next) {
    const data = {};
    const result = await executeQuery('getConfigurationsWithEvent', {
        event: eventId,
    });

    if (!(result && result.length)) {
        return next('Did not find configurations');
    }
    each(result, function (configuration) {
        if (configuration.name === 'style.json') {
            let styles = configuration.value;

            if (styles[eventId] && styles[eventId].style) {
                let style = { name: styles[eventId].style.name };
                if (styles[eventId].style.all && styles[eventId].style.all.color) {
                    style.colors = styles[eventId].style.all.color;
                }
                data.style = style;
            }
        }
        if (configuration.name === 'arguments.json') {
            let args = configuration.value;
            if (args[eventId] && args[eventId].argument) {
                let argument = {};
                if (args[eventId].argument.all) {
                    argument = args[eventId].argument.all;
                }

                argument.name = args[eventId].argument.name;
                data.settings = argument;
            }
        }
        if (configuration.name === 'strings.json') {
            let json = configuration.value;
            if (json[eventId] && json[eventId].string) {
                let strings = {};
                if (json[eventId].string.all) {
                    strings = json[eventId].string.all;
                }

                strings.name = json[eventId].string.name;
                data.strings = strings;
            }
        }
    });
    next(null, data);
}

const getSocietyInfo = function (config, next) {
    const options = {};
    const url = config.configuratorUrl + '/api/events/societyId';
    axios
        .get(url)
        .then(function (response) {
            next(null, response);
        })
        .catch(function (ex) {
            console.log('parsing failed', ex);
            next(ex);
        });
};

const getEventData = function (config, next) {
    const url = config.configuratorUrl + '/api/events/latest/grid';
    axios
        .get(url)
        .then(function (response) {
            next(null, response);
        })
        .catch(function (ex) {
            console.log('parsing failed', ex);
            next(ex);
        });
};

async function loadAppState(config, next) {
    try {
        const [domain] = window.location.hostname.split('.');
        const result = await executeQuery('getAppStatesWithDomain', { domain }, true);

        if (
            result.error &&
            result.error.message &&
            result.error.message === 'Request failed with status code 401'
        ) {
            const retry = Cookies.get('retryNonAuthenticatedGraphQl');
            if (!retry || retry !== 'true') {
                Cookies.set('retryNonAuthenticatedGraphQl', 'true', { expires: 1 });
                navigator.serviceWorker.getRegistrations().then(registrations => {
                    registrations.forEach(registration => {
                        registration.unregister();
                    });
                });
                caches.keys().then(keyList => {
                    return Promise.all(
                        keyList.map(key => {
                            return caches.delete(key);
                        }),
                    );
                });
                setTimeout(() => {
                    window.location.reload(true);
                }, 1000);
            }
        }

        if (isArray(result)) {
            [config] = result;
        }
    } catch (e) {
        return next(e);
    }

    if (!(config && config.eventId && config.navigation)) {
        next(EVENT_NOT_FOUND_MESSAGE);
    } else if (config.navigation.length === 0) {
        next(EVENT_NOT_CONFIGURED_MESSAGE);
    } else {
        getLocalItem('appState', 'config', (err, item) => {
            if (
                err ||
                !item ||
                item.eventId !== config.eventId ||
                item.releaseNum !== config.releaseNum
            ) {
                // Log the message why the update is happening
                const message = err ? 'Error accessing local data' : 'New release!';
                // Invalidate local cache
                clearLocalStorage();
            }
            // Update society info
            getSocietyInfo(config, async (err, response) => {
                const result = response && response.data ? response.data : null;
                if (!config.societyId) {
                    config.societyId = result && result.societyId ? result.societyId : 'legacy';
                }
                if (
                    result &&
                    result.loginSettings &&
                    result.loginSettings.type &&
                    result.loginSettings.type === 'custom'
                ) {
                    config.externalEurekaLogin =
                        result.loginSettings.externalLoginSettings.externalEurekaLogin;
                    config.signInButtonTitle =
                        result.loginSettings.externalLoginSettings.signInButtonTitle;
                    config.externalLoginOptions =
                        result.loginSettings.externalLoginSettings.externalLoginOptions;
                } else {
                    config.externalEurekaLogin = null;
                    config.signInButtonTitle = null;
                    config.externalLoginOptions = null;
                }

                if (result) {
                    config.oneSignalAppId = result.oneSignalAppId;
                    config.oneSignalSafariWebId = result.oneSignalSafariWebId;
                    config.branchIoDomain = result.branchIoDomain;
                }
                if (result.floqHost) {
                    config.floqHost = result.floqHost;
                }

                // setup analytics
                config.installationId = await AnalyticsService.setup(
                    config.configuratorUrl,
                    config.id,
                );

                //save the appstate so far, and then try to get the latest grid
                addLocalItem('appState', 'config', config, function (err) {
                    getEventData(config, (err, response) => {
                        if (err || !response || !response.data) {
                            return next(null);
                        }

                        const eventData = response.data.find(
                            d => d.type === 'event' && d.id === config.id,
                        );

                        config.eurekaOnly = eventData.eurekaOnly;
                        config.attendingOnly = eventData.attendingOnly;
                        config.hiddenPassword = eventData.hiddenPassword;
                        config.start = eventData.start;
                        config.end = eventData.end;
                        config.eventLogo = eventData.icon;
                        config.uniqueName = eventData.uniqueName;

                        if (USE_LOCAL && USE_CONFIGURATOR) {
                            config.configuratorUrl = 'http://virtual-test.localhost:3001';
                        }

                        // if the latest grid was found, update the config and save it
                        addLocalItem('appState', 'config', config, function (err) {
                            if (err) {
                                next(err);
                            } else {
                                next(null);
                            }
                        });
                    });
                });
            });
        });
    }
}

function updateApp(next) {
    let config = {};
    async.waterfall(
        [
            function (callback) {
                loadAppState(config, callback);
            },
            function (callback) {
                eureka.sync(err => {
                    callback(err);
                });
            },
        ],
        next,
    );
}

export { updateApp, getLocalAppState, getConfigurations };
