import React, { memo } from 'react';
import Auth from '../../services/api/auth';
import { InteractivityContext } from '../Interactivity';

const AuthenticationProtection = ({ children }) => {
    const isAuthenticated = Auth.isUserAuthenticated();

    if (!isAuthenticated) {
        return (
            <InteractivityContext.Consumer>
                {({ showSignIn }) => <>{showSignIn()}</>}
            </InteractivityContext.Consumer>
        );
    }

    return children;
};

export default memo(AuthenticationProtection);
