import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Divider, ListItem, Button, FontIcon } from 'react-md';
import { getString } from '../../../services/api/store';

import { selectPostById, selectLikeUsersByPostId } from '../selectors';
import {
    addLike as addLikeAction,
    removeLike as removeLikeAction,
    removePost as removePostAction,
    reportPost as reportPostAction,
} from '../actions';
import ImageAvatar from '../../../components/Icons/ImageAvatar';
import Auth from '../../../services/api/auth';
import Dropdown from '../../../components/General/Dropdown';
import PostImagePreview from './PostImagePreview';
import PostDetail from '../containers/PostDetail';
import { getLocalTimeAgo } from '../common';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import UsersModal from './UsersModal';
import AuthenticationProtection from '../../../components/ProtectedPages/AuthenticationProtection';
import AnalyticsService from '../../../features/analytics/services';
import { InteractivityContext } from '../../../components/Interactivity';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';

const Container = styled.div`
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin: 24px 0;
    background-color: #f5f5f5;
`;

const PostHeader = styled.div`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledListItem = styled(ListItem)`
    list-style-type: none;
`;

const TextContainer = styled.div`
    padding: 16px;
`;

const DividerStyled = styled(Divider)`
    margin: 0px !important;
`;

const StatusContainer = styled.div`
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
`;

const StyledButton = styled(Button)`
    color: #000000;
`;

const FontIconWrapper = styled.div`
    height: fit-content;

    .md-icon {
        color: ${props => props.color || 'inherit'};
    }
`;

const LikesContainer = styled.div`
    color: #000000;
    display: flex;
    padding: 8px;
    cursor: pointer;

    .md-icon {
        color: #000000;
    }
`;

const LikesLabel = styled.div`
    margin-left: 2px;
`;

const OPTION_TYPES = Object.freeze({
    EDIT: 'edit',
    DELETE: 'delete',
});

const Post = ({
    post,
    likesInfo,
    addLike,
    removeLike,
    removePost,
    reportPost,
    history,
    rootRoute,
}) => {
    const { users, label, yourLikeId } = likesInfo;
    const { user, time, comments, likes, id, isMine, message, image } = post;
    const usersWithDetails = users.map(user => ({
        ...user,
        name: `${user.firstName} ${user.lastName}`,
        subNameList: (
            <>
                {user.jobTitle && <div>{user.jobTitle}</div>}
                {user.companyName && <div>{user.companyName}</div>}
            </>
        ),
    }));

    const [expandComments, setExpandComments] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showUsersList, setShowUsersList] = useState(false);
    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const { trackAchievement } = useAchievementActions();

    const myDropdownOptions = [
        {
            text: getString('edit') || 'Edit',
            onClick: () => onMyOptionClick(OPTION_TYPES.EDIT),
        },
        {
            text: getString('deleteButton') || 'Delete',
            onClick: () => onMyOptionClick(OPTION_TYPES.DELETE),
        },
    ];
    const dropdownOptions = [
        {
            text: 'Report this post',
            onClick: () => onPostReport(),
        },
    ];

    const onPressLike = () => {
        if (!Auth.isUserAuthenticated()) {
            setShowLoginDialog(true);
        }

        if (yourLikeId) {
            removeLike(post, yourLikeId);
        } else {
            addLike(post);
            AnalyticsService.addSample('socialWall', null, post.id);
            trackAchievement(AchievementType.LIKE_SOCIAL_POST, post.id);
        }
    };

    const ago = getLocalTimeAgo(time);
    const isAuthenticated = Auth.isUserAuthenticated();
    const showLikes = likes.length > 0;
    const commentCount = comments.length > 0 ? ` (${comments.length})` : '';
    const commentsTitle = `Comment${commentCount}`;

    const onMyOptionClick = type => {
        switch (type) {
            case OPTION_TYPES.EDIT:
                history.push(`${rootRoute}/edit/${post.id}`);
                return;
            case OPTION_TYPES.DELETE:
                setShowDeleteConfirmation(true);
                return;
            default:
                return;
        }
    };

    const toggleComments = () => setExpandComments(!expandComments);

    const onPostReport = () => {
        reportPost(post);
    };

    const showUsers = () => setShowUsersList(true);

    const hideUsers = () => setShowUsersList(false);

    const onHeaderClick = userId => history.push(`${rootRoute}/profile/${userId}`);

    return (
        <Container>
            {showLoginDialog && (
                <InteractivityContext.Consumer>
                    {({ showSignIn }) => showSignIn()}
                </InteractivityContext.Consumer>
            )}

            <PostHeader>
                {user.id > 0 && (
                    <StyledListItem
                        primaryText={
                            <div onClick={() => onHeaderClick(user.id)}>
                                {`${user.firstName} ${user.lastName}`}
                            </div>
                        }
                        secondaryText={ago}
                        leftAvatar={
                            <div onClick={() => onHeaderClick(user.id)}>
                                <ImageAvatar imageId={user.imageUrl} avatarType={'persons'} />
                            </div>
                        }
                        rightAvatar={
                            isAuthenticated && (
                                <Dropdown items={isMine ? myDropdownOptions : dropdownOptions} />
                            )
                        }
                    />
                )}
            </PostHeader>
            {image && image.length > 0 && <PostImagePreview source={image} />}
            <TextContainer>{message}</TextContainer>
            <DividerStyled />
            <StatusContainer>
                <StyledButton
                    flat
                    iconEl={
                        <FontIconWrapper color={yourLikeId && 'red'}>
                            <FontIcon>{yourLikeId ? 'favorite' : 'favorite_outline'}</FontIcon>
                        </FontIconWrapper>
                    }
                    onClick={onPressLike}
                    iconColor={yourLikeId ? 'red' : 'inherit'}
                >
                    Like
                </StyledButton>
                <StyledButton
                    flat
                    iconEl={<FontIcon className="material-icons-outlined">message</FontIcon>}
                    onClick={toggleComments}
                >
                    {commentsTitle}
                </StyledButton>
            </StatusContainer>
            {showLikes && (
                <>
                    <DividerStyled />
                    <LikesContainer onClick={showUsers}>
                        <FontIcon>favorite</FontIcon>
                        <LikesLabel>{label}</LikesLabel>
                    </LikesContainer>
                </>
            )}

            {expandComments && (
                <AuthenticationProtection goBack={() => setExpandComments(false)}>
                    <DividerStyled />
                    <PostDetail id={id} />
                </AuthenticationProtection>
            )}

            <ConfirmDialog
                visible={showDeleteConfirmation}
                options={{
                    cancelText: 'Cancel',
                    confirmText: getString('deleteButton') || 'Delete',
                    title: 'Delete post',
                    text: 'Are you sure you want to delete your post?',
                    cancel: () => {},
                    confirm: () => removePost(post),
                }}
                onHideDialog={() => setShowDeleteConfirmation(false)}
            />

            {showUsersList && (
                <AuthenticationProtection goBack={hideUsers}>
                    <UsersModal
                        users={usersWithDetails}
                        onClose={hideUsers}
                        rootRoute={rootRoute}
                    />
                </AuthenticationProtection>
            )}
        </Container>
    );
};

Post.propTypes = {
    post: PropTypes.shape({
        user: PropTypes.shape({}),
        time: PropTypes.string,
        comments: PropTypes.arrayOf(PropTypes.number),
        likes: PropTypes.arrayOf(PropTypes.number),
        image: PropTypes.string,
        message: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isMine: PropTypes.bool,
    }),
    likesInfo: PropTypes.shape({
        users: PropTypes.arrayOf(PropTypes.shape({})),
        label: PropTypes.string,
        yourLikeId: PropTypes.number,
    }),
    addLike: PropTypes.func,
    removeLike: PropTypes.func,
    removePost: PropTypes.func,
    reportPost: PropTypes.func,
};

Post.defaultProps = {
    post: {
        user: {},
    },
    likesInfo: {},
    addLike: () => {},
    removeLike: () => {},
    removePost: () => {},
    reportPost: () => {},
};

const mapStateToProps = (state, ownProps) => ({
    post: selectPostById(ownProps.postId, state),
    likesInfo: selectLikeUsersByPostId(ownProps.postId, state),
});

export default connect(mapStateToProps, {
    addLike: addLikeAction,
    removeLike: removeLikeAction,
    removePost: removePostAction,
    reportPost: reportPostAction,
})(withRouter(Post));
