import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button, { buttonTypes } from '../../../common/Button';
import { useTheme } from '../../../../components/Theme/ThemeContext';
import { getLatestPrivacyPolicy } from '../../../../services/api/eureka';
import Loader from '../../../../components/General/Loader';
import poweredByCC from '../../../../assets/images/cc-logo.jpg';
import packageJSON from '../../../../../package.json';
import { getString } from '../../../../services/api/store';
import { Flex } from '../../../common/Flex';
import { getLocalAppStateAsync } from '../../../../services/api/db';
import { BUILD_VERSION } from '../../../../config';
import { ContentContainer, TitleText } from '../details';

const Image = styled.img`
    width: 80px;
    height: 40px;
    margin-bottom: 9px;
    margin-top: 16px;
    cursor: pointer;
`;

const VersionDetailsContainer = styled.div`
    width: 100%;
`;

const VersionDetailsText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;

    span {
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
    }
`;

const PoweredByText = styled.div`
    height: 20px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);

    a {
        cursor: pointer;
        color: #00a480;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const VersionContainer = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
    border-radius: 6px;
    border: solid 1px #ddd;
`;

const UpdateAvailableLabel = ({ theme, text }) => (
    <Button
        text={text}
        type={buttonTypes.GREEN_REVERSE}
        background={theme.primary}
        onClick={() => {}}
        style={{ height: '24px', borderRadius: '50px', padding: '0 12px', whiteSpace: 'nowrap' }}
        enabled={false}
    />
);

const PlatformInfoContent = ({
    updateAvailable = false,
    updateServiceWorker = () => {},
    waitingWorker,
}) => {
    const [loading, setLoading] = useState(false);
    const [privacyPolicyURL, setPrivacyPolicyURL] = useState('');
    const [releaseNum, setReleaseNum] = useState(null);

    const { theme } = useTheme();
    const { version: platformVersion } = packageJSON;

    const VERSION_TEXT = getString('appVersionText') || 'Platform version';
    const DATA_TEXT = getString('dataVersionText') || 'Data';
    const UPDATE_TEXT = getString('updateAvailableTitle') || 'Update available';
    const UPDATE_TEXT_BUTTON = getString('updateConfirmButton') || 'Update';

    useEffect(() => {
        (async () => {
            const appState = await getLocalAppStateAsync();
            setReleaseNum(appState.releaseNum);
        })();
    }, []);

    const getPrivacyPolicy = async () => {
        try {
            const latestPrivacyPolicy = await getLatestPrivacyPolicy();

            if (latestPrivacyPolicy) {
                setPrivacyPolicyURL(latestPrivacyPolicy.url);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getInfo = async () => {
        setLoading(true);
        await getPrivacyPolicy();
        setLoading(false);
    };

    const redirectToMobileEventPlatform = () => {
        const url = 'https://www.conferencecompass.com/mobile-event-platform/';
        const newWindow = window.open(url, '_blank', 'noopener');
        if (newWindow) {
            newWindow.opener = null;
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <ContentContainer>
            <VersionContainer>
                <TitleText>Version</TitleText>
                <VersionDetailsContainer>
                    <Flex justifyContent={'space-between'}>
                        <VersionDetailsText>
                            <Flex justifyContent={'space-between'}>
                                <div>{`${VERSION_TEXT}: ${platformVersion}`}</div>
                                {updateAvailable && waitingWorker && (
                                    <UpdateAvailableLabel theme={theme} text={UPDATE_TEXT} />
                                )}
                            </Flex>
                            {BUILD_VERSION && <span>{BUILD_VERSION}</span>}
                        </VersionDetailsText>
                    </Flex>
                    <Flex justifyContent={'space-between'} margin={'8px 0'}>
                        <VersionDetailsText>{`${DATA_TEXT}: ${releaseNum}`}</VersionDetailsText>
                        {updateAvailable && !waitingWorker && (
                            <UpdateAvailableLabel theme={theme} text={UPDATE_TEXT} />
                        )}
                    </Flex>
                </VersionDetailsContainer>
                <Flex flexdirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    {updateAvailable && updateServiceWorker && (
                        <Button
                            text={UPDATE_TEXT_BUTTON}
                            type={buttonTypes.GREEN_LONG}
                            background={theme.primary}
                            onClick={updateServiceWorker}
                        />
                    )}
                    {privacyPolicyURL && (
                        <Button
                            text="Privacy Policy"
                            type={buttonTypes.GHOST}
                            background={theme.primary}
                            onClick={() => window.open(privacyPolicyURL, '_blank')}
                            style={updateAvailable ? { marginTop: '10px' } : {}}
                        />
                    )}
                </Flex>
            </VersionContainer>
            <Image
                src={poweredByCC}
                alt="Powered by Conference Compass"
                onClick={redirectToMobileEventPlatform}
            />
            <PoweredByText>
                Powered by{' '}
                <a
                    href={'https://www.conferencecompass.com/mobile-event-platform/'}
                    target={'_blank'}
                    rel="noreferrer"
                >
                    Conference Compass
                </a>
            </PoweredByText>
        </ContentContainer>
    );
};

export default PlatformInfoContent;
