import React from 'react';
import styled from "styled-components";
import {DialogContainer, FontIcon} from "react-md";
import UserAvatar from "../../../components/General/UserAvatar";
import Auth from "../../../services/api/auth";
import {useTheme} from "../../../components/Theme/ThemeContext";
import {useSelector} from "react-redux";
import {getNumberOfCompletedAchievements, getNumberOfRewardsClaimed, getTotalPoints} from "../selectors";
import {Flex} from "../../VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers";

const StyledDialog = styled(DialogContainer)`
    .md-paper {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        box-shadow: unset;
    }
`;

const Container = styled.div`
    width: 401px;
    padding: 24px 24px 32px 24px;
    border-radius: 16px;
    box-shadow: 0 16px 40px 0 rgba(28, 29, 29, 0.35);
    background-color: #fff;
`;

const CenteredSection = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: #000;
`;

const UserAndScoreWrapper = styled.div`
    margin: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ScoreLabel = styled.div`
    background-color: ${props => props.color};
    width: 52px;
    height: 32px;
    border-radius: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
    line-height: 28px;
    color: #fff;
    vertical-align: middle;
    border: 2px solid #fff;
    margin-left: -10px;
`;

const Icon = styled(FontIcon)`
    margin-right: 16px;
    opacity: 0.54;
    color: #000;
`;

const AchievementText = styled.div`
    opacity: 0.54;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 1.33;
    color: #000;
`;

const CountLabel = styled.div`
    width: 32px;
    height: 32px;
    background-color: #ebedf0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
`;

const FlexWithMargins = styled(Flex)`
    margin-bottom: 16px;
`;

const MyScoreDetails = ({ onHide }) => {
    const user = Auth.getUser();
    const { theme } = useTheme();
    const totalPoints = useSelector(getTotalPoints);
    const numberOfCompletedAchievements = useSelector(getNumberOfCompletedAchievements);
    const numberOfRewardsClaimed = useSelector(getNumberOfRewardsClaimed);

    return (
        <StyledDialog
            id="achievement-modal"
            visible={true}
            onHide={onHide}
            titleStyle={{
                backgroundColor: 'transparent',
            }}
            initialFocus="#my-score-modal-content"
        >
            <Container id="my-score-modal-content">
                <CenteredSection>
                    <Title>My score</Title>
                    <UserAndScoreWrapper>
                        <UserAvatar user={user} size={50} />
                        <ScoreLabel color={theme.contrast}>
                            {totalPoints}
                        </ScoreLabel>
                    </UserAndScoreWrapper>
                </CenteredSection>
                <FlexWithMargins justifycontent="space-between" alignItems="center">
                    <Flex>
                        <Icon iconClassName="material-icons-outlined">emoji_events</Icon>
                        <AchievementText>Achievements completed</AchievementText>
                    </Flex>
                    <CountLabel>{numberOfCompletedAchievements}</CountLabel>
                </FlexWithMargins>
                <Flex justifycontent="space-between" alignItems="center">
                    <Flex>
                        <Icon iconClassName="material-icons-outlined">flag</Icon>
                        <AchievementText>Rewards claimed</AchievementText>
                    </Flex>
                    <CountLabel>{numberOfRewardsClaimed}</CountLabel>
                </Flex>
            </Container>
        </StyledDialog>
    );
};

export default MyScoreDetails;