import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { defaultState, reducer, volumeReducer } from './store';
import functionForDevices from './useDevices';

const StateContext = createContext({});
const VolumeContext = createContext({});
const MutationContext = createContext({});

export const ContainerProvider = ({ children, eventId }) => {
    const newState = {
        ...cloneDeep(defaultState),
        socket: defaultState.socket,
        eventId,
    };
    const [state, dispatch] = useReducer(reducer, newState);
    const [volumeState, volumeDispatch] = useReducer(volumeReducer, defaultState);

    window.rootState = state;
    window.volumeState = volumeState;

    const [toasts, updateToasts] = useState([]);

    const methods = {
        addLocal(evt) {
            const { stream } = evt;
            methods.setLocalStream(stream);
            methods.setCurrentStream(stream);
        },
        addPeer(evt) {
            const { uid } = evt;
            dispatch({
                type: 'addPeer',
                payload: uid,
            });
        },
        addStream(evt) {
            const { stream } = evt;
            dispatch({
                type: 'addStream',
                payload: stream,
            });
        },
        changeCamera(cameraId) {
            methods.updateConfig({ cameraId });
            methods.setRecreateStreams(true);
        },
        changeMicrophone(microphoneId) {
            methods.updateConfig({ microphoneId });
            methods.setRecreateStreams(true);
        },
        clearAllStream() {
            dispatch({ type: 'clearAllStream' });
        },
        connectionStateChanged(evt) {
            methods.toastInfo(`${evt.curState}`);
        },
        removePeer(evt) {
            const { uid, reason } = evt;

            if (reason !== 'BecomeAudience') {
                dispatch({
                    type: 'removePeer',
                    payload: uid,
                });
            }
        },
        removeStream(param) {
            dispatch({ type: 'removeStream', user: param });
        },
        removeStreamById(param) {
            dispatch({ type: 'removeStream', user: param });
        },
        setCanJoin(param) {
            dispatch({
                type: 'canJoin',
                payload: param,
            });
        },
        setCurrentStream(param) {
            dispatch({
                type: 'currentStream',
                payload: param,
            });
        },
        setCurrentVirtualEventUser(param) {
            dispatch({
                type: 'currentVirtualEventUser',
                payload: param,
            });
        },
        setEurekaChatUrl(param) {
            dispatch({
                type: 'eurekaChatUrl',
                payload: param,
            });
        },
        setExtraState(param) {
            dispatch({
                type: 'extraState',
                payload: param,
            });
        },
        setLocalClient(param) {
            dispatch({
                type: 'localClient',
                payload: param,
            });
        },
        setLocalStream(param) {
            dispatch({
                type: 'localStream',
                payload: param,
            });
        },
        setPlayedSeconds(param) {
            dispatch({
                type: 'playedSeconds',
                payload: param,
            });
        },
        setPollSets(param) {
            dispatch({
                type: 'pollSets',
                payload: param,
            });
        },
        setPrimaryPlayerRef(param) {
            dispatch({
                type: 'primaryPlayerRef',
                payload: param,
            });
        },
        setRecreateStreams(value) {
            dispatch({
                type: 'recreateStreams',
                payload: value,
            });
        },
        setScreenSharingClient(client) {
            dispatch({
                type: 'screenSharingClient',
                payload: client,
            });
        },
        setScreenSharingStream(evt) {
            const { stream } = evt || {};

            dispatch({
                type: 'screenShareStream',
                payload: stream,
            });
        },
        setSecondaryPlayerRef(param) {
            dispatch({
                type: 'secondaryPlayerRef',
                payload: param,
            });
        },
        setShareType(type) {
            dispatch({
                type: 'shareType',
                payload: type,
            });
        },
        setShowAlreadyInTheSessionModal(param) {
            dispatch({
                type: 'showAlreadyInTheSessionModal',
                payload: param,
            });
        },
        setIsInRoundTable(param) {
            dispatch({
                type: 'isInRoundTable',
                payload: param,
            });
        },
        setRoundTableUserInfoError(param) {
            dispatch({
                type: 'roundTableUserInfoError',
                payload: param,
            });
        },
        setShowCapacityModal(param) {
            dispatch({
                type: 'showCapacityModal',
                payload: param,
            });
        },
        setShowScreenSharingModal(type) {
            dispatch({
                type: 'showScreenSharingModal',
                payload: type,
            });
        },
        setShowToast(param) {
            dispatch({
                type: 'showToast',
                payload: param,
            });
        },
        setSocket(param) {
            dispatch({
                type: 'socket',
                payload: param,
            });
        },
        setTimeslot(param) {
            dispatch({
                type: 'timeslot',
                payload: param,
            });
        },
        setToasts(toasts) {
            dispatch({ type: 'toasts', payload: toasts });
        },
        setVirtualEventPollSets(param) {
            dispatch({ type: 'virtualEventPollSets', payload: param });
        },
        setSubscribeTimestamp(param) {
            dispatch({ type: 'subscribeTimestamp', payload: param });
        },
        setVirtualEventSession(param) {
            dispatch({
                type: 'virtualEventSession',
                payload: param,
            });
        },
        setVirtualEventUser(param) {
            dispatch({
                type: 'virtualEventUser',
                payload: param,
            });
        },
        startPlaying() {
            dispatch({
                type: 'playing',
                payload: true,
            });
        },
        startScreenSharing() {
            dispatch({
                type: 'screenSharing',
                payload: true,
            });
        },
        startUsingDevices(params, newMicrophoneId, newCameraId, recreateStreams) {
            functionForDevices()
                .then(({ cameraList, microphoneList }) => {
                    const defaultMic = microphoneList[0];
                    const defaultCamera = cameraList[0];

                    methods.setExtraState({
                        cameraList,
                        microphoneList,
                    });

                    const cameraId = get(defaultCamera, 'deviceId');
                    const microphoneId = get(defaultMic, 'deviceId');

                    methods.updateConfig({
                        ...params,
                        cameraId: newCameraId ? newCameraId : cameraId,
                        microphoneId: newMicrophoneId ? newMicrophoneId : microphoneId,
                        host: !!cameraId || !!microphoneId,
                    });

                    if (newMicrophoneId || newCameraId || recreateStreams) {
                        methods.setRecreateStreams(true);
                    }

                    dispatch({
                        type: 'useDevices',
                        payload: true,
                    });
                })
                .catch(() => {});
        },
        setMutedBySystem(param) {
            dispatch({
                type: 'mutedBySystem',
                payload: param,
            });
        },
        setMicMutedDialog(param) {
            dispatch({
                type: 'micMutedDialog',
                payload: param,
            });
        },
        setPeers(peers) {
            dispatch({
                type: 'setPeers',
                payload: peers,
            });
        },
        stopPlaying() {
            dispatch({
                type: 'playing',
                payload: false,
            });
        },
        stopScreenSharing() {
            dispatch({
                type: 'screenSharing',
                payload: false,
            });
        },
        stopUsingDevices() {
            dispatch({
                type: 'useDevices',
                payload: false,
            });
        },
        switchMain() {
            dispatch({ type: 'switchMain' });
        },
        userSwitchMain() {
            dispatch({ type: 'userSwitchMain' });
        },
        toastError(message) {
            updateToasts([
                ...toasts,
                {
                    variant: 'error',
                    message,
                },
            ]);
        },
        toastInfo(message) {
            updateToasts([
                ...toasts,
                {
                    variant: 'info',
                    message,
                },
            ]);
        },
        updateConfig(params) {
            dispatch({
                type: 'config',
                payload: { ...state.config, ...params },
            });
        },
        showJoiningLoader() {
            dispatch({
                type: 'joiningLoaderVisible',
                payload: true,
            });
        },
        hideJoiningLoader() {
            dispatch({
                type: 'joiningLoaderVisible',
                payload: false,
            });
        },
        setVolumeIndicators(streams) {
            volumeDispatch({ type: 'volumeIndicators', payload: { streams } });
        },
    };

    useEffect(() => {
        window.sessionStorage.setItem(
            'custom_storage',
            JSON.stringify({
                uid: state.config.uid,
                host: state.config.host,
                channelName: state.config.channelName,
                token: state.config.token,
                resolution: state.config.resolution,
            }),
        );
    }, [state]);

    return (
        <StateContext.Provider value={state}>
            <VolumeContext.Provider value={volumeState}>
                <MutationContext.Provider value={methods}>
                    {/*<CustomizedSnackbar toasts={toasts} />*/}
                    {/*{state.loading ? <Loading /> : null}*/}
                    {children}
                </MutationContext.Provider>
            </VolumeContext.Provider>
        </StateContext.Provider>
    );
};

export function useGlobalState() {
    return useContext(StateContext);
}

export function useVolumeState() {
    return useContext(VolumeContext);
}

export function useGlobalMutation() {
    return useContext(MutationContext);
}
