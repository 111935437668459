import React, { useEffect, useState, useContext } from 'react';

import { DialogContainerStyled } from '../style/modalsStyle';
import ThemeContext from '../../../components/Theme/ThemeContext';
import * as palette from '../../../components/General/Variables';
import { getFullProfile, getUserSettings, updateUserSettings } from '../../../services/api/eureka';
import { getLocalAppState } from '../../../services/api';
import { getString } from '../../../services/api/store';
import { useGlobalState } from '../../../utils/container';
import { TimezoneContext } from '../../Timezone/context';
import * as string from '../constants/index';
import SettingsContent from '../components/settings/SettingsContent';
import ComplexDialog from '../../../components/Dialog/ComplexDialog';

const SettingsModal = ({ onClose, afterSignUp }) => {
    const [state, setState] = useState({
        profileVisible: true,
        chatAvailable: true,
        eventId: null,
        eventName: '',
    });
    const [user, setUser] = useState(null);
    const [dialogTimezone, setDialogTimezone] = useState('local');
    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;

    const {
        getLocalTimezoneName,
        getEventTimezoneName,
        selectedTimezone,
        updateTimezoneSettings,
    } = useContext(TimezoneContext);

    useEffect(() => {
        setDialogTimezone(selectedTimezone);
    }, []);

    const updateTimezone = zone => {
        setDialogTimezone(zone);
    };

    const TIMEZONE_LOCAL_LABEL = getString('settingsTimezoneLocal') || string.TIMEZONE_LOCAL_LABEL;
    const TIMEZONE_EVENT_LABEL = getString('settingsTimezoneEvent') || string.TIMEZONE_EVENT_LABEL;
    const SETTINGS_TITLE = getString('settingsEditTitle') || string.SETTINGS_TITLE;

    const options = [
        {
            label: `${TIMEZONE_LOCAL_LABEL} (${getLocalTimezoneName()})`,
            value: 'local',
        },
        {
            label: `${TIMEZONE_EVENT_LABEL} (${getEventTimezoneName()})`,
            value: 'event',
        },
    ];

    useEffect(() => {
        getLocalAppState(async (err, appState) => {
            if (err) {
                console.log(err);
            } else {
                const response = await getUserSettings(appState.eventId);
                const userData = await getFullProfile();
                setUser(userData);

                setState({
                    profileVisible: response && response.visible === true,
                    chatAvailable: response && response.available,
                    eventId: appState.eventId,
                    eventName: appState.eventName,
                });
            }
        });
    }, []);

    const handleChange = (value, name) => {
        setState({ ...state, [name]: value });
    };

    const onSave = async () => {
        const settings = {
            visible: state.profileVisible,
            available: state.chatAvailable,
            eventId: state.eventId,
            eventTitle: state.eventName,
        };

        await updateUserSettings(settings);
        await updateTimezoneSettings(dialogTimezone);

        if (socket) {
            socket.emit('privacySettingsUpdated', {
                eventId: state.eventId,
                userId: user.id,
            });
        }

        onClose();
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    {isDesktop ? (
                        <ComplexDialog
                            title={SETTINGS_TITLE}
                            visible={true}
                            onClose={onClose}
                            contentStyle={{ padding: 0 }}
                            displayBoxShadow
                            options={[
                                {
                                    title: 'Save',
                                    variant: 'text',
                                    type: 'save',
                                    onClick: onSave,
                                },
                                {
                                    title: 'Cancel',
                                    variant: 'text',
                                    type: 'cancel',
                                    onClick: onClose,
                                },
                            ]}
                        >
                            <SettingsContent
                                theme={theme}
                                options={options}
                                afterSignUp={afterSignUp}
                                dialogTimezone={dialogTimezone}
                                updateTimezone={updateTimezone}
                                handleChange={handleChange}
                                state={state}
                            />
                        </ComplexDialog>
                    ) : (
                        <DialogContainerStyled
                            id="speed-boost"
                            aria-describedby="speed-boost-description"
                            style={{
                                zIndex: '100 !important',
                            }}
                            visible={true}
                            fullPage={true}
                            pageX={12}
                            pageY={12}
                            aria-labelledby="simple-full-page-dialog-title"
                            onHide={() => {}}
                        >
                            <SettingsContent
                                theme={theme}
                                options={options}
                                afterSignUp={afterSignUp}
                                onClose={onClose}
                                dialogTimezone={dialogTimezone}
                                updateTimezone={updateTimezone}
                                handleChange={handleChange}
                                onSave={onSave}
                                state={state}
                            />
                        </DialogContainerStyled>
                    )}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};
export default SettingsModal;
