import styled from 'styled-components';

const MutedBySystemIcon = styled.span`
    background: #ee1045;
    display: block;
    position: absolute;
    height: 24px;
    width: 24px;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-family: Roboto, sans-serif;
    line-height: 24px;
    z-index: 99;
`;

export default MutedBySystemIcon;