import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BookmarkButton from '../../../components/General/BookmarkButton';
import Button, { buttonTypes } from '../../common/Button';
import { useTheme } from '../../../components/Theme/ThemeContext';
import {
    addFavorite,
    isFavoriteById,
    makeItemAsync,
    removeFavorite,
} from '../../../services/api/data';
import { withRouter } from 'react-router-dom';
import eventBus from '../../../utils/eventBus';
import { sync } from '../../../services/api/eureka';
import AnalyticsService from '../../../features/analytics/services';

const Container = styled.div`
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    z-index: 4;
    width: 648px;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 66px 0 0;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.15),
        0 2px 8px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
`;

const Image = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`;

const BoothText = styled.div`
    flex-grow: 0;
    font-family: 'Cabin', sans-serif;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: 0.5px;
    text-align: left;
    color: rgba(0, 0, 0, 0.38);
    text-transform: uppercase;
`;

const Title = styled.div`
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Cabin', sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const BoothInfoCard = ({ item, history, showClickBooth, location }) => {
    const [favoriteStatus, setFavoriteStatus] = useState('');
    const [exhibitor, setExhibitor] = useState({});
    const [loading, setLoading] = useState(false);
    const { theme } = useTheme();

    useEffect(() => {
        const fetchData = async () => {
            if (!item.exhibitorId) {
                return;
            }

            setLoading(true);
            const exhibitorResponse = await makeItemAsync(item.exhibitorId, 'institution');

            if (!exhibitorResponse) {
                setLoading(false);
                return;
            }

            setExhibitor(exhibitorResponse);
            isFavoriteById(exhibitorResponse.id, (err, isFavorite) => {
                if (!err) {
                    setFavoriteStatus(isFavorite ? 'checked' : '');
                    setLoading(false);
                }
            });
        };

        fetchData();
    }, [item.id]);

    if (!item.exhibitorId) {
        return null;
    }

    const onFavorite = event => {
        event.preventDefault();
        event.stopPropagation();

        if (favoriteStatus !== 'checked') {
            addFavorite(exhibitor, () => {
                AnalyticsService.addSample('favorite', true, exhibitor.id);

                sync(() => {
                    eventBus.emit('updateMyProgrammeCount');
                });
            });
        } else {
            removeFavorite(exhibitor, () => {
                AnalyticsService.addSample('favorite', false, exhibitor.id);

                sync(() => {
                    eventBus.emit('updateMyProgrammeCount');
                });
            });
        }

        setFavoriteStatus(favoriteStatus === 'checked' ? '' : 'checked');
    };

    const onViewProfileClick = () => {
        if (showClickBooth) {
            history.push(`/exhibitorbooth/${item.exhibitorId}`);
        } else {
            history.push(`${location.pathname}/${exhibitor.type}/${exhibitor.id}`);
        }
    };

    const { imageUrl } = exhibitor;

    if (!item.exhibitorId) {
        return null;
    }

    if (loading) {
        return (
            <Container>
                <Title>Loading...</Title>
            </Container>
        );
    }

    return (
        <Container>
            <Flex>
                {imageUrl && <Image src={imageUrl} alt={exhibitor.title} />}
                <div>
                    <BoothText>Booth</BoothText>
                    <Title>{exhibitor.title}</Title>
                </div>
            </Flex>
            <Flex>
                <BookmarkButton
                    favoriteStatus={favoriteStatus}
                    clickMyCongress={onFavorite}
                    smallBookmarkButton
                />
                <Button
                    type={buttonTypes.GREEN}
                    text="View full profile"
                    background={theme.primary}
                    onClick={onViewProfileClick}
                    enabled={!location?.pathname?.includes(`${exhibitor.type}/${exhibitor.id}`)}
                />
            </Flex>
        </Container>
    );
};

export default withRouter(BoothInfoCard);
