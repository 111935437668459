import React, { useContext, useEffect, useRef, useState } from 'react';
import { SessionsContainer, TileTitle, UpcomingSessionsTile } from '../common/styles';
import Loader from '../../../components/General/Loader';
import moment from 'moment';
import TimeslotListItem from '../../../components/ObjectListItem/Timeslot';
import NoUpcomingSessionsPlaceholder from './NoUpcomingSessionsPlaceholder';
import { useTheme } from '../../../components/Theme/ThemeContext';
import { withRouter } from 'react-router-dom';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { fetchUpcomingSessions } from '../api';
import { TimezoneContext } from '../../Timezone/context';
import { getString } from '../../../services/api/store';
import sortBy from 'lodash/sortBy';
import Button, { buttonTypes } from '../../common/Button';

const UpcomingSessionsWidget = ({ tileGroup, history, match }) => {
    const [upcomingSessions, setUpcomingSessions] = useState([]);
    const [noSessionsMessage, setNoSessionMessage] = useState('There are no events happening.');
    const [upcomingSessionsLoading, setUpcomingSessionsLoading] = useState(false);

    const TEXT_UPCOMING_SESSIONS_TITLE = getString('upcomingSessionsTitle') || 'Upcoming sessions';
    const TEXT_SEE_ALL_BUTTON = getString('upcomingSessionsSeeAllButton') || 'See all';

    const { theme } = useTheme();
    const { timeslotToTimezone } = useContext(TimezoneContext);

    const containerRef = useRef(null);

    useEffect(() => {
        (async () => {
            if (tileGroup) {
                setUpcomingSessionsLoading(true);
                const pageId =
                    tileGroup.items && tileGroup.items.length && tileGroup.items[0].pageId;
                const appState = await getLocalAppStateAsync();
                const { sessions, message } = await fetchUpcomingSessions(appState.eventId, pageId);
                const sortedSessions = sortBy(sessions, ['start', 'name']);
                setUpcomingSessions(sortedSessions);
                setNoSessionMessage(message);
                setUpcomingSessionsLoading(false);
            }
        })();
    }, []);

    const onSessionClick = item => {
        history.push(`${match.path}/timeslot/${item.id}`);
    };

    return (
        <UpcomingSessionsTile innerRef={containerRef}>
            <TileTitle>{TEXT_UPCOMING_SESSIONS_TITLE}</TileTitle>
            {upcomingSessionsLoading ? (
                <Loader />
            ) : (
                <>
                    {upcomingSessions && upcomingSessions.length > 0 ? (
                        <>
                            <SessionsContainer>
                                {upcomingSessions.map((it, index) => {
                                    const item = timeslotToTimezone(it);
                                    const timeToDisplay = moment.utc(item.start).format('HH:mm');
                                    const dateToDisplay = moment.utc(item.start).format('MMM D');

                                    item.dateTime = {
                                        startTimeToDisplay: timeToDisplay,
                                        startDateToDisplay: dateToDisplay,
                                    };

                                    return (
                                        <div
                                            key={`upcoming-session-${index}`}
                                            onClick={() => onSessionClick(item)}
                                        >
                                            <TimeslotListItem
                                                timeslot={item}
                                                isUpcomingSession={true}
                                                containerWidth={
                                                    containerRef.current &&
                                                    containerRef.current.offsetWidth
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </SessionsContainer>
                            <Button
                                onClick={() => history.push(`${match.path}/upcomingSessions`)}
                                type={buttonTypes.GREEN}
                                text={TEXT_SEE_ALL_BUTTON}
                                style={{ borderRadius: '18px', margin: '12px 16px' }}
                            />
                        </>
                    ) : (
                        <NoUpcomingSessionsPlaceholder text={noSessionsMessage} />
                    )}
                </>
            )}
        </UpcomingSessionsTile>
    );
};

export default withRouter(UpcomingSessionsWidget);
