import React, { useEffect, useState, useContext } from 'react';
import {
    getAllProgramFavorites,
    getDateWithLocale,
    getFormatTime,
    makeItem,
} from '../../services/api/data';
import moment from 'moment';
import 'moment/min/locales.min';
import { getItem } from '../../services/api/graphQlRepository';
import { List } from 'react-md';
import ThemeContext from '../../components/Theme/ThemeContext';
import { TimezoneContext } from '../Timezone/context';
import styled from 'styled-components';
import { getString } from '../../services/api/store.js';
import Loader from '../../components/General/Loader';
import ClassifierIcon from '../../components/Icons/ClassifierIcon';
import ObjectListItem from '../../components/ObjectListItem';
import { Link } from 'react-router-dom';
import { each } from 'async';
import Placeholder from '../SearchPage/components/placeholder';
import AppointmentService from '../Appointments/services/AppointmentService';
import Auth from '../../services/api/auth';
import eventBus from '../../utils/eventBus';
import { APPOINTMENT_PARTICIPANT_STATUS } from '../Appointments/constants';
import {Tab, TabsContainer} from "../../components/DateTabs";

const LinkStyled = styled(Link)`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${props => window.innerWidth}px;
`;

const ListItem = ({ item }) => (
    <span>
        <ObjectListItem item={item} type={item.typeObj.target.toLowerCase()} />
    </span>
);

const TAB_DATE_PATTERN = 'ddd DD';

const MyProgrammePage = props => {
    const [favorites, setFavorites] = useState([]);
    const [days, setDays] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const { objectClass, objectId } = props.match.params;

    const { timeslotToTimezone } = useContext(TimezoneContext);

    useEffect(() => {
        fetchData();

        eventBus.on('refreshMyProgramme', fetchData);
    }, [activeTab]);

    useEffect(() => {
        if (objectClass && objectId) {
            makeItem(objectId, objectClass, (err, obj) => {
                setTimeout(() => {
                    props.setObject(obj);
                });
            });
        } else {
            props.setObject(null);
        }
    }, [objectId, objectClass]);

    const shouldDisplayAppointment = appointment =>
        appointment &&
        appointment.members &&
        appointment.members.length &&
        appointment.members[0].AppointmentGroup &&
        (appointment.members[0].AppointmentGroup.status ===
            APPOINTMENT_PARTICIPANT_STATUS.WAITING ||
            appointment.members[0].AppointmentGroup.status ===
                APPOINTMENT_PARTICIPANT_STATUS.ACCEPTED);

    const fetchData = () => {
        const favoritesArray = [];
        const daysArray = [];
        const daysMomentArray = [];

        getAllProgramFavorites((err, favorites) => {
            each(
                favorites,
                (item, callback) => {
                    getItem('timeslots', item.id, (err, timeslotFromDb) => {
                        if (err || !timeslotFromDb) {
                            return callback();
                        }

                        const timeslot = timeslotToTimezone(timeslotFromDb);

                        getItem('types', timeslot.type, (err, type) => {
                            if (err || !type) {
                                console.log(err);

                                return callback();
                            }

                            timeslot.typeObj = type;
                            favoritesArray.push(timeslot);
                            let day = moment.utc(timeslot.start);
                            day.locale(locale);

                            const label = day.format(TAB_DATE_PATTERN);
                            if (!daysArray.includes(label)) {
                                daysArray.push(label);
                                daysMomentArray.push(day);
                            }

                            callback();
                        });
                    });
                },
                async () => {
                    let appointments = [];
                    const user = Auth.getUser();

                    if (user) {
                        appointments = await AppointmentService.getAllAppointments(user.id);
                        // Filter appointments: keep only appointments that are not declined
                        appointments = appointments.filter(shouldDisplayAppointment);
                    }

                    appointments.forEach(appointment => {
                        let day = moment.utc(appointment.start);
                        if (!day.isValid()) {
                            day = moment.unix(appointment.start);
                        }
                        day.locale(locale);

                        const label = day.format(TAB_DATE_PATTERN);
                        if (!daysArray.includes(label)) {
                            daysArray.push(label);
                            daysMomentArray.push(day);
                        }

                        appointment.type = 'appointment';
                        appointment.typeObj = { target: 'appointment' };
                        favoritesArray.push(appointment);
                    });

                    favoritesArray.sort((a, b) =>
                        a.start > b.start ? 1 : b.start > a.start ? -1 : 0,
                    );
                    const sortedDaysArray = daysMomentArray
                        .sort((a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'))
                        .filter((el, i, a) => i === a.indexOf(el));

                    setFavorites(favoritesArray);
                    setDays(sortedDaysArray);
                    setLoading(false);
                },
            );
        });
    };

    const onTabClick = index => {
        setActiveTab(index);
    };

    let dateSettings = getString('datetime');
    let locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';
    const timeArray = [];
    const renderListItems = () =>
        favorites.map((item, i) => {
            if (!item || !item.typeObj) {
                return;
            }

            let day = moment.utc(item.start);
            if (!day.isValid()) {
                day = moment.unix(item.start);
            }
            day.locale(locale);
            const isAppointment = item.type === 'appointment';
            const eventDay = day.format(TAB_DATE_PATTERN);

            if (eventDay === moment(days[activeTab]).format(TAB_DATE_PATTERN)) {
                let params = item.params;
                if (typeof params === 'string') {
                    params = JSON.parse(item.params);
                }
                let classifierIcon;
                if (params && params.icons && params.icons.length) {
                    params.icons.forEach((item, idx) => {
                        classifierIcon = <ClassifierIcon key={item} imageId={item} />;
                    });
                }
                const itemWithTimezone = timeslotToTimezone(item);

                let start;
                if (isAppointment) {
                    start = moment.utc(itemWithTimezone.start);
                    if (!start.isValid()) {
                        start = moment.unix(itemWithTimezone.start);
                    }
                    start = start.utc();
                } else {
                    start = moment.utc(item.start);
                    if (!start.isValid()) {
                        start = moment.unix(item.start);
                    }
                }

                const timeFormat = getFormatTime();
                const timetoDisplay = moment(start).format(timeFormat);
                const dateSettings = getString('datetime');
                const datePattern =
                    dateSettings && dateSettings.dayMonthDateFormat
                        ? dateSettings.dayMonthDateFormat
                        : 'MMMM D';
                const day = getDateWithLocale(start);
                const dateToDisplay = day.format(datePattern);

                item.dateTime = {
                    startTimeToDisplay: timetoDisplay,
                    startDateToDisplay: dateToDisplay,
                };
                timeArray.push({ dateTime: item.dateTime });

                return (
                    <LinkStyled
                        key={'link_' + item.id}
                        to={`/mybookmarks/${item.typeObj.target.toLowerCase()}/${item.id}`}
                    >
                        <ListItem item={item} />
                    </LinkStyled>
                );
            }
        });

    const content = () => {
        if (!favorites.length && !loading) {
            return (
                <Placeholder
                    type="emptyList"
                    introText={getString('personalNoFavoritesTitle') || 'Nothing saved yet...'}
                />
            );
        } else {
            return <ListStyled className="">{renderListItems()}</ListStyled>;
        }
    };

    const renderTabs = theme => {
        return days.map((elem, index) => {
            const label = moment(elem).format(TAB_DATE_PATTERN);
            return (
                <Tab
                    key={`tab_${index}`}
                    active={index === activeTab}
                    color={theme.contrast}
                    onClick={() => onTabClick(index)}
                >
                    <div>{label.split(' ')[0]}</div>
                    <div>{label.split(' ')[1]}</div>
                </Tab>
            );
        });
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <TabsContainer>{renderTabs(theme)}</TabsContainer>
                    {loading && <Loader />}
                    {!loading && content()}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default MyProgrammePage;
