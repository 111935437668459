import React from 'react';
import {DatePicker} from "react-md";
import styled from 'styled-components';
import {useTheme} from "../Theme/ThemeContext";

const StyledDatePicker = styled(DatePicker)`
  font-family: 'Roboto', sans-serif;
  
  .md-picker-header,
  .md-dialog-footer {
    display: none;
  }
  
  .md-picker--inline {
    padding: 8px 0 0;
    border-radius: 8px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
    width: fit-content;
  }

  .md-calendar-date--btn::after {
    background: ${props => props.color};
  }

  .md-text-field-container {
    background-color: #efefef;
    border-radius: 6px;
    overflow: hidden;
  }
  
  .md-picker-content-container {
    min-width: 376px;
  }

  .md-text-field-container--input input {
    padding: 0 12px;

    &::placeholder {
      font-size: 15px;
      line-height: 1.6;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .md-divider {
    height: 0;
  }

  .md-divider--text-field::after {
    height: 3px;
    background: ${props => props.color};
  }

  .md-calendar-controls {
    & > :nth-child(1) {
      order: 1;
    }

    & > :nth-child(2) {
      order: 0;
      text-align: left;
    }

    & > :nth-child(3) {
      order: 2;
    }
  }
`;

const FORMAT_OPTIONS = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
};

const CustomDatePicker = ({ placeholder, value, onChange }) => {
    const { theme } = useTheme();

    return (
        <StyledDatePicker
            id={`inline-date-picker-auto-${placeholder}`}
            placeholder={placeholder}
            inline
            fullWidth={true}
            firstDayOfWeek={1}
            autoOk={true}
            icon={null}
            onChange={(_, newDate) => onChange(newDate)}
            value={value}
            formatOptions={FORMAT_OPTIONS}
            color={theme.primary}
        />
    );
};

export default CustomDatePicker;