import React from 'react';
import ReactDOM from 'react-dom';
import { API } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import {
    SENTRY_DSN,
    SENTRY_ENVIRONMENT,
    PACKAGE_RELEASE_VERSION,
    USE_LOCAL,
    APPSYNC_CONFIG,
} from './config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import './index.css';

if (SENTRY_DSN && !USE_LOCAL) {
    const opts = {
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        release: PACKAGE_RELEASE_VERSION,
        integrations: [new TracingIntegrations.BrowserTracing()],
        tracesSampleRate: 0.1, // send 10% of transactions
    };
    Sentry.init(opts);
}

serviceWorkerRegistration.unregister();

API.configure(APPSYNC_CONFIG);

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root'),
);
