import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import ThemeContext from '../../components/Theme/ThemeContext';
import { getString } from '../../services/api/store';
import MyProgrammePage from '../MyProgrammePage';
import MyFavoritePage from '../MyFavoritesPage';
import AppointmentDetailPage from '../Appointments/containers/AppointmentDetailPage';
import DetailPage from '../DetailPage';
import {
    TabsContainerStyled,
    TabsWrapper,
    ToolbarContainer,
    TabElement,
} from '../Programme/style/style';

const MyBookmarksPage = props => {
    const [object, setObject] = useState(null);

    let TITLE_MY_PROGRAMME = getString('myProgramme') || 'My programme';
    let TITLE_MY_FAVORITES = getString('myFavorites') || 'My favorites';

    useEffect(() => {
        props.setTitle();
    }, []);

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <ToolbarContainer>
                        <TabsContainerStyled panelClassName="md-grid" colored heght={'100%'}>
                            <TabsWrapper
                                background={theme.primary}
                                tabId="simple-tab"
                                mobile
                                alignToKeyline={false}
                            >
                                <TabElement
                                    label={TITLE_MY_PROGRAMME}
                                    primary={theme.primary}
                                    active={props.location.pathname.startsWith('/mybookmarks')}
                                    margin={'24px 8px 24px 0'}
                                >
                                    <MyProgrammePage setObject={setObject} {...props} />
                                </TabElement>
                                <TabElement label={TITLE_MY_FAVORITES} primary={theme.primary}>
                                    <MyFavoritePage setObject={setObject} {...props} />
                                </TabElement>
                            </TabsWrapper>
                        </TabsContainerStyled>
                    </ToolbarContainer>

                    {object &&
                        (object.objectClass === 'Appointment' || object.type === 'appointment' ? (
                            <AppointmentDetailPage
                                {...props}
                                closeLink={props.rootRoute}
                                prefix={`${props.rootRoute}`}
                            />
                        ) : (
                            <DetailPage
                                {...props}
                                closeLink={props.rootRoute}
                                prefix={`${props.rootRoute}`}
                            />
                        ))}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(MyBookmarksPage);
