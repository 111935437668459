import React, { useEffect, useState } from 'react';
import { FontIcon } from 'react-md';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { default as Store, getString } from '../../../services/api/store';
import { WithTimezone } from '../../../scenes/Timezone/context';
import { ListItem } from 'react-md';

import {
    ICON_ACCESS_TIME,
    ICON_CLOSE,
    ICON_LOCATION_ON,
    ICON_NOTES,
    ICON_PERSON__OUTLINE,
    ICON_VIDEOCAM,
} from '../../../components/General/Variables';
import AppointmentService from '../services/AppointmentService';
import Auth from '../../../services/api/auth';
import { getLocalAppStateAsync } from '../../../services/api/db';
import Loader from '../../../components/General/Loader';
import { nanoid } from 'nanoid';
import toast from 'toasted-notes';
import moment from 'moment-timezone';
import AnalyticsService from '../../../features/analytics/services';
import { sendChatNotification } from '../../Talk/actions';
import { APPOINTMENT_STATE } from '../../Talk/constants';
import eventBus from '../../../utils/eventBus';
import Button, { buttonTypes } from '../../common/Button';
import { useTheme } from '../../../components/Theme/ThemeContext';
import ComplexDialog from '../../../components/Dialog/ComplexDialog';
import UnderlinedTextInput from '../../../components/TextInput/UnderlinedTextInput';
import CustomSelect from '../../../components/Selectors/CustomSelect';
import UserAvatar from '../../../components/General/UserAvatar';
import { executeListQuery } from '../../../services/api/graphQlRepository';
import uniq from 'lodash/uniq';
import { Flex } from '../../common/Flex';
import { getItemAsync } from '../../../services/api/data';
import Textarea from '../../../components/TextInput/Textarea';
import CustomDatePicker from '../../../components/Picker/DatePicker';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';
import ReminderService from "../../Reminders/ReminderService";

const IconWrapper = styled.div`
    margin-top: 10px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled(FontIcon)`
    color: rgba(0, 0, 0, 0.87) !important;
`;

const FlexBigContainer = styled.div`
    flex: 1;

    &:not(:last-child) {
        margin-right: 24px;
    }
`;

const TitleWrapper = styled.div`
    margin-left: 40px;
    margin-bottom: 24px;
`;

const ListSection = styled(ListItem)`
    &:not(:first-child) {
        margin-top: 16px;
    }

    .md-tile-text--primary {
        font-family: 'Cabin', sans-serif;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.23;
        letter-spacing: 0.5px;
        color: rgba(0, 0, 0, 0.6);
        text-transform: uppercase;
    }

    .md-list-tile {
        height: 34px;
    }
`;

const FlexRow = styled(Flex)`
    margin-bottom: 32px;
    align-items: start;
`;

const FitContentWrapper = styled.div`
    width: ${props => (props.width ? `${props.width}px` : 'fit-content')};
    margin: auto 0;

    &:not(:last-child) {
        margin: auto 8px auto 0;
    }
`;

const VirtualRoomWrapper = styled.div`
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    position: relative;

    &:hover {
        cursor: pointer;
        background-color: #f5f5f5;

        #virtual-room-remove-icon {
            display: block;
        }
    }
`;

const VirtualRoomText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.87);
`;

const VirtualRoomInfo = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 1.54;
    color: rgba(0, 0, 0, 0.54);
`;

const VirtualRoomIcon = styled(FontIcon)`
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 24px;
    padding: 4px;
    display: none;
`;

const HourAdditionalText = styled.span`
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 1.07;
    color: rgba(0, 0, 0, 0.38);
    margin-left: 8px;
`;

export const appointmentTypes = {
    WITH_SELECTED_USER: 'with-selected-user',
    WITH_REPRESENTATIVE: 'with-representative',
};

const DEFAULT_ERRORS = {
    title: '',
    attendee: '',
    endTime: '',
};

const AppointmentModal = ({
    appointment,
    participant,
    representatives,
    onClose,
    sendChatMessage,
    timezone,
    type = appointmentTypes.WITH_SELECTED_USER,
}) => {
    const [currentEvent, setCurrentEvent] = useState({});
    const [loading, setLoading] = useState(false);

    const [usersList, setUsersList] = useState([]);
    const [locations, setLocations] = useState({});
    const [hoursList, setHoursList] = useState([]);

    const [title, setTitle] = useState(null);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [date, setDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [description, setDescription] = useState(null);
    const [isVirtualRoom, setIsVirtualRoom] = useState(false);
    const [errors, setErrors] = useState(DEFAULT_ERRORS);

    const isAppointmentWithSelectedUser = type === appointmentTypes.WITH_SELECTED_USER;

    const { trackAchievement } = useAchievementActions();
    const { theme } = useTheme();

    const CHOOSE_A_REPRESENTATIVE_TEXT = getString('chooseARepresentative', 'Choose a representative');
    const CHOOSE_A_USER_TEXT = getString('chooseAUSER', 'Choose a user');
    const VIRTUAL_ROOM_TEXT = getString('virtualAppointmentVirtualRoom', 'Virtual room');
    const VIRTUAL_ROOM_INFO = getString(
        'virtualAppointmentRoomDescription',
        'Link will be accessible from the meeting details page after scheduling.'
    );

    useEffect(() => {
        (async () => {
            setLoading(true);
            const appState = await getLocalAppStateAsync();

            if (isAppointmentWithSelectedUser) {
                setSelectedParticipant(participant);
            } else {
                setUsersList(representatives);
            }

            setDate(new Date());
            const hoursArray = getHoursArray();
            setHoursList(hoursArray);
            setStartTime(getCurrentRoundedHour());

            setCurrentEvent(appState);
            const fetchedLocations = await getLocations(appState.id);
            setLocations(fetchedLocations);

            if (appointment) {
                setTitle(appointment.name);
                const member =
                    appointment.members && appointment.members.length && appointment.members[0];
                const locationName = appointment.contentLocation;
                setSelectedParticipant(member);
                const locationsArray = Object.keys(fetchedLocations).reduce(
                    (acc, curr) => [...acc, ...fetchedLocations[curr]],
                    [],
                );
                const foundLocation = locationsArray.find(
                    location => location.name === locationName,
                );
                setSelectedLocation(foundLocation);
                setIsVirtualRoom(appointment.isVirtual);
                setDescription(appointment.subNameList);
            }

            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        const startTimeArrayIndex = hoursList.findIndex(
            hourItem => hourItem.value === startTime.value,
        );
        const endTimeNewIndex = (startTimeArrayIndex + 2) % hoursList.length;

        setEndTime(hoursList[endTimeNewIndex]);
    }, [startTime]);

    const getLocations = async event => {
        const places = await executeListQuery(
            'findPlaces',
            {
                event: {
                    eq: event,
                },
            },
            true,
        );

        const types = uniq(places.map(place => place.type));

        const locationsGroupedByType = await types.reduce(async (acc, typeId) => {
            const accumulator = await acc;
            const typeInfo = await getItemAsync('types', typeId);

            if (typeInfo) {
                const { plural } = typeInfo;

                accumulator[plural] = places.filter(place => place.type === typeId);
            }

            return accumulator;
        }, Promise.resolve({}));

        return locationsGroupedByType;
    };

    const getCurrentRoundedHour = () => {
        const currentDate = moment();
        const hours = currentDate.hours();
        const minutes = currentDate.minutes();

        let roundedHours = hours;
        let roundedMinutes = 0;

        if (minutes < 15) {
            roundedMinutes = 15;
        } else if (minutes < 30) {
            roundedMinutes = 30;
        } else if (minutes < 45) {
            roundedMinutes = 45;
        } else if (minutes <= 59) {
            roundedHours = hours + 1;
            roundedMinutes = 0;
        }

        currentDate.set({
            hour: roundedHours,
            minute: roundedMinutes,
        });

        const formattedHour = currentDate.format('HH:mm');

        return {
            value: formattedHour,
            label: formattedHour,
        };
    };

    const getHoursArray = () => {
        const arr = [];
        const today = moment();
        const date = moment();
        date.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        });

        while (date.isSame(today, 'day')) {
            arr.push({
                value: date.format('HH:mm'),
                label: date.format('HH:mm'),
            });

            date.add(15, 'minutes');
        }

        return arr;
    };

    const goBack = () => onClose();

    const mergeDateAndTime = (date, startTime, endTime) => {
        const start = new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                startTime.split(':')[0],
                startTime.split(':')[1],
            ),
        );
        const end = new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                endTime.split(':')[0],
                endTime.split(':')[1],
            ),
        );

        return { start, end };
    };

    const createVirtualSession = async appointmentId => {
        await AppointmentService.createVirtualRoom({
            appointmentId,
            roomType: 'virtual',
        });
    };

    const addVirtualEventUsers = async (user, appointmentId) => {
        const payload = {
            eventId: currentEvent.id,
            appointmentId,
        };

        await AppointmentService.addUser({
            ...payload,
            role: 'virtualRoomHost',
            user: {
                id: user.id,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
            },
        });

        await AppointmentService.addUser({
            ...payload,
            role: 'virtualRoomParticipant',
            user: {
                id: selectedParticipant.id,
                email: selectedParticipant.email,
                firstName: selectedParticipant.firstName,
                lastName: selectedParticipant.lastName,
            },
        });
    };

    const createOrUpdateAppointment = async input => {
        return await AppointmentService.saveAppointment(input);
    };

    const getMomentDateWithTime = time =>
        moment().set({
            hour: time.split(':')[0],
            minute: time.split(':')[1],
        });

    const renderUserOption = item => {
        const fullName = `${item.firstName} ${item.lastName}`;
        const detailsArr = [item.jobTitle, item.companyName];
        const details = detailsArr.filter(det => !!det).join(' - ');

        return (
            <ListItem
                key={`user-${item.id}`}
                primaryText={fullName}
                secondaryText={details}
                leftAvatar={<UserAvatar user={item} />}
                onClick={() => setSelectedParticipant(item)}
            />
        );
    };

    const renderHourOption = (item, type) => {
        const isStartTime = type === 'start';
        const updateStateFunc = isStartTime ? setStartTime : setEndTime;
        let primaryText;

        if (isStartTime || !startTime) {
            primaryText = item.label;
        } else {
            const start = moment().set({
                hour: startTime.value.split(':')[0],
                minute: startTime.value.split(':')[1],
            });
            const end = moment().set({
                hour: item.value.split(':')[0],
                minute: item.value.split(':')[1],
            });
            const isEndDateAfterStartDate = end.isAfter(start);
            const diff = end.diff(start, 'minutes');

            primaryText = (
                <span>
                    {item.value}
                    {isEndDateAfterStartDate && (
                        <HourAdditionalText>{`${diff} min`}</HourAdditionalText>
                    )}
                </span>
            );
        }

        return (
            <ListItem
                key={`hour-${item.value}`}
                primaryText={primaryText}
                onClick={() => {
                    updateStateFunc(item);
                    const validationStartTime = isStartTime ? item : startTime;
                    const validationEndTime = !isStartTime ? item : endTime;
                    const { error } = isTimeValid(validationStartTime, validationEndTime);
                    setErrors({
                        ...errors,
                        endTime: error,
                    });
                }}
            />
        );
    };

    const locationObjectToArray = () => {
        const array = [];

        Object.keys(locations).forEach(typeName => {
            array.push(
                <ListSection
                    key={`list-section-${typeName}`}
                    primaryText={typeName}
                    disabled={true}
                />,
            );

            locations[typeName].forEach(location => {
                array.push(
                    <ListItem
                        key={`location-${location.name}`}
                        primaryText={location.name}
                        leftIcon={
                            <FontIcon iconClassName="material-icons-outlined">
                                {ICON_LOCATION_ON}
                            </FontIcon>
                        }
                        onClick={() => setSelectedLocation(location)}
                    />,
                );
            });
        });

        return array;
    };

    const isTitleValid = title => {
        const isValid = !!title;
        const error = !isValid ? 'Please set a title' : null;
        return { isValid, error };
    };

    const isSelectedParticipantValid = selectedParticipant => {
        const isValid = !!selectedParticipant;
        const error = !isValid
            ? `Please choose a ${isAppointmentWithSelectedUser ? 'user' : 'representative'} first`
            : null;
        return { isValid, error };
    };

    const isTimeValid = (startTime, endTime) => {
        let isValid = true;
        if (startTime && endTime) {
            const start = getMomentDateWithTime(startTime.value);
            const end = getMomentDateWithTime(endTime.value);
            isValid = start.isBefore(end);
        }
        const error = !isValid ? 'Choose an end time that is later than the start time' : null;
        return { isValid, error };
    };

    const isFormValid = () => {
        const { isValid: validTitle, error: errorTitle } = isTitleValid(title);
        const { isValid: validAttendee, error: errorAttendee } = isSelectedParticipantValid(
            selectedParticipant,
        );
        const { isValid: validTime, error: errorTime } = isTimeValid(startTime, endTime);

        setErrors({
            title: errorTitle,
            attendee: errorAttendee,
            endTime: errorTime,
        });
        return validTitle && validAttendee && validTime;
    };

    const onSubmit = async () => {
        const user = Auth.getUser();

        if (!isFormValid()) {
            return;
        }

        setLoading(true);
        try {
            const { start, end } = mergeDateAndTime(date, startTime.value, endTime.value);
            const appointmentType = await AppointmentService.getAppointmentType(currentEvent.id);
            const id = appointment ? appointment.reference : nanoid();
            const buddyId = selectedParticipant.id;
            const isAppointmentVirtual = appointment && appointment.isVirtual;
            const invitationStatus =
                appointment &&
                appointment.members &&
                appointment.members.length &&
                appointment.members[0].AppointmentGroup &&
                appointment.members[0].AppointmentGroup.status;

            const { selectedTimezone } = timezone;
            let startDateTime = moment(start).unix();
            let endDateTime = moment(end).unix();

            if (selectedTimezone === 'local') {
                const timezoneOffsetMinutes = Store.argument.offsetTimezone;
                const currentOffset = moment().utcOffset();
                const differenceSeconds = parseInt(currentOffset - timezoneOffsetMinutes, 10) * 60;
                const dateSecondsStart = parseInt(moment.utc(start).unix(), 10);
                const dateMomentStart = moment
                    .utc(dateSecondsStart - differenceSeconds, 'X')
                    .unix();

                const dateSecondsEnd = parseInt(moment.utc(end).unix(), 10) || end;
                const dateMomentEnd = moment.utc(dateSecondsEnd - differenceSeconds, 'X').unix();

                startDateTime = dateMomentStart;
                endDateTime = dateMomentEnd;
            }

            const newAppointment = await createOrUpdateAppointment({
                ...appointment,
                id: id,
                parentId: id,
                name: title,
                start: startDateTime,
                end: endDateTime,
                contentLocation: selectedLocation ? selectedLocation.name : '',
                subNameList: description,
                buddyId,
                ownerId: currentEvent.id,
                eventId: currentEvent.id,
                eventTitle: currentEvent.eventTitle,
                objectClass: 'timeslot',
                typeId: appointmentType ? appointmentType.id : null,
                isNew: !appointment,
                visible: true,
                isVirtual: isVirtualRoom,
                invitationStatus,
            });

            AnalyticsService.addSample(
                'appointment',
                JSON.stringify(newAppointment),
                newAppointment.id,
            );
            trackAchievement(AchievementType.APPOINTMENT, id);

            if (isVirtualRoom && !isAppointmentVirtual) {
                await createVirtualSession(newAppointment.id);
                await addVirtualEventUsers(user, newAppointment.id);
            }

            ReminderService.addItemToScheduledList(newAppointment, { isAppointment: true });

            if (buddyId) {
                const chatAppointment = {
                    id: newAppointment.reference,
                    eurekaId: newAppointment.id,
                    creator: newAppointment.UserId,
                    event: newAppointment.ownerId,
                    isVirtual: newAppointment.isVirtual,

                    // Appointment details
                    name: newAppointment.name,
                    start: newAppointment.start,
                    end: newAppointment.end,
                    location: newAppointment.contentLocation,

                    // Status
                    status: !appointment ? APPOINTMENT_STATE.SCHEDULED : APPOINTMENT_STATE.UPDATED,
                };

                // TODO: check if this needs to be implemented like in the mobile app; In the mobile app there's an alert asking the user if the other user should be notified about this change.
                sendChatMessage({
                    userId: buddyId,
                    action: !appointment ? APPOINTMENT_STATE.SCHEDULED : APPOINTMENT_STATE.UPDATED,
                    appointment: chatAppointment,
                });
            }

            goBack();
            if (!appointment) {
                eventBus.emit('updateMyProgrammeCount');
            }
            eventBus.emit('refreshMyProgramme');
            toast.notify(`Appointment successfully ${appointment ? 'updated' : 'created'}`, {
                position: 'top-right',
            });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ComplexDialog
            visible={true}
            options={[
                {
                    title: !appointment
                        ? getString('scheduleAppointment') || 'Send'
                        : 'Save changes',
                    variant: 'contained',
                    color: 'primary',
                    onClick: onSubmit,
                },
                {
                    title: getString('appointmentCancelButtonTitle') || 'Cancel',
                    variant: 'contained',
                    color: 'default',
                    onClick: goBack,
                },
            ]}
            onClose={goBack}
            size="md"
            extraStyles={{
                paper: {
                    overflowY: 'visible',
                },
            }}
            contentStyle={{
                overflowY: 'visible',
            }}
        >
            <TitleWrapper>
                <UnderlinedTextInput
                    value={title}
                    placeholder={getString('addTitle') || 'Add title'}
                    onChange={value => {
                        setTitle(value);
                        const { error } = isTitleValid(value);
                        setErrors({
                            ...errors,
                            title: error,
                        });
                    }}
                    error={errors.title}
                />
            </TitleWrapper>

            <Flex>
                <FlexBigContainer>
                    <FlexRow>
                        <IconWrapper>
                            <Icon>{ICON_PERSON__OUTLINE}</Icon>
                        </IconWrapper>

                        <CustomSelect
                            listItems={usersList.map(renderUserOption)}
                            placeholder={
                                isAppointmentWithSelectedUser ? CHOOSE_A_USER_TEXT : CHOOSE_A_REPRESENTATIVE_TEXT
                            }
                            textFieldValue={
                                selectedParticipant
                                    ? `${selectedParticipant.firstName} ${selectedParticipant.lastName}`
                                    : ''
                            }
                            error={errors.attendee}
                            disabled={!!appointment || isAppointmentWithSelectedUser}
                        />
                    </FlexRow>

                    <FlexRow>
                        <IconWrapper>
                            <Icon>{ICON_ACCESS_TIME}</Icon>
                        </IconWrapper>

                        <FitContentWrapper width={120}>
                            <CustomDatePicker
                                placeholder="Select date"
                                value={date}
                                onChange={setDate}
                            />
                        </FitContentWrapper>

                        <FitContentWrapper width={64}>
                            <CustomSelect
                                listItems={hoursList.map(item => renderHourOption(item, 'start'))}
                                placeholder="Start time"
                                textFieldValue={startTime ? startTime.label : ''}
                            />
                        </FitContentWrapper>

                        <FitContentWrapper>-</FitContentWrapper>

                        <FitContentWrapper width={64}>
                            <CustomSelect
                                listItems={hoursList.map(item => renderHourOption(item, 'end'))}
                                placeholder="End time"
                                textFieldValue={endTime ? endTime.label : ''}
                                error={errors.endTime}
                                tooltipError
                            />
                        </FitContentWrapper>
                    </FlexRow>

                    <FlexRow>
                        <IconWrapper>
                            <Icon>{ICON_NOTES}</Icon>
                        </IconWrapper>

                        <Textarea
                            onChange={event => setDescription(event.target.value)}
                            placeholder={
                                getString('appointmentAddDescription') ||
                                'Add description (optional)'
                            }
                            value={description}
                        />
                    </FlexRow>
                </FlexBigContainer>

                <FlexBigContainer>
                    <FlexRow>
                        <IconWrapper>
                            <Icon iconClassName="material-icons-outlined">{ICON_LOCATION_ON}</Icon>
                        </IconWrapper>

                        <CustomSelect
                            listItems={locationObjectToArray()}
                            placeholder={
                                getString('addApointmentHintLocation') || 'Add location (optional)'
                            }
                            textFieldValue={selectedLocation ? selectedLocation.name : ''}
                        />
                    </FlexRow>

                    <FlexRow>
                        <IconWrapper>
                            <Icon iconClassName="material-icons-outlined">{ICON_VIDEOCAM}</Icon>
                        </IconWrapper>

                        {!isVirtualRoom && (
                            <Button
                                type={buttonTypes.GREEN}
                                background={theme.primary}
                                text={
                                    getString('virtualAppointmentAddVirtualRoom') ||
                                    'Add virtual room'
                                }
                                onClick={() => setIsVirtualRoom(true)}
                            />
                        )}

                        {isVirtualRoom && (
                            <VirtualRoomWrapper>
                                <VirtualRoomText>{VIRTUAL_ROOM_TEXT}</VirtualRoomText>
                                <VirtualRoomInfo>
                                    {VIRTUAL_ROOM_INFO}
                                </VirtualRoomInfo>
                                <VirtualRoomIcon
                                    id="virtual-room-remove-icon"
                                    onClick={() => setIsVirtualRoom(false)}
                                >
                                    {ICON_CLOSE}
                                </VirtualRoomIcon>
                            </VirtualRoomWrapper>
                        )}
                    </FlexRow>
                </FlexBigContainer>
            </Flex>

            {loading && <Loader />}
        </ComplexDialog>
    );
};

export default WithTimezone(
    connect(null, {
        sendChatMessage: sendChatNotification,
    })(AppointmentModal),
);
