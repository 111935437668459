import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Divider, TextField } from 'react-md';

import { getPost } from '../selectors';
import { makeComment as makeCommentAction } from '../actions';
import CommentItem from '../components/CommentItem';
import { ImageAvatar } from '../../../components/Icons';
import Auth from '../../../services/api/auth';
import FontIcon from 'react-md/lib/FontIcons';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';

const DividerStyled = styled(Divider)`
    margin: 0px !important;
`;

const CommentsContainer = styled.div`\
  max-height: 300px;
  overflow: scroll;
`;

const InputContainer = styled.div`
    display: flex;
`;

const IconContainer = styled.div`
    width: auto;
    height: auto;
    padding: 8px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const StyledTextField = styled(TextField)`
    border-radius: 4px;
    display: flex;
    align-content: center;
    padding: 12px 12px 16px 12px;

    input {
        margin-top: 0;
        width: 100%;
        margin-right: 32px;
    }
    hr {
        display: none;
    }

    .md-text-field-inline-indicator {
        top: 0;
    }
`;

const SendIconWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;

    .md-icon {
        color: inherit;
    }

    &:hover .md-icon {
        color: rgba(0, 0, 0, 0.5);
    }
`;

const ENTER_CODE = 13;

const PostDetail = ({ post, makeComment }) => {
    const { comments } = post;
    const user = Auth.getUser();

    const [value, setValue] = useState('');
    const { trackAchievement } = useAchievementActions();

    const onChange = newValue => {
        setValue(newValue);
    };

    const onSubmitComment = () => {
        if (value) {
            makeComment(post, value);
            setValue('');

            trackAchievement(AchievementType.COMMENT_SOCIAL_POST);
            if (!comments.length) {
                trackAchievement(AchievementType.FIRST_COMMENT_SOCIAL_POST);
            }
        }
    };

    const onKeyPress = e => {
        if (e.charCode === ENTER_CODE) {
            onSubmitComment();
        }
    };

    const renderComment = commentId => <CommentItem id={commentId} />;

    return (
        <>
            <CommentsContainer>{comments.map(renderComment)}</CommentsContainer>
            <DividerStyled />
            <InputContainer>
                <IconContainer>
                    <ImageAvatar imageId={user.imageUrl || user.image} avatarType={'persons'} />
                </IconContainer>
                <StyledTextField
                    value={value}
                    onChange={onChange}
                    type="text"
                    placeholder="Add a comment..."
                    inlineIndicator={
                        <SendIconWrapper onClick={onSubmitComment}>
                            <FontIcon>send</FontIcon>
                        </SendIconWrapper>
                    }
                    onKeyPress={onKeyPress}
                />
            </InputContainer>
        </>
    );
};

PostDetail.propTypes = {
    post: PropTypes.shape({
        comments: PropTypes.arrayOf(PropTypes.number),
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    makeComment: PropTypes.func,
};

PostDetail.defaultProps = {
    post: {
        comments: [],
        id: 0,
    },
    makeComment: () => {},
};

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps;
    return {
        post: getPost(id, state),
    };
};

export default connect(mapStateToProps, {
    makeComment: makeCommentAction,
})(PostDetail);
