import React, { useMemo, useState } from 'react';
import get from 'lodash/get';
import { useGlobalState } from '../../../../../utils/container';
import StreamPlayer from '../../common/StreamPlayer';
import styled, { css } from 'styled-components';
import ActivePresenter from './ActivePresenter';
import { AccountCircleIconWrapper, ActionButtonsWrapper } from '../../../styles';
import Auth from '../../../../../services/api/auth';
import { FontIcon } from 'react-md';
import * as palette from '../../../../../components/General/Variables';

const MainVideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    background: #000;
    border-radius: 8px;

    ${props =>
        props.hidden &&
        css`
            position: absolute;
            z-index: -1;
        `}
`;

const Wrapper = styled.div`
    height: 100%;

    .stream-player.video-on {
        height: 100%;
        width: 100%;
        border: 8px;
        [id*='player'] {
            background: transparent !important;
        }

        video {
            border-radius: 8px;
            background: #fff;
            /* This used to work for the parent element of button divs */
            /* But it does not work with newer browsers, the below doesn't hide the play button parent div */
            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
            }

            /* Old shadow dom for play button */

            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
            }

            /* New shadow dom for play button */

            /* This one works! */

            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
            }
        }
    }
`;

const Video = () => {
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const stateCtx = useGlobalState();
    const { streams, virtualEventUser, peers } = stateCtx;
    const { isVideoOn } = virtualEventUser;

    //Host stream. virtualEventUser is the active user
    const activeStreamData = useMemo(() => {
        if (!streams.length) {
            return null;
        }
        //const foundStream = streams[0];
        const foundStream = (streams || []).find(
            stream => stream && virtualEventUser && stream.streamId === virtualEventUser.UserId,
        );

        if (foundStream) {
            return {
                stream: foundStream,
                virtualEventUser,
            };
        } else {
            return null;
        }
    }, [virtualEventUser, streams]);

    const screenSharingStream = useMemo(() => {
        if (!streams.length) {
            return null;
        }

        //const foundStream = streams[0];
        const foundStream = (streams || []).find(
            stream =>
                stream &&
                virtualEventUser &&
                stream.streamId === virtualEventUser.UserId + 10000000,
        );

        if (foundStream) {
            setIsScreenSharing(true);
            return {
                stream: foundStream,
                virtualEventUser,
            };
        } else {
            setIsScreenSharing(false);
            return null;
        }
    }, [get(virtualEventUser, 'UserId'), streams]);

    const hasPartner = useMemo(() => {
        return !!peers.find(peerId => peerId === virtualEventUser.UserId);
    }, [peers]);

    return (
        <Wrapper>
            {!isVideoOn && !activeStreamData && hasPartner && (
                <ActionButtonsWrapper>
                    <AccountCircleIconWrapper imageUrl={virtualEventUser.User.imageUrl}>
                        {!virtualEventUser.User.imageUrl && (
                            <FontIcon
                                style={{ fontSize: '30px', display: 'flex', color: 'grey' }}
                            >
                                {palette.ICON_PROFILE}
                            </FontIcon>
                        )}
                        <ActivePresenter virtualEventUser={virtualEventUser} />
                    </AccountCircleIconWrapper>
                </ActionButtonsWrapper>
            )}

            {activeStreamData && (
                <MainVideoWrapper hidden={isScreenSharing && screenSharingStream}>
                    <StreamPlayer
                        key={activeStreamData.stream.getId()}
                        stream={activeStreamData.stream}
                        isVideoOn={!(isScreenSharing && screenSharingStream)}
                        uid={activeStreamData.stream.getId()}
                        domId={`stream-player-${activeStreamData.stream.getId()}`}
                    >
                        {activeStreamData.virtualEventUser && (
                            <React.Fragment>
                                {!isVideoOn && (
                                    <ActionButtonsWrapper>
                                        <AccountCircleIconWrapper imageUrl={activeStreamData.virtualEventUser.imageUrl}>
                                            {!activeStreamData.virtualEventUser.imageUrl && (
                                                <FontIcon
                                                    style={{
                                                        fontSize: '30px',
                                                        display: 'flex',
                                                        color: 'grey',
                                                    }}
                                                >
                                                    {palette.ICON_PROFILE}
                                                </FontIcon>
                                            )}
                                        </AccountCircleIconWrapper>
                                    </ActionButtonsWrapper>
                                )}
                                <ActivePresenter virtualEventUser={activeStreamData.virtualEventUser} />
                            </React.Fragment>
                        )}
                    </StreamPlayer>
                </MainVideoWrapper>
            )}

            {isScreenSharing && screenSharingStream && (
                <MainVideoWrapper>
                    <StreamPlayer
                        key={screenSharingStream.stream.getId()}
                        stream={screenSharingStream.stream}
                        isVideoOn={true}
                        uid={screenSharingStream.stream.getId()}
                        domId={`stream-player-${screenSharingStream.stream.getId()}`}
                    >
                        {screenSharingStream.virtualEventUser && (
                            <ActivePresenter virtualEventUser={activeStreamData.virtualEventUser} />
                        )}
                    </StreamPlayer>
                </MainVideoWrapper>
            )}
        </Wrapper>
    );
};

export default Video;
