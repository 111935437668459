import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../../components/HeaderNoSidebarMenu/Header';
import {
    AppContent,
    EventIcon,
    MenuItemMobile,
    StyledPopover,
    TabsContainer,
    toolbarTitleStyle,
} from '../style/navigationStyle';
import Tabs from '../../components/Tabs';
import { MenuItem } from '@material-ui/core';
import { Tooltip } from '../../scenes/common/Button';
import * as palette from '../../components/General/Variables';
import { FontIcon } from 'react-md';
import { Flex } from '../../scenes/common/Flex';

const TopNavigation = ({
    title,
    children,
    navigationItems,
    history,
    location,
    isSidepanelOpened,
    appState,
    newVersionAvailable,
    updateServiceWorker,
    onServiceWorkerUpdate,
    waitingWorker,
    setTitle,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorItems, setAnchorItems] = useState(null);
    const [labelMarginLeft, setLabelMarginLeft] = useState(null);
    const [hover, setHover] = useState(false);
    const [anchorTooltipLabel, setAnchorTooltipLabel] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (navigationItems?.length) {
            navigationItems.forEach(item => {
                if (item?.subItems?.length && item.kind !== 'tabs') {
                    item.subItems.map(it => {
                        if (it?.link?.linkType === 'mycongress') {
                            if (it.name === 'attendees') {
                                it.name = 'attendee_list';
                            }
                            if (it.name === 'eventwall') {
                                it.name = 'social_feed';
                            }
                            return it;
                        }
                    });
                }
            });
        }
    }, [navigationItems]);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    const handleClick = (event, items) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setAnchorItems(items);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorItems(null);
    };

    const handleHover = (event, label) => {
        event.stopPropagation();
        const elementPosition = event.target.getBoundingClientRect();
        const tabWidth = elementPosition.width;
        const tooltipWidth = label.length * 7.05 + 16;
        const emptyWidth = tabWidth - tooltipWidth;
        setLabelMarginLeft(
            tabWidth > tooltipWidth
                ? elementPosition.left + Math.abs(emptyWidth) / 2
                : elementPosition.left,
        );
        setAnchorTooltipLabel(label);
    };

    const tabsData = [];
    const mobileNavigationData = [];
    if (navigationItems) {
        navigationItems.forEach(item => {
            tabsData.push({
                labelIcon: item.webappIcon,
                onClick: e => {
                    if (item?.subItems?.length && item.kind !== 'tabs') {
                        handleClick(e, item.subItems);
                    } else {
                        setTitle(item.label);
                        history.push(item.screen === 'home' ? '/' : `/${item.screen}`);
                    }
                },
                onMouseEnter: e => {
                    handleHover(e, item.label);
                },
                anchorTooltipLabel,
            });
            if (item?.subItems?.length && item.kind !== 'tabs') {
                item.subItems.map(it => {
                    mobileNavigationData.push(it);
                });
            } else {
                mobileNavigationData.push(item);
            }
        });
    }

    const initialPathName = location.pathname.split('/').slice(0, 2).join('/');
    let activeIndex = 0;
    let subitemTitle = '';
    if (location && location.pathname && location.pathname.length) {
        let activeItem;
        if (navigationItems) {
            activeItem = navigationItems.find(item => `/${item.screen}` === initialPathName);
            if (!activeItem) {
                activeItem = navigationItems.find(item => {
                    if (item?.subItems?.length) {
                        const currentSelectedSubitem = item.subItems.find(
                            subIt => `/${subIt.name}` === initialPathName,
                        );
                        subitemTitle = currentSelectedSubitem && currentSelectedSubitem.title;

                        return currentSelectedSubitem;
                    }
                });
            }
        }
        activeIndex = (activeItem && activeItem.col) || '0';
    }

    const isVirtualSession =
        location.pathname &&
        (location.pathname.toLowerCase().indexOf('virtual-session-round') !== -1 ||
            location.pathname.toLowerCase().indexOf('virtual-session') !== -1);

    const hideHeader =
        (location.pathname && location.pathname.toLowerCase().indexOf('/exhibitorbooth') > -1) ||
        isVirtualSession;

    return (
        <React.Fragment>
            {!hideHeader && (
                <div>
                    <Header
                        navTitle={title}
                        light={true}
                        newVersionAvailable={newVersionAvailable}
                        updateServiceWorker={updateServiceWorker}
                        onServiceWorkerUpdate={onServiceWorkerUpdate}
                        waitingWorker={waitingWorker}
                    >
                        {appState.pageHeader && (
                            <EventIcon
                                src={appState.pageHeader}
                                marginLeft={'16px'}
                                onClick={() => {
                                    history.push('/');
                                }}
                            />
                        )}
                        {windowWidth > palette.MAX_PHONE_XS_INT ? (
                            <>
                                <TabsContainer>
                                    <Tabs
                                        data={tabsData}
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                        currentTab={activeIndex}
                                        topNavigationTabs={true}
                                    />
                                </TabsContainer>
                                <StyledPopover
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    {anchorItems?.map(item => (
                                        <MenuItem
                                            onClick={() => {
                                                history.push(`/${item.name}`);
                                                handleClose();
                                            }}
                                            key={item.title}
                                        >
                                            {item.title}
                                        </MenuItem>
                                    ))}
                                    {anchorTooltipLabel && !anchorItems && (
                                        <MenuItem
                                            onClick={() => {
                                                handleClose();
                                            }}
                                            key={anchorTooltipLabel}
                                        >
                                            {anchorTooltipLabel}
                                        </MenuItem>
                                    )}
                                </StyledPopover>
                            </>
                        ) : (
                            <>
                                <Flex
                                    height={'100%'}
                                    alignItems={'center'}
                                    onClick={e => setAnchorEl(e.currentTarget)}
                                >
                                    <FontIcon iconClassName={'material-icons material-icons-round'}>
                                        menu
                                    </FontIcon>
                                </Flex>
                                <StyledPopover
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    {mobileNavigationData?.map(item => (
                                        <MenuItemMobile
                                            onClick={() => {
                                                history.push(
                                                    item.screen === 'home'
                                                        ? '/'
                                                        : `/${item.screenName}`,
                                                );
                                                handleClose();
                                            }}
                                            key={item.label || item.title}
                                            active={initialPathName === `/${item.screenName}`}
                                        >
                                            <FontIcon
                                                iconClassName={
                                                    'material-icons material-icons-round'
                                                }
                                            >
                                                {item.webappIcon || 'add'}
                                            </FontIcon>
                                            {item.label || item.title}
                                            {initialPathName === `/${item.screenName}` && (
                                                <FontIcon
                                                    iconClassName={
                                                        'material-icons material-icons-round'
                                                    }
                                                >
                                                    check
                                                </FontIcon>
                                            )}
                                        </MenuItemMobile>
                                    ))}
                                </StyledPopover>
                            </>
                        )}
                    </Header>
                    <div
                        style={{
                            ...toolbarTitleStyle,
                            fontSize: '21px',
                            padding: '24px 20px',
                            width: '100%',
                            background: '#fff',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                        }}
                    >
                        {subitemTitle || title}
                    </div>
                </div>
            )}
            {hover && (
                <Tooltip bottom={'unset'} top={'64px'} left={`${labelMarginLeft}px`}>
                    {anchorTooltipLabel}
                </Tooltip>
            )}
            <AppContent
                isSidePanelOpened={isSidepanelOpened}
                noHeightCalc={
                    isVirtualSession || location.pathname.includes('exhibitorbooth') ? 1 : 0
                }
                id={'main-container'}
            >
                {children}
            </AppContent>
        </React.Fragment>
    );
};

export default withRouter(TopNavigation);
