import sortBy from 'lodash/sortBy';

import React, { useContext, useEffect, useRef, useState } from 'react';
import NavigationBar from '../../../components/DetailHeader/NavigationBar';
import ThemeContext from '../../../components/Theme/ThemeContext';
import throttle from 'lodash/throttle';
import styled from 'styled-components';
import TimeslotListItem from '../../../components/ObjectListItem/Timeslot';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { fetchUpcomingSessions } from '../api';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/General/Loader';
import { parseTileGroups } from '../service/parsing';
import { TILE_GROUP_TYPES } from '../constants';
import moment from 'moment';
import NoUpcomingSessionsPlaceholder from '../components/NoUpcomingSessionsPlaceholder';
import { TimezoneContext } from '../../Timezone/context';
import * as palette from '../../../components/General/Variables';
import { DetailDrawer } from '../../../components/General/DetailDrawer';

const StyledDetailDrawer = styled(DetailDrawer)`
    z-index: 10;
`;
const Scrollable = styled.section.attrs({
    className: 'scrollable-div',
})`
    height: 100%;
    overflow: auto;
`;

const UpcomingSessionsDetailPage = ({ goBack, closeLink, rootRoute, history }) => {
    const [sessions, setSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [noSessionsMessage, setNoSessionsMessage] = useState('');
    const { timeslotToTimezone } = useContext(TimezoneContext);
    const navBar = useRef();
    let scroll = 0;
    let scrollDirection = 'UP';
    const _handleScroll = throttle(() => {
        let newScroll = document.getElementById('scrollable').scrollTop;

        if (scroll > newScroll && scrollDirection !== 'UP' && newScroll > 48) {
            navBar.current.className = 'fixed-navbar';
            scrollDirection = 'UP';
        } else if (scroll <= newScroll && scrollDirection !== 'DOWN' && newScroll > 48) {
            navBar.current.className = 'auto-navbar';
            scrollDirection = 'DOWN';
        }

        scroll = newScroll;
    }, 100);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const appState = await getLocalAppStateAsync();
            const tileGroups = parseTileGroups(appState.tileGroups);
            const upcomingSessionsTileGroups = tileGroups.find(
                item => item.type === TILE_GROUP_TYPES.UPCOMING_SESSIONS,
            );
            const happeningNowPageId =
                upcomingSessionsTileGroups &&
                upcomingSessionsTileGroups.items &&
                upcomingSessionsTileGroups.items.length &&
                upcomingSessionsTileGroups.items[0].pageId;
            const { sessions: fetchedSessions, message } = await fetchUpcomingSessions(
                appState.eventId,
                happeningNowPageId,
            );

            const sessions = sortBy(fetchedSessions, ['start', 'name']);
            setSessions(sessions);
            setIsLoading(false);
            setNoSessionsMessage(message);
        })();
    }, []);

    const onSessionClick = item => {
        history.push(`${rootRoute}/timeslot/${item.id}`);
    };

    return (
        <StyledDetailDrawer>
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <Scrollable id="scrollable" onScroll={_handleScroll}>
                        <div ref={navBar}>
                            <NavigationBar
                                scrollDirection={scrollDirection}
                                goBack={goBack}
                                closePath={closeLink}
                                typeName={'Upcoming sessions'}
                                background={palette.COLOR_WHITE}
                            />
                        </div>

                        {isLoading && <Loader />}

                        {!isLoading &&
                            sessions.map((it, index) => {
                                const item = timeslotToTimezone(it);
                                const timeToDisplay = moment.utc(item.start).format('HH:mm');
                                const dateToDisplay = moment.utc(item.start).format('MMM D');

                                item.dateTime = {
                                    startTimeToDisplay: timeToDisplay,
                                    startDateToDisplay: dateToDisplay,
                                };

                                return (
                                    <div
                                        key={`detail-page-upcoming-session-${index}`}
                                        onClick={() => onSessionClick(item)}
                                    >
                                        <TimeslotListItem timeslot={item} />
                                    </div>
                                );
                            })}
                        {!isLoading && sessions.length === 0 && (
                            <NoUpcomingSessionsPlaceholder text={noSessionsMessage} />
                        )}
                    </Scrollable>
                )}
            </ThemeContext.Consumer>
        </StyledDetailDrawer>
    );
};

export default withRouter(UpcomingSessionsDetailPage);
