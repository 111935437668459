/* eslint-disable class-methods-use-this */
import { client, xml } from '@xmpp/client';

import parseStanza from '../api/stanzaParser';

const generateId = () => {
    // https://gist.github.com/gordonbrander/2230317
    return '_' + Math.random().toString(36).substr(2, 9);
};

class TalkManager {
    constructor() {
        if (!TalkManager.instance) {
            this.xmpp = null;
            TalkManager.instance = this;
            this.connected = false;
            this.resourceId = generateId();
            this.handleRoster = null;
            this.handleMessage = null;
            this.handleGroupMessage = null;
            this.handlePresence = null;
            this.handleServerStatus = null;
            this.reconnectingAttempts = 0;
            this.cachedMessageToBeSent = null;
        }
        return TalkManager.instance;
    }

    sendCachedMessage = async () => {
        if (!this.cachedMessageToBeSent) {
            return;
        }

        await this.sendMessage({
            ...this.cachedMessageToBeSent,
            isPrivateMessage: false,
        });

        this.cachedMessageToBeSent = null;
    };

    joinRooms = async ({ rooms, userId }) => {
        if (!this.xmpp) {
            return null;
        }
        const isOnline = this.xmpp.status === 'online';
        if (!isOnline) {
            console.log(` >>>>>>>>>>>>>>>>>>>>>>>>>>>>> Error joining room: XMPP is offline`);
            return null;
        }

        const promises = [];

        rooms.forEach(room => {
            /* 
                Important: I'm now adding resourceId to the presence. This should allow users to
                participate on group chat with multiple resources at the same time.
                If messages are being duplicated or users never leaving rooms, this might be an issue
            */
            console.log(' >>>>>>>>>>>>>>>>>>>>>>>>>>>>> CHAT -> joining room: ' + room.jid);
            const presence = xml(
                'presence',
                { to: `${room.jid}/${userId}_webapp${this.resourceId}` },
                xml('x', 'http://jabber.org/protocol/muc'),
                xml('history', {}, { maxchars: '0' }),
            );
            promises.push(this.xmpp.send(presence));
        });

        return Promise.all(promises);
    };

    leaveRooms = ({ rooms, userId }) => {
        if (!this.xmpp) {
            return;
        }

        const promises = [];
        rooms.forEach(room => {
            if (room && room.jid) {
                console.log(' >>>>>>>>>>>>>>>>>>>>>>>>>>>>> CHAT -> leaving room: ' + room.jid);
                const exitPresence = xml('presence', {
                    to: `${room.jid}/${userId}`,
                    type: 'unavailable',
                });
                promises.push(this.xmpp.send(exitPresence));
            }
        });

        return Promise.all(promises);
    };

    addToRoster = jid => {
        if (!this.xmpp) {
            return null;
        }

        const xmlRoster = xml(
            'iq',
            { id: 'roster_add', type: 'set' },
            xml('query', 'jabber:iq:roster', xml('item', { jid })),
        );
        return this.xmpp.send(xmlRoster);
    };

    // Block/Unblock follows this protocol https://xmpp.org/extensions/xep-0191.html
    blockUser = jid => {
        if (!this.xmpp) {
            return null;
        }

        const xmlBlock = xml(
            'iq',
            { id: 'block_1', type: 'set' },
            xml('block', 'urn:xmpp:blocking', xml('item', { jid })),
        );

        return this.xmpp.send(xmlBlock);
    };

    unblockUser = jid => {
        if (!this.xmpp) {
            return null;
        }

        const xmlUnblock = xml(
            'iq',
            { id: 'unblock_1', type: 'set' },
            xml('unblock', 'urn:xmpp:blocking', xml('item', { jid })),
        );

        return this.xmpp.send(xmlUnblock);
    };

    getBlockList = () => {
        if (!this.xmpp) {
            return;
        }
        const blockList = xml(
            'iq',
            { id: 'blocklist_1', type: 'get' },
            xml('blocklist', 'urn:xmpp:blocking'),
        );
        this.xmpp.send(blockList);
    };

    subscribe = to => {
        if (!this.xmpp) {
            return;
        }

        const subscribeXml = xml('presence', { to, type: 'subscribe' });
        this.xmpp.send(subscribeXml);
    };

    acceptSubscription = to => {
        if (!this.xmpp) {
            return;
        }
        const acceptXml = xml('presence', { to, type: 'subscribed' });
        this.xmpp.send(acceptXml);
    };

    updateMyStatus = active => {
        if (!this.xmpp) {
            return null;
        }

        const presence = xml('presence');

        if (!active) {
            presence.attrs.type = 'unavailable';
        }

        return this.xmpp.send(presence);
    };

    async sendMessage({ to, message, isPrivateMessage }) {
        if (!this.xmpp) {
            return null;
        }
        const isOnline = this.xmpp.status === 'online';
        if (!isOnline) {
            console.log(` >>>>>>>>>>>>>>>>>>>>>>>>>>>>> Error sending message: XMPP is offline`);
            return null;
        }

        // Takes destination and message from UI and sends to server
        const messageType = isPrivateMessage ? 'chat' : 'groupchat';
        const msg = xml('message', { type: messageType, to }, xml('body', {}, message));
        return this.xmpp.send(msg);
    }

    async getRoster() {
        if (!this.xmpp) {
            return;
        }

        const roster = xml('iq', { id: 'roster_1', type: 'get' }, xml('query', 'jabber:iq:roster'));
        await this.xmpp.send(roster);
    }

    connect({
        chatUrl,
        userData,
        handleRoster,
        handleMessage,
        handleGroupMessage,
        handlePresence,
        handleServerStatus,
    }) {
        this.handleRoster = handleRoster;
        this.handleMessage = handleMessage;
        this.handleGroupMessage = handleGroupMessage;
        this.handlePresence = handlePresence;
        this.handleServerStatus = handleServerStatus;

        const { jid, jpassword } = userData;
        if (chatUrl && jid && jpassword) {
            // TODO: add a unique identifier for the browser session
            const service = `wss://${chatUrl}:5443/ws`;
            const xmpp = client({
                service,
                jid,
                resource: `webapp${this.resourceId}`,
                username: jid,
                password: jpassword,
            });
            xmpp.reconnect.delay = 5000;
            xmpp.on('error', err => {
                // console.error(`XMPP ERROR: ${JSON.stringify(err, null, '    ')}`);
                handleServerStatus({ online: false, error: err });
            });

            xmpp.on('offline', () => {
                console.log('XMPP is offline');
                handleServerStatus({ online: false, error: { offline: true } });
                this.connected = false;
            });

            xmpp.on('stanza', async stanza => {
                parseStanza(stanza);
            });

            xmpp.on('online', async () => {
                console.log('XMPP IS ONLINE');
                this.connected = true;
                handleServerStatus({ online: true, error: false });
                await this.getRoster();
                // Makes itself available
                await xmpp.send(xml('presence'));
            });

            xmpp.on('status', status => {
                console.log('XMPP Status: ' + status);
            });

            xmpp.reconnect.on('reconnecting', status => {
                if (this.reconnectingAttempts >= 2) {
                    console.log('XMPP: too many reconnect attempts, stopping XMPP');
                    xmpp.stop();
                    this.xmpp = null;
                    this.reconnectingAttempts = 0;
                    return;
                }
                this.reconnectingAttempts++;
            });

            xmpp.start().catch(err => {
                console.error(`XMPP START ERROR: ${JSON.stringify(err, null, '    ')}`);
            });

            this.xmpp = xmpp;
        }
    }

    getXmppOnline = () => {
        if (!this.xmpp) {
            return false;
        }
        return this.xmpp.status === 'online';
    };

    async disconnect() {
        if (!this.xmpp) {
            return;
        }
        await this.updateMyStatus(false);
        await this.xmpp.stop();
        this.xmpp = null;
    }
}

const instance = new TalkManager();

export default instance;
