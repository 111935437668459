import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    checkShouldStartConversation as checkShouldStartConversationAction,
    leaveRooms as leaveRoomsAction,
    addRepresentativesToConversation as addRepresentativesAction,
} from '../../Talk/actions';
import { getConversationWithRoomId, getServerOnline } from '../../Talk/selectors';
import TalkRoomComponent from '../components/TalkRoomComponent';

const TalkRoomContainer = ({
    chatType,
    conversation,
    representatives,
    addRepresentatives,
    checkShouldStartConversation,
    leaveRooms,
    goBack,
    closePath,
    title,
    chatServerOnline,
}) => {
    const [isCheckingConversation, setIsCheckingConversation] = useState(true);

    useEffect(() => {
        if (chatServerOnline) {
            checkConversation();
        }

        return () => {
            if (conversation) {
                // leaveRooms([conversation]);
                leaveRooms();
            }
        };
    }, [chatServerOnline, conversation.roomId]);

    const checkConversation = async () => {
        await checkShouldStartConversation(conversation);
        if (representatives && representatives.length) {
            addRepresentatives(conversation.id, representatives);
        }
        setIsCheckingConversation(false);
    };

    return (
        <TalkRoomComponent
            conversation={conversation}
            chatType={chatType}
            title={title}
            goBack={goBack}
            closePath={closePath}
            checking={isCheckingConversation}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    let conversation = ownProps.conversation;
    const serverOnline = getServerOnline(state);
    if (!conversation.jid) {
        const stateConversation = getConversationWithRoomId(state, conversation.roomId);
        if (stateConversation) {
            conversation = stateConversation;
        }
    }

    return {
        conversation,
        chatServerOnline: serverOnline,
    };
};

export default connect(mapStateToProps, {
    checkShouldStartConversation: checkShouldStartConversationAction,
    leaveRooms: leaveRoomsAction,
    addRepresentatives: addRepresentativesAction,
})(withRouter(TalkRoomContainer));
