import * as StyledComponent from '../../style/modalsStyle';
import { BackgroundAndAvatar } from './BackgroundAndAvatar';
import { PersonalInformation } from './PersonalInformation';
import { SocialLinks } from './SocialLinks';
import * as palette from '../../../../components/General/Variables';
import { StyledForm } from '../../../../components/SignIn/styles';
import React from 'react';
import { getString } from '../../../../services/api/store';
import * as constants from '../../constants';
import Button, { buttonTypes } from '../../../common/Button';

export const ProfileForm = ({
    theme,
    isDesktop,
    afterSignUp,
    fieldValues,
    onImageChange,
    authUser,
    onChange,
    countries,
    onSelectChange,
    setShowSocialLinksModal,
    setShowContactInfoModal,
    importedFields,
}) => {
    const PROFILE_SUBTITLE =
        getString('profileCheckMyProfileScreenSubtitle') || constants.PROFILE_SUBTITLE;
    const SOCIAL_LINKS = getString('profileSectionLinksTitle') || constants.SOCIAL_LINKS;
    const CONTACT_INFORMATION_TITLE =
        getString('profileContactInfoDivider') || constants.CONTACT_INFO;
    const CONTACT_INFORMATION_SUBTITLE =
        getString('profileContactInfoMessage') || constants.VISIBLE_FOR_CONTACTS;

    return (
        <StyledForm maincolor={theme.primary} horizontalMargin={isDesktop && '0px'}>
            {afterSignUp && (
                <StyledComponent.ProfileInfoText>
                    {PROFILE_SUBTITLE}
                </StyledComponent.ProfileInfoText>
            )}

            <BackgroundAndAvatar
                theme={theme}
                fieldValues={fieldValues}
                onImageChange={onImageChange}
                authUser={authUser}
            />

            <PersonalInformation
                fieldValues={fieldValues}
                onChange={onChange}
                countries={countries}
                onSelectChange={onSelectChange}
                afterSignUp={afterSignUp}
                importedFields={importedFields}
            />
            <StyledComponent.FormWrapper>
                <StyledComponent.SectionText noMarginLeft={true}>
                    {SOCIAL_LINKS}
                </StyledComponent.SectionText>
                <SocialLinks fieldValues={fieldValues} />
                <Button
                    type={buttonTypes.GREY_LONG}
                    background={theme.primary}
                    icon={palette.ICON_PEN_EDIT}
                    text={getString('edit', 'Edit')}
                    onClick={() => setShowSocialLinksModal(true)}
                    style={{ marginTop: '12px' }}
                />

                <StyledComponent.SectionText noMarginLeft={true}>
                    {CONTACT_INFORMATION_TITLE}
                </StyledComponent.SectionText>
                <StyledComponent.SectionDetails>
                    {CONTACT_INFORMATION_SUBTITLE}
                </StyledComponent.SectionDetails>
                <Button
                    type={buttonTypes.GREY_LONG}
                    background={theme.primary}
                    icon={palette.ICON_PEN_EDIT}
                    text={getString('edit', 'Edit')}
                    onClick={() => setShowContactInfoModal(true)}
                    style={{ marginTop: '12px', marginBottom: '16px' }}
                />
            </StyledComponent.FormWrapper>
        </StyledForm>
    );
};
