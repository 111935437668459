import styled from 'styled-components';
import { Button, Cell, Grid, FontIcon, TextField } from 'react-md';
import * as palette from '../../../components/General/Variables';

export const InteractivityCell = styled(Cell)`
    text-align: left;
    width: 100% !important;
`;
export const InteractivityGrid = styled(Grid)`
    padding-left: 10px;
    padding-right: 10px;
`;
export const Icon = styled(FontIcon)`
    margin-right: 4px;
`;
export const ButtonStyled = styled(Button)`
    border: 1px solid ${props => props.primarycolor} !important;
    border-radius: 24px !important;
    color: ${props =>
        props.theming === 'inversed' ? props.primarycolor : palette.COLOR_WHITE} !important;
    background-color: ${props =>
        props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor} !important;
    display: block;
    width: fit-content;

    &:disabled {
        background-color: ${palette.COLOR_GREY} !important;
        border-color: ${palette.COLOR_LIGHT_GREY} !important;
        color: ${palette.COLOR_TEXT_LIGHT} !important;
    }
    &.md-btn--dialog,
    &.md-btn--text {
        text-transform: none;
        font-size: 16px;
        height: 36px;
        font-weight: 500;
    }

    .md-icon-separator {
        justify-content: space-between;
        span {
            padding-left: 0;
            text-align: right;
        }
    }

    &.md-btn--dialog &:hover {
        background-color: ${props =>
            props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    }
`;

export const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    font-family: Cabin, sans-serif;
    font-style: normal;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
`;

export const SectionText = styled.div`
    white-space: pre-wrap;
    word-break: break-word;
    font-family: Roboto, sans-serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    margin: ${props => (props.margin ? props.margin : '12px 0 16px 0')} !important;

    & > * {
        color: rgba(0, 0, 0, 0.87);
    }
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #ebedf0;
    margin: 0 !important;
`;

export const Padding16px = styled.div`
    padding: 16px;
`;

export const Container = styled.div`
    ${props => (props.paddingDisabled ? '' : 'padding: 16px')};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.15),
        0px 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: ${palette.COLOR_WHITE};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;

    & > div:nth-child(2) {
        margin: 8px 0;
        width: 100%;
    }
`;

export const StyledTextField = styled(TextField)`
    border-radius: 4px;
    border: solid 1px #cccccc;
    background-color: #ffffff;
    display: flex;
    align-content: center;
    padding: 12px 12px 16px 12px;
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}`}
    input {
        margin-top: 0;
        width: ${props => props.inputWidth || '80%'};
    }
    hr {
        display: none;
    }
`;
