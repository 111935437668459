import React, { useEffect, useRef, useState } from 'react';
import {
    ArrowButton,
    ArrowButtonRight,
    FilterLabels,
    SearchAndArrowContainer,
    StyledDropdownMenu,
    StyledListItem,
    TabsContainerStyled,
    TabsWrapper,
    ToolbarContainer,
    ToolbarIcon,
    ViewButton,
} from '../style/style';
import { AccessibleFakeButton, DropdownMenu, FontIcon, IconSeparator } from 'react-md';
import { Flex } from '../../common/Flex';
import Loader from '../../../components/General/Loader';
import ThemeContext from '../../../components/Theme/ThemeContext';
import Search from '../../../components/General/Search';
import { programmeViewTypes } from '../constants/index';
import { getString } from '../../../services/api/store';
import { NewNotficationsBadge } from '../../Notifications/components/NotificationButton';
import flatten from 'lodash/flatten';
import ClassifierIconLabelComponent from '../../../components/Icons/ClassifierIconLabelComponent';

const Toolbar = ({
    activeTab,
    tabs,
    openViewDropDown,
    isSidepanelOpened,
    renderListView,
    renderTimetable,
    renderVerticalTimetable,
    onVisibilityChange,
    loading,
    onTabClick,
    setShowFilter,
    renderTimezoneInfo,
    action,
    selectedFilters,
    viewType,
    changeViewType,
    numberOfAppliedFilters = 0,
}) => {
    const [filterString, setFilterString] = useState(null);
    const [extendSearch, setExtendSearch] = useState(false);
    const [smallSearchIcon, setSmallSearchIcon] = useState(false);
    const [applyGrid, setApplyGrid] = useState(false);
    const [gridWidth, setGridWidth] = useState(false);
    const [gridDirection, setGridDirection] = useState('left');
    const [tabsScrollLeft, setTabsScrollLeft] = useState(0);
    const [isScrolledToRight, setIisScrolledToRight] = useState(false);
    const [displayClassifiers, setDisplayClassifiers] = useState(true);
    const searchRef = useRef(null);
    const toolbarRef = useRef(null);
    const initialGridWidth = useRef(null);
    const tabsContainer = document.getElementsByClassName('md-tabs');

    const HORIZONTAL_VIEW_TEXT = getString('horizontalView', 'Horizontal view');
    const VERTICAL_VIEW_TEXT = getString('verticalView', 'Vertical view');
    const LIST_VIEW_TEXT = getString('listView', 'List view');
    const scorllableContainer = document.getElementById(
        `timetable-scrollable-container-${viewType?.toLowerCase()}`,
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('resize', handleResize);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (scorllableContainer) {
            scorllableContainer.addEventListener('scroll', handleScrollForClassifiers);
        }

        return () => {
            if (scorllableContainer) {
                scorllableContainer.removeEventListener('scroll', handleScrollForClassifiers);
            }
        };
    }, [scorllableContainer, viewType]);

    useEffect(() => {
        setTimeout(() => {
            handleResize();
        });
    }, [loading]);

    useEffect(() => {
        const addGridDirection = () => {
            if (
                tabsContainer[0].scrollLeft + tabsContainer[0].offsetWidth <
                tabsContainer[0].scrollWidth
            ) {
                setGridDirection('left');
            } else {
                setGridDirection('right');
            }
            setIisScrolledToRight(
                tabsContainer[0].offsetWidth ===
                    tabsContainer[0].scrollWidth - tabsContainer[0].scrollLeft,
            );
            setTabsScrollLeft(tabsContainer[0].scrollLeft);
        };

        tabsContainer &&
            tabsContainer[0] &&
            tabsContainer[0].addEventListener('scroll', addGridDirection);

        setApplyGrid(
            tabsContainer &&
                tabsContainer[0] &&
                tabsContainer[0].offsetWidth < tabsContainer[0].scrollWidth,
        );

        setGridWidth(tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth);

        if (!initialGridWidth.current) {
            initialGridWidth.current =
                tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth;
        }

        return () => {
            tabsContainer &&
                tabsContainer[0] &&
                tabsContainer[0].removeEventListener('scroll', addGridDirection);
        };
    }, [
        tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth,
        tabsContainer && tabsContainer[0] && tabsContainer[0].scrollLeft,
        isSidepanelOpened,
        extendSearch,
    ]);

    useEffect(() => {
        if (extendSearch) {
            setGridWidth(tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth - 100);
        } else {
            const addittionalWidth = smallSearchIcon ? 250 : 100;
            setGridWidth(
                tabsContainer &&
                    tabsContainer[0] &&
                    tabsContainer[0].offsetWidth + addittionalWidth,
            );
        }
    }, [extendSearch, smallSearchIcon, isSidepanelOpened]);

    const handleScrollForClassifiers = () => {
        setDisplayClassifiers(scorllableContainer.scrollTop < 5);
    };

    const handleScroll = scrollDirection => {
        const content = tabsContainer && tabsContainer[0];
        if (content && content.children && content.children.length) {
            const firstTab = document.getElementById(`program-0`);
            if (scrollDirection === 'right') {
                content.scrollLeft += firstTab.offsetWidth;
            } else if (scrollDirection === 'left') {
                content.scrollLeft -= firstTab.offsetWidth;
            }
        }
    };
    const handleResize = () => {
        setApplyGrid(
            tabsContainer &&
                tabsContainer[0] &&
                tabsContainer[0].offsetWidth < tabsContainer[0].scrollWidth,
        );

        setGridWidth(tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth);
        initialGridWidth.current =
            tabsContainer && tabsContainer[0] && tabsContainer[0].offsetWidth;
    };

    const handleClickOutside = () => {};

    const onSearch = str => {
        action({
            filters: selectedFilters.filters,
            text: str,
            myProgram: selectedFilters.myProgram,
        });
    };

    const clearSearch = () => {
        setFilterString(null);
        action({
            filters: {},
            text: '',
            myProgram: selectedFilters.myProgram,
        });
    };

    const clearGivenFilter = item => {
        Object.keys(selectedFilters.filters).map(key => {
            selectedFilters.filters[key] = selectedFilters.filters[key].filter(
                i => i.id !== item.id,
            );
            if (!selectedFilters.filters[key] || !selectedFilters.filters[key].length) {
                delete selectedFilters.filters[key];
            }
        });

        action({
            myProgram: selectedFilters.myProgram,
            filters: selectedFilters.filters,
            text: selectedFilters.text,
        });
    };

    const getTextByType = type => {
        switch (type) {
            case programmeViewTypes.HORIZONTAL_VIEW:
                return HORIZONTAL_VIEW_TEXT;
            case programmeViewTypes.VERTICAL_VIEW:
                return VERTICAL_VIEW_TEXT;
            case programmeViewTypes.LIST_VIEW:
                return LIST_VIEW_TEXT;
            default:
                return '';
        }
    };
    let selectedFiltersArray = [];
    let selectedFiltersWithDetails = [];
    if (selectedFilters?.filters && Object.keys(selectedFilters?.filters).length) {
        Object.keys(selectedFilters?.filters).map(key =>
            selectedFiltersArray.push(selectedFilters.filters[key]),
        );
        selectedFiltersWithDetails = flatten(selectedFiltersArray);
    }

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <ToolbarContainer innerRef={toolbarRef}>
                        <ArrowButton
                            label={''}
                            component={IconSeparator}
                            iconBefore
                            onClick={e => {
                                e.stopPropagation();
                                handleScroll('left');
                            }}
                            applyColor={applyGrid && tabsScrollLeft > 0}
                        >
                            <FontIcon>chevron_left</FontIcon>
                        </ArrowButton>
                        <TabsContainerStyled
                            className="tabs__page-layout"
                            colored
                            panelClassName="md-grxid"
                            onTabChange={indx => onTabClick(indx)}
                            activeTabIndex={activeTab}
                            applyGrid={applyGrid}
                            gridDirection={gridDirection}
                            gridWidth={
                                applyGrid && gridWidth > 0 ? gridWidth : initialGridWidth.current
                            }
                        >
                            <TabsWrapper tabId="program" mobile alignToKeyline={false}>
                                {tabs}
                            </TabsWrapper>
                        </TabsContainerStyled>
                        <Flex justifyContent={'flex-end'} width={'unset'} flexShrink={'0'}>
                            <SearchAndArrowContainer
                                position={'relative'}
                                innerRef={searchRef}
                                width={
                                    !extendSearch ? (smallSearchIcon ? '88px' : '250px') : '348px'
                                }
                            >
                                <ArrowButtonRight
                                    label={''}
                                    component={IconSeparator}
                                    iconBefore
                                    onClick={() => handleScroll('right')}
                                    applyColor={applyGrid && !isScrolledToRight}
                                    extendSearch={!extendSearch}
                                >
                                    <FontIcon>chevron_right</FontIcon>
                                </ArrowButtonRight>
                                <Search
                                    live={false}
                                    action={onSearch}
                                    startingtext={selectedFilters.text}
                                    clear={clearSearch}
                                    isDailySchedule={true}
                                    isSidePanelOpened={isSidepanelOpened}
                                    externalCollapsedSearch={val => {
                                        setExtendSearch(val);
                                    }}
                                    exernalSmallSearch={val => setSmallSearchIcon(val)}
                                />
                            </SearchAndArrowContainer>
                            {viewType === programmeViewTypes.LIST_VIEW && renderTimezoneInfo()}
                            <StyledDropdownMenu
                                id="programme-view-dropdown-menu"
                                menuItems={[
                                    <StyledListItem
                                        key="programme-view-dropdown-item-horizontal"
                                        primaryText={HORIZONTAL_VIEW_TEXT}
                                        leftIcon={<FontIcon>view_day</FontIcon>}
                                        onClick={() =>
                                            viewType !== programmeViewTypes.HORIZONTAL_VIEW &&
                                            changeViewType(programmeViewTypes.HORIZONTAL_VIEW)
                                        }
                                        active={viewType === programmeViewTypes.HORIZONTAL_VIEW}
                                    />,
                                    <StyledListItem
                                        key="programme-view-dropdown-item-vertical"
                                        primaryText={VERTICAL_VIEW_TEXT}
                                        leftIcon={<FontIcon>view_array</FontIcon>}
                                        onClick={() =>
                                            viewType !== programmeViewTypes.VERTICAL_VIEW &&
                                            changeViewType(programmeViewTypes.VERTICAL_VIEW)
                                        }
                                        active={viewType === programmeViewTypes.VERTICAL_VIEW}
                                    />,
                                    <StyledListItem
                                        key="programme-view-dropdown-item-list"
                                        primaryText={LIST_VIEW_TEXT}
                                        leftIcon={<FontIcon>view_list</FontIcon>}
                                        onClick={() =>
                                            viewType !== programmeViewTypes.LIST_VIEW &&
                                            changeViewType(programmeViewTypes.LIST_VIEW)
                                        }
                                        active={viewType === programmeViewTypes.LIST_VIEW}
                                    />,
                                ]}
                                anchor={{
                                    x: DropdownMenu.HorizontalAnchors.INNER_RIGHT,
                                    y: DropdownMenu.VerticalAnchors.BOTTOM,
                                }}
                                position={DropdownMenu.Positions.TOP_LEFT}
                                animationPosition="below"
                                sameWidth
                                simplifiedMenu={false}
                                onVisibilityChange={onVisibilityChange}
                                visible={openViewDropDown}
                                light={false}
                            >
                                <ViewButton>
                                    {getTextByType(viewType)}
                                    <FontIcon>arrow_drop_down</FontIcon>
                                </ViewButton>
                            </StyledDropdownMenu>
                            <AccessibleFakeButton label={''} component={IconSeparator} iconBefore>
                                <ToolbarIcon onClick={setShowFilter}>
                                    <FontIcon>tune</FontIcon>
                                </ToolbarIcon>
                                {numberOfAppliedFilters > 0 && (
                                    <NewNotficationsBadge
                                        color={theme.contrast}
                                        top={'0px'}
                                        right={'10px'}
                                    >
                                        {numberOfAppliedFilters}
                                    </NewNotficationsBadge>
                                )}
                            </AccessibleFakeButton>
                        </Flex>
                    </ToolbarContainer>
                    {!!selectedFiltersWithDetails?.length && displayClassifiers && (
                        <FilterLabels>
                            {selectedFiltersWithDetails.map(item => (
                                <ClassifierIconLabelComponent
                                    params={item.params}
                                    label={item.name}
                                    onClose={() => clearGivenFilter(item)}
                                />
                            ))}
                        </FilterLabels>
                    )}
                    {loading && <Loader />}
                    {!loading &&
                        viewType === programmeViewTypes.LIST_VIEW &&
                        renderListView(handleClickOutside)}
                    {!loading &&
                        viewType === programmeViewTypes.HORIZONTAL_VIEW &&
                        renderTimetable(handleClickOutside)}
                    {!loading &&
                        viewType === programmeViewTypes.VERTICAL_VIEW &&
                        renderVerticalTimetable(handleClickOutside)}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default Toolbar;
