import React, { createContext, useState, useEffect } from 'react';
import { default as Store, getString } from '../../../services/api/store';
import { getSettings, updateSettings, getLocalAppStateAsync } from '../../../services/api/db';
import { getFullProfile, getUserSettings, updateUserSettings } from '../../../services/api/eureka';
import Auth from '../../../services/api/auth';
import moment from 'moment';

const TimezoneContext = createContext();

const TimezoneContextProvider = ({ children }) => {
    const [introDialogVisible, setIntroDialogVisible] = useState(false);
    const [selectDialogVisible, setSelectDialogVisible] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState('local');
    const [eventInfo, setEventInfo] = useState({});

    const TIMEZONE_LOCAL_PROGRAMME_FULL_LABEL =
        getString('programmeTimezoneLocalMessage') || 'Times are displayed in your local timezone';
    const TIMEZONE_EVENT_PROGRAMME_FULL_LABEL =
        getString('programmeTimezoneEventMessage') || 'Times are displayed in the event timezone';

    async function initTimezoneContext() {
        const settings = await getSettings();
        if (settings.useLocalTimezone === null || settings.useLocalTimezone === undefined) {
            await updateSettings({ useLocalTimezone: true });
            setIntroDialogVisible(true);
        } else {
            const zoneSetting = settings.useLocalTimezone ? 'local' : 'event';
            setSelectedTimezone(zoneSetting);
        }
        const appState = await getLocalAppStateAsync();
        setEventInfo({
            eventId: appState.eventId,
            eventTitle: appState.eventName,
        });

        if (Auth.isUserAuthenticated()) {
            await setTimezoneFromEurekaSettings(appState.eventId);
        }
    }

    async function setTimezoneFromEurekaSettings(id) {
        const eventId = id || eventInfo.eventId;
        const response = await getUserSettings(eventId);
        await updateSettings({ useLocalTimezone: response.useLocalTimezone === true });
        const zoneSetting = response.useLocalTimezone ? 'local' : 'event';
        if (zoneSetting === 'event') {
            setIntroDialogVisible(false);
        }
        setSelectedTimezone(zoneSetting);
        return response.useLocalTimezone === true;
    }

    function showTimezoneSelectDialog() {
        setSelectDialogVisible(true);
    }

    async function getTimezoneSettings() {
        const settings = await getSettings();
        const zoneSetting = settings.useLocalTimezone ? 'local' : 'event';
        setSelectedTimezone(zoneSetting);
        return zoneSetting;
    }

    async function updateTimezoneSettings(zoneSetting) {
        const useLocalTimezone = zoneSetting === 'local';
        await updateSettings({ useLocalTimezone });
        setSelectedTimezone(zoneSetting);
        //update user settings
        if (Auth.isUserAuthenticated()) {
            const userSettings = await getUserSettings(eventInfo.eventId);
            const settings = {
                useLocalTimezone,
                visible: userSettings.visible,
                available: userSettings.available,
                ...eventInfo,
            };

            await updateUserSettings(settings);
        }
    }

    const getLocalTimezoneName = () => {
        const tz = moment().utcOffset();
        return formatOffset(tz);
    };

    const getEventTimezoneName = () => {
        const tz = Store.argument.offsetTimezone;
        return formatOffset(tz);
    };

    // This will return something like "UTC-2:30"
    const formatOffset = offset => {
        let formatted = 'UTC';
        if (!offset || offset === 0 || offset === '0') {
            return formatted;
        }
        const offsetAsHours = parseInt(offset / 60, 10);
        const diff = Math.abs(offset % 60);
        const operator = offsetAsHours < 0 ? '' : '+';
        formatted = `${formatted}${operator}${offsetAsHours}`;
        if (diff !== 0) {
            formatted += `:${diff}`;
        }
        return formatted;
    };

    function getTimezoneLabel() {
        return selectedTimezone === 'local' ? getLocalTimezoneName() : getEventTimezoneName();
    }

    function getFullTimezoneLabel() {
        return selectedTimezone === 'local'
            ? `${TIMEZONE_LOCAL_PROGRAMME_FULL_LABEL} (${getLocalTimezoneName()})`
            : `${TIMEZONE_EVENT_PROGRAMME_FULL_LABEL} (${getEventTimezoneName()})`;
    }

    function timeslotToTimezone(session) {
        const timezoneOffsetMinutes = Store.argument.offsetTimezone;
        const noTimezoneFormat = 'YYYY-MM-DD[T]HH:mm:ss';
        if (selectedTimezone === 'event') {
            // sessions times were already displayed in Even timezone
            return session;
        } else {
            const start = session.start;
            const end = session.end;
            const currentOffset = moment().utcOffset();
            const differenceSeconds = parseInt(timezoneOffsetMinutes - currentOffset, 10) * 60;
            const dateSecondsStart = parseInt(moment.utc(start).unix(), 10) || start;
            const dateMomentStart = moment
                .utc(dateSecondsStart - differenceSeconds, 'X')
                .format(noTimezoneFormat);

            const dateSecondsEnd = parseInt(moment.utc(end).unix(), 10) || end;
            const dateMomentEnd = moment
                .utc(dateSecondsEnd - differenceSeconds, 'X')
                .format(noTimezoneFormat);

            return {
                ...session,
                start: dateMomentStart,
                end: dateMomentEnd,
            };
        }
    }

    const defaultContext = {
        introDialogVisible,
        setIntroDialogVisible,
        selectDialogVisible,
        setSelectDialogVisible,
        getTimezoneSettings,
        updateTimezoneSettings,
        getLocalTimezoneName,
        getEventTimezoneName,
        getTimezoneLabel,
        timeslotToTimezone,
        showTimezoneSelectDialog,
        selectedTimezone,
        setTimezoneFromEurekaSettings,
        initTimezoneContext,
    };

    return <TimezoneContext.Provider value={defaultContext}>{children}</TimezoneContext.Provider>;
};

const WithTimezone = Component => {
    return props => (
        <TimezoneContext.Consumer>
            {timezone => <Component {...props} timezone={timezone} />}
        </TimezoneContext.Consumer>
    );
};

export { TimezoneContext, TimezoneContextProvider, WithTimezone };
