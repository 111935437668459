import { saveAnalytic } from '../../../../services/api/data';
import async from 'async';
import {
    addLocalItem,
    clearUserAnalytics,
    findAllLocal,
    getItemWithTypeInfo,
} from '../../../../services/api/db';

const getAllSamples = function (next) {
    findAllLocal(
        'analytics',
        item => {
            return item.installation !== null;
        },
        (err, analytics) => {
            if (analytics && analytics.length) {
                async.eachSeries(
                    analytics,
                    (item, cb) => {
                        if (!item.objectClass) {
                            cb();
                        } else {
                            getItemWithTypeInfo(
                                item.objectClass,
                                item.objectId,
                                (err, withType) => {
                                    item.object = {
                                        type: withType.type,
                                        name: withType.name,
                                    };
                                    cb(err);
                                },
                            );
                        }
                    },
                    err => {
                        next(err, analytics);
                    },
                );
            } else {
                next(err, []);
            }
        },
    );
};

const saveSample = async (name, object, event, objectId, installationId = {}) => {
    const timestamp = `${new Date().getTime()}`;

    const sample = {
        name,
        object,
        timestamp,
        event,
        objectId,
        installation: installationId,
    };

    saveAnalytic(sample, err => {
        if (err) {
            console.log('err', err);
        }
    });
};

const deleteSamples = async timestamp => {
    getAllSamples((err, analytics) => {
        const analyticsToRemain = analytics.filter(analytic => analytic.timestamp > timestamp);

        clearUserAnalytics();
        analyticsToRemain.forEach(analytic =>
            addLocalItem('analytics', analytic.object, analytic, err => {
                if (err) {
                    console.log('err', err);
                }
            }),
        );
    });
};

export { saveSample, getAllSamples, deleteSamples };
