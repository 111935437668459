import React from 'react';
import NotificationsCenter from './NotificationsCenter';
import MyConversations from '../../MyConversationsPage';

const Notifications = ({ light }) => {
    return (
        <>
            <NotificationsCenter light={light} />
            <MyConversations light={light} />
        </>
    );
};

export default Notifications;
