import styled from 'styled-components';

export const baseButtonStyle = {
    flex: 1,
    textTransform: 'none',
    height: 40,
    borderRadius: 6,
    fontSize: 15,
    fontWeight: 500,
};

export const saveButtonStyle = {
    color: '#fff',
};

export const cancelButtonStyle = {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#EFEFEF',
};

export const ModalContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto, sans-serif;
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    line-height: 24px;
    text-align: ${props => props.textAlign || 'center'};
    font-weight: 400;
    padding: ${props => (props.padding ? props.padding : 0)};
`;
