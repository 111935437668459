import React, { useEffect, useRef, useState } from 'react';
import { getLocalAppState } from '../../services/api';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { Avatar, List } from 'react-md';
import throttle from 'lodash/throttle';
import { ImageAvatar } from '../../components/Icons';
import { getInitials } from '../VirtualSession/components/userInteraction/attendees/Attendees';
import { getCurrentEventAttendees } from '../VirtualSession/services/attendees';
import { useGlobalState } from '../../utils/container';
import AuthUserContext from '../../components/Session/AuthUserContext';
import { getFullProfile, getUserSettings, updateUserSettings } from '../../services/api/eureka';
import { ConfirmDialog } from '../../components/Dialog';
import { getString } from '../../services/api/store';
import Search from '../../components/General/Search';
import Loader from '../../components/General/Loader';
import * as palette from '../../components/General/Variables';
import { InteractivityContext } from '../../components/Interactivity';
import { StyledListItem } from '../../components/ObjectListItem/style/index';

const LinkStyled = styled(Link)`
    text-decoration: none !important;
`;

const CustomAvatar = styled(Avatar)`
    background-color: #bfbfbf !important;
    border: none !important;
    width: 56px !important;
    height: 56px !important;

    .md-avatar-content {
        font-size: 14px !important;
    }
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${window.innerWidth}px;
`;

const SearchWrapper = styled.div`
    padding: 16px;
    position: relative;
    height: 72px;
    background-color: ${palette.COLOR_WHITE};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05),
        0px 2px 4px rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 0;
    z-index: 1;
`;

const Item = ({ item, i }) => {
    return (
        <StyledListItem
            height={'80px'}
            primaryText={`${item.User.firstName} ${item.User.lastName}`}
            secondaryText={
                <>
                    <div>{item.User.jobTitle}</div>
                    <div>{item.User.companyName}</div>
                </>
            }
            leftAvatar={
                item.User.imageUrl ? (
                    <ImageAvatar imageId={item.User.imageUrl} avatarType={'persons'} />
                ) : (
                    <CustomAvatar>
                        {getInitials(item.User.firstName, item.User.lastName)}
                    </CustomAvatar>
                )
            }
        />
    );
};

const AttendeeList = props => {
    const [allAttendees, setAllAttendees] = useState([]);
    const [filteredAttendees, setFilteredAttendees] = useState([]);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [authUser, setAuthUser] = useState(null);
    const eventId = useRef();
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const settings = useRef();
    const isSearching = useRef(false);

    useEffect(() => {
        if (authUser) {
            getLocalAppState(async (err, localAppState) => {
                if (err || !localAppState) {
                    return;
                }
                settings.current = await getUserSettings(localAppState.eventId);
                settings.current = {
                    ...settings.current,
                    eventId: localAppState.eventId,
                    eventName: localAppState.eventName,
                };

                if (!settings.current.visible) {
                    setShowSettingsModal(true);
                }
                eventId.current = localAppState.eventId;
                await getCurrentAttendees();
                if (socket) {
                    socket.on(
                        `refreshCurrentAttendees_${localAppState.eventId}`,
                        getCurrentAttendees,
                    );
                }
            });
        }
    }, [authUser]);

    const getCurrentAttendees = async () => {
        if (!isSearching.current) {
            setLoading(true);

            const allAttendees = await getCurrentEventAttendees(eventId.current);
            const visibleAttendees = allAttendees && allAttendees.filter(i => i.visible);
            const sortedAttendees = sortAttendees(visibleAttendees);

            setAllAttendees(sortedAttendees);
            setFilteredAttendees(sortedAttendees);
            setLoading(false);
        }
    };

    const sortAttendees = attendees => {
        const sortetAttendeeList = attendees.sort((attendee1, attendee2) => {
            const name1 = `${attendee1.User.lastName} ${attendee1.User.firstName}`;
            const name2 = `${attendee2.User.lastName} ${attendee2.User.firstName}`;
            return name1.toLowerCase().localeCompare(name2.toLowerCase());
        });
        return sortetAttendeeList;
    };

    const attendeeItems = () =>
        filteredAttendees.map((item, i) => {
            let linkTo = props.rootRoute + '/profile/' + item.User.id;
            if (props.isMenu) {
                linkTo =
                    props.rootRoute + '/page/list/' + props.pageId + '/profile/' + item.User.id;
            }

            return (
                <React.Fragment key={'attendee_' + i}>
                    <LinkStyled to={linkTo}>
                        <Item item={item} i={i} />
                    </LinkStyled>
                </React.Fragment>
            );
        });

    const onClose = () => {
        setShowSettingsModal(false);
    };

    const onSave = async () => {
        const userSettings = {
            visible: true,
            available: settings.current.available,
            eventId: settings.current.eventId,
            eventTitle: settings.current.eventName,
        };

        await updateUserSettings(userSettings);
        const userData = await getFullProfile();

        if (socket) {
            socket.emit('privacySettingsUpdated', {
                eventId: settings.current.eventId,
                userId: userData.id,
            });
        }

        onClose();
    };

    const onSearchValueChange = throttle(async value => {
        if (!value) {
            setFilteredAttendees(allAttendees);
        } else {
            const searchedAttendees = allAttendees.filter(attendee => {
                const { firstName, lastName, jobTitle, companyName } = attendee.User;
                const searchTerms = `${firstName} ${lastName} ${jobTitle} ${companyName}`.toLowerCase();

                return searchTerms.includes(value.toLowerCase());
            });
            setFilteredAttendees(searchedAttendees);
        }
        isSearching.current = value.length > 0;
        if (value.length === 0) {
            await getCurrentAttendees();
        }
    }, 500);

    const options = {
        title: getString('attendeesMessageInvisibleTitle') || 'You are invisible...',
        text:
            getString('attendeesMessageInvisible') ||
            'Other people cannot see you in this list and cannot contact you',
        confirmText: getString('attendeesConfirmInvisibleButton') || 'Go Visible',
        cancelText: getString('attendeesCancelInvisibleButton') || 'Stay Invisible',
        confirm: onSave,
        cancel: onClose,
    };

    return (
        <AuthUserContext.Consumer>
            {authUser => {
                if (authUser) {
                    setAuthUser(authUser);
                    return (
                        <React.Fragment>
                            {loading && <Loader />}
                            {!loading && filteredAttendees && (
                                <>
                                    <SearchWrapper>
                                        <Search
                                            action={onSearchValueChange}
                                            live={true}
                                            skey={props.pageId || props.rootRoute}
                                            isExpandableSearch={true}
                                            displayLeft={true}
                                        />
                                    </SearchWrapper>
                                    <ListStyled className="">{attendeeItems()}</ListStyled>
                                </>
                            )}
                            {showSettingsModal && (
                                <ConfirmDialog
                                    visible={true}
                                    options={options}
                                    onHideDialog={onClose}
                                />
                            )}
                        </React.Fragment>
                    );
                } else {
                    return (
                        <InteractivityContext.Consumer>
                            {({ showSignIn }) => showSignIn()}
                        </InteractivityContext.Consumer>
                    );
                }
            }}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(AttendeeList);
