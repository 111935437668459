import React from 'react';
import styled from 'styled-components';

import VoteOption from './VoteOption';
import { votesColorScheme } from './constants';
import HowToVoteIcon from '@material-ui/icons/HowToVoteOutlined';
import ProgressBar from './ProgressBar';

const VotingPreviewWrapper = styled.div`
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    margin-bottom: 16px;
`;

const Img = styled.img`
    height: 100%;
    z-index: 1000;
    border-radius: 8px;
    margin: 0 0 16px 0;
    object-fit: contain;
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 368px;
    margin: 0 0 16px 0;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
`;

const GridContainer = styled.div`
    display: grid;
    grid-gap: 8px;
`;

const PlaceholderContainer = styled.div`
    width: fit-content;
    align-items: center;
    justify-content: center;
    background-color: ${props => (props.votingactive ? '#1fa294' : '#f1f1f3')};
    padding: 2.5px 8px;
    border-radius: 16px;
`;

const PlaceholderText = styled.span`
    color: ${props => (props.votingactive ? '#fff' : 'rgba(0, 0, 0, 0.87)')};
    font-size: 12px;
    font-family: Cabin, sans-serif;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.43px;
    text-align: center;
`;

const TotalVotesContainer = styled.div`
    position: absolute;
    right: 16px;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.86px;
    color: ${props => (props.votingactive ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.38)')};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Bar = styled.div`
    position: absolute;
    top: 0;
    height: 8px;
    background-color: #dedee1;
    width: 100%;
    margin-left: -16px;
`;

const VotingInProgress = ({ pollSet, activePoll, votingIsActive, totalVotes, onSubmitVote }) => {
    const { title, PollOptions, imageUrl } = activePoll;
    const options = PollOptions.filter(option => option.text.length > 0).sort(
        (o1, o2) => o1.order - o2.order,
    );

    return (
        <VotingPreviewWrapper>
            {!votingIsActive && <Bar />}
            {votingIsActive && (
                <ProgressBar pollSet={pollSet} activePoll={activePoll} activeColor={'#1fa294'} />
            )}
            <FirstRow>
                <PlaceholderContainer votingactive={votingIsActive ? 1 : 0}>
                    <PlaceholderText votingactive={votingIsActive ? 1 : 0}>
                        {votingIsActive ? 'VOTE NOW' : 'WAIT FOR VOTING TO START...'}
                    </PlaceholderText>
                </PlaceholderContainer>
                <TotalVotesContainer votingactive={votingIsActive ? 1 : 0}>
                    {totalVotes}
                    <HowToVoteIcon />
                </TotalVotesContainer>
            </FirstRow>
            <Title>{title}</Title>
            {imageUrl && (
                <ImageContainer>
                    <Img src={imageUrl} id="voting-preview-image" />
                </ImageContainer>
            )}
            <GridContainer
                style={{
                    gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(2),
                    gridAutoRows: '1fr',
                }}
            >
                {options.map((option, index) => (
                    <VoteOption
                        key={`preview-vote-option-${index}`}
                        label={String.fromCharCode(65 + index)}
                        option={option}
                        placeholder="Option"
                        color={votesColorScheme[index]}
                        votingIsActive={votingIsActive}
                        onSubmitVote={onSubmitVote}
                    />
                ))}
            </GridContainer>
        </VotingPreviewWrapper>
    );
};

export default VotingInProgress;
