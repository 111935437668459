import async from 'async';
import localforage from 'localforage';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import { batchGet, getItem } from './graphQlRepository';
import axios from 'axios';

const asyncForEach = async function (array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

// Local database functions
var localEntities = ['Favorite', 'Note', 'Analytic', 'Rating', 'Checkin'];
var mapCategory = {};
var db = {};

function categoryToCollection(entity) {
    return entity.toLowerCase() + 's';
}

each(localEntities, function (entity) {
    var category = categoryToCollection(entity);
    db[category] = localforage.createInstance({
        name: category,
    });
    mapCategory[entity] = category;
});

db.appState = localforage.createInstance({
    name: 'appState',
});

db.settings = localforage.createInstance({
    name: 'settings',
});

function getLocalAppState(cb) {
    db.appState.getItem('config', function (err, value) {
        if (err) {
            cb(err);
        } else {
            cb(null, value);
        }
    });
}

const getLocalAppStateAsync = () => {
    return db.appState.getItem('config');
};

const getSettings = () => {
    return db.settings.getItem('settings');
};

const updateSettings = async updatedProps => {
    const config = await getSettings();
    const toUpdate = {
        ...config,
        ...updatedProps,
    };

    return db.settings.setItem('settings', toUpdate);
};

updateSettings({});

function getLocalItem(category, key, cb) {
    db[category].getItem(key, function (err, value) {
        if (value) {
            let obj = value;
            obj.id = key;
            cb(null, obj);
        } else if (err) {
            cb(err);
        } else {
            cb(null, null);
        }
    });
}

function addLocalItem(category, key, value, cb) {
    db[category]
        .setItem(key, value)
        .then(function () {
            return db[category].getItem(key);
        })
        .then(function (value) {
            cb(null, value);
        })
        .catch(function (err) {
            cb(err);
        });
}

// For testing purposes. Deletes all data!!!!!!
function dropDb(cb) {
    async.eachSeries(
        localEntities,
        function (entity, callback) {
            var category = categoryToCollection(entity);
            db[category].clear().then(
                function () {
                    callback();
                },
                function (err) {
                    callback(err);
                },
            );
        },
        err => {
            if (err) {
                cb(err);
            } else {
                db.appState.clear().then(
                    function () {
                        cb();
                    },
                    function (err) {
                        cb(err);
                    },
                );
            }
        },
    );
}

function findAllLocal(category, compare, cb) {
    var results = [];
    db[category]
        .iterate(function (value, key, iterationNumber) {
            var obj = value;
            obj.id = key;
            if (compare(obj)) {
                results.push(obj);
            }
        })
        .then(function (found) {
            cb(null, results);
        })
        .catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
            cb(err);
        });
}

function findOneLocal(category, compare, cb) {
    var result = null;
    db[category]
        .iterate(function (value, key, iterationNumber) {
            var obj = value;
            obj.id = key;

            if (compare(obj)) {
                return obj;
            }
        })
        .then(function (found) {
            if (found && found.id) {
                result = found;
            }
            cb(null, result);
        })
        .catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
            cb(err);
        });
}

function clearUserData(next) {
    const categories = ['Analytic', 'Note', 'Favorite'];
    asyncForEach(categories, async entity => {
        let category = categoryToCollection(entity);
        return db[category].clear();
    })
        .then(() => {
            return next(null, 'ok');
        })
        .catch(err => {
            if (err) {
                return next(err);
            }
        });
}

// GraphQl database queries TODO replace

function getItemWithTypeInfo(category, key, cb) {
    getItem(category, key, function (err, value) {
        if (value) {
            let obj = value;
            if (obj.type) {
                getItem('types', obj.type, function (err, type) {
                    if (type) {
                        obj.type = {
                            id: obj.type,
                            singular: type.singular,
                            plural: type.plural,
                            target: type.target,
                        };
                        cb(null, obj);
                    } else if (err) {
                        cb(err);
                    } else {
                        cb(null, obj);
                    }
                });
            } else {
                cb(null, obj);
            }
        } else if (err) {
            cb(err);
        } else {
            cb(null, null);
        }
    });
}

async function getImageSource(image, cb) {
    if (image.startsWith('http')) {
        return cb(null, image);
    }

    getItem('images', image, (err, obj) => {
        if (!obj) {
            cb('image resource not found');
            return;
        }
        if (obj.imageUrl) {
            cb(null, obj.imageUrl);
        } else {
            getLocalAppState((err, config) => {
                if (obj.resources && obj.resources.length && config && config.configuratorUrl) {
                    const url =
                        config.configuratorUrl + '/api/data/imagefiles/' + obj.resources[0].file;
                    cb(null, url);
                } else {
                    cb('image resource not found');
                }
            });
        }
    });
}

async function getImageSourceAsync(image) {
    return new Promise((resolve, reject) => {
        getImageSource(image, (err, item) => {
            if (err) {
                reject(err);
            } else {
                resolve(item);
            }
        });
    });
}

async function getObjectClassWithId(id, next) {
    const input = {
        data: [],
    };
    const entitiesWithNotes = [
        'timeslot',
        'person',
        'institution',
        'programelement',
        'classifier',
        'place',
    ];
    entitiesWithNotes.forEach(entity => {
        input.data.push({
            target: `${entity}s`,
            ids: [id],
        });
    });

    const response = await batchGet(input);
    each(response, (item, index) => {
        if (isEmpty(item)) {
            delete response[index];
        }
    });
    if (isEmpty(response)) {
        return next('No item found');
    }
    const objectClass = Object.keys(response)[0];
    const [item] = response[objectClass];
    return next(null, objectClass, item);
}

const getObjectClassWithIdAsync = id => {
    return new Promise((resolve, reject) => {
        getObjectClassWithId(id, (err, objectClass, item) => {
            if (err) {
                reject(err);
            } else {
                resolve({
                    objectClass,
                    item,
                });
            }
        });
    });
};

const clearUserAnalytics = () => {
    if (db['analytics']) {
        db['analytics'].clear();
    }
};

const clearInstallationId = cb => {
    getLocalAppState((err, config) => {
        if (err) {
            return cb();
        }
        delete config.installationId;
        addLocalItem('appState', 'config', config, cb);
    });
};

const getEventDetails = async (eventId, configuratorUrl) => {
    const url = `${configuratorUrl}/api/events/${eventId}`;

    try {
        const response = await axios({
            method: 'GET',
            url,
        });
        return response?.data;
    } catch (err) {
        console.log('err', err);
        return true;
    }
};

export {
    db,
    dropDb,
    mapCategory,
    getLocalItem,
    addLocalItem,
    getLocalAppState,
    getLocalAppStateAsync,
    getSettings,
    updateSettings,
    findOneLocal,
    findAllLocal,
    clearUserData,
    getImageSource,
    getImageSourceAsync,
    getItemWithTypeInfo,
    getObjectClassWithId,
    getObjectClassWithIdAsync,
    clearUserAnalytics,
    clearInstallationId,
    getEventDetails,
};
