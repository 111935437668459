/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchGet = /* GraphQL */ `
    query BatchGet($data: [BatchInput]) {
        batchGet(data: $data) {
            classifiers {
                configuratorType
                deleted
                event
                id
                importedOT
                info
                lockedFields
                name
                orderingName
                params
                relatedOf
                searchTerms
                type
            }
            images {
                deleted
                event
                id
                imageUrl
                name
                offline
                releaseExclude
                resources
                searchTerms
                tags
            }
            institutions {
                classifications
                configuratorType
                deleted
                event
                id
                image
                imageUrl
                importedOT
                info
                links
                locations
                lockedFields
                name
                orderingName
                params
                rolesOf
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            links {
                classifications
                configuratorType
                deleted
                deletedBy
                event
                id
                importChannel
                imported
                lockedFields
                mimeType
                name
                searchTerms
                type
                url
            }
            persons {
                affiliations
                classifications
                configuratorType
                deleted
                email
                eurekaId
                event
                id
                image
                imageUrl
                imported
                importedOT
                info
                links
                lockedFields
                name
                orderingName
                params
                rolesOf
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            places {
                classifications
                configuratorType
                deleted
                event
                exhibitorId
                floorplan
                id
                importedOT
                lockedFields
                name
                orderingName
                params
                positionX
                positionY
                searchTerms
                type
                webpages
            }
            programelements {
                classifications
                configuratorType
                deleted
                event
                id
                importedOT
                info
                links
                locations
                lockedFields
                name
                orderingName
                params
                parent
                related
                roles
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            timeslots {
                classifications
                configuratorType
                deleted
                end
                event
                id
                image
                imageUrl
                importedOT
                info
                invites
                links
                locations
                lockedFields
                name
                orderingName
                params
                parent
                related
                roles
                searchTerms
                start
                subNameDetail
                subNameList
                type
                webpages
            }
            types {
                deleted
                event
                id
                invisibleSections
                modified
                params
                plural
                reverse
                searchTerms
                sections
                singular
                target
            }
        }
    }
`;
export const findAppStates = /* GraphQL */ `
  query FindAppStates(
    $event: String!
    $filter: TableAppStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findAppStates(
      event: $event
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        accessRestrictions
        available
        banner
        configuratorUrl
        domain
        eurekaUrl
        eventId
        eventName
        eventTitle
        externalEurekaLogin
        externalLoginOptions
        hasNews
        id
        isKioskPasswordProtected
        kioskAvailable
        kioskItems
        kioskNavigationType
        kioskPassword
        navigation
        navigationIcons
        navigationImage
        navigationType
        pageHeader
        releaseNum
        signInButtonTitle
        societyId
        subdomain
        tileGroups
      }
      nextToken
    }
  }
`;
export const findClassifiers = /* GraphQL */ `
    query FindClassifiers(
        $event: String!
        $filter: TableClassifiersFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findClassifiers(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                configuratorType
                deleted
                event
                id
                importedOT
                info
                lockedFields
                name
                orderingName
                params
                relatedOf
                searchTerms
                type
            }
            nextToken
        }
    }
`;
export const findConfigurations = /* GraphQL */ `
    query FindConfigurations(
        $event: String!
        $filter: TableConfigurationsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findConfigurations(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                current
                deleted
                event
                id
                lockedFields
                name
                searchTerms
                value
            }
            nextToken
        }
    }
`;
export const findFloorplans = /* GraphQL */ `
    query FindFloorplans(
        $event: String!
        $filter: TableFloorplansFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findFloorplans(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                classifications
                deleted
                event
                id
                image
                imageUrl
                imported
                lockedFields
                name
                orderingName
                searchTerms
                type
                webpages
            }
            nextToken
        }
    }
`;
export const findImages = /* GraphQL */ `
    query FindImages(
        $event: String!
        $filter: TableImagesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findImages(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                deleted
                event
                id
                imageUrl
                name
                offline
                releaseExclude
                resources
                searchTerms
                tags
            }
            nextToken
        }
    }
`;
export const findInstitutions = /* GraphQL */ `
    query FindInstitutions(
        $event: String!
        $filter: TableInstitutionsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findInstitutions(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                classifications
                configuratorType
                deleted
                event
                id
                image
                imageUrl
                importedOT
                info
                links
                locations
                lockedFields
                name
                orderingName
                params
                rolesOf
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const findLinks = /* GraphQL */ `
    query FindLinks(
        $event: String!
        $filter: TableLinksFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findLinks(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                classifications
                configuratorType
                deleted
                deletedBy
                event
                id
                importChannel
                imported
                lockedFields
                mimeType
                name
                searchTerms
                type
                url
            }
            nextToken
        }
    }
`;
export const findPages = /* GraphQL */ `
    query FindPages(
        $event: String!
        $filter: TablePagesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findPages(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                accessRestrictions
                deleted
                event
                id
                isPasswordProtected
                kind
                modified
                name
                params
                password
                searchTerms
            }
            nextToken
        }
    }
`;
export const findPersons = /* GraphQL */ `
    query FindPersons(
        $event: String!
        $filter: TablePersonsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findPersons(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                affiliations
                classifications
                configuratorType
                deleted
                email
                eurekaId
                event
                id
                image
                imageUrl
                imported
                importedOT
                info
                links
                lockedFields
                name
                orderingName
                params
                rolesOf
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const findPlaces = /* GraphQL */ `
    query FindPlaces(
        $event: String!
        $filter: TablePlacesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findPlaces(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                classifications
                configuratorType
                deleted
                event
                exhibitorId
                floorplan
                id
                importedOT
                lockedFields
                name
                orderingName
                params
                positionX
                positionY
                searchTerms
                type
                webpages
            }
            nextToken
        }
    }
`;
export const findProgramelements = /* GraphQL */ `
    query FindProgramelements(
        $event: String!
        $filter: TableProgramelementsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findProgramelements(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                classifications
                configuratorType
                deleted
                event
                id
                importedOT
                info
                links
                locations
                lockedFields
                name
                orderingName
                params
                parent
                related
                roles
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const findTimeslots = /* GraphQL */ `
    query FindTimeslots(
        $event: String!
        $filter: TableTimeslotsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findTimeslots(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                classifications
                configuratorType
                deleted
                end
                event
                id
                image
                imageUrl
                importedOT
                info
                invites
                links
                locations
                lockedFields
                name
                orderingName
                params
                parent
                related
                roles
                searchTerms
                start
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const findTypes = /* GraphQL */ `
    query FindTypes(
        $event: String!
        $filter: TableTypesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findTypes(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                deleted
                event
                id
                invisibleSections
                modified
                params
                plural
                reverse
                searchTerms
                sections
                singular
                target
            }
            nextToken
        }
    }
`;
export const findWebpages = /* GraphQL */ `
    query FindWebpages(
        $event: String!
        $filter: TableWebpagesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        findWebpages(
            event: $event
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                body
                classifications
                deleted
                event
                html
                id
                imported
                lockedFields
                name
                searchTerms
                type
            }
            nextToken
        }
    }
`;
export const getAppState = /* GraphQL */ `
  query GetAppState($id: String!) {
    getAppState(id: $id) {
      accessRestrictions
      available
      banner
      configuratorUrl
      domain
      eurekaUrl
      eventId
      eventName
      eventTitle
      externalEurekaLogin
      externalLoginOptions
      hasNews
      id
      isKioskPasswordProtected
      kioskAvailable
      kioskItems
      kioskNavigationType
      kioskPassword
      navigation
      navigationIcons
      navigationImage
      navigationType
      pageHeader
      releaseNum
      signInButtonTitle
      societyId
      subdomain
      tileGroups
    }
  }
`;
export const getAppStatesWithDomain = /* GraphQL */ `
  query GetAppStatesWithDomain($domain: String!) {
    getAppStatesWithDomain(domain: $domain) {
      items {
        accessRestrictions
        available
        banner
        configuratorUrl
        domain
        eurekaUrl
        eventId
        eventName
        eventTitle
        externalEurekaLogin
        externalLoginOptions
        hasNews
        id
        isKioskPasswordProtected
        kioskAvailable
        kioskItems
        kioskNavigationType
        kioskPassword
        navigation
        navigationIcons
        navigationImage
        navigationType
        pageHeader
        releaseNum
        signInButtonTitle
        societyId
        subdomain
        tileGroups
      }
      nextToken
    }
  }
`;
export const getChildrenOf = /* GraphQL */ `
    query GetChildrenOf($parent: String!, $type: String) {
        getChildrenOf(parent: $parent, type: $type) {
            items {
                classifications
                configuratorType
                deleted
                end
                event
                id
                image
                imageUrl
                importedOT
                info
                invites
                links
                locations
                lockedFields
                name
                orderingName
                params
                parent
                related
                roles
                searchTerms
                start
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const getClassifiers = /* GraphQL */ `
    query GetClassifiers($id: String!) {
        getClassifiers(id: $id) {
            configuratorType
            deleted
            event
            id
            importedOT
            info
            lockedFields
            name
            orderingName
            params
            relatedOf
            searchTerms
            type
        }
    }
`;
export const getClassifiersWithType = /* GraphQL */ `
    query GetClassifiersWithType($type: String!) {
        getClassifiersWithType(type: $type) {
            items {
                configuratorType
                deleted
                event
                id
                importedOT
                info
                lockedFields
                name
                orderingName
                params
                relatedOf
                searchTerms
                type
            }
            nextToken
        }
    }
`;
export const getConfigurations = /* GraphQL */ `
    query GetConfigurations($id: String!) {
        getConfigurations(id: $id) {
            current
            deleted
            event
            id
            lockedFields
            name
            searchTerms
            value
        }
    }
`;
export const getConfigurationsWithEvent = /* GraphQL */ `
    query GetConfigurationsWithEvent($event: String!) {
        getConfigurationsWithEvent(event: $event) {
            items {
                current
                deleted
                event
                id
                lockedFields
                name
                searchTerms
                value
            }
            nextToken
        }
    }
`;
export const getFloorplans = /* GraphQL */ `
    query GetFloorplans($id: String!) {
        getFloorplans(id: $id) {
            classifications
            deleted
            event
            id
            image
            imageUrl
            imported
            lockedFields
            name
            orderingName
            searchTerms
            type
            webpages
        }
    }
`;
export const getFloorplansWithEvent = /* GraphQL */ `
    query GetFloorplansWithEvent($event: String!) {
        getFloorplansWithEvent(event: $event) {
            items {
                classifications
                deleted
                event
                id
                image
                imageUrl
                imported
                lockedFields
                name
                orderingName
                searchTerms
                type
                webpages
            }
            nextToken
        }
    }
`;
export const getImages = /* GraphQL */ `
    query GetImages($id: String!) {
        getImages(id: $id) {
            deleted
            event
            id
            imageUrl
            name
            offline
            releaseExclude
            resources
            searchTerms
            tags
        }
    }
`;
export const getInstitutions = /* GraphQL */ `
    query GetInstitutions($id: String!) {
        getInstitutions(id: $id) {
            classifications
            configuratorType
            deleted
            event
            id
            image
            imageUrl
            importedOT
            info
            links
            locations
            lockedFields
            name
            orderingName
            params
            rolesOf
            searchTerms
            subNameDetail
            subNameList
            type
            webpages
        }
    }
`;
export const getInstitutionsWithType = /* GraphQL */ `
    query GetInstitutionsWithType($type: String!) {
        getInstitutionsWithType(type: $type) {
            items {
                classifications
                configuratorType
                deleted
                event
                id
                image
                imageUrl
                importedOT
                info
                links
                locations
                lockedFields
                name
                orderingName
                params
                rolesOf
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const getLinks = /* GraphQL */ `
    query GetLinks($id: String!) {
        getLinks(id: $id) {
            classifications
            configuratorType
            deleted
            deletedBy
            event
            id
            importChannel
            imported
            lockedFields
            mimeType
            name
            searchTerms
            type
            url
        }
    }
`;
export const getPages = /* GraphQL */ `
    query GetPages($id: String!) {
        getPages(id: $id) {
            accessRestrictions
            deleted
            event
            id
            isPasswordProtected
            kind
            modified
            name
            params
            password
            searchTerms
        }
    }
`;
export const getPagesWithEventAndKind = /* GraphQL */ `
    query GetPagesWithEventAndKind($event: String!, $kind: String) {
        getPagesWithEventAndKind(event: $event, kind: $kind) {
            items {
                accessRestrictions
                deleted
                event
                id
                isPasswordProtected
                kind
                modified
                name
                params
                password
                searchTerms
            }
            nextToken
        }
    }
`;
export const getPagesWithEventAndName = /* GraphQL */ `
    query GetPagesWithEventAndName($event: String!, $name: String) {
        getPagesWithEventAndName(event: $event, name: $name) {
            items {
                accessRestrictions
                deleted
                event
                id
                isPasswordProtected
                kind
                modified
                name
                params
                password
                searchTerms
            }
            nextToken
        }
    }
`;
export const getPersons = /* GraphQL */ `
    query GetPersons($id: String!) {
        getPersons(id: $id) {
            affiliations
            classifications
            configuratorType
            deleted
            email
            eurekaId
            event
            id
            image
            imageUrl
            imported
            importedOT
            info
            links
            lockedFields
            name
            orderingName
            params
            rolesOf
            searchTerms
            subNameDetail
            subNameList
            type
            webpages
        }
    }
`;
export const getPersonsWithType = /* GraphQL */ `
    query GetPersonsWithType($type: String!) {
        getPersonsWithType(type: $type) {
            items {
                affiliations
                classifications
                configuratorType
                deleted
                email
                eurekaId
                event
                id
                image
                imageUrl
                imported
                importedOT
                info
                links
                lockedFields
                name
                orderingName
                params
                rolesOf
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const getPlaces = /* GraphQL */ `
    query GetPlaces($id: String!) {
        getPlaces(id: $id) {
            classifications
            configuratorType
            deleted
            event
            exhibitorId
            floorplan
            id
            importedOT
            lockedFields
            name
            orderingName
            params
            positionX
            positionY
            searchTerms
            type
            webpages
        }
    }
`;
export const getPlacesWithFloorplan = /* GraphQL */ `
    query GetPlacesWithFloorplan($floorplan: String!) {
        getPlacesWithFloorplan(floorplan: $floorplan) {
            items {
                classifications
                configuratorType
                deleted
                event
                exhibitorId
                floorplan
                id
                importedOT
                lockedFields
                name
                orderingName
                params
                positionX
                positionY
                searchTerms
                type
                webpages
            }
            nextToken
        }
    }
`;
export const getPlacesWithType = /* GraphQL */ `
    query GetPlacesWithType($type: String!) {
        getPlacesWithType(type: $type) {
            items {
                classifications
                configuratorType
                deleted
                event
                exhibitorId
                floorplan
                id
                importedOT
                lockedFields
                name
                orderingName
                params
                positionX
                positionY
                searchTerms
                type
                webpages
            }
            nextToken
        }
    }
`;
export const getProgramelements = /* GraphQL */ `
    query GetProgramelements($id: String!) {
        getProgramelements(id: $id) {
            classifications
            configuratorType
            deleted
            event
            id
            importedOT
            info
            links
            locations
            lockedFields
            name
            orderingName
            params
            parent
            related
            roles
            searchTerms
            subNameDetail
            subNameList
            type
            webpages
        }
    }
`;
export const getProgramelementsChildrenOf = /* GraphQL */ `
    query GetProgramelementsChildrenOf($parent: String!, $type: String) {
        getProgramelementsChildrenOf(parent: $parent, type: $type) {
            classifications
            configuratorType
            deleted
            event
            id
            importedOT
            info
            links
            locations
            lockedFields
            name
            orderingName
            params
            parent
            related
            roles
            searchTerms
            subNameDetail
            subNameList
            type
            webpages
        }
    }
`;
export const getProgramelementsWithType = /* GraphQL */ `
    query GetProgramelementsWithType($type: String!) {
        getProgramelementsWithType(type: $type) {
            items {
                classifications
                configuratorType
                deleted
                event
                id
                importedOT
                info
                links
                locations
                lockedFields
                name
                orderingName
                params
                parent
                related
                roles
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const getTimeslots = /* GraphQL */ `
    query GetTimeslots($id: String!) {
        getTimeslots(id: $id) {
            classifications
            configuratorType
            deleted
            end
            event
            id
            image
            imageUrl
            importedOT
            info
            invites
            links
            locations
            lockedFields
            name
            orderingName
            params
            parent
            related
            roles
            searchTerms
            start
            subNameDetail
            subNameList
            type
            webpages
        }
    }
`;
export const getTimeslotsWithType = /* GraphQL */ `
    query GetTimeslotsWithType($type: String!) {
        getTimeslotsWithType(type: $type) {
            items {
                classifications
                configuratorType
                deleted
                end
                event
                id
                image
                imageUrl
                importedOT
                info
                invites
                links
                locations
                lockedFields
                name
                orderingName
                params
                parent
                related
                roles
                searchTerms
                start
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const getTypes = /* GraphQL */ `
    query GetTypes($id: String!) {
        getTypes(id: $id) {
            deleted
            event
            id
            invisibleSections
            modified
            params
            plural
            reverse
            searchTerms
            sections
            singular
            target
        }
    }
`;
export const getWebpages = /* GraphQL */ `
    query GetWebpages($id: String!) {
        getWebpages(id: $id) {
            body
            classifications
            deleted
            event
            html
            id
            imported
            lockedFields
            name
            searchTerms
            type
        }
    }
`;
export const listAppStates = /* GraphQL */ `
  query ListAppStates(
    $filter: TableAppStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        accessRestrictions
        available
        banner
        configuratorUrl
        domain
        eurekaUrl
        eventId
        eventName
        eventTitle
        externalEurekaLogin
        externalLoginOptions
        hasNews
        id
        isKioskPasswordProtected
        kioskAvailable
        kioskItems
        kioskNavigationType
        kioskPassword
        navigation
        navigationIcons
        navigationImage
        navigationType
        pageHeader
        releaseNum
        signInButtonTitle
        societyId
        subdomain
        tileGroups
      }
      nextToken
    }
  }
`;
export const listClassifiers = /* GraphQL */ `
    query ListClassifiers(
        $filter: TableClassifiersFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listClassifiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                configuratorType
                deleted
                event
                id
                importedOT
                info
                lockedFields
                name
                orderingName
                params
                relatedOf
                searchTerms
                type
            }
            nextToken
        }
    }
`;
export const listConfigurations = /* GraphQL */ `
    query ListConfigurations(
        $filter: TableConfigurationsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listConfigurations(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                current
                deleted
                event
                id
                lockedFields
                name
                searchTerms
                value
            }
            nextToken
        }
    }
`;
export const listFloorplans = /* GraphQL */ `
    query ListFloorplans(
        $filter: TableFloorplansFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listFloorplans(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                classifications
                deleted
                event
                id
                image
                imageUrl
                imported
                lockedFields
                name
                orderingName
                searchTerms
                type
                webpages
            }
            nextToken
        }
    }
`;
export const listImages = /* GraphQL */ `
    query ListImages(
        $filter: TableImagesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                deleted
                event
                id
                imageUrl
                name
                offline
                releaseExclude
                resources
                searchTerms
                tags
            }
            nextToken
        }
    }
`;
export const listInstitutions = /* GraphQL */ `
    query ListInstitutions(
        $filter: TableInstitutionsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listInstitutions(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                classifications
                configuratorType
                deleted
                event
                id
                image
                imageUrl
                importedOT
                info
                links
                locations
                lockedFields
                name
                orderingName
                params
                rolesOf
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const listLinks = /* GraphQL */ `
    query ListLinks(
        $filter: TableLinksFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                classifications
                configuratorType
                deleted
                deletedBy
                event
                id
                importChannel
                imported
                lockedFields
                mimeType
                name
                searchTerms
                type
                url
            }
            nextToken
        }
    }
`;
export const listPages = /* GraphQL */ `
    query ListPages(
        $filter: TablePagesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                accessRestrictions
                deleted
                event
                id
                isPasswordProtected
                kind
                modified
                name
                params
                password
                searchTerms
            }
            nextToken
        }
    }
`;
export const listPersons = /* GraphQL */ `
    query ListPersons(
        $filter: TablePersonsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPersons(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                affiliations
                classifications
                configuratorType
                deleted
                email
                eurekaId
                event
                id
                image
                imageUrl
                imported
                importedOT
                info
                links
                lockedFields
                name
                orderingName
                params
                rolesOf
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const listPlaces = /* GraphQL */ `
    query ListPlaces(
        $filter: TablePlacesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPlaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                classifications
                configuratorType
                deleted
                event
                exhibitorId
                floorplan
                id
                importedOT
                lockedFields
                name
                orderingName
                params
                positionX
                positionY
                searchTerms
                type
                webpages
            }
            nextToken
        }
    }
`;
export const listProgramelements = /* GraphQL */ `
    query ListProgramelements(
        $filter: TableProgramelementsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listProgramelements(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                classifications
                configuratorType
                deleted
                event
                id
                importedOT
                info
                links
                locations
                lockedFields
                name
                orderingName
                params
                parent
                related
                roles
                searchTerms
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const listTimeslots = /* GraphQL */ `
    query ListTimeslots(
        $filter: TableTimeslotsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listTimeslots(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                classifications
                configuratorType
                deleted
                end
                event
                id
                image
                imageUrl
                importedOT
                info
                invites
                links
                locations
                lockedFields
                name
                orderingName
                params
                parent
                related
                roles
                searchTerms
                start
                subNameDetail
                subNameList
                type
                webpages
            }
            nextToken
        }
    }
`;
export const listTypes = /* GraphQL */ `
    query ListTypes(
        $filter: TableTypesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                deleted
                event
                id
                invisibleSections
                modified
                params
                plural
                reverse
                searchTerms
                sections
                singular
                target
            }
            nextToken
        }
    }
`;
export const listWebpages = /* GraphQL */ `
    query ListWebpages(
        $filter: TableWebpagesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listWebpages(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                body
                classifications
                deleted
                event
                html
                id
                imported
                lockedFields
                name
                searchTerms
                type
            }
            nextToken
        }
    }
`;
