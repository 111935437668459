import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import throttle from 'lodash/throttle';

import NotificationContentHeader from '../components/NotificationContentHeader';
import { useTheme } from '../../../components/Theme/ThemeContext';
import {
    getUnreadNotifications as getUnreadNotificationsAction,
    fetchNotifications as fetchNotificationsAction,
    removeNotificationsByIds as removeNotificationsByIdsAction,
    getPrivateConversationFromNotification,
    acceptFriendRequestAction,
    declineFriendRequestAction,
} from '../actions';
import Loader from '../../../components/General/Loader';
import NotificationItem from '../components/NotificationItem';
import { notificationTypes } from '../constants';
import { withRouter } from 'react-router-dom';
import NoNotificationPlaceholder from '../components/NoNotificationPlaceholder';
import { getLocalAppStateAsync } from '../../../services/api/db';
import Auth from '../../../services/api/auth';
import Button, { buttonTypes } from '../../common/Button';
import { acceptAppointment, declineAppointment, deleteAppointment } from '../../Talk/actions';
import NotificationButton from '../components/NotificationButton';
import { useGlobalState } from '../../../utils/container';
import { getString } from '../../../services/api/store';

const SectionTitle = styled.div`
    font-family: 'Cabin', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin: 8px 0;
`;

const NotificationsCenter = ({
    notifications,
    unreadNotificationsCount,
    getUnreadNotifications,
    fetchingNotifications,
    fetchNotifications,
    removeNotificationsByIds,
    history,
    location,
    getPrivateConversation,
    chat = false,
    light = false,
}) => {
    const [visible, setVisible] = useState(false);
    const [newsFeedUrl, setNewsFeedUrl] = useState('/');
    const [searchValue, setSearchValue] = useState('');
    const [showAllRequests, setShowAllRequests] = useState(false);
    const [eventId, setEventId] = useState(null);

    const { theme } = useTheme();
    const mounted = useRef(false);

    const user = Auth.getUser();
    const dispatch = useDispatch();

    const stateCtx = useGlobalState();
    const { socket } = stateCtx;

    const REQUESTS_TEXT = getString('contactsListRequests', 'Requests');

    useEffect(() => {
        (async () => {
            const appState = await getLocalAppStateAsync();

            setEventId(appState.eventId);
            getUnreadNotifications();
        })();
    }, []);

    useEffect(() => {
        const { id } = Auth.getUser();

        if (socket && id) {
            socket.on(`refreshNotifications_${id}`, getUnreadNotificationsThrottled);
            socket.on(`refreshNotifications_${id}_${eventId}`, getUnreadNotificationsThrottled);
        }

        return () => {
            if (socket && id) {
                socket.removeAllListeners(
                    `refreshNotifications_${id}`,
                    () => getUnreadNotificationsThrottled,
                );
                socket.removeAllListeners(
                    `refreshNotifications_${id}_${eventId}`,
                    getUnreadNotificationsThrottled,
                );
            }
        };
    }, [socket, eventId]);

    const getUnreadNotificationsThrottled = throttle(getUnreadNotifications, 1000);

    useEffect(() => {
        const getNewsFeedPageUrl = async () => {
            const appState = await getLocalAppStateAsync();
            if (!appState) {
                return;
            }

            const newsFeedPage = appState.navigation.find(item => item.type === 'Feed');

            if (newsFeedPage) {
                setNewsFeedUrl(newsFeedPage.to);
            }
        };

        getNewsFeedPageUrl();
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        if (visible) {
            fetchNotifications(chat);
        } else {
            getUnreadNotifications();
        }
    }, [visible]);

    const onSearchChange = text => {
        setSearchValue(text);
    };

    const onAcceptFriendRequest = userId => {
        dispatch(acceptFriendRequestAction(userId));
    };

    const onDeclineFriendRequest = userId => {
        dispatch(declineFriendRequestAction(userId));
    };

    const onAcceptAppointment = id => {
        dispatch(acceptAppointment({ id }));
    };

    const onDeclineAppointment = id => {
        dispatch(declineAppointment({ id }));
    };

    const onDeleteAppointment = id => {
        dispatch(deleteAppointment({ id }));
    };

    const onVisibilityChange = newVisible => {
        setVisible(newVisible);

        if (!newVisible) {
            setShowAllRequests(false);
        }
    };

    const getTimeAgo = date => moment.utc(date).fromNow();

    const getIconByType = type => {
        switch (type) {
            case notificationTypes.REMINDER:
                return 'event';
            case notificationTypes.CHAT:
                return 'question_answer';
            case notificationTypes.APPOINTMENT:
                return 'person';
            case notificationTypes.NEWS:
                return 'insert_comment';
            case notificationTypes.FRIENDSHIP:
                return 'person_add';
            default:
                return undefined;
        }
    };

    const onNotificationClick = async (notification, redirectUrl, state = {}) => {
        if (notification.type === notificationTypes.CHAT) {
            const { isPrivateMessage, senderId } = notification.params;
            if (isPrivateMessage) {
                const privateConversation = await getPrivateConversation(senderId);
                if (privateConversation) {
                    state.conversation = privateConversation;
                } else {
                    console.error(`Private chat not found`);
                    return;
                }
            }
        }

        setVisible(false);
        await removeNotificationsByIds({ ids: [notification.id] });
        history.push({
            pathname: redirectUrl,
            state,
        });
    };

    const renderNotificationElement = notif => {
        let linkTo = '/';
        let historyState = {};
        let buttons = [];

        if (notif.type === notificationTypes.APPOINTMENT) {
            const { appointmentId, creatorId } = notif.params;
            const createdByMe = user.id === creatorId;

            linkTo = `/mybookmarks/appointment/${appointmentId}`;

            if (createdByMe) {
                buttons = [
                    {
                        type: buttonTypes.GREEN_LONG,
                        text: 'Change',
                        onClick: () => {}, // Nothing to do, the whole list item onClick function is triggered
                        background: theme.primary,
                    },
                    {
                        type: buttonTypes.GREY_LONG,
                        text: 'Cancel',
                        onClick: () => onDeleteAppointment(appointmentId),
                    },
                ];
            } else {
                buttons = [
                    {
                        type: buttonTypes.GREEN_LONG,
                        text: 'Accept',
                        onClick: () => onAcceptAppointment(appointmentId),
                        background: theme.primary,
                    },
                    {
                        type: buttonTypes.GREY_LONG,
                        text: 'Decline',
                        onClick: () => onDeclineAppointment(appointmentId),
                    },
                ];
            }
        }
        if (notif.type === notificationTypes.REMINDER) {
            const { reminderId, reminderTarget } = notif.params;

            linkTo = `/mybookmarks/${reminderTarget.toLowerCase()}/${reminderId}`;
        }
        if (notif.type === notificationTypes.FRIENDSHIP) {
            const { fromUserId, toUserId, pendingResponse } = notif.params;
            const createdByMe = user.id === fromUserId;
            const redirectUserId = createdByMe ? toUserId : fromUserId;
            const splitPathname = location.pathname.split('/');
            if (splitPathname.length > 3) {
                splitPathname.splice(splitPathname.length - 2, 2);
                linkTo = `${splitPathname.join('/')}/profile/${redirectUserId}`;
            } else {
                linkTo = `${location.pathname}/profile/${redirectUserId}`;
            }

            if (!createdByMe && pendingResponse) {
                buttons = [
                    {
                        type: buttonTypes.GREEN_LONG,
                        text: 'Accept',
                        onClick: () => onAcceptFriendRequest(fromUserId),
                        background: theme.primary,
                    },
                    {
                        type: buttonTypes.GREY_LONG,
                        text: 'Decline',
                        onClick: () => onDeclineFriendRequest(fromUserId),
                    },
                ];
            }
        }
        if (notif.type === notificationTypes.NEWS) {
            const { newsId } = notif.params;

            historyState = {
                itemId: newsId,
            };
            linkTo = newsFeedUrl;
        }

        return (
            <NotificationItem
                key={`notification-item-${notif.id}`}
                primaryText={notif.title}
                secondaryText={notif.subtitle}
                timeText={getTimeAgo(notif.displayDate)}
                imageUrl={notif.imageUrl}
                icon={getIconByType(notif.type)}
                badge={!notif.read}
                onClick={() => onNotificationClick(notif, linkTo, historyState)}
                buttons={buttons}
            />
        );
    };

    const onClearAll = async () => {
        const ids = notifications.map(notif => notif.id);
        await removeNotificationsByIds({ ids });
        await fetchNotifications(chat, eventId);
    };

    let menuItems = [
        <NotificationContentHeader
            key="notification-content-header"
            onMenuClose={() => setVisible(false)}
            onClearAll={onClearAll}
            displayClearAll={notifications.length > 0}
            hasSearch={chat}
            searchValue={searchValue}
            onSearchChange={onSearchChange}
            title={getString('notificationsTitle') || 'Notifications'}
        />,
    ];

    if (fetchingNotifications) {
        menuItems = [...menuItems, <Loader key="loader" />];
    } else if (notifications && notifications.length) {
        const requestNotifications = [];
        const updateNotifications = [];

        notifications.forEach(notification => {
            if (
                notification.type === notificationTypes.FRIENDSHIP ||
                notification.type === notificationTypes.APPOINTMENT
            ) {
                requestNotifications.push(notification);
            } else {
                updateNotifications.push(notification);
            }
        });

        if (requestNotifications.length) {
            let requestNotificationsToDisplay = [...requestNotifications];

            if (!showAllRequests) {
                requestNotificationsToDisplay = requestNotificationsToDisplay.slice(0, 2);
            }

            menuItems = [
                ...menuItems,
                <SectionTitle key="section-requests">{REQUESTS_TEXT}</SectionTitle>,
                ...requestNotificationsToDisplay.map(renderNotificationElement),
            ];
        }

        if (requestNotifications.length > 2 && !showAllRequests) {
            menuItems = [
                ...menuItems,
                <Button
                    key="show-all-request-notifications-button"
                    type={buttonTypes.GREY_LONG}
                    text="Show all"
                    onClick={event => {
                        event.stopPropagation();
                        setShowAllRequests(true);
                    }}
                />,
            ];
        }

        if (updateNotifications.length) {
            if (!chat) {
                menuItems = [
                    ...menuItems,
                    <SectionTitle key="section-updates">Updates</SectionTitle>,
                ];
            }

            menuItems = [...menuItems, ...updateNotifications.map(renderNotificationElement)];
        }
    } else if (notifications && notifications.length === 0) {
        menuItems = [...menuItems, <NoNotificationPlaceholder key="no-notification-placeholder" />];
    }

    return (
        <NotificationButton
            items={menuItems}
            unreadNotificationsCount={unreadNotificationsCount}
            icon={'notifications'}
            visible={visible}
            onVisibilityChange={onVisibilityChange}
            light={light}
        />
    );
};

const mapStateToProps = state => ({
    fetchingNotifications: state.notifications.fetchingNotifications,
    unreadNotificationsCount: state.notifications.unreadNotificationsCount,
    notifications: state.notifications.notifications,
});

export default connect(mapStateToProps, {
    getUnreadNotifications: getUnreadNotificationsAction,
    fetchNotifications: fetchNotificationsAction,
    removeNotificationsByIds: removeNotificationsByIdsAction,
    getPrivateConversation: getPrivateConversationFromNotification,
})(withRouter(NotificationsCenter));
