import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, FontIcon, TextField } from 'react-md';
import ThemeContext from '../Theme/ThemeContext';
import { getString } from '../../services/api/store';
import * as palette from './Variables';
import { withRouter } from 'react-router-dom';

const Wrapper = styled.div`
    width: ${props => (props.collapsedSearch ? '40px' : '')};
    transition: width 0.4s linear;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    margin-right: 12px;

    .md-divider--text-field::after {
        background: ${props => props.maincolor};
    }
    .md-text--theme-primary {
        color: ${props => props.maincolor};
    }
`;

const SearchCloseBtn = styled(Button)`
    width: 28px !important;
    height: 28px !important;
    top: 6px !important;
    right: 6px !important;
    background: rgba(245, 245, 245, 1) !important;

    &:hover {
        color: rgba(0, 0, 0, 0.78) !important;
        filter: brightness(90%) !important;
    }

    i {
        font-size: 24px !important;
    }
`;

const StyledTextField = styled(TextField)`
    border-radius: 4px;
    background-color: rgba(245, 245, 245, 1);
    height: 40px;

    input {
        margin-top: 0;
        height: 100%;
    }
    hr {
        display: none;
    }

    .md-icon {
        font-size: 20px;
    }

    @media only screen and (max-width: ${palette.MIN_DESKTOP}) {
        .md-text-field-divider-container {
            margin-bottom: 4px;
        }
    }
`;

const StyledExpandableTextField = styled(StyledTextField)`
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 50px;
    height: 40px;
    ${props => (props.value ? '' : `width: 40px;`)}!important;
    cursor: pointer;
    z-index: 2;

    margin: 0 0 0 auto;
    width: 40px;
    transition: width 0.4s linear;
    position: absolute !important;
    ${props => (props.displayLeft ? 'left: 24px' : 'right: 6px')};

    ${props =>
        props.collapsedSearch
            ? `width: ${props.value ? '295px' : '40px'} !important;`
            : `
        width: ${props.largeSearch ? '295px' : '200px'} !important;
        button {
            * > i {
                color: #1e1e1f !important;
            }
        }
    `}

    .md-text-field-divider-container--grow {
        margin-left: 20px !important;
        margin-top: 10px;
    }

    input:not(:focus) {
        max-width: 70%;
        text-overflow: ellipsis;
    }

    input {
        background: inherit;
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.87);
    }

    .md-icon {
        color: #1e1e1f;
    }

    button {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0px;
        right: 2px;

        &:hover {
            background: inherit;
        }
        * > i {
            color: rgba(0, 0, 0, 0.6) !important;
        }
    }
`;

const StyledSearchIcon = styled(FontIcon)`
    transform: translate(10px, 10px);
    font-size: 24px !important;
`;

const Search = props => {
    const [text, setText] = useState(props.startingtext ? props.startingtext : '');
    const [skey, setSkey] = useState(null);
    const [collapsedSearch, setCollapsedSearch] = useState(false);
    const [isLargeSearch, setIsLargeSearch] = useState(false);

    useEffect(() => {
        if (props.skey !== skey) {
            setSkey(props.skey);
            setText('');
        }
    }, [props.skey]);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        const collapsedSearch = props.isSidePanelOpened || windowWidth < 992;
        setCollapsedSearch(collapsedSearch);
        const largeSearch = isLargeSearch && text !== '';
        setIsLargeSearch(largeSearch);
        if (props.externalCollapsedSearch) {
            props.externalCollapsedSearch(text !== '' || (!collapsedSearch && largeSearch));
        }
        if (props.exernalSmallSearch) {
            props.exernalSmallSearch(collapsedSearch);
        }
    }, [props.isSidePanelOpened]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setCollapsedSearch(props.isSidePanelOpened || windowWidth < 992);
        if (props.exernalSmallSearch) {
            props.exernalSmallSearch(props.isSidePanelOpened || windowWidth < 992);
        }
    };

    const onKeyDown = e => {
        if (e.keyCode === 13) {
            props.action(text);
        }
    };

    const onChange = text => {
        setText(text);

        if (props.live && text.length > 0) {
            props.action(text);
        }
        if (!text || text === '') {
            props.action('');
        }
    };

    // Clears the search field
    const clear = () => {
        setText('');
        props.action('');

        if (props.clear) {
            props.clear();
        }
    };

    // Executes a search
    const search = () => {
        props.action(text);
    };

    const onFocus = () => {
        setCollapsedSearch(false);
        setIsLargeSearch(true);
        if (props.externalCollapsedSearch) {
            props.externalCollapsedSearch(true);
        }
        if (props.exernalSmallSearch) {
            props.exernalSmallSearch(false);
        }
        if (props.onFocus) {
            props.onFocus();
        }
    };

    const onBlur = () => {
        const windowWidth = window.innerWidth;
        const collapsedSearch = props.isSidePanelOpened || windowWidth < 992;
        setCollapsedSearch(collapsedSearch);
        const largeSearch = isLargeSearch && text !== '';
        setIsLargeSearch(largeSearch);
        if (props.externalCollapsedSearch) {
            props.externalCollapsedSearch(text !== '' || (!collapsedSearch && largeSearch));
        }
        if (props.exernalSmallSearch) {
            props.exernalSmallSearch(collapsedSearch);
        }
        if (props.onBlur) {
            props.onBlur();
        }
    };

    const TEXT_SEARCH = getString('search') || 'Search';

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <Wrapper
                    maincolor={theme.primary}
                    expandable={props.isExpandableSearch}
                    collapsedSearch={collapsedSearch}
                >
                    {props.isDailySchedule ? (
                        <StyledExpandableTextField
                            id="expandable-search"
                            placeholder={TEXT_SEARCH}
                            leftIcon={<StyledSearchIcon onClick={search}>search</StyledSearchIcon>}
                            onChange={onChange}
                            value={text}
                            onKeyDown={onKeyDown}
                            inlineIndicator={
                                text ? (
                                    <SearchCloseBtn icon onClick={clear}>
                                        <FontIcon>close</FontIcon>
                                    </SearchCloseBtn>
                                ) : null
                            }
                            onFocus={onFocus}
                            onBlur={onBlur}
                            collapsedSearch={collapsedSearch}
                            largeSearch={text !== '' || (!collapsedSearch && isLargeSearch)}
                            displayLeft={props.displayLeft}
                        />
                    ) : (
                        <StyledExpandableTextField
                            id="search"
                            placeholder={TEXT_SEARCH}
                            leftIcon={<StyledSearchIcon onClick={search}>search</StyledSearchIcon>}
                            onChange={onChange}
                            value={text}
                            onKeyDown={onKeyDown}
                            inlineIndicator={
                                text ? (
                                    <SearchCloseBtn icon onClick={clear}>
                                        <FontIcon>close</FontIcon>
                                    </SearchCloseBtn>
                                ) : null
                            }
                            displayLeft={props.displayLeft}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            collapsedSearch={false}
                            largeSearch={true}
                        />
                    )}
                </Wrapper>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(Search);
