/*
INIT: ensure Babel/Eslint/Flow is configured for ES Class Fields & Static Properties
JSX USAGE: <Iframe src='http://web.site' onLoad={myOnloadFunction}/>
*/
import React, { Component } from 'react';
import styled from 'styled-components';
import { checkCCLinks } from '../../helper/CCLink';
import { withRouter } from 'react-router-dom';

const IframeContainer = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: ${props => window.innerHeight - 64}px;
`;

class Iframe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            html: '',
            closelink: '',
        };
        this.iframe = React.createRef();
    }
    componentDidMount() {
        const iframe = this.iframe.current;
        iframe.addEventListener('load', this.props.onLoad);
        if (this.props && this.props.html) {
            this.setState({
                html: this.props.html,
                closelink: this.props.closelink,
            });
        }
    }

    update() {
        const iframe = this.iframe.current;
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(this.state.html);
        iframe.contentWindow.document.close();

        checkCCLinks(
            iframe.contentDocument,
            this.props.prefix,
            this.state.closelink,
            this.props.history,
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.html !== prevState.html) {
            return {
                reload: true,
                html: nextProps.html,
                closelink: nextProps.closelink,
            };
        } else {
            return null;
        }
    }

    componentDidUpdate() {
        if (this.state.reload) {
            this.update();
        }
    }

    render() {
        const iframeStyle = {
            width: '100%',
            height: '100%',
            border: '0',
            display: 'block',
        };

        return (
            <IframeContainer>
                <iframe
                    title="webpage"
                    ref={this.iframe}
                    frameBorder={'0'}
                    width={'100%'}
                    height={window.innerHeight + 'px'}
                    style={iframeStyle}
                />
            </IframeContainer>
        );
    }
}

export default withRouter(Iframe);
