// Copyright 2017 Brandon Mowat
// Written, developed, and designed by Brandon Mowat for the purpose of helping
// other developers make chat interfaces.

import React, { useEffect, useRef, useState } from 'react';

import BubbleGroup from '../BubbleGroup';
import DefaultChatBubble from '../ChatBubble';
import styles from './styles';
import Loader from '../../../components/General/Loader';
import ScrollDownButton from '../ScrollDownButton';
import inRange from 'lodash/inRange';
import {messageBubbleStyleType} from "../constants";

const SCROLL_OFFSET = 3;

const splitMessagesGroup = (messages) => {
    const finalArray = [];
    let currentArray = [];
    
    messages.forEach(message => {
        if (message.appointment) {
            finalArray.push(currentArray);
            finalArray.push([message]);
            currentArray = [];
        } else if (message.dateMessage) {
            finalArray.push(currentArray);
            currentArray = [message];
        } else {
            currentArray.push(message);
        }
    });
    
    finalArray.push(currentArray);
    
    return finalArray.filter(elements => elements.length > 0);
};

const determineSingleMessageBubbleStyle = (messages) => messages.map((message, index) => {
    switch (true) {
        case index === 0 && messages.length === 1:
            message.bubbleStyle = messageBubbleStyleType.SINGLE;
            return message;

        case index === 0:
            message.bubbleStyle = messageBubbleStyleType.TOP;
            return message;

        case index > 0 && index < messages.length - 1:
            message.bubbleStyle = messageBubbleStyleType.MIDDLE;
            return message;

        case index === messages.length - 1:
            message.bubbleStyle = messageBubbleStyleType.BOTTOM;
            return message;

        default:
            message.bubbleStyle = messageBubbleStyleType.SINGLE;
            return message;
    }
});

const addSingleMessageBubbleStyle = (messages) => {
    const groups = splitMessagesGroup(messages);

    return groups.reduce((acc, group) => [...acc, ...determineSingleMessageBubbleStyle(group)], []);
};


const ChatFeed = props => {
    const { maxHeight } = props;
    const [buttonHidden, setButtonHidden] = useState(true);
    const messagesEnd = useRef();
    const atBottom = useRef();
    const scrolledBefore = useRef(); // first load scrolls without animation

    const [fetching, setFetching] = useState(false);

    let storedPosition = 0;
    let scrollingUp = false;

    useEffect(() => {
        setFetching(false);
        if (atBottom.current || !scrolledBefore.current) {
            scrollToBottom();
        }
    }, [props.messages]);

    useEffect(() => {
        scrollToBottom();
    }, []);

    const handleScroll = event => {
        const { clientHeight, scrollHeight, scrollTop } = event.target;
        const clientPercentage = clientHeight * 0.15;
        const difference = scrollHeight - (scrollTop + clientHeight);
        atBottom.current = inRange(scrollHeight - scrollTop, clientHeight - SCROLL_OFFSET, clientHeight + SCROLL_OFFSET);
        if (atBottom.current) {
            setButtonHidden(true);
        } else if (difference >= clientPercentage) {
            setButtonHidden(false);
        }

        if (scrollTop % 10 === 0) {
            scrollingUp = scrollTop < storedPosition ? true : false;
            storedPosition = scrollTop;
            if (scrollTop < 5 && !fetching && scrollingUp) {
                if (props.loadPreviousMessages) {
                    setFetching(true);
                    props.loadPreviousMessages();
                }
            }
        }
    };

    const scrollToBottom = () => {
        const scrollObj = !scrolledBefore.current ?
            { block: 'nearest', inline: 'start' } :                     // scroll without animations
            { behavior: 'smooth', block: 'nearest', inline: 'start' };  // scroll with animations
        messagesEnd.current.scrollIntoView(scrollObj);
        if (props.messages.length > 1) {
            scrolledBefore.current = true;
        }
    };

    const renderMessages = () => {
        const { messages, bubbleStyles, chatBubble, showSenderName } = props;
        const ChatBubble = chatBubble || DefaultChatBubble;
        let group = [];
        const messageNodes = messages.map((message, index) => {
            group.push(message);
            // Find diff in message type or no more messages
            if (index === messages.length - 1 || messages[index + 1].id !== message.id) {
                const messageGroup = addSingleMessageBubbleStyle(group);
                group = [];
                return (
                    <BubbleGroup
                        key={index}
                        messages={messageGroup}
                        id={message.id}
                        showSenderName={showSenderName}
                        chatBubble={ChatBubble}
                        bubbleStyles={bubbleStyles}
                    />
                );
            }
            return null;
        });
        return messageNodes;
    };

    return (
        <div id="chat-panel" style={styles.chatPanel}>
            <div
                className="chat-history"
                style={{ ...styles.chatHistory, maxHeight }}
                onScroll={handleScroll}
            >
                {fetching && <Loader />}
                <div className="chat-messages">{renderMessages()}</div>
                <div ref={messagesEnd} style={{ float: 'left', clear: 'both' }} />
                <ScrollDownButton onClick={scrollToBottom} hidden={buttonHidden} />
            </div>
        </div>
    );
};

export default ChatFeed;
