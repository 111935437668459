import get from 'lodash/get';
import merge from 'lodash/merge';

import {
    LOAD_GROUP_CONVERSATIONS,
    LOAD_PRIVATE_CHAT_MESSAGES,
    LOAD_GROUP_CHAT_MESSAGES,
    UPDATE_PRIVATE_CONVERSATIONS,
    NEW_MESSAGE,
    LOAD_GROUP_CHAT_PARTICIPANTS,
    UPDATE_CONVERSATION,
    UPDATE_PARTICIPANT,
    UPDATE_PARTICIPANT_STATUS,
    UPDATE_SERVER_STATUS,
    SET_USER,
    SET_EVENT_ID,
    SET_CHAT_URL,
    SET_CHAT_ROOM_ID,
    SET_JOINED_ROOMS, SET_LAST_SEEN_TIMESTAMP,
} from '../actions';

export const INITIAL_STATE = {
    privateConversations: {},
    groupConversations: {},
    messages: {},
    participants: {},
    lastMessagesSeen: {},
    participantStatus: {},
    blockedParticipants: {},
    api: {},
    user: {
        id: '',
        firstName: '',
        lastName: '',
        imageUrl: '',
        color: '',
    },
    settings: {
        eventId: '',
        chatUrl: '',
        serverOnline: false,
        serverError: false,
        chatRoomId: '',
    },
    joinedRooms: [],
    unreadMessagesCount: {},
};

const talkReducer = (state = INITIAL_STATE, action) => {
    let conversationId;
    let conversationState;
    let isPrivateMessage;
    let message;
    let currentMessages;
    let total;
    let hasMore;
    let messages;
    let newMessages;
    let eventId;
    let lastTimestamp;
    let newLastMessage;
    let conversationKey;

    switch (action.type) {
        case 'CLEAR_SETTINGS':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    chatRoomId: '',
                },
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload.userData,
            };

        case SET_EVENT_ID:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    eventId: action.payload.eventId,
                },
            };

        case SET_CHAT_URL:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    chatUrl: action.payload.chatUrl,
                },
            };

        case SET_CHAT_ROOM_ID:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    chatRoomId: action.payload.chatRoomId,
                },
            };

        case UPDATE_SERVER_STATUS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    serverOnline: action.payload.online,
                    serverError: action.payload.error,
                },
            };

        case UPDATE_PRIVATE_CONVERSATIONS.SUCCESS:
            return {
                ...state,
                ...merge(state, action.payload.privateConversations),
            };

        case LOAD_GROUP_CONVERSATIONS.REQUEST: {
            eventId = action.payload.actionParams.event;
            return {
                ...state,
                api: {
                    ...state.api,
                    [eventId]: {
                        isLoadingGroupConversations: true,
                        loadGroupConversationsError: null,
                    },
                },
            };
        }

        case LOAD_GROUP_CONVERSATIONS.SUCCESS: {
            eventId = action.payload.actionParams.event;
            return {
                ...state,
                ...merge(state, action.payload.groupConversations),
                api: {
                    ...state.api,
                    [eventId]: {
                        isLoadingGroupConversations: false,
                        loadGroupConversationsError: null,
                    },
                },
                unreadMessagesCount: {
                    ...state.unreadMessagesCount,
                    ...action.payload.unreadMessagesCount,
                },
            };
        }

        case LOAD_GROUP_CONVERSATIONS.FAILURE: {
            eventId = action.payload.actionParams.event;
            const { error } = action.payload;
            return {
                ...state,
                api: {
                    ...state.api,
                    [eventId]: {
                        isLoadingGroupConversations: false,
                        loadGroupConversationsError: error,
                    },
                },
            };
        }

        case SET_LAST_SEEN_TIMESTAMP: {
            ({ isPrivate: isPrivateMessage, conversationId } = action.payload);
            conversationKey = isPrivateMessage ? 'privateConversations' : 'groupConversations';

            return {
                ...state,
                lastMessagesSeen: {
                    ...state.lastMessagesSeen,
                    [conversationId]: action.payload.timestamp,
                },
                unreadMessagesCount: {
                    ...state.unreadMessagesCount,
                    [conversationId]: 0,
                },
            };
        }

        case LOAD_GROUP_CHAT_MESSAGES.REQUEST: {
            conversationId = action.payload.actionParams.conversation.id;
            return {
                ...state,
                api: {
                    ...state.api,
                    [conversationId]: {
                        isLoadingGroupChatMessages: true,
                        loadGroupChatMessagesError: null,
                    },
                },
            };
        }

        case LOAD_GROUP_CHAT_MESSAGES.SUCCESS: {
            conversationId = get(action, 'payload.actionParams.conversation.id');
            currentMessages = get(state, `groupConversations.${conversationId}.messages`, []);
            ({ messages } = action.payload.response.entities);
            total = action.payload.response.result.length;
            hasMore = total > 0 && total === action.payload.actionParams.limit;
            newMessages = action.payload.actionParams.resetOnSuccess
                ? action.payload.response.result
                : [...new Set([...currentMessages, ...action.payload.response.result])];

            return {
                ...state,
                messages: merge(state.messages, messages),
                groupConversations: {
                    ...state.groupConversations,
                    [conversationId]: {
                        ...state.groupConversations[conversationId],
                        messages: newMessages,
                        lastMessage: newMessages[0],
                    },
                },
                api: {
                    ...state.api,
                    [conversationId]: {
                        isLoadingGroupChatMessages: false,
                        hasMore,
                    },
                },
            };
        }

        case LOAD_GROUP_CHAT_MESSAGES.FAILURE: {
            conversationId = action.payload.actionParams.conversation.id;

            return {
                ...state,
                api: {
                    ...state.api,
                    [conversationId]: {
                        isLoadingGroupChatMessages: false,
                        loadGroupChatMessagesError: action.payload.error,
                    },
                },
            };
        }

        case LOAD_PRIVATE_CHAT_MESSAGES.REQUEST: {
            conversationId = action.payload.actionParams.conversation.id;
            return {
                ...state,
                api: {
                    ...state.api,
                    [conversationId]: {
                        isLoadingPrivateChatMessages: true,
                        loadPrivateChatMessagesError: null,
                    },
                },
            };
        }

        case LOAD_PRIVATE_CHAT_MESSAGES.SUCCESS: {
            conversationId = get(action, 'payload.actionParams.conversation.id');
            lastTimestamp = get(action, 'payload.actionParams.lastTimestamp');
            currentMessages = get(state, `privateConversations.${conversationId}.messages`, []);
            messages = get(action, 'payload.response.entities.messages', {});

            total = action.payload.response.result.length;
            hasMore = total > 0 && total === action.payload.actionParams.limit;
            newMessages = action.payload.actionParams.resetOnSuccess
                ? action.payload.response.result
                : [...new Set([...currentMessages, ...action.payload.response.result])];
            const messagesArr = Object.keys(messages).reverse();
            conversationState = state.privateConversations[conversationId];

            // only update lastMessage on first messages load
            newLastMessage = lastTimestamp
                ? conversationState.lastMessage
                : messagesArr.find(mId => messages[mId].txt !== '__removed__');

            return {
                ...state,
                messages: merge(state.messages, messages),
                privateConversations: {
                    ...state.privateConversations,
                    [conversationId]: {
                        ...state.privateConversations[conversationId],
                        messages: newMessages,
                        lastMessage: newLastMessage,
                    },
                },
                api: {
                    ...state.api,
                    [conversationId]: {
                        isLoadingPrivateChatMessages: false,
                        hasMore,
                    },
                },
            };
        }

        case LOAD_PRIVATE_CHAT_MESSAGES.FAILURE: {
            conversationId = action.payload.actionParams.conversation.id;

            return {
                ...state,
                api: {
                    ...state.api,
                    [conversationId]: {
                        isLoadingPrivateChatMessages: false,
                        loadPrivateChatMessagesError: action.payload.error,
                    },
                },
            };
        }

        case UPDATE_PARTICIPANT_STATUS:
            return {
                ...state,
                participantStatus: {
                    ...state.participantStatus,
                    [action.payload.participantId]: action.payload.online,
                },
            };

        case NEW_MESSAGE: {
            ({ isPrivateMessage } = action.payload);
            ({ message } = action.payload.data);
            const { conversation } = action.payload.data;

            const sliceKey = isPrivateMessage ? 'privateConversations' : 'groupConversations';
            const isOldParticipant = Object.keys(state.participants).find(key => parseInt(key) === parseInt(conversation.id));
            currentMessages = get(state, `${sliceKey}.${conversation.id}.messages`, []);

            // Removing message with temp ID from conversation when adding the correct on with ID from server
            if (!isPrivateMessage) {
                const tempMessageIndex = currentMessages.indexOf(parseInt(message.timestamp));
                if (tempMessageIndex > -1 && message.timestamp !== message.id.toString()) {
                    currentMessages.splice(tempMessageIndex, 1);
                }
            }

            const updatedConversationState = {
                ...state[sliceKey][conversation.id],
                ...conversation,
                messages: [...new Set([message.id, ...currentMessages])],
                lastMessage: message.id,
            };

            if (isPrivateMessage) {
                updatedConversationState.participant = conversation.id;
            }

            if (!isOldParticipant) {
                return {
                    ...state,
                    [sliceKey]: {
                        ...state[sliceKey],
                        [conversation.id]: updatedConversationState,
                    },
                    messages: {
                        ...state.messages,
                        [message.id]: {
                            ...message,
                        },
                    },
                    unreadMessagesCount: {
                        ...state.unreadMessagesCount,
                        [conversation.id]: action.payload.unreadCount,
                    },
                    participants: {
                        ...state.participants,
                        [conversation.id]: conversation.participant,
                    },
                };
            }
            return {
                ...state,
                [sliceKey]: {
                    ...state[sliceKey],
                    [conversation.id]: updatedConversationState,
                },
                messages: {
                    ...state.messages,
                    [message.id]: {
                        ...message,
                    },
                },
                unreadMessagesCount: {
                    ...state.unreadMessagesCount,
                    [conversation.id]: action.payload.unreadCount,
                },
            };
        }

        case LOAD_GROUP_CHAT_PARTICIPANTS.REQUEST: {
            conversationId = get(action, 'payload.actionParams.conversationId');

            return {
                ...state,
                api: {
                    ...state.api,
                    [conversationId]: {
                        isLoadingGroupChatParticipants: true,
                        loadGroupChatParticipantsError: null,
                    },
                },
            };
        }

        case LOAD_GROUP_CHAT_PARTICIPANTS.SUCCESS: {
            conversationId = get(action, 'payload.actionParams.conversationId');
            const roomId = get(action, 'payload.actionParams.objectId');
            const chatUrl = get(state, 'settings.chatUrl');
            const conversationJid = `${conversationId}@conference.${chatUrl}`;
            const { participants } = action.payload.response.entities;
            const { result: participantIds } = action.payload.response;
            const currentParticipantIds = get(
                state,
                `groupConversations.${conversationId}.participants`,
                [],
            );

            const newParticipantIds = [...new Set([...currentParticipantIds, ...participantIds])];

            return {
                ...state,
                groupConversations: {
                    ...state.groupConversations,
                    [conversationId]: {
                        ...state.groupConversations[conversationId],
                        participants: newParticipantIds,
                        roomId,
                        jid: conversationJid,
                    },
                },
                participants: merge(state.participants, participants),
                api: {
                    ...state.api,
                    [conversationId]: {
                        isLoadingGroupChatParticipants: false,
                    },
                },
            };
        }

        case LOAD_GROUP_CHAT_PARTICIPANTS.FAILURE: {
            conversationId = get(action, 'payload.actionParams.conversationId');

            return {
                ...state,
                api: {
                    ...state.api,
                    [conversationId]: {
                        isLoadingGroupChatParticipants: false,
                        loadGroupChatParticipantsError: action.payload.error,
                    },
                },
            };
        }

        case UPDATE_CONVERSATION:
            return {
                ...state,
                groupConversations: merge(state.groupConversations, {
                    [action.payload.conversation.id]: action.payload.conversation,
                }),
            };

        case UPDATE_PARTICIPANT:
            return {
                ...state,
                participants: merge(state.participants, action.payload),
            };

        case SET_JOINED_ROOMS:
            return {
                ...state,
                joinedRooms: action.payload.joinedRooms,
            };

        default:
            return state;
    }
};

export default talkReducer;
