import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { loadGroupChatMessages, sendGroupMessage } from '../../../../Talk/actions';
import { getGroupChatMessages } from '../../../../Talk/selectors';
import { GroupChat } from '../../../../Talk/containers/GroupChat';

const MESSAGES_LIMIT = 15;

const ChatContainer = ({
    loadMessages,
    conversation,
    sendChatMessage,
    messages,
    user,
    chatType,
}) => {
    const [messagesRequested, setMessagesRequested] = useState(false);

    useEffect(() => {
        if (conversation.jid && !messagesRequested) {
            loadMessages({ conversation, limit: MESSAGES_LIMIT });
            setMessagesRequested(true);
        }
    }, [conversation]);

    const onSendMessage = async text => {
        // eventTitle/chatType needed to create conversation on Eureka with a type (institution, session or private talk)
        conversation.eventTitle = chatType;
        const sent = await sendChatMessage({
            conversation,
            message: text,
            isPrivate: false,
        });
        return sent;
    };

    const fetchPrevMessages = lastTimestamp => {
        setTimeout(() => {
            loadMessages({ conversation, limit: MESSAGES_LIMIT, lastTimestamp });
            setMessagesRequested(true);
        }, 500);
    };

    return (
        <GroupChat
            conversation={conversation}
            messages={messages}
            userData={user}
            onSendMessage={onSendMessage}
            loadPreviousMessages={time => {
                fetchPrevMessages(time);
            }}
        />
    );
};

const mapStateToProps = (state, ownProps) => {
    const conversationId = ownProps.conversation.id;
    return {
        user: state.talk.user,
        messages: getGroupChatMessages(state, conversationId),
    };
};

export default connect(mapStateToProps, {
    loadMessages: loadGroupChatMessages,
    sendChatMessage: sendGroupMessage,
})(ChatContainer);
