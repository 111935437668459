import get from 'lodash/get';
import React, { useContext, useEffect, useState } from 'react';
import { Button, DialogContainer } from 'react-md';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { isUserBlockedBySessionIdAndUserId } from '../../scenes/VirtualSession/services/virtualEventBlockedUsers';
import Auth from '../../services/api/auth';
import WarningDialog from '../Dialog/WarningDialog';
import * as palette from '../General/Variables';
import InteractivityContext from '../Interactivity/InteractivityContext';
import AuthUserContext from '../Session/AuthUserContext';
import { useTheme } from '../Theme/ThemeContext';
import AnalyticsService from '../../features/analytics/services';
import { canJoinRoundTable, getVirtualEventSession } from '../../services/api/eureka';
import { useGlobalMutation, useGlobalState } from '../../utils/container';
import CustomButton, { buttonTypes, Tooltip } from '../../scenes/common/Button';
import { getString } from '../../services/api/store';
import BookmarkButton from '../General/BookmarkButton';
import { checkIfUserHasAuthorizationByTypeRestrictions } from '../../scenes/AccessRestrictions/utils';
import { ButtonsContainer } from '../DetailHeader/index';
import {
    AchievementType,
    useAchievementActions,
} from '../../scenes/Achievements/hooks/useAchievementActions';
import CheckinButton from './CheckinButton';

const TYPE_EXTERNAL = 'External';
const TYPE_APPOINTMENT_VIRTUAL_ROOT = 'Appointment virtual room';

const ButtonStyled = styled(Button)`
    border: 1px solid ${props => props.primarycolor} !important;
    margin-left: 16px !important;
    margin-top: 27px !important;
    margin-bottom: 27px !important;
    border-radius: ${props => (props.theming === 'inversed' ? '2px' : '4px')} !important;
    color: ${props =>
        props.styled && props.styled.fullRoom
            ? 'rgba(0, 0, 0, 0.38)'
            : props.theming === 'inversed'
            ? props.primarycolor
            : palette.COLOR_WHITE} !important;

    background-color: ${props =>
        props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor} !important;
    display: block;

    &.md-btn--dialog,
    &.md-btn--text {
        text-transform: none;
        font-size: 16px;
        height: 36px;
        font-weight: 500;
        padding: 8px 16px;
    }

    span {
        display: flex;
        align-items: center;
    }

    &:hover {
        background-color: ${props =>
            props.theming === 'inversed' ? palette.COLOR_WHITE : props.primarycolor};
    }
`;

const Paragraph = styled.p`
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
`;

const DialogContainerStyled = styled(DialogContainer)`
    .md-dialog-content--padded {
        padding-bottom: 0;
    }
    .md-dialog-footer--inline {
        padding: 0 15px;
    }
`;

const StyledChip = styled.div`
    text-align: center;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: ${props => props.color};
    background-color: #fff !important;
    border-radius: 4px;
    width: fit-content;
    min-width: 32px;
    height: 24px;
    white-space: nowrap;
    letter-spacing: 0.42px;
    padding: 4px 8px;
    margin-right: 8px;
    margin-left: auto;
    position: relative;
    &:hover {
        filter: brightness(90%);
    }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

const EnterVirtualSessionContainer = styled(FlexContainer)`
    fontfamily: 'Roboto, sans-serif';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #757575;
    width: 100%;
`;

const EnterVirtualSessionButton = withRouter(
    ({
        setModalVisible,
        enterVirtualroom,
        type,
        isExternalLink,
        virtualEventSession,
        timeslotId,
        customButton,
        customButtonType,
        customButtonText,
        hasCheckin,
        usersJoinedState,
    }) => {
        const [usersJoined, setUsersJoined] = usersJoinedState;
        const [chipHover, setChipHover] = useState(false);

        const { theme } = useTheme();
        const currentUser = Auth.getUser();
        const stateCtx = useGlobalState();
        const { socket } = stateCtx;
        const ENTER_ROOM_TEXT = getString('virtualEnterVirtualRoom') || 'Enter virtual room';
        const CLOSED_ROOM_TEXT = getString('virtualRoomIsClosed') || 'Virtual room is closed';
        const RECORDING_ROOM_TEXT = getString('virtualRoomViewRecording') || 'View Recording';
        const { trackAchievement } = useAchievementActions();
        const authUser = Auth.getUser();
        const { showSignIn } = useContext(InteractivityContext);

        const broadcasting = virtualEventSession && virtualEventSession.status === 'broadcasting';
        const isRoundTable = virtualEventSession && virtualEventSession.roomType === 'roundTable';
        const fullRoom = currentUser && isRoundTable && usersJoined === 15;

        let buttonText = ENTER_ROOM_TEXT;
        if (type === 'on-demand') {
            buttonText = RECORDING_ROOM_TEXT;
        }

        useEffect(() => {
            socket.on(`updateRoundTableCount_${timeslotId}`, checkCapacityWithTimeout);

            return () => {
                if (socket) {
                    socket.off(`updateRoundTableCount_${timeslotId}`, checkCapacityWithTimeout);
                    socket.removeAllListeners(`updateRoundTableCount_${timeslotId}`);
                }
            };
        }, []);

        useEffect(() => {
            (async () => {
                await checkCapacity();
            })();
        }, [currentUser]);

        const checkCapacity = async () => {
            if (isRoundTable && currentUser && broadcasting) {
                try {
                    const response = await canJoinRoundTable(timeslotId, currentUser.id);
                    const numberOfUsers = response.users;

                    if (numberOfUsers !== usersJoined) {
                        setUsersJoined(numberOfUsers);
                    }
                } catch (err) {
                    setUsersJoined(15);
                }
            }
        };

        const checkCapacityWithTimeout = () => {
            setTimeout(checkCapacity, 2000);
        };

        const handleEnterVirtualRoom = e => {
            if (fullRoom) {
                if (authUser) {
                    trackAchievement(AchievementType.ENTER_FULL_CAPACITY_ROOM, timeslotId);
                }
                return;
            }

            if (authUser || isExternalLink) {
                if (
                    window.location.pathname.indexOf('/virtual-session/') > -1 ||
                    window.location.pathname.indexOf('/virtual-session-round/') > -1
                ) {
                    setModalVisible(true);
                } else {
                    enterVirtualroom();
                }
            } else {
                showSignIn(e);
            }
        };

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <InteractivityContext.Consumer>
                        {({ showSignIn }) => (
                            <EnterVirtualSessionContainer>
                                {customButton ? (
                                    <CustomButton
                                        type={customButtonType}
                                        background={theme.primary}
                                        text={customButtonText}
                                        onClick={handleEnterVirtualRoom}
                                    />
                                ) : (
                                    <CustomButton
                                        type={
                                            fullRoom
                                                ? buttonTypes.GREY_LONG
                                                : buttonTypes.GREEN_LONG
                                        }
                                        background={theme.primary}
                                        text={buttonText}
                                        onClick={handleEnterVirtualRoom}
                                        tooltip={fullRoom && CLOSED_ROOM_TEXT}
                                        icon={'videocam'}
                                        enabled={!fullRoom}
                                        style={{
                                            padding:
                                                isRoundTable &&
                                                broadcasting &&
                                                currentUser &&
                                                (hasCheckin
                                                    ? '10px 0 10px 30%'
                                                    : '10px 0 10px 25%'),
                                            whiteSpace: 'nowrap',
                                        }}
                                        chip={
                                            isRoundTable &&
                                            broadcasting &&
                                            currentUser && (
                                                <StyledChip
                                                    color={
                                                        fullRoom
                                                            ? 'rgba(0, 0, 0, 0.38)'
                                                            : theme.primary
                                                    }
                                                    onMouseEnter={() => {
                                                        setChipHover(true);
                                                    }}
                                                    onMouseLeave={() => setChipHover(false)}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <FlexContainer>
                                                        {`${usersJoined} / 15`}
                                                    </FlexContainer>
                                                    {chipHover && (
                                                        <Tooltip left={'-1px'} bottom={'42px'}>
                                                            {15 - `${usersJoined}`} spots left
                                                        </Tooltip>
                                                    )}
                                                </StyledChip>
                                            )
                                        }
                                    />
                                )}
                            </EnterVirtualSessionContainer>
                        )}
                    </InteractivityContext.Consumer>
                )}
            </AuthUserContext.Consumer>
        );
    },
);

const EnterVirtualSesssionClosedButton = ({ timeslotId }) => {
    const { trackAchievement } = useAchievementActions();

    const handleClick = () => {
        trackAchievement(AchievementType.CLICK_CLOSED_ROOM_BUTTON, timeslotId);
    };

    const CLOSED_ROOM_TEXT = getString('virtualRoomIsClosed') || 'Virtual room is closed';
    const ENTER_ROOM_TEXT = getString('virtualEnterVirtualRoom') || 'Enter virtual room';

    return (
        <CustomButton
            type={buttonTypes.GREY_LONG}
            text={ENTER_ROOM_TEXT}
            icon={'videocam'}
            tooltip={CLOSED_ROOM_TEXT}
            enabled={false}
            onClick={handleClick}
        />
    );
};

const TimeslotVirtualSession = ({
    history,
    id,
    params,
    virtualEventSession,
    type,
    customButton,
    customButtonType,
    customButtonText,
    favoriteStatus,
    clickMyCongress,
    notesButton,
    notesButtonLarge,
    typeParams,
    currentUserGroups,
    hasCheckin,
}) => {
    const mutationCtx = useGlobalMutation();
    const [modalVisible, setModalVisible] = useState(false);
    const [blockedUser, setBlockedUser] = useState(false);
    const [restrictedUser, setRestrictedUser] = useState(false);
    const usersJoinedState = useState(0);

    const { theme } = useTheme();
    const {
        virtualSession,
        virtualSessionType,
        virtualSessionExternalLink,
        onDemandRecType,
        onDemandExternalLink,
        onDemandLink,
        onDemandLinkScreen,
    } = params;

    const isExternalLink =
        virtualSessionType === TYPE_EXTERNAL || onDemandRecType === TYPE_EXTERNAL;
    const isExternalOpen = !!(isExternalLink && virtualSession !== '');
    const isAppointmentVirtualRoom = virtualSessionType === TYPE_APPOINTMENT_VIRTUAL_ROOT;
    const isOnDemandRecording = virtualSession === 'on-demand';

    const isSessionOpen = !!(
        isExternalOpen ||
        isAppointmentVirtualRoom ||
        virtualSession === 'on-demand' ||
        (virtualEventSession && virtualEventSession.status !== 'closed')
    );

    useEffect(() => {
        const canUserAccessSession = checkIfUserHasAuthorizationByTypeRestrictions(
            typeParams?.virtualRoomRestrictedGroup,
            currentUserGroups,
        );
        setRestrictedUser(!canUserAccessSession);
    }, [typeParams, currentUserGroups]);

    const openUrl = path => {
        if (isExternalLink) {
            const url =
                virtualSession === 'on-demand' ? onDemandExternalLink : virtualSessionExternalLink;

            return window.open(url, '_blank');
        }

        if (virtualEventSession && virtualEventSession.status === 'broadcasting') {
            AnalyticsService.addSample('viewVirtualRoom', path, id);
        }

        history.push({
            pathname: `/${path}/${id}`,
            state: { prevPath: history.location.pathname },
        });
    };

    const getPath = virtualEventSession => {
        if (!virtualEventSession || !virtualEventSession.roomType) {
            return '';
        }

        switch (virtualEventSession.roomType) {
            case 'roundTable':
                return 'virtual-session-round';
            case 'virtual':
            case 'virtual-room':
                return 'virtual-session-room';
            default:
                return 'virtual-session';
        }
    };

    const enterVirtualRoom = async () => {
        const user = Auth.getUser();
        const virtualEventSession = await getVirtualEventSession(id);
        const roomType = virtualEventSession ? virtualEventSession.roomType : '';

        if (virtualEventSession && roomType === 'roundTable') {
            const participants = get(virtualEventSession, 'VirtualEventUsers') || [];

            if (participants.length > 15) {
                mutationCtx.setShowCapacityModal(true);
                return;
            }

            const inTheSession = participants.find(virtualUser => virtualUser.UserId === user.id);

            if (inTheSession) {
                mutationCtx.setShowAlreadyInTheSessionModal(true);
                return;
            }
        }

        const path =
            getPath(virtualEventSession) || getPath({ roomType: params && params.virtualSession });

        if (user && user.id && virtualEventSession && virtualEventSession.id) {
            const isBlocked =
                virtualEventSession &&
                (await checkIfUserIsBlocked(virtualEventSession.id, user.id));

            if (isBlocked) {
                setBlockedUser(true);
            } else {
                openUrl(path);
            }
        } else if (isExternalLink) {
            openUrl(path);
        } else if (
            !virtualEventSession ||
            (!virtualEventSession.roomType && path === 'virtual-session-room')
        ) {
            history.push({
                pathname: `/${path}/${id}`,
                state: { prevPath: history.location.pathname },
            });
        }
    };

    const checkIfUserIsBlocked = async (sessionId, userId) => {
        const { blocked } = await isUserBlockedBySessionIdAndUserId(sessionId, userId);

        return blocked;
    };

    const bookmarkedButtonSmall = (
        <BookmarkButton
            favoriteStatus={favoriteStatus}
            clickMyCongress={clickMyCongress}
            smallBookmarkButton={true}
        />
    );
    const bookmarkedButtonLarge = (
        <BookmarkButton favoriteStatus={favoriteStatus} clickMyCongress={clickMyCongress} />
    );

    if (restrictedUser) {
        const ENTER_ROOM_TEXT = getString('virtualEnterVirtualRoom') || 'Enter virtual room';
        const RECORDING_ROOM_TEXT = getString('virtualRoomViewRecording') || 'View Recording';

        const VIRTUAL_ROOM_NO_PERMISSION_TEXT = getString(
            'noPermissionVirtualRoom',
            'You do not have permission to enter this virtual room.',
        );
        const RECORDINGS_NO_PERMISSION_TEXT = getString(
            'noPermissionRecordings',
            'You do not have permission to see these recordings.',
        );

        let buttonText = ENTER_ROOM_TEXT;
        let tooltipText = VIRTUAL_ROOM_NO_PERMISSION_TEXT;
        if (virtualSession === 'on-demand') {
            buttonText = RECORDING_ROOM_TEXT;
            tooltipText = RECORDINGS_NO_PERMISSION_TEXT;
        }

        const RestrictedVirtualSessionButton = () => (
            <CustomButton
                type={buttonTypes.GREEN_LONG}
                text={buttonText}
                background="rgba(0, 0, 0, 0.38)"
                enabled={false}
                icon={'lock'}
                style={{ pointer: 'default' }}
                tooltip={tooltipText}
                tooltipPosition="top"
            />
        );

        return (
            <>
                {virtualSession && hasCheckin && (
                    <ButtonsContainer>
                        <RestrictedVirtualSessionButton />
                    </ButtonsContainer>
                )}
                <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                    {virtualSession && !hasCheckin && <RestrictedVirtualSessionButton />}
                    {hasCheckin && <CheckinButton id={id} type={type} />}
                    {virtualSession && !hasCheckin ? bookmarkedButtonSmall : bookmarkedButtonLarge}
                    {virtualSession || hasCheckin ? notesButton : notesButtonLarge}
                </ButtonsContainer>
            </>
        );
    }

    const ViewRecordingNonVirtualRoomButton = () => (
        <CustomButton
            type={buttonTypes.GREEN_LONG}
            background={theme.primary}
            icon={'videocam'}
            text={'View Recordings'}
            onClick={() => history.push(`/video/${type}/${id}`)}
        />
    );

    if (isOnDemandRecording && onDemandLink && onDemandLinkScreen && !virtualEventSession) {
        return (
            <>
                {hasCheckin && (
                    <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                        <ViewRecordingNonVirtualRoomButton />
                    </ButtonsContainer>
                )}
                <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                    {hasCheckin ? (
                        <CheckinButton id={id} type={type} />
                    ) : (
                        <ViewRecordingNonVirtualRoomButton />
                    )}
                    {hasCheckin ? bookmarkedButtonLarge : bookmarkedButtonSmall}
                    {notesButton}
                </ButtonsContainer>
            </>
        );
    }

    if (!virtualSession) {
        return (
            <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                {hasCheckin && <CheckinButton id={id} type={type} />}
                <BookmarkButton favoriteStatus={favoriteStatus} clickMyCongress={clickMyCongress} />
                {hasCheckin ? notesButton : notesButtonLarge}
            </ButtonsContainer>
        );
    }

    const VirtualSessionButton = () =>
        isSessionOpen ? (
            <EnterVirtualSessionButton
                key={`enter_virtual_session_${id}`}
                setModalVisible={setModalVisible}
                enterVirtualroom={enterVirtualRoom}
                virtualSessionType={virtualSessionType}
                type={virtualSession}
                isExternalLink={isExternalLink}
                virtualEventSession={virtualEventSession}
                timeslotId={id}
                customButton={customButton}
                customButtonType={customButtonType}
                customButtonText={customButtonText}
                restrictedUser={restrictedUser}
                hasCheckin={hasCheckin}
                usersJoinedState={usersJoinedState}
            />
        ) : (
            <EnterVirtualSesssionClosedButton timeslotId={id} />
        );

    return (
        <div key={`enter-virtual-session-${id}`}>
            <DialogContainerStyled
                id="speed-boost"
                visible={modalVisible}
                title="Enter this virtual session?"
                onHide={() => setModalVisible(false)}
                aria-describedby="speed-boost-description"
                actions={[
                    <ButtonStyled
                        flat
                        primary
                        primarycolor={theme.primary}
                        theming={'inversed'}
                        onClick={() => setModalVisible(false)}
                    >
                        Cancel
                    </ButtonStyled>,
                    <ButtonStyled
                        flat
                        primary
                        primarycolor={theme.primary}
                        onClick={() => {
                            setModalVisible(false);
                            enterVirtualRoom();
                        }}
                    >
                        Open new session
                    </ButtonStyled>,
                ]}
                width={'360px'}
                modal
                portal
            >
                <Paragraph>
                    If you enter this virtual session, you will leave the current one.
                </Paragraph>
            </DialogContainerStyled>

            <WarningDialog
                open={blockedUser}
                title="You are blocked"
                content={
                    <Paragraph>
                        You cannot join this virtual session anymore because you have been blocked
                        by a moderator.
                    </Paragraph>
                }
                onClose={() => setBlockedUser(false)}
            />

            {hasCheckin && (
                <ButtonsContainer>
                    <VirtualSessionButton />
                </ButtonsContainer>
            )}

            <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                {!hasCheckin && <VirtualSessionButton />}

                {hasCheckin && <CheckinButton id={id} type={type} />}

                {hasCheckin ? bookmarkedButtonLarge : bookmarkedButtonSmall}

                {notesButton}
            </ButtonsContainer>
        </div>
    );
};

export default withRouter(TimeslotVirtualSession);
