import React from 'react';
import { connect } from 'react-redux';

import { hidePlatformInfo as hidePlatformInfoAction } from '../../actions';
import { TitleContainer, TitleIcon, TitleText } from '../details';

const PlatformInfoTitle = ({ hidePlatformInfo }) => {
    return (
        <TitleContainer>
            <TitleIcon
                iconClassName="material-icons-outlined"
                onClick={e => {
                    e.stopPropagation();
                    hidePlatformInfo();
                }}
            >
                arrow_back
            </TitleIcon>
            <TitleText>About this platform</TitleText>
            <TitleIcon hidden />
        </TitleContainer>
    );
};

export default connect(null, {
    hidePlatformInfo: hidePlatformInfoAction,
})(PlatformInfoTitle);
