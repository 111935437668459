import React, { useEffect, useRef, useState } from 'react';
import * as palette from '../components/General/Variables';
import { getConfigurations, getLocalAppState, updateApp } from '../services/api';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { default as Store } from '../services/api/store';
import { EUREKA_TALK_HOST } from '../config';
import { EVENT_NOT_FOUND_MESSAGE } from '../App';
import { getImageSourceAsync, getLocalItem } from '../services/api/db';
import NotificationService from '../scenes/Notifications/services/NotificationService';
import PasswordProtect from '../PasswordProtect';
import NavItemLink from '../components/NavItemLink';
import EurekaHeader from '../components/EurekaHeader';
import * as AppStyle from './style/navigationStyle';
import { Provider } from 'react-redux';
import reduxStore from '../reduxStore';
import Routes from '../components/Routes';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { XMPPManager } from '../scenes/Talk/components/XMPPManager';
import Auth from '../services/api/auth';
import { ContainerProvider } from '../utils/container';
import OneSignalWrapper from '../scenes/OneSignal/containers/OneSignalWrapper';
import RemindersWrapper from '../scenes/Reminders/RemindersWrapper';
import NotificationWrapper from '../scenes/Notifications/components/NotificationWrapper';
import Loader from '../components/General/Loader';
import ProtectionWrapper from '../components/ProtectedEvents/ProtectionWrapper';
import TopNavigation from './containers/TopNavigation';
import SideNavigation from './containers/SideNavigation';
import NavigationContent from './containers/NavigationContent';
import { executeQuery } from '../services/api/graphQlRepository';
import { navigationIconsWithClass } from './utils/utils';

// the type of the app is given by the subdomain event1-client.kiosk. or event1-client.web.
const TYPE_WEB_APP_KIOSK = 'kiosk';
const NAVIGATION_TYPES = {
    SIDE_MENU: 'sideMenu',
    TOP_NAVIGATION: 'iconMenu',
};

const AppNavigation = props => {
    const queryParams = new URLSearchParams(props.location.search);
    const mode = queryParams.get('mode');
    const webAppType = window.location.hostname.split('.');
    const isLandingPage = props.location.pathname.toLowerCase().includes('/landing');
    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
    );

    const routes = useRef();
    const embed = !!(mode && mode === 'embed');
    const kiosk = webAppType === TYPE_WEB_APP_KIOSK;

    const [appReady, setAppReady] = useState(false);
    const [appState, setAppState] = useState({});
    const [theme, setTheme] = useState(null);
    const [eventName, setEventName] = useState('');
    const [noScroll, setNoScroll] = useState(false);
    const [title, setPageTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [navigation, setNavigation] = useState([]);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [available, setAvailable] = useState(false);
    const [connectionErrors, setConnectionErrors] = useState(false);
    const [eventNotFound, setEventNotFound] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState(null);
    const [id, setId] = useState(null); // AKA eventId
    const [installationId, setInstallationId] = useState(null);
    const [eurekaOnly, setEurekaOnly] = useState(false);
    const [attendingOnly, setAttendingOnly] = useState(false);
    const [hiddenPassword, setHiddenPassword] = useState(null);
    const [oneSignalAppId, setOneSignalAppId] = useState(null);
    const [oneSignalSafariWebId, setOneSignalSafariWebId] = useState(null);
    const [visibleSideMenu, setVisibleSideMenu] = useState(!isLandingPage && isDesktop);
    const [showInstallMobileAppModal, setShowInstallMobileAppModal] = useState(false);
    const [isSidepanelOpened, setIsSidepanelOpened] = useState(false);
    const [branchIoDomain, setBranchIoDomain] = useState(null);
    const [navigationType, setNavigationType] = useState(NAVIGATION_TYPES.SIDE_MENU);
    const [navigationItemsMobile, setNavigationItemsMobile] = useState(null);
    const [navigationItemsMobileConfigured, setNavigationItemsMobileConfigured] = useState(null);

    const setTitle = title => setPageTitle(title);

    const disableScroll = isTrue => setNoScroll(isTrue);

    const handleSidepanel = isTrue => setIsSidepanelOpened(isTrue);

    const hideConfirmDialog = () => setConfirmDialogVisible(false);

    const onSideMenuVisibilityChange = () => setVisibleSideMenu(!visibleSideMenu);

    useEffect(() => {
        (async () => {
            await update();
            NotificationService.requestPermission();
        })();
    }, []);

    useEffect(() => {
        const connectionErrors = eventNotFound || loading;
        const isAvailable = !connectionErrors && available;
        setConnectionErrors(connectionErrors);
        setAvailable(isAvailable);
    }, [eventNotFound, loading]);

    useEffect(() => {
        const isLandingPage = props.location.pathname.toLowerCase().includes('/landing');
        if (isLandingPage && visibleSideMenu) {
            setVisibleSideMenu(false);
        }
    }, [props.location.pathname]);

    const update = async () => {
        setLoading(true);

        updateApp(err => {
            if (err) {
                console.log(err);

                switch (err.message || err) {
                    case EVENT_NOT_FOUND_MESSAGE:
                        setEventNotFound(true);
                        setLoading(false);
                        setAppReady(true);
                        break;
                    default:
                        setAppReady(true);
                        break;
                }
            }

            getLocalAppState(async (err, appState) => {
                if (appState && appState.floqHost) {
                    const floqDomain = appState.domain.replace(`${appState.uniqueName}-`, '');
                    window.location.href = `https://${floqDomain}.${appState.floqHost}/event/${appState.uniqueName}`;
                    return;
                }

                if (err) {
                    console.log(err);
                } else {
                    await props.initTimezoneContext();
                    const params = queryString.parse(props.location.search);
                    if (params.token) {
                        try {
                            Cookies.set('userOnboardingInfo', params.token);
                        } catch (e) {
                            //console.log('invalid token');
                        }
                    }

                    const pageHeader =
                        appState.pageHeader && (await getImageSourceAsync(appState.pageHeader));

                    getConfigurations(appState.eventId, async (err, data) => {
                        const { style, settings, strings } = data;
                        let theme = {
                            primary: '#673ab7',
                            secondary: '#7E57C2',
                            contrast: '#f6a738',
                            textSponsor: '#f6a738',
                        };

                        if (style) {
                            theme = {
                                primary: style.colors.primary,
                                secondary: style.colors.secondary,
                                contrast: style.colors.contrast,
                                textSponsor: style.colors.textSponsor,
                            };
                        }

                        if (settings) {
                            Store.argument = settings;
                        }

                        if (strings) {
                            Store.string = strings;
                        }

                        props.setTheme({
                            ...theme,
                            embed,
                            kiosk,
                        });

                        checkShowPasswordModal(appState, kiosk);

                        const pathnamePrefix = props.location.pathname.split('/')[1];
                        const currentNavigationItem =
                            appState.navigation.find(
                                navItem => navItem.to === `/${pathnamePrefix}`,
                            ) ||
                            appState.navigation[0] ||
                            {};

                        let navigationItems = appState.navigationIcons;
                        if (typeof appState.navigationIcons === 'string') {
                            navigationItems = JSON.parse(appState.navigationIcons);
                        }
                        setNavigationItemsMobile(navigationItems);
                        setTheme(theme);
                        setEventName(appState.eventName);
                        setLoading(false);
                        setNavigation(appState.navigation || []);
                        setAvailable(appState.available);
                        setPageTitle(currentNavigationItem.title || '');
                        setPassword(!!appState.kioskPassword);
                        setId(appState.id);
                        setInstallationId(appState.installationId);
                        setEurekaOnly(appState.eurekaOnly);
                        setAttendingOnly(appState.attendingOnly);
                        setHiddenPassword(appState.hiddenPassword);
                        setOneSignalAppId(appState.oneSignalAppId);
                        setOneSignalSafariWebId(appState.oneSignalSafariWebId);
                        setAppReady(true);
                        setAppState({ ...appState, pageHeader });
                        setBranchIoDomain(appState.branchIoDomain);
                        setShowInstallMobileAppModal(
                            isMobileDevice && isMobile && appState.branchIoDomain,
                        );
                        setNavigationType(appState.navigationType || NAVIGATION_TYPES.SIDE_MENU);
                        document.title =
                            appState.eventName && appState.eventName !== ''
                                ? appState.eventName
                                : 'Web App';
                    });
                }
            });
        });
    };

    const getWebappIconUsingClass = classIcon => {
        const selectedIcon = navigationIconsWithClass.find(it => it.configuratorIcon === classIcon);
        return selectedIcon?.webappIcon;
    };

    useEffect(() => {
        (async () => {
            if (id && navigationItemsMobile && navigationItemsMobile.length) {
                const pages = await executeQuery('getPagesWithEventAndName', {
                    event: id,
                });

                const items = [];
                navigationItemsMobile.map(i => {
                    const screen = i.screen || 'unkown';
                    let iconLabel = i.customLabel || i.label;
                    const page = pages.find(
                        p =>
                            p.name === screen ||
                            (i.customElements && p.name === iconLabel.toLowerCase()),
                    );

                    let { contentKind } = page || { contentKind: 'notfound' };
                    contentKind = i.url ? 'webpage' : contentKind;
                    if (!iconLabel) {
                        if (page && page.params && page.params.title) {
                            iconLabel = page.params.title;
                        } else {
                            iconLabel = 'no label';
                        }
                    }
                    iconLabel = iconLabel.replace('*newline*', '\n');

                    const params = {
                        ...i,
                        iconLabel,
                        contentKind,
                        icon: i.class,
                        iconType: 'material-community',
                        subItems: page?.params?.items,
                        screenName: i.screen || null,
                        webappIcon: i.webappIcon || getWebappIconUsingClass(i.class),
                        kind: page.kind,
                        pageId: page.id,
                        type: page.kind,
                    };
                    if (i.screen === 'home') {
                        params.screenName = 'newsfeed';
                        params.contentKind = 'newsfeed';
                    }
                    items.push(params);
                });
                setNavigationItemsMobileConfigured(items);
            }
        })();
    }, [navigationItemsMobile, id]);

    const checkShowPasswordModal = (appState, kiosk) => {
        if (!kiosk || !appState.isKioskPasswordProtected) {
            return;
        }

        getLocalItem('appState', 'userEnteredPassword', (err, item) => {
            if ((err || !item) && kiosk) {
                return setShowPasswordModal(true);
            }
        });
    };

    if (showPasswordModal) {
        return (
            <PasswordProtect
                password={password}
                eventName={eventName}
                hideShowModal={() => setShowPasswordModal(false)}
            />
        );
    }

    const childProps = {};
    const navItems = () => {
        const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;

        if (
            navigationItemsMobileConfigured &&
            navigationItemsMobileConfigured.length &&
            isMobile &&
            navigationType === NAVIGATION_TYPES.TOP_NAVIGATION
        ) {
            const items = [];
            navigationItemsMobileConfigured.map(topNavItem => {
                let item = {};
                if (topNavItem?.subItems?.length && topNavItem.kind !== 'tabs') {
                    topNavItem.subItems.map(it => {
                        item = {
                            imageUrl: null,
                            materialIcon: { webappIcon: topNavItem.webappIcon },
                            pageId: topNavItem.pageId,
                            title: it.title,
                            to: `/${it.screenName}`,
                            type: topNavItem.kind,
                        };
                        items.push(item);
                    });
                } else {
                    item = {
                        imageUrl: null,
                        materialIcon: { webappIcon: topNavItem.webappIcon },
                        pageId: topNavItem.pageId,
                        title: topNavItem.label,
                        to: `/${topNavItem.screen}`,
                        type: topNavItem.kind,
                    };
                    items.push(item);
                }

                return items;
            });

            return items.map((item, index) => {
                if (item.to) {
                    return (
                        <NavItemLink
                            key={`${item.type}_${index}`}
                            {...item}
                            item={item}
                            getWebappIconUsingClass={getWebappIconUsingClass}
                            visibleFullSideMenu={visibleSideMenu}
                            index={index}
                        />
                    );
                }
            });
        }

        let nav = navigation.slice(0);
        // if the app is in fullscreen, remove the user navigation items
        if (kiosk) {
            const removeActions = [
                'signout',
                'signin',
                'myconversations',
                'mynotes',
                'myprogramme',
                'myfavorites',
            ];

            nav = nav.filter(item => {
                return removeActions.indexOf(item.action) < 0;
            });
        }

        return nav.map((item, index) => {
            if (item.to) {
                return (
                    <NavItemLink
                        key={`${item.type}_${index}`}
                        {...item}
                        item={item}
                        setTitle={setTitle}
                        getWebappIconUsingClass={getWebappIconUsingClass}
                        visibleFullSideMenu={visibleSideMenu}
                        index={index}
                    />
                );
            }
        });
    };

    const navMenu = () => {
        const elements = navItems();
        elements.unshift(<EurekaHeader key="EurekaHeader" />);
        elements.push(<AppStyle.Margin key="margin" />);
        return elements;
    };

    const mobileDeepLink = location => {
        var iframe = document.createElement('iframe');
        iframe.src = location;

        // hide iframe visually
        iframe.width = 0;
        iframe.height = 0;
        iframe.frameBorder = 0;

        var now = new Date().valueOf();
        setTimeout(function () {
            if (new Date().valueOf() - now > 100) return;
            window.location = location;
        }, 10);

        // Append it to the body.
        document.body.appendChild(iframe);
        iframe.parentNode.removeChild(iframe);
    };

    const hideNavigation =
        props.location.pathname.indexOf('/virtual-session') > -1 ||
        props.location.pathname.indexOf('/virtual-session-room') > -1 ||
        props.location.pathname.indexOf('/video') > -1 ||
        props.location.pathname.toLowerCase().indexOf('/exhibitorbooth') > -1;

    const Container = embed || hideNavigation ? AppStyle.Base : AppStyle.StyledNavigationDrawer;

    const isSignInPage = props.location.pathname.toLowerCase().includes('/sign-in');

    if (isSignInPage && appReady) {
        return (
            <Provider store={reduxStore}>
                <Routes
                    ref={routes}
                    childProps={childProps}
                    nav={navigation}
                    setTitle={setTitle}
                    disableScroll={disableScroll}
                    location={props.location}
                    installationId={installationId}
                    handleSidepanel={handleSidepanel}
                    isSidepanelOpened={isSidepanelOpened}
                    eventId={id}
                    navigationType={navigationType}
                    visibleFullSideMenu={visibleSideMenu}
                />
            </Provider>
        );
    }

    // Setting Material UI ThemeProvider
    const materialUITheme = createTheme({
        palette: {
            type: 'light', // we can later use dark/light modes
            primary: {
                main: theme ? theme.primary : '#673ab7',
            },
            secondary: {
                main: theme ? theme.secondary : '#7E57C2',
            },
        },
    });

    const renderContent = () => (
        <NavigationContent
            isSidepanelOpened={isSidepanelOpened}
            routes={routes}
            childProps={childProps}
            navigation={navigation}
            setTitle={setTitle}
            location={props.location}
            disableScroll={disableScroll}
            installationId={installationId}
            handleSidepanel={handleSidepanel}
            hideConfirmDialog={hideConfirmDialog}
            confirmDialogVisible={confirmDialogVisible}
            showInstallMobileAppModal={showInstallMobileAppModal}
            mobileDeepLink={mobileDeepLink}
            setShowInstallMobileAppModal={setShowInstallMobileAppModal}
            branchIoDomain={branchIoDomain}
            eventId={id}
            navigationType={navigationType}
            visibleFullSideMenu={visibleSideMenu}
        />
    );

    if (appReady) {
        return (
            <Provider store={reduxStore}>
                <ThemeProvider theme={materialUITheme}>
                    {EUREKA_TALK_HOST && (
                        <XMPPManager
                            isAuthenticated={Auth.isUserAuthenticated()}
                            eventId={id}
                            chatUrl={EUREKA_TALK_HOST}
                        />
                    )}
                    <ContainerProvider eventId={id}>
                        <OneSignalWrapper
                            appId={oneSignalAppId}
                            safariWebId={oneSignalSafariWebId}
                            eventId={id}
                            subdomain={webAppType}
                        >
                            <RemindersWrapper>
                                <NotificationWrapper
                                    theme={theme}
                                    isAuthenticated={Auth.isUserAuthenticated()}
                                    location={props.location}
                                >
                                    {eventNotFound && (
                                        <div className="mdl-layout mdl-js-layout">
                                            <AppStyle.LoaderContainer>
                                                <AppStyle.EventNotFoundImage />
                                                <h4>{'Event not found'}</h4>
                                                <p>{'Make sure you accessed the correct URL.'}</p>
                                            </AppStyle.LoaderContainer>
                                        </div>
                                    )}

                                    {loading && (
                                        <AppStyle.LoaderContainer>
                                            <Loader />
                                        </AppStyle.LoaderContainer>
                                    )}

                                    {!connectionErrors && !available && (
                                        <AppStyle.EmptyState>
                                            <h4>Page not available…</h4>
                                            <p>Unfortunately, it’s quite empty here.</p>
                                            <AppStyle.PlaceholderImage />
                                        </AppStyle.EmptyState>
                                    )}

                                    {available && !eventNotFound && (
                                        <ProtectionWrapper
                                            eurekaOnly={eurekaOnly}
                                            attendingOnly={attendingOnly}
                                            hiddenPassword={hiddenPassword}
                                        >
                                            {navigationType === NAVIGATION_TYPES.TOP_NAVIGATION && (
                                                <TopNavigation
                                                    title={title}
                                                    navigationItems={
                                                        navigationItemsMobileConfigured
                                                    }
                                                    isSidepanelOpened={isSidepanelOpened}
                                                    appState={appState}
                                                    newVersionAvailable={props.newVersionAvailable}
                                                    updateServiceWorker={props.updateServiceWorker}
                                                    onServiceWorkerUpdate={
                                                        props.onServiceWorkerUpdate
                                                    }
                                                    waitingWorker={props.waitingWorker}
                                                    setTitle={setPageTitle}
                                                >
                                                    {renderContent()}
                                                </TopNavigation>
                                            )}

                                            {navigationType === NAVIGATION_TYPES.SIDE_MENU && (
                                                <SideNavigation
                                                    title={title}
                                                    isMobile={isMobile}
                                                    visibleSideMenu={visibleSideMenu}
                                                    isSidepanelOpened={isSidepanelOpened}
                                                    noScroll={noScroll}
                                                    navMenu={navMenu}
                                                    onSideMenuVisibilityChange={
                                                        onSideMenuVisibilityChange
                                                    }
                                                    renderContent={renderContent}
                                                    Container={Container}
                                                    theme={theme}
                                                    appState={appState}
                                                    newVersionAvailable={props.newVersionAvailable}
                                                    updateServiceWorker={props.updateServiceWorker}
                                                    waitingWorker={props.waitingWorker}
                                                    onServiceWorkerUpdate={
                                                        props.onServiceWorkerUpdate
                                                    }
                                                />
                                            )}
                                        </ProtectionWrapper>
                                    )}
                                </NotificationWrapper>
                            </RemindersWrapper>
                        </OneSignalWrapper>
                    </ContainerProvider>
                </ThemeProvider>
            </Provider>
        );
    }
    return null;
};

export default AppNavigation;
