import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Auth from '../../../services/api/auth';
import { UserChatCard as UserChatCardComponent } from '../components/UserChatCard';
import { loadPrivateChatMessages as loadPrivateChatMessagesAction } from '../actions';
import {
    getIsLoadingChatMessages,
    getLoadChatMessagesError,
    getPrivateConversationWithId,
    getUserChatCardLastMessages,
} from '../selectors';
import ChatCard from '../components/ChatCard';

const Wrapper = styled.div`
    margin-horizontal: 16px;
`;

const UserChatCardContainer = ({
    user,
    conversation,
    messages,
    loadPrivateChatMessages,
    matchUrl,
    history,
    useNewCard,
}) => {
    useEffect(() => {
        loadPrivateChatMessages({
            from: user.jid,
            limit: 2,
        });
    }, []);

    const onChatCardClick = (e, showSignIn) => {
        // From mobile
        const convData =
            conversation && conversation.id
                ? conversation
                : {
                      id: user.id,
                      jid: user.jid,
                      title: user.displayName || `${user.firstName} ${user.lastName}`,
                      lastMessage: messages[0],
                      participant: user,
                  };
        if (!Auth.isUserAuthenticated()) {
            showSignIn(e);
            return;
        }
        if (history && user.id) {
            history.push(`${matchUrl}/privateChat/${user.id}`, {
                conversation: convData,
            });
        }
    };

    return (
        <Wrapper>
            {useNewCard ? (
                <ChatCard
                    user={user}
                    messages={messages}
                    clickHandler={onChatCardClick}
                    isProfileChatCard={true}
                />
            ) : (
                <UserChatCardComponent
                    user={user}
                    messages={messages}
                    clickHandler={onChatCardClick}
                />
            )}
        </Wrapper>
    );
};

const mapStateToProps = (state, ownProps) => {
    const conversationId = ownProps.user.id;
    return {
        isLoading: getIsLoadingChatMessages(state, conversationId),
        error: getLoadChatMessagesError(state, conversationId),
        messages: getUserChatCardLastMessages(state, conversationId),
        conversation: getPrivateConversationWithId(state, conversationId),
    };
};

const UserChatCard = connect(mapStateToProps, {
    loadPrivateChatMessages: loadPrivateChatMessagesAction,
})(withRouter(UserChatCardContainer));

export { UserChatCard };
