import React, { useContext, useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import { withRouter } from 'react-router-dom';
import sortBy from 'lodash/sortBy';

import ReactPlayer from 'react-player';
import styled from 'styled-components';
import eventBus from '../../utils/eventBus';
import Header from '../../components/DetailHeader';
import NavigationBar from '../../components/DetailHeader/NavigationBar.js';
import Loader from '../../components/General/Loader.js';
import * as palette from '../../components/General/Variables.js';
import { useTheme } from '../../components/Theme/ThemeContext';
import { addFavorite, hasNote, makeItem, removeFavorite } from '../../services/api/data';
import * as eureka from '../../services/api/eureka';
import { getFullProfile } from '../../services/api/eureka';
import InteractivityBar from './interactivityTab/InteractivityBar';
import AnalyticsService from '../../features/analytics/services';
import { TimezoneContext } from '../../scenes/Timezone/context';
import Info from '../ExhibitorPage/components/content/Info';
import Tabs from '../../components/Tabs';
import Resources from '../ExhibitorPage/components/content/Resources';
import SectionsWrapper from './SectionsWrapper';
import InfoTab from './infoTab';
import ContactTab from './contactTab';
import Notes from './interactivityTab/Notes';
import AuthUserContext from '../../components/Session/AuthUserContext';
import InteractivityContext from '../../components/Interactivity/InteractivityContext';
import { getImageSource } from '../../services/api/db';
import Auth from '../../services/api/auth';
import TypeAccessRestrictionsForTabs, {
    tabTypes,
} from '../AccessRestrictions/TypeAccessRestrictionsForTabs';
import {
    AchievementType,
    useAchievementActions,
} from '../Achievements/hooks/useAchievementActions';
import { getString } from '../../services/api/store';
import { DetailDrawer } from '../../components/General/DetailDrawer';

const Scrollable = styled.section.attrs({
    className: 'scrollable-div',
})`
    height: calc(100vh - 158px);
    overflow: auto;
    border-radius: 12px;
    width: 100%;
`;

const ReactPlayerWrapper = styled.div`
    width: 100%;
    height: 256px;
    padding: 0 16px;

    iframe {
        border-radius: 8px;
    }
`;

const DetailPage = props => {
    const [item, setItem] = useState({ params: {}, sections: [] });
    const [navigationTitle, setNavigationTitle] = useState(null);
    const [updateNote, setUpdateNote] = useState(false);
    const [favoriteStatus, setFavoriteStatus] = useState('hidden');
    const [firstLocation, setFirstLocation] = useState(null);
    const { trackAchievement } = useAchievementActions();
    const [loading, setLoading] = useState(true);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [evaluationScreen, setEvaluationScreen] = useState(null);
    const [currentUserGroups, setCurrentUserGroups] = useState([]);
    const [loadingUserGroups, setLoadingUserGroups] = useState(true);
    const [showEvaluationScreen, setShowEvaluationScreenExternal] = useState(false);

    const navBar = useRef();
    const header = useRef();
    const { theme } = useTheme();
    const { objectId } = props.match.params;
    const { timeslotToTimezone } = useContext(TimezoneContext);

    let scroll = 0;
    let scrollDirection = 'UP';

    const _handleScroll = throttle(() => {
        const element = document.getElementById(`scrollable-${item.id}`);
        if (!element) {
            return;
        }

        const newScroll = element.scrollTop;
        if ((scroll > newScroll && scrollDirection !== 'UP') || (scroll === 0 && newScroll === 0)) {
            navBar.current.className = 'fixed-navbar';
            header.current.className = 'fixed-navbar-header';
            scrollDirection = 'UP';
            setNavigationTitle(item.typeName);
        } else if (scroll <= newScroll && scrollDirection !== 'DOWN' && newScroll > 48) {
            setNavigationTitle(item.name);
            scrollDirection = 'DOWN';
        }

        scroll = newScroll;
    }, 100);

    useEffect(() => {
        if (item.type === 'timeslot') {
            trackAchievement(AchievementType.VIEW_SCHEDULE_ITEM, item.id);
        }
    }, [item]);

    useEffect(() => {
        (async () => {
            if (!Auth.isUserAuthenticated()) {
                setLoadingUserGroups(false);
                return;
            }

            const fullProfile = await getFullProfile();
            const { userGroups } = fullProfile;

            setCurrentUserGroups(userGroups);
            setLoadingUserGroups(false);
        })();
    }, []);

    useEffect(() => {
        document.getElementById('root').className = 'noscroll';
        navBar.current.className = 'fixed-navbar';
        header.current.className = 'fixed-navbar-header';

        _handleScroll();

        details(props.match.params.objectClass, props.match.params.objectId);
        setUpdateNote(false);
        return function cleanup() {
            document.getElementById('root').className = '';
        };
    }, [objectId, updateNote]);

    useEffect(() => {
        setInitialLocation();
    }, []);

    const setInitialLocation = () => {
        if (!props.history.forceBack && !props.match.params.pageType) {
            setFirstLocation(props.location.pathname);
        } else {
            if (props.match.params.pageType && props.history.forceBack) {
                setFirstLocation(props.prefix || props.location.pathname);
                props.history.forceBack = null;
            } else {
                props.history.forceBack = null;
            }
        }
    };

    const details = (objectClass, objectId) => {
        if (!objectClass || !objectId) {
            return;
        }

        setLoading(true);
        navBar.current.className = 'fixed-navbar';
        header.current.className = 'fixed-navbar-header';
        scrollDirection = 'UP';
        makeItem(objectId, objectClass, (err, item) => {
            if (err) {
                console.log(err);
            } else {
                item = timeslotToTimezone(item);

                if (item && item.id) {
                    const itemBackgroundImage = item.params?.backgroundImage;
                    const typeDefaultBackgroundImage = item.typeParams?.defaultBackgroundImage;
                    const bgImageId = itemBackgroundImage || typeDefaultBackgroundImage;

                    if (bgImageId) {
                        getImageSource(bgImageId, (err, img) => {
                            setBackgroundImage(img);
                        });
                    } else {
                        setBackgroundImage(null);
                    }
                    hasNote(item.id, (err, isNote) => {
                        item.hasNote = isNote;
                        setItem(item);
                        setNavigationTitle(item.typeName);
                        setFavoriteStatus(item.favorite ? 'checked' : '');
                        setLoading(false);
                    });
                }
            }
        });
    };

    const onFavorite = () => {
        const _favoriteStatus = favoriteStatus === 'checked' ? '' : 'checked';
        setFavoriteStatus(_favoriteStatus);

        if (_favoriteStatus) {
            addFavorite(item, () => {
                AnalyticsService.addSample('favorite', true, item.id);
                trackAchievement(AchievementType.FAVORITE, item.id);

                eureka.sync(() => {
                    if (item.type === 'timeslot') {
                        eventBus.emit('updateMyProgrammeCount');
                    } else {
                        eventBus.emit('updateMyFavoritesCount');
                    }
                });
            });
        } else {
            removeFavorite(item, () => {
                AnalyticsService.addSample('favorite', false, item.id);

                eureka.sync(() => {
                    if (item.type === 'timeslot') {
                        eventBus.emit('updateMyProgrammeCount');
                    } else {
                        eventBus.emit('updateMyFavoritesCount');
                    }
                });
            });
        }
    };

    const interactivityInfo = {
        id: item.id,
        name: item.name,
        type: item.type,
        objectClass: props.match.params.objectClass,
        interactivity: item.interactivity,
        hasNote: item.hasNote,
    };
    const matchUrl = props.prefix ? props.prefix : '/' + props.match.url.split('/')[1];
    const hasCheckin = (item.interactivity || []).findIndex(item => item.id === 'checkin') > -1;

    const { backgroundType, backgroundVideo } = item.params || {};

    const classifierColor = item.params && item.params.color ? item.params.color : '';
    const canGoBack = props.location.pathname === firstLocation;

    const goBack = () => {
        if (props.prefix === firstLocation) {
            return props.history.push(props.prefix);
        }
        return props.history.goBack();
    };

    const tabsData = [];

    if (
        !loadingUserGroups &&
        ((item && item.info && item.info.text) ||
            (item &&
                item.sections &&
                item.sections.length &&
                item.sections.find(
                    i =>
                        i.type !== 'timeslot' &&
                        i.type !== 'link' &&
                        i.type !== 'place' &&
                        i.type !== 'programelement',
                )) ||
            (item.params && item.params.slideshow && item.params.slideshow.length > 0))
    ) {
        tabsData.push({
            id: 'tabInfoRestrictedGroup',
            label: getString('tabs.infoTab', 'Info'),
            content: () => (
                <TypeAccessRestrictionsForTabs
                    tabType={tabTypes.INFO}
                    typeParams={item.typeParams}
                    currentUserGroups={currentUserGroups}
                >
                    <InfoTab
                        item={item}
                        clickOpenDetail={props.clickOpenDetail}
                        matchUrl={matchUrl}
                        {...props}
                    />
                </TypeAccessRestrictionsForTabs>
            ),
        });
    }

    if (
        !loadingUserGroups &&
        item?.params &&
        (item?.params?.contactEmail ||
            item?.params?.contactFacebookUrl ||
            item?.params?.contactLinkedinUrl ||
            item?.params?.contactPhoneNumber ||
            item?.params?.contactTwitterUrl)
    ) {
        tabsData.push({
            id: 'tabContactRestrictedGroup',
            label: getString('contactTab', 'Contact'),
            content: () => (
                <TypeAccessRestrictionsForTabs
                    tabType={tabTypes.CONTACT}
                    typeParams={item.typeParams}
                    currentUserGroups={currentUserGroups}
                >
                    <ContactTab
                        item={item}
                        clickOpenDetail={props.clickOpenDetail}
                        matchUrl={matchUrl}
                        {...props}
                    />
                </TypeAccessRestrictionsForTabs>
            ),
        });
    }

    if (
        !loadingUserGroups &&
        item &&
        item.sections &&
        item.sections.length &&
        item.sections.find(i => i.type === 'timeslot' || i.type === 'programelement')
    ) {
        const scheduleItems = item.sections.filter(
            i => i.type === 'timeslot' || i.type === 'programelement',
        );
        tabsData.push({
            id: 'tabScheduleRestrictedGroup',
            label: getString('tabs.scheduleTab', 'Schedule'),
            content: () => (
                <TypeAccessRestrictionsForTabs
                    tabType={tabTypes.SCHEDULE}
                    typeParams={item.typeParams}
                    currentUserGroups={currentUserGroups}
                >
                    <SectionsWrapper
                        item={{ sections: scheduleItems }}
                        clickOpenDetail={props.clickOpenDetail}
                        matchUrl={matchUrl}
                    />
                </TypeAccessRestrictionsForTabs>
            ),
        });
    }

    if (
        !loadingUserGroups &&
        item &&
        !theme.embed &&
        !theme.kiosk &&
        item.interactivity &&
        item.interactivity.length
    ) {
        tabsData.push({
            id: 'tabInteractiveRestrictedGroup',
            label: getString('tabs.interactiveTab', 'Interactive'),
            content: () => (
                <TypeAccessRestrictionsForTabs
                    tabType={tabTypes.INTERACTIVE}
                    typeParams={item.typeParams}
                    currentUserGroups={currentUserGroups}
                >
                    <InteractivityBar
                        item={interactivityInfo}
                        history={props.history}
                        matchUrl={matchUrl}
                        setShowEvaluationScreenExternal={setShowEvaluationScreenExternal}
                        setEvaluationScreen={setEvaluationScreen}
                    />
                </TypeAccessRestrictionsForTabs>
            ),
        });
    }

    if (
        !loadingUserGroups &&
        item &&
        item.sections &&
        item.sections.length &&
        item.sections.find(i => i.type === 'link')
    ) {
        const linkItems = item.sections.filter(i => i.type === 'link');

        tabsData.push({
            id: 'tabResourcesRestrictedGroup',
            label: getString('tabs.resourcesTab', 'Resources'),
            content: () => (
                <TypeAccessRestrictionsForTabs
                    tabType={tabTypes.RESOURCES}
                    typeParams={item.typeParams}
                    currentUserGroups={currentUserGroups}
                >
                    <SectionsWrapper
                        item={{ sections: linkItems }}
                        clickOpenDetail={props.clickOpenDetail}
                        matchUrl={matchUrl}
                    />
                </TypeAccessRestrictionsForTabs>
            ),
        });
    }

    if (item.typeParams?.tabRestrictionsWithOrder) {
        tabsData.map(tab => {
            const tabWithGivenId = item.typeParams?.tabRestrictionsWithOrder.find(
                it => it.id === tab.id,
            );
            tab.order = tabWithGivenId?.order;
            tab.label = tabWithGivenId?.name;
            return tab;
        });
    }

    const places =
        (item &&
            item.sections &&
            item.sections.length &&
            item.sections.filter(i => i.type === 'place')) ||
        null;

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <InteractivityContext.Consumer>
                    {({ showNotes, showSignIn }) => (
                        <DetailDrawer>
                            {evaluationScreen && showEvaluationScreen ? (
                                <>{evaluationScreen}</>
                            ) : (
                                <>
                                    <div ref={navBar}>
                                        <NavigationBar
                                            scrollDirection={scrollDirection}
                                            goBack={!canGoBack ? goBack : null}
                                            closePath={props.closeLink}
                                            typeName={navigationTitle}
                                            background={palette.COLOR_WHITE}
                                        />
                                    </div>
                                    <Scrollable
                                        id={`scrollable-${item.id}`}
                                        onScroll={_handleScroll}
                                    >
                                        <div>
                                            {backgroundType === 'video' && backgroundVideo && (
                                                <ReactPlayerWrapper>
                                                    <ReactPlayer
                                                        url={backgroundVideo}
                                                        width="100%"
                                                        height="256px"
                                                        controls
                                                    />
                                                </ReactPlayerWrapper>
                                            )}

                                            <div ref={header}>
                                                <Header
                                                    id={item.id}
                                                    type={item.type}
                                                    time={item.time}
                                                    startTime={item.start}
                                                    endTime={item.end}
                                                    typeName={item.typeName}
                                                    title={item.title}
                                                    subtitle={item.subNameDetail}
                                                    picture={item.picture}
                                                    params={item.params}
                                                    backgroundType={backgroundType || 'image'}
                                                    backgroundImage={backgroundImage}
                                                    currentUserGroups={currentUserGroups}
                                                    favoriteStatus={favoriteStatus}
                                                    clickMyCongress={onFavorite}
                                                    typeParams={item.typeParams}
                                                    imageUrl={item.imageUrl}
                                                    matchUrl={matchUrl}
                                                    places={
                                                        places && places.length > 0 ? places : null
                                                    }
                                                    hasCheckin={hasCheckin}
                                                    notesButton={
                                                        <Notes
                                                            authUser={authUser}
                                                            showNotes={showNotes}
                                                            showSignIn={showSignIn}
                                                            theme={theme}
                                                            item={interactivityInfo}
                                                            objectId={objectId}
                                                            renderSmallButton={true}
                                                            setUpdateNote={setUpdateNote}
                                                            showNoteDialog={
                                                                props.location?.state
                                                                    ?.showNoteDialog
                                                            }
                                                        />
                                                    }
                                                    notesButtonLarge={
                                                        <Notes
                                                            authUser={authUser}
                                                            showNotes={showNotes}
                                                            showSignIn={showSignIn}
                                                            theme={theme}
                                                            item={interactivityInfo}
                                                            objectId={objectId}
                                                            setUpdateNote={setUpdateNote}
                                                            renderLargeGrayButton={true}
                                                            showNoteDialog={
                                                                props.location?.state
                                                                    ?.showNoteDialog
                                                            }
                                                        />
                                                    }
                                                />
                                            </div>
                                            {item.hasNote && (
                                                <Notes
                                                    authUser={authUser}
                                                    showNotes={showNotes}
                                                    showSignIn={showSignIn}
                                                    theme={theme}
                                                    item={interactivityInfo}
                                                    setUpdateNote={setUpdateNote}
                                                />
                                            )}
                                        </div>

                                        {!(loading || loadingUserGroups) && (
                                            <div>
                                                {tabsData && tabsData.length > 1 ? (
                                                    <Tabs
                                                        data={sortBy(tabsData, 'order')}
                                                        isSmallTab={true}
                                                        useMemo={true}
                                                    />
                                                ) : (
                                                    <>
                                                        {tabsData.length > 0 &&
                                                            tabsData[0].content()}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {loading && <Loader />}
                                    </Scrollable>
                                </>
                            )}
                        </DetailDrawer>
                    )}
                </InteractivityContext.Consumer>
            )}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(DetailPage);
