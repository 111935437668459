import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    checkShouldStartConversation as checkShouldStartConversationAction,
    leaveRooms as leaveRoomsAction,
} from '../../../Talk/actions';
import { getConversationWithRoomId } from '../../../Talk/selectors';

import ChatContainer from './chat/ChatContainer';

const Chat = ({ conversation, leaveRooms, checkShouldStartConversation }) => {
    useEffect(() => {
        checkShouldStartConversation(conversation);

        return () => {
            if (conversation) {
                // leaveRooms([conversation]);
                leaveRooms();
            }
        };
    }, []);

    return <ChatContainer conversation={conversation} chatType={`webapp_chat_virtual`} />;
};

const mapStateToProps = (state, ownProps) => {
    let conversation = ownProps.conversation;
    if (!conversation.jid) {
        const stateConversation = getConversationWithRoomId(state, conversation.roomId);
        if (stateConversation) {
            conversation = stateConversation;
        }
    }

    return {
        conversation,
    };
};

export default connect(mapStateToProps, {
    checkShouldStartConversation: checkShouldStartConversationAction,
    leaveRooms: leaveRoomsAction,
})(Chat);
