import React, { useEffect, useState } from 'react';
import get from 'lodash/get';

import Loader from '../../../components/General/Loader';
import Participants from './Participants';
import ChatRoomContainer from '../containers/ChatRoom'; // Wrapped group chat
import { getString } from '../../../services/api/store';
import { ChatWrapper } from '../../Talk/common';
import NavigationHeader from '../../Talk/components/NavigationHeader';
import Tabs from '../../../components/Tabs';

const TalkRoomComponent = ({
    conversation,
    title,
    goBack,
    closePath,
    chatType,
    checking = true,
    rootRoute,
}) => {
    const CONVERSATION_TITLE = getString('groupChatScreenTitle') || 'Conversation';
    const [isLoadingConversationData, setIsLoadingConversationData] = useState(true);
    const [attendees, setAttendees] = useState([]);

    useEffect(() => {
        const jid = get(conversation, 'jid');
        if (jid) {
            setIsLoadingConversationData(false);
            if (conversation.lastMessage) {
                const chatAttendees = conversation.participants.map(p => {
                    p.userId = p.id;
                    return p;
                });
                const sortedAttendees = sortAttendees(chatAttendees);
                setAttendees(sortedAttendees);
            } else {
                setAttendees([]);
            }
            if (!conversation.title) {
                conversation.title = title;
            }
        }
    }, [conversation]);

    const sortAttendees = attendees =>
        attendees.sort((attendee1, attendee2) => {
            const name1 = `${attendee1.lastName} ${attendee1.firstName}`;
            const name2 = `${attendee2.lastName} ${attendee2.firstName}`;

            return name1.toLowerCase().localeCompare(name2.toLowerCase());
        });

    const tabsData = [
        {
            label: 'Chat',
            labelIcon: 'chat_bubble',
            content: (
                <ChatRoomContainer
                    rootRoute={rootRoute}
                    conversationRoomId={conversation.roomId}
                    conversationId={conversation.id}
                    conversation={conversation}
                    chatType={chatType}
                />
            ),
        },
        {
            label: attendees.length,
            labelIcon: 'group',
            content: <Participants sortedAttendees={attendees} rootRoute={rootRoute} />,
        },
    ];

    const renderGroupChat = () => {
        return (
            <React.Fragment>
                <ChatWrapper>
                    {goBack && closePath && (
                        <NavigationHeader
                            goBack={goBack}
                            closePath={closePath}
                            title={`${CONVERSATION_TITLE} - ${title || conversation.title}`}
                            isGroupChat
                        />
                    )}
                    <Tabs data={tabsData} containerHeight={'calc(100% - 54px)'} />
                </ChatWrapper>
            </React.Fragment>
        );
    };

    return isLoadingConversationData || checking ? <Loader /> : renderGroupChat();
};

export default TalkRoomComponent;
