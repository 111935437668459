import { eurekaRequest } from '../../../services/api/eureka';

export const getAttendeesList = (eventId, limit, page, next) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/virtualEventSession/attendees/${eventId}?limit=${limit}&page=${page}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const getAllAttendees = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/virtualEventSession/attendees/${eventId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const getCurrentEventAttendees = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/chatAvailability/visible/event/${eventId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};
