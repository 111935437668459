import React, { useEffect, useState } from 'react';
import toast from 'toasted-notes';

import { useGlobalState } from '../../../utils/container';
import { getLocalAppStateAsync } from '../../../services/api/db';
import Auth from '../../../services/api/auth';
import AchievementNotification from '../components/AchievementNotification';

const useAchievementsListener = () => {
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const [event, setEvent] = useState();

    useEffect(() => {
        const loadEvent = async () => {
            const currentEvent = await getLocalAppStateAsync();
            setEvent(currentEvent);
        };

        loadEvent();
    }, []);

    useEffect(() => {
        if (!event) {
            return;
        }

        const user = Auth.getUser();

        const handleQuestionLiked = data => {
            const { achievement, notification } = data;

            if (!notification) {
                return;
            }

            // show notification
            toast.notify(
                ({ onClose }) => (
                    <AchievementNotification
                        title={notification.title}
                        subtitle={notification.subtitle}
                        level={notification.level}
                        icon={achievement.icon}
                        percentage={notification.percentage}
                        onClose={onClose}
                    />
                ),
                {
                    position: 'top-right',
                    duration: 4000,
                },
            );
        };

        const bootstrapAsync = async () => {
            socket.on(`refreshAchievement_${event.id}_${user.id}`, handleQuestionLiked);
        };

        bootstrapAsync();

        return () => {
            socket.removeAllListeners(
                `refreshAchievement_${event.id}_${user.id}`,
                handleQuestionLiked,
            );
        };
    }, [socket, event]);
};

export default useAchievementsListener;
