import React, { useMemo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { useGlobalState } from '../../../../../utils/container';
import Presenter from './Presenter';

const MainVirtualRoomWrapper = styled.div`
    width: 100%;
    display: flex;
    height: 100% !important;
    max-height: calc(100vh - 400px);
    background: #000;
    border-radius: 8px;
`;

const Wrapper = styled.div`
    height: 100%;

    .stream-player.video-on {
        height: 100%;
        width: 100%;

        [id*='player'] {
            background: transparent !important;
        }

        video {
            border-radius: 8px;
            background: #fff;
            /* This used to work for the parent element of button divs */
            /* But it does not work with newer browsers, the below doesn't hide the play button parent div */
            &::-webkit-media-controls-panel {
                display: none !important;
                -webkit-appearance: none;
            }

            /* Old shadow dom for play button */

            &::-webkit-media-controls-play-button {
                display: none !important;
                -webkit-appearance: none;
            }

            /* New shadow dom for play button */

            /* This one works! */

            &::-webkit-media-controls-start-playback-button {
                display: none !important;
                -webkit-appearance: none;
            }
        }
    }
`;

const VirtualRoomVideo = () => {
    const stateCtx = useGlobalState();
    const { currentVirtualEventUser, virtualEventSession } = stateCtx;

    const virtualEventUser = useMemo(() => {
        return get(virtualEventSession, 'VirtualEventUsers', []).find(vUser => vUser.id === get(currentVirtualEventUser, 'id'))
    }, [currentVirtualEventUser, virtualEventSession]);

    return (
        <React.Fragment>
            <Wrapper>
                <MainVirtualRoomWrapper>
                    {
                        virtualEventUser && (
                            <Presenter
                                virtualEventUser={virtualEventUser}
                                muted={true}
                            />
                        )
                    }
                </MainVirtualRoomWrapper>
            </Wrapper>
        </React.Fragment>
    );
};

export default VirtualRoomVideo;
