import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { get } from 'lodash';

import Tabs from '../../../../components/Tabs';
import ChatRoomContainer from '../../../TalkRoom/containers/ChatRoom'; // Wrapped group chat
import {
    addRepresentativesToConversation as addRepresentativesAction,
    checkShouldStartConversation as checkShouldStartConversationAction,
    leaveRooms as leaveRoomsAction,
} from '../../../Talk/actions';
import { getConversationWithRoomId, getServerOnline } from '../../../Talk/selectors';
import Loader from '../../../../components/General/Loader';
import Auth from '../../../../services/api/auth';
import { getString } from '../../../../services/api/store';
import Button, { buttonTypes } from '../../../common/Button';
import { InteractivityContext } from '../../../../components/Interactivity';
import * as palette from '../../../../components/General/Variables';
import { selectPrivateChatParticipant } from '../../selectors';
import { setPrivateChatUserId as setPrivateChatUserIdAction } from '../../actions';
import PrivateChat from '../../../Talk/containers/PrivateChat';
import eventBus from '../../../../utils/eventBus';
import SearchAndParticipantsList from './SearchAndParticipantsList';

const Container = styled.div`
    background-color: #ffffff;
    border-radius: 8px;
    padding: 16px 24px;
    height: calc(100vh - ${props => (!props.needBannerSpace ? '258px' : '116px')}) !important;
    min-height: calc(100vh - ${props => (!props.needBannerSpace ? '258px' : '116px')}) !important;
    transition: height 0.2s linear;
    position: sticky;
    top: 85px;
    right: 0;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        padding: 0 16px 16px 16px;
    }
`;

const TabsWrapper = styled.div`
    flex: 1;
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
`;

const ChatWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 57px;
    height: calc(100% - 57px);
    width: 100%;
`;

const ChatColumn = ({
    conversation,
    leaveRooms,
    checkShouldStartConversation,
    privateChatUserId,
    privateChatParticipant,
    setPrivateChatUserId,
    exhibitor,
    addRepresentatives,
    chatServerOnline,
    needBannerSpace,
}) => {
    const TEXT_REPRESENTATIVE_ROLE = getString('representativeRole') || 'Representative of';
    const isAuthenticated = Auth.isUserAuthenticated();
    const [loading, setLoading] = useState(true);
    const [conversationItem, setConversationItem] = useState(null);
    const [representativesInfo, setRepresentativesInfo] = useState(null);
    const participants = conversation.participants
        ? conversation.participants.map(p => ({ ...p, userId: p.id }))
        : [];
    const isGroupChat = !privateChatUserId;

    useEffect(() => {
        if (!isAuthenticated) {
            setLoading(false);
        }

        const setPrivateChatUserIdCallback = ({ userId }) => setPrivateChatUserId(userId);

        eventBus.on('openExhibitorBoothChat', setPrivateChatUserIdCallback);

        return () => {
            if (conversation && conversation.id) {
                leaveRooms();
            }
            eventBus.removeListener('openExhibitorBoothChat', setPrivateChatUserIdCallback);
        };
    }, []);

    useEffect(() => {
        if (exhibitor && exhibitor.id && exhibitor.event) {
            const sItem = {
                objectId: exhibitor.id,
                roomId: exhibitor.id,
                objectTitle: exhibitor.name,
                eventId: exhibitor.event,
                eventTitle: 'webapp_chat_exhibitor',
            };
            const representativesList = get(exhibitor, 'params.representatives', []);
            if (representativesList.length) {
                const representatives = representativesList.map(user => ({
                    id: user.userId,
                    title: `${TEXT_REPRESENTATIVE_ROLE} ${exhibitor.name}`,
                }));
                setRepresentativesInfo(representatives);
            }
            setConversationItem(sItem);
        }
    }, [exhibitor]);

    useEffect(() => {
        if (conversationItem && chatServerOnline) {
            checkConversation();
        }
    }, [conversationItem, chatServerOnline]);

    const checkConversation = async () => {
        await checkShouldStartConversation(conversationItem);
        if (conversation.id && representativesInfo) {
            addRepresentatives(conversation.id, representativesInfo);
        }
        setLoading(false);
    };

    const ChatContent =
        isAuthenticated && !loading ? (
            <ChatWrapper>
                <ChatRoomContainer
                    rootRoute={'/root'}
                    conversationRoomId={conversation.roomId}
                    conversationId={conversation.id}
                    conversation={conversation}
                    chatType={`webapp_chat_exhibitor`}
                />
            </ChatWrapper>
        ) : (
            <div>Sign in to see the chat</div>
        );

    const AttendeesContent = <SearchAndParticipantsList participants={participants} />;

    const tabsData = [
        {
            label: 'Chat',
            labelIcon: 'chat_bubble',
            content: ChatContent,
        },
        {
            label: participants.length,
            labelIcon: 'group',
            content: AttendeesContent,
        },
    ];

    const renderUnauthenticatedUserContent = () => (
        <InteractivityContext.Consumer>
            {({ showSignIn }) => (
                <Button
                    type={buttonTypes.GREEN_LONG}
                    text="Sign in to access chat"
                    onClick={showSignIn}
                />
            )}
        </InteractivityContext.Consumer>
    );

    const renderAuthenticatedUserContent = () => (
        <>
            {loading && <Loader />}
            {!loading && (
                <TabsWrapper>
                    <Tabs data={tabsData} checkContentAllTabs={true} />
                </TabsWrapper>
            )}
        </>
    );

    const renderGroupChat = () => {
        return (
            <Container needBannerSpace={needBannerSpace}>
                {isAuthenticated && renderAuthenticatedUserContent()}

                {!isAuthenticated && renderUnauthenticatedUserContent()}
            </Container>
        );
    };

    const renderPrivateChat = () => {
        const conversation = {
            id: privateChatParticipant.id,
            jid: privateChatParticipant.jid,
            title:
                privateChatParticipant.displayName ||
                `${privateChatParticipant.firstName} ${privateChatParticipant.lastName}`,
            participant: privateChatParticipant,
        };

        return (
            <PrivateChat
                conversationId={privateChatParticipant.id}
                propConversation={conversation}
                containerComponent={Container}
                goBack={() => setPrivateChatUserId(null)}
            />
        );
    };

    return (
        <>
            {isGroupChat && renderGroupChat()}
            {!isGroupChat && renderPrivateChat()}
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    let conversation = ownProps.conversation;
    const serverOnline = getServerOnline(state);
    if (!conversation) {
        conversation = {
            roomId: get(state, 'exhibitorBooth.exhibitor.id'),
        };
    }

    if (!conversation.jid) {
        const stateConversation = getConversationWithRoomId(state, conversation.roomId);
        if (stateConversation) {
            conversation = stateConversation;
        }
    }

    if (!conversation.title) {
        conversation.title = get(state, 'exhibitorBooth.exhibitor.name');
    }

    return {
        conversation,
        privateChatUserId: state.exhibitorBooth.privateChatUserId,
        privateChatParticipant: selectPrivateChatParticipant(state.exhibitorBooth),
        exhibitor: get(state, 'exhibitorBooth.exhibitor'),
        chatServerOnline: serverOnline,
    };
};

export default connect(mapStateToProps, {
    checkShouldStartConversation: checkShouldStartConversationAction,
    leaveRooms: leaveRoomsAction,
    setPrivateChatUserId: setPrivateChatUserIdAction,
    addRepresentatives: addRepresentativesAction,
})(ChatColumn);
