import React, { useContext } from 'react';
import { FontIcon } from 'react-md';
import moment from 'moment';
import styled from 'styled-components';

import { default as Store } from '../../../../services/api/store';
import StartEndTime from '../../../../components/General/StartEndTime';
import * as palette from '../../../../components/General/Variables';
import AuthButton from '../../../User/containers/AuthButton';
import { TimezoneContext } from '../../../Timezone/context';

const Wrapper = styled.div`
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    flex-flow: row;
    height: 56px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 16;
    padding: 0 16px 0 0 !important;
`;

const Icon = styled(FontIcon)`
    vertical-align: top;
    color: rgba(0, 0, 0, 0.87) !important;
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

const SessionInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    overflow: hidden;
    margin-left: 28px;
    height: 100%;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        margin-left: 12px;
    }
`;

const SessionName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Cabin', sans-serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
`;

const Header = ({ goBack, timeslot, newVersionAvailable, updateServiceWorker }) => {
    const { timeslotToTimezone } = useContext(TimezoneContext);

    const timeslotWithTimezone = timeslotToTimezone(timeslot);
    const timezone = Store.argument.offsetTimezone;
    const isAppointment = timeslot.type === 'appointment';

    let start = moment.utc(timeslotWithTimezone.start);
    if (!start.isValid()) {
        start = moment.unix(timeslotWithTimezone.start);
    }
    if (isAppointment) {
        start = start.utc();
    } else {
        start = start.utcOffset(timezone, true);
    }
    let end = moment.utc(timeslotWithTimezone.end);
    if (!end.isValid()) {
        end = moment.unix(timeslotWithTimezone.end);
    }
    if (isAppointment) {
        end = end.utc();
    } else {
        end = end.utcOffset(timezone, true);
    }
    const startTime = moment(start).format('HH:mm');
    const endTime = moment(end).format('HH:mm');

    return (
        <Wrapper>
            <Icon onClick={goBack}>{palette.ICON_BACK}</Icon>
            <SessionInfo desktopSize={9}>
                <TextWrapper>
                    <SessionName>{timeslot.name}</SessionName>
                    <StartEndTime text={`${startTime} - ${endTime}`} isVirtual />
                </TextWrapper>
            </SessionInfo>
            <AuthButton
                light
                newVersionAvailable={newVersionAvailable}
                updateServiceWorker={updateServiceWorker}
            />
        </Wrapper>
    );
};

export default Header;
