import React, { useEffect, useState } from 'react';
import AuthUserContext from './components/Session/AuthUserContext';
import { getFullProfile } from './services/api/eureka';
import styled from 'styled-components';
import PasswordProtect from './PasswordProtect';
import { Snackbar } from 'react-md';
import Loader from './components/General/Loader';
import { InteractivityContext } from './components/Interactivity';

const Paragraph = styled.p`
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
`;

const AccessRestrictions = ({
    pageId,
    result,
    accessRestrictions,
    isPasswordProtected,
    password,
}) => {
    const [userData, setUserData] = useState({ userGroups: [], id: null });
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [toasts, setToasts] = useState([
        { text: 'You do not have the required permissions to access this page.' },
    ]);

    useEffect(() => {
        setLoading(true);
        if (
            showPasswordModal !== isPasswordProtected &&
            isPasswordProtected !== undefined &&
            !checkIfPasswordWasEnteredForRestrictedPage(pageId)
        ) {
            setShowPasswordModal(isPasswordProtected);
        } else {
            if (isPasswordProtected) {
                setShowPasswordModal(!checkIfPasswordWasEnteredForRestrictedPage(pageId));
            } else {
                setShowPasswordModal(false);
            }
        }
        setLoading(false);
    }, [isPasswordProtected, pageId]);

    const checkIfPasswordWasEnteredForRestrictedPage = pageId => {
        if (!pageId) {
            return false;
        }

        try {
            const allowedRestrictedPages =
                JSON.parse(localStorage.getItem('allowedRestrictedPages')) || [];

            return allowedRestrictedPages.find(id => id === pageId);
        } catch (_) {
            // Parse failed
            return false;
        }
    };

    const updateUserProfile = async () => {
        if (userData.id) {
            return;
        }

        try {
            setLoading(true);
            const result = await getFullProfile();
            setUserData(result);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    if (loading) {
        return <Loader />;
    }

    if (!accessRestrictions && !showPasswordModal) {
        return result();
    }

    return (
        <AuthUserContext.Consumer>
            {authUser => {
                if (showPasswordModal && password) {
                    return (
                        <PasswordProtect
                            pageId={pageId}
                            password={password}
                            eventName={'Page is restricted'}
                            hideShowModal={() => {
                                setShowPasswordModal(false);
                            }}
                        />
                    );
                }

                if (!authUser && accessRestrictions) {
                    return (
                        <InteractivityContext.Consumer>
                            {({ showSignIn }) => <>{showSignIn()}</>}
                        </InteractivityContext.Consumer>
                    );
                } else {
                    updateUserProfile();
                }

                if (
                    accessRestrictions &&
                    !showPasswordModal &&
                    accessRestrictions !== 'eureka' &&
                    userData &&
                    userData.id &&
                    !userData.userGroups.find(group => `${group.id}` === `${accessRestrictions}`)
                ) {
                    return (
                        <Snackbar
                            toasts={toasts}
                            onDismiss={() => {
                                setToasts({});
                            }}
                            autohide={true}
                            autohideTimeout={4000}
                        />
                    );
                }

                return result();
            }}
        </AuthUserContext.Consumer>
    );
};

export default AccessRestrictions;
