import * as palette from '../../../components/General/Variables';
import facebookLogo from '../../../assets/social/icon-filled-facebook.png';
import linkedinLogo from '../../../assets/social/icon-filled-linkedin.png';
import twitterLogo from '../../../assets/social/icon-filled-twitter.png';
import React from 'react';
import { IconWrapper, SocialLink, LinkIcon, LinkLogo } from '../style';

const ContactLinks = ({ links }) =>
    links.map(
        link =>
            link.url && (
                <IconWrapper
                    href={
                        link.type === 'twitter' && link.url.charAt(0) === '@'
                            ? `https://twitter.com/${link.url.substring(1)}`
                            : link.url && link.url.startsWith('https://')
                            ? link.url
                            : `https://${link.url}`
                    }
                    key={link.url}
                    target={link.type === 'email' ? '_self' : '_blank'}
                >
                    {link.type === 'phone' && (
                        <SocialLink>
                            <LinkIcon color={'#00a86b'}>phone</LinkIcon>
                        </SocialLink>
                    )}
                    {link.type === 'email' && (
                        <SocialLink>
                            <LinkIcon>{palette.EMAIL_ICON}</LinkIcon>
                        </SocialLink>
                    )}
                    {link.type === 'facebook' && (
                        <SocialLink>
                            <LinkLogo src={facebookLogo} alt="Facebook" />
                        </SocialLink>
                    )}
                    {link.type === 'linkedin' && (
                        <SocialLink>
                            <LinkLogo src={linkedinLogo} alt="linkedin" />
                        </SocialLink>
                    )}
                    {link.type === 'twitter' && (
                        <SocialLink>
                            <LinkLogo src={twitterLogo} alt="twitter" />
                        </SocialLink>
                    )}
                    {link.type === 'web' && (
                        <SocialLink>
                            <LinkIcon>{palette.ICON_GLOBE}</LinkIcon>
                        </SocialLink>
                    )}
                    {link.type === 'website' && (
                        <SocialLink>
                            <LinkIcon>{palette.ICON_GLOBE}</LinkIcon>
                        </SocialLink>
                    )}
                </IconWrapper>
            ),
    );

export default ContactLinks;
