import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: ${props => props.height}px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

/**
 * Component that displays the background banners on detail page and
 * always keep the 16:9 ratio for it.
 */
const BackgroundBanner = ({ src }) => {
    const [height, setHeight] = useState(400);
    const [heightInitialized, setHeightInitialized] = useState(false);
    const imageRef = useRef();
    
    useEffect(() => {
        if (imageRef && imageRef.current && !heightInitialized) {
            const { offsetWidth: width } = imageRef.current;
            const newHeight = width / (16 / 9);

            setHeight(newHeight);
            setHeightInitialized(true);
        }
    }, [imageRef.current]);
    
    return (
        <ImageContainer
            innerRef={imageRef}
            imageUrl={src}
            height={height}
        >
            <Image src={src} alt="background" />
        </ImageContainer>
    );
};

export default BackgroundBanner;