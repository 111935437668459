import React from 'react';
import styled from 'styled-components';

const StartEndTimeWrapper = styled.div`
    color: ${props => props.customColor || 'rgba(30, 30, 31, 1)'};
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    white-space: nowrap;
    min-width: 100px;

    div:nth-of-type(1) {
        font-size: 13px;
        margin-bottom: 2px;
        white-space: normal;
    }

    div {
        width: 100%;
        text-align: center;
    }
`;

const StartTime = props => {
    if (props.customColor === 'transparent') {
        return null;
    }
    return (
        <StartEndTimeWrapper customColor={props.customColor}>
            {props.text && <div>{props.start}</div>}
            <div>{props.text}</div>
        </StartEndTimeWrapper>
    );
};

export default StartTime;
