import { useEffect } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

import {
    addNewMessage as addNewMessageAction,
    enrichBuddies as enrichBuddiesAction,
    updateEvent as updateEventAction,
    updateChatUrl as updateChatUrlAction,
    updateUser as updateUserAction,
    updateParticipantStatus as updateParticipantStatusAction,
    updateServerStatus as updateServerStatusAction,
    registerChatUser as registerChatUserAction,
    loadGroupConversations as loadGroupConversationsAction,
} from '../actions';
import { getPrivateConversationIds, getIsLoadingConversations } from '../selectors';
import Auth from '../../../services/api/auth';
import talkManager from '../services';
import eventBus from '../../../utils/eventBus';
import NotificationService from '../../Notifications/services/NotificationService';
import { notificationTypes } from '../../Notifications/constants';

const XMPPManagerContainer = ({
    eventId,
    isAuthenticated,
    addNewMessage,
    enrichBuddies,
    chatUrl,
    updateParticipantStatus,
    updateServerStatus,
    registerChatUser,
    updateEvent,
    updateChatUrl,
    updateUser,
    storeUserData,
    privateConversationIds,
    loadGroupConversations,
    loadingGroupConversations,
}) => {
    useEffect(() => {
        if (isAuthenticated) {
            const user = Auth.getUser();
            if (user) {
                updateUser(user);
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (storeUserData && storeUserData.email) {
            if (storeUserData.jid && storeUserData.jpassword) {
                connectToXMPP();
            } else {
                // User never participated on chat before
                registerChatUser();
            }
        }
    }, [storeUserData]);

    useEffect(() => {
        if (eventId) {
            updateEvent(eventId);
        }

        if (chatUrl) {
            updateChatUrl(chatUrl);
        }

        if (eventId && chatUrl && isAuthenticated) {
            loadGroupConversations();
        }
    }, [eventId, chatUrl]);

    useEffect(() => {
        if (loadingGroupConversations === false) {
            eventBus.emit('updateConversationsCount');
        }
    }, [loadingGroupConversations]);

    // Should only disconnect on Logout!
    // useEffect(() => {
    //     return () => {
    //         disconnectFromXMPP();
    //     };
    // }, []);

    const disconnectFromXMPP = () => {
        talkManager.disconnect();
    };

    const connectToXMPP = async () => {
        if (talkManager.connected) {
            return;
        }
        try {
            await talkManager.connect({
                chatUrl,
                userData: storeUserData,
                handleMessage,
                handleRoster,
                handleGroupMessage,
                handlePresence,
                handleServerStatus,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleAppointment = throttle(async data => {
        if (!data) {
            return;
        }

        NotificationService.handleNotification({
            notificationType: notificationTypes.APPOINTMENT,
            type: data.action,
            appointmentId: data.eurekaId,
        });
        eventBus.emit(`refreshAppointment_${data.eurekaId}`);
    }, 5000); // send a notification every 5 seconds so spam will be avoided

    const handleMessage = async data => {
        if (data) {
            try {
                const appointmentMessage = JSON.parse(data.message.txt);
                handleAppointment(appointmentMessage.data);
            } catch (_) {
                // Do nothing, the message is not an appointment
            }
            addNewMessage({ isPrivateMessage: true, data });
            const { jid, id } = data.conversation;
            if (privateConversationIds.indexOf(id) < 0) {
                enrichBuddies([jid]);
                await talkManager.addToRoster(jid);
                talkManager.subscribe(jid);
            }
        }
    };

    const handleGroupMessage = data => {
        addNewMessage({ isPrivateMessage: false, data });
    };

    const handleRoster = ({ buddiesJids }) => {
        if (buddiesJids) {
            enrichBuddies(buddiesJids);
            talkManager.getBlockList();
        }
    };

    const handlePresence = ({ participantId, online = false }) => {
        updateParticipantStatus({ participantId, online });
    };

    const handleServerStatus = ({ online = false, error }) => {
        updateServerStatus({ online, error });
    };

    return null;
};

const mapStateToProps = (state, ownProps) => {
    return {
        eventId: ownProps.eventId,
        userData: ownProps.userData,
        storeUserData: state.talk.user,
        privateConversationIds: getPrivateConversationIds(state),
        loadingGroupConversations: getIsLoadingConversations(state),
    };
};

const mapDispatchToProps = {
    addNewMessage: addNewMessageAction,
    enrichBuddies: enrichBuddiesAction,
    updateParticipantStatus: updateParticipantStatusAction,
    updateServerStatus: updateServerStatusAction,
    registerChatUser: registerChatUserAction,
    updateEvent: updateEventAction,
    updateChatUrl: updateChatUrlAction,
    updateUser: updateUserAction,
    loadGroupConversations: loadGroupConversationsAction,
};

const XMPPManager = connect(mapStateToProps, mapDispatchToProps)(XMPPManagerContainer);

export { XMPPManager };
