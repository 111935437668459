import React from 'react';

import AuthUserContext from './AuthUserContext';
import auth from '../../services/api/auth';
import AnalyticsService from '../../features/analytics/services';
import { getFullProfile } from '../../services/api/eureka';

const withAuthentication = Component =>
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                authUser: null,
            };
        }

        componentDidMount() {
            auth.subscribe(async authUser => {
                if (authUser) {
                    const userData = await getFullProfile();
                    const country =
                        userData.Country && userData.Country.name ? userData.Country.name : '';

                    const details = {
                        name: `${userData.firstName} ${userData.lastName}`,
                        country,
                        email: userData.email,
                    };
                    AnalyticsService.addSample('user', JSON.stringify(details), authUser.id);
                }
                authUser
                    ? this.setState(() => ({ authUser }))
                    : this.setState(() => ({ authUser: null }));
            });
        }

        render() {
            const { authUser } = this.state;
            return (
                <AuthUserContext.Provider value={authUser}>
                    <Component />
                </AuthUserContext.Provider>
            );
        }
    };

export default withAuthentication;
