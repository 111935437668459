import {useEffect, useState} from "react";
import { ResizeObserver } from '@juggle/resize-observer';
import throttle from "lodash/throttle";

const useDimensions = (ref) => {
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        if (ref.current) {
            const ro = new ResizeObserver(throttle((entries) => {
                if (!entries || entries.length === 0) {
                    return;
                }

                const entry = entries[0];
                const { width, height } = entry.contentRect;

                setDimensions({
                    width,
                    height,
                });
            }, 1000));

            ro.observe(ref.current);

            return () => {
                ro.unobserve(ref.current);
                ro.disconnect();
            }
        }
    }, [ref.current]);

    return dimensions;
};

export default useDimensions;