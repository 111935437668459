import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import ThemeContext from '../../../components/Theme/ThemeContext';
import Auth from '../../../services/api/auth';
import AppointmentService from '../services/AppointmentService';
import { withRouter } from 'react-router-dom';
import { deleteAppointment as deleteEurekaAppointment } from '../../Talk/actions';
import eventBus from '../../../utils/eventBus';
import { useDispatch } from 'react-redux';
import ShowMoreAppointmentsCard from '../components/ShowMoreAppointentsCard';
import CurrentAppointmentCard from '../components/Card';
import { TimezoneContext } from '../../Timezone/context';
import { default as Store } from '../../../services/api/store';

const AppointmentCard = ({ userId, setShowAppointmentModal, history, rootRoute }) => {
    const [appointments, setAppointments] = useState([]);
    const [showCancelMeetingModal, setShowCancelMeetingModal] = useState(null);
    const [showMoreAppointments, setShowMoreAppointments] = useState(false);
    const dispatch = useDispatch();
    const { timeslotToTimezone } = useContext(TimezoneContext);

    useEffect(() => {
        (async () => {
            await getAppointments();

            eventBus.on('refreshAppointments', getAppointments);
        })();
    }, [userId]);

    const getAppointments = async () => {
        const user = Auth.getUser();

        if (!user) {
            return;
        }

        const organizedAppointments = await AppointmentService.getAppointmentsByUserIdAndMemberId(
            user.id,
            userId,
        );
        const invitedAppointments = await AppointmentService.getAppointmentsByUserIdAndMemberId(
            userId,
            user.id,
        );
        const mergedAppointments = [...organizedAppointments, ...invitedAppointments];

        setAppointments(mergedAppointments.sort((ap1, ap2) => (ap1.start > ap2.start ? 1 : -1)));
    };

    const getDateAndTime = appointment => {
        const timezone = Store.argument.offsetTimezone;
        let start = moment.utc(appointment.start);
        if (!start.isValid()) {
            start = moment.unix(appointment.start);
        }
        let end = moment.utc(appointment.end);
        if (!end.isValid()) {
            end = moment.unix(appointment.end);
        }

        const timeslotWithTimezone = timeslotToTimezone({
            ...appointment,
            start: start.format(),
            end: end.format(),
        });

        let startTime = moment.utc(timeslotWithTimezone.start);
        if (!startTime.isValid()) {
            startTime = moment.unix(timeslotWithTimezone.start);
        }
        startTime = startTime.utcOffset(timezone, true);
        let endTime = moment.utc(timeslotWithTimezone.end);
        if (!endTime.isValid()) {
            endTime = moment.unix(timeslotWithTimezone.end);
        }
        endTime = endTime.utcOffset(timezone, true);
        const app = { ...timeslotWithTimezone, start: startTime, end: endTime };

        const date = moment(app.start).format('DD MMMM');
        const timeStart = moment(app.start).format('HH:mm');
        const timeEnd = moment(app.end).format('HH:mm');
        const dateTime = `${date} - ${timeStart} - ${timeEnd}`;
        return dateTime;
    };

    const onViewDetailsClick = id => {
        history.push(`${rootRoute}/appointment/${id}`);
    };

    const deleteAppointment = async appointment => {
        dispatch(deleteEurekaAppointment(appointment));
        setShowCancelMeetingModal(null);
        setShowMoreAppointments(false);
        eventBus.emit('updateMyProgrammeCount');
        eventBus.emit('refreshMyProgramme');
    };
    const appointmentToDisplay = appointments.length && appointments[0];

    if (!appointmentToDisplay) {
        return null;
    }

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    {!showMoreAppointments && (
                        <CurrentAppointmentCard
                            theme={theme}
                            appointmentToDisplay={appointmentToDisplay}
                            getDateAndTime={getDateAndTime}
                            cancelMeeting={() => setShowCancelMeetingModal(appointmentToDisplay.id)}
                            openDetails={() => onViewDetailsClick(appointmentToDisplay.id)}
                            showCancelMeetingModal={showCancelMeetingModal}
                            deleteAppointment={deleteAppointment}
                            setShowCancelMeetingModal={setShowCancelMeetingModal}
                        />
                    )}
                    {appointments && appointments.length > 1 && (
                        <ShowMoreAppointmentsCard
                            theme={theme}
                            appointments={appointments}
                            userId={userId}
                            setShowAppointmentModal={setShowAppointmentModal}
                            getDateAndTime={getDateAndTime}
                            cancelMeeting={setShowCancelMeetingModal}
                            openDetails={onViewDetailsClick}
                            showCancelMeetingModal={showCancelMeetingModal}
                            deleteAppointment={deleteAppointment}
                            setShowCancelMeetingModal={setShowCancelMeetingModal}
                            showMore={showMoreAppointments}
                            setShowMore={setShowMoreAppointments}
                        />
                    )}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(AppointmentCard);
