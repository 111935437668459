import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { DialogContainer, TextField, Toolbar } from 'react-md';
import * as palette from '../../components/General/Variables';
import { getString } from '../../services/api/store';
import ThemeContext from '../../components/Theme/ThemeContext';
import { getNote, saveNote } from './services/notes';
import * as eureka from '../../services/api/eureka';
import eventBus from '../../utils/eventBus';
import AnalyticsService from '../../features/analytics/services';
import Button, { buttonTypes } from '../../scenes/common/Button';
import { getLocalAppStateAsync } from '../../services/api/db';
import GamificationService from '../../scenes/Achievements/services/GamificationService';
import { AchievementType } from '../../scenes/Achievements/hooks/useAchievementActions';

const updateByPropertyName = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    visible: false,
    isNewNote: true,
    text: '',
};

const StyledForm = styled.form`
    min-height: 400px;
    margin-top: ${props => props.margin || '0px'};
    .md-divider--text-field::after {
        background: ${props => props.maincolor};
    }
    .md-text--theme-primary {
        color: ${props => props.maincolor};
    }
`;

const ADD_NOTE_TITLE = getString('noteAddTitle') || 'Add note';
const ADD_NOTE_DESCRIPTION =
    getString('notePlaceholder') || getString('createNote') || 'Add your note here';
const EDIT_NOTE_TITLE = getString('editNote') || 'Edit note';
const ADD_BUTTON = getString('noteAddButtonTitle') || 'Add';
const SAVE_BUTTON = getString('noteSaveButtonTitle') || 'Save';
const CANCEL_BUTTON = getString('noteCancelButtonTitle') || 'Cancel';

export default class NoteDialog extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.visible !== prevState.visible) {
            return {
                visible: nextProps.visible,
                item: nextProps.item,
            };
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item !== this.props.item) {
            getNote(this.props.item.id, (err, note) => {
                if (note && note.text && note.text.length) {
                    this.setState({
                        isNewNote: false,
                        text: note.text,
                    });
                } else {
                    this.setState({
                        isNewNote: true,
                        text: '',
                    });
                }
            });
        }
    }

    addOrEditNote = async () => {
        if (!this.state.item) {
            return;
        }
        AnalyticsService.addSample('addNote', null, this.state.item.id);
        const note = await saveNote(
            this.state.item,
            this.state.text,
            hasNote => {
                this.state.item.notifyUI(hasNote);
            },
            err => {
                eureka.sync(function () {
                    eventBus.emit('updateNotesCount');
                });
                this.hide();
            },
        );

        if (this.state.isNewNote) {
            const currentEvent = await getLocalAppStateAsync();
            GamificationService.trackAchievement(
                AchievementType.NOTE,
                currentEvent.id,
                this.state.item.id,
            );
        }

        return note;
    };

    show = () => {
        this.setState({ visible: true });
    };

    hide = () => {
        this.props.onClose();
        this.setState({ visible: false });
    };

    // Catches the keyboard enter event that triggers a sign in
    onKeyDown = e => {
        if (e.keyCode === 13) {
        }
    };

    render() {
        let TEXT_CANCEL_BUTTON = getString('noteCancelButtonTitle') || CANCEL_BUTTON;
        let TEXT_ADD_NOTE_DESCRIPTION = getString('notePlaceholder') || ADD_NOTE_DESCRIPTION;
        let TEXT_ADD_BUTTON = getString('noteAddButtonTitle') || ADD_BUTTON;
        let TEXT_SAVE_BUTTON = getString('noteSaveButtonTitle') || SAVE_BUTTON;
        let TEXT_NOTE_TITLE_ADD = getString('noteAddTitle') || ADD_NOTE_TITLE;
        let TEXT_NOTE_TITLE_EDIT = getString('noteEditTitle') || EDIT_NOTE_TITLE;

        const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
        const { visible, text, isNewNote } = this.state;

        const addActions = theme => {
            const actions = [];
            actions.push(
                <Button
                    onClick={this.hide}
                    type={buttonTypes.GREY}
                    text={TEXT_CANCEL_BUTTON}
                    style={{ marginRight: '8px' }}
                />,
            );
            actions.push(
                <Button
                    onClick={this.addOrEditNote}
                    type={buttonTypes.GREEN}
                    background={theme.primary}
                    text={isNewNote ? TEXT_ADD_BUTTON : TEXT_SAVE_BUTTON}
                />,
            );
            return actions;
        };

        const buildDialogContent = theme => {
            return (
                <React.Fragment>
                    <StyledForm maincolor={theme.primary} margin={isDesktop ? '0px' : '72px'}>
                        <TextField
                            id="note-editor"
                            value={text}
                            placeholder={TEXT_ADD_NOTE_DESCRIPTION}
                            onChange={text => this.setState(updateByPropertyName('text', text))}
                            label=""
                            rows={2}
                        />
                    </StyledForm>
                </React.Fragment>
            );
        };

        return (
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <React.Fragment>
                        {isDesktop && (
                            <DialogContainer
                                id="speed-boost"
                                visible={visible}
                                title={isNewNote ? TEXT_NOTE_TITLE_ADD : TEXT_NOTE_TITLE_EDIT}
                                onHide={this.hide}
                                width={2 * palette.DIALOG_WIDTH}
                                aria-describedby="speed-boost-description"
                                actions={addActions(theme)}
                                focusOnMount={false}
                                footerStyle={{ padding: '16px' }}
                                style={{ zIndex: '100 !important' }}
                            >
                                {buildDialogContent(theme)}
                            </DialogContainer>
                        )}
                        {!isDesktop && (
                            <DialogContainer
                                id="speed-boost"
                                visible={this.state.visible}
                                onHide={this.hide}
                                aria-describedby="speed-boost-description"
                                fullPage={true}
                                pageX={12}
                                pageY={12}
                                focusOnMount={false}
                                aria-labelledby="simple-full-page-dialog-title"
                            >
                                <div style={{ margin: '16px' }}>
                                    <Toolbar
                                        id="sign-in-toolbar"
                                        fixed
                                        style={{
                                            backgroundColor: theme.primary,
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        title={
                                            isNewNote ? TEXT_NOTE_TITLE_ADD : TEXT_NOTE_TITLE_EDIT
                                        }
                                        titleStyle={{ color: 'white' }}
                                        titleId="simple-full-page-dialog-title"
                                        nav={
                                            <Button
                                                onClick={this.hide}
                                                icon={'close'}
                                                background={theme.primary}
                                                type={buttonTypes.GREEN}
                                            />
                                        }
                                        actions={
                                            <Button
                                                onClick={this.addOrEditNote}
                                                type={buttonTypes.GREEN}
                                                background={theme.primary}
                                                text={
                                                    isNewNote ? TEXT_ADD_BUTTON : TEXT_SAVE_BUTTON
                                                }
                                            />
                                        }
                                    />
                                    {buildDialogContent(theme)}
                                </div>
                            </DialogContainer>
                        )}
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    }
}
