import async from 'async';

import chunk from 'lodash/chunk';
import each from 'lodash/each';
import filter from 'lodash/filter';
import get from 'lodash/get';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import moment from 'moment';
import { getAllNotes } from '../../components/Interactivity';
import AppointmentService from '../../scenes/Appointments/services/AppointmentService';
import {
    addLocalItem,
    findAllLocal,
    getLocalAppStateAsync,
    getLocalItem,
    getObjectClassWithId,
} from './db';

import { batchGet, executeListQuery, getItem } from './graphQlRepository';
import { getString } from './store';
import Auth from './auth';
import ReminderService from '../../scenes/Reminders/ReminderService';

function mapObjectClass(objectClass) {
    if (objectClass && objectClass.slice(-1) === 's') {
        return objectClass;
    }
    // database form is plural, are there any cases besides appending s?
    return objectClass + 's';
}

var formatDate = function (item) {
    let dateSettings = getString('datetime');
    let locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';
    let datePattern =
        dateSettings && dateSettings.longDateFormat
            ? dateSettings.longDateFormat
            : 'dddd, MMMM Do YYYY';
    let timeFormat = dateSettings && dateSettings.timeFormat ? dateSettings.timeFormat : 'HH:mm';
    let day = moment.utc(item.start);
    if (!day.isValid()) {
        day = moment.unix(item.start);
    }
    day.locale(locale);
    var start = moment(item.start).utc();
    if (!start.isValid()) {
        start = moment.unix(item.start);
    }
    var end = moment(item.end).utc();
    if (!end.isValid()) {
        end = moment.unix(item.end);
    }
    return `${day.format(datePattern)}, ${start.format(timeFormat)} - ${end.format(
        timeFormat,
    )}`.toUpperCase();
};

const getDateWithLocale = item => {
    const dateSettings = getString('datetime');
    const locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';
    let day = moment.utc(item);
    if (!day.isValid()) {
        day = moment.unix(item);
    }
    day.locale(locale);
    return day;
};

const getFormatDate = () => {
    const dateSettings = getString('datetime');
    const datePattern =
        dateSettings && dateSettings.longDateFormat
            ? dateSettings.longDateFormat
            : 'dddd, MMMM Do YYYY';
    return datePattern;
};

const getDayMonthDateFormat = () => {
    const dateSettings = getString('datetime');
    return dateSettings && dateSettings.dayMonthDateFormat
        ? dateSettings.dayMonthDateFormat
        : 'MMMM D';
};

const getFormatTime = () => {
    const dateSettings = getString('datetime');
    const timeFormat = dateSettings && dateSettings.timeFormat ? dateSettings.timeFormat : 'HH:mm';
    return timeFormat;
};

const formatTime = function (timestamp) {
    const dateSettings = getString('datetime');
    const locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';
    const timeFormat = dateSettings && dateSettings.timeFormat ? dateSettings.timeFormat : 'HH:mm';
    const time = moment(parseInt(timestamp, 10)).locale(locale).format(timeFormat);
    return time;
};

const formatTimeMessageSection = function (timestamp) {
    const dateSettings = getString('datetime');
    const locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';

    const date = moment.unix(timestamp / 1000);
    if (moment().isSame(date, 'day')) {
        let TODAY_STRING = getString('talkTodayTitle', '').toLowerCase() || 'Today';
        return `${TODAY_STRING[0].toUpperCase()}${TODAY_STRING.substring(1).toLowerCase()}`;
    }
    const yesterday = moment().subtract(1, 'day');
    if (yesterday.isSame(date, 'day')) {
        let YESTERDAY_STRING = getString('talkYesterdayTitle', '').toLowerCase() || 'Yesterday';
        return `${YESTERDAY_STRING[0].toUpperCase()}${YESTERDAY_STRING.substring(1).toLowerCase()}`;
    }
    const sameWeek = date.isBetween(moment().subtract(6, 'day'), moment());
    if (sameWeek) {
        return moment(date).locale(locale).format('dddd');
    }
    return moment(date).locale(locale).format(get(dateSettings, 'dayMonthDateFormat'));
};

const formatLastMessageDate = timestamp => {
    const dateSettings = getString('datetime');
    const locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';

    const date = moment.unix(timestamp / 1000000);
    if (moment().isSame(date, 'day')) {
        return moment(date).locale(locale).fromNow();
    }
    const yesterday = moment().subtract(1, 'day');
    if (yesterday.isSame(date, 'day')) {
        let YESTERDAY_STRING = getString('talkYesterdayTitle', '').toLowerCase() || 'Yesterday';
        return `${YESTERDAY_STRING[0].toUpperCase()}${YESTERDAY_STRING.substring(1).toLowerCase()}`;
    }
    const sameWeek = date.isBetween(moment().subtract(6, 'day'), moment());
    if (sameWeek) {
        return moment(date).locale(locale).format('dddd');
    }
    return moment(date).locale(locale).format(get(dateSettings, 'dayMonthDateFormat'));
};

const formatStringWithValueSubstring = (givenString, substr) => {
    let subString = substr;
    if (substr === 'timeslot') {
        subString = 'session';
    }
    return givenString.replace(
        givenString.substring(givenString.indexOf('__'), givenString.length),
        subString,
    );
};

function populateRoles(item, section, next) {
    getItem('types', section.query.roleTypeId, (err, sectionType) => {
        const roles = filter(item.roles, function (role) {
            return role.type === section.query.roleTypeId;
        });
        let rolesObj = {
            type: 'person',
            items: [],
        };
        async.eachSeries(
            roles,
            function (role, proceed) {
                const input = {
                    data: [
                        {
                            target: 'persons',
                            ids: [role.actor],
                        },
                        {
                            target: 'institutions',
                            ids: [role.actor],
                        },
                    ],
                };

                batchGet(input)
                    .then(result => {
                        const { persons, institutions } = result;
                        if (persons && persons.length) {
                            const [person] = persons;
                            const rolePerson = {
                                id: person.id,
                                name: person.name,
                                subNameDetail: person.subNameDetail,
                                subNameList: person.subNameList,
                                image: person.image,
                                imageUrl: person.imageUrl,
                                type: 'person',
                                ordering: role.ordering,
                                eurekaId: person.eurekaId,
                                email: person.email,
                            };
                            rolesObj.items.push(rolePerson);
                            proceed();
                        } else if (institutions && institutions.length) {
                            const [institution] = institutions;
                            const roleInstitution = {
                                id: institution.id,
                                name: institution.name,
                                subNameDetail: institution.subNameDetail,
                                subNameList: institution.subNameList,
                                image: institution.image,
                                imageUrl: institution.imageUrl,
                                type: 'institution',
                                ordering: role.ordering,
                            };
                            rolesObj.items.push(roleInstitution);
                            proceed();
                        } else {
                            proceed();
                        }
                    })
                    .catch(() => {
                        proceed();
                    });
            },
            function (err) {
                rolesObj.items = sortByRoleOrdering(rolesObj.items);
                rolesObj.title =
                    rolesObj.items.length && rolesObj.items.length > 1
                        ? sectionType.plural
                        : sectionType?.singular;
                rolesObj.type = rolesObj.items.length ? rolesObj.items[0].type : 'person';
                next(null, rolesObj);
            },
        );
    });
}

function populateRolesOf(item, section, next) {
    getItem('types', section.query.roleTypeId, async (err, sectionType) => {
        const rolesOf = filter(item.rolesOf, function (role) {
            return role.type === section.query.roleTypeId;
        });

        const rolesObjTimeslots = {
            type: 'timeslot',
            items: [],
        };

        const rolesObjProgramelements = {
            type: 'programelement',
            items: [],
        };

        const timeslotIds = [];
        const programelementIds = [];

        rolesOf.forEach(roleOf => {
            if (roleOf.category === 'Timeslot') {
                timeslotIds.push(roleOf.object);
            } else if (roleOf.category === 'Programelement') {
                programelementIds.push(roleOf.object);
            }
        });

        const input = {
            data: [],
        };
        if (timeslotIds.length) {
            input.data.push({
                target: 'timeslots',
                ids: timeslotIds,
            });
        }
        if (programelementIds.length) {
            input.data.push({
                target: 'programelements',
                ids: programelementIds,
            });
        }

        const { timeslots, programelements } = await batchGet(input);
        async.eachSeries(
            rolesOf,
            function (roleOf, proceed) {
                if (roleOf.category === 'Timeslot') {
                    const timeslot = find(timeslots, ts => {
                        return ts.id === roleOf.object;
                    });

                    if (timeslot) {
                        let relatedTimeslot = {
                            name: timeslot.name,
                            subNameDetail: timeslot.subNameDetail,
                            subNameList: timeslot.subNameList,
                            time: formatDate(timeslot),
                            params: timeslot.params,
                            id: timeslot.id,
                            start: timeslot.start,
                            end: timeslot.end,
                            orderingName: timeslot.orderingName,
                        };

                        if (timeslot.locations && timeslot.locations.length) {
                            getItem('places', timeslot.locations[0]._id, (err, place) => {
                                if (place) {
                                    relatedTimeslot.room = place.name;
                                }
                                rolesObjTimeslots.items.push(relatedTimeslot);
                                proceed();
                            });
                        } else {
                            rolesObjTimeslots.items.push(relatedTimeslot);
                            proceed();
                        }
                    } else {
                        proceed();
                    }
                } else if (roleOf.category === 'Programelement') {
                    const programelement = find(programelements, ts => {
                        return ts.id === roleOf.object;
                    });
                    let relatedProgramelement = {
                        name: programelement.name,
                        subNameDetail: programelement.subNameDetail,
                        subNameList: programelement.subNameList,
                        params: programelement.params,
                        id: programelement.id,
                        orderingName: programelement.orderingName,
                    };

                    if (programelement.locations && programelement.locations.length) {
                        getItem('places', programelement.locations[0]._id, (err, place) => {
                            if (place) {
                                relatedProgramelement.room = place.name;
                            }
                            rolesObjProgramelements.items.push(relatedProgramelement);
                            proceed();
                        });
                    } else {
                        rolesObjProgramelements.items.push(relatedProgramelement);
                        proceed();
                    }
                }
            },
            function (err) {
                rolesObjTimeslots.items = sortByStartTime(rolesObjTimeslots.items);
                rolesObjTimeslots.title = sectionType
                    ? sectionType.reverse || sectionType.singular + ' of'
                    : '';
                rolesObjProgramelements.items = sortByNameAndOrdering(
                    rolesObjProgramelements.items,
                );
                rolesObjProgramelements.title = sectionType
                    ? sectionType.reverse || sectionType.singular + ' of'
                    : '';
                next(null, rolesObjTimeslots, rolesObjProgramelements);
            },
        );
    });
}

async function populatePlaces(item, section, sectionType, next) {
    const ids = item?.locations?.map(location => {
        return location._id;
    });
    const locationsObj = {
        type: 'place',
        items: [],
    };

    if (!(ids && ids.length)) {
        return next(null, null);
    }

    const input = {
        data: [
            {
                target: 'places',
                ids,
            },
        ],
    };
    const { places } = await batchGet(input);
    places &&
        places.forEach(place => {
            if (place.type && place.type === sectionType.id) {
                locationsObj.items.push({
                    name: place.name,
                    id: place.id,
                    floorplan: place.floorplan,
                });
            }
        });
    locationsObj.title =
        locationsObj.items.length && locationsObj.items.length > 1
            ? sectionType.plural
            : sectionType.singular;
    next(null, locationsObj);
}

async function populateClassifiers(item, section, sectionType, next) {
    if (!(item && item.classifications && Array.isArray(item.classifications))) {
        return next(null, null);
    }

    const ids = item.classifications.map(classification => {
        return classification._id;
    });

    var classifiersObj = {
        type: 'classifier',
        items: [],
    };

    if (!(ids && ids.length)) {
        return next(null, null);
    }

    const input = {
        data: [
            {
                target: 'classifiers',
                ids,
            },
        ],
    };
    const { classifiers } = await batchGet(input);
    classifiers &&
        classifiers.forEach(classifierItem => {
            if (classifierItem.type === sectionType.id) {
                const classifierById = item?.classifications?.find(
                    i => i._id === classifierItem.id,
                );
                classifiersObj.items.push({
                    name: classifierItem.name,
                    orderingName: classifierItem.orderingName || classifierById?.ordering,
                    params: classifierItem.params,
                    id: classifierItem.id,
                });
            }
        });
    classifiersObj.title =
        classifiersObj.items.length && classifiersObj.items.length > 1
            ? sectionType.plural
            : sectionType.singular;
    next(null, classifiersObj);
}

async function getObjectLinks(item, next) {
    const ids = item.links.map(link => {
        return link._id;
    });

    if (!(ids && ids.length)) {
        return next(null, null);
    }

    const input = {
        data: [
            {
                target: 'links',
                ids,
            },
        ],
    };
    const { links } = await batchGet(input);
    next(null, links);
}

function populateLinks(item, section, sectionType, next) {
    const linksObj = {
        type: 'link',
        items: [],
    };

    getObjectLinks(item, (err, links) => {
        if (links && links.length) {
            links.forEach(linkItem => {
                if (linkItem.type === sectionType.id) {
                    linksObj.items.push({
                        ...linkItem,
                    });
                }
            });

            linksObj.title =
                linksObj.items.length && linksObj.items.length > 1
                    ? sectionType.plural
                    : sectionType.singular;
            next(null, linksObj);
        } else {
            next(null, linksObj);
        }
    });
}

async function populateInstitutions(item, section, sectionType, next) {
    const ids = item.affiliations
        ? item.affiliations.map(institution => {
              return institution._id;
          })
        : [];

    var institutionsObj = {
        type: 'institution',
        items: [],
    };

    if (!(ids && ids.length)) {
        return next(null, null);
    }

    const input = {
        data: [
            {
                target: 'institutions',
                ids,
            },
        ],
    };
    const { institutions } = await batchGet(input);

    institutions.forEach(institutionItem => {
        if (institutionItem.type === sectionType.id) {
            institutionsObj.items.push({
                name: institutionItem.name,
                id: institutionItem.id,
            });
        }
    });
    institutionsObj.title =
        institutionsObj.items.length && institutionsObj.items.length > 1
            ? sectionType.plural
            : sectionType.singular;
    next(null, institutionsObj);
}

function populateRelated(item, section, next) {
    getItem('types', section.query.typeId, (err, sectionType) => {
        if (sectionType && sectionType.target === 'Place') {
            populatePlaces(item, section, sectionType, next);
        } else if (sectionType && sectionType.target === 'Classifier') {
            populateClassifiers(item, section, sectionType, next);
        } else if (sectionType && sectionType.target === 'Link') {
            populateLinks(item, section, sectionType, next);
        } else if (sectionType && sectionType.target === 'Institution') {
            populateInstitutions(item, section, sectionType, next);
        } else {
            next();
        }
    });
}

function compareNameAndOrdering(a, b) {
    if (a.orderingName && b.orderingName) {
        var titleA = a.orderingName.toLowerCase(),
            titleB = b.orderingName.toLowerCase();
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
    } else {
        var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }
}

function sortByStartTime(collection) {
    if (collection && collection.length) {
        return collection.sort(function (a, b) {
            if (a.start && b.start) {
                var dateA = new Date(a.start),
                    dateB = new Date(b.start);

                if (dateA.getTime() !== dateB.getTime()) {
                    return dateA - dateB;
                } else {
                    return compareNameAndOrdering(a, b);
                }
            } else {
                return compareNameAndOrdering(a, b);
            }
        });
    } else {
        return collection;
    }
}

function sortByNameAndOrdering(collection) {
    return orderBy(collection, ['orderingName', 'name'], ['asc', 'asc']);
}

function sortByRoleOrdering(collection) {
    return orderBy(collection, ['ordering', 'name'], ['asc', 'asc']);
}

async function populateClassifications(item, section, sectionType, next) {
    if (!(item.relatedOf && item.relatedOf.length)) {
        return next(null, null);
    }

    const ids = item.relatedOf.map(ro => {
        return ro.object;
    });

    var classifiedObj = {
        type: sectionType.target.toLowerCase(),
        items: [],
    };

    const target = `${sectionType.target.toLowerCase()}s`;
    const chunkIds = chunk(ids, 100);
    const result = [];

    for (const chunk of chunkIds) {
        const input = {
            data: [
                {
                    target,
                    ids: chunk,
                },
            ],
        };

        const queryResult = await batchGet(input);
        result.push(...queryResult[target]);
    }

    async.eachSeries(
        result,
        function (object, proceed) {
            if (object && object.type === sectionType.id) {
                let relatedObject = {
                    name: object.name,
                    subNameDetail: object.subNameDetail,
                    subNameList: object.subNameList,
                    time: formatDate(object),
                    params: object.params,
                    id: object.id,
                    start: object.start,
                    end: object.end,
                    orderingName: object.orderingName,
                    image: object.image,
                    imageUrl: object.imageUrl,
                };

                if (object.locations && object.locations.length) {
                    getItem('places', object.locations[0]._id, (err, place) => {
                        if (place) {
                            relatedObject.room = place.name;
                        }
                        classifiedObj.items.push(relatedObject);
                        proceed();
                    });
                } else {
                    classifiedObj.items.push(relatedObject);
                    proceed();
                }
            } else {
                proceed();
            }
        },
        function (err) {
            classifiedObj.items = sortByStartTime(classifiedObj.items);
            classifiedObj.title =
                classifiedObj.items.length && classifiedObj.items.length > 1
                    ? sectionType.plural
                    : sectionType.singular;
            next(null, classifiedObj);
        },
    );
}

function populateRelatedOf(item, section, next) {
    getItem('types', section.query.typeId, (err, sectionType) => {
        if (sectionType) {
            populateClassifications(item, section, sectionType, next);
        } else {
            next();
        }
    });
}

function populateParent(item, parentId, next) {
    var parentObj = {
        type: 'timeslot',
        title: 'daddy',
        items: [],
    };

    if (!parentId) {
        return next(null, parentObj);
    }

    const processParent = (parent, isProgramelement) => {
        if (!parent) {
            return next(null, null);
        }

        if (!isProgramelement) {
            parent.time = formatDate(parent);
        }
        parentObj.items.push(parent);

        getItem('types', parent.type, (err, parentType) => {
            parentObj.title =
                parentObj.items.length && parentObj.items.length > 1
                    ? parentType.plural
                    : parentType.singular;

            if (parent.locations && parent.locations.length) {
                getItem('places', parent.locations[0]._id, (err, place) => {
                    if (place) {
                        parentObj.items[0].room = place.name;
                    }
                    next(null, parentObj);
                });
            } else {
                next(null, parentObj);
            }
        });
    };

    getItem('timeslots', parentId, (err, parent) => {
        if (!parent) {
            parentObj.type = 'programelement';
            //Parent can be a Programelement(poster)
            getItem('programelements', parentId, (err, parent) => {
                processParent(parent, true);
            });
        } else {
            processParent(parent);
        }
    });
}

function populateChildren(section, parent, advance) {
    getItem('types', section.query.typeId, async (err, sectionType) => {
        if (!sectionType) {
            return advance(null, null);
        }
        const objectClass = sectionType.target; //Timeslot
        let children = {
            type: sectionType.target.toLowerCase(),
            title: sectionType.pluralitems,
            items: [],
        };

        let childrenItems;
        if (objectClass === 'Programelement') {
            const queryFunctionName = 'findProgramelements';
            childrenItems = await executeListQuery(queryFunctionName, {
                parent: {
                    eq: parent,
                },
                type: {
                    eq: sectionType.id,
                },
            });
        } else {
            childrenItems = await executeListQuery('findTimeslots', {
                parent: {
                    eq: parent,
                },
                type: {
                    eq: sectionType.id,
                },
            });
        }

        if (!childrenItems.length) {
            return advance(err, children);
        }

        children.items = map(childrenItems, function (child) {
            return {
                name: child.name,
                orderingName: child.orderingName,
                subNameDetail: child.subNameDetail,
                subNameList: child.subNameList,
                locations: child.locations,
                time: formatDate(child),
                start: child.start,
                end: child.end,
                params: child.params,
                id: child.id,
                parent: child.parent,
            };
        });

        children.items = sortByStartTime(children.items);

        children.title =
            children.items.length && children.items.length > 1
                ? sectionType.plural
                : sectionType.singular;

        async.eachSeries(
            children.items,
            function (item, next) {
                let locations;
                if (item.locations && isString(item.locations)) {
                    locations = JSON.parse(item.locations);
                } else {
                    locations = item.locations;
                }

                if (locations && Array.isArray(locations) && !isEmpty(locations)) {
                    getItem('places', locations[0]._id, (err, place) => {
                        if (place) {
                            item.room = place.name;
                        }
                        next();
                    });
                } else {
                    next();
                }
            },
            function (err) {
                advance(err, children);
            },
        );
    });
}

function formatInteractiveObj(item) {
    var id = item.query.feature,
        name = id.charAt(0).toUpperCase() + id.slice(1);

    return {
        id: id,
        name: name,
        filled: true,
    };
}

export const populateSections = function (item, type, next) {
    let sections = [],
        interactivity = [];
    async.eachSeries(
        type.sections,
        function (section, proceed) {
            switch (section.kind) {
                case 'children':
                    populateChildren(section, item.id, function (err, childSection) {
                        if (childSection && childSection.items && childSection.items.length) {
                            if (section.title) {
                                childSection.title = section.title;
                            }
                            sections.push(childSection);
                        }
                        proceed();
                    });
                    break;
                case 'roles':
                    populateRoles(item, section, function (err, rolesSection) {
                        if (rolesSection.items && rolesSection.items.length) {
                            if (section.title) {
                                rolesSection.title = section.title;
                            }
                            sections.push(rolesSection);
                        }
                        proceed();
                    });
                    break;
                case 'rolesOf':
                    populateRolesOf(item, section, function (
                        err,
                        resultTimeslots,
                        resultProgramelements,
                    ) {
                        if (resultTimeslots.items && resultTimeslots.items.length) {
                            if (section.title) {
                                resultTimeslots.title = section.title;
                            }
                            sections.push(resultTimeslots);
                        }
                        if (resultProgramelements.items && resultProgramelements.items.length) {
                            if (section.title) {
                                resultProgramelements.title = section.title;
                            }
                            sections.push(resultProgramelements);
                        }
                        proceed();
                    });
                    break;
                case 'related':
                    populateRelated(item, section, function (err, relatedSection) {
                        if (relatedSection && relatedSection.items && relatedSection.items.length) {
                            if (section.title) {
                                relatedSection.title = section.title;
                            }
                            sections.push(relatedSection);
                        }
                        proceed();
                    });
                    break;
                case 'relatedOf':
                    populateRelatedOf(item, section, function (err, relatedOfSection) {
                        if (
                            relatedOfSection &&
                            relatedOfSection.items &&
                            relatedOfSection.items.length
                        ) {
                            if (section.title) {
                                relatedOfSection.title = section.title;
                            }
                            sections.push(relatedOfSection);
                        }
                        proceed();
                    });
                    break;
                case 'parent':
                    populateParent(item, item.parent, function (err, parentSection) {
                        if (parentSection && parentSection.items && parentSection.items.length) {
                            if (section.title) {
                                parentSection.title = section.title;
                            }
                            sections.push(parentSection);
                        }
                        proceed();
                    });
                    break;
                case 'details':
                    item.info = {
                        title: section.title,
                        text: item.info,
                    };
                    proceed();
                    break;
                case 'interactive':
                    interactivity.push(formatInteractiveObj(section));
                    proceed();
                    break;
                case 'evaluations':
                    interactivity.push(section);
                    proceed();
                    break;
                default:
                    proceed();
            }
        },
        function (err) {
            next(null, sections, interactivity);
        },
    );
};

async function attachImages(item, type, callback) {
    const ids = [];
    const imageUrl = item.imageUrl || null;
    if (item.image && !imageUrl) {
        ids.push(item.image);
    }
    if (item.params && item.params.backgroundImage) {
        ids.push(item.params.backgroundImage);
    }
    if (type.params && type.params.defaultBackgroundImage) {
        ids.push(type.params.defaultBackgroundImage);
    }
    if (!ids.length) {
        if (imageUrl) {
            item.picture = imageUrl;
        }
        callback(null, item, type);
    } else {
        const input = {
            data: [
                {
                    target: 'images',
                    ids,
                },
            ],
        };
        const { images } = await batchGet(input);
        if (!item.image) {
            item.picture = imageUrl || null;
            item.backgroundImage = images && images[0] ? images[0].imageUrl : null;
        } else if (imageUrl) {
            item.picture = imageUrl;
            item.backgroundImage = images && images[0] ? images[0].imageUrl : null;
        } else {
            item.picture = images && images[0] ? images[0].imageUrl : null;
            item.backgroundImage = images && images[1] ? images[1].imageUrl : null;
        }
        callback(null, item, type);
    }
}

function extendSectionData(category, key, item, cb) {
    async.waterfall(
        [
            function (next) {
                let obj = {
                    id: key,
                    category: category.slice(0, -1),
                    name: item.name,
                    type: item.type,
                };

                if (item.orderingName) {
                    obj.orderingName = item.orderingName;
                }

                if (item.start && item.end) {
                    obj.start = item.start;
                    obj.end = item.end;
                    obj.time = formatDate(item);
                }

                if (item.subNameList) {
                    obj.subNameList = item.subNameList;
                }

                if (item.image) {
                    obj.image = item.image;
                }

                if (item.params) {
                    obj.params = item.params;
                }

                next(null, obj);
            },
            function (obj, next) {
                if (item.locations && item.locations.length) {
                    getItem('places', item.locations[0]._id, function (err, value) {
                        obj.room = value.name;
                        next(null, obj);
                    });
                } else {
                    next(null, obj);
                }
            },
            function (obj, next) {
                if (item.links && item.links.length) {
                    const [link] = item.links;
                    getObjectLinks({ links: [link] }, (err, links) => {
                        const [firstLink] = links;
                        obj.url = firstLink.url;
                    });
                    next(null, obj);
                } else {
                    next(null, obj);
                }
            },
        ],
        function (err, obj) {
            cb(err, obj);
        },
    );
}

async function makeItem(id, objectClass, next) {
    if (objectClass === 'appointment') {
        const item = await AppointmentService.getAppointmentById(id);
        item.typeName = 'Appointment';
        item.type = 'appointment';
        return next(null, item);
    }

    async.waterfall([
        function (callback) {
            // Find item
            getItem(mapObjectClass(objectClass), id, (err, item) => {
                if (err || !item) {
                    next(err ? err : 'object not found');
                } else {
                    callback(null, item);
                }
            });
        },
        function (item, callback) {
            // Check favorite
            isFavorite(item, (err, item) => {
                if (err || !item) {
                    next(err ? err : 'error with favorite');
                } else {
                    callback(null, item);
                }
            });
        },
        function (item, callback) {
            // Check checkin
            isCheckin(item, (err, item) => {
                if (err || !item) {
                    next(err ? err : 'error with checkin');
                } else {
                    callback(null, item);
                }
            });
        },
        function (item, callback) {
            getItem('types', item.type, (err, type) => {
                if (err || !type) {
                    next(err ? err : 'type not found');
                } else {
                    callback(null, item, type);
                }
            });
        }, // Attach images (if any)
        function (item, type, callback) {
            attachImages(item, type, callback);
        }, // Attach representatives (if any)
        function (item, type, callback) {
            if (
                type &&
                type.target &&
                type.target === 'Institution' &&
                item.params &&
                item.params.representatives &&
                item.params.representatives.length
            ) {
                item.representatives = item.params.representatives;
            }

            callback(null, item, type);
        }, // Populate sections
        function (item, type, callback) {
            populateSections(
                item,
                type,
                function (err, sections, interactivity) {
                    callback(err, sections, interactivity, item, type);
                },
                objectClass,
            );
        },
        function (sections, interactivity, item, type, callback) {
            item.sections = sections;
            item.interactivity = interactivity;
            item.type = objectClass;
            item.typeName = type.singular;
            item.title = item.name;
            item.typeParams = {};
            if (type && type.params) {
                item.typeParams = type.params;
            }

            if (item.start) {
                item.time = formatDate(item);
            }

            next(null, item);
        },
    ]);
}

const makeItemAsync = async (id, objectClass) => {
    return await new Promise((resolve, reject) => {
        makeItem(id, objectClass, (err, item) => {
            if (err) {
                reject(err);
            } else {
                resolve(item);
            }
        });
    });
};

function filterProgram(filter, data, favorites, marked) {
    if (filter && data && data.length) {
        let result = [];

        // for each group, iterate its items
        each(data, function (locationItem) {
            let newItem = {};
            newItem.group = locationItem.group;
            newItem.items = [];

            // for each program item of a group
            each(locationItem.items, function (item) {
                let textMatch =
                        (filter.text &&
                            item.name.toLowerCase().includes(filter.text.toLowerCase())) ||
                        !filter.text ||
                        filter.text.length < 1,
                    filterMatch = false,
                    filterFavorite =
                        !favorites ||
                        find(favorites, function (f) {
                            return f.id === item.id && f.action === 'Add';
                        });
                if (!filterFavorite && marked && marked.length) {
                    filterFavorite = find(marked, function (m) {
                        return m === item.id;
                    });
                }

                if (!isEmpty(filter.filters)) {
                    // iterate the filter groups, and add if the filter is found and the text filter matches (or is missing)
                    each(values(filter.filters), function (filterGroup) {
                        // for each filter (classifier), check if it exist in the item classifications
                        each(filterGroup, function (filter) {
                            if (
                                filterFavorite &&
                                textMatch &&
                                !filterMatch &&
                                filter &&
                                find(item.classifications, function (i) {
                                    return i._id === filter.id;
                                })
                            ) {
                                newItem.items.push(item);
                                filterMatch = true;
                            }
                        });
                    });
                } else if (textMatch && filterFavorite) {
                    newItem.items.push(item);
                }
            });
            result.push(newItem);
        });

        return result;
    } else {
        return data;
    }
}

function getFilterTypes(id, callback) {
    let mappedFilters = [];
    getItem('pages', id, (err, page) => {
        if (page && page.params && page.params.webappfilters) {
            async.eachSeries(
                page.params.webappfilters,
                function (filter, next) {
                    if (!filter) {
                        return next();
                    }

                    getItem('types', filter, function (err, type) {
                        if (type && type.singular) {
                            mappedFilters.push({
                                name: type.singular,
                                type: filter,
                            });
                        }
                        next(err);
                    });
                },
                function (err) {
                    callback(err, mappedFilters);
                },
            );
        } else {
            callback();
        }
    });
}

function getGroupingOptions(id, callback) {
    getItem('pages', id, (err, page) => {
        if (page && page.params && page.params.groupingOption) {
            callback(null, {
                groupingOption: page.params.groupingOption,
                groupingValue: page.params.groupingValue,
            });
        } else {
            callback(null, {});
        }
    });
}

/* Favorites */
let favoriteListeners = {};

/* Ratings */
let ratingListeners = {};

function getMarkedAsFavorites(data, next) {
    let result = [];
    async.eachSeries(
        data,
        function (location, proceed) {
            async.eachSeries(
                location.items,
                function (item, cb) {
                    hasFavoritedChildren(item.id, hasFav => {
                        if (hasFav) {
                            result.push(item.id);
                        }
                        cb();
                    });
                },
                proceed,
            );
        },
        function (err) {
            next(err, result);
        },
    );
}

function hasFavoritedChildren(parent, after) {
    let result = false;

    findAllLocal(
        'favorites',
        item => {
            return item.markParent === parent;
        },
        (err, childrenItems) => {
            if (childrenItems && childrenItems.length) {
                result = true;
            }
            after(result);
        },
    );
}

function addRating(item, callback) {
    getLocalItem('ratings', item.objectId, function (err, i) {
        if (err) {
            callback(err);
        } else {
            let deleted = 0;
            if (item.rate === 0) {
                deleted = 1;
            }
            item.action = 'Add';
            item.deleted = deleted;
            getItem('timeslots', item.objectId, function (err, timeslot) {
                addLocalItem('ratings', item.objectId, item, (err, newItem) => {
                    if (err) {
                        callback(err);
                    } else {
                        if (ratingListeners[item.objectId]) {
                            ratingListeners[item.objectId].action(true);
                        }
                        callback();
                    }
                });
            });
        }
    });
}

function addFavorite(item, callback) {
    getLocalItem('favorites', item.id, function (err, fav) {
        if (err) {
            callback(err);
        } else {
            const date = new Date();
            const timestamp = date.toISOString();
            const lastUpdate = date.getTime();
            let favorite = {
                id: item.id,
                objectId: item.id,
                objectClass: item.type,
                typeName: item.typeName,
                start: item.start,
                end: item.end,
                title: item.name,
                action: 'Add',
                timestamp: timestamp,
                lastUpdate: lastUpdate,
            };
            getItem('timeslots', item.id, function (err, timeslot) {
                if (timeslot && timeslot.parent) {
                    favorite.markParent = timeslot.parent;
                }
                addLocalItem('favorites', favorite.id, favorite, (err, newfav) => {
                    if (err) {
                        callback(err);
                    } else {
                        if (favoriteListeners[item.id]) {
                            favoriteListeners[item.id].action(true);
                        } else if (item.parent && favoriteListeners[item.parent]) {
                            favoriteListeners[item.parent].action('isChild');
                        }

                        ReminderService.addItemToScheduledList(newfav, { isFavorite: true });
                        callback();
                    }
                });
            });
        }
    });
}

function removeFavorite(item, callback) {
    getLocalItem('favorites', item.id, function (err, favorite) {
        if (err) {
            callback(err);
        } else if (favorite) {
            const date = new Date();
            const timestamp = date.toISOString();
            const lastUpdate = date.getTime();
            favorite.action = 'Delete';
            favorite.markParent = undefined;
            favorite.timestamp = timestamp;
            favorite.lastUpdate = lastUpdate;
            addLocalItem('favorites', favorite.id, favorite, (err, newfav) => {
                if (err) {
                    callback(err);
                } else {
                    if (favoriteListeners[item.id]) {
                        favoriteListeners[item.id].action(false);
                    }
                    if (item.parent && favoriteListeners[item.parent]) {
                        favoriteListeners[item.parent].action('isChild');
                    }
                    callback();
                }
            });
        } else {
            callback('no favorite found to remove');
        }
    });
}

function isFavorite(item, callback) {
    getLocalItem('favorites', item.id, function (err, fav) {
        if (fav && fav.action === 'Add') {
            item.favorite = true;
            callback(null, item);
        } else {
            item.favorite = false;
            callback(null, item);
        }
    });
}

function isFavoriteById(itemId, callback) {
    getLocalItem('favorites', itemId, function (err, fav) {
        if (fav && fav.action === 'Add') {
            callback(null, true);
        } else {
            callback(null, false);
        }
    });
}

const addCheckin = item => {
    const date = new Date();
    const timestamp = date.toISOString();
    const lastUpdate = date.getTime();

    const checkin = {
        id: item.id,
        objectId: item.id,
        objectClass: item.type,
        typeName: item.typeName,
        start: item.start,
        end: item.end,
        title: item.name,
        action: 'Add',
        timestamp: timestamp,
        lastUpdate: lastUpdate,
    };

    return new Promise(resolve => {
        addLocalItem('checkins', checkin.id, checkin, (err, newCheckin) => {
            if (err) {
                console.log('Add checkin error:', err);
                resolve(null);
            } else {
                resolve(newCheckin);
            }
        });
    });
};

const removeCheckin = item => {
    return new Promise(resolve => {
        getLocalItem('checkins', item.id, (err, checkin) => {
            if (err) {
                console.log('Remove checkin error:', err);
                resolve(null);
            } else if (!checkin) {
                console.log('Remove checkin error: item not found');
            } else {
                const date = new Date();
                const timestamp = date.toISOString();
                const lastUpdate = date.getTime();

                checkin.action = 'Delete';
                checkin.timestamp = timestamp;
                checkin.lastUpdate = lastUpdate;

                addLocalItem('checkins', checkin.id, checkin, (err, newCheckin) => {
                    if (err) {
                        console.log('Save removed checkin error:', err);
                        resolve(null);
                    } else {
                        resolve(newCheckin);
                    }
                });
            }
        });
    });
};

const isCheckin = (item, callback) => {
    getLocalItem('checkins', item.id, function (err, checkin) {
        if (checkin && checkin.action === 'Add') {
            item.isCheckin = true;
            callback(null, item);
        } else {
            item.isCheckin = false;
            callback(null, item);
        }
    });
};

const isThereAtLeastOneTypeWithCheckinFeatureEnabled = async () => {
    const { eventId } = await getLocalAppStateAsync();
    const types = await executeListQuery(
        'listTypes',
        {
            event: {
                eq: eventId,
            },
        },
        false,
        true,
    );

    return (
        types.findIndex(type => {
            return (type.sections || []).some(
                section => section?.kind === 'interactive' && section.query?.feature === 'checkin',
            );
        }) > -1
    );
};

const getMyCheckins = () => {
    return new Promise(resolve => {
        findAllLocal(
            'checkins',
            item => item.action !== 'Delete',
            (err, result) => {
                if (err) {
                    resolve([]);
                } else {
                    resolve(result);
                }
            },
        );
    });
};

const getMyCheckinsCount = () => {
    return new Promise(async resolve => {
        const results = await getMyCheckins();
        resolve(results.length);
    });
};

function hasNote(itemId, callback) {
    getLocalItem('notes', itemId, function (err, note) {
        if (note && note.action === 'Add' && note.text) {
            callback(null, true);
        } else {
            callback(null, false);
        }
    });
}

function saveAnalytic(item, next) {
    getLocalItem('analytics', `${item.objectId}_${item.timestamp}`, function (err) {
        if (err) {
            next(err);
        } else {
            const argumentsObj = {
                ownerId: item.event,
                value: item.object,
            };

            const analytic = {
                arguments: JSON.stringify(argumentsObj),
                object: item.objectId,
                name: item.name,
                event: item.event,
                timestamp: item.timestamp,
                installation: item.installation,
            };

            addLocalItem('analytics', `${item.objectId}_${item.timestamp}`, analytic, err => {
                next(err);
            });
        }
    });
}

function saveNote(object, text, notifyUI, next) {
    let hasNote = false;
    getLocalItem('notes', object.id, function (err, note) {
        if (err) {
            next(err);
        } else {
            const date = new Date();
            const timestamp = date.toISOString();
            const lastUpdate = Math.round(date.getTime() / 1000);
            if (text && text.length > 0) {
                let title = object.title || object.name;
                if (!title && note && note.title) {
                    title = note.title;
                }
                if (!(note && note.objectClass) && !object.objectClass) {
                    //Expensive function of finding the objectClass.Only will be used when a note coming from another device and only for the first time.
                    getObjectClassWithId(object.id, (err, objectClass) => {
                        if (objectClass && objectClass.length) {
                            const nnote = {
                                objectId: object.id,
                                text: text,
                                title: title,
                                objectClass: objectClass,
                                action: 'Add',
                                timestamp: timestamp,
                                lastUpdate: lastUpdate,
                            };
                            addLocalItem('notes', object.id, nnote, err => {
                                hasNote = true;
                                notifyUI(hasNote);
                                next(err);
                            });
                        } else {
                            next(null);
                        }
                    });
                } else {
                    let objectClassValue = object.objectClass;
                    if (!object.objectClass && note && note.objectClass) {
                        objectClassValue = note.objectClass;
                    }
                    const nnote = {
                        objectId: object.id,
                        text: text,
                        title: title,
                        objectClass: objectClassValue,
                        action: 'Add',
                        timestamp: timestamp,
                        lastUpdate: lastUpdate,
                    };
                    addLocalItem('notes', object.id, nnote, err => {
                        hasNote = true;
                        notifyUI(hasNote);
                        next(err);
                    });
                }
            } else {
                if (note) {
                    note.action = 'Delete';
                    note.text = '';
                    note.timestamp = timestamp;
                    note.lastUpdate = lastUpdate;
                    addLocalItem('notes', object.id, note, err => {
                        hasNote = false;
                        notifyUI(hasNote);
                        next(err);
                    });
                } else {
                    next(null);
                }
            }
        }
    });
}

function getAllFavorites(callback) {
    findAllLocal(
        'favorites',
        item => {
            return item.action !== 'Delete';
        },
        callback,
    );
}

function getAllProgramFavorites(callback) {
    findAllLocal(
        'favorites',
        item => {
            return item.action !== 'Delete';
        },
        callback,
    );
}

function getAllProgramFavoritesAsync() {
    return new Promise((resolve, reject) => {
        getAllProgramFavorites((err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}

function getItemAsync(type, id) {
    return new Promise((resolve, reject) => {
        getItem(type, id, (err, item) => {
            if (err) {
                reject(err);
            }
            resolve(item);
        });
    });
}

function getObjectClassWithIdAsync(id) {
    return new Promise((resolve, reject) => {
        getObjectClassWithId(id, (err, objectClass, objectItem) => {
            if (err) {
                reject(err);
            }

            resolve({
                objectClass,
                objectItem,
            });
        });
    });
}

function getMyProgramCount() {
    const user = Auth.getUser();
    return new Promise(resolve => {
        let counter = 0;

        getAllProgramFavorites(async (err, favorites) => {
            let programItems = await Promise.all(
                favorites.map(async fav => {
                    return await getItemAsync('timeslots', fav.id);
                }),
            );

            programItems = programItems.filter(item => item);
            counter += programItems.length;

            if (user && user.id) {
                const appointments = await AppointmentService.getAllAppointments(user.id);
                counter += appointments.length;
            }

            resolve(counter);
        });
    });
}

function getMyFavoritesCount() {
    return new Promise(resolve => {
        getAllProgramFavorites(async (err, favorites) => {
            let favoriteItems = await Promise.all(
                favorites.map(async fav => {
                    try {
                        const { objectItem } = await getObjectClassWithIdAsync(fav.id);
                        const item = await getItemAsync('types', objectItem.type);

                        return item.target !== 'Timeslot' ? item : null;
                    } catch (e) {
                        return null;
                    }
                }),
            ).catch(() => {
                return [];
            });

            // Remove null values
            favoriteItems = favoriteItems.filter(item => item);
            resolve(favoriteItems.length);
        });
    });
}

function getNotesCount() {
    return new Promise((resolve, reject) => {
        getAllNotes((err, notes) => {
            if (err) {
                reject(err);
            }

            resolve(notes.length);
        });
    });
}

function filterByFavorites(data, callback) {
    if (data && data.length) {
        getAllFavorites((err, favorites) => {
            // index favorites by "objectId"
            const lookup = keyBy(favorites, o => {
                return o.objectId;
            });
            const filteredArray = filter(data, u => {
                return lookup[u.id] !== undefined;
            });
            callback(null, filteredArray);
        });
    } else {
        callback('no data to filter', null);
    }
}

function getClassifierWithRelated(key, next) {
    getItem('classifiers', key, async function (err, classifier) {
        if (classifier) {
            if (!classifier.relatedItems) {
                classifier.relatedItems = [];
            }

            if (!classifier.relatedOf || !classifier.relatedOf.length) {
                next(null, classifier);
            } else {
                classifier.relatedItems = [];

                const input = {
                    data: [],
                };

                const categories = groupBy(classifier.relatedOf, relatedObject => {
                    return relatedObject.category;
                });

                Object.keys(categories).forEach(category => {
                    if (categories[category] && categories[category].length > 0) {
                        input.data.push({
                            target: `${category.toLowerCase()}s`,
                            ids: categories[category].map(relatedObject => relatedObject.object),
                        });
                    }
                });

                const response = await batchGet(input);
                let allItems = [];
                Object.keys(response).forEach(category => {
                    if (response[category] && response[category].length > 0) {
                        const items = response[category].map(item => {
                            return {
                                ...item,
                                category,
                            };
                        });
                        allItems = allItems.concat(items);
                    }
                });

                async.eachSeries(
                    allItems,
                    function (item, cb) {
                        const obj = {
                            ...item,
                            category: item.category.slice(0, -1),
                            start: item.start,
                            end: item.end,
                            time: formatDate(item),
                            image: item.image,
                        };
                        if (item.locations && item.locations.length) {
                            getItem('places', item.locations[0]._id, function (err, value) {
                                obj.room = value.name;
                                classifier.relatedItems.push(obj);
                                cb();
                            });
                        } else {
                            classifier.relatedItems.push(obj);
                            cb();
                        }
                    },
                    function (err) {
                        next(null, classifier);
                    },
                );
            }
        } else if (err) {
            next(err);
        } else {
            next(null, null);
        }
    });
}

export {
    filterProgram,
    sortByNameAndOrdering,
    sortByStartTime,
    makeItem,
    makeItemAsync,
    formatDate,
    formatTime,
    formatTimeMessageSection,
    formatLastMessageDate,
    favoriteListeners,
    isFavoriteById,
    hasFavoritedChildren,
    getMarkedAsFavorites,
    addFavorite,
    removeFavorite,
    getAllFavorites,
    getAllProgramFavorites,
    getAllProgramFavoritesAsync,
    filterByFavorites,
    hasNote,
    saveNote,
    getFilterTypes,
    getClassifierWithRelated,
    getMyFavoritesCount,
    getMyProgramCount,
    getNotesCount,
    getGroupingOptions,
    saveAnalytic,
    addRating,
    formatStringWithValueSubstring,
    getFormatDate,
    getDayMonthDateFormat,
    getFormatTime,
    getDateWithLocale,
    getItemAsync,
    addCheckin,
    removeCheckin,
    getMyCheckins,
    getMyCheckinsCount,
    isThereAtLeastOneTypeWithCheckinFeatureEnabled,
};
