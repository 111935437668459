import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
    hideProfileModal as hideProfileModalAction,
    hideSettingsModal as hideSettingsModalAction,
    hideSignOutDialog as hideSignOutDialogAction,
} from '../actions';
import ProfileModal from './profile/ProfileModal';
import SettingsModal from './SettingsModal';
import SignOutDialog from '../../../components/Dialog/SignOutDialog';
import Auth from '../../../services/api/auth';
import AuthenticatedUserButton from '../components/AuthenticatedUserButton';
import UnauthenticatedUserButton from '../components/UnauthenticatedUserButton';
import Notifications from '../../Notifications/containers/Notifications';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { useGlobalState } from '../../../utils/container';

const Container = styled.div`
    display: flex;
    float: right;
`;

const AuthButton = props => {
    const isAuthenticated = Auth.isUserAuthenticated();
    const stateCtx = useGlobalState();
    const [eventId, setEventId] = useState(null);
    const [releaseNum, setReleaseNum] = useState(null);
    const { socket } = stateCtx;

    useEffect(() => {
        (async () => {
            const appState = await getLocalAppStateAsync();
            setEventId(appState.eventId);
            setReleaseNum(appState.releaseNum);
        })();
    }, []);

    useEffect(() => {
        if (socket && eventId && releaseNum) {
            socket.on(`new_data_release_${eventId}`, data => update(data, releaseNum));
        }
        return () => {
            if (socket && eventId && releaseNum) {
                socket.off(`new_data_release_${eventId}`, data => update(data, releaseNum));
            }
        };
    }, [socket, eventId, releaseNum]);

    const update = (data, releaseNum) => {
        if (data?.releaseNum > releaseNum && props.onServiceWorkerUpdate) {
            props.onServiceWorkerUpdate();
        }
    };

    return (
        <React.Fragment>
            {props.showProfileModal && <ProfileModal onClose={props.hideProfileModal} />}
            {props.showSettingsModal && <SettingsModal onClose={props.hideSettingsModal} />}
            {props.showSignOutDialog && (
                <SignOutDialog hideConfirmDialog={props.hideSignOutDialog} />
            )}
            {isAuthenticated && (
                <Container>
                    <Notifications light={props.light} />
                    <AuthenticatedUserButton
                        light={props.light}
                        newVersionAvailable={props.newVersionAvailable}
                        updateServiceWorker={props.updateServiceWorker}
                        waitingWorker={props.waitingWorker}
                    />
                </Container>
            )}
            {!isAuthenticated && (
                <UnauthenticatedUserButton
                    light={props.light}
                    newVersionAvailable={props.newVersionAvailable}
                    updateServiceWorker={props.updateServiceWorker}
                    waitingWorker={props.waitingWorker}
                />
            )}
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        showProfileModal: state.auth.showProfileModal,
        showSettingsModal: state.auth.showSettingsModal,
        showSignOutDialog: state.auth.showSignOutDialog,
    };
};

export default connect(mapStateToProps, {
    hideProfileModal: hideProfileModalAction,
    hideSettingsModal: hideSettingsModalAction,
    hideSignOutDialog: hideSignOutDialogAction,
})(AuthButton);
