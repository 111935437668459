import React, { useEffect, useRef, useState } from 'react';

import throttle from 'lodash/throttle';
import get from 'lodash/get';

import NavigationBar from '../../../components/DetailHeader/NavigationBar.js';
import Auth from '../../../services/api/auth';
import { getFullProfileById } from '../../../services/api/eureka';
import {
    getFriendship,
    sendFriendRequest,
    cancelFriendRequest,
    acceptFriendRequest,
} from '../service';
import Loader from '../../../components/General/Loader';

import AppointmentModal from '../../Appointments/containers/AppointmentModal';
import { getTimeslot } from '../../VirtualSession/services/session';
import { UserChatCard } from '../../Talk/containers/UserChatCard';
import { useGlobalState } from '../../../utils/container';
import { getLocalAppStateAsync } from '../../../services/api/db';
import { withRouter } from 'react-router-dom';
import ThemeContext from '../../../components/Theme/ThemeContext';
import { getString } from '../../../services/api/store';
import ContactButtons from '../components/ContactButtons';
import NotContactButtons from '../components/NotContactButtons';
import FriendRequestSentButtons from '../components/FriendRequestSentButtons';
import ProfileInformationContact from '../components/ProfileInformationContact';
import { PadddingLeftRight16px } from '../../common/Padding';
import ContactLinks from '../components/ContactLinks';
import ProfileHeader from '../components/ProfileHeader';
import AppointmentCard from '../../Appointments/containers/AppointmentCard';
import eventBus from '../../../utils/eventBus';
import {
    Scrollable,
    Container,
    RectangleContainer,
    SectionTitle,
    GeneralProfileDetails,
    UserProfileWrapper,
    Item,
    PersonContainer,
} from '../style';

import * as palette from '../../../components/General/Variables';
import { executeQuery } from '../../../services/api/graphQlRepository';
import { MarginTop16px } from '../../common/Margin';
import Button, { buttonTypes } from '../../common/Button';
import { Avatar } from 'react-md';
import AcceptDeclineFriendShipButtons from '../components/AcceptDeclineFriendshipButtons';
import * as constants from '../../User/constants';
import ProfileModal from '../../User/containers/profile/ProfileModal';
import { makeItem } from '../../../services/api/data';
import Section from '../../DetailPage/Section';
import {
    useAchievementActions,
    AchievementType,
} from '../../Achievements/hooks/useAchievementActions';
import ReactHtmlParser from 'react-html-parser';
import { SectionDescription } from '../../DetailPage/InfoBar';

const UserProfile = props => {
    const [profileData, setProfileData] = useState(null);
    const [isContact, setIsContact] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [timeslot, setTimeslot] = useState(null);
    const [readAll, setReadAll] = useState(false);
    const [appState, setAppState] = useState(null);
    const [friendshipStatus, setFriendshipStatus] = useState(-1);
    const [actionUserId, setActionUserId] = useState(null);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [item, setItem] = useState({ params: {}, sections: [] });
    const { trackAchievement } = useAchievementActions();

    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const authUser = Auth.getUser();
    const userId = +get(props, 'match.params.userId', null);
    const isOnExhibitorBoothPage = props.location.pathname.includes('/exhibitorbooth');

    const navBar = useRef();
    const header = useRef();
    let scroll = 0;
    let scrollDirection = 'UP';
    const TEXT_REPRESENTATIVE_ROLE = getString('representativeRole') || 'Representative of';
    const EDIT_PROFILE_TITLE =
        getString('profileEditMyProfileScreenTitle') || constants.EDIT_PROFILE;

    const _handleScroll = throttle(() => {
        let newScroll = document.getElementById('scrollable').scrollTop;

        if (scroll > newScroll && scrollDirection !== 'UP' && newScroll > 48) {
            if (navBar && navBar.current && header && header.current) {
                navBar.current.className = 'fixed-navbar';
                header.current.className = 'fixed-navbar-header';
            }
            scrollDirection = 'UP';
        } else if (scroll <= newScroll && scrollDirection !== 'DOWN' && newScroll > 48) {
            if (navBar && navBar.current && header && header.current) {
                navBar.current.className = 'auto-navbar';
                header.current.className = 'auto-navbar-header';
            }
            scrollDirection = 'DOWN';
        }

        scroll = newScroll;
    }, 100);

    useEffect(() => {
        if (!Auth.isUserAuthenticated()) {
            props.history.push(props.rootRoute);
        }
    }, []);

    useEffect(() => {
        (async () => {
            const appState = await getLocalAppStateAsync();
            setAppState(appState);
            await fetchProfileData(appState);

            if (socket) {
                socket.on(`refreshUserSettingsInEvent_${appState.eventId}_${userId}`, () =>
                    fetchProfileData(appState),
                );
            }
        })();
    }, [userId]);

    useEffect(() => {
        trackAchievement(AchievementType.VIEW_USER_PROFILE, userId);
    }, [userId]);

    useEffect(() => {
        const objectId = props.match.params.personLinkedId;

        if (objectId) {
            makeItem(objectId, 'person', (err, item) => {
                if (err) {
                    console.log(err);
                } else {
                    if (item && item.id) {
                        setItem(item);
                    }
                }
            });
        }
    }, [props.match.params.personLinkedId]);

    useEffect(() => {
        const refreshFriendship = () => fetchProfileData(appState);

        eventBus.on(`refreshFriendshipStatus_${userId}`, refreshFriendship);

        return () =>
            eventBus.removeListener(`refreshFriendshipStatus_${userId}`, refreshFriendship);
    }, []);

    const getElements = async (eventId, userData) => {
        const representativeOfList = [];
        let institutions = await executeQuery('findInstitutions', {
            event: eventId,
        });
        if (institutions && institutions.length > 0) {
            institutions.map(institution => {
                const representatives = get(institution, 'params.representatives', []);

                if (representatives && representatives.length > 0) {
                    const userIsRepresentativeOfCurrentInstitution = representatives.find(
                        item => item.userId === userData.id,
                    );

                    if (userIsRepresentativeOfCurrentInstitution) {
                        representativeOfList.push(institution);
                    }
                }
            });
        }

        return representativeOfList;
    };

    const fetchProfileData = async appState => {
        try {
            const userData = await getFullProfileById(userId);
            const friendship = await getFriendship(userId);
            const representativeOf = await getElements(appState && appState.eventId, userData);

            const links =
                userData &&
                userData.Links.reduce(function (a, b) {
                    if (a.every(item => item.type !== b.type)) {
                        a.push(b);
                    }
                    return a;
                }, []);

            const contactInitiatorUserId = friendship.status === 0 ? friendship.action_user_id : null;
            if (friendship && friendship.status >= 0) {
                setFriendshipStatus(friendship.status);
                setActionUserId(contactInitiatorUserId);
            } else {
                setFriendshipStatus(-1);
            }

            if (authUser.id !== contactInitiatorUserId && friendship.status === 0) {
                trackAchievement(AchievementType.RECEIVE_CONTACT_REQUEST, contactInitiatorUserId);
            }

            if (friendship && friendship.status === 1) {
                addPhoneAndEmailToLinks(userData, links);
            }

            setIsContact(friendship && friendship.status === 1);
            setProfileData({...userData, Links: links, representativeOf});
        } catch (err) {
            if (err.message === 'Not Found') {
                const { personFallbackUrl, prevPath } = props.location.state || {};
                props.history.replace(personFallbackUrl || prevPath || props.rootRoute);
            }
        }
    };

    const addPhoneAndEmailToLinks = (userData, links) => {
        if (userData && userData.Contact && userData.Contact.email) {
            links.unshift({
                type: 'email',
                url: `mailto:${userData.Contact.email}`,
            });
        }
        if (userData && userData.Contact && userData.Contact.phone) {
            links.unshift({
                type: 'phone',
                url: `tel:${userData.Contact.phone}`,
            });
        }
    };

    useEffect(() => {
        const routerStateTimeslot = get(props, 'location.state.timeslot', null);
        const { timeslotId } = props.match.params;

        if (routerStateTimeslot && (routerStateTimeslot.id === timeslotId || !timeslotId)) {
            setTimeslot(routerStateTimeslot);
        } else {
            getTimeslot(timeslotId, (err, timeslot) => {
                if (err || !timeslot) {
                    return;
                }

                setTimeslot(timeslot);
            });
        }
    }, [props.match.params.timeslotId]);

    const matchUrl = props.prefix ? props.prefix : props.rootRoute;

    const onAppointmentModalClose = () => {
        setShowAppointmentModal(false);
        eventBus.emit('refreshAppointments');
    };

    const onClose = () => props.history.push(props.rootRoute);

    if (!profileData) {
        return <Loader />;
    }

    const longText = profileData && profileData.headline;
    const showMore = longText && longText.length > 180;
    const shortText = longText && longText.substring(0, 180);
    let text = shortText;

    if (showMore) {
        text += '...';
    }
    if (readAll) {
        text = longText;
    }

    const onCreateConnection = async () => {
        if (appState) {
            const data = {
                to: userId,
                event: appState.eventId, // this is how is set in legacy
                push: {
                    configuratorUrl: appState.configuratorUrl,
                    message:
                        getString('notificationContactRequest') || 'sent you a contact request.',
                },
            };
            await sendFriendRequest(data);
            await fetchProfileData(appState);
            eventBus.emit('refreshRepresentativesList');

            trackAchievement(AchievementType.SEND_CONTACT_REQUEST, userId);
        }
    };

    const onCancelRequest = async () => {
        const data = {
            from: userId,
            event: appState.eventId,
        };
        await cancelFriendRequest(data);
        await fetchProfileData(appState);
        eventBus.emit('refreshRepresentativesList');
    };

    const onAcceptFriendRequest = async () => {
        const data = {
            from: userId,
            event: appState.eventId,
        };
        await acceptFriendRequest(data);
        await fetchProfileData(appState);
    };

    const renderRepresentativeOfList = () =>
        profileData.representativeOf.map((item, index) => (
            <Item
                key={`representative-${index}`}
                primaryText={item.name}
                leftAvatar={item.imageUrl && <Avatar src={item.imageUrl} />}
                onClick={() =>
                    props.history.push({
                        pathname: `/exhibitorbooth/${item.id}`,
                        state: props.rootRoute,
                    })
                }
            />
        ));

    const openChat = conversation => {
        if (isOnExhibitorBoothPage) {
            /*
                If user profile detail page is opened on Exhibitor Booth page, that private chat will be opened in 3rd
                column on Exhibitor Booth, and not in a new detail page
             */

            eventBus.emit('openExhibitorBoothChat', { userId });
            onClose();
            return;
        }
        if (profileData && props.history) {
            const convData =
                conversation && conversation.id
                    ? conversation
                    : {
                          id: profileData.id,
                          jid: profileData.jid,
                          title:
                              profileData.displayName ||
                              `${profileData.firstName} ${profileData.lastName}`,
                          participant: profileData,
                      };
            if (props.history) {
                props.history.push(`${matchUrl}/privateChat/${profileData.id}`, {
                    conversation: convData,
                });
            }
        }
    };

    const handleProfileModal = () => {
        setShowProfileModal(!showProfileModal);
    };

    const renderSections = item => {
        if (!item || !item.sections || !item.sections.length) {
            return;
        }

        return item.sections.map(sec => {
            const type = sec.type.replace(' ', '');
            const title = sec.title.replace(' ', '');
            const key = `${item.id}_${type}_${title}`;

            return (
                <Section
                    key={key}
                    icon={sec.icon}
                    type={sec.type}
                    title={sec.title}
                    items={sec.items}
                    clickOpenDetail={item => props.clickOpenDetail(item)}
                    matchUrl={matchUrl}
                />
            );
        });
    };

    return (
        <React.Fragment>
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <UserProfileWrapper>
                        <Scrollable id="scrollable" onScroll={_handleScroll}>
                            <div ref={navBar}>
                                <NavigationBar
                                    scrollDirection={scrollDirection}
                                    goBack={props.goBack}
                                    onClose={onClose}
                                    typeName={getString('profileFriendTitle') || 'Profile'}
                                    closeDataQa={`close-user-profile-${profileData.id}`}
                                    background={palette.COLOR_WHITE}
                                />
                            </div>
                            <Container>
                                <ProfileHeader
                                    profileData={profileData}
                                    header={header}
                                    friendshipStatus={friendshipStatus}
                                    onCancelRequest={onCancelRequest}
                                />
                                {profileData && profileData.Links.length > 0 && (
                                  <RectangleContainer>
                                      <ContactLinks links={profileData.Links} />
                                  </RectangleContainer>
                                )}
                                {showProfileModal && <ProfileModal onClose={handleProfileModal} />}
                                <GeneralProfileDetails>
                                    {authUser.id === profileData.id ? (
                                        <PadddingLeftRight16px>
                                            <Button
                                                type={buttonTypes.GREEN_LONG}
                                                background={theme.primary}
                                                text={EDIT_PROFILE_TITLE}
                                                onClick={handleProfileModal}
                                                icon={palette.ICON_PEN_EDIT}
                                            />
                                        </PadddingLeftRight16px>
                                    ) : (
                                        <>
                                            {friendshipStatus === 0 &&
                                                authUser.id === actionUserId && (
                                                    <FriendRequestSentButtons
                                                        theme={theme}
                                                        openChat={openChat}
                                                        profileData={profileData}
                                                        onCancelRequest={onCancelRequest}
                                                        scheduleMeeting={() =>
                                                            setShowAppointmentModal(true)
                                                        }
                                                    />
                                                )}
                                            {friendshipStatus === 0 &&
                                                authUser.id !== actionUserId && (
                                                    <AcceptDeclineFriendShipButtons
                                                        theme={theme}
                                                        onCancelRequest={onCancelRequest}
                                                        onAcceptFriendRequest={
                                                            onAcceptFriendRequest
                                                        }
                                                    />
                                                )}
                                            {friendshipStatus === 1 &&
                                                profileData &&
                                                profileData.chatAvailable && (
                                                    <ContactButtons
                                                        theme={theme}
                                                        openChat={openChat}
                                                        profileData={profileData}
                                                        scheduleMeeting={() =>
                                                            setShowAppointmentModal(true)
                                                        }
                                                    />
                                                )}
                                            {friendshipStatus === -1 && (
                                                <NotContactButtons
                                                    theme={theme}
                                                    openChat={openChat}
                                                    profileData={profileData}
                                                    onCreateConnection={onCreateConnection}
                                                    scheduleMeeting={() =>
                                                        setShowAppointmentModal(true)
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                    {profileData && profileData.chatAvailable && (
                                        <>
                                            {authUser.id !== userId && (
                                                <UserChatCard
                                                    user={profileData}
                                                    matchUrl={matchUrl}
                                                    useNewCard={true}
                                                />
                                            )}
                                            {authUser.id !== userId && (
                                                <AppointmentCard
                                                    userId={userId}
                                                    setShowAppointmentModal={
                                                        setShowAppointmentModal
                                                    }
                                                    id={profileData.id}
                                                    rootRoute={props.rootRoute}
                                                />
                                            )}
                                        </>
                                    )}
                                    {isContact || authUser.id === profileData.id ? (
                                        <ProfileInformationContact
                                            theme={theme}
                                            profileData={profileData}
                                            showMore={showMore}
                                            readAll={readAll}
                                            setReadAll={setReadAll}
                                            text={text}
                                            renderRepresentativeOfList={renderRepresentativeOfList}
                                        />
                                    ) : (
                                        <PadddingLeftRight16px>
                                            {profileData &&
                                                profileData.representativeOf &&
                                                profileData.representativeOf.length > 0 && (
                                                    <>
                                                        <SectionTitle>
                                                            {TEXT_REPRESENTATIVE_ROLE}
                                                        </SectionTitle>
                                                        {renderRepresentativeOfList()}
                                                    </>
                                                )}
                                            {profileData && profileData.headline && (
                                                <>
                                                    <SectionTitle>Biography</SectionTitle>
                                                    <SectionDescription
                                                        primary={theme.primary}
                                                        contrast={theme.contrast}
                                                    >
                                                        {ReactHtmlParser(text)}
                                                    </SectionDescription>
                                                    <MarginTop16px />
                                                    {showMore && (
                                                        <Button
                                                            type={buttonTypes.GREY_LONG}
                                                            background={theme.primary}
                                                            text={
                                                                !readAll
                                                                    ? getString(
                                                                          'announcementsReadMore',
                                                                      ) || 'Read More'
                                                                    : getString(
                                                                          'announcementsReadLess',
                                                                      ) || 'Read less'
                                                            }
                                                            onClick={() => setReadAll(!readAll)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </PadddingLeftRight16px>
                                    )}
                                    <PersonContainer key={`${item.id}_sections`}>
                                        {renderSections(item)}
                                    </PersonContainer>
                                </GeneralProfileDetails>
                            </Container>
                        </Scrollable>
                    </UserProfileWrapper>
                )}
            </ThemeContext.Consumer>
            {showAppointmentModal && (
                <AppointmentModal participant={profileData} onClose={onAppointmentModalClose} />
            )}
        </React.Fragment>
    );
};

export default withRouter(UserProfile);
