import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import styled from 'styled-components';

const titleStyles = theme => ({
    root: {
        margin: 0,
        paddingTop: 20,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 20,
    },
    closeButton: {
        position: 'absolute',
        right: 12,
        top: 12,
        color: theme.palette.grey[700],
    },
});

// To Do: use Material UI Typography with theme
const StyledTitle = styled.p`
    font-size: 21px;
    color: black;
    font-weight: bold;
    font-family: Cabin, sans-serif;
    line-height: 24px;
    margin: 0 24px 0 0 !important;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 100%;
    margin: 16px 0;
`;

const CenteredTitle = styled(StyledTitle)`
    text-align: center;
    padding: 0 !important;
    margin: 0 !important;
`;

const DialogTitle = ({ children, classes, onClose, centered }) =>
    centered ? (
        <Wrapper>
            <CenteredTitle>{children}</CenteredTitle>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </Wrapper>
    ) : (
        <MuiDialogTitle className={classes.root}>
            <StyledTitle>{children}</StyledTitle>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );

export default withStyles(titleStyles)(DialogTitle);
