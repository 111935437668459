import { useGlobalMutation, useGlobalState } from '../../../utils/container';
import React, { useEffect } from 'react';
import Auth from '../../../services/api/auth';
import NotificationService from '../services/NotificationService';
import { notificationTypes } from '../constants';
import * as AppStyle from '../../../Navigation/style/navigationStyle';
import get from 'lodash/get';

const NotificationWrapper = ({ children, isAuthenticated, theme, location }) => {
    const mutationCtx = useGlobalMutation();
    const stateCtx = useGlobalState();
    const { showAlreadyInTheSessionModal, showCapacityModal } = stateCtx;

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        const { socket } = stateCtx;

        if (!socket) {
            return;
        }

        const user = Auth.getUser();

        if (user) {
            const virtualEvent =
                location.pathname.indexOf('/virtual-session') > -1 ||
                location.pathname.indexOf('/virtual-session-room') > -1 ||
                location.pathname.indexOf('/video') > -1;

            socket.on(`messageNotification_${user.id}`, data => {
                if (!virtualEvent) {
                    NotificationService.handleNotification({
                        notificationType: notificationTypes.CHAT,
                        userId: data.senderId,
                        type: data.type,
                        roomId: data.roomId,
                    });
                }
            });
        }

        return () => {
            if (user && socket) {
                socket.removeAllListeners(`messageNotification_${user.id}`);
            }
        };
    }, [isAuthenticated, stateCtx.socket, location]);

    return (
        <React.Fragment>
            <AppStyle.DialogContainerStyled
                id="speed-boost"
                visible={showCapacityModal}
                title="Virtual room is at capacity"
                onHide={() => mutationCtx.setShowCapacityModal(false)}
                aria-describedby="speed-boost-description"
                actions={[
                    <AppStyle.ButtonStyled
                        key={`button-room-at-capacity`}
                        flat
                        primary
                        primarycolor={get(theme, 'primary')}
                        onClick={() => mutationCtx.setShowCapacityModal(false)}
                    >
                        OK
                    </AppStyle.ButtonStyled>,
                ]}
                width={'360px'}
                modal
                portal
            >
                <AppStyle.Paragraph>
                    This virtual room is at capacity at this moment. You will not be able to enter.
                </AppStyle.Paragraph>
            </AppStyle.DialogContainerStyled>
            <AppStyle.DialogContainerStyled
                id="speed-boost"
                visible={showAlreadyInTheSessionModal}
                title="You are already in the session"
                onHide={() => mutationCtx.setShowAlreadyInTheSessionModal(false)}
                aria-describedby="speed-boost-description"
                actions={[
                    <AppStyle.ButtonStyled
                        key={`button-already-in-session`}
                        flat
                        primary
                        primarycolor={get(theme, 'primary')}
                        onClick={() => mutationCtx.setShowAlreadyInTheSessionModal(false)}
                    >
                        OK
                    </AppStyle.ButtonStyled>,
                ]}
                width={'360px'}
                modal
                portal
            >
                <AppStyle.Paragraph>
                    An account with your ID is already in the session. You will not be able to
                    enter.
                </AppStyle.Paragraph>
            </AppStyle.DialogContainerStyled>
            {children}
        </React.Fragment>
    );
};

export default NotificationWrapper;
