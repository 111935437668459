import React from 'react';
import { List } from 'react-md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ObjectListItem from '../../components/ObjectListItem';
import { getString } from '../../services/api/store';
import Auth from '../../services/api/auth';

const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
    font-family: 'Cabin', sans-serif;
`;

const LinkStyled = styled(Link)`
    text-decoration: none !important;
    list-style: none;
`;

const RepresentativesList = ({ representatives, matchUrl }) => {
    const TEXT_REPRESENTATIVES = getString('representativesTitle') || 'Representatives';

    const renderListItems = () => {
        return representatives.map(representative => (
            <LinkStyled
                key={`representative_${representative.userId}`}
                to={`${matchUrl}/profile/${representative.userId}`}
            >
                <ObjectListItem
                    item={
                        Auth.isUserAuthenticated() ? { id: representative.userId } : representative
                    }
                    type={'user'}
                />
            </LinkStyled>
        ));
    };

    return (
        <>
            <SectionTitle>{TEXT_REPRESENTATIVES}</SectionTitle>
            <List>{renderListItems()}</List>
        </>
    );
};

export default RepresentativesList;
