import React from 'react';
import styled from 'styled-components';
import { FontIcon, injectTooltip } from 'react-md';
import * as palette from '../../../../../components/General/Variables';
import { useGlobalMutation, useGlobalState } from '../../../../../utils/container';
import Auth from '../../../../../services/api/auth';
const ProfileImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 10px;
`;

const VirtualUserInfoWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    height: fit-content;
    left: 0;
    bottom: 0;
`;

const VirtualUserTitle = styled.p`
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0;
`;

const MicNoneIconWrapper = styled.div`
    margin-bottom: 8px;
    margin-right: 8px;
    margin-left: 8px;
`;

export default ({ virtualEventUser }) => {
    const user = virtualEventUser.User;
    const { isMicrophoneOn } = virtualEventUser;

    return (
        <ProfileImageWrapper>
            <VirtualUserInfoWrapper>
                <MicNoneIconWrapper>
                    <FontIcon style={{ fontSize: '24px', color: '#ffffff' }}>
                        {isMicrophoneOn ? palette.ICON_MIC : palette.ICON_MIC_OFF}
                    </FontIcon>
                </MicNoneIconWrapper>
                <VirtualUserTitle>
                    {user.firstName} {user.lastName}
                </VirtualUserTitle>
            </VirtualUserInfoWrapper>
        </ProfileImageWrapper>
    );
};
