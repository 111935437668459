import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import {
    loadPrivateChatMessages as loadPrivateChatMessagesAction,
    sendPrivateMessage,
    setLastSeenTimestamp as setLastSeenTimestampAction,
} from '../actions';
import { getIsLoadingChatMessages, getPrivateMessagesHistory } from '../selectors';
import { EmptyState } from '../components/EmptyState';
import { getString } from '../../../services/api/store';
import { Chat as ChatComponent } from '../components';
import { useGlobalState } from '../../../utils/container';
import AnalyticsService from '../../../features/analytics/services';
import { ChatWrapper } from '../common';
import NavigationHeader from '../components/NavigationHeader';
import notificationsApi from '../../Notifications/api';
import { notificationTypes } from '../../Notifications/constants';
import InputMessageComponent from './InputMessageComponent';
import { getLocalAppStateAsync } from '../../../services/api/db';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';
import { getFriendship } from '../../UserProfile/service';
import eventBus from '../../../utils/eventBus';

const MessagesContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 0 48px 0;
`;

const Wrapper = styled.div`
    height: calc(100% - 64px);
    overflow: hidden;
    padding: 8px;
`;

const MESSAGES_LIMIT = 15;

const sortedMessages = (failed, messages) => {
    if (!failed || failed.length === 0) {
        return messages;
    }
    const allMessages = [...failed, ...messages];
    return allMessages.sort((m1, m2) => m2.timestamp - m1.timestamp);
};

const PrivateChat = ({
    location,
    privateMessages,
    loadMessages,
    currentUser,
    sendChatMessage,
    goBack,
    closePath,
    history,
    propConversation,
    containerComponent,
    setLastSeenTimestamp,
}) => {
    const [inputMessage, setInputMessage] = useState('');
    const [eventId, setEventId] = useState(null);
    const [failedMessages, setFailedMessages] = useState([]);
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const TEXT_EMPTY_CHAT_ROOM =
        getString('groupChatNoMessages') || 'No chat messages sent in this room yet.';
    const { trackAchievement } = useAchievementActions();

    const conversation = propConversation || (location.state && location.state.conversation);
    const lastMessageId = conversation?.lastMessage?.id;
    const prevConversation = useRef();
    useEffect(() => {
        if (conversation === undefined) {
            history.push('/');
            return;
        }
        fetchMessages(conversation);
    }, []);

    useEffect(() => {
        (async () => {
            const appState = await getLocalAppStateAsync();

            setEventId(appState.eventId);
        })();
    }, [eventId]);

    useEffect(() => {
        if (conversation && conversation.id && conversation.id !== prevConversation.current) {
            fetchMessages(conversation);
        }
    }, [conversation]);

    useEffect(() => {
        removeChatNotifications();
    }, []);

    useEffect(() => {
        if (lastMessageId) {
            const { lastMessage, id: conversationId } = conversation;
            const { id: messageId, timestamp } = lastMessage;
            setLastSeenTimestamp({
                conversationId,
                messageId,
                timestamp,
                isPrivate: true,
            });
        }
    }, [lastMessageId]);

    const fetchMessages = conversation => {
        prevConversation.current = conversation.id;
        const { jid: user } = currentUser;
        loadMessages({
            user,
            from: conversation.jid,
            limit: MESSAGES_LIMIT,
        });
    };

    const removeChatNotifications = async () => {
        if (!conversation) {
            return;
        }
        // Mark current chat messages as read, so remove the notifications for this chat
        await notificationsApi.removeNotifications({
            type: notificationTypes.CHAT,
            params: {
                senderId: conversation.id,
            },
        });
    };

    const sendMessage = async text => {
        const sent = await sendChatMessage({
            eventId,
            conversation,
            message: text,
        });
        setInputMessage('');
        if (!sent) {
            console.error(` CHAT ERROR - cannot send private message`);
            sendFailMessage(text);
            return;
        }
        const date = new Date().getTime();
        AnalyticsService.addSample('chat', inputMessage, `${conversation.id}_${date}`);
        trackAchievement(AchievementType.CHAT);
        const friendship = await getFriendship(conversation.participant.id);
        if (friendship.status === 1) {
            trackAchievement(AchievementType.CHAT_CONTACT);
        }

        if (socket) {
            socket.emit('messageNotification', {
                chatId: conversation.id,
                senderId: currentUser.id,
                userId: conversation.participant.id,
                type: 'privateChat',
            });
        }
    };

    const sendFailMessage = text => {
        const date = new Date().getTime() * 1000;
        const user = {
            ...currentUser,
            _id: currentUser.id,
        };
        const failedMessage = {
            text,
            user,
            _id: date,
            timestamp: date,
            failed: true,
        };
        setFailedMessages([...failedMessages, failedMessage]);
    };

    const handleChange = event => {
        const { value } = event.target;
        setInputMessage(value);
    };

    const fetchPrevMessages = lastTimestamp => {
        setTimeout(() => {
            loadMessages({
                from: conversation.jid,
                limit: MESSAGES_LIMIT,
                lastTimestamp,
            });
        }, 500);
    };

    const renderChatMessages = () => {
        return (
            <ChatComponent
                messages={sortedMessages(failedMessages, privateMessages)}
                userData={currentUser}
                loadPreviousMessages={time => {
                    fetchPrevMessages(time);
                }}
            />
        );
    };

    const handleGoBack = () => {
        const openedFromNotificationCenter = get(
            location,
            'state.openedFromNotificationCenter',
            false,
        );

        goBack();

        // If the chat was opened from notification center,
        // the notifications list should be opened again when closing the chat.
        if (openedFromNotificationCenter) {
            eventBus.emit('openChatNotifications');
        }
    };

    if (conversation === undefined) {
        return null;
    }

    const Container = containerComponent || ChatWrapper;

    return (
        <Container>
            <>
                <NavigationHeader
                    closePath={closePath}
                    participant={conversation.participant}
                    title={conversation.title || 'Conversation'}
                    goBack={handleGoBack}
                />
                <Wrapper>
                    {privateMessages.length ? (
                        <MessagesContainer>{renderChatMessages()}</MessagesContainer>
                    ) : (
                        <EmptyState message={TEXT_EMPTY_CHAT_ROOM} />
                    )}
                    <InputMessageComponent
                        inputMessage={inputMessage}
                        handleChange={handleChange}
                        sendMessage={sendMessage}
                        participant={conversation.participant}
                    />
                </Wrapper>
            </>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    const conversationId =
        ownProps.conversationId ||
        (ownProps.location.state && ownProps.location.state.conversation.id);
    return {
        privateMessages: getPrivateMessagesHistory(state, conversationId),
        currentUser: state.talk.user,
        isLoading: getIsLoadingChatMessages(state, conversationId, true),
    };
};

export default connect(mapStateToProps, {
    loadMessages: loadPrivateChatMessagesAction,
    sendChatMessage: sendPrivateMessage,
    setLastSeenTimestamp: setLastSeenTimestampAction,
})(withRouter(PrivateChat));
