import React from 'react';
import styled from 'styled-components';
import * as palette from './Variables';
import PropTypes from 'prop-types';
import Button, { buttonTypes } from '../../scenes/common/Button';

const Wrapper = styled.div`
    width: fit-content;
    background-color: ${props => props.color || palette.COLOR_TEXT};
    color: white;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;

    input[type='file'] {
        display: none;
    }

    label {
        cursor: pointer;
        display: flex;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.75);
    }

    .md-icon {
        color: white;
    }
`;

const UploadButton = ({ id, accept, onChange, text, name, icon, color }) => {
    return (
        <Wrapper color={color}>
            <label htmlFor={id}>
                <Button type={buttonTypes.GREEN} background={color} text={text} icon={icon} />
            </label>
            <input id={id} name={name} type="file" accept={accept} onChange={onChange} />
        </Wrapper>
    );
};

UploadButton.propTypes = {
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    accept: PropTypes.string,
    id: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
};

UploadButton.defaultProps = {
    text: '',
    onChange: () => {},
    name: null,
    accept: '*',
    id: 'general-upload-button',
    icon: null,
    color: palette.COLOR_TEXT,
};

export default UploadButton;
