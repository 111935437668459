import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import * as AppStyle from '../style/navigationStyle';
import { DrawerHeader, EventIcon, ExpandButton } from '../style/navigationStyle';
import { FontIcon, NavigationDrawer } from 'react-md';
import AuthButton from '../../scenes/User/containers/AuthButton';
import { getBlackOrWhiteCalculated } from '../../components/General/Colors';

const SideNavigation = ({
    title,
    isMobile,
    visibleSideMenu,
    isSidepanelOpened,
    noScroll,
    navMenu,
    onSideMenuVisibilityChange,
    renderContent,
    Container,
    theme,
    appState,
    newVersionAvailable,
    updateServiceWorker,
    onServiceWorkerUpdate,
    waitingWorker,
    history,
}) => {
    const [displayExpandButton, setDisplayExpandButton] = useState(false);

    return (
        <Container
            id={'main-container'}
            onMouseEnter={() => setDisplayExpandButton(true)}
            onMouseLeave={() => setDisplayExpandButton(false)}
            toolbarTitle={!isMobile && title}
            toolbarTitleStyle={AppStyle.toolbarTitleStyle}
            toolbarStyle={AppStyle.toolbarStyle}
            toolbarZDepth={0}
            tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT}
            mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
            desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT}
            toolbarThemeType="colored"
            toolbarActions={
                <AuthButton
                    newVersionAvailable={newVersionAvailable}
                    updateServiceWorker={updateServiceWorker}
                    onServiceWorkerUpdate={onServiceWorkerUpdate}
                    waitingWorker={waitingWorker}
                />
            }
            contentId="page-content"
            drawerHeader={
                <DrawerHeader alignItems={'center'}>
                    {appState.pageHeader && (
                        <EventIcon src={appState.pageHeader} onClick={() => history.push('/')} />
                    )}
                    {visibleSideMenu && appState.eventTitle && <div>{appState.eventTitle}</div>}
                    {displayExpandButton && (
                        <ExpandButton
                            expanded={visibleSideMenu}
                            primary={theme.primary}
                            isMobile={isMobile}
                            onClick={onSideMenuVisibilityChange}
                        >
                            <FontIcon iconClassName={'material-icons material-icons-round'}>
                                chevron_right
                            </FontIcon>
                        </ExpandButton>
                    )}
                </DrawerHeader>
            }
            primary={theme.primary}
            contrastBlackOrWhite={getBlackOrWhiteCalculated(theme.primary)}
            contentStyle={{
                width: isSidepanelOpened
                    ? visibleSideMenu
                        ? 'calc(100vw - 497px - 258px)'
                        : 'calc(100vw - 573px)'
                    : 'inherit',
                marginLeft: isMobile ? 'unset' : !visibleSideMenu ? '74px' : '260px',
                transition: 'all 0.2s linear',
                minHeight: 'auto',
                overflowY: noScroll ? 'hidden' : 'auto',
            }}
            contentClassName="main-content"
            navItems={navMenu()}
            navStyle={{
                height: '100%',
                width: '100%',
                'overflow-x': 'visible',
                'overflow-y': 'scroll',
            }}
            desktopMinWidth={1300}
            railnavigation={!isMobile && !visibleSideMenu}
            visible={isMobile ? !!visibleSideMenu : true}
            onVisibilityChange={onSideMenuVisibilityChange}
        >
            {renderContent()}
        </Container>
    );
};

export default withRouter(SideNavigation);
