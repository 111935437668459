import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { FontIcon, TextField } from 'react-md';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import Auth from '../../../../../services/api/auth';
import {
    askQuestion,
    likeQuestion,
    loadLatestQuestions,
    toggleQuestionLike,
} from '../../../services/questionsAndAnswers';
import ThemeContext from '../../../../../components/Theme/ThemeContext';
import Loader from '../../../../../components/General/Loader';
import * as palette from '../../../../../components/General/Variables';
import noQuestionImage from '../../../../../assets/images/no-questions.png';
import { useGlobalState } from '../../../../../utils/container';
import { getLocalAppStateAsync } from '../../../../../services/api/db';
import TextAreaMessageComponent from '../../../../../components/TextInput/TextAreaMessageComponent';
import {
    AchievementType,
    useAchievementActions,
} from '../../../../Achievements/hooks/useAchievementActions';

export const Flex = styled.div`
    display: flex;
    align-items: ${props => props.alignitems};
    justify-content: ${props => props.justifycontent};
    flex-wrap: ${props => props.wrap};
`;

const QuestionItemDetailsWrapper = styled(Flex)`
    width: 100%;
`;

export const QuestionAndTimeContainer = styled(QuestionItemDetailsWrapper)`
    flex-direction: column;
    margin-left: 18px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 4px;

    div:nth-of-type(2) {
        font-size: 14px;
        line-height: 1;
        color: rgba(0, 0, 0, 0.54);
        margin-top: 4px;
    }
`;

const StyledChip = styled.div`
    text-align: center !important;
    font-family: 'Roboto', sans-serif;
    font-size: 8px !important;
    font-weight: bold !important;
    color: #fff !important;
    background-color: #1fa294;
    border-radius: 18px;
    width: 26px !important;
    height: 12px !important;
    line-height: 13px !important;
    white-space: nowrap;
    margin-left: 8px;
`;

const TextFieldWrapper = styled.div`
    background-color: #ffffff;
    width: 100%;
    ${props =>
        props.isfirefox
            ? `
        bottom: 110px;
        position: relative;
        display: inline-block;
        content: '';
    `
            : `
        bottom: 0;
        position: absolute !important;
    `}
`;

export const StyledTextField = styled(TextField)`
    height: 40px;
    border-radius: 8px;
    background-color: #f0f0f0;
    margin: 8px 8px 8px 0;
    display: flex;
    align-content: center;
    padding: 10px 10px 10px 16px;
    width: 100% !important;
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}`}
    input {
        margin-top: 0;
        width: ${props => props.inputWidth || '100%'};
    }
    hr {
        display: none;
    }
`;

export const Button = styled(FontIcon)`
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color} !important;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px !important;
    cursor: ${props => props.pointer && 'pointer'};
`;

export const QuestionItem = styled(Flex)`
    border-radius: 8px;
    ${props =>
        props.transparent
            ? 'background-color: transparent;'
            : `background-color: ${props.backgroundColor};
        padding: 12px 16px 14px 12px;
        cursor: pointer;
        `}
    ${props => props.isHovered && 'box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2)'};
    margin-bottom: 8px;
    justify-content: space-between;
    font-family: 'Cabin', sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    white-space: pre-wrap;
    word-break: break-word;
`;

const Wrapper = styled.div`
    height: 100%;
    overflow-y: auto;
    padding: ${props => `8px 16px ${props.extramarginbottom}`}px 16px;
`;

export const Likes = styled(Flex)`
    white-space: nowrap;

    i {
        font-size: 12px !important;
        margin-left: 3px;
    }
`;

export const LikesContainer = styled(Flex)`
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin-left: 12px;
`;

export const StyledFavoriteIcon = styled(FontIcon)`
    color: ${props => props.color} !important;
    cursor: pointer;
    font-size: 20px !important;
`;

export const QuestionsContainer = styled.div`
    height: ${props => (props.mobile === 'true' ? '59%' : '100%')}
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 0 60px 0;
`;

export const Container = styled.div`
    height: ${props =>
        props.isfirefox && props.windowheight
            ? 'calc(100vh - ' + (props.windowheight < 700 ? '300px)' : '400px)')
            : '100%'};
    position: relative;
    overflow: hidden;
`;

export const Margin15pxScrollable = styled.div`
    margin: 15px 0;
    max-height: 130px;
    overflow: scroll;
`;

const PlaceholderImageWrapper = styled.div`
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
    }
`;

const PlaceholderImage = styled.img`
    width: 250px;
    height: 200px;
`;

const ErrorText = styled.p`
    font-style: italic;
    font-size: 14px;
    margin: 0 16px 8px 16px;
    padding-left: 12px;
    color: #d50000 !important;
`;

const ConfirmationText = styled.p`
    font-style: italic;
    font-size: 14px;
    margin: 0 16px 8px 16px;
    color: rgba(0, 0, 0, 0.87);
`;

const TimeAndChipContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MAX_QUESTION_SIZE = 250;

const QuestionsAndAnswers = props => {
    const stateCtx = useGlobalState();
    const { socket, virtualEventSession } = stateCtx;
    const { timeslotId } = props.match.params;

    const [question, setQuestion] = useState('');
    const [questionList, setQuestionList] = useState([]);
    const [loading, setIsLoading] = useState(true);
    const [externalObjectId, setExternalObjectId] = useState(null);
    const [joinedQuestions, setJoinedQuestions] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [showConfirmationText, setShowConfirmationText] = useState(false);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [displayActions, setDisplayActions] = useState(null);

    const eventId = useRef(null);
    const questionSetId = useRef(null);
    const scrollableWrapper = useRef(null);
    const isSubmittingQuestion = useRef(false);
    const isSendingRequest = useRef(false);
    const newQuestionIds = useRef(props.newQuestionIds);
    const isSortedByLikes = useRef(false);
    const initialQuestions = useRef([]);

    const { trackAchievement } = useAchievementActions();

    const currentUser = Auth.getUser();
    const mobile = (window.innerWidth < palette.MIN_TABLET_INT).toString();
    const isFirefox = typeof InstallTrigger !== 'undefined';

    useEffect(() => {
        (async () => {
            try {
                const appState = await getLocalAppStateAsync();

                eventId.current = appState.eventId;

                await getQuestions();
            } catch (err) {
                //
            }
        })();
    }, [timeslotId]);

    useEffect(() => {
        (async () => {
            try {
                await getQuestions();
            } catch (err) {
                //
            }
        })();
    }, [virtualEventSession]);

    useEffect(() => {
        const updateQuestionsHandling = data => {
            if (data.action !== 'addQuestion') {
                getQuestions(data.action);
            }
        };

        const joinQuestions = () => {
            socket.emit('joinQuestions', {
                objectId: externalObjectId,
            });
        };

        (async () => {
            if (!externalObjectId) {
                return () => {};
            }

            if (joinedQuestions.indexOf(externalObjectId) > -1) {
                return () => {};
            }

            setJoinedQuestions([...joinedQuestions, externalObjectId]);

            if (!socket) {
                return;
            }

            socket.on('connect', joinQuestions);

            joinQuestions();

            socket.on('updateQuestions', updateQuestionsHandling);
        })();

        return () => {
            if (socket) {
                socket.off('updateQuestions', updateQuestionsHandling);
                socket.off('connect', joinQuestions);
            }
        };
    }, [externalObjectId]);

    useEffect(() => {
        newQuestionIds.current = props.newQuestionIds;
    }, [props.newQuestionIds]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        props.setNewQuestionsCount(0);
        scrollableWrapper.current &&
            scrollableWrapper.current.addEventListener('scroll', handleScroll);
        return () => {
            props.setNewQuestionsCount(0);
            scrollableWrapper.current &&
                scrollableWrapper.current.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = debounce(() => {
        setWindowHeight(window.innerHeight);
    }, 500);

    const handleScroll = () => {
        if (
            scrollableWrapper.current.scrollTop > 100 &&
            scrollableWrapper.current.scrollTop < 200
        ) {
            props.setNewQuestionsCount(0);
        }
    };

    const onChange = text => {
        setQuestion(text);

        if (showConfirmationText) {
            setShowConfirmationText(false);
        }
    };

    const getQuestions = async action => {
        let questions = [];

        if (eventId.current && Auth.isUserAuthenticated()) {
            const latestQuestions = await loadLatestQuestions({
                eventId: eventId.current,
                itemId: timeslotId,
            });

            setIsLoading(false);

            const firstQuestion = latestQuestions && latestQuestions[0];

            if (!firstQuestion) {
                return;
            }

            questionSetId.current = firstQuestion.id;

            setExternalObjectId(firstQuestion.ExternalObjectId);

            questions = firstQuestion.Questions;
            setAllQuestions(questions);
            questions = questions.filter(question => question && question.visible);
            questions = questions.map(question => {
                question.like = question.QuestionLikes.find(q => q.UserId === currentUser.id);
                question.numberOfLikes = question.QuestionLikes.filter(
                    q => q.active === true,
                ).length;
                question.newQuestion = newQuestionIds.current.includes(question.id);
                return question;
            });

            if (!isSortedByLikes.current || action === 'updateQuestion') {
                sortQuestionsByLikesAndDate(questions);
                isSortedByLikes.current = true;
                initialQuestions.current = questions;
            }

            let newQuestions = questions.filter(q => q && q.newQuestion);
            let oldQuestions = questions.filter(q => q && !q.newQuestion);
            if (action === 'removeLike') {
                setIsLoading(true);
                initialQuestions.current.forEach(item => {
                    const currentQuestion = questions.find(q => q.id === item.id);
                    item.like =
                        currentQuestion &&
                        currentQuestion.QuestionLikes.find(q => q.UserId === currentUser.id);
                    item.numberOfLikes =
                        currentQuestion &&
                        currentQuestion.QuestionLikes.filter(q => q.active === true).length;
                });
                newQuestions = initialQuestions.current.filter(q => q && q.newQuestion);
                oldQuestions = initialQuestions.current.filter(q => q && !q.newQuestion);
                setIsLoading(false);
            }
            setQuestionList(oldQuestionsList => {
                const oldQuestionList = oldQuestionsList.filter(q => q && !q.newQuestion);
                if (
                    oldQuestionList.length !== 0 &&
                    oldQuestions.length === oldQuestionList.length
                ) {
                    const likesMap = Array.from(new Set(newQuestions.concat(oldQuestions))).reduce(
                        (acc, q) => {
                            acc[q.id] = { ...q };
                            return acc;
                        },
                        {},
                    );
                    const questionsWithUpdatedLikes = oldQuestionList.map(q => likesMap[q.id]);

                    return Array.from(new Set(newQuestions.concat(questionsWithUpdatedLikes)));
                }

                return Array.from(new Set(newQuestions.concat(oldQuestions)));
            });
        }

        return questions;
    };

    const sortQuestionsByLikesAndDate = questions => {
        const sorted = questions.sort((a, b) =>
            a.numberOfLikes > b.numberOfLikes
                ? -1
                : b.numberOfLikes > a.numberOfLikes
                ? 1
                : new Date(b.createdAt) - new Date(a.createdAt),
        );

        return sorted;
    };

    const calcTimeDifference = start => {
        const timedifference = new Date(start).getTimezoneOffset();
        const end = moment().utcOffset(timedifference);
        const timeDifference = moment.duration(end.diff(start));
        const days = timeDifference.days();
        if (days > 0) {
            return `${days}d ago`;
        }
        const hours = timeDifference.hours();
        if (hours > 0) {
            return `${hours}h ago`;
        }
        const minutes = timeDifference.minutes();
        return `${minutes}m ago`;
    };

    const onFavorite = async ({ id, like }) => {
        if (isSendingRequest.current) {
            return;
        }
        isSendingRequest.current = true;

        const data = {
            active: like ? !like.active : true,
            QuestionId: id,
            UserId: currentUser.id,
        };

        if (like) {
            await toggleQuestionLike({ questionLikeId: like.id, data });
        } else {
            await likeQuestion({ data });
        }

        isSendingRequest.current = false;
    };

    const onSubmitQuestion = async () => {
        if (isSubmittingQuestion.current === true) {
            return;
        }

        const currentQuestionAlreadyExists = allQuestions.find(item => item.question === question);

        if (question && !currentQuestionAlreadyExists) {
            isSubmittingQuestion.current = true;

            try {
                const sessionId = props.match.params.objectId || props.match.params.timeslotId;
                await askQuestion({ questionSetId: questionSetId.current, question, sessionId });
                trackAchievement(AchievementType.QUESTION);

                if (!questionList.length) {
                    trackAchievement(AchievementType.FIRST_QUESTION);
                }
            } catch (err) {
                //
            }

            isSubmittingQuestion.current = false;
        }

        setQuestion('');

        if (question) {
            setShowConfirmationText(true);
        }
    };

    const renderQuestions = theme =>
        questionList.map((item, index) =>
            item ? (
                <QuestionItem
                    key={`question-${index}`}
                    data-qa={`qa-item-${item.id}`}
                    onMouseEnter={() => setDisplayActions(item.id)}
                    onMouseLeave={() => setDisplayActions(null)}
                    backgroundColor={displayActions === item.id ? '#fff' : '#f1f1f3'}
                    isHovered={displayActions === item.id}
                >
                    <QuestionItemDetailsWrapper>
                        <LikesContainer data-qa={`qa-item-likes-${item.id}`}>
                            <StyledFavoriteIcon
                                color={
                                    (item.like && item.like.active && '#000000') ||
                                    'rgba(0, 0, 0, 0.87)'
                                }
                                onClick={() => onFavorite(item)}
                            >
                                {item.like && item.like.active ? 'favorite' : 'favorite_border'}
                            </StyledFavoriteIcon>
                            {item.numberOfLikes}
                        </LikesContainer>
                        <QuestionAndTimeContainer>
                            <div>{item.question}</div>
                            <TimeAndChipContainer>
                                {calcTimeDifference(new Date(item.createdAt))}
                                {item.newQuestion && (
                                    <StyledChip primary={theme.contrast}>NEW</StyledChip>
                                )}
                            </TimeAndChipContainer>
                        </QuestionAndTimeContainer>
                    </QuestionItemDetailsWrapper>
                </QuestionItem>
            ) : null,
        );

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <Container isfirefox={isFirefox} windowheight={windowHeight}>
                    {loading && <Loader />}
                    <QuestionsContainer mobile={mobile}>
                        <Wrapper
                            innerRef={scrollableWrapper}
                            extramarginbottom={showConfirmationText ? 50 : 0}
                        >
                            {!loading && renderQuestions(theme)}
                            {!loading && questionList.length === 0 && (
                                <PlaceholderImageWrapper>
                                    <PlaceholderImage src={noQuestionImage} alt="No questions" />
                                    <span>There are no questions yet</span>
                                </PlaceholderImageWrapper>
                            )}
                        </Wrapper>
                    </QuestionsContainer>
                    <TextFieldWrapper isfirefox={isFirefox ? 1 : 0}>
                        <Flex alignItems={'center'}>
                            <TextAreaMessageComponent
                                sendMessage={onSubmitQuestion}
                                placeholder={'Ask a question'}
                                onChange={onChange}
                                maxChar={MAX_QUESTION_SIZE}
                            />
                        </Flex>
                        {question.length > MAX_QUESTION_SIZE && (
                            <ErrorText>{`${question.length}/${MAX_QUESTION_SIZE} characters`}</ErrorText>
                        )}
                        {showConfirmationText && (
                            <ConfirmationText data-qa="qa-question-sent-paragraph">
                                Question was sent. It will appear in this list when the moderator
                                decides to make it visible.
                            </ConfirmationText>
                        )}
                    </TextFieldWrapper>
                </Container>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(QuestionsAndAnswers);
