import React, { useState } from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button, { buttonTypes } from '../../../common/Button';
import Tabs from '../../../../components/Tabs';
import Info from './Info';
import Resources from './Resources';
import Contact from './Contact';
import Schedule from './Schedule';
import * as palette from '../../../../components/General/Variables';
import {
    selectContentData,
    selectFullRepresentatives,
    selectShouldRenderContactTab,
    selectShouldRenderResourcesTab,
    selectShouldRenderScheduleTab,
} from '../../selectors';
import { openExternalLink } from '../../common';
import { useTheme } from '../../../../components/Theme/ThemeContext';
import AppointmentModal, {
    appointmentTypes,
} from '../../../Appointments/containers/AppointmentModal';
import { getString } from '../../../../services/api/store';
import sortBy from 'lodash/sortBy';
import TypeAccessRestrictionsForTabs, {
    tabTypes,
} from '../../../AccessRestrictions/TypeAccessRestrictionsForTabs';

const Container = styled.div`
    background-color: #ffffff;
    border-radius: 8px;
    padding: 16px 24px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 16px;
`;

const LogoAndNameWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.img`
    width: 72px;
    height: auto;
    margin-right: 8px;
`;

const Name = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
`;

const LinksContainer = styled.div`
    display: flex;
`;

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-right: 24px;
    }
`;

const LinkIcon = styled(FontIcon)`
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.38) !important;
`;

const LinkText = styled.div`
    font-family: 'Roboto', sans-serif;
    color: ${props => props.primary || '#00a933'};
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const Intro = styled.div`
    font-family: 'Roboto', sans-serif;
    opacity: 0.87;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #000000;
    margin: 16px 0;
`;

const ContentColumn = ({
    content,
    history,
    match,
    shouldRenderResourcesTab,
    shouldRenderContactTab,
    shouldRenderScheduleTab,
    representatives,
    currentUserGroups,
}) => {
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);

    const { theme } = useTheme();

    if (!content) {
        return null;
    }

    const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;
    const { imageUrl, title, introText, websiteUrl, locations } = content;
    const shouldRenderRequestMeetingButton = representatives.length > 0;

    const tabsData = [];

    if (
        content?.info?.text ||
        content?.classifiers?.length ||
        content.videoUrl ||
        content?.slideshowImages?.length
    ) {
        tabsData.push({
            id: 'tabInfoRestrictedGroup',
            label: getString('infoTab', 'Info'),
            content: () => (
                <TypeAccessRestrictionsForTabs
                    tabType={tabTypes.INFO}
                    typeParams={content.typeParams}
                    currentUserGroups={currentUserGroups}
                >
                    <Info exhibitor={content} />
                </TypeAccessRestrictionsForTabs>
            ),
        });
    }

    if (shouldRenderResourcesTab) {
        tabsData.push({
            id: 'tabResourcesRestrictedGroup',
            label: getString('resourcesTab', 'Resources'),
            content: () => (
                <TypeAccessRestrictionsForTabs
                    tabType={tabTypes.RESOURCES}
                    typeParams={content.typeParams}
                    currentUserGroups={currentUserGroups}
                >
                    <Resources />
                </TypeAccessRestrictionsForTabs>
            ),
        });
    }
    if (shouldRenderScheduleTab) {
        tabsData.push({
            id: 'tabScheduleRestrictedGroup',
            label: getString('scheduleTab', 'Schedule'),
            content: () => (
                <TypeAccessRestrictionsForTabs
                    tabType={tabTypes.SCHEDULE}
                    typeParams={content.typeParams}
                    currentUserGroups={currentUserGroups}
                >
                    <Schedule item={shouldRenderScheduleTab} matchUrl={match.url} />
                </TypeAccessRestrictionsForTabs>
            ),
        });
    }
    if (shouldRenderContactTab) {
        tabsData.push({
            id: 'tabContactRestrictedGroup',
            label: getString('contactTab', 'Contact'),
            content: () => (
                <TypeAccessRestrictionsForTabs
                    tabType={tabTypes.CONTACT}
                    typeParams={content.typeParams}
                    currentUserGroups={currentUserGroups}
                >
                    <Contact />
                </TypeAccessRestrictionsForTabs>
            ),
        });
    }

    const requestMeetingButton = (
        <Button
            background={theme.primary}
            type={isMobile ? buttonTypes.GREEN_LONG : buttonTypes.GREEN}
            text={getString('institutionBoothRequestMeetingButtonTitle') || 'Request meeting'}
            icon="add"
            onClick={() => setShowAppointmentModal(true)}
        />
    );

    if (content.typeParams?.tabRestrictionsWithOrder) {
        tabsData.map(tab => {
            const tabWithGivenId = content.typeParams?.tabRestrictionsWithOrder.find(
                it => it.id === tab.id,
            );
            tab.order = tabWithGivenId?.order;
            tab.label = tabWithGivenId?.name;
            return tab;
        });
    }

    return (
        <Container>
            <Header>
                <LogoAndNameWrapper>
                    {imageUrl && <Logo src={imageUrl} alt="Logo" />}
                    <Name>{title}</Name>
                </LogoAndNameWrapper>
                {!isMobile && shouldRenderRequestMeetingButton && requestMeetingButton}
            </Header>

            <LinksContainer>
                {locations.map(({ id, floorplan, name }) => (
                    <LinkWrapper key={`link-${id}`}>
                        <LinkIcon iconClassName="material-icons-outlined">location_on</LinkIcon>
                        <LinkText
                            onClick={() => history.push(`/floorplan/${floorplan}/${id}`)}
                            primary={theme.primary}
                        >
                            {name}
                        </LinkText>
                    </LinkWrapper>
                ))}
                {websiteUrl && (
                    <LinkWrapper>
                        <LinkIcon iconClassName="material-icons-outlined">link</LinkIcon>
                        <LinkText
                            onClick={() => openExternalLink(websiteUrl)}
                            primary={theme.primary}
                        >
                            {websiteUrl}
                        </LinkText>
                    </LinkWrapper>
                )}
            </LinksContainer>

            <Intro>{introText}</Intro>

            {isMobile && shouldRenderRequestMeetingButton && requestMeetingButton}

            <Tabs data={sortBy(tabsData, 'order')} useMemo={true} isSmallTab={true} />

            {showAppointmentModal && (
                <AppointmentModal
                    onClose={() => setShowAppointmentModal(false)}
                    representatives={representatives}
                    type={appointmentTypes.WITH_REPRESENTATIVE}
                />
            )}
        </Container>
    );
};

const mapStateToProps = state => ({
    content: selectContentData(state.exhibitorBooth),
    shouldRenderResourcesTab: selectShouldRenderResourcesTab(state.exhibitorBooth),
    shouldRenderContactTab: selectShouldRenderContactTab(state.exhibitorBooth),
    shouldRenderScheduleTab: selectShouldRenderScheduleTab(state.exhibitorBooth),
    representatives: selectFullRepresentatives(state.exhibitorBooth),
});

export default connect(mapStateToProps)(withRouter(ContentColumn));
