import React from 'react';
import Auth from '../../../services/api/auth';
import { ButtonsContainer, Card, CloseCardIcon, Subtitle, Title } from '../style/signIn';
import Button, { buttonTypes } from '../../common/Button';
import { getString } from '../../../services/api/store';
import {
    UNAUTHORIZED_BUTTON_LABEL,
    UNAUTHORIZED_DESCRIPTION,
    UNAUTHORIZED_TITLE,
} from '../../../components/ProtectedEvents/strings';
import { Flex } from '../../common/Flex';
import * as palette from '../../../components/General/Variables';

const UnauthorizedAttendeeContent = ({
    theme,
    onHide,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => {
    const TITLE = getString('attendeesOnlyInstallEventWarningTitle', UNAUTHORIZED_TITLE);
    const DESCRIPTION = getString(
        'attendeesOnlyInstallEventWarningMessage',
        UNAUTHORIZED_DESCRIPTION,
    );
    const LABEL = getString(
        'attendeesOnlyInstallEventWarningConfirmButton',
        UNAUTHORIZED_BUTTON_LABEL,
    );

    const onClick = () => {
        if (onHide) {
            onHide();
        } else {
            Auth.signOut();
        }
    };

    return (
        <Card customHeight={'240px'} isModal={isModal}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title>{TITLE}</Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseCardIcon onClick={onClose}>{palette.ICON_CLOSE}</CloseCardIcon>
                )}
            </Flex>
            <Subtitle>{DESCRIPTION}</Subtitle>
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={LABEL}
                    onClick={onClick}
                    data-qa="button-sign-in-with-another-account"
                />
            </ButtonsContainer>
        </Card>
    );
};

export default UnauthorizedAttendeeContent;
