import * as React from 'react';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    AppointmentCardButton,
    AppointmentCardButtonLight,
    AppointmentCardButtonsWrapper,
    AppointmentCardDetails,
    AppointmentCardLabel,
    AppointmentCardTextWrapper,
    AppointmentCardTitle,
    AppointmentCardWrapper,
    ChatBubbleWrapper,
    LinkStyle,
} from './styles';
import { useTheme } from '../../Theme/ThemeContext';
import {
    acceptAppointment as acceptAppointmentAction,
    declineAppointment as declineAppointmentAction,
    deleteAppointment as deleteAppointmentAction,
} from '../../../scenes/Talk/actions';
import { APPOINTMENT_STATE } from '../../../scenes/Talk/constants';
import Auth from '../../../services/api/auth';
import { Flex } from '../../../scenes/VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import { useContext } from 'react';
import { TimezoneContext } from '../../../scenes/Timezone/context';

const DATE_FORMAT = 'DD MMMM';
const TIME_FORMAT = 'HH:mm';

const AppointmentCard = ({
    data,
    acceptAppointment,
    declineAppointment,
    deleteAppointment,
    history,
    goToAppointmentDetails,
}) => {
    const { title, location, needsResponse, isVirtual } = data;
    const { theme } = useTheme();
    const user = Auth.getUser();
    const { timeslotToTimezone } = useContext(TimezoneContext);

    const isOwnAppointment = data.creator === user.id;
    const isDeclined = data.action === APPOINTMENT_STATE.DECLINED;
    const isAccepted = data.action === APPOINTMENT_STATE.ACCEPTED;

    const getMomentDate = date => {
        let momentDate = moment(date);
        if (!momentDate.isValid()) {
            momentDate = moment.unix(date);
        }

        return momentDate;
    };

    const formatDate = (date, format) => {
        const formatted = getMomentDate(date);
        return formatted.utc().format(format);
    };

    const checkIfIsNow = (start, end) => {
        const now = moment.now();
        const startDate = getMomentDate(start);
        const endDate = getMomentDate(end);

        return startDate.isSameOrBefore(now) && endDate.isSameOrAfter(now);
    };

    const onAcceptAppointment = () => {
        acceptAppointment({ ...data, id: data.eurekaId });
    };

    const onDeclineAppointment = () => {
        declineAppointment({ ...data, id: data.eurekaId });
    };

    const onCancelRequestAppointment = () => {
        deleteAppointment({ ...data, id: data.eurekaId, reference: data.id });
    };

    const onGoToMeeting = () => {
        history.push({
            pathname: `/virtual-session-room/${data.id}`,
            state: { prevPath: history.location.pathname },
        });
    };

    const onGoToAppointmentDetails = () => {
        goToAppointmentDetails(data.eurekaId);
    };
    const timeslotWithTimezone = timeslotToTimezone(data);

    let start = moment.utc(timeslotWithTimezone.start);
    if (!start.isValid()) {
        start = moment.unix(timeslotWithTimezone.start);
    }

    let end = moment.utc(timeslotWithTimezone.end);
    if (!end.isValid()) {
        end = moment.unix(timeslotWithTimezone.end);
    }

    const date = formatDate(start, DATE_FORMAT);
    const startTime = formatDate(start, TIME_FORMAT);
    const endTime = formatDate(end, TIME_FORMAT);
    const dateTime = `${date} - ${startTime} - ${endTime}`;
    const isNow = checkIfIsNow(start, end);

    if (isDeclined && !isOwnAppointment) {
        return null;
    }

    return (
        <div style={ChatBubbleWrapper} key={`appointment_message_${data.id}`}>
            <AppointmentCardWrapper alignRight={isOwnAppointment}>
                <AppointmentCardTextWrapper>
                    <Flex>
                        <AppointmentCardTitle>{title} </AppointmentCardTitle>
                        {isDeclined && <AppointmentCardLabel red>Declined</AppointmentCardLabel>}
                        {isNow && !isDeclined && <AppointmentCardLabel>Now</AppointmentCardLabel>}
                    </Flex>
                    <AppointmentCardDetails>{dateTime}</AppointmentCardDetails>
                    <AppointmentCardDetails>{location}</AppointmentCardDetails>
                </AppointmentCardTextWrapper>
                <AppointmentCardButtonsWrapper>
                    {needsResponse && (
                        <>
                            <AppointmentCardButton
                                color={theme.primary}
                                onClick={onAcceptAppointment}
                            >
                                Accept
                            </AppointmentCardButton>
                            <AppointmentCardButtonLight
                                color={theme.primary}
                                onClick={onDeclineAppointment}
                            >
                                Decline
                            </AppointmentCardButtonLight>
                        </>
                    )}

                    {isAccepted && (
                        <>
                            {isVirtual && (
                                <AppointmentCardButton
                                    color={theme.primary}
                                    onClick={onGoToMeeting}
                                >
                                    Go to meeting
                                </AppointmentCardButton>
                            )}
                            <AppointmentCardButtonLight
                                color={theme.primary}
                                onClick={onGoToAppointmentDetails}
                            >
                                Details
                            </AppointmentCardButtonLight>
                        </>
                    )}

                    {isOwnAppointment && needsResponse && (
                        <AppointmentCardButtonLight
                            color={theme.primary}
                            onClick={onCancelRequestAppointment}
                        >
                            Cancel request
                        </AppointmentCardButtonLight>
                    )}
                </AppointmentCardButtonsWrapper>
            </AppointmentCardWrapper>
        </div>
    );
};

export default connect(null, {
    acceptAppointment: acceptAppointmentAction,
    declineAppointment: declineAppointmentAction,
    deleteAppointment: deleteAppointmentAction,
})(withRouter(AppointmentCard));
