import React, { useState } from 'react';
import { DialogContainer, TextField } from 'react-md';
import * as palette from '../General/Variables';
import ThemeContext from '../Theme/ThemeContext';
import { StyledForm, StyledInfo, SyncImage } from '../SignIn/styles';
import { StyledTitle, BlueButton } from './styles';
import {
    HIDDEN_PASSWORD_BUTTON_LABEL,
    HIDDEN_PASSWORD_DESCRIPTION,
    HIDDEN_PASSWORD_TITLE,
} from './strings';

const HiddenPasswordModal = ({ password, onDone }) => {
    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(false);

    const onChange = value => {
        if (value.length > 0) {
            setIsValid(true);
        }

        setValue(value);
    };

    const onKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onSubmit();
        }
    };

    const onSubmit = () => {
        if (!isValid) {
            return;
        }

        if (value !== password) {
            setIsValid(false);
            return;
        }

        localStorage.setItem('enteredHiddenPassword', value);
        onDone();
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    {isDesktop ? (
                        <DialogContainer
                            id="speed-boost"
                            modal
                            width={palette.DIALOG_WIDTH}
                            height={palette.DIALOG_HEIGHT}
                            aria-describedby="speed-boost-description"
                            style={{ zIndex: '100 !important' }}
                            visible={true}
                        >
                            <StyledForm maincolor={theme.primary}>
                                <SyncImage />
                                <StyledTitle>{HIDDEN_PASSWORD_TITLE}</StyledTitle>
                                <StyledInfo>{HIDDEN_PASSWORD_DESCRIPTION}</StyledInfo>
                                <TextField
                                    id="hidden-password"
                                    required
                                    error={value.length > 0 && !isValid}
                                    value={value}
                                    onChange={onChange}
                                    label="Password"
                                    type="text"
                                    errorText={'Password is incorrect.'}
                                    onKeyDown={onKeyDown}
                                />
                                <BlueButton onClick={onSubmit} color={theme.primary}>
                                    {HIDDEN_PASSWORD_BUTTON_LABEL}
                                </BlueButton>
                            </StyledForm>
                        </DialogContainer>
                    ) : (
                        <DialogContainer
                            id="speed-boost"
                            aria-describedby="speed-boost-description"
                            style={{ zIndex: '100 !important' }}
                            visible={true}
                            fullPage={true}
                            pageX={12}
                            pageY={12}
                            aria-labelledby="simple-full-page-dialog-title"
                        >
                            <StyledForm maincolor={theme.primary}>
                                <SyncImage />
                                <StyledTitle>{HIDDEN_PASSWORD_TITLE}</StyledTitle>
                                <StyledInfo>{HIDDEN_PASSWORD_DESCRIPTION}</StyledInfo>
                                <TextField
                                    id="hidden-password"
                                    required
                                    error={value.length > 0 && !isValid}
                                    value={value}
                                    onChange={onChange}
                                    label="Password"
                                    type="text"
                                    errorText={'Password is incorrect.'}
                                    onKeyDown={onKeyDown}
                                />
                                <BlueButton onClick={onSubmit} color={theme.primary}>
                                    {HIDDEN_PASSWORD_BUTTON_LABEL}
                                </BlueButton>
                            </StyledForm>
                        </DialogContainer>
                    )}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default HiddenPasswordModal;
