import React, { useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { List } from 'react-md';
import styled from 'styled-components';
import { TimezoneContext } from '../../Timezone/context';
import ObjectListItem from '../../../components/ObjectListItem';
import GroupButton from './groupButton';
import { formatDate, getDateWithLocale, getFormatTime } from '../../../services/api/data';
import SearchResultsContext from '../../../components/SearchResults/SearchResultsContext';
import { getString } from '../../../services/api/store';
import moment from 'moment';
import Auth from '../../../services/api/auth';

const Scrollable = styled.section.attrs({
    className: '',
})`
    height: 100%;
    overflow: auto;
`;

const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
    font-family: 'Cabin', sans-serif;
`;

const LinkStyled = styled(Link)`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${() => window.innerWidth}px;
`;

const SearchList = ({
    results,
    pageId,
    history,
    rootRoute,
    limit,
    hiddenSectionTitle,
    childrenId,
}) => {
    const [listItems, setListItems] = useState([]);

    const { timeslotToTimezone } = useContext(TimezoneContext);
    const isAuthenticated = Auth.isUserAuthenticated();

    const ListItem = ({ type, item }) => (
        <span>
            <ObjectListItem item={item} type={type} childrenId={childrenId} />
        </span>
    );

    const openGroupResults = url => {
        if (history) {
            history.push(url);
        }
    };

    useEffect(() => {
        if (isEmpty(results)) {
            return;
        }
        const listComponents = [];
        forEach(results, sectionResults => {
            const queryType = get(sectionResults, 'listQuery', 'timeslot');
            const target = get(sectionResults, 'type.target', 'timeslot');
            const title = get(sectionResults, 'type.plural', '');
            const typeId = get(sectionResults, 'type.id', '');
            const itemType = target.toLowerCase();
            let baseURL = `${rootRoute}/page/list/${pageId}/${itemType}`;
            if (itemType === 'institution' && sectionResults?.type?.params?.showClickBooth) {
                baseURL = '/exhibitorbooth';
            }

            const altBaseURL = `${rootRoute}/page/list/${pageId}/details/${itemType}`;
            const groupBaseUrl = `${rootRoute}/page/searchResults/${pageId}/${typeId}`;

            const timeArray = [];
            for (let index = 0; index < sectionResults.items.length; index++) {
                const it = sectionResults.items[index];
                const item = timeslotToTimezone(it);
                if (item.start) {
                    item.time = formatDate(item);
                    let start = moment.utc(item.start);
                    if (!start.isValid()) {
                        start = moment.unix(item.start);
                    }
                    const timeFormat = getFormatTime();
                    const timetoDisplay = moment(start).format(timeFormat);
                    const dateSettings = getString('datetime');
                    const datePattern =
                        dateSettings && dateSettings.dayMonthDateFormat
                            ? dateSettings.dayMonthDateFormat
                            : 'MMMM D';
                    const day = getDateWithLocale(start);
                    const dateToDisplay = day.format(datePattern);

                    item.dateTime = {
                        startTimeToDisplay: timetoDisplay,
                        startDateToDisplay: dateToDisplay,
                    };
                    timeArray.push({ dateTime: item.dateTime });
                }

                if (limit && index >= limit) {
                    // if section has too many results, we group them and display a "load all" button
                    const groupButton = (
                        <SearchResultsContext.Consumer key={`link_${index}_${item.id}`}>
                            {({ setResultsData }) => (
                                <GroupButton
                                    id={index}
                                    label={`View all ${
                                        sectionResults.items.length
                                    } ${title.toLowerCase()}`}
                                    onClick={() => {
                                        setResultsData([
                                            {
                                                type: sectionResults.type,
                                                items: sectionResults.items,
                                            },
                                        ]);
                                        openGroupResults(groupBaseUrl);
                                    }}
                                />
                            )}
                        </SearchResultsContext.Consumer>
                    );
                    listComponents.push(groupButton);
                    break;
                }

                let linkUrl = `${baseURL}/${item.id}`;
                if (queryType === 'findWebpages' || itemType === 'webpage') {
                    linkUrl = `${altBaseURL}/${item.id}`;
                }
                if (itemType === 'person' && item.eurekaId && isAuthenticated) {
                    linkUrl = `${rootRoute}/page/list/${pageId}/profile/${item.eurekaId}/${item.id}`;
                }
                const listItem = (
                    <div key={item.id}>
                        {index === 0 && !hiddenSectionTitle && (
                            // Renders section title on first items of each type
                            <SectionTitle key={item.id}>{title}</SectionTitle>
                        )}
                        <LinkStyled
                            key={`link_${index}_${item.id}`}
                            to={{ pathname: linkUrl, state: { prevPath: rootRoute } }}
                        >
                            <ListItem type={itemType} item={item} />
                        </LinkStyled>
                    </div>
                );
                listComponents.push(listItem);
            }
        });
        setListItems(listComponents);
    }, [results]);

    const renderList = () => {
        return <ListStyled className="">{listItems}</ListStyled>;
    };

    return <Scrollable>{renderList()}</Scrollable>;
};

export default withRouter(SearchList);
