import React, { useMemo } from 'react';

import {
    ContainerLeft,
    MainScreen,
    MainScreenWrapper,
    NoJoinedUserPlaceholder,
    RemoteVideoMutedOverlay,
    RemoteVideoWrapper,
} from '../../../styles';
import { useGlobalState } from '../../../../../utils/container';
import useResize from '../../../hooks/useResize';
import ActiveVideo from './MainVideo';
import { getString } from '../../../../../services/api/store';

const OtherUserContent = () => {
    const EMPTY_ROOM_TEXT =
        getString('virtualRoomEmpty') || `User hasn’t joined this virtual room yet`;
    const stateCtx = useGlobalState();

    const { peers, virtualEventUser } = stateCtx;
    const { isVideoOn } = virtualEventUser;

    const screenWrapperRef = useResize();

    const hasPartner = useMemo(() => {
        return !!peers.find(peerId => peerId === virtualEventUser.UserId);
    }, [peers]);

    const renderActiveVideo = () => {
        const content = <ActiveVideo />;

        if (!isVideoOn) {
            return (
                <RemoteVideoWrapper>
                    {content}
                    <RemoteVideoMutedOverlay />
                </RemoteVideoWrapper>
            );
        }

        return content;
    };

    return (
        <ContainerLeft size={8} tabletSize={8} phoneSize={12}>
            <MainScreenWrapper innerRef={screenWrapperRef}>
                <MainScreen size={12} tabletSize={12} video="yes">
                    {hasPartner ? (
                        renderActiveVideo()
                    ) : (
                        <NoJoinedUserPlaceholder> {EMPTY_ROOM_TEXT} </NoJoinedUserPlaceholder>
                    )}
                </MainScreen>
            </MainScreenWrapper>
        </ContainerLeft>
    );
};

export default OtherUserContent;
