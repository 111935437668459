import styled from 'styled-components';
import * as palette from './Variables';

export const DetailDrawer = styled.div`
    position: fixed;
    top: 64px;
    right: 0;
    width: 480px;
    border-radius: 12px;
    margin: 16px;
    height: calc(100% - 64px - 32px);
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(47, 51, 55, 0.15);
    z-index: 4;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        border-radius: unset;
        z-index: 1000;
        margin: 0;
    }
`;
