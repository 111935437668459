import React, { useEffect, useState } from 'react';
import { DialogContainer } from 'react-md';

import * as palette from '../../../../components/General/Variables';
import isEqual from 'lodash/isEqual';
import { useTheme } from '../../../../components/Theme/ThemeContext';
import Auth from '../../../../services/api/auth';

import {
    getCountries,
    getFullProfile,
    updateFullProfile,
    uploadFileToS3,
} from '../../../../services/api/eureka';
import 'cropperjs/dist/cropper.css';
import { ConfirmDialog } from '../../../../components/Dialog';
import { useGlobalState } from '../../../../utils/container';
import { getString } from '../../../../services/api/store';
import * as constants from '../../constants';
import * as StyledComponent from '../../style/modalsStyle';
import { ProfileForm } from '../../components/profile/ProfileForm';
import { ProfileContent } from '../../components/profile/ProfileContent';
import ImageCropperModal from '../../../../components/ImageCropper/ImageCropperModal';
import ComplexDialog from '../../../../components/Dialog/ComplexDialog';
import Loader from '../../../../components/General/Loader';

const getDefaultFieldValues = () => ({
    coverUrl: '',
    imageUrl: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    title: '',
    headline: '',
    companyName: '',
    country: 0,
    facebookLink: '',
    linkedinLink: '',
    twitterLink: '',
    webLink: '',
    email: '',
    phone: '',
    city: '',
    streetName: '',
    streetNumber: '',
    zip: '',
});

const ProfileModal = ({ onClose, afterSignUp, handleSettingsModal }) => {
    const PROFILE_TITLE = getString('profileCheckMyProfileScreenTitle') || constants.CHECK_PROFILE;
    const EDIT_PROFILE_TITLE =
        getString('profileEditMyProfileScreenTitle') || constants.EDIT_PROFILE;
    const STEPS = getString('profileOnboardingCompleteStep') || constants.STEPS;

    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const [fieldValues, setFieldValues] = useState(getDefaultFieldValues());
    const [countries, setCountries] = useState([]);
    const [user, setUser] = useState(null);
    const [editedLinks, setEditedLinks] = useState({
        facebookLink: false,
        linkedinLink: false,
        twitterLink: false,
        webLink: false,
    });
    const [fileCroppedCover, setFileCroppedCover] = useState(null);
    const [fileCroppedAvatar, setFileCroppedAvatar] = useState(null);
    const [initialFieldValues, setInitialFieldValues] = useState(null);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showConfirmSaveIcon, setShowConfirmSaveIcon] = useState(false);
    const [closeIcon, setCloseIcon] = useState(null);
    const [showSocialLinksModal, setShowSocialLinksModal] = useState(false);
    const [showContactInfoModal, setShowContactInfoModal] = useState(false);
    const [importedFields, setImportedFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [imageToCropName, setImageToCropName] = useState('');
    const [saving, setSaving] = useState(false);
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const { theme } = useTheme();

    useEffect(() => {
        const fetchProfileData = async () => {
            const userData = await getFullProfile();
            const countiresResponse = await getCountries();

            setUser(userData);
            if (userData && userData.importedFields) {
                Auth.getAuthSettings((err, config) => {
                    if (config && config.externalEurekaLogin && config.externalLoginOptions) {
                        setImportedFields(userData.importedFields);
                    }
                });
            }

            mapCountiresToSelectItems(countiresResponse.countries);
            mapUserToFieldValues(userData);
            setLoading(false);
        };

        fetchProfileData();
    }, []);

    const mapCountiresToSelectItems = countries => {
        setCountries(
            countries.map(country => ({
                label: country.name,
                value: country.id,
            })),
        );
    };

    const findLinkByType = (links, type) => links.find(link => link.type === type) || null;

    const mapUserToFieldValues = user => {
        const fieldValuesCopy = { ...fieldValues };

        const { Links: links, Contact: contact } = user;

        const facebookLink = findLinkByType(links, 'facebook');
        const linkedinLink = findLinkByType(links, 'linkedin');
        const twitterLink = findLinkByType(links, 'twitter');
        const webLink = findLinkByType(links, 'website');

        fieldValuesCopy.coverUrl = user.coverUrl;
        fieldValuesCopy.imageUrl = user.imageUrl;
        fieldValuesCopy.firstName = user.firstName;
        fieldValuesCopy.lastName = user.lastName;
        fieldValuesCopy.jobTitle = user.jobTitle;
        fieldValuesCopy.title = user.title;
        fieldValuesCopy.headline = user.headline;
        fieldValuesCopy.companyName = user.companyName;
        fieldValuesCopy.country = user.Country ? user.Country.id : 0;

        fieldValuesCopy.facebookLink = facebookLink ? facebookLink.url : '';
        fieldValuesCopy.linkedinLink = linkedinLink ? linkedinLink.url : '';
        fieldValuesCopy.twitterLink = twitterLink ? twitterLink.url : '';
        fieldValuesCopy.webLink = webLink ? webLink.url : '';

        fieldValuesCopy.email = contact ? contact.email : '';
        fieldValuesCopy.phone = contact ? contact.phone : '';
        fieldValuesCopy.city = contact ? contact.city : '';
        fieldValuesCopy.streetName = contact ? contact.streetName : '';
        fieldValuesCopy.streetNumber = contact ? contact.streetNumber : '';
        fieldValuesCopy.zip = contact ? contact.zip : '';

        setFieldValues(fieldValuesCopy);
        setInitialFieldValues(fieldValuesCopy);
    };

    const onChange = (value, event) => {
        const { name } = event.target;

        setFieldValues({
            ...fieldValues,
            [name]: value,
        });

        const linkNames = ['facebookLink', 'linkedinLink', 'twitterLink', 'webLink'];
        if (linkNames.includes(name)) {
            setEditedLinks({
                ...editedLinks,
                [name]: true,
            });
        }
    };

    const onSelectChange = value => {
        setFieldValues({
            ...fieldValues,
            country: value,
        });
    };

    const onImageChange = async event => {
        const { name } = event.target;
        const file = event.target.files[0];
        setImageToCrop(file);
        setImageToCropName(name);
    };

    const onImageCropDone = file => {
        const url = URL.createObjectURL(file);
        setFieldValues({
            ...fieldValues,
            [imageToCropName]: url,
        });

        if (imageToCropName === 'imageUrl') {
            setFileCroppedAvatar(file);
        } else {
            setFileCroppedCover(file);
        }
        onImageCropCancel();
    };

    const onImageCropCancel = () => {
        setImageToCrop(null);
        setImageToCropName('');
    };

    const getImageToCropMaxSizes = () => {
        if (imageToCropName === 'coverUrl') {
            return {
                width: 1200,
                height: 600,
            };
        }

        if (imageToCropName === 'imageUrl') {
            return {
                width: 600,
                height: 600,
            };
        }

        return {
            width: 0,
            height: 0,
        };
    };

    const validateFiledValues = () => {
        if (!fieldValues.firstName || fieldValues.firstName.length === 0) {
            return false;
        }
        if (!fieldValues.lastName || fieldValues.lastName.length === 0) {
            return false;
        }

        return true;
    };

    const uploadCoverToS3 = async name => {
        if (name === 'cover' && fileCroppedCover) {
            const s3data = await uploadFileToS3(fileCroppedCover);

            setFieldValues({
                ...fieldValues,
                ['coverUrl']: s3data.imageUrl,
            });

            return s3data.imageUrl;
        }

        if (name === 'avatar' && fileCroppedAvatar) {
            const s3data = await uploadFileToS3(fileCroppedAvatar);

            setFieldValues({
                ...fieldValues,
                ['imageUrl']: s3data.imageUrl,
            });

            return s3data.imageUrl;
        }
    };

    const onSave = async () => {
        if (!validateFiledValues()) {
            return;
        }
        setSaving(true);
        let { Country } = user;
        if (fieldValues.country !== 0 && fieldValues.country !== user.countryId) {
            Country = Country || {};
            Country.id = fieldValues.country;
            Country.shouldUpdate = true;
        }

        const contactData = {
            ...user.Contact,
            email: fieldValues.email,
            phone: fieldValues.phone,
            city: fieldValues.city,
            streetName: fieldValues.streetName,
            streetNumber: fieldValues.streetNumber,
            zip: fieldValues.zip,
        };

        const { Links } = user;
        const links = Object.keys(editedLinks)
            .filter(key => editedLinks[key])
            .map(key => {
                const link = findLinkByType(Links, constants.linkTypes[key]);

                return {
                    ...link,
                    type: constants.linkTypes[key],
                    url: fieldValues[key],
                    shouldUpdate: true,
                };
            });

        const cover = await uploadCoverToS3('cover');
        const avatar = await uploadCoverToS3('avatar');

        const data = {
            ...user,
            imageUrl: avatar || fieldValues.imageUrl,
            coverUrl: cover || fieldValues.coverUrl,
            firstName: fieldValues.firstName,
            lastName: fieldValues.lastName,
            jobTitle: fieldValues.jobTitle,
            title: fieldValues.title,
            headline: fieldValues.headline,
            companyName: fieldValues.companyName,
            Country: Country,
            Contact: contactData,
            Links: links,
        };

        await updateFullProfile(data);
        Auth.changeAuthUserData(data);

        if (socket) {
            socket.emit('profileChanged', { id: user.id });
        }
        setSaving(false);
        setShowConfirmSaveIcon(true);
        setTimeout(() => {
            setShowConfirmSaveIcon(false);
            !afterSignUp && onClose();
            afterSignUp && handleSettingsModal();
        }, 500);
    };

    const authUser = Auth.getUser() || {};
    const imageToCropSizes = getImageToCropMaxSizes();

    const warningModalOptions = {
        title: 'Save changes?',
        text: 'Do you want to save the changes you made on your profile?',
        confirmText: 'Save',
        cancelText: 'Don’t save',
        confirm: onSave,
        cancel: onClose,
    };

    const renderForm = (theme, isDesktop) => (
        <>
            {afterSignUp && <StyledComponent.StepsText>{STEPS}</StyledComponent.StepsText>}
            <ProfileForm
                theme={theme}
                isDesktop={isDesktop}
                afterSignUp={afterSignUp}
                fieldValues={fieldValues}
                onImageChange={onImageChange}
                authUser={authUser}
                onChange={onChange}
                countries={countries}
                onSelectChange={onSelectChange}
                setShowSocialLinksModal={setShowSocialLinksModal}
                setShowContactInfoModal={setShowContactInfoModal}
                onSave={onSave}
                importedFields={importedFields}
            />
        </>
    );

    const renderContent = theme => (
        <ProfileContent
            theme={theme}
            fieldValues={fieldValues}
            suggestedContactEmail={user.email}
            setFieldValues={setFieldValues}
            onChange={onChange}
            closeIcon={closeIcon}
            setCloseIcon={setCloseIcon}
            editedLinks={editedLinks}
            setEditedLinks={setEditedLinks}
            onSelectChange={onSelectChange}
            countries={countries}
            isDesktop={isDesktop}
            renderForm={renderForm}
            showSocialLinksModal={showSocialLinksModal}
            showContactInfoModal={showContactInfoModal}
            setShowSocialLinksModal={setShowSocialLinksModal}
            setShowContactInfoModal={setShowContactInfoModal}
            afterSignUp={afterSignUp}
        />
    );

    return (
        <>
            {!loading && (
                <React.Fragment>
                    {isDesktop ? (
                        <ComplexDialog
                            title={afterSignUp ? PROFILE_TITLE : EDIT_PROFILE_TITLE}
                            visible={true}
                            contentStyle={{ padding: 0 }}
                            onClose={onClose}
                            displayBoxShadow
                            options={[
                                {
                                    title: saving ? (
                                        <Loader simpleLoader={true} customColor={'white'} />
                                    ) : showConfirmSaveIcon ? (
                                        getString('savedButton') || 'Saved'
                                    ) : (
                                        getString('saveButton') || 'Save'
                                    ),
                                    variant: 'text',
                                    type: 'save',
                                    icon: showConfirmSaveIcon && 'check_circle_outlined',
                                    onClick: () => onSave(),
                                },
                                {
                                    title: getString('cancel') || 'Cancel',
                                    variant: 'text',
                                    type: 'cancel',
                                    onClick: () => {
                                        if (isEqual(initialFieldValues, fieldValues)) {
                                            onClose();
                                        } else {
                                            setShowWarningModal(true);
                                        }
                                    },
                                },
                            ]}
                        >
                            {renderContent(theme)}
                        </ComplexDialog>
                    ) : (
                        <DialogContainer
                            id="speed-boost"
                            aria-describedby="speed-boost-description"
                            style={{
                                zIndex: '100 !important',
                            }}
                            visible={true}
                            fullPage={true}
                            pageX={12}
                            pageY={12}
                            aria-labelledby="simple-full-page-dialog-title"
                            onHide={() => {}}
                        >
                            <div style={{ margin: '16px' }}>{renderContent(theme)}</div>
                        </DialogContainer>
                    )}
                    {showWarningModal && (
                        <ConfirmDialog
                            visible={true}
                            options={warningModalOptions}
                            onHideDialog={onClose}
                        />
                    )}
                    <ImageCropperModal
                        visible={!!imageToCrop}
                        file={imageToCrop}
                        title={`Change ${
                            imageToCropName === 'coverUrl' ? 'background' : 'profile image'
                        }`}
                        onDone={onImageCropDone}
                        onClose={onImageCropCancel}
                        maxHeight={imageToCropSizes.height}
                        maxWidth={imageToCropSizes.width}
                        aspectRatio={imageToCropName === 'imageUrl' ? 1 : 2}
                        roundedCrop={imageToCropName === 'imageUrl'}
                    />
                </React.Fragment>
            )}
        </>
    );
};

export default ProfileModal;
