import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getObjectPage } from './services/objectPage';
import { getObjectClassWithId } from '../../services/api/db';

const ObjectPage = props => {
    useEffect(() => {
        getObjectInfo();
    }, [props.pageId]);

    useEffect(() => {
        if (props.match.isExact) {
            getObjectInfo();
        }
    }, [props.match.isExact]);

    const getObjectInfo = () => {
        getObjectPage(props.pageId, (err, result) => {
            if (result && result.params && result.params.objectId) {
                const objectId = result.params.objectId;
                getObjectClassWithId(objectId, (err, objectClass, objectItem) => {
                    const linkTo = `${props.rootRoute}/page/object/${props.pageId}/${objectClass}/${objectId}`;
                    props.history.replace(linkTo);
                });
            }
        });
    };

    return <React.Fragment key={'link_' + props.location.key}></React.Fragment>;
};
export default withRouter(ObjectPage);
