import React from 'react';
import styled from 'styled-components';
import Auth from '../../../services/api/auth';
import { ImageAvatar } from '../../../components/Icons';
import { getString } from '../../../services/api/store';

const Wrapper = styled.div`
    background-color: #f5f5f5;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
`;

const Content = styled.div`
    display: flex;
    padding: 24px;
    width: 500px;
`;

const FakeInput = styled.div`
    background-color: white;
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: grey;
    display: flex;
    padding: 0 16px;
    align-items: center;
    margin-left: 16px;
    width: 100%;
    transition: border 0.2s;

    &:hover {
        cursor: text;
        border: 1px solid #a3a3a3;
    }
`;

const NewPostInput = ({ onClick }) => {
    const user = Auth.getUser() || {};
    const inputText = getString('socialWallPostPlaceholder') || 'Share your thoughts…';

    return (
        <Wrapper>
            <Content>
                <ImageAvatar imageId={user.imageUrl || user.image} avatarType={'persons'} />
                <FakeInput onClick={onClick}>{inputText}</FakeInput>
            </Content>
        </Wrapper>
    );
};

export default NewPostInput;
