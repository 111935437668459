import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Checkbox } from 'react-md';
import { PanoramaFishEyeFontIcon, RadioButtonCheckedFontIcon } from '@react-md/material-icons';

import AnalyticsService from '../../../features/analytics/services';
import {
    Container,
    InteractivityCell,
    InteractivityGrid,
    SectionText,
    SectionTitle,
} from './style';
import {
    getVirtualEventPollSetsByEventAndItemIds,
    submitPollAnswer,
} from '../../../services/api/eureka';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';
import { getString } from '../../../services/api/store';
import { makeItem } from '../../../services/api/data';
import { useGlobalState } from '../../../utils/container';
import Button, { buttonTypes } from '../../common/Button';

const NewWrapper = styled.div`
    float: left;
    color: #ffffff;
    background-color: ${props => props.background};
    text-align: center;
    font-size: 10px;
    font-family: 'Cabin', sans-serif;
    font-weight: bold;
    width: 30px;
    margin-right: 4px;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
    height: 56px !important;
    border-radius: 8px;
    cursor: pointer;
    display: flex;

    span {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px !important;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
    }

    i {
        margin-top: -2px;
        color: ${props => (props.color ? props.color : '#1fa294')};
    }

    &:hover {
        background: #f4f5f7;
    }
`;

const VoteCard = props => {
    const VOTE_TITLE = getString('votingTitle') || 'Vote';
    const SUBMIT_BUTTON = getString('voteSubmitButton') || 'Submit vote';
    const NEW_LABEL = getString('voteNewLabel') || 'New';

    const stateCtx = useGlobalState();
    const { trackAchievement } = useAchievementActions();
    const { objectId, objectClass } = props.match.params;
    const { socket } = stateCtx;

    const [disabled, setDisabled] = useState(false);
    const [joinedPolls, setJoinedPolls] = useState([]);
    const [itemId, setItemId] = useState(null);
    const [externalObjectId, setExternalObjectId] = useState(null);
    const [poll, setPoll] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [pollOptions, setPollOptions] = useState([]);
    const [newLabel, setNew] = useState(false);

    // const poll = data && data[0] && data[0].state === 'open' && data[0].Polls.find(p => p.active);
    useEffect(() => {
        makeItem(objectId, objectClass, async (err, objectItem) => {
            if (err) {
                console.log(err);
            }
            if (objectItem && objectItem.id) {
                setItemId(objectItem.id);
                setEventId(props.localAppState.eventId);
                await loadData(props.localAppState.eventId, objectItem.id);
            }
        });

        if (socket) {
            socket.on(`pollRefresh_${itemId}`, () => loadData(eventId, itemId));
        }
    }, [objectId, itemId]);

    const loadData = async (eventId, itemId) => {
        const pollSets = await getVirtualEventPollSetsByEventAndItemIds(eventId, itemId);

        if (pollSets && pollSets[0] && pollSets[0].state === 'open') {
            const poll = pollSets[0].Polls.find(p => p.active);

            setPoll(poll);
            setExternalObjectId(pollSets[0].ExternalObjectId);

            if (poll) {
                initPollOptions(poll.PollOptions);
            }
        }
    };

    useEffect(() => {
        if (joinedPolls.indexOf(externalObjectId) > -1) {
            return () => {};
        }

        setJoinedPolls([...joinedPolls, externalObjectId]);

        if (socket) {
            socket.emit('joinPolls', { objectId: externalObjectId });

            socket.on('updatePollSet', pollData => {
                if (pollData.action === 'addVote') {
                    // increment pollData.pollOptionId with 1 if we display number of votes
                } else if (pollData.action === 'updatePollSet') {
                    loadData(eventId, itemId);
                }
            });
        }

        return () => {
            if (socket) {
                socket.removeAllListeners('updatePollSet');
            }
        };
    }, [externalObjectId]);

    useEffect(() => {
        isSubmitDisabled();
    }, [poll]);

    useState(() => {});

    const isSubmitDisabled = () => {
        const answeredPolls = JSON.parse(localStorage.getItem('answeredPolls')) || [];
        if (poll && answeredPolls.find(pollId => pollId === poll.id)) {
            setNew(false);
            setDisabled(true);
            return true;
        }
        setNew(true);
        setDisabled(false);
        return false;
    };

    const initPollOptions = pollOptions => {
        pollOptions = pollOptions
            .sort((o1, o2) => o1.order - o2.order)
            .map(option => ({
                ...option,
                checked: false,
            }))
            .filter(option => !!option.text);

        setPollOptions(pollOptions);
    };

    const submitVote = async () => {
        const checkedOption = pollOptions.find(option => option.checked);

        if (!checkedOption) {
            return;
        }

        const sessionId = props.match.params.objectId || props.match.params.timeslotId;
        AnalyticsService.addSample('vote', checkedOption.text, sessionId);

        trackAchievement(AchievementType.VOTE, sessionId);

        const { id } = checkedOption;
        await submitPollAnswer({ PollOptionId: id });

        const answeredPolls = JSON.parse(localStorage.getItem('answeredPolls')) || [];
        answeredPolls.push(poll.id);
        localStorage.setItem('answeredPolls', JSON.stringify(answeredPolls));
        initPollOptions(pollOptions)
        isSubmitDisabled();

        const { socket } = stateCtx;

        if (socket) {
            socket.emit('pollAnswer', { objectId: eventId });
        }
    };

    const VoteButton = () => (
        <Button
            type={buttonTypes.GREY_LONG}
            background={props.theme.primary}
            text={disabled ? 'Vote submitted' : 'Submit vote'}
            enabled={!disabled}
            onClick={submitVote}
            style={{ marginTop: '12px' }}
        />
    );

    const onChange = (checked, index) => {
        if (!checked || disabled) {
            return;
        }

        const optionsClone = [
            ...pollOptions.map(option => ({
                ...option,
                checked: false,
            })),
        ];
        optionsClone[index].checked = true;
        setPollOptions(optionsClone);
    };

    if (!poll) {
        return <></>;
    }

    return (
        <InteractivityGrid>
            <InteractivityCell size={1} key="q&a">
                <Container>
                    <SectionTitle secondary={props.theme.secondary}>{VOTE_TITLE}</SectionTitle>
                    <TitleContainer>
                        {newLabel && (
                            <NewWrapper background={props.theme.contrast}>{NEW_LABEL}</NewWrapper>
                        )}
                        <SectionText>{poll && poll.title}</SectionText>
                    </TitleContainer>
                    {pollOptions &&
                        pollOptions.map((option, index) => (
                            <StyledCheckbox
                                id={`poll-option-${index}`}
                                name={`poll-option-${index}`}
                                key={`poll-option-${index}`}
                                label={option.text}
                                checked={option.checked}
                                checkedCheckboxIcon={<RadioButtonCheckedFontIcon />}
                                uncheckedCheckboxIcon={<PanoramaFishEyeFontIcon />}
                                onChange={checked => onChange(checked, index)}
                                onClick={checked => onChange(checked, index)}
                                defaultChecked={false}
                                disabled={disabled}
                                color={props.theme.primary}
                            />
                        ))}
                    <VoteButton />
                </Container>
            </InteractivityCell>
        </InteractivityGrid>
    );
};

export default withRouter(VoteCard);
