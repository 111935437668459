import React from 'react';

import InstitutionListItem from './Institution';
import PersonListItem from './Person';
import PlaceListItem from './Place';
import SimpleListItem from './Simple';
import TimeslotListItem from './Timeslot';
import UserListItem from './User';

const ObjectListItem = ({ item, type, childrenId, cardView, displayIconsForLinks, icon }) => {
    const renderItem = () => {
        switch (type) {
            case 'appointment':
                return <TimeslotListItem key={`${type}_${item.id.toString()}`} timeslot={item} />;
            case 'institution':
                return <InstitutionListItem institution={item} cardView={cardView} />;
            case 'person':
                return <PersonListItem person={item} cardView={cardView} />;
            case 'role':
                return <PersonListItem person={item} cardView={cardView} />;
            case 'place':
                return <PlaceListItem place={item} />;
            case 'timeslot':
                return <TimeslotListItem timeslot={item} childrenId={childrenId} />;
            case 'programelement':
                return <TimeslotListItem timeslot={{ ...item, type }} childrenId={childrenId} />;
            case 'user':
                return <UserListItem user={item} />;
            default:
                return (
                    <SimpleListItem
                        item={item}
                        type={type}
                        classifiercolor={item.params ? item.params.color : ''}
                        displayIconsForLinks={displayIconsForLinks}
                        icon={icon}
                    />
                );
        }
    };

    return renderItem();
};

export default ObjectListItem;
