import React from 'react';

import { ImageAvatar } from '../../Icons';
import { formatLastMessageDate } from '../../../services/api/data';
import {
    ListItemStyled,
    listContentStyle,
    PrimaryTitle,
    Timestamp,
    SecondaryTitle,
    SecondaryTitleMessage,
} from './styles';

const Group = ({ conversation }) => {
    const { lastMessage, title } = conversation;
    if (!lastMessage || !lastMessage.participant) {
        return;
    }
    const displayName =
        lastMessage.participant.displayName ||
        `${lastMessage.participant.firstName} ${lastMessage.participant.lastName}`;
    const messageTimestamp = formatLastMessageDate(lastMessage.timestamp);

    const Secondary = ({ name, message, timestamp }) => (
        <SecondaryTitle>
            <SecondaryTitleMessage>
                <strong>{`${name}: `}</strong>
                {message}
            </SecondaryTitleMessage>
            <Timestamp>{timestamp}</Timestamp>
        </SecondaryTitle>
    );

    return (
        <ListItemStyled
            key={'conversation_group_' + conversation.id}
            contentStyle={listContentStyle}
            primaryText={<PrimaryTitle>{title}</PrimaryTitle>}
            secondaryText={
                <Secondary
                    name={displayName}
                    message={lastMessage.txt}
                    timestamp={messageTimestamp}
                />
            }
            leftAvatar={<ImageAvatar avatarType={'conversation'} />}
            threeLines
        />
    );
};

export default Group;
