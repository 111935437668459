import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DialogContainer, TextField, FontIcon } from 'react-md';
import { connect } from 'react-redux';

import { getPost } from '../selectors';
import { createOrUpdatePost as createOrUpdatePostAction } from '../actions';
import styled from 'styled-components';
import UploadButton from '../../../components/General/UploadButton';
import { uploadFileToS3 } from '../../../services/api/eureka';
import Loader from '../../../components/General/Loader';
import ThemeContext from '../../../components/Theme/ThemeContext';
import Button, { buttonTypes } from '../../common/Button';
import { getString } from '../../../services/api/store';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';

const StyledTextField = styled(TextField)`
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    align-content: center;
    padding: 12px 12px 16px 12px;
    margin-bottom: 16px;

    input {
        margin-top: 0;
        width: 100%;
    }
    hr {
        display: none;
    }

    .md-text-field-inline-indicator {
        top: 0;
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    position: relative;
`;

const Image = styled.img`
    width: 100%;
    height: auto;
`;

const RemoveIconWrapper = styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    top: 10px;
    right: 10px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    .md-icon {
        color: white;
    }
`;

const INITIAL_IMAGE = {
    file: null,
    url: null,
    isNew: false,
};

const PostEditor = ({ post, createOrUpdatePost, goBack }) => {
    const [text, setText] = useState(null);
    const [image, setImage] = useState(INITIAL_IMAGE);
    const [loading, setLoading] = useState(false);
    const [resettingImageUpload, setResettingImageUpload] = useState(false);
    const { trackAchievement } = useAchievementActions();

    useEffect(() => {
        if (post && post.id) {
            setImage({
                file: null,
                url: post.image,
                isNew: false,
            });
            setText(post.message);
        }
    }, [post]);

    const onTextChange = value => {
        setText(value);
    };

    const onImageChange = event => {
        const file = event.target.files.length && event.target.files[0];

        if (!file) {
            return;
        }

        setImage({
            file,
            url: URL.createObjectURL(file),
            isNew: true,
        });
    };

    const uploadImage = async () => {
        if (!image || !image.file || !image.isNew) {
            return image ? image.url : null;
        }

        const imageUploadInfo = await uploadFileToS3(image.file);

        return imageUploadInfo.imageUrl;
    };

    const removeImage = () => {
        setImage(null);
        // Force the input file element to rerender, so you can re-upload the same image
        setResettingImageUpload(true);
    };

    useEffect(() => {
        if (resettingImageUpload) {
            setResettingImageUpload(false);
        }
    }, [resettingImageUpload]);

    const sendPost = async () => {
        if (text && !loading) {
            setLoading(true);
            const imageUrl = await uploadImage();
            const newPost = {
                ...post,
                message: text,
                image: imageUrl,
            };
            createOrUpdatePost(newPost, () => {
                setLoading(false);
                goBack();
            });

            if (newPost.id) {
                return;
            }

            trackAchievement(AchievementType.SOCIAL_POST);

            if (imageUrl) {
                trackAchievement(AchievementType.SOCIAL_POST_WITH_PHOTO);
            }
        }
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <DialogContainer
                    id="post-dialog"
                    visible={true}
                    onHide={() => {}}
                    actions={[
                        <Button
                            onClick={goBack}
                            type={buttonTypes.GREY}
                            text={getString('cancel') || 'Cancel'}
                            style={{ marginRight: '8px' }}
                        />,
                        <Button
                            onClick={sendPost}
                            enabled={text}
                            type={buttonTypes.GREEN}
                            background={theme.primary}
                            text={post ? 'Save changes' : 'Post'}
                        />,
                    ]}
                    title={`${post ? 'Edit' : 'Add'} post`}
                    width={580}
                >
                    {image && image.url && (
                        <ImageWrapper>
                            <Image src={image.url} alt="Post" />
                            <RemoveIconWrapper onClick={removeImage}>
                                <FontIcon>delete_outline</FontIcon>
                            </RemoveIconWrapper>
                        </ImageWrapper>
                    )}
                    <StyledTextField
                        type="text"
                        value={text}
                        onChange={onTextChange}
                        rows={3}
                        maxRows={6}
                    />
                    {!resettingImageUpload && (
                        <UploadButton
                            id="social-post-upload-button"
                            text={`${image && image.url ? 'Change' : 'Add'} photo`}
                            icon="camera_alt"
                            accept="image/*"
                            color={theme.primary}
                            onChange={onImageChange}
                        />
                    )}
                    {loading && <Loader />}
                </DialogContainer>
            )}
        </ThemeContext.Consumer>
    );
};

PostEditor.propTypes = {
    post: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        message: PropTypes.string,
        image: PropTypes.string,
    }),
    createOrUpdatePost: PropTypes.func,
};

PostEditor.defaultProps = {
    post: {
        comments: [],
        id: 0,
        message: '',
        image: '',
    },
    createOrUpdatePost: () => {},
};

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;
    return {
        post: id ? getPost(id, state) : null,
    };
};

export default connect(mapStateToProps, {
    createOrUpdatePost: createOrUpdatePostAction,
})(PostEditor);
