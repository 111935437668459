import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Attendees from '../userInteraction/attendees/Attendees';
import Chat from '../userInteraction/Chat';
import QuestionsAndAnswers from '../userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import { getAttendeesList } from '../../services/attendees';
import Auth from '../../../../services/api/auth';
import { useGlobalState } from '../../../../utils/container';
import * as palette from '../../../../components/General/Variables';
import Tabs from '../../../../components/Tabs';
import eventBus from '../../../../utils/eventBus';

const Container = styled.div`
    background-color: #fff;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    padding: 4px 16px 0 16px;
`;

const ATTENDEES_LIMIT = 12;

const options = ['chat', 'qa', 'attendees'];

const UserInteraction = ({ timeslot, userData, sessionType, match }) => {
    const stateCtx = useGlobalState();
    const { socket, virtualEventSession } = stateCtx;
    const {
        showChat,
        showQA,
        showAttendees,
        ExternalObjectId: externalObjectId,
    } = virtualEventSession;
    const [section, setSection] = useState('');
    const [attendees, setAttendees] = useState([]);
    const [totalAttendees, setTotalAttendees] = useState(0);
    const [newQuestionsCount, setNewQuestionsCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const [unreadMessageNotifications, setUnreadMessageNotifications] = useState(0);
    const newQuestionsCountRef = useRef(newQuestionsCount);
    const newQuestionsIdsRef = useRef([]);

    const sessionId = window.location.pathname.split('/')[2];

    useEffect(() => {
        getAttendees();

        if (socket) {
            socket.on(`refreshAttendees_${timeslot.id}`, () => {
                setPage(1);
                getAttendees();
            });

            socket.on(`messageNotification_${userData.id}`, data => {
                if (data.roomId !== sessionId) {
                    return;
                }
                if (section !== 'chat') {
                    setUnreadMessageNotifications(val => val + 1);
                } else if (unreadMessageNotifications > 0) {
                    setUnreadMessageNotifications(0);
                }
            });
        }

        return () => {
            if (socket) {
                socket.removeAllListeners(`refreshAttendees_${timeslot.id}`);
                socket.removeAllListeners(`messageNotification_${userData.id}`);
            }
        };
    }, []);

    useEffect(() => {
        setSection(value => {
            const valueMap = {
                chat: showChat,
                qa: showQA,
                attendees: showAttendees,
            };

            // if the current value is available, keep it
            if (valueMap[value]) {
                return value;
            }

            // otherwise change it
            return options.find(opt => {
                if (opt !== value && valueMap[opt]) {
                    return opt;
                }

                return false;
            });
        });
    }, [virtualEventSession]);

    useEffect(() => {
        if (section === 'chat' && unreadMessageNotifications > 0) {
            setUnreadMessageNotifications(0);
        }
    }, [section]);

    useEffect(() => {
        const joinQuestions = () => {
            if (socket) {
                socket.emit('joinQuestions', {
                    objectId: externalObjectId,
                });
            }
        };
        const updateQuestions = async questionData => {
            if (questionData.action === 'updateQuestion') {
                const { question } = questionData;

                // if question is not visible => it becomes visible now
                if (
                    question.visible &&
                    !newQuestionsIdsRef.current.find(id => id === question.id)
                ) {
                    newQuestionsIdsRef.current.push(question.id);
                    increaseNewQuestionsCount();
                }

                if (
                    !question.visible &&
                    newQuestionsIdsRef.current.find(id => id === question.id)
                ) {
                    newQuestionsIdsRef.current = newQuestionsIdsRef.current.filter(
                        id => id !== question.id,
                    );
                    decreaseNewQuestionsCount();
                }
            }
        };

        (async () => {
            if (!externalObjectId) {
                return () => {};
            }

            if (!socket) {
                return;
            }

            socket.on('connect', joinQuestions);

            joinQuestions();

            socket.on('updateQuestions', updateQuestions);
        })();

        return () => {
            if (socket) {
                socket.off('connect', joinQuestions);
                socket.off('updateQuestions', updateQuestions);
            }
        };
    }, [externalObjectId, newQuestionsCount]);

    const setNewQuestionsCountExtra = count => {
        newQuestionsCountRef.current = count;
        setNewQuestionsCount(count);
    };

    const updateAttendeeList = (attendees, page) => {
        setAttendees(attendees);
        setIsFetching(false);
        setPage(page);
    };

    const getAttendees = async () => {
        if (Auth.isUserAuthenticated() && !isFetching) {
            const attendees = await getAttendeesList(
                match.params.timeslotId,
                ATTENDEES_LIMIT,
                page,
            );
            setTotalAttendees(attendees.count);
            setAttendees(sortAttendees(attendees.rows));
            eventBus.emit('updateAttendeesList', sortAttendees(attendees.rows));
            setIsFetching(false);
        }
    };

    const sortAttendees = attendees => {
        const sortetAttendeeList = attendees.sort((attendee1, attendee2) => {
            const name1 = `${attendee1.lastName} ${attendee1.firstName}`;
            const name2 = `${attendee2.lastName} ${attendee2.firstName}`;
            return name1.toLowerCase().localeCompare(name2.toLowerCase());
        });
        return sortetAttendeeList;
    };

    const increaseNewQuestionsCount = () => {
        setNewQuestionsCountExtra(newQuestionsCountRef.current + 1);
    };

    const decreaseNewQuestionsCount = () => {
        newQuestionsCountRef.current > 0 &&
            setNewQuestionsCountExtra(newQuestionsCountRef.current - 1);
    };

    const tabsData = [];
    const conversation = { roomId: timeslot.id };

    if (showChat) {
        tabsData.push({
            id: 'user-interaction-chat',
            label: 'Chat',
            labelIcon: section === 'chat' ? palette.ICON_CHAT : palette.ICON_CHAT_OUTLINE,
            chip:
                section !== 'chat' && unreadMessageNotifications > 0 && unreadMessageNotifications,
            onClick: () => {
                setSection('chat');
                newQuestionsIdsRef.current = [];
            },
            content: () => <Chat conversation={conversation} />,
        });
    }
    if (sessionType !== 'on-demand' && showQA) {
        tabsData.push({
            id: 'user-interaction-qa',
            label: 'Q&A',
            labelIcon: section === 'qa' ? palette.QA_ICON : palette.QA_ICON_OUTLINE,
            chip: section !== 'qa' && newQuestionsCount > 0 && newQuestionsCount,
            onClick: () => setSection('qa'),
            content: () => (
                <QuestionsAndAnswers
                    setNewQuestionsCount={count => setNewQuestionsCountExtra(count)}
                    newQuestionIds={newQuestionsIdsRef.current}
                />
            ),
        });
    }

    if (sessionType !== 'on-demand' && showAttendees) {
        tabsData.push({
            id: 'user-interaction-attendees',
            label: totalAttendees,
            labelIcon: section === 'attendees' ? palette.PEOPLE_ICON : palette.PEOPLE_ICON_OUTLINE,
            onClick: () => {
                setSection('attendees');
                newQuestionsIdsRef.current = [];
            },
            checkContentOnly: true,
            content: () => (
                <Attendees
                    sortedAttendees={attendees}
                    totalAttendees={totalAttendees}
                    timeslot={timeslot}
                    limit={ATTENDEES_LIMIT}
                    updateAttendeeList={updateAttendeeList}
                    externalIsFetching={tabsData.length === 1 ? true : isFetching}
                    externalPage={page}
                />
            ),
        });
    }

    return (
        <Container socket={socket}>
            <Tabs data={tabsData} containerHeight={'calc(100% - 64px)'} useMemo={true} />
        </Container>
    );
};

export default withRouter(UserInteraction);
