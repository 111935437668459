import React from 'react';

import Routes from '../../components/Routes';
import { APP_VERSION } from '../../config';
import SignOutDialog from '../../components/Dialog/SignOutDialog';
import ConfirmDialog from '../../components/Dialog/ConfirmDialog';
import TimezoneHandlers from '../../scenes/Timezone/containers';
import * as AppStyle from '../style/navigationStyle';

const NavigationContent = ({
    isSidepanelOpened,
    routes,
    childProps,
    navigation,
    setTitle,
    location,
    disableScroll,
    installationId,
    handleSidepanel,
    hideConfirmDialog,
    confirmDialogVisible,
    showInstallMobileAppModal,
    mobileDeepLink,
    setShowInstallMobileAppModal,
    branchIoDomain,
    eventId,
    navigationType,
    visibleFullSideMenu,
}) => (
    <React.Fragment>
        <AppStyle.Hidden>{APP_VERSION}</AppStyle.Hidden>
        <Routes
            ref={routes}
            childProps={childProps}
            nav={navigation}
            setTitle={setTitle}
            disableScroll={disableScroll}
            location={location}
            installationId={installationId}
            handleSidepanel={handleSidepanel}
            isSidepanelOpened={isSidepanelOpened}
            eventId={eventId}
            navigationType={navigationType}
            visibleFullSideMenu={visibleFullSideMenu}
        />
        {confirmDialogVisible && <SignOutDialog hideConfirmDialog={hideConfirmDialog} />}
        <ConfirmDialog
            visible={showInstallMobileAppModal}
            options={{
                title: 'Install the mobile app',
                text: 'Get the app for a better mobile experience',
                cancelText: 'Not now',
                confirmText: 'Install',
                confirmIcon: 'get_app',
                confirm: () => mobileDeepLink(`https://${branchIoDomain}`),
                cancel: () => setShowInstallMobileAppModal(false),
            }}
            onHideDialog={() => setShowInstallMobileAppModal(false)}
        />
        <TimezoneHandlers />
    </React.Fragment>
);

export default NavigationContent;
