import get from 'lodash/get';
import { getLocalAppStateAsync } from '../../../../services/api/db';

export const getDailyProgrammePath = async () => {
    const appState = await getLocalAppStateAsync();
    const { navigation } = appState;

    const dailyProgrammePage = navigation.find(navItem => navItem.type === 'programme') || {};

    return dailyProgrammePage.to;
};

export const goBackFromVirtualSession = async (history, sessionId) => {
    const prevPath = get(history, 'location.state.prevPath', null);

    if (prevPath) {
        if (prevPath.includes('/page/')) {
            history.forceBack = true;
        }
        return history.push(prevPath);
    }

    const dailyProgrammePath = await getDailyProgrammePath();

    if (dailyProgrammePath) {
        return history.push(`${dailyProgrammePath}/timeslot/${sessionId}`);
    }

    // This means that virtual session page was opened externally from a link and has not any previous pages
    if (history.length < 2) {
        return history.push('/');
    }

    history.goBack();
};
