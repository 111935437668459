import React, { useState } from 'react';
import {
    default as getString,
    ERROR_MESSAGE_DEFAULT,
    ERROR_MESSAGE_WRONG_PASSWORD,
} from '../../../components/SignIn/strings';
import {
    ButtonsContainer,
    ButtonStyled,
    Card,
    OAuthPasswordImage,
    StyledTextField,
    TextInputContainer,
} from '../style/signIn';
import { SIGN_IN_BUTTON } from '../constants/strings';

const OAuthPassword = ({ auth, theme, authenticate, error, onKeyDown, isModal }) => {
    const {
        organizationLogoUrl,
        organizationColor,
        registerButtonTitle,
        registerButtonLink,
        forgotPasswordButtonTitle,
        forgotPasswordButtonLink,
        organization,
    } = auth;
    const usernameLabelText = 'Username';
    const passwordLabelText = 'Password';

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isValid, setIsValid] = useState(false);

    const updatePassword = value => {
        if (username.length && value.length) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
        setPassword(value);
    };

    const updateUsername = value => {
        if (password.length && value.length) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
        setUsername(value);
    };

    const getInputs = () => {
        if (organization && organization === 'KENES') {
            return (
                <TextInputContainer>
                    <StyledTextField
                        value={password}
                        required
                        error={errorMessage.length > 0}
                        onChange={pass => {
                            updatePassword(pass);
                        }}
                        onKeyDown={e => {
                            if (isValid) {
                                onKeyDown(e, () => {
                                    authenticate(username, password);
                                });
                            }
                        }}
                        id="oauth_password_password"
                        label="E-mail address"
                        errorText={errorMessage || 'E-mail address is required'}
                    />
                    <StyledTextField
                        id="oauth_password_username"
                        required
                        name="username"
                        error={username.length > 0 && !isValid}
                        value={username}
                        label="Registration ID"
                        onChange={username => {
                            updateUsername(username);
                        }}
                        type="password"
                        errorText={'Registration ID is required'}
                    />
                </TextInputContainer>
            );
        }

        return (
            <TextInputContainer>
                <StyledTextField
                    id="oauth_password_username"
                    required
                    name="username"
                    error={username.length > 0 && !isValid}
                    value={username}
                    label={usernameLabelText}
                    onChange={username => {
                        updateUsername(username);
                    }}
                    errorText={TEXT_ERROR_REQUIRED_USERNAME}
                />
                <StyledTextField
                    value={password}
                    required
                    error={errorMessage.length > 0}
                    onChange={pass => {
                        updatePassword(pass);
                    }}
                    onKeyDown={e => {
                        if (isValid) {
                            onKeyDown(e, () => {
                                authenticate(username, password);
                            });
                        }
                    }}
                    id="oauth_password_password"
                    label={passwordLabelText}
                    type="password"
                    errorText={errorMessage || TEXT_ERROR_REQUIRED_PASSWORD}
                />
            </TextInputContainer>
        );
    };

    const openInNewTab = url => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const TEXT_DIALOG_SIGNIN = getString('profileSignInScreenTitle') || SIGN_IN_BUTTON;
    const TEXT_ERROR_REQUIRED_USERNAME = `${usernameLabelText} is required`;
    const TEXT_ERROR_WRONG_PASSWORD =
        getString('passwordModalError') || ERROR_MESSAGE_WRONG_PASSWORD;
    const TEXT_ERROR_REQUIRED_PASSWORD = `${passwordLabelText} is required`;
    const TEXT_ERROR_DEFAULT = getString('loginSignInErrorDialogMessage') || ERROR_MESSAGE_DEFAULT;
    let errorMessage = '';

    if (error) {
        if (error.type) {
            switch (error.type) {
                case 'password':
                    errorMessage = TEXT_ERROR_WRONG_PASSWORD;
                    break;
                default:
                    errorMessage = TEXT_ERROR_DEFAULT;
                    break;
            }
        } else if (error.message) {
            errorMessage = TEXT_ERROR_DEFAULT;
        }
    }

    return (
        <Card largeCard={true} isModal={isModal}>
            {organizationLogoUrl && (
                <OAuthPasswordImage
                    src={organizationLogoUrl}
                    primary={theme.primary}
                    organizationColor={organizationColor}
                />
            )}
            {getInputs()}
            <ButtonsContainer primarycolor={theme.primary}>
                <ButtonStyled
                    flat
                    primary
                    onClick={() => {
                        authenticate(username, password);
                    }}
                    data-qa="button-custom-sign-in"
                >
                    {TEXT_DIALOG_SIGNIN}
                </ButtonStyled>
                {registerButtonLink && (
                    <ButtonStyled
                        flat
                        primary
                        onClick={() => {
                            openInNewTab(registerButtonLink);
                        }}
                        data-qa="button-custom-register"
                    >
                        {registerButtonTitle}
                    </ButtonStyled>
                )}
                {forgotPasswordButtonLink && (
                    <ButtonStyled
                        flat
                        maincolor={organizationColor || theme.primary}
                        primary
                        onClick={() => {
                            openInNewTab(forgotPasswordButtonLink);
                        }}
                        data-qa="button-custom-forgot-password"
                    >
                        {forgotPasswordButtonTitle}
                    </ButtonStyled>
                )}
            </ButtonsContainer>
        </Card>
    );
};

export default OAuthPassword;
