import { getAllSamples, deleteSamples, saveSample } from '../api/db';
import { createInstallation } from '../api/index';
import { sendSamples } from '../api';
import { getLocalAppState } from '../../../services/api';
const { detect } = require('detect-browser');

const SAVE_SAMPLES_INTERVAL = 15 * 60000; // 15 minutes

class AnalyticsService {
    sendSamplesInterval = null;

    configureDefaultInstallation = () => {
        const browser = detect();
        const devModel = browser.name;
        const osName = browser.os;

        return {
            osName,
            devModel,
        };
    };

    sendSamplesToBackend = async timestamp => {
        getAllSamples(async (err, analytics) => {
            if (!analytics.length) {
                return;
            }

            analytics = analytics.map(s => ({ ...s, timestamp: parseInt(s.timestamp, 10) }));
            const latestTimestamp =
                timestamp ||
                Math.max.apply(
                    Math,
                    analytics.map(o => o.created),
                );

            try {
                const response = await sendSamples(analytics);

                if (response.status === 200) {
                    deleteSamples(latestTimestamp);
                }
            } catch (error) {
                console.log({ error });
            }
        });
    };

    startSendSamplesInterval = () => {
        if (this.sendSamplesInterval) {
            clearInterval(this.sendSamplesInterval);
        }
        this.sendSamplesInterval = setInterval(this.sendSamplesToBackend, SAVE_SAMPLES_INTERVAL);
    };

    setup = async (configuratorUrl, id) => {
        const currentInstallation = this.configureDefaultInstallation();
        const installation = { ...currentInstallation };
        const timestamp = new Date().getTime();

        try {
            const newInstallation = await createInstallation(configuratorUrl, id, installation);

            if (newInstallation.status === 'add') {
                const installationData = this.configureDefaultInstallation();
                const argumentsObj = {
                    ownerId: id,
                    value: JSON.stringify(installationData),
                };

                const sample = {
                    arguments: JSON.stringify(argumentsObj),
                    object: JSON.stringify(installationData),
                    name: 'eventInstallation',
                    event: id,
                    timestamp: timestamp,
                    installation: newInstallation.id,
                };
                const analytics = [];
                analytics.push(sample);
                await sendSamples(analytics, newInstallation.id, id, configuratorUrl);
            }

            this.sendSamplesToBackend(timestamp);
            this.startSendSamplesInterval();
            return newInstallation.id;
        } catch (error) {
            console.log('error', error);
        }
    };

    addSample = async (name, object, objectId, installationId = null) => {
        getLocalAppState(async (err, appState) => {
            if (appState) {
                if (!err) {
                    try {
                        const installation = installationId || appState.installationId;
                        const eventId = appState.id;
                        await saveSample(name, object, eventId, objectId, installation);
                    } catch (error) {
                        console.log('error', error);
                    }
                }
            }
        });
    };
}

export default new AnalyticsService();
