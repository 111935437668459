import React, { useEffect } from 'react';

import get from 'lodash/get';
import styled from 'styled-components';

import SearchList from './list';
import SearchResultsContext from '../../../components/SearchResults/SearchResultsContext';

const Scrollable = styled.section.attrs({
    className: '',
})`
    height: 100%;
    overflow: auto;
    margin-top: 8px;
`;

const ListComponent = ({ pageId, rootRoute, data, updateTitle, childrenId }) => {
    useEffect(() => {
        const toolbarTitle = get(data[0], 'type.plural', '');
        updateTitle(toolbarTitle);
    }, [data]);

    return (
        <Scrollable>
            <SearchList
                pageId={pageId}
                rootRoute={rootRoute}
                results={data}
                hiddenSectionTitle
                childrenId={childrenId}
            />
        </Scrollable>
    );
};

const SearchResults = ({ pageId, rootRoute, updateTitle, childrenId }) => {
    return (
        <SearchResultsContext.Consumer>
            {({ resultsData }) => {
                if (resultsData.length) {
                    return (
                        <ListComponent
                            data={resultsData}
                            pageId={pageId}
                            rootRoute={rootRoute}
                            updateTitle={updateTitle}
                            childrenId={childrenId}
                        />
                    );
                }
                return null;
            }}
        </SearchResultsContext.Consumer>
    );
};

export default SearchResults;
