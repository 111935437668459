import React from 'react';
import styled from 'styled-components';
import { getString } from '../../services/api/store';
import { InteractivityContext } from '../Interactivity';
import AuthUserContext from '../Session/AuthUserContext';
import ThemeContext from '../Theme/ThemeContext';
import { SelectField, FontIcon } from 'react-md';

//container for the buttons all / my program
const ViewTypeButtons = styled.div`
    ${props =>
        props.hasFloat &&
        `
        float: ${props.alignment || 'right'};
    `};
    display: inline-block;
    left: 334px;
    position: absolute;
`;

const StyledSelectField = styled(SelectField)`
    width: fit-content;
    height: 40px;

    border-radius: 50px !important;
    border: 1px solid #dde1e5 !important;
    color: rgba(0, 0, 0, 0.87) !important;

    input {
        margin-top: 0;
    }

    ${props =>
        props.value !== 'all' &&
        `
     .md-icon {
        margin-left: -10px;
    }
    `}

    hr {
        display: none;
    }

    #program-type-select-toggle {
        padding: 0 16px;
        height: 100%;
    }

    .md-text-field {
        text-transform: capitalize;
    }

    .md-text-field,
    .md-tile-text--primary {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #000000;
    }

    ul {
        border-radius: 8px !important;
        box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.1);
        margin-top: 12px;
        padding: 8px;
        width: fit-content !important;

        li {
            div {
                border-radius: 8px;

                &:hover {
                    background-color: #ebedf0;
                }
            }
        }
    }

    #program-type-select-menu-options {
        transform: translateY(20px);
    }
`;

const OPTION_TEXT_ALL = 'All';
const OPTION_TEXT_MY_PROGRAM = 'My Programme';
const OPTION_TEXT_MY_FAVORITES = 'My Favourites';

class ProgramTypeButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: this.props.myProgram ? this.props.myProgram : 'all',
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.myProgram !== prevState.mode) {
            return {
                mode: nextProps.myProgram,
            };
        } else {
            return null;
        }
    }

    // change all/my programme and call action
    toggle = mode => {
        this.setState({ mode: mode });

        if (this.props.action) {
            this.props.action(mode);
        }
    };

    render() {
        let TEXT_ALL = getString('all') || OPTION_TEXT_ALL;
        let TEXT_MY_PROGRAM = getString('myProgramme') || OPTION_TEXT_MY_PROGRAM;
        let TEXT_MY_FAVORITES = getString('myFavorites') || OPTION_TEXT_MY_FAVORITES;

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <InteractivityContext.Consumer>
                        {({ showSignIn }) => (
                            <ThemeContext.Consumer>
                                {({ theme }) => (
                                    <React.Fragment>
                                        {!theme.embed && !theme.kiosk && (
                                            <ViewTypeButtons
                                                alignment={this.props.alignment}
                                                hasFloat={!this.props.withoutFloat}
                                            >
                                                <StyledSelectField
                                                    id="program-type-select"
                                                    menuItems={[
                                                        {
                                                            label: TEXT_ALL,
                                                            value: 'all',
                                                        },
                                                        {
                                                            label: this.props.useFavoritesTitle
                                                                ? TEXT_MY_FAVORITES
                                                                : TEXT_MY_PROGRAM,
                                                            value: 'my_program',
                                                        },
                                                    ]}
                                                    centered
                                                    value={this.state.mode}
                                                    bgcolor={theme.primary}
                                                    onChange={value =>
                                                        value !== 'all' && !authUser
                                                            ? showSignIn()
                                                            : this.toggle(value)
                                                    }
                                                    dropdownIcon={
                                                        <FontIcon>keyboard_arrow_down</FontIcon>
                                                    }
                                                    position={SelectField.Positions.BOTTOM_RIGHT}
                                                />
                                            </ViewTypeButtons>
                                        )}
                                    </React.Fragment>
                                )}
                            </ThemeContext.Consumer>
                        )}
                    </InteractivityContext.Consumer>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

export default ProgramTypeButtons;
