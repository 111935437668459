import cloneDeep from 'lodash/cloneDeep';
import { parseVirtualEventSession } from './sortUtils';
import { TOAST_TYPES } from '../scenes/VirtualSession/components/common/toasts';

const defaultState = {
    appID: null,
    // loading effect
    loading: false,
    // media devices
    peers: [],
    streams: [],
    localStream: null,
    currentStream: null,
    otherStreams: [],
    cameraList: [],
    microphoneList: [],
    config: {
        uid: 0,
        host: false,
        role: 'host',
        muteAudio: true,
        muteVideo: true,
        resolution: '120p_1',
        audioProfile: 'high_quality_stereo',
        microphoneId: '',
        cameraId: '',
    },
    flags: {},
    agoraClient: null,
    localClient: null,
    mainMedia: 'slides',
    mainMediaSwitchedByUser: false,
    mode: 'live',
    codec: 'h264',
    muteVideo: true,
    muteAudio: true,
    screen: false,
    profile: false,
    useDevices: false,
    currentVirtualEventUser: null,
    virtualEventSession: null,
    virtualEventPollSets: null,
    externalObject: null,
    pdf: null,
    canJoin: false,
    currentUser: null,
    url: '',
    socket: {},
    sessionId: null,
    screenSharing: false,
    canvasSharing: null,
    handleSafari: false,
    activePoll: null,
    prerecording: false,
    showToast: false,
    toastDisplayed: false,
    recreateStreams: false,
    duration: null,
    playing: false,
    playedSeconds: 0,
    primaryPlayerRef: null,
    secondaryPlayerRef: null,
    showScreenSharingModal: false,
    shareType: null,
    timeslot: {},
    audioToastDisplayed: false,
    showCapacityModal: false,
    showAlreadyInTheSessionModal: false,
    isInRoundTable: false,
    roundTableUserInfoError: 'false',
};

export const populateDefaultState = extra => {
    Object.assign(defaultState, extra);
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'activePoll': {
            const newState = {
                ...state,
                activePoll: action.payload,
            };

            if (action.payload) {
                newState.shareType = 'canvas';
                newState.screenSharing = false;
            }

            return newState;
        }
        case 'addPeer': {
            const { peers } = state;
            const newPeer = action.payload;

            if (peers.find(peer => peer === newPeer)) {
                return state;
            }

            const newPeers = [...peers, newPeer];
            return {
                ...state,
                peers: newPeers,
            };
        }
        case 'addStream': {
            const { streams, localStream, currentStream } = state;
            const newStream = action.payload;
            let newCurrentStream = currentStream;

            if (!newCurrentStream) {
                newCurrentStream = newStream;
            }

            const duplicateRemovedStreams = streams.filter(
                st => st.streamId !== newStream.streamId,
            );
            const newStreams = [...duplicateRemovedStreams, newStream];

            const otherStreams =
                !localStream ||
                !newCurrentStream ||
                newCurrentStream.getId() !== localStream.getId()
                    ? newStreams
                    : newStreams.filter(it => it.getId() !== newCurrentStream.getId());

            return {
                ...state,
                streams: newStreams,
                currentStream: newCurrentStream,
                otherStreams,
            };
        }
        case 'audio': {
            return { ...state, muteAudio: action.payload };
        }
        case 'canJoin': {
            return { ...state, canJoin: action.payload };
        }
        case 'canvasSharing': {
            return { ...state, canvasSharing: action.payload, shareType: 'canvas' };
        }
        case 'clearAllStream': {
            const { streams, localStream, currentStream } = state;
            streams.forEach(stream => {
                if (stream.isPlaying()) {
                    stream.stop();
                }
            });

            if (localStream) {
                localStream.isPlaying() && localStream.stop();
            }
            if (currentStream) {
                currentStream.isPlaying() && currentStream.stop();
            }

            return {
                ...cloneDeep(defaultState),
                showCapacityModal: state.showCapacityModal,
                showAlreadyInTheSessionModal: state.showAlreadyInTheSessionModal,
                socket: state.socket,
            };
        }
        case 'codec': {
            return { ...state, codec: action.payload };
        }
        case 'config': {
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.payload,
                },
            };
        }
        case 'currentStream': {
            const { streams } = state;
            const newCurrentStream = action.payload;
            const otherStreams = streams.filter(it => it.getId() !== newCurrentStream.getId());
            return { ...state, currentStream: newCurrentStream, otherStreams };
        }
        case 'currentUser': {
            return { ...state, currentUser: action.payload };
        }
        case 'currentVirtualEventUser': {
            return { ...state, currentVirtualEventUser: action.payload };
        }
        case 'eurekaChatUrl': {
            return { ...state, eurekaChatUrl: action.payload };
        }
        case 'externalObject': {
            return { ...state, externalObject: action.payload };
        }
        case 'extraState': {
            return { ...state, ...action.payload };
        }
        case 'localClient': {
            return { ...state, localClient: action.payload };
        }
        case 'localStream': {
            return { ...state, localStream: action.payload };
        }
        case 'mutedBySystem': {
            return { ...state, mutedBySystem: action.payload };
        }
        case 'micMutedDialog': {
            return { ...state, micMutedDialog: action.payload };
        }
        case 'playedSeconds': {
            return { ...state, playedSeconds: action.payload };
        }
        case 'playing': {
            return { ...state, playing: action.payload };
        }
        case 'pollSets': {
            return { ...state, pollSets: action.payload };
        }
        case 'setPeers': {
            return { ...state, peers: action.payload };
        }
        case 'primaryPlayerRef': {
            return { ...state, primaryPlayerRef: action.payload };
        }
        case 'publishShareStream': {
            return { ...state, publishShareStream: action.payload };
        }
        case 'recreateStreams': {
            return { ...state, recreateStreams: action.payload };
        }
        case 'removePeer': {
            const { peers } = state;
            const peerToRemove = action.payload;
            const newPeers = [...peers].filter(peer => peer !== peerToRemove);
            return {
                ...state,
                peers: newPeers,
            };
        }
        case 'removeStream': {
            const { streams, currentStream } = state;
            const {
                user: { uid },
            } = action;
            const targetId = uid;
            let newCurrentStream = currentStream;
            // we want a new reference
            const newStreams = [...streams].filter(st => st.getId() !== targetId);

            if (currentStream && targetId === currentStream.getId()) {
                if (newStreams.length === 0) {
                    newCurrentStream = null;
                } else {
                    newCurrentStream = newStreams[0];
                }
            }

            const otherStreams = newCurrentStream
                ? newStreams.filter(it => it.getId() !== newCurrentStream.getId())
                : [];
            return {
                ...state,
                streams: newStreams,
                currentStream: newCurrentStream,
                otherStreams,
            };
        }
        case 'screenShareStream': {
            return { ...state, screenShareStream: action.payload };
        }
        case 'screenSharing': {
            return { ...state, screenSharing: action.payload };
        }
        case 'screenSharingClient': {
            return { ...state, screenSharingClient: action.payload };
        }
        case 'sessionId': {
            return { ...state, sessionId: action.payload };
        }
        case 'secondaryPlayerRef': {
            return { ...state, secondaryPlayerRef: action.payload };
        }
        case 'shareType': {
            return { ...state, shareType: action.payload };
        }
        case 'showAlreadyInTheSessionModal': {
            return { ...state, showAlreadyInTheSessionModal: action.payload };
        }
        case 'isInRoundTable': {
            return { ...state, isInRoundTable: action.payload };
        }
        case 'roundTableUserInfoError': {
            return { ...state, roundTableUserInfoError: action.payload };
        }
        case 'showCapacityModal': {
            return { ...state, showCapacityModal: action.payload };
        }
        case 'showScreenSharingModal': {
            return { ...state, showScreenSharingModal: action.payload };
        }
        case 'joiningLoaderVisible': {
            return { ...state, joiningLoaderVisible: action.payload };
        }
        case 'showToast': {
            // Audio toast should be displayed only one time.
            if (action.payload === TOAST_TYPES.AUDIO) {
                const { audioToastDisplayed, showToast } = state;
                const newAudioToastDisplayed = audioToastDisplayed || !!action.payload;
                const newShowToast =
                    showToast === action.payload
                        ? action.payload
                        : !audioToastDisplayed && action.payload;

                return {
                    ...state,
                    showToast: newShowToast,
                    audioToastDisplayed: newAudioToastDisplayed,
                };
            } else {
                return {
                    ...state,
                    showToast: action.payload,
                };
            }
        }
        case 'socket': {
            return { ...state, socket: action.payload };
        }
        case 'switchMain': {
            const { mainMedia } = state;
            const newMain = mainMedia === 'slides' ? 'video' : 'slides';

            return { ...state, mainMedia: newMain, mainMediaSwitchedByUser: false };
        }
        case 'userSwitchMain': {
            const { mainMedia } = state;
            const newMain = mainMedia === 'slides' ? 'video' : 'slides';

            return { ...state, mainMedia: newMain, mainMediaSwitchedByUser: true };
        }
        case 'subscribeTimestamp': {
            return { ...state, subscribeTimestamp: action.payload };
        }
        case 'timeslot': {
            return { ...state, timeslot: action.payload };
        }
        case 'useDevices': {
            return { ...state, useDevices: action.payload };
        }
        case 'videoClient': {
            return { ...state, videoClient: action.payload };
        }
        case 'virtualEventSession': {
            const parsedVirtualEventSession = parseVirtualEventSession(action.payload);

            return { ...state, virtualEventSession: parsedVirtualEventSession };
        }
        case 'virtualEventUser': {
            return { ...state, virtualEventUser: action.payload };
        }
        default:
            throw new Error('mutation type not defined');
    }
};

const volumeReducer = (state, action) => {
    switch (action.type) {
        case 'volumeIndicators': {
            const { streams } = action.payload;
            const volumeIndicators = streams.map(st => ({
                uid: st.streamId,
                level: st.getAudioLevel() * 12,
            }));

            return { ...state, volumeIndicators };
        }
        // end player
        default:
            throw new Error('mutation type not defined');
    }
};

export { reducer, volumeReducer, defaultState };
