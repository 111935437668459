import React from 'react';

import Button, { buttonTypes } from '../../common/Button';
import { PadddingLeftRight16px } from '../../common/Padding';
import { FlexButtonsWrapper } from '../style';
import { getString } from '../../../services/api/store';

const AcceptDeclineFriendShipButtons = ({ theme, onCancelRequest, onAcceptFriendRequest }) => (
    <PadddingLeftRight16px>
        <FlexButtonsWrapper>
            <Button
                type={buttonTypes.GREEN_LONG}
                background={theme.primary}
                text={getString('profileAcceptContactRequest') || 'Accept'}
                onClick={onAcceptFriendRequest}
            />
            <Button
                type={buttonTypes.GREEN_LONG_REVERSE}
                background={theme.primary}
                text={getString('profileDeclineContactRequest') || 'Decline'}
                onClick={onCancelRequest}
            />
        </FlexButtonsWrapper>
    </PadddingLeftRight16px>
);

export default AcceptDeclineFriendShipButtons;
