import React, { createContext } from 'react';

const PlatformInfoContext = createContext();

const withPlatformInfoUpdates = Component =>
    class WithPlatformInfo extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                newVersionAvailable: false,
                waitingWorker: null,
            };
        }

        onServiceWorkerUpdate = registration => {
            this.setState({
                newVersionAvailable: true,
                waitingWorker: registration && registration.waiting,
            });
        };

        updateServiceWorker = () => {
            const { waitingWorker } = this.state;
            if (waitingWorker && waitingWorker.postMessage) {
                waitingWorker.postMessage({ type: 'SKIP_WAITING' });
            }
            this.setState({ newVersionAvailable: false });
            window.location.reload();
        };

        render() {
            const { newVersionAvailable, waitingWorker } = this.state;
            const defaultContext = {
                ...this.props,
                newVersionAvailable,
                waitingWorker,
                onServiceWorkerUpdate: this.onServiceWorkerUpdate,
                updateServiceWorker: this.updateServiceWorker,
            };

            return (
                <PlatformInfoContext.Provider value={defaultContext}>
                    <Component {...defaultContext} />
                </PlatformInfoContext.Provider>
            );
        }
    };

export default withPlatformInfoUpdates;
