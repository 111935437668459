import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Auth from '../../services/api/auth';
import { addReminder as addReminderAction } from '../Notifications/actions';
import ReminderService from "./ReminderService";

const REMINDERS_CHECK_TIME = 60000; // 1 minute
const REMINDER_ITEMS_FETCH_TIME = 600000; // 10 minutes

const RemindersWrapper = ({ children, addReminder }) => {
    const isAuthenticated = Auth.isUserAuthenticated();

    const fetchCallback = async () => {
        await ReminderService.fetchReminderItems();
        checkForReminders();
    };

    useEffect(() => {
        let fetchingIntervalId;
        let checkingIntervalId;

        if (isAuthenticated) {
            fetchCallback();
            fetchingIntervalId = setInterval(fetchCallback, REMINDER_ITEMS_FETCH_TIME);
            checkingIntervalId = setInterval(checkForReminders, REMINDERS_CHECK_TIME);
        }

        return () => {
            if (fetchingIntervalId) {
                clearInterval(fetchingIntervalId);
            }

            if (checkingIntervalId) {
                clearInterval(checkingIntervalId);
            }
        };
    }, [isAuthenticated]);

    const checkForReminders = () => {
        const itemsStartingInNext10Minutes = ReminderService.getItemsStartingInNext10Minutes();
        const itemsWithEvaluationReminders = ReminderService.getItemsWithEvaluationReminders();
        const mergedItems = [
            ...itemsStartingInNext10Minutes,
            ...itemsWithEvaluationReminders.map(item => ({ ...item, isEvaluationReminder: true })),
        ];

        mergedItems.forEach(item => {
            addReminder(item);
        });
    };

    return children;
};

export default connect(null, {
    addReminder: addReminderAction,
})(RemindersWrapper);
