import React, { Component } from 'react';
import AnalyticsService from '../services/index';

const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = async (page, nav, installationId) => {
        let type = 'page';
        let object;
        let objectId;

        if (page !== '/') {
            const pageParams = page.split('/').filter(item => item);
            const numberOfParams = pageParams.length;

            objectId = pageParams[numberOfParams - 1];

            if (numberOfParams > 2) {
                type = 'object';
                object = pageParams[numberOfParams - 2];
            } else {
                switch (objectId) {
                    case 'myconversations': {
                        object = 'My Conversations';
                        break;
                    }
                    case 'mynotes': {
                        object = 'My Notes';
                        break;
                    }
                    case 'myfavorites': {
                        object = 'My Favorites';
                        break;
                    }
                    case 'myprogramme': {
                        object = 'My Program';
                        break;
                    }
                    default: {
                        const filteredNav = nav.filter(route => page.includes(route.to));
                        object = filteredNav.length > 0 ? filteredNav[0].title : '';
                        break;
                    }
                }
            }

            AnalyticsService.addSample(type, object, objectId, installationId);
        }
    };

    class HOC extends Component {
        componentDidMount() {
            const page = this.props.location.pathname + this.props.location.search;
            trackPage(page, this.props.nav, this.props.installationId);
        }

        componentDidUpdate(prevProps) {
            const currentPage = prevProps.location.pathname + prevProps.location.search;
            const nextPage = this.props.location.pathname + this.props.location.search;

            if (currentPage !== nextPage) {
                trackPage(nextPage, this.props.nav, this.props.installationId);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }
    return HOC;
};

export default withTracker;
