import Section from './Section';
import React from 'react';

const SectionsWrapper = ({ item, matchUrl, clickOpenDetail }) => (
    <React.Fragment>
        {item.sections.map(sec => {
            const type = sec.type.replace(' ', '');
            const title = sec.title.replace(' ', '');
            const key = `${item.id}_${type}_${title}`;

            return (
                <Section
                    key={key}
                    icon={sec.icon}
                    type={sec.type}
                    title={sec.title}
                    items={sec.items}
                    clickOpenDetail={item => clickOpenDetail(item)}
                    matchUrl={matchUrl}
                />
            );
        })}
    </React.Fragment>
);

export default SectionsWrapper;
