import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { loadPosts as loadPostsAction, resetState as resetStateAction } from '../actions';
import { socialFeedApi } from '../api';
import { selectAllPosts } from '../selectors';
import Auth from '../../../services/api/auth';
import Post from '../components/PostHeader';
import Loader from '../../../components/General/Loader';
import NewPostInput from '../components/NewPostInput';
import { getString } from '../../../services/api/store';
import Button, { buttonTypes } from '../../common/Button';

const Wrapper = styled.div`
    max-width: 500px;
    padding: 24px;
`;

const SocialFeed = ({ loadPosts, posts, loading, lastPostId, resetState, history, rootRoute }) => {
    const isAuthenticated = Auth.isUserAuthenticated();

    useEffect(() => {
        // Make sure we have a clean social wall due to social notifications received on society level
        resetState();
    }, []);

    useEffect(() => {
        async function register() {
            await socialFeedApi.registerUser();
        }

        if (isAuthenticated) {
            register();
        }

        loadPosts();
        return () => {
            resetState();
        };
    }, [isAuthenticated]);

    const handleLoadMore = () => {
        if (lastPostId) {
            loadPosts();
        }
    };

    const onNewPostClick = () => history.push(`${rootRoute}/add`);

    return (
        <>
            <NewPostInput onClick={onNewPostClick} />
            <Wrapper>
                {posts.map((postId, index) => (
                    <Post key={`social-wall-post-${index}`} postId={postId} rootRoute={rootRoute} />
                ))}
                {loading && <Loader />}
                {!loading && lastPostId && (
                    <Button
                        onClick={handleLoadMore}
                        type={buttonTypes.GREY}
                        text={getString('loadMore') || 'Load more...'}
                    />
                )}
            </Wrapper>
        </>
    );
};

SocialFeed.propTypes = {
    loadPosts: PropTypes.func,
    posts: PropTypes.arrayOf(PropTypes.string),
    loading: PropTypes.bool,
    refreshing: PropTypes.bool,
    lastPostId: PropTypes.string,
    resetState: PropTypes.func,
    rootRoute: PropTypes.string,
};

SocialFeed.defaultProps = {
    loadPosts: () => {},
    posts: [],
    loading: false,
    refreshing: false,
    lastPostId: null,
    resetState: () => {},
    rootRoute: '',
};

const mapStateToProps = state => {
    return {
        posts: selectAllPosts(state),
        loading: state.socialFeed.loading,
        refreshing: state.socialFeed.refreshing,
        lastPostId: state.socialFeed.lastPostId,
    };
};

export default connect(mapStateToProps, {
    loadPosts: loadPostsAction,
    resetState: resetStateAction,
})(withRouter(SocialFeed));
