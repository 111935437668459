import React, { useState, useMemo } from 'react';
import { FontIcon, Button } from 'react-md';
import styled from 'styled-components';
import get from 'lodash/get';

import { useGlobalState, useVolumeState } from '../../../../../utils/container';
import StreamPlayer from '../../common/StreamPlayer';
import * as palette from '../../../../../components/General/Variables';
import { ShadowOverlay } from '../../../styles';
import {
    AccountCircleIconWrapper,
    ActionButtonsWrapper,
    styles,
    LightTooltip,
    viewProfileTooltipMessage,
    ProfileImageWrapper,
    VirtualUserInfoWrapper,
    VirtualUserTitle,
} from './common';
import VoiceIndicator from '../../common/VoiceIndicator';

const RoundTableWrapper = styled.div`
    position: relative;
    width: 100%;
    ${props => !props.nofixedaspectratio && 'aspect-ratio: 16 / 9'};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${props => (props.marginbottom ? '8px' : '0')};
    border-radius: 8px;
    overflow: hidden;
`;

export default ({ virtualEventUser, toggleUserProfile, muted, index, noFixedAspectRatio }) => {
    const stateCtx = useGlobalState();
    const volumeStateCtx = useVolumeState();
    const { volumeIndicators } = volumeStateCtx;
    const user = virtualEventUser.User;
    const isHost = virtualEventUser.role === 'roundTableHost';
    const { isMicrophoneOn, isVideoOn } = virtualEventUser;
    const [showActionButtons, setShowActionButtons] = useState(false);
    const [isActionButtonHovered, setIsActionButtonHovered] = useState(false);
    const { streams, peers } = stateCtx;
    const stream = useMemo(() => streams.find(st => st.streamId === user.id), [stateCtx, streams]);
    const buttonStyle = {
        ...styles.buttonStyle,
        backgroundColor: isActionButtonHovered ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.87)',
    };

    const volumeLevel = useMemo(() => {
        if (!volumeIndicators) {
            return 0;
        }

        const indicator = volumeIndicators.find(
            vInd => `${get(stream, 'streamId')}` === `${vInd.uid}`,
        );

        return indicator ? indicator.level : 0;
    }, [volumeIndicators, stream]);

    return (
        <RoundTableWrapper
            nofixedaspectratio={noFixedAspectRatio ? 1 : 0}
            marginbottom={isHost && !noFixedAspectRatio ? 1 : 0}
        >
            {stream && (
                <StreamPlayer
                    key={`stream-${stream.getId()}-${index}`}
                    local={false}
                    stream={stream}
                    isPlaying={stream.isPlaying()}
                    isVideoOn={isVideoOn}
                    uid={stream.getId()}
                    domId={`stream-player-${stream.getId()}-${index}`}
                    muted={muted}
                    roundTableNoSlides={noFixedAspectRatio}
                />
            )}
            <ProfileImageWrapper
                onMouseEnter={() => !showActionButtons && setShowActionButtons(true)}
                onMouseLeave={() => showActionButtons && setShowActionButtons(false)}
                isVideoOn={isVideoOn}
            >
                <VirtualUserInfoWrapper>
                    {!isMicrophoneOn && (
                        <FontIcon style={{ fontSize: '16px', color: '#ffffff' }}>
                            {palette.ICON_MIC_OFF}
                        </FontIcon>
                    )}
                    {isMicrophoneOn && <VoiceIndicator level={volumeLevel} small />}
                    <VirtualUserTitle>
                        {user.firstName} {user.lastName} {isHost ? '(Host)' : ''}
                    </VirtualUserTitle>
                </VirtualUserInfoWrapper>
                {!isVideoOn && (
                    <ActionButtonsWrapper>
                        <AccountCircleIconWrapper imageUrl={user.imageUrl}>
                            {!user.imageUrl && (
                                <FontIcon
                                    style={{ fontSize: '40px', display: 'flex', color: '#fff' }}
                                >
                                    {palette.ICON_PROFILE}
                                </FontIcon>
                            )}
                        </AccountCircleIconWrapper>
                    </ActionButtonsWrapper>
                )}
                <ShadowOverlay />
                {showActionButtons && (
                    <ActionButtonsWrapper transparent clickable>
                        <LightTooltip title={viewProfileTooltipMessage} placement="top">
                            <Button
                                style={buttonStyle}
                                icon
                                forceIconSize={24}
                                onClick={() => {
                                    toggleUserProfile(user);
                                }}
                                onMouseEnter={() => setIsActionButtonHovered(true)}
                                onMouseLeave={() => setIsActionButtonHovered(false)}
                            >
                                <FontIcon style={{ fontSize: '24px', color: '#ffffff' }}>
                                    {palette.ICON_PERSON__OUTLINE}
                                </FontIcon>
                            </Button>
                        </LightTooltip>
                    </ActionButtonsWrapper>
                )}
            </ProfileImageWrapper>
        </RoundTableWrapper>
    );
};
