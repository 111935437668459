import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import * as palette from '../../../components/General/Variables';
import { Flex } from '../../VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import LeaderboardList, { LeaderboardFilter } from '../components/LeaderboardList';
import AchievementsList, { AchievementFilter } from '../components/AchievementsList';
import MyScore from '../components/MyScore';
import { useTheme } from '../../../components/Theme/ThemeContext';
import GamificationFilterButton from '../components/GamificationFilterButton';
import MyScoreDetails from "../components/MyScoreDetails";
import {TabElement, TabsContainerStyled, TabsWrapper} from "../../Programme/style/style";

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        padding: 0;
    }
`;

const StyledContentContainer = styled.div`
    background-color: #ebedf0;
    padding: 0 32px 32px 32px;
    display: flex;
    justify-content: center;
`;

const StyledHeaderContainer = styled.div`
    padding: 12px 32px;
`;

const ACHIEVEMENTS_FILTERS = [
    AchievementFilter.All,
    AchievementFilter.Completed,
    AchievementFilter.Pending,
];

const LEADERBOARD_FILTERS = [LeaderboardFilter.All, LeaderboardFilter.Contacts];

const AchievementsPage = ({ setTitle }) => {
    const { theme } = useTheme();
    const [selectedSection, setSelectedSection] = useState(0);
    const filters = selectedSection === 0 ? ACHIEVEMENTS_FILTERS : LEADERBOARD_FILTERS;
    const [selectedFilter, setSelectedFilter] = useState(filters[0]);
    const [showMyScoreDetails, setShowMyScoreDetails] = useState(false);

    useEffect(() => {
        setTitle();
    }, []);

    const handleFilterSelected = filter => {
        setSelectedFilter(filter);
    };

    const handleSelectSection = section => {
        setSelectedSection(section);
        setSelectedFilter(section === 0 ? ACHIEVEMENTS_FILTERS[0] : LEADERBOARD_FILTERS[0]);
    };

    return (
        <Container>
            <Page>
                <StyledHeaderContainer>
                    <Flex justifycontent={'space-between'} alignitems="center">
                        <Flex alignitems="center">
                            <TabsContainerStyled
                                onTabChange={index => handleSelectSection(index)}
                                activeTabIndex={selectedSection}
                            >
                                <TabsWrapper
                                    tabId="achievement-page-tabs"
                                    mobile
                                    alignToKeyline={true}
                                    background="inherit"
                                >
                                    <TabElement
                                        key="achievements-tab-element"
                                        label="Achievements"
                                        primary={theme.primary}
                                    />
                                    <TabElement
                                        key="leaderboard-tab-element"
                                        label="Leaderboard"
                                        primary={theme.primary}
                                    />
                                </TabsWrapper>
                            </TabsContainerStyled>
                        </Flex>
                        <Flex>
                            <GamificationFilterButton
                                filters={filters}
                                selectedFilter={selectedFilter}
                                onFilterSelected={handleFilterSelected}
                            />

                            <MyScore onClick={() => setShowMyScoreDetails(true)}/>
                        </Flex>
                    </Flex>
                </StyledHeaderContainer>
                <StyledContentContainer>
                    {selectedSection === 0 && <AchievementsList filter={selectedFilter} />}
                    {selectedSection === 1 && <LeaderboardList filter={selectedFilter} />}
                </StyledContentContainer>
            </Page>

            {showMyScoreDetails && <MyScoreDetails onHide={() => setShowMyScoreDetails(false)}/>}
        </Container>
    );
};

export default withRouter(AchievementsPage);
