import React from 'react';
import { List, ListItem } from 'react-md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Loader from '../../components/General/Loader';
import { ImageAvatar } from '../../components/Icons';
import { getMenuPage } from './services/menuPage';
import AccessRestrictions from '../../AccessRestrictions';
import AttendeeList from '../Attendees';
import AuthenticationProtection from '../../components/ProtectedPages/AuthenticationProtection';
import Auth from '../../services/api/auth';

const LinkStyled = styled(Link)`
    text-decoration: none !important;
`;

const AStyled = styled.a`
    text-decoration: none !important;
`;

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${props => window.innerWidth}px;
`;

const getUrlParameter = (name, url) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(url);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const removeURLParameter = (url, parameter) => {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0; ) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        return url;
    } else {
        return url;
    }
};

const Item = ({ item, i }) => (
    <ListItem
        tileStyle={{
            borderTop: i === 0 ? '0px solid rgba(0, 0, 0, 0.08)' : '1px solid rgba(0, 0, 0, 0.08)',
        }}
        primaryText={item.title}
        leftAvatar={item.imageId ? <ImageAvatar imageId={item.imageId} shape="square" /> : ''}
    />
);

const Menu = ({ loading, menuItems }) => (
    <React.Fragment>
        {loading && <Loader />}
        {!loading && <ListStyled className="">{menuItems}</ListStyled>}
    </React.Fragment>
);

class MenuPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            menuData: [],
            loading: true,
            closeLinkPath: props.closeLink ? props.closeLink : '/programme',
            pageId: props.pageId,
            accessRestrictions: null,
        };
        this.props.setTitle();
    }

    getMenuInfo = () => {
        getMenuPage(this.state.pageId, (err, result) => {
            if (result && result.params && result.params.items && result.params.items.length) {
                this.setState({
                    menuData: result.params.items,
                    loading: false,
                    accessRestrictions: result.accessRestrictions,
                    isPasswordProtected: !!result.isPasswordProtected,
                    password: result.password,
                });

                if (this.props.updateTitle) {
                    this.props.updateTitle(result.params.title);
                }
            }
            if (result && result.name === 'attendee_list') {
                this.setState({
                    isAttendeeList: true,
                });
            }
        });
    };

    componentDidMount() {
        this.getMenuInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.pageId !== prevState.pageId) {
            this.getMenuInfo();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.pageId !== prevState.pageId) {
            return {
                pageId: nextProps.pageId,
            };
        } else {
            return null;
        }
    }

    linkWithToken = (type, tokenLabel, i, item, target) => {
        let link = item.url;
        if (type && type === 'access_token') {
            const user = Auth.getUser();
            let label = tokenLabel;
            if (!label) {
                label = 'token';
            }
            if (user && user.accessToken) {
                link = item.url + '&' + label + '=' + user.accessToken;
                link = `${item.url}&${label}=${user.accessToken}`;
            }
            link = removeURLParameter(link, 'authType');
            link = removeURLParameter(link, 'tokenLabel');
        } else if (type && type === 'login_only') {
            link = removeURLParameter(link, 'authType');
            link = removeURLParameter(link, 'tokenLabel');
        }
        return (
            <AStyled href={link} target={target}>
                <Item item={item} i={i} />
            </AStyled>
        );
    };

    createExternalLink = (i, item, target) => {
        const { url } = item;
        const type = getUrlParameter('authType', url);
        const tokenLabel = getUrlParameter('tokenLabel', url);

        if (type && type.length) {
            return (
                <AuthenticationProtection>
                    {this.linkWithToken(type, tokenLabel, i, item, target)}
                </AuthenticationProtection>
            );
        } else {
            return (
                <AStyled href={item.url} target={target}>
                    <Item item={item} i={i} />
                </AStyled>
            );
        }
    };

    menuItems = () =>
        this.state.menuData.map((item, i) => {
            let linkTo;
            if (item.screen) {
                linkTo = this.props.rootRoute + '/page/' + item.screen + '/' + item.page;
            } else {
                linkTo =
                    this.props.rootRoute + '/page/' + item.link.screen + '/' + this.props.pageId;
            }

            let externalLink = false;
            if (!item.screen && !item.page && item.url && item.url !== '') {
                externalLink = true;
            }

            let target = '_blank';
            if (item.url && (item.url.includes('mailto:') || false === item.isExternal)) {
                target = '_self';
            }

            return (
                <React.Fragment key={'link_' + i}>
                    {!externalLink && !item.url ? (
                        <LinkStyled to={linkTo}>
                            <Item item={item} i={i} />
                        </LinkStyled>
                    ) : (
                        this.createExternalLink(i, item, target)
                    )}
                </React.Fragment>
            );
        });

    render() {
        const { isAttendeeList, accessRestrictions, loading } = this.state;

        return (
            <>
                {isAttendeeList ? (
                    <AttendeeList isMenu {...this.props} />
                ) : (
                    <AccessRestrictions
                        pageId={this.state.pageId}
                        accessRestrictions={this.state.accessRestrictions}
                        isPasswordProtected={this.state.isPasswordProtected}
                        password={this.state.password}
                        result={() => (
                            <Menu loading={this.state.loading} menuItems={this.menuItems()} />
                        )}
                    />
                )}
            </>
        );
    }
}

export default MenuPage;
