import React, { useEffect, useState } from 'react';
import { Avatar, FontIcon } from 'react-md';
import styled from 'styled-components';
import * as palette from '../../components/General/Variables.js';
import { getImageSource } from '../../services/api/db';

const ConversationAvatar = styled(Avatar)`
    background-color: ${palette.COLOR_GREY} !important;
    color: ${palette.COLOR_WHITE} !important;
    border: 0px solid rgba(0, 0, 0);
    border-radius: 50% !important;
`;

const PersonAvatar = styled(Avatar)`
    background-color: transparent;
    color: ${palette.COLOR_GREY};
    border: 0px solid rgba(0, 0, 0);
    ${props =>
        props.shape === 'square' &&
        `
        border-radius: unset !important;
      `};

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        ${props =>
            props.shape === 'square' &&
            `
            width: 40px !important;
            height: 40px !important;
      `};
    }
`;

const PlaceAvatar = styled(Avatar)`
    background-color: transparent;
    color: ${palette.COLOR_GREY};
    border: 0px solid rgba(0, 0, 0);
`;

const InstitutionAvatar = styled(Avatar)`
    background-color: ${palette.COLOR_GREY} !important;
    color: ${palette.COLOR_WHITE} !important;
    border: 0px solid rgba(0, 0, 0);
    border-radius: 0%;
`;

const Container = styled.div`
    width: ${props => props.customSize || '56px'} !important;
    height: ${props => props.customSize || '56px'} !important;
    display: flex;
    justify-content: center;
    align-items: center;

    & .md-icon {
        ${props =>
            props.avatarType === 'institutions' &&
            `
              background: #BFBFBF;
              color: #fff !important;
            `};
    }

    & .md-avatar {
        border: none !important;
        width: ${props => props.customSize || '56px'} !important;
        height: ${props => props.customSize || '56px'} !important;
        ${props =>
            props.avatarType === 'institutions' &&
            `
          border-radius: 4px;
        `};
        ${props =>
            props.imageUrl ||
            props.avatarType === 'persons' ||
            props.avatarType === 'institutions' ||
            props.avatarType === 'conversation'
                ? ''
                : `
        width: 27px !important;
        height: 50px !important;
      `}
    }

    & .md-avatar--default {
        background: transparent;
        color: #bfbfbf;
    }
`;

const ConversationIcon = () => (
    <ConversationAvatar
        icon={
            <FontIcon forceSize={40} forceFontSize={true}>
                {palette.ICON_CONVERSATION}
            </FontIcon>
        }
    />
);
const PersonsIcon = ({ customSize }) => (
    <PersonAvatar
        icon={
            <FontIcon forceSize={customSize || 66} forceFontSize={true}>
                {palette.ICON_PERSON}
            </FontIcon>
        }
    />
);
const PlacesIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.ICON_PLACE}
            </FontIcon>
        }
    />
);
const InstitutionsIcon = ({ imageUrl }) => (
    <InstitutionAvatar
        icon={
            <FontIcon forceSize={imageUrl ? 66 : 56} forceFontSize={true}>
                {palette.ICON_INSTITUTION}
            </FontIcon>
        }
    />
);
const WebpageIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.ICON_GLOBE}
            </FontIcon>
        }
    />
);
const AskIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.HELP_ICON_OUTLINE}
            </FontIcon>
        }
    />
);
const EvaluationIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.ASSIGNMENT_ICON}
            </FontIcon>
        }
    />
);
const LikeIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.THUMB_UP_ICON}
            </FontIcon>
        }
    />
);
const InteractiveIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.QA_ICON_OUTLINE}
            </FontIcon>
        }
    />
);
const MailIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.EMAIL_ICON}
            </FontIcon>
        }
    />
);
const EposterIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.ICON_POSTER}
            </FontIcon>
        }
    />
);
const VotingIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.CHECK_BOX_ICON}
            </FontIcon>
        }
    />
);
const PDFIcon = () => (
    <PlaceAvatar
        icon={
            <FontIcon forceSize={20} forceFontSize={true}>
                {palette.PDF_ICON}
            </FontIcon>
        }
    />
);

const ImageAvatar = ({ avatarType, imageId, customSize }) => {
    const [imageUrl, setImageUrl] = useState('');
    const shape = avatarType === 'persons' || avatarType === 'institutions' ? 'round' : 'square';

    useEffect(() => {
        if (imageId) {
            getImageSource(imageId, (err, result) => {
                if (result) {
                    setImageUrl(result);
                }
            });
        } else {
            setImageUrl('');
        }
    }, [imageId, avatarType]);

    return (
        <Container imageUrl={imageUrl} avatarType={avatarType} customSize={customSize}>
            {!imageUrl && avatarType === 'conversation' && <ConversationIcon />}
            {!imageUrl && avatarType === 'institutions' && <InstitutionsIcon imageUrl={imageUrl} />}
            {!imageUrl && avatarType === 'persons' && <PersonsIcon customSize={customSize} />}
            {!imageUrl && avatarType === 'places' && <PlacesIcon />}
            {!imageUrl && avatarType === 'text/html' && <WebpageIcon />}
            {!imageUrl && avatarType === 'ask_speaker/custom' && <AskIcon />}
            {!imageUrl && avatarType === 'evaluation/custom' && <EvaluationIcon />}
            {!imageUrl && avatarType === 'like_abstract/custom' && <LikeIcon />}
            {!imageUrl && avatarType === 'interactive/custom' && <InteractiveIcon />}
            {!imageUrl && avatarType === 'send_email_to_speaker/custom' && <MailIcon />}
            {!imageUrl && avatarType === 'eposter/custom' && <EposterIcon />}
            {!imageUrl && avatarType === 'eposter/pdf' && <EposterIcon />}
            {!imageUrl && avatarType === 'voting/custom' && <VotingIcon />}
            {!imageUrl && avatarType === 'application/pdf' && <PDFIcon />}
            {imageUrl && (
                <PersonAvatar src={imageUrl} role="presentation" className={shape} shape={shape} />
            )}
        </Container>
    );
};

export default ImageAvatar;
