import React from 'react';
import {
    ButtonsContainer,
    Card,
    CloseCardIcon,
    RegularLink,
    StyledError,
    Subtitle,
    Title,
} from '../style/signIn';
import * as string from '../constants/strings';
import { getString } from '../../../services/api/store';
import Button, { buttonTypes } from '../../common/Button';
import * as palette from '../../../components/General/Variables';
import { Flex } from '../../../scenes/common/Flex';

const LoginVerification = ({
    theme,
    email,
    resendEmail,
    confirmEmail,
    mailResent,
    error,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => {
    const GOT_MAIL_TITLE = getString('createAccountSuccessMessage') || string.GOT_MAIL_TITLE;
    const TEXT_ERROR_DEFAULT =
        getString('loginSignInErrorDialogMessage') || string.ERROR_MESSAGE_DEFAULT;
    const ERROR_ACCOUNT_NOT_CONFIRMED =
        getString('createAccountConfirmedErrorMessage') || string.ERROR_ACCOUNT_NOT_CONFIRMED;
    const VERIFICATION_EMAIL_SENT_MESSAGE =
        getString('createAccountSuccessSubtitle') || string.VERIFICATION_EMAIL_SENT_MESSAGE;
    const VERIFICATION_EMAIL_RESENT_MESSAGE =
        getString('resendEmailWarningMessage') || string.VERIFICATION_EMAIL_RESENT_MESSAGE;
    const TEXT_VERIFICATION_EMAIL_SENT = !mailResent
        ? VERIFICATION_EMAIL_SENT_MESSAGE
        : VERIFICATION_EMAIL_RESENT_MESSAGE;
    const TEXT_DIALOG_VERIFICATION = TEXT_VERIFICATION_EMAIL_SENT.replace('__email__', email);
    const TEXT_DIDNT_GET_EMAIL =
        getString('createAccountSuccessResendEmailCallToAction') || string.TEXT_DIDNT_GET_EMAIL;
    const TEXT_RESEND_EMAIL_BUTTON =
        getString('createAccountSuccessResendEmailButton') || string.TEXT_RESEND_EMAIL_BUTTON;

    const TEXT_DIALOG_VERIFICATION_AFTER = [
        `${TEXT_DIDNT_GET_EMAIL} `,
        `${TEXT_RESEND_EMAIL_BUTTON}`,
        `.`,
    ];
    const TEXT_I_CONFIRMED_BUTTON =
        getString('createAccountSuccessMainButton') || string.TEXT_I_CONFIRMED_BUTTON;
    let errorMessage = '';
    if (error) {
        if (error.type) {
            switch (error.type) {
                case 'account':
                    errorMessage = ERROR_ACCOUNT_NOT_CONFIRMED;
                    break;
                default:
                    errorMessage = TEXT_ERROR_DEFAULT;
                    break;
            }
        } else if (error.message) {
            errorMessage = TEXT_ERROR_DEFAULT;
        }
    }
    return (
        <Card customHeight={'276px'} isModal={isModal}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Title>{GOT_MAIL_TITLE}</Title>
                {!eurekaOnly && !attendingOnly && (
                    <CloseCardIcon onClick={onClose}>{palette.ICON_CLOSE}</CloseCardIcon>
                )}
            </Flex>
            <Subtitle>
                {TEXT_DIALOG_VERIFICATION}
                <br />
                <br />
                {TEXT_DIALOG_VERIFICATION_AFTER[0]}
                <RegularLink
                    linkcolor={theme.primary}
                    onClick={() => {
                        resendEmail();
                    }}
                >
                    {TEXT_DIALOG_VERIFICATION_AFTER[1]}
                </RegularLink>
                {TEXT_DIALOG_VERIFICATION_AFTER[2]}
            </Subtitle>
            <ButtonsContainer primarycolor={theme.primary}>
                <Button
                    type={buttonTypes.GREEN_LONG}
                    background={theme.primary}
                    text={TEXT_I_CONFIRMED_BUTTON}
                    onClick={confirmEmail}
                    data-qa="button-confirmed-email"
                />
                <StyledError>{errorMessage}</StyledError>
            </ButtonsContainer>
        </Card>
    );
};

export default LoginVerification;
