import React from 'react';

import InteractivityContext from './InteractivityContext';
import NotesDialog from './NotesDialog';
import { withRouter } from 'react-router-dom';
import SignIn from '../../scenes/SignInPage/components/index';
import reduxStore from '../../reduxStore';
import { Provider } from 'react-redux';

const withInteractivity = Component =>
    withRouter(
        class WithInteractivity extends React.PureComponent {
            constructor(props) {
                super(props);
                this.state = {
                    notesVisible: false,
                    signInVisible: false,
                };
            }

            componentDidMount() {
                // auth.subscribe(authUser => {
                //     authUser
                //     ? this.setState(() => ({ authUser }))
                //     : this.setState(() => ({ authUser: null }));
                // });
            }

            showNotes = item => {
                this.setState({ notesVisible: true, item: item });
            };

            hideNotes = () => {
                this.setState({ notesVisible: false });
            };

            showSignIn = e => {
                this.setState({ notesVisible: false, signInVisible: true });
                e && e.preventDefault();
                // return this.props.history.push('/sign-in');
            };

            hideSignIn = () => {
                this.setState({ notesVisible: false, signInVisible: false });
            };

            render() {
                const { notesVisible, signInVisible } = this.state;
                return (
                    <Provider store={reduxStore}>
                        <InteractivityContext.Provider
                            value={{
                                notesVisible,
                                showNotes: this.showNotes,
                                signInVisible,
                                showSignIn: this.showSignIn,
                            }}
                        >
                            <Component />
                            <NotesDialog
                                item={this.state.item}
                                visible={this.state.notesVisible}
                                onClose={this.hideNotes}
                            ></NotesDialog>
                            {this.state.signInVisible && (
                                <SignIn
                                    visible={this.state.signInVisible}
                                    onHideDialog={this.hideSignIn}
                                ></SignIn>
                            )}
                        </InteractivityContext.Provider>
                    </Provider>
                );
            }
        },
    );

export default withInteractivity;
