import React, { Component } from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md';
import ReactHtmlParser from 'react-html-parser';
import styled, { css } from 'styled-components';
import CCLink from '../../helper/CCLink';
import ThemeContext from '../../components/Theme/ThemeContext';
import Button, { buttonTypes } from '../common/Button';
import {getString} from "../../services/api/store";

const InfoList = styled(ExpansionList)`
    margin-top: 8px;
    .md-expansion-panel--expanded:not(:first-child) {
        margin-top: -8px;
    }
`;

const infoTextStyle = css`
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    line-height: 20px;
    list-style: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    max-width: 100%;
    overflow: wrap;

    h1 {
        color: ${props => props.primary};
        font-size: 20px;
        font-weight: bold;
    }

    h2 {
        font-weight: bold;
        color: ${props => props.primary};
        font-size: 16px;
    }

    h3 {
        font-weight: 500;
        color: ${props => props.primary};
        font-size: 14px;
    }
    a {
        font-size: 14px;
        color: ${props => props.contrast};
    }

    body,
    p,
    h4,
    h5,
    h6 {
        color: #000000;
    }

    img {
        max-width: 100% !important;
    }
    table {
        border: 1px solid #dddddd;
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        padding: 8px;
    }
`;

const InfoWrapper = styled(ExpansionPanel)`
    ${infoTextStyle}
    .md-text {
        font-family: 'Cabin', sans-serif;
        font-weight: bold;
        letter-spacing: 0.5px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        text-transform: uppercase;
    }
    & * {
        font-size: 14px;
    }

    .md-panel-header,
    .md-panel-content {
        padding-left: 16px;
        font-size: 14px;
        line-height: 1.43;
        color: rgba(0, 0, 0, 0.87);
    }

    iframe {
        width: 100%;
        height: 256px;
    }
`;

const ButtonWrapper = styled.div`
    margin: 16px 16px 0 16px;
`;

export const SectionDescription = styled.div`
    ${infoTextStyle};
    margin: 0 16px;
    &.hidden {
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    & * {
        color: rgba(0, 0, 0, 0.87);
        white-space: pre-wrap;
        font-size: 14px;
    }
    iframe {
        width: 100%;
        height: 256px;
    }
`;

const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin: 16px;
`;

class InfoBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            html: '',
            closelink: '',
            readAll: false,
        };
        this.infoBar = React.createRef();
    }

    checkCCLinks(document) {
        const anchors = document.getElementsByTagName('a');
        for (let i = 0; i < anchors.length; i++) {
            const anchor = anchors[i];
            const href = anchor.href;
            if (href.includes('cc://')) {
                CCLink(
                    anchor,
                    this.props.prefix || this.props.rootRoute,
                    this.props.closeLink,
                    this.props.history,
                );
            }
        }
    }

    update(toggle, div) {
        const self = this;
        if (toggle) {
            //Without this setTimeout it's not working
            //Apparently the toggle event is fired before append/attach the div content.
            //Thus, trying to get the anchors without the timeout doesn't work.
            setTimeout(function () {
                self.checkCCLinks(div.current);
            }, 0);
        }
    }
    render() {
        const { info, typeParams } = this.props;
        const showMore = info.text && info.text.length > 400;

        const READ_MORE_TEXT = getString('announcementsReadMore', 'Read more');
        const READ_LESS_TEXT = getString('announcementsReadLess', 'Read less');

        if (info && info.text && info.text.length) {
            const text = info.text;

            return (
                <React.Fragment>
                    <ThemeContext.Consumer>
                        {({ theme }) => (
                            <div ref={this.infoBar}>
                                <InfoList>
                                    {typeParams.previewType &&
                                    typeParams.previewType === 'preview' ? (
                                        <>
                                            <SectionTitle secondary={theme.secondary}>
                                                {info.title || 'Info'}
                                            </SectionTitle>
                                            <SectionDescription
                                                className={this.state.readAll ? '' : 'hidden'}
                                                contrast={theme.contrast}
                                                primary={theme.primary}
                                            >
                                                {ReactHtmlParser(text)}
                                            </SectionDescription>
                                            {showMore && (
                                                <ButtonWrapper>
                                                    <Button
                                                        type={buttonTypes.GREY_LONG}
                                                        background={theme.primary}
                                                        text={this.state.readAll ? READ_LESS_TEXT : READ_MORE_TEXT}
                                                        onClick={() => {
                                                            this.setState({
                                                                readAll: !this.state.readAll,
                                                            });
                                                        }}
                                                    />
                                                </ButtonWrapper>
                                            )}
                                        </>
                                    ) : (
                                        <InfoWrapper
                                            label={info.title || 'Info'}
                                            footer={null}
                                            onExpandToggle={e => this.update(e, this.infoBar)}
                                            contrast={theme.contrast}
                                            primary={theme.primary}
                                        >
                                            <div>{ReactHtmlParser(info.text)}</div>
                                        </InfoWrapper>
                                    )}
                                </InfoList>
                            </div>
                        )}
                    </ThemeContext.Consumer>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

export default InfoBar;
