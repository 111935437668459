import React from 'react';
import styled from 'styled-components';
import { Cell, Grid } from 'react-md';
import Iframe from '../../components/General/Iframe';
import { getWebPage, getWebPageByPage } from './services/webPage';
import AccessRestrictions from '../../AccessRestrictions';

const ListContainer = styled(Grid)`
    margin: 0;
    padding: 0;
`;

const ListCell = styled(Cell)`
    margin: 0;
    background-color: white;
    width: 100%;
`;

const Scrollable = styled.section.attrs({
    className: '',
})`
    height: 100%;
    overflow: auto;
`;

class WebPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            html: '',
            closeLinkPath: props.closeLink ? props.closeLink : '/programme',
            pageId: props.pageId,
            accessRestrictions: null,
            isPasswordProtected: false,
            password: null,
            prefix: props.prefix,
        };
        this.props.setTitle();
    }

    setData = (err, result) => {
        result = result || {};

        let htmlToRender = result.html;
        if (err) {
            htmlToRender = 'Webpage not found';
        }

        htmlToRender = `<style type='text/css'> .container{width:auto !important;}</style>${htmlToRender}`;

        this.setState({
            html: htmlToRender,
            title: result.name || '',
        });

        if (this.props.updateTitle) {
            this.props.updateTitle(result.name);
        }
    };

    getWebpageInfo = () => {
        getWebPageByPage(this.state.pageId, (err, result, page) => {
            if (page) {
                this.setState({
                    accessRestrictions: page.accessRestrictions,
                    isPasswordProtected: !!page.isPasswordProtected,
                    password: page.password,
                    prefix:
                        this.props.prefix || `${this.props.rootRoute}/page/${page.kind}/${page.id}`,
                });
            }

            if (!result) {
                return getWebPage(this.state.pageId, (error, webpage) => {
                    return this.setData(error, webpage);
                });
            }

            return this.setData(err, result);
        });
    };

    componentDidMount() {
        this.getWebpageInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.pageId !== prevState.pageId) {
            this.getWebpageInfo();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.pageId !== prevState.pageId) {
            return {
                pageId: nextProps.pageId,
            };
        } else {
            return null;
        }
    }

    render() {
        return (
            <AccessRestrictions
                pageId={this.state.pageId}
                accessRestrictions={this.state.accessRestrictions}
                isPasswordProtected={this.state.isPasswordProtected}
                password={this.state.password}
                result={() => {
                    if (this.props.detailPage || this.props.fromTabs) {
                        return (
                            <Iframe
                                html={this.state.html}
                                src=""
                                closelink={this.props.rootRoute}
                                prefix={this.state.prefix}
                            />
                        );
                    } else {
                        return (
                            <div>
                                <ListContainer>
                                    <ListCell size={12} tabletSize={8}>
                                        <Scrollable>
                                            <Iframe
                                                html={this.state.html}
                                                src=""
                                                closelink={this.props.rootRoute}
                                                prefix={this.state.prefix}
                                            />
                                        </Scrollable>
                                    </ListCell>
                                </ListContainer>
                            </div>
                        );
                    }
                }}
            />
        );
    }
}

export default WebPage;
