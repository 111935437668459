import React, { useEffect, useState } from 'react';
import { getString } from '../../../services/api/store';
import { getNote, saveNote } from '../../../components/Interactivity';
import ReactHtmlParser from 'react-html-parser';

import {
    Container,
    InteractivityCell,
    InteractivityGrid,
    SectionText,
    SectionTitle,
} from './style';
import Button, { buttonTypes } from '../../common/Button';
import AnalyticsService from '../../../features/analytics/services';
import * as eureka from '../../../services/api/eureka';
import eventBus from '../../../utils/eventBus';
import { NotesPage } from '../../../components/Interactivity/NotesPage';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';

const Notes = ({
    authUser,
    showSignIn,
    theme,
    item,
    renderSmallButton,
    setUpdateNote,
    renderLargeGrayButton,
    showNoteDialog = false,
}) => {
    const ADD_NOTE = getString('createNote') || 'Create note';
    const EDIT_NOTE = getString('editNote') || 'Edit note';
    const NOTE_TITLE = getString('addNote') || 'NOTE';

    const [hasNote, setHasNote] = useState(null);
    const [isNewNote, setIsNewNote] = useState(false);
    const [text, setText] = useState('');
    const [showNotesScreen, setShowNotesScreen] = useState(showNoteDialog);
    const { trackAchievement } = useAchievementActions();

    useEffect(() => {
        setHasNote(item.hasNote);
        getNote(item.id, (err, note) => {
            if (note && note.text && note.text.length) {
                setIsNewNote(false);
                setText(note.text);
            } else {
                setIsNewNote(true);
                setText('');
            }
        });
    }, [item]);

    const NotesButton = () => (
        <Button
            type={buttonTypes.GREY_LONG}
            background={theme.primary}
            icon={text ? 'edit_note' : 'playlist_add'}
            text={text ? EDIT_NOTE : ADD_NOTE}
            onClick={e => {
                if (authUser) setShowNotesScreen(true);
                else showSignIn(e);
            }}
        />
    );

    const addOrEditNote = text => {
        if (!item) {
            return;
        }
        AnalyticsService.addSample('addNote', null, item.id);

        if (isNewNote) {
            trackAchievement(AchievementType.NOTE, item.id);
        }

        return saveNote(
            item,
            text,
            hasNote => {
                item && item.notifyUI && item.notifyUI(hasNote);
            },
            err => {
                eureka.sync(function () {
                    eventBus.emit('updateNotesCount');
                });
                setShowNotesScreen(false);
            },
        );
    };

    if (showNotesScreen) {
        return (
            <NotesPage
                text={text}
                setText={setText}
                addOrEditNote={addOrEditNote}
                setShowNotesScreen={setShowNotesScreen}
                isNewNote={isNewNote}
                theme={theme}
                setUpdateNote={setUpdateNote}
            />
        );
    }

    let displayText = text && text.substring(0, 250);
    if (text && text.length > 250) {
        displayText += '...';
    }

    if (renderSmallButton) {
        return (
            <Button
                type={buttonTypes.GREY}
                background={theme.primary}
                icon={text ? 'edit_note' : 'playlist_add'}
                onClick={e => {
                    if (authUser) setShowNotesScreen(true);
                    else showSignIn(e);
                }}
                tooltip={text ? EDIT_NOTE : ADD_NOTE}
            />
        );
    }

    if (renderLargeGrayButton) {
        return (
            <Button
                type={buttonTypes.GREY_LONG}
                background={theme.primary}
                icon={text ? 'edit_note' : 'playlist_add'}
                text={text ? EDIT_NOTE : ADD_NOTE}
                onClick={e => {
                    if (authUser) setShowNotesScreen(true);
                    else showSignIn(e);
                }}
                tooltip={text ? EDIT_NOTE : ADD_NOTE}
            />
        );
    }

    return (
        <InteractivityGrid>
            <InteractivityCell size={1} key="note">
                {displayText ? (
                    <Container>
                        <SectionTitle>{NOTE_TITLE}</SectionTitle>
                        <SectionText>{ReactHtmlParser(displayText)}</SectionText>
                        <NotesButton />
                    </Container>
                ) : (
                    <NotesButton />
                )}
            </InteractivityCell>
        </InteractivityGrid>
    );
};

export default Notes;
