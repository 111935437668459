import React, { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import toast from 'toasted-notes';
import { useDispatch } from 'react-redux';

import LoginIntro from './LoginIntro';
import { getEventDetails, getImageSource, getLocalAppStateAsync } from '../../../services/api/db';
import Auth from '../../../services/api/auth';
import {
    getFullProfile,
    getLatestPrivacyPolicy,
    getUserSettings,
    isUserAttendingByEmail,
    updateFullProfile,
    updateUserPrivacyPolicy,
    updateUserSettings,
} from '../../../services/api/eureka';
import { validateEmail, validatePassword } from '../constants/strings';
import Password from './Password';
import ForgotPassword from './ForgotPassword';
import eventBus from '../../../utils/eventBus';
import { withRouter } from 'react-router-dom';
import { Container } from '../style/signIn';
import ThemeContext from '../../../components/Theme/ThemeContext';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import UnauthorizedAttendeeContent from './UnauthorizedAttendeeContent';
import Register from './Regiester';
import PrivacyPolicy from './PrivacyPolicy';
import LoginVerificationPage from './LoginVerification';
import AddProfileImage from './AddProfileImage';
import { useGlobalState } from '../../../utils/container';
import ProfileImageSuccessfullyAdded from './ProfileImageSuccessfullyAdded';
import Welcome from './Welcome';
import SettingsModal from '../../../scenes/User/containers/SettingsModal';
import * as palette from '../../../components/General/Variables';
import OAuthPasswordPage from './OAuthPasswordPage';
import ProfileModal from '../../User/containers/profile/ProfileModal';
import { default as Store } from '../../../services/api/store';
import { WithTimezone } from '../../Timezone/context';
import AttendeesSettings from './AttendeesSettings';
import ChatAndMeetingsSettings from './ChatAndMeeetingsSettings';
import {
    AchievementType,
    useAchievementActions,
} from '../../Achievements/hooks/useAchievementActions';
import { loadIsGamificationEnabled } from '../../Achievements/actions';
import { Button, DialogContainer, Toolbar } from 'react-md';
import styled from 'styled-components';

const Dialog = styled(DialogContainer)`
    .md-background--card {
        background: transparent;
        box-shadow: unset;
    }

    .md-toolbar {
        display: none;
    }

    .md-dialog-content--padded {
        padding: 0;
    }

    .md-dialog--centered {
        max-width: unset !important;
        max-height: unset !important;
    }
`;

export const Background = React.memo(({ loginImage }) => (
    <Container>
        <Image loginImage={loginImage} />
    </Container>
));
Background.displayName = 'LoginBackground';

const Image = React.memo(function Image({ loginImage }) {
    return (
        <div
            src={loginImage}
            style={{
                width: '100vw',
                height: '100vh',
                background: `#fff url(${loginImage}) no-repeat center/cover fixed`,
                position: 'relative',
            }}
        />
    );
});

const SignIn = props => {
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const [currentPage, setCurrentPage] = useState('intro');
    const [auth, setAuth] = useState(null);
    const [appState, setAppState] = useState(null);
    const [isAttendeeRestriction, setIsAttendeeRestriction] = useState(false);
    const [mailResent, setMailResent] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [profileVisible, setProfileVisible] = useState(false);
    const [eventDetails, setEventDetails] = useState({});
    const currentPathIsSignIn = props.location.pathname.toLowerCase().includes('/sign-in');
    const webappRefreshed = useRef(false);

    const [email, setEmail] = useState({
        value: '',
        isValidEmail: false,
    });
    const [forgotEmail, setForgotEmail] = useState({
        value: '',
        isValidEmail: false,
    });
    const [firstName, setFirstName] = useState({
        value: '',
        isValidFn: false,
    });
    const [lastName, setLastName] = useState({
        value: '',
        isValiLn: false,
    });
    const [company, setCompany] = useState({
        value: '',
        isValidCompany: false,
    });
    const [jobTitle, setJobTitle] = useState({
        value: '',
        isValidJobTitle: false,
    });
    const [privacyAgreed, setPrivacyAgreed] = useState(false);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);
    const [passwordRegister, setPasswordRegister] = useState({
        value: '',
        isValidPass: false,
    });
    const [signInImage, setSignInImage] = useState(null);
    const [privacyPolicy, setPrivacyPolicy] = useState({});
    const signInBackground = Store.argument && Store.argument.signInBackground;
    const dispatch = useDispatch();
    const { trackAchievement } = useAchievementActions();

    useEffect(() => {
        if (window.performance) {
            if (performance.navigation.type == 1) {
                webappRefreshed.current = true;
            } else {
                webappRefreshed.current = false;
                props.history.push('/sign-in');
            }
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const userOnboardingInfoToken = Cookies.get('userOnboardingInfo');
                const userOnboardingInfo = JSON.parse(
                    Buffer.from(userOnboardingInfoToken, 'base64').toString('binary'),
                );
                if (userOnboardingInfo && userOnboardingInfo.email) {
                    updateFirstName(decodeURIComponent(userOnboardingInfo.firstName));
                    updateLastName(decodeURIComponent(userOnboardingInfo.lastName));
                    changeEmail(userOnboardingInfo.email);
                    Cookies.remove('userOnboardingInfo');
                }
            } catch (e) {
                // console.log('wrong token');
            }

            const appState = await getLocalAppStateAsync();
            const eventDetails = await getEventDetails(appState.eventId, appState.configuratorUrl);
            setAppState(appState);
            setIsAttendeeRestriction(appState.attendingOnly);
            setEventDetails(eventDetails);
            let latestPolicy = await getLatestPrivacyPolicy();
            if (!latestPolicy) {
                latestPolicy = {
                    url: 'https://www.iubenda.com/privacy-policy/51127541',
                    version: 1,
                };
            }
            setPrivacyPolicy(latestPolicy);
            updateAuthSettings();
            Auth.setPolicyFlow(checkPolicy, latestPolicy);
        })();
    }, []);

    useEffect(() => {
        if (signInBackground) {
            getImageSource(signInBackground, (err, img) => {
                setSignInImage(img);
            });
        }
    }, [signInBackground]);

    const updateAuthSettings = () => {
        Auth.getAuthSettings((err, config) => {
            if (config && config.externalEurekaLogin && config.externalLoginOptions) {
                setAuth({
                    ...config.externalLoginOptions,
                    organization: config.externalEurekaLogin,
                    authorizeUrl: config.authorizeUrl,
                    buttonTitle: config.signInButtonTitle,
                    grantTypePasswordState: config.grantTypePasswordState,
                    addEmail: config.addEmail,
                    options: config.options,
                });
            }
        });
    };

    const resetState = () => {
        const initialEmailObject = {
            value: '',
            isValidEmail: false,
        };
        setForgotEmail(initialEmailObject);
        setEmail(initialEmailObject);
        setPassword(null);
        setError(null);
        setCurrentPage('intro');
    };

    const goTo = page => {
        setCurrentPage(page);
    };

    const signin = () => {
        Auth.doSignInWithEmailAndPassword(email.value, password, async (error, isValid) => {
            if (error) {
                setError(error);
            } else if (isValid) {
                if (props.timezone) {
                    props.timezone.setTimezoneFromEurekaSettings();
                }
                setTimeout(() => {
                    eventBus.emit('updateMyProgrammeCount');
                    eventBus.emit('updateNotesCount');
                    eventBus.emit('updateMyFavoritesCount');
                    eventBus.emit('updateConversationsCount');
                }, 1000);
                const cookies = Cookies.get(`event-${appState.eventId}-visited`);
                if (cookies) {
                    const response = await getUserSettings(appState.eventId);
                    if (response && response.available === false) {
                        goTo('chatAndMeetingsSettings');
                    } else {
                        const response = await getUserSettings(appState.eventId);
                        if (response && response.available === false) {
                            goTo('chatAndMeetingsSettings');
                        } else {
                            if (currentPathIsSignIn) {
                                goHome();
                            } else {
                                window.location.reload();
                            }
                        }
                    }
                } else {
                    Cookies.set(`event-${appState.eventId}-visited`);
                    goTo('attendeesSettings');
                }
            } else {
                goTo('verification');
            }
        });
    };

    const goNextFromAttendeesSettings = async profileVisible => {
        setProfileVisible(profileVisible);
        const cookies = Cookies.get(`event-${appState.eventId}-visited`);
        if (cookies) {
            const response = await getUserSettings(appState.eventId);
            if (response && response.available === false) {
                goTo('chatAndMeetingsSettings');
            } else {
                goHome();
            }
        } else {
            goTo('chatAndMeetingsSettings');
        }
    };

    const goHome = () => {
        if (props.onHideDialog) {
            props.onHideDialog();
        }
        return props.history.push('/');
    };

    const forgotPassword = () => {
        goTo('forgotPassword');
    };

    const resetPassword = () => {
        Auth.resetPassword(forgotEmail.value, error => {
            if (error) {
                setError(error);
            } else {
                goTo('resetPasswordSuccess');
            }
        });
    };

    const resetFlow = () => {
        resetState();
        updateAuthSettings();
    };

    const onKeyDown = (e, fn) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            fn();
        }
    };

    const changeEmail = mail => {
        const isValidEmail = validateEmail(mail);
        setEmail({
            value: mail,
            isValidEmail,
        });
        setError(null);
    };

    const updateForgotEmail = mail => {
        const isValidEmail = validateEmail(mail);
        setForgotEmail({
            value: mail,
            isValidEmail,
        });
        setError(null);
    };

    const updateFirstName = name => {
        setFirstName({
            value: name,
            isValidFn: name.length > 0,
        });
    };

    const updatePassword = pass => {
        setPasswordRegister({
            value: pass,
            isValidPass: validatePassword(pass),
        });
    };

    const updateLastName = name => {
        setLastName({
            value: name,
            isValidLn: name.length > 0,
        });
    };

    const updateCompany = name => {
        setCompany({
            value: name,
            isValidCompany: name.length > 0,
        });
    };

    const updateJobTitle = name => {
        setJobTitle({
            value: name,
            isValidJobTitle: name.length > 0,
        });
    };

    const updatePrivacySettings = value => {
        setPrivacyAgreed(value);
    };

    const authorize = isAttendeeRestriction => {
        if (auth.grantTypePasswordState) {
            goTo('oAuthPassword', isAttendeeRestriction);
        } else {
            const msg = Auth.authTab(
                auth.options,
                palette.DIALOG_WIDTH,
                palette.DIALOG_HEIGHT,
                isAttendeeRestriction,
            );
            msg.then(success => {
                if (success) {
                    if (success.err && success.err === 'notAttendingError') {
                        return goTo('unauthorizedAttendee');
                    }
                    goHome();
                }
            }).catch(() => {
                toast.notify('There was an error at log in', {
                    position: 'bottom-left',
                });
            });
        }
    };

    const checkPolicy = (user, policy, token, next) => {
        if (user && policy && policy.version && policy.version > user.acceptedPolicyVersion) {
            setPrivacyPolicy({
                ...policy,
                successAction: next,
                token,
            });
            goTo('privacyPolicy');
        } else {
            next();
        }
    };

    const updatePolicy = async () => {
        if (!privacyPolicy.version) {
            return;
        }
        try {
            await updateUserPrivacyPolicy(privacyPolicy.version, privacyPolicy.token);
            if (privacyPolicy.successAction && typeof privacyPolicy.successAction === 'function') {
                privacyPolicy.successAction();
            }
        } catch {
            console.log('privacyPolicyUpadateError');
        }
    };

    const checkEmail = () => {
        Auth.checkIfEmailExists(email.value, async (error, result) => {
            if (error) {
                setError(error);
            } else {
                if (result.registered && result.email.toLowerCase() === email.value.toLowerCase()) {
                    const isAttending = await isUserAttendingByEmail(appState.id, email.value);

                    if (isAttendeeRestriction && !isAttending) {
                        return goTo('unauthorizedAttendee');
                    }

                    goTo('password');
                } else {
                    const isAttending = await isUserAttendingByEmail(appState.id, email.value);

                    if (isAttendeeRestriction && !isAttending) {
                        return goTo('unauthorizedAttendee');
                    }

                    goTo('register');
                }
            }
        });
    };

    const register = () => {
        if (
            privacyAgreed &&
            firstName.isValidFn &&
            lastName.isValidLn &&
            company.isValidCompany &&
            jobTitle.isValidJobTitle &&
            passwordRegister.isValidPass
        ) {
            Auth.register(
                email.value,
                firstName.value,
                lastName.value,
                passwordRegister.value,
                error => {
                    if (error) {
                        setError(error);
                    } else {
                        goTo('verification');
                    }
                },
            );
        }
    };

    const resendEmail = () => {
        Auth.resendConfirmation(error => {
            if (error) {
                setError(error);
            } else {
                setMailResent(true);
            }
        });
    };

    const confirmEmail = () => {
        Auth.isAccountValidated(error => {
            if (error) {
                setError(error);
            } else {
                Cookies.set(`event-${appState.eventId}-visited`);
                goTo('profileImage');
            }
        });
    };

    const saveProfileInfo = async imageUrl => {
        const userData = await getFullProfile();
        const data = {
            ...userData,
            imageUrl: imageUrl || profileImage,
            companyName: company.value,
            jobTitle: jobTitle.value,
            firstName: firstName.value,
            lastName: lastName,
        };
        await updateFullProfile(data);
        Auth.changeAuthUserData(data);

        if (socket) {
            socket.emit('profileChanged', { id: userData.id });
        }
    };

    const oAuthPasswordAuthenticate = (username, password) => {
        const data = {
            username,
            password,
            state: auth.grantTypePasswordState,
            organization: auth.organization,
        };
        Auth.oAuthPasswordAuthenticate(
            data,
            error => {
                if (error && error === 'notAttendingError') {
                    goTo('unauthorizedAttendee');
                } else if (error) {
                    setError(error);
                } else {
                    resetFlow();
                    goHome();
                }
            },
            isAttendeeRestriction,
        );
    };

    const onSaveProfileSettings = async chatAvailable => {
        const userData = await getFullProfile();
        const settings = {
            visible: profileVisible,
            available: chatAvailable,
            eventId: appState.eventId,
            eventTitle: appState.eventName,
        };

        await updateUserSettings(settings);

        if (socket) {
            socket.emit('privacySettingsUpdated', {
                eventId: appState.eventId,
                userId: userData.id,
            });
        }
        const cookies = Cookies.get(`event-${appState.eventId}-visited`);

        // track achievement
        const isGamificationEnabled = await dispatch(loadIsGamificationEnabled());
        if (isGamificationEnabled) {
            trackAchievement(AchievementType.COMPLETE_PROFILE, undefined, true);
        }

        if (cookies) {
            goHome();
        } else {
            goTo('welcome');
        }
    };

    const buildContent = theme => {
        switch (currentPage) {
            case 'intro':
                return (
                    <LoginIntro
                        goTo={goTo}
                        eventDetails={eventDetails}
                        currentPage={currentPage}
                        attendingOnly={appState && appState.attendingOnly}
                        email={email.value}
                        updateEmail={mail => changeEmail(mail)}
                        onKeyDown={onKeyDown}
                        error={error}
                        isValid={email.isValidEmail}
                        checkEmail={checkEmail}
                        appName={appState && appState.eventName}
                        theme={theme}
                        eurekaOnly={appState && appState.eurekaOnly}
                        auth={auth}
                        authorize={authorize}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );
            case 'email':
                return (
                    <LoginIntro
                        goTo={goTo}
                        appState={appState}
                        currentPage={currentPage}
                        attendingOnly={appState && appState.attendingOnly}
                        email={email.value}
                        updateEmail={mail => changeEmail(mail)}
                        onKeyDown={onKeyDown}
                        error={error}
                        isValid={email.isValidEmail}
                        checkEmail={checkEmail}
                        appName={appState && appState.eventName}
                        theme={theme}
                        eurekaOnly={appState && appState.eurekaOnly}
                        auth={auth}
                        authorize={authorize}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );
            case 'password':
                return (
                    <Password
                        goTo={goTo}
                        email={email.value}
                        updatePassword={pass => setPassword(pass)}
                        onKeyDown={onKeyDown}
                        error={error}
                        signin={signin}
                        isValid={password && password.length > 0}
                        checkEmail={checkEmail}
                        forgotPassword={forgotPassword}
                        theme={theme}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );
            case 'privacyPolicy':
                return (
                    <PrivacyPolicy
                        goToSignIn={resetFlow}
                        privacyPolicy={privacyPolicy}
                        updatePolicy={updatePolicy}
                        theme={theme}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );
            case 'forgotPassword':
                return (
                    <ForgotPassword
                        email={forgotEmail.value}
                        updateEmail={mail => updateForgotEmail(mail)}
                        isValid={forgotEmail.isValidEmail}
                        onKeyDown={onKeyDown}
                        resetPassword={resetPassword}
                        error={error}
                        theme={theme}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );
            case 'resetPasswordSuccess':
                return (
                    <ResetPasswordSuccess
                        theme={theme}
                        goToSignIn={resetFlow}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            case 'unauthorizedAttendee':
                return (
                    <UnauthorizedAttendeeContent
                        theme={theme}
                        onHide={resetFlow}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            case 'register':
                return (
                    <Register
                        password={passwordRegister.value}
                        privacyPolicy={privacyPolicy}
                        updatePassword={updatePassword}
                        firstName={firstName.value}
                        updateFirstName={updateFirstName}
                        register={register}
                        error={error}
                        lastName={lastName.value}
                        updateLastName={updateLastName}
                        theme={theme}
                        company={company.value}
                        updateCompany={updateCompany}
                        jobTitle={jobTitle.value}
                        updateJobTitle={updateJobTitle}
                        isValid={
                            privacyAgreed &&
                            firstName.isValidFn &&
                            lastName.isValiLn &&
                            company.isValidCompany &&
                            jobTitle.isValidJobTitle &&
                            passwordRegister.isValidPass
                        }
                        updatePrivacySettings={updatePrivacySettings}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            case 'verification':
                return (
                    <LoginVerificationPage
                        theme={theme}
                        goTo={goTo}
                        email={email.value}
                        confirmEmail={confirmEmail}
                        resendEmail={resendEmail}
                        mailResent={mailResent}
                        onKeyDown={onKeyDown}
                        error={error}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            case 'profileImage':
                return (
                    <AddProfileImage
                        theme={theme}
                        goTo={goTo}
                        setProfileImage={img => setProfileImage(img)}
                        saveProfileInfo={saveProfileInfo}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            case 'imageAdded':
                return (
                    <ProfileImageSuccessfullyAdded
                        theme={theme}
                        goTo={goTo}
                        profileImage={profileImage}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            case 'attendeesSettings':
                return (
                    <AttendeesSettings
                        theme={theme}
                        goNextFromAttendeesSettings={goNextFromAttendeesSettings}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            case 'chatAndMeetingsSettings':
                return (
                    <ChatAndMeetingsSettings
                        theme={theme}
                        onSaveProfileSettings={onSaveProfileSettings}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            case 'welcome':
                return (
                    <Welcome
                        theme={theme}
                        goTo={goTo}
                        profileImage={profileImage}
                        firstName={firstName.value}
                        lastName={lastName.value}
                        company={company.value}
                        jobTitle={jobTitle.value}
                        goHome={goHome}
                        handleProfileModal={handleProfileModal}
                        attendingOnly={appState && appState.attendingOnly}
                        eurekaOnly={appState && appState.eurekaOnly}
                        onClose={goHome}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            case 'oAuthPassword':
                return (
                    <OAuthPasswordPage
                        auth={auth}
                        theme={theme}
                        authenticate={oAuthPasswordAuthenticate}
                        onKeyDown={onKeyDown}
                        error={error}
                        isModal={props.visible && webappRefreshed.current && !currentPathIsSignIn}
                    />
                );

            default:
                return null;
        }
    };

    const handleSettingsModal = () => {
        setShowSettingsModal(!showSettingsModal);
    };

    const handleCloseSettingsModal = () => {
        setShowSettingsModal(false);
        goHome();
    };

    const closeProfileModal = () => {
        setShowProfileModal(false);
        goHome();
    };

    const handleProfileModal = () => {
        setShowProfileModal(true);
    };

    const signInModalVisible = props.visible;
    if ((appState?.attendingOnly || appState?.eurekaOnly) && !currentPathIsSignIn) {
        props.history.push('/sign-in');
    }

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <Dialog
                        id="speed-boost"
                        visible={true}
                        modal
                        onHide={goHome}
                        width={
                            signInModalVisible && webappRefreshed.current && !currentPathIsSignIn
                                ? palette.DIALOG_WIDTH
                                : '100vw'
                        }
                        height={
                            signInModalVisible && webappRefreshed.current && !currentPathIsSignIn
                                ? palette.DIALOG_HEIGHT
                                : '100vh'
                        }
                        aria-describedby="speed-boost-description"
                        style={{
                            zIndex: '100 !important',
                        }}
                    >
                        <Toolbar
                            id="sign-in-toolbar"
                            title={''}
                            titleId="simple-full-page-dialog-title"
                            actions={
                                <Button
                                    style={{
                                        color: palette.COLOR_TEXT,
                                        marginRight: -25,
                                        marginTop: -10,
                                    }}
                                    icon
                                ></Button>
                            }
                        />
                        {signInModalVisible && webappRefreshed.current && !currentPathIsSignIn ? (
                            <>{buildContent(theme)}</>
                        ) : (
                            <>
                                <Background loginImage={signInImage} />
                                {buildContent(theme)}
                            </>
                        )}
                    </Dialog>
                    {showProfileModal && (
                        <ProfileModal
                            afterSignUp
                            onClose={closeProfileModal}
                            handleSettingsModal={handleSettingsModal}
                        />
                    )}
                    {showSettingsModal && (
                        <SettingsModal afterSignUp onClose={handleCloseSettingsModal} />
                    )}
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(WithTimezone(SignIn));
