export const linkTypes = {
    facebookLink: 'facebook',
    linkedinLink: 'linkedin',
    twitterLink: 'twitter',
    webLink: 'website',
};

// Strings for Profile, Contact and Social modals
export const CONTACT_INFO = 'Contact Information';
export const VISIBLE_FOR_CONTACTS = 'Only visible for your contacts';
export const NEXT = 'Next';
export const SAVE = 'Save';
export const CHECK_PROFILE = 'Check your profile';
export const EDIT_PROFILE = 'Edit profile';
export const SOCIAL_LINKS = 'Social Links';
export const PROFILE_SUBTITLE =
    ' Complete your profile so that others can find you. You can always edit your details later.';
export const STEPS = 'STEP 1 OF 2';
export const PERSONAL_INFORMATION = 'Personal information';
export const CONTACT_INFO_TITLE = 'Contact info';
export const SOCIAL_LINKS_TITLE = 'Social links';

// Strings for Settings modal
export const SETTINGS_TITLE = 'Profile settings';
export const SETTINGS_SUBTITLE =
    'You can always edit these settings again from your profile if you change your mind later.';
export const ATTENDEE_VISIBILITY_TITLE = 'Visible in event attendee list';
export const ATTENDEE_VISIBILITY_DESCRIPTION =
    'If on, you will appear in the event’s attendee list. Being visible in the attendee list will allow other event attendees to see that you are attending this event, to access your profile and to get in touch with you. Please note that you are always visible in a virtual room attendee list.';
export const CHAT_APPOINTMENTS_AVAILABLE_TITLE = 'Allow new chats and appointments';
export const CHAT_APPOINTMENTS_AVAILABLE_DESCRIPTION =
    'If on, other attendees can start a personal chat with you and send you a request for a meeting. Enable this functionality to get in touch with your fellow attendees and get the most out of your event experience.';
export const SETTINGS_STEPS = 'STEP 2 OF 2';
export const EVENT_TIMEZONE_TITLE = 'Timezone';
export const EVENT_TIMEZONE_SUBTITLE = 'Determine time for daily schedule';
export const TIMEZONE_LOCAL_LABEL = 'Local';
export const TIMEZONE_EVENT_LABEL = 'Event';
