import React from 'react';
import throttle from 'lodash/throttle';
import styled from 'styled-components';

import NavigationBar from '../../components/DetailHeader/NavigationBar';
import * as palette from '../../components/General/Variables';
import ListPage from '../../scenes/ListPage';
import SearchPage from '../../scenes/SearchPage';
import TalkRoom from '../../scenes/TalkRoom';
import SearchResultsPage from '../../scenes/SearchPage/components/searchResults';
import MenuPage from '../../scenes/MenuPage';
import ObjectPage from '../../scenes/ObjectPage';
import SponsorPage from '../../scenes/Sponsor';
import TabsPage from '../../scenes/TabsPage';
import WebPage from '../../scenes/WebPage';
import AttendeeList from '../../scenes/Attendees';
import ProgrammePage from '../../scenes/Programme';

const DetailDrawer = styled.div`
    position: fixed;
    top: 64px;
    right: 0;
    width: 480px;
    border-radius: 12px;
    margin: 16px;
    height: calc(100% - 64px - 32px);
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(47, 51, 55, 0.15);
    z-index: 10;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        border-radius: unset;
        z-index: 1000;
        margin: 0;
    }
`;

const Scrollable = styled.section.attrs({
    className: 'scrollable-div',
})`
    height: 100%;
    overflow: auto;
    overflow-y: scroll;
`;

class PageSelector extends React.PureComponent {
    navBar = React.createRef();
    header = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            pageId: '',
            childrenId: '',
            pageType: '',
            title: '',
        };

        this._handleScroll = throttle(this._handleScroll.bind(this), 100);
        this.scroll = 0;
        this.scrollDirection = 'UP';
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.pageId !== prevState.pageId ||
            nextProps.childrenId !== prevState.childrenId
        ) {
            return {
                pageId: nextProps.pageId,
                pageType: nextProps.pageType,
                childrenId: nextProps.childrenId,
            };
        } else {
            return null;
        }
    }

    _handleScroll() {
        if (this.navBar.current) {
            let newScroll = document.getElementById('scrollable').scrollTop;
            if (this.scroll > newScroll && this.scrollDirection !== 'UP' && newScroll > 48) {
                this.navBar.current.className = 'fixed-navbar';
                this.header.current.className = 'fixed-navbar-header';
                this.scrollDirection = 'UP';
            } else if (
                this.scroll <= newScroll &&
                this.scrollDirection !== 'DOWN' &&
                newScroll > 48
            ) {
                this.navBar.current.className = 'auto-navbar';
                this.header.current.className = 'auto-navbar-header';
                this.scrollDirection = 'DOWN';
            }
            this.scroll = newScroll;
        }
    }

    initNavbar() {
        if (this.navBar.current) {
            document.getElementById('root').className = 'noscroll';
            this.navBar.current.className = 'fixed-navbar';
            this.header.current.className = 'fixed-navbar-header';
            this.scrollDirection = 'UP';
        }
    }

    componentDidMount() {
        this.initNavbar();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.initNavbar();
    }

    componentWillUnmount() {
        if (this.navBar.current) {
            document.getElementById('root').className = '';
        }
    }

    setTitle = str => {
        this.setState({ title: str });
    };

    render() {
        let Rendercomponent = {};
        const { pageId, pageType, title, childrenId } = this.state;
        const { detailPage, fromTabs, history, matchUrl, rootRoute } = this.props;

        switch (pageType) {
            case 'webpage': {
                Rendercomponent = WebPage;
                break;
            }
            case 'list': {
                Rendercomponent = ListPage;
                break;
            }
            case 'search': {
                Rendercomponent = SearchPage;
                break;
            }
            case 'talkRoom': {
                Rendercomponent = TalkRoom;
                break;
            }
            case 'searchResults': {
                Rendercomponent = SearchResultsPage;
                break;
            }
            case 'menu': {
                Rendercomponent = MenuPage;
                break;
            }
            case 'tabs': {
                Rendercomponent = TabsPage;
                break;
            }
            case 'object': {
                Rendercomponent = ObjectPage;
                break;
            }
            case 'sponsor': {
                Rendercomponent = SponsorPage;
                break;
            }
            case 'attendees': {
                Rendercomponent = AttendeeList;
                break;
            }
            case 'dailyprogramme': {
                Rendercomponent = ProgrammePage;
                break;
            }
            default: {
                console.error(`Page type: ${pageType} not found on PageSelector!`);
                break;
            }
        }
        if (pageId && pageType) {
            if (detailPage && !fromTabs) {
                return (
                    <DetailDrawer>
                        <Scrollable id="scrollable" onScroll={this._handleScroll}>
                            <div ref={this.navBar}>
                                <NavigationBar
                                    goBack={history.goBack}
                                    closePath={rootRoute}
                                    typeName={title}
                                    background={palette.COLOR_WHITE}
                                />
                            </div>

                            <div ref={this.header}>
                                <Rendercomponent
                                    closeLink={matchUrl}
                                    setTitle={() => {}}
                                    pageId={pageId}
                                    childrenId={childrenId}
                                    rootRoute={rootRoute}
                                    detailPage={detailPage}
                                    fromTabs={fromTabs}
                                    updateTitle={this.setTitle}
                                    prefix={`${rootRoute}/page/${pageType}/${pageId}`}
                                />
                            </div>
                        </Scrollable>
                    </DetailDrawer>
                );
            } else if (pageType !== 'tabs' || !fromTabs) {
                return (
                    <Rendercomponent
                        closeLink={matchUrl}
                        setTitle={() => {}}
                        pageId={pageId}
                        childrenId={childrenId}
                        rootRoute={rootRoute}
                        detailPage={detailPage}
                        fromTabs={fromTabs}
                        prefix={`${rootRoute}/page/${pageType}/${pageId}`}
                    />
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

export default PageSelector;
