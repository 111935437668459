import React from 'react';
import { getObjectClassWithId, getLocalAppState } from '../services/api/db';
import { executeQuery } from '../services/api/graphQlRepository';

const PATTERN = 'cc://';
const PATTERN_OBJECT = 'objectId=';
let history = null;

const getLink = url => {
    let idx = url.indexOf(PATTERN);
    if (idx === -1) {
        return null;
    }

    return url.substring(idx + PATTERN.length);
};

const isObject = itemId => {
    return itemId.indexOf(PATTERN_OBJECT) >= 0;
};

const openURL = item => {
    item.preventDefault();
    if (history) {
        history.forceBack = true;
        history.push(item.target.getAttribute('href'));
    }
};

const getObjectLink = async (item, itemId, prefix) => {
    return new Promise((resolve, reject) => {
        let idx = itemId.indexOf(PATTERN_OBJECT);
        let objectId = itemId.substring(idx + PATTERN_OBJECT.length);
        getObjectClassWithId(objectId, (err, objectClass, objectItem) => {
            let url = null;

            if (err) {
                console.log(err);
                reject(err);
            }

            if (objectClass === 'place') {
                url = `/floorplan/${objectItem.floorplan}/${objectId}`;
            } else {
                url = `${prefix}/${objectClass}/${objectId}`;
            }

            if (item.nodeType == 1) {
                item.setAttribute('href', url);
                item.addEventListener('click', openURL);
            }

            resolve(url);
        });
    });
};

const getPageLink = (item, itemId, prefix) => {
    return new Promise(resolve => {
        getLocalAppState(async (err, config) => {
            const { eventId } = config;
            const pages = await executeQuery('getPagesWithEventAndName', {
                name: itemId,
                event: eventId,
            });

            if (!pages.length) {
                return;
            }

            const [page] = pages;
            if (page) {
                let url = null;
                switch (page.kind) {
                    case 'floorplan':
                        url = `/floorplan`;
                        break;
                    case 'dailyprogramme':
                        url = `/dailyprogramme`;
                        break;
                    case 'webpage':
                        url = `${prefix}/page/${page.kind}/${page.id}`;
                        break;
                    case 'list':
                        url = `${prefix}/page/${page.kind}/${page.id}`;
                        break;
                    default:
                        url = `${prefix}/page/${page.kind}/${page.id}`;
                        break;
                }

                if (item.nodeType == 1) {
                    item.setAttribute('href', url);
                    item.addEventListener('click', openURL);
                }

                resolve(url);
            }
        });
    });
};

const CCLink = async (item, prefix, closeLink, routerHistory) => {
    if (item === null || item.href === null) {
        return null;
    }
    let link = item.href;
    let itemId = getLink(link);
    history = routerHistory;

    //If it is not a CCLink
    if (itemId === null) {
        return null;
    }

    let result = '';
    if (isObject(itemId)) {
        result = await getObjectLink(item, itemId, prefix);
    } else {
        result = await getPageLink(item, itemId, closeLink);
    }
    return result;
};

export default CCLink;

export const checkCCLinks = async (document, prefix, closelink, history) => {
    const anchors = document.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i++) {
        const anchor = anchors[i];
        const href = anchor.href;
        const errors = [];
        if (href.includes('cc://')) {
            try {
                await CCLink(anchor, prefix, closelink, history);
            } catch (e) {
                errors.push(e);
            }
        } else {
            anchor.target = '_blank';
        }
    }
};

const getDataValueFromUnknownLevel = (arr, key, result) => {
    arr.forEach(function (item) {
        for (let keys in item) {
            if (keys === key) {
                result.push(item[key]);
            } else if (Array.isArray(item[keys])) {
                getDataValueFromUnknownLevel(item[keys], key, result);
            }
        }
    });
    return result;
};

export const CCLinkTransform = (node, prefix, history, rootRoute) => {
    if (node.name === 'a' && node.attribs && node.attribs.href) {
        if (node.attribs.href.includes('cc://')) {
            return (
                <a
                    href={node.attribs.href}
                    onClick={async e => {
                        e.preventDefault();
                        const translatedLink = await CCLink(
                            node.attribs,
                            prefix,
                            rootRoute,
                            history,
                        );
                        if (history) {
                            history.forceBack = true;
                            history.push(translatedLink);
                        }
                    }}
                >
                    {node.children[0].data}
                </a>
            );
        } else {
            if (!node.children && !node.children[0] && !node.children[0].data) {
                let result = [];
                getDataValueFromUnknownLevel(node.children, 'data', result);

                if (result && result[0]) {
                    return (
                        <a href={node.attribs.href} target="_blank" rel="noreferrer">
                            {result[0]}
                        </a>
                    );
                }
            }
            return (
                <a href={node.attribs.href} target="_blank" rel="noreferrer">
                    {node.children && node.children[0] && node.children[0].data}
                </a>
            );
        }
    }
};
