import Resizer from 'react-image-file-resizer';

export const getImageSizes = (url) => {
    return new Promise((resolve) => {
        const image = new Image;

        image.onload = () => {
            resolve({
                width: image.width,
                height: image.height,
            })
        };

        image.src = url;
    });
};

export const compressImage = (image, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            image,
            maxWidth,
            maxHeight,
            'JPEG',
            100,
            0,
            uri => resolve(uri),
            'file',
        );
    });
};

const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.src = url
    });

export const getCroppedImage = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(file)
        }, 'image/jpeg')
    })
}
