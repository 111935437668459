import async from 'async';
import React, {createRef, useEffect, useState} from 'react';
import { Slide } from 'react-slideshow-image';
import styled from 'styled-components';
import { getImageSource, getObjectClassWithId } from '../../services/api/db';
import { Link } from 'react-router-dom';
import AnalyticsService from '../../features/analytics/services';
import useDimensions from "../../hooks/useDimensions";

const Container = styled.div`
    width: 100%;
    margin: auto;
    margin-bottom: 16px;
`;

const AStyled = styled.a`
    text-decoration: none !important;
`;

const LinkStyled = styled(Link)`
    text-decoration: none !important;
`;

const Slideshow = ({ data, noIndicators, setExternalObject, rootRoute, itemDuration = 5000 }) => {
    const ids = JSON.stringify(data.map(item => item._id));
    const [items, setItems] = useState([]);

    const properties = {
        duration: itemDuration,
        transitionDuration: 1000,
        infinite: true,
        indicators: noIndicators ? false : true,
        arrows: noIndicators ? false : true,
        onChange: (oldIndex, newIndex) => {
            const item = items[newIndex];
            const id = item && item.id;

            AnalyticsService.addSample('bannerImageView', id, id);
        },
    };

    const onImageClick = item => {
        const id = item && item.id;
        AnalyticsService.addSample('bannerImageClick', id, id);
    };

    useEffect(() => {
        if (data && data.length) {
            const images = [];
            async.eachSeries(
                data,
                function (image, callback) {
                    if (image.linkType === 'object') {
                        getObjectClassWithId(image.objectId, (err, objectClass, objectItem) => {
                            if (err) {
                                console.log(err);
                                return callback();
                            }

                            getImageSource(image._id, (err, result) => {
                                if (result) {
                                    images.push({
                                        id: image._id,
                                        src: result,
                                        url: image.url,
                                        objectClass,
                                        objectItem,
                                        linkId: image.objectId,
                                    });
                                }
                                callback();
                            });
                        });
                    } else {
                        getImageSource(image._id, (err, result) => {
                            if (result) {
                                images.push({
                                    id: image._id,
                                    src: result,
                                    url: image.url,
                                    linkType: image.linkType,
                                    screen: image.screen,
                                });
                            }
                            callback();
                        });
                    }
                },
                err => {
                    if (!err) {
                        setItems(images);
                    }
                },
            );
        } else {
            setItems([]);
        }
    }, [ids]);

    const ref = createRef();
    ref.current = document.getElementById('feed-wrapper');
    const { width: wrapperWidth } = useDimensions(ref);

    const renderImages = () => {
        return items.map((item, i) => {
            let imageElement;

            if (item.linkType === 'url' && item.url) {
                imageElement = (
                    <AStyled target="_blank" href={item.url} key={`${item.src}_${i}`}>
                        <img
                            key={i}
                            style={{ width: wrapperWidth }}
                            src={item.src}
                            alt={`slide-item-${i}`}
                        />
                    </AStyled>
                );
            } else if (item.objectClass) {
                imageElement = (
                    <LinkStyled
                        key={'link_' + i}
                        to={`${rootRoute}/${item.objectClass.replace(/.$/, '')}/${
                            item.objectItem.id
                        }`}
                        onClick={() => setExternalObject(item.objectItem)}
                    >
                        <img
                            key={i}
                            style={{ width: wrapperWidth }}
                            src={item.src}
                            alt={`slide-item-${i}`}
                        />
                    </LinkStyled>
                );
            } else if (item.linkType === 'page' && item.screen) {
                imageElement = (
                    <LinkStyled key={'link_' + i} to={`/${item.screen}`}>
                        <img
                            key={i}
                            style={{ width: wrapperWidth }}
                            src={item.src}
                            alt={`slide-item-${i}`}
                        />
                    </LinkStyled>
                );
            } else {
                imageElement = (
                    <img key={i} style={{ width: wrapperWidth }} src={item.src} alt={`slide-item-${i}`} />
                );
            }

            return <span onClick={() => onImageClick(item)}>{imageElement}</span>;
        });
    };

    if (items.length < 2) {
        properties.arrows = false;
        properties.indicators = false;
        properties.infinite = false;
    }

    return (
        <Container>
            <Slide {...properties}>{renderImages()}</Slide>
        </Container>
    );
};

export default Slideshow;
