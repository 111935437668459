import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import toast from 'toasted-notes';

import withInteractivity from './components/Interactivity/withInteractivity';
import withSearchResults from './components/SearchResults/withSearchResults';
import withSocket from './components/Session/withSocket';
import withAuthentication from './components/Session/withAuthentication';
import ThemeContext, { useTheme } from './components/Theme/ThemeContext';
import withTheme from './components/Theme/withTheme';
import NetworkDetector from './NetworkDetector';
import { TimezoneContext, TimezoneContextProvider } from './scenes/Timezone/context';
import AppNavigation from './Navigation/AppNavigation';
import SocketContext from './components/Session/SocketContext';
import PlatformInfoNotification from './scenes/User/components/platformInfo/PlatformInfoNotification';
import withPlatformInfoUpdates from './scenes/User/components/platformInfo/PlatformInfoContextProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export const EVENT_NOT_FOUND_MESSAGE = 'AppState not found';
export const EVENT_NOT_CONFIGURED_MESSAGE = 'Navigation not configured';

// Pass set theme function in the app component
const AppWithThemeAndUpdates = props => {
    const { theme } = useTheme();
    const [newVersionAvailable, setNewVersionAvailable] = useState(props.newVersionAvailable);
    const [notificationDismissed, setNotificationDismissed] = useState(false);

    useEffect(() => {
        if (!props.waitingWorker) {
            serviceWorkerRegistration.register({
                onUpdate: reg => props.onServiceWorkerUpdate(reg),
                onSuccess: () => {},
            });
        }
        setNewVersionAvailable(props.newVersionAvailable);
    }, [props.newVersionAvailable]);

    const versionUpdateAlreadyDisplayed = document.getElementById('version-update-notification');

    if (
        newVersionAvailable &&
        theme &&
        theme.primary &&
        !versionUpdateAlreadyDisplayed &&
        !notificationDismissed
    ) {
        toast.notify(
            ({ onClose }) => (
                <PlatformInfoNotification
                    onClose={() => {
                        setNotificationDismissed(true);
                        onClose();
                    }}
                    onUpdate={props.updateServiceWorker}
                    theme={theme}
                />
            ),
            {
                position: 'top-right',
                duration: null,
            },
        );
    }

    return (
        <SocketContext.Consumer>
            {({ socket }) =>
                socket && (
                    <TimezoneContext.Consumer>
                        {({ initTimezoneContext }) => (
                            <ThemeContext.Consumer>
                                {({ setTheme }) => (
                                    <AppNavigation
                                        {...props}
                                        socket={socket}
                                        initTimezoneContext={initTimezoneContext}
                                        setTheme={setTheme}
                                        newVersionAvailable={props.newVersionAvailable}
                                        updateServiceWorker={props.updateServiceWorker}
                                        waitingWorker={props.waitingWorker}
                                    />
                                )}
                            </ThemeContext.Consumer>
                        )}
                    </TimezoneContext.Consumer>
                )
            }
        </SocketContext.Consumer>
    );
};

const AppWithRouter = withRouter(AppWithThemeAndUpdates);

// Add Theme and Interactivity contexts(Theme needs to be added before Interactivity because Interactivity is using it);
const InteractiveApp = withTheme(withInteractivity(withPlatformInfoUpdates(AppWithRouter)));

const AppWithTimezone = props => (
    <TimezoneContextProvider>
        <InteractiveApp {...props}></InteractiveApp>
    </TimezoneContextProvider>
);

// Passes searchResults context in
const searchResults = withSearchResults(AppWithTimezone);

// Add Authentication context
export default NetworkDetector(withSocket(withAuthentication(searchResults)));
