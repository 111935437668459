import React, { useState } from 'react';
import styled from 'styled-components';
import HowToVoteIcon from '@material-ui/icons/HowToVoteOutlined';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

import { votesColorScheme } from './constants';
import VoteResult from './VoteResult';
import ProgressBar from './ProgressBar';
import EnlargedImageModal from '../common/EnlargedImageModal';

const VotingResultsWrapper = styled.div`
    background-color: #ffffff;
    z-index: 1;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    height: 100%;
`;

const Img = styled.img`
    height: 100%;
    z-index: 1000;
    border-radius: 8px;
`;

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    height: 95px;
    width: 221px;
    align-items: center;
    justify-content: flex-start;
    margin-right: 16px;
    overflow: hidden;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 90px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-gap: 16px;
    margin-left: ${props => (props.nomarginleft ? '87px' : '237px')};
`;

const TotalVotesRecordingContainer = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.86px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ImageWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const ZoomIconContainer = styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.54);
    color: #fff;
    height: 32px;
    width: 40px;
    border-radius: 6px;
    z-index: 1000;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const PlaceholderContainer = styled.div`
    width: fit-content;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f3;
    padding: 2.5px 8px;
    border-radius: 16px;
`;

const PlaceholderText = styled.span`
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-family: Cabin, sans-serif;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.43px;
    text-align: center;
`;

const VotingResults = ({
    activePoll,
    pollSet,
    totalVotes,
    optionsWithVotes,
    showVoteResults,
    currentPollAnswer,
}) => {
    const { title, imageUrl } = activePoll;
    const isCorrectOptionSet = !!optionsWithVotes.find(option => option.correct);
    const [isImageHovered, setIsImageHovered] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    return (
        <VotingResultsWrapper>
            {!showVoteResults && (
                <ProgressBar pollSet={pollSet} activePoll={activePoll} activeColor={'#1fa294'} />
            )}
            <FirstRow>
                <PlaceholderContainer>
                    <PlaceholderText>VOTING RESULTS</PlaceholderText>
                </PlaceholderContainer>
                <TotalVotesRecordingContainer>
                    {totalVotes}
                    <HowToVoteIcon />
                </TotalVotesRecordingContainer>
            </FirstRow>
            <FlexRow>
                {imageUrl && (
                    <ImageContainer
                        onMouseEnter={() => {
                            setIsImageHovered(true);
                        }}
                        onMouseLeave={() => {
                            setIsImageHovered(false);
                        }}
                        onClick={() => {
                            setIsImageModalOpen(true);
                        }}
                    >
                        <ImageWrapper>
                            <Img src={imageUrl} />
                            {isImageHovered && (
                                <ZoomIconContainer>
                                    <ZoomOutMapIcon />
                                </ZoomIconContainer>
                            )}
                        </ImageWrapper>
                    </ImageContainer>
                )}
                <FlexColumn>
                    <Title>{title}</Title>
                </FlexColumn>
            </FlexRow>
            <GridContainer
                style={{
                    gridTemplateColumns: 'minmax(0, 1fr)  '.repeat(1),
                    gridAutoRows: '1fr',
                }}
                nomarginleft={!imageUrl ? 1 : 0}
            >
                {optionsWithVotes.map((option, index) => (
                    <VoteResult
                        key={`vote-result-${index}`}
                        label={String.fromCharCode(65 + index)}
                        option={option}
                        totalVotes={totalVotes}
                        color={votesColorScheme[index]}
                        showCorrectAnswer={showVoteResults}
                        currentPollAnswer={currentPollAnswer}
                        isCorrectOptionSet={isCorrectOptionSet}
                    />
                ))}
            </GridContainer>
            <EnlargedImageModal
                open={isImageModalOpen}
                handleClose={() => {
                    setIsImageModalOpen(false);
                }}
                imageUrl={imageUrl}
            />
        </VotingResultsWrapper>
    );
};

export default VotingResults;
