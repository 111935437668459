import React, { useState } from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import { darken } from '@material-ui/core';
import { useTheme } from '../../components/Theme/ThemeContext';

const ButtonContainer = styled.div`
    width: 100%;
    padding: 8px 16px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: ${props => (props.enabled ? 'pointer' : 'default')};
    position: relative;
`;

const GreenButtonContainer = styled(ButtonContainer)`
    background-color: ${props => (props.background ? props.background : '#00a933')};
    width: fit-content;

    &:hover {
        background-color: ${props =>
            props.background ? darken(props.background, 0.2) : darken('#00a933', 0.2)};
    }

    &:active {
        background-color: ${props =>
            props.background ? darken(props.background, 0.4) : darken('#00a933', 0.2)};
    }
`;

const GreenButtonReverseContainer = styled(ButtonContainer)`
    background-color: ${props => (props.background ? props.background + '30' : '#00a933' + '30')};
    width: fit-content;
    ${props => props.background && `color: ${props.background} !important`};

    &:hover {
        background-color: ${props =>
            props.background ? props.background + '30' : '#00a933' + '30'};
        filter: brightness(90%);
    }

    &:active {
        background-color: ${props =>
            props.background ? props.background + '30' : '#00a933' + '30'};
        filter: brightness(70%);
    }
`;

const GhostButtonContainer = styled(ButtonContainer)`
    background: transparent;
    width: fit-content;
`;

const GhostLongButtonContainer = styled(GhostButtonContainer)`
    width: 100%;
`;

const GreenLongReverseButtonContainer = styled(GreenButtonReverseContainer)`
    width: 100%;
`;

const GreenLongButtonContainer = styled(GreenButtonContainer)`
    width: 100%;
`;

const GreyButtonContainer = styled(ButtonContainer)`
    background-color: ${props =>
        props.enabled ? 'rgba(239, 239, 239, 1)' : 'rgba(0, 0, 0, 0.38)'};
    opacity: 1;
    width: fit-content;
    ${props =>
        props.enabled &&
        `
        &:hover {
            background-color: gba(239, 239, 239, 0.4);
            filter: brightness(90%);
        }
        &:active {
            background-color: gba(239, 239, 239, 0.12);
            filter: brightness(70%);
        }
    `};
`;

const GreyLongButtonContainer = styled(GreyButtonContainer)`
    width: 100%;
`;

const ButtonIcon = styled(FontIcon)`
    margin-right: ${props => (props.marginRight ? 8 : 0)}px;
    color: ${props => (!props.enabled ? '#fff' : props.iconCustomColor || '#000000')} !important;
    font-size: 24px !important;
`;

const ButtonIconLight = styled(ButtonIcon)`
    color: #ffffff !important;
`;

const ButtonText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: ${props => (props.enabled ? '#000000' : '#fff')};
    text-align: center;
`;

const ButtonTextLight = styled(ButtonText)`
    color: #ffffff;
`;

const ButtonIconLightReverse = styled(ButtonIcon)`
    color: ${props => props.background || '#00a933'};
`;

const ButtonTextLightReverse = styled(ButtonText)`
    color: ${props => props.background || '#00a933'};
`;

export const Tooltip = styled.div`
    width: fit-content;
    padding: 4px 8px;
    height: 28px;
    background: ${props => props.background || 'rgba(0, 0, 0, 0.87)'};
    border-radius: 4px;
    position: absolute;
    bottom: ${props => (props.bottom ? props.bottom : '48px')};
    ${props => props.left && `left: ${props.left}`};
    ${props => props.top && `top: ${props.top}`};
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
    font-stretch: normal;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 21;
    white-space: nowrap;
`;

export const buttonTypes = {
    GREEN: 'green',
    GREEN_LONG: 'green-long',
    GREEN_REVERSE: 'green-reverse',
    GREEN_LONG_REVERSE: 'green-long-reverse',
    GREY: 'grey',
    GREY_LONG: 'grey-long',
    GHOST: 'ghost',
    GHOST_LONG: 'ghost-long',
};

const Button = ({
    type,
    text,
    icon,
    outlinedIcon = false,
    onClick,
    background,
    enabled = true,
    style,
    children,
    image,
    imageStyle,
    chip,
    'data-qa': dataQa,
    iconCustomColor,
    tooltip,
}) => {
    const [hover, setHover] = useState(false);
    const getContainer = type => {
        switch (type) {
            case buttonTypes.GREEN:
                return GreenButtonContainer;
            case buttonTypes.GREEN_LONG:
                return GreenLongButtonContainer;
            case buttonTypes.GREY:
                return GreyButtonContainer;
            case buttonTypes.GREY_LONG:
                return GreyLongButtonContainer;
            case buttonTypes.GREEN_REVERSE:
                return GreenButtonReverseContainer;
            case buttonTypes.GREEN_LONG_REVERSE:
                return GreenLongReverseButtonContainer;
            case buttonTypes.GHOST:
                return GhostButtonContainer;
            case buttonTypes.GHOST_LONG:
                return GhostLongButtonContainer;
            default:
                return GreenButtonContainer;
        }
    };

    const getIcon = type => {
        switch (type) {
            case buttonTypes.GREEN:
            case buttonTypes.GREEN_LONG:
                return ButtonIconLight;
            case buttonTypes.GREY:
            case buttonTypes.GREEN_REVERSE:
                return ButtonIconLightReverse;
            case buttonTypes.GREEN_LONG_REVERSE:
                return ButtonIconLightReverse;
            default:
                return ButtonIcon;
        }
    };

    const getText = type => {
        switch (type) {
            case buttonTypes.GREEN_REVERSE:
                return ButtonTextLightReverse;
            case buttonTypes.GREEN_LONG_REVERSE:
                return ButtonTextLightReverse;
            case buttonTypes.GHOST:
                return ButtonTextLightReverse;
            case buttonTypes.GHOST_LONG:
                return ButtonTextLightReverse;
            case buttonTypes.GREEN:
            case buttonTypes.GREEN_LONG:
                return ButtonTextLight;
            case buttonTypes.GREY:
            default:
                return ButtonText;
        }
    };

    const Container = getContainer(type);
    const Icon = getIcon(type);
    const Text = getText(type);
    const { theme } = useTheme();

    return (
        <Container
            background={background || theme.primary}
            enabled={enabled}
            style={style}
            onClick={e => {
                if (enabled && onClick) {
                    if (e) onClick(e);
                    else onClick();
                }
            }}
            data-qa={dataQa}
            onMouseEnter={() => {
                if (tooltip) {
                    setHover(true);
                }
            }}
            onMouseLeave={() => setHover(false)}
        >
            {icon && (
                <Icon
                    marginRight={!!text}
                    background={background}
                    iconCustomColor={iconCustomColor}
                    enabled={enabled}
                    iconClassName={`material-icons${outlinedIcon ? '-outlined' : ''}`}
                >
                    {icon}
                </Icon>
            )}
            {image && (
                <img src={image} alt={'button-image'} className="md-btn" style={imageStyle}></img>
            )}
            <Text background={background} enabled={enabled}>
                {text}
            </Text>
            {chip}
            {children}
            {tooltip && hover && <Tooltip>{tooltip}</Tooltip>}
        </Container>
    );
};

export default Button;
