import moment from 'moment';
import async from 'async';
import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import sortBy from 'lodash/sortBy';

import { executeListQuery, executeQuery, getItem } from '../../../services/api/graphQlRepository';
import Auth from '../../../services/api/auth';
import AppointmentService from '../../Appointments/services/AppointmentService';
import { APPOINTMENT_PARTICIPANT_STATUS } from '../../Appointments/constants';

const getDays = async function (pageId, timeslotToTimezone, next) {
    getItem('pages', pageId, async (err, page) => {
        if (err) {
            return next(err);
        }

        if (page && page.id && page.params && page.params.filter && page.params.filter.typeId) {
            const root = page.params.filter.typeId;
            let timeslots = await executeQuery('getTimeslotsWithType', {
                type: root,
            });

            if (!timeslots.length) {
                return next(null, []);
            }

            // timeslots = timeslots.map(session => {
            //     return timeslotToTimezone(session);
            // });

            next(null, sortBy(timeslots, ['start']), page.params.skipTimeframes);
        } else {
            next('no programme page found or root is not configured');
        }
    });
};

const mapNoGroupedToObject = (group, groupId) => {
    switch (groupId) {
        case 'nonLocated':
            return {
                group: {
                    id: 'noPlace',
                    name: 'No specified location',
                },
                items: sortBy(group[groupId], ['start']),
            };
        case 'nonClassified':
            return {
                group: {
                    id: 'noClassifier',
                    name: 'No specified classifier',
                },
                items: sortBy(group[groupId], ['start']),
            };
        case 'nonGrouped':
            return {
                group: {
                    id: 'noGroup',
                },
                items: sortBy(group[groupId], ['start']),
            };
        case 'other':
            return {
                group: {
                    id: 'other',
                    name: 'Other',
                },
                items: sortBy(group[groupId], ['start']),
            };
        default:
            return {
                group: {
                    id: 'noPlace',
                    name: 'No specified location',
                },
                items: sortBy(group[groupId], ['start']),
            };
    }
};

const groupByPlace = sessions => {
    return groupBy(sessions, session => {
        if (session) {
            if (session.objectClass === 'Appointment') {
                return 'other';
            }

            return session.locations && session.locations.length
                ? session.locations[0]._id
                : 'nonLocated';
        }

        return 'noSession';
    });
};

const groupByClassifier = (sessions, classifiers) => {
    // Group items by classifier id
    const group = classifiers.reduce((acc, cls) => {
        const sessionsForClassifier = sessions.filter(
            session =>
                session.classifications && session.classifications.find(c => c._id === cls.id),
        );

        // Remove the items that has been already grouped
        sessions = sessions.filter(
            session => !sessionsForClassifier.find(sfc => sfc.id === session.id),
        );

        acc[cls.id] = sessionsForClassifier;

        return acc;
    }, {});

    group.other = sessions.filter(session => session.objectClass === 'Appointment');
    sessions = sessions.filter(session => session.objectClass !== 'Appointment');
    group.nonClassified = sessions;

    return group;
};

const shouldDisplayAppointment = appointment =>
    appointment &&
    appointment.members &&
    appointment.members.length &&
    appointment.members[0].AppointmentGroup &&
    (appointment.members[0].AppointmentGroup.status === APPOINTMENT_PARTICIPANT_STATUS.WAITING ||
        appointment.members[0].AppointmentGroup.status === APPOINTMENT_PARTICIPANT_STATUS.ACCEPTED);

const getUserAppointmentsFromDay = async day => {
    if (!Auth.isUserAuthenticated()) {
        return [];
    }

    const user = Auth.getUser();
    const appointments = await AppointmentService.getAllAppointments(user.id);
    const appointmentsToDisplay = appointments.filter(shouldDisplayAppointment);
    const dayStart = moment.utc(day.start);
    const dayEnd = moment.utc(day.end);

    const filteredAppointments = appointmentsToDisplay.filter(appointment => {
        let appointmentStart = moment.utc(appointment.start);

        if (!appointmentStart.isValid()) {
            appointmentStart = moment.unix(appointment.start);
        }

        return appointmentStart.isSameOrAfter(dayStart) && appointmentStart.isSameOrBefore(dayEnd);
    });

    // Convert unix time to string format
    return filteredAppointments.map(app => ({
        ...app,
        start: moment.unix(app.start).utc().toString(),
        end: moment.unix(app.end).utc().toString(),
        type: 'appointment',
    }));
};

var getSessionsFromDay = async function (day, skipTimeframes, grouping, timeslotToTimezone, next) {
    let sessions = [];
    const { groupingOption, groupingValue } = grouping;

    const timeframes = await executeListQuery('findTimeslots', {
        parent: {
            eq: day.id,
        },
    });
    // Merge appointents (eureka entity) with other sessions
    const appointments = await getUserAppointmentsFromDay(day);
    sessions.push(...appointments);
    if (!timeframes.length) {
        return next('no timeframes found');
    }

    if (!skipTimeframes) {
        for (let index = 0; index < timeframes.length; index++) {
            const slots = await executeListQuery('findTimeslots', {
                parent: {
                    eq: timeframes[index]['id'],
                },
            });

            sessions.push(...slots);
        }
    } else {
        sessions.push(...timeframes);
    }

    sessions = sessions.map(session => {
        return timeslotToTimezone(session);
    });

    const result = [];
    const sortedSessionsByEnd = sessions.sort((a, b) => new Date(b.end) - new Date(a.end));
    const last = (sortedSessionsByEnd && sortedSessionsByEnd[0]) || maxBy(sessions, o => o.end);
    const first = minBy(sessions, o => o.start);

    const timerange = {
        start: first && moment.utc(first.start).hour(),
        end: last && moment.utc(last.end).hour() + 1,
    };

    if (timerange.start > timerange.end) {
        timerange.end = timerange.end + 24;
    }

    let classifiers = [];
    let group;
    if (groupingOption === 'byPlace') {
        group = groupByPlace(sessions);
    } else if (groupingOption === 'noGrouping') {
        group = { nonGrouped: sessions };
    } else if (groupingOption === 'byClassifier') {
        classifiers = await executeQuery('getClassifiersWithType', { type: groupingValue });
        group = groupByClassifier(sessions, classifiers);
    } else {
        // Default case: group by location
        group = groupByPlace(sessions);
    }

    const groups = Object.keys(group);
    async.each(
        groups,
        function (groupId, cb) {
            // if no group defined, return early
            if (
                groupId === 'nonLocated' ||
                groupId === 'nonClassified' ||
                groupId === 'nonGrouped' ||
                groupId === 'other'
            ) {
                const object = mapNoGroupedToObject(group, groupId);
                result.push(object);

                return cb();
            }

            if (groupingOption === 'byPlace' || !groupingOption) {
                getItem('places', groupId, (err, place) => {
                    const object = {
                        group: {
                            id: groupId || 'noPlace',
                            name: place && place.name ? place.name : 'No specified location',
                            orderingName: place && place.orderingName,
                        },
                        items: sortBy(group[groupId], ['start']),
                    };
                    result.push(object);

                    return cb(err);
                });
            } else if (groupingOption === 'byClassifier') {
                const classifier = classifiers.find(cl => cl.id === groupId);

                const object = {
                    group: {
                        id: groupId || 'noClassifier',
                        name:
                            classifier && classifier.name
                                ? classifier.name
                                : 'No specified classifier',
                        orderingName: classifier && classifier.orderingName,
                    },
                    items: sortBy(group[groupId], ['start']),
                };
                result.push(object);

                return cb(null);
            } else {
                return cb('Invalid grouping option');
            }
        },
        err => {
            next(
                err,
                sortBy(result, [
                    function (elem) {
                        return elem.group.orderingName;
                    },
                    function (elem) {
                        return elem.group.name;
                    },
                ]),
                timerange,
            );
        },
    );
};

export { getDays, getSessionsFromDay };
