import React from 'react';

import { ImageAvatar } from '../../Icons';
import { formatLastMessageDate } from '../../../services/api/data';
import {
    ListItemStyled,
    listContentStyle,
    PrimaryTitle,
    Timestamp,
    SecondaryTitle,
    SecondaryTitleMessage,
} from './styles';

const Institution = ({ conversation }) => {
    const { lastMessage, title, externalObject: institution } = conversation;
    const displayName =
        lastMessage.participant.displayName ||
        `${lastMessage.participant.firstName} ${lastMessage.participant.lastName}`;
    const messageTimestamp = formatLastMessageDate(lastMessage.timestamp);

    const Secondary = ({ name, message, timestamp }) => (
        <SecondaryTitle>
            <SecondaryTitleMessage>
                <strong>{`${name}: `}</strong>
                {message}
            </SecondaryTitleMessage>
            <Timestamp>{timestamp}</Timestamp>
        </SecondaryTitle>
    );

    return (
        <ListItemStyled
            key={'conversation_institution_' + conversation.id}
            contentStyle={listContentStyle}
            primaryText={<PrimaryTitle>{title}</PrimaryTitle>}
            secondaryText={
                <Secondary
                    name={displayName}
                    message={lastMessage.txt}
                    timestamp={messageTimestamp}
                />
            }
            leftAvatar={
                <ImageAvatar
                    key={'institution_image_' + institution.id}
                    imageId={institution.imageUrl || institution.image}
                    avatarType={'institutions'}
                />
            }
            threeLines
        />
    );
};

export default Institution;
