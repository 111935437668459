export const navigationIconsWithClass = [
    { configuratorIcon: 'calendar-outline', webappIcon: 'event' },
    { configuratorIcon: 'calendar-text-outline', webappIcon: 'event_note' },
    { configuratorIcon: 'forum-outline', webappIcon: 'forum' },
    { configuratorIcon: 'folder-account-outline', webappIcon: 'folder_shared' },
    { configuratorIcon: 'account-outline', webappIcon: 'person_outline' },
    {
        configuratorIcon: 'picture-in-picture-top-right-outline',
        webappIcon: 'picture_in_picture',
    },
    { configuratorIcon: 'account-box-outline', webappIcon: 'account_box' },
    { configuratorIcon: 'heart-outline', webappIcon: 'favorite_border' },
    { configuratorIcon: 'domain', webappIcon: 'domain' },
    { configuratorIcon: 'file-outline', webappIcon: 'insert_drive_file' },
    { configuratorIcon: 'magnify', webappIcon: 'search' },
    { configuratorIcon: 'map-marker-outline', webappIcon: 'location_on' },
    { configuratorIcon: 'dots-horizontal', webappIcon: 'more_horiz' },
    { configuratorIcon: 'reorder-horizontal', webappIcon: 'reorder' },
    { configuratorIcon: 'file-document-box-multiple-outline', webappIcon: 'article' },
    { configuratorIcon: 'alert-decagram-outline', webappIcon: 'new_releases' },
    { configuratorIcon: 'information-outline', webappIcon: 'info_outline' },
    { configuratorIcon: 'tag-outline', webappIcon: 'local_offer' },
    { configuratorIcon: 'file-document-outline', webappIcon: 'description' },
    { configuratorIcon: 'file-multiple-outline', webappIcon: 'file_copy' },
    { configuratorIcon: 'book-outline', webappIcon: 'book' },
    { configuratorIcon: 'play-box-outline', webappIcon: 'slideshow' },
    { configuratorIcon: 'clipboard-text-outline', webappIcon: 'assignment' },
    { configuratorIcon: 'email-outline', webappIcon: 'email' },
    { configuratorIcon: 'television-classic', webappIcon: 'tv' },
    { configuratorIcon: 'cloud-download-outline', webappIcon: 'cloud_download' },
    { configuratorIcon: 'home-outline', webappIcon: 'home' },
    { configuratorIcon: 'check-box-outline', webappIcon: 'check_box_outline' },
    { configuratorIcon: 'format-list-bulleted', webappIcon: 'list' },
    { configuratorIcon: 'message-outline', webappIcon: 'chat_bubble_outline' },
    { configuratorIcon: 'dns-outline', webappIcon: 'dns' },
    { configuratorIcon: 'checkbox-multiple-blank-outline', webappIcon: 'filter_none' },
    { configuratorIcon: 'earth', webappIcon: 'public' },
    { configuratorIcon: 'map-outline', webappIcon: 'map' },
    { configuratorIcon: 'cellphone', webappIcon: 'smartphone' },
    { configuratorIcon: 'account-group-outline', webappIcon: 'groups' },
    { configuratorIcon: 'camera-outline', webappIcon: 'camera_alt' },
    { configuratorIcon: 'star-outline', webappIcon: 'star_border' },
    { configuratorIcon: 'view-agenda-outline', webappIcon: 'view_agenda' },
];
