import React from 'react';
import { withRouter } from 'react-router-dom';

import { LINK_TYPES, TILE_GROUP_TYPES } from '../constants';
import {
    TileIcon,
    TileLabel,
    WidgetFrame1Tile,
    WidgetFrameWith2Cols,
    WidgetFrameWith2ColsDirectionColumn,
    WidgetTile,
    WidgetTileBackgroundImage,
    WidgetFrameWith2ColsMedium,
} from '../common/styles';

const Tile = ({ tileGroup, history, rootRoute }) => {
    const isColorDark = color => {
        if (!color) {
            return false;
        }

        const c = color.substring(1); // strip #
        const rgb = parseInt(c, 16); // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff; // extract red
        const g = (rgb >> 8) & 0xff; // extract green
        const b = (rgb >> 0) & 0xff; // extract blue

        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
        const factor = 128;

        return luma < factor;
    };

    const onTileClick = async tile => {
        const { page, object, url } = tile;
        let currentUrl = '';

        if (!page && !object && !url) {
            return;
        }

        if (!url || !url.linkUrl) {
            if (page && page.type === 'floorplan') {
                currentUrl = '/floorplan';
            } else if (page) {
                currentUrl = `/${page.screen || +page.type.toLowerCase()}`;
            } else {
                currentUrl = `${rootRoute}/${object.type.toLowerCase()}/${object.id}`;
            }
            history.push(currentUrl);
        } else {
            window.open(url.linkUrl, '_blank');
        }
    };

    const getFrameComponent = type => {
        switch (type) {
            case TILE_GROUP_TYPES.TWO_MEDIUM:
                return WidgetFrameWith2ColsMedium;
            case TILE_GROUP_TYPES.FOUR_SMALL:
                return WidgetFrameWith2Cols;
            case TILE_GROUP_TYPES.TWO_SMALL_ONE_MEDIUM:
                return WidgetFrameWith2ColsDirectionColumn;
            case TILE_GROUP_TYPES.ONE_MEDIUM_TWO_SMALL:
                return WidgetFrameWith2ColsDirectionColumn;
            case TILE_GROUP_TYPES.ONE_LARGE:
                return WidgetFrame1Tile;
            default:
                return null;
        }
    };

    const tiles = tileGroup.items.map((item, index) => {
        const isImage = item.linkType === LINK_TYPES.IMAGE;

        return (
            <WidgetTile
                key={`tile-group-${index}`}
                fullHeight={
                    (tileGroup.type === TILE_GROUP_TYPES.ONE_MEDIUM_TWO_SMALL && index === 0) ||
                    (tileGroup.type === TILE_GROUP_TYPES.TWO_SMALL_ONE_MEDIUM && index === 2)
                }
                backgroundColor={!isImage && item.backgroundColor}
                color={isColorDark(item.backgroundColor) ? '#ffffff' : 'inherit'}
                onClick={() => onTileClick(item)}
                customheight={tileGroup.type === TILE_GROUP_TYPES.FOUR_SMALL && '45%'}
            >
                {isImage && <WidgetTileBackgroundImage imageUrl={item.image.url} />}
                {!isImage && (
                    <React.Fragment>
                        <TileIcon>{item.icon.webappIcon}</TileIcon>
                        {item.label && <TileLabel>{item.label}</TileLabel>}
                    </React.Fragment>
                )}
            </WidgetTile>
        );
    });

    const Component = getFrameComponent(tileGroup.type);

    if (!Component) {
        return null;
    }
    const isFirefox = typeof InstallTrigger !== 'undefined';

    return <Component isFirefox={isFirefox}>{tiles}</Component>;
};

export default withRouter(Tile);
