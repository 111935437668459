import React, { useEffect, useState, useRef } from 'react';
import { getString } from '../../../services/api/store';

import {
    InteractivityGrid,
    InteractivityCell,
    Container,
    SectionText,
    SectionTitle,
    Padding16px,
    Divider,
} from './style';
import Auth from '../../../services/api/auth';
import {
    askQuestion,
    likeQuestion,
    loadLatestQuestions,
    toggleQuestionLike,
} from '../../VirtualSession/services/questionsAndAnswers';
import { useGlobalState } from '../../../utils/container';
import { withRouter } from 'react-router-dom';
import {
    LikesContainer,
    QuestionItem,
    StyledFavoriteIcon,
    Margin15pxScrollable,
    Flex,
    QuestionAndTimeContainer,
} from '../../VirtualSession/components/userInteraction/questionsAndAnswers/QuestionsAndAnswers';
import { getLocalAppStateAsync } from '../../../services/api/db';
import TextAreaMessageComponent from '../../../components/TextInput/TextAreaMessageComponent';
const MAX_QUESTION_SIZE = 250;

const QuestionsAndAnswers = props => {
    const ASK_TITLE = getString('askQuestionTitle') || 'Ask a question';
    const ASK_NOT_ACTIVE =
        getString('askQuestionNotActive') || 'Asking questions has been disabled at this moment';

    const displayText =
        getString('askQuestionVisiblesHeaderMessage') ||
        'The following questions are selected by the moderator. Tap the heart to show your appreciation and increase its chance of being answered. Got another question? Submit it through the button.';
    const displayTextNoQuestions =
        getString('askQuestionHint') || 'Got a question for the speaker? Ask it here!';

    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const { objectId } = props.match.params;

    const [externalObjectId, setExternalObjectId] = useState(null);
    const [joinedQuestions, setJoinedQuestions] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);
    const [question, setQuestion] = useState('');
    const [loading, setIsLoading] = useState(true);
    const [questionList, setQuestionList] = useState([]);
    const [askAvailable, setAskavailable] = useState(false);

    const eventId = useRef(null);
    const questionSetId = useRef(null);
    const isSendingRequest = useRef(false);
    const isSubmittingQuestion = useRef(false);

    const currentUser = Auth.getUser();

    useEffect(() => {
        (async () => {
            try {
                const appState = await getLocalAppStateAsync();

                eventId.current = appState.eventId;

                await getQuestions();
            } catch (err) {
                //
            }
        })();
    }, [eventId.current, objectId, props.virtualSession]);

    useEffect(() => {
        const joinQuestions = () => {
            socket.emit('joinQuestions', {
                objectId: externalObjectId,
            });
        };

        (async () => {
            if (!externalObjectId) {
                return () => {};
            }

            if (joinedQuestions.indexOf(externalObjectId) > -1) {
                return () => {};
            }

            setJoinedQuestions([...joinedQuestions, externalObjectId]);

            if (!socket) {
                return;
            }

            socket.on('connect', joinQuestions);

            joinQuestions();

            socket.on('updateQuestions', getQuestions);
        })();
        return () => {
            if (socket) {
                socket.off('updateQuestions', getQuestions);
                socket.off('connect', joinQuestions);
            }
        };
    }, [externalObjectId]);

    const getQuestions = async () => {
        let questions = [];

        if (eventId.current && Auth.isUserAuthenticated()) {
            const latestQuestions = await loadLatestQuestions({
                eventId: eventId.current,
                itemId: objectId,
            });
            setIsLoading(false);
            const firstQuestion = latestQuestions && latestQuestions[0];
            if (!firstQuestion) {
                setAllQuestions([]);
                setQuestionList([]);
                return;
            }
            questionSetId.current = firstQuestion.id;
            setExternalObjectId(firstQuestion.ExternalObjectId);
            if (
                (props.virtualSession && props.virtualSession.showQA) ||
                ((!props.virtualSession || Object.keys(props.virtualSession).length === 0) &&
                    firstQuestion.state === 'open')
            ) {
                questions = firstQuestion.Questions;
                setAllQuestions(questions);
                questions = questions.filter(question => question.visible);
                questions = questions.map(question => {
                    question.like = question.QuestionLikes.find(q => q.UserId === currentUser.id);
                    question.numberOfLikes = question.QuestionLikes.filter(
                        q => q.active === true,
                    ).length;
                    return question;
                });
                questions.sort((a, b) =>
                    a.numberOfLikes > b.numberOfLikes
                        ? -1
                        : b.numberOfLikes > a.numberOfLikes
                        ? 1
                        : a.question.localeCompare(b.question),
                );
                setQuestionList(questions);
                setAskavailable(true);
            } else {
                setAskavailable(false);
            }
        }
        return questions;
    };

    const onChange = text => {
        setQuestion(text);
    };

    const onFavorite = async ({ id, like }) => {
        if (isSendingRequest.current) {
            return;
        }

        isSendingRequest.current = true;

        const data = {
            active: like ? !like.active : true,
            QuestionId: id,
            UserId: currentUser.id,
        };

        if (like) {
            await toggleQuestionLike({ questionLikeId: like.id, data });
        } else {
            await likeQuestion({ data });
        }

        await getQuestions();

        isSendingRequest.current = false;
    };

    const onSubmitQuestion = async () => {
        if (isSubmittingQuestion.current === true) {
            return;
        }

        const currentQuestionAlreadyExists = allQuestions.find(item => item.question === question);

        if (question && !currentQuestionAlreadyExists) {
            isSubmittingQuestion.current = true;

            try {
                const sessionId = props.match.params.objectId || props.match.params.timeslotId;
                await askQuestion({ questionSetId: questionSetId.current, question, sessionId });
                await getQuestions();
            } catch (err) {
                //
            }

            isSubmittingQuestion.current = false;
        }

        setQuestion('');
    };

    const renderQuestions = color =>
        questionList.map((item, index) => (
            <QuestionItem key={`question-${index}`} transparent={true}>
                <Flex>
                    <LikesContainer>
                        <StyledFavoriteIcon
                            onClick={() => onFavorite(item)}
                            color={color || 'grey'}
                        >
                            {item.like && item.like.active ? 'favorite' : 'favorite_border'}
                        </StyledFavoriteIcon>
                        {item.numberOfLikes}
                    </LikesContainer>
                    <QuestionAndTimeContainer>{item.question}</QuestionAndTimeContainer>
                </Flex>
            </QuestionItem>
        ));

    return (
        <InteractivityGrid>
            <InteractivityCell size={1} key="q&a">
                <Container paddingDisabled={true}>
                    <Padding16px>
                        <SectionTitle secondary={props.theme.secondary}>{ASK_TITLE}</SectionTitle>
                        <SectionText margin={'12px 0'}>
                            {!askAvailable
                                ? ASK_NOT_ACTIVE
                                : questionList.length > 0
                                ? displayText
                                : displayTextNoQuestions}
                        </SectionText>
                        {!loading && askAvailable && (
                            <>
                                {questionList.length > 0 && (
                                    <Margin15pxScrollable>
                                        {renderQuestions(props.theme.primary)}
                                    </Margin15pxScrollable>
                                )}
                            </>
                        )}
                    </Padding16px>
                    <Divider />
                    <Flex alignItemd={'center'}>
                        <TextAreaMessageComponent
                            sendMessage={onSubmitQuestion}
                            placeholder={ASK_TITLE}
                            onChange={onChange}
                            maxChar={MAX_QUESTION_SIZE}
                            padding={'12px 16px'}
                            disabledTextArea={!askAvailable}
                        />
                    </Flex>
                </Container>
            </InteractivityCell>
        </InteractivityGrid>
    );
};

export default withRouter(QuestionsAndAnswers);
