import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Auth from '../../../services/api/auth';
import { InteractivityCell, InteractivityGrid } from '../../DetailPage/interactivityTab/style';
import ChatCard from '../components/ChatCard';
import { loadTimeslotChatCardLastMessages as loadTimeslotChatCardLastMessagesAction } from '../actions';
import { getConversationWithRoomId, getTimeslotChatCardLastMessages } from '../selectors';
import { InteractivityContext } from '../../../components/Interactivity';

const GroupChatCardContainer = ({
    itemId,
    conversation,
    messages,
    loadTimeslotChatCardLastMessages,
    matchUrl,
    history,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showSignInModal, setShowSignIn] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);

            try {
                await loadTimeslotChatCardLastMessages(itemId);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        };

        if (Auth.isUserAuthenticated()) {
            loadData();
        }
    }, [itemId]);

    const onChatCardClick = () => {
        if (!Auth.isUserAuthenticated()) {
            setShowSignIn(true);
            return;
        }
        if (history && itemId) {
            history.push(`${matchUrl}/talkRoom/${itemId}`);
        }
        setShowSignIn(false);
    };

    return (
        <InteractivityGrid>
            <InteractivityCell size={1} key="q&a">
                <ChatCard
                    isLoading={isLoading && !messages.length}
                    messages={messages}
                    error={error}
                    conversation={conversation}
                    clickHandler={onChatCardClick}
                />
                {showSignInModal && (
                    <InteractivityContext.Consumer>
                        {({ showSignIn }) => showSignIn()}
                    </InteractivityContext.Consumer>
                )}
            </InteractivityCell>
        </InteractivityGrid>
    );
};

const mapStateToProps = (state, ownProps) => {
    let messages = [];
    const conversation = getConversationWithRoomId(state, ownProps.itemId);
    if (conversation) {
        messages = getTimeslotChatCardLastMessages(state, conversation.id);
    }

    return {
        conversation,
        messages,
    };
};

const GroupChatCard = connect(mapStateToProps, {
    loadTimeslotChatCardLastMessages: loadTimeslotChatCardLastMessagesAction,
})(withRouter(GroupChatCardContainer));

export { GroupChatCard };
