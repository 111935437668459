import React, { useMemo, useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { Button, FontIcon } from 'react-md';

import * as palette from '../../../../../components/General/Variables';
import { ShadowOverlay } from '../../../styles';
import {
    AccountCircleIconWrapper,
    ActionButtonsWrapper,
    styles,
    LightTooltip,
    viewProfileTooltipMessage,
    ProfileImageWrapper,
    VirtualUserInfoWrapper,
    VirtualUserTitle,
} from './common';
import VoiceIndicator from '../../common/VoiceIndicator';
import { useVolumeState } from '../../../../../utils/container';

const ExtraStyledShadowOverlay = styled(ShadowOverlay)`
    border-radius: 8px 8px 0 0;
`;

const ActivePresenter = ({ activeStream, toggleUserProfile }) => {
    const volumeStateCtx = useVolumeState();
    const { volumeIndicators } = volumeStateCtx;
    const [showActionButtons, setShowActionButtons] = useState(false);
    const [isActionButtonHovered, setIsActionButtonHovered] = useState(false);

    const volumeLevel = useMemo(() => {
        if (!volumeIndicators || !activeStream) {
            return 0;
        }

        const indicator = volumeIndicators.find(
            vInd => `${get(activeStream, 'streamId')}` === `${vInd.uid}`,
        );

        return indicator ? indicator.level : 0;
    }, [volumeIndicators, activeStream]);

    const { virtualEventUser } = activeStream;

    if (!virtualEventUser) {
        return null;
    }

    const user = virtualEventUser.User;
    const isHost = virtualEventUser.role === 'roundTableHost';
    const { isMicrophoneOn, isVideoOn } = virtualEventUser;
    const buttonStyle = {
        ...styles.buttonStyle,
        backgroundColor: isActionButtonHovered ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.87)',
    };

    return (
        <ProfileImageWrapper
            onMouseEnter={() => setShowActionButtons(true)}
            onMouseLeave={() => setShowActionButtons(false)}
        >
            <VirtualUserInfoWrapper>
                {!isMicrophoneOn && (
                    <FontIcon style={{ fontSize: '16px', color: '#ffffff' }}>
                        {palette.ICON_MIC_OFF}
                    </FontIcon>
                )}
                {isMicrophoneOn && <VoiceIndicator level={volumeLevel} small />}
                <VirtualUserTitle>
                    {user.firstName} {user.lastName} {isHost ? '(Host)' : ''}
                </VirtualUserTitle>
            </VirtualUserInfoWrapper>
            <ExtraStyledShadowOverlay />
            {!isVideoOn && (
                <ActionButtonsWrapper>
                    <AccountCircleIconWrapper imageUrl={user.imageUrl}>
                        {!user.imageUrl && (
                            <FontIcon style={{ fontSize: '40px', display: 'flex', color: '#fff' }}>
                                {palette.ICON_PROFILE}
                            </FontIcon>
                        )}
                    </AccountCircleIconWrapper>
                </ActionButtonsWrapper>
            )}
            {showActionButtons && (
                <ActionButtonsWrapper transparent clickable>
                    <LightTooltip title={viewProfileTooltipMessage} placement="top">
                        <Button
                            style={buttonStyle}
                            icon
                            forceIconSize={24}
                            onClick={() => toggleUserProfile(virtualEventUser.UserId)}
                            onMouseEnter={() => setIsActionButtonHovered(true)}
                            onMouseLeave={() => setIsActionButtonHovered(false)}
                        >
                            <FontIcon style={{ fontSize: '24px', color: '#ffffff' }}>
                                {palette.ICON_PERSON__OUTLINE}
                            </FontIcon>
                        </Button>
                    </LightTooltip>
                </ActionButtonsWrapper>
            )}
        </ProfileImageWrapper>
    );
};

export default ActivePresenter;
