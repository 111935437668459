import React, { useRef, useState } from 'react';
import { FontIcon } from 'react-md';

import styled from 'styled-components';
import JoditEditor from 'jodit-react';

import NavigationBar from '../DetailHeader/NavigationBar';
import Button, { buttonTypes } from '../../scenes/common/Button';
import { getString } from '../../services/api/store';
import * as palette from '../General/Variables';
import { Flex } from '../../scenes/common/Flex';
import { ModalContent } from '../Dialog/styles';
import BasicDialog from '../Dialog/BasicDialog';
import { DetailDrawer } from '../General/DetailDrawer';

const NotePageContainer = styled.div`
    padding: 16px;
`;

const DropdownWrapper = styled.div`
    cursor: pointer;
    color: #fff !important;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 20px;
    float: right;
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        right: 24px;
    }
    i {
        font-size: 24px !important;
    }
`;

const StyledDetailDrawer = styled(DetailDrawer)`
    z-index: 10;
`;

const EditorContainer = styled.div`
    border-radius: 8px;
    overflow: hidden;
    div {
        border-color: #dddddd !important;
        & > * {
            color: rgba(0, 0, 0, 0.87) !important;
            font-size: 15px;
        }
        .jodit-ui-group {
            width: 100%;
            display: flex !important;
            justify-content: space-around !important;
        }
    }
`;

export const NotesPage = ({
    text,
    setText,
    addOrEditNote,
    setShowNotesScreen,
    isNewNote,
    theme,
    setUpdateNote,
}) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const editor = useRef(null);

    const NOTE_TITLE = getString('addNote') || 'NOTE';
    const TEXT_CANCEL_BUTTON = getString('noteCancelButtonTitle') || 'Cancel';
    const TEXT_SAVE_BUTTON = getString('noteSaveButtonTitle') || 'Save';
    const DELETE_NOTE_DIALOG_TEXT = getString('noteDeleteButtonTitle') || 'Delete';
    const CANCEL_NOTE_DIALOG_TEXT = getString('notesDeleteConfirmationCancel') || 'Cancel';
    const DELETE_NOTE_DIALOG_TITLE = getString('noteDeleteDialogTitle') || 'Delete note';
    const DELETE_NOTE_DIALOG_DESCRIPTION =
        getString('notesDeleteConfirmationDescription') ||
        'Are you sure you want to delete this note? Once deleted, it cannot be retrieved.';

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        buttons: ['ul', 'ol', 'bold', 'underline', 'italic', 'link'],
        toolbarAdaptive: false,
        showXPathInStatusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        style: {
            background: '#EFEFEF',
            height: 'calc(100vh - 273px)',
            color: 'rgba(0, 0, 0, 0.87)',
            position: 'relative',
        },
    };

    return (
        <StyledDetailDrawer>
            <NavigationBar
                background={palette.COLOR_WHITE}
                typeName={NOTE_TITLE}
                goBack={() => setShowNotesScreen(false)}
                customRightButton={
                    text !== '' && (
                        <DropdownWrapper>
                            <FontIcon
                                iconClassName="material-icons-outlined"
                                onClick={() => setShowDeleteConfirmation(true)}
                            >
                                delete
                            </FontIcon>
                        </DropdownWrapper>
                    )
                }
            />
            <BasicDialog
                title={DELETE_NOTE_DIALOG_TITLE}
                visible={showDeleteConfirmation}
                onClose={() => setShowDeleteConfirmation(false)}
                options={[
                    {
                        title: DELETE_NOTE_DIALOG_TEXT,
                        variant: 'text',
                        type: 'delete',
                        onClick: () => {
                            setText('');
                            addOrEditNote('');
                            setUpdateNote(true);
                        },
                    },
                    {
                        title: CANCEL_NOTE_DIALOG_TEXT,
                        variant: 'text',
                        type: 'cancel',
                        onClick: () => setShowDeleteConfirmation(false),
                    },
                ]}
            >
                <ModalContent textAlign={'start'}>{DELETE_NOTE_DIALOG_DESCRIPTION}</ModalContent>
            </BasicDialog>
            <NotePageContainer>
                <EditorContainer>
                    <JoditEditor
                        ref={editor}
                        value={text}
                        config={config}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setText(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={newContent => console.log('newContent', newContent)}
                    />
                </EditorContainer>

                <Flex>
                    <Button
                        type={buttonTypes.GREEN_LONG}
                        background={theme.primary}
                        text={TEXT_SAVE_BUTTON}
                        onClick={() => {
                            addOrEditNote(text);
                            setUpdateNote(true);
                        }}
                        style={{ marginTop: '16px', marginRight: '8px' }}
                    />
                    <Button
                        type={buttonTypes.GREY_LONG}
                        background={theme.primary}
                        text={TEXT_CANCEL_BUTTON}
                        onClick={() => setShowNotesScreen(false)}
                        style={{ marginTop: '16px' }}
                    />
                </Flex>
            </NotePageContainer>
        </StyledDetailDrawer>
    );
};
