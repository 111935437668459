import React, { useContext, useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import { Divider, List } from 'react-md';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import noFavoritesImg from '../../assets/images/no-favorites.png';
import noResultsImg from '../../assets/images/no-results.png';
import Loader from '../../components/General/Loader';
import ProgramTypeButtons from '../../components/General/ProgramTypeButtons';
import Search from '../../components/General/Search';
import * as palette from '../../components/General/Variables';
import ClassifierIcon from '../../components/Icons/ClassifierIcon';
import ObjectListItem from '../../components/ObjectListItem';
import { getLocalAppState } from '../../services/api';
import { getString } from '../../services/api/store';
import { filterByFavorites, getListElements } from './services/listPage';
import { TimezoneContext } from '../Timezone/context';
import AccessRestrictions from '../../AccessRestrictions';
import moment from 'moment';
import { getDateWithLocale, getFormatTime, makeItemAsync } from '../../services/api/data';
import Auth from '../../services/api/auth';
import { getItemAsync } from '../../services/api/graphQlRepository';
import AttendeeList from '../Attendees';
import Button, { buttonTypes } from '../common/Button';

const OFFSET = 50;
const MAX_WIDTH_GRID_LAYOUT = 1050;

const EmptyState = styled.div`
    text-align: center;
    padding-top: 50px;

    h4 {
        font-size: 18px;
        font-weight: 500;
        color: ${palette.COLOR_TEXT};
    }

    p {
        font-size: 14px;
        color: ${palette.COLOR_TEXT};
    }
`;

const LinkStyled = styled(Link)`
    text-decoration: none !important;
    min-width: 0;
`;

const DividerStyled = styled(Divider)`
    margin-bottom: 0px !important;
    margin-top: 0px !important;
`;
const ListStyled = styled(List)`
    width: 100%;
    max-width: ${window.innerWidth}px;
`;

const SearchContainer = styled.div`
    margin-right: 8px;
    flex: auto;
    transition: width 2s linear;
`;

const MoreButtonContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: calc(50% - 40px);
`;
const PlaceholderImage = styled.div`
    background-image: url(${props => (props.myprogram ? noFavoritesImg : noResultsImg)});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: 500px;
`;
const ListInformation = styled.div`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    width: 100%;
    text-align: center;
    margin-top: 40px;
`;

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 0 0.25fr;
  grid-template-areas: '. search .';
  height: 72px;
  padding: 16px !important;
  position: relative;
  background-color: ${palette.COLOR_WHITE};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.05),
  0px 2px 4px rgba(0, 0, 0, 0.15);
  ${props =>
      props.issticky &&
      `
        position: sticky;
        top: ${props.top};
        z-index: 1;
    `} @media only screen and(max-width: ${palette.MAX_DESKTOP}) {
  padding: 0 24px;
}

  @media only screen and (max-width: ${palette.MIN_DESKTOP}) {
    padding: 0 64px;
  }

  @media only screen and (max-width: ${palette.MAX_PHONE}) {
    grid-template-columns: 1fr;
    grid-template-areas: 'search';
    padding: 0;
  }

  @media only screen and (max-width: ${palette.MIN_PHONE_XS}) {
    padding: 0 4px;
    width: unset;
  }
`;

const FiltersActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    grid-area: search;
`;

const ListsWrapper = styled.div`
    margin: 40px auto;
    max-width: ${MAX_WIDTH_GRID_LAYOUT}px;

    @media only screen and (max-width: ${palette.MIN_DESKTOP}) {
        margin: 24px auto;
        max-width: 769px;
    }

    @media only screen and (max-width: ${palette.MIN_TABLET}) {
        max-width: 738px;
    }

    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        margin: 16px auto 0 auto;
        max-width: 342px;
    }
`;

const GridLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    width: 100%;

    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        justify-content: space-between;
    }

    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        justify-content: space-around;
    }
`;

const EMPTY_LIST_FAVORITE_TITLE = 'Nothing favourited yet...';
const EMPTY_LIST_FAVORITE_SUBTITLE =
    'Click the bookmark icon at the top right to add an item to your personal programme.';
const EMPTY_LIST_FILTER_TITLE = 'Nothing here…';
const EMPTY_LIST_FILTER_SUBTITLE = 'Change your keywords or filters.';

const ListItem = ({ item, classifierIcon, insetDivider, listType, cardView }) => (
    <span>
        <ObjectListItem
            item={item}
            type={(item && item.category) || listType}
            icon={classifierIcon}
            cardView={cardView}
        />
        {!cardView && !item.time && <DividerStyled inset={insetDivider} />}
    </span>
);

const ListItemWithLik = ({
    i,
    url,
    styles,
    item,
    classifierIcon,
    insetDivider,
    data,
    rootRoute,
    historyState,
}) => (
    <LinkStyled
        key={'link_' + i}
        to={{ pathname: `${url}`, state: historyState || { prevPath: rootRoute } }}
        style={styles}
    >
        <ListItem
            item={item}
            classifierIcon={classifierIcon}
            insetDivider={insetDivider}
            listType={data.listType}
            cardView={data.gridLayout}
        />
    </LinkStyled>
);

const ListPage = props => {
    const [data, setData] = useState({
        listItems: [],
        displayItems: [],
        listType: '',
        closeLinkPath: props.closeLink ? props.closeLink : '/programme',
        currentIndex: 0,
        myProgram: 'all',
        searchStr: '',
        listInfo: '',
        accessRestrictions: null,
        isPasswordProtected: false,
        password: null,
        gridLayout: false,
    });
    const [loading, setLoading] = useState(true);
    const [hideFilterDropdown, setHideFilterDropdown] = useState(false);
    const [isAttendeeList, setIsAttendeeList] = useState(false);

    const { timeslotToTimezone } = useContext(TimezoneContext);

    const isAuthenticated = Auth.isUserAuthenticated();

    const navigation = useRef(null);
    let TEXT_EMPTY_LIST_FAVORITE_TITLE =
        getString('noFavouriteItemsTitle') || EMPTY_LIST_FAVORITE_TITLE;
    let TEXT_EMPTY_LIST_FAVORITE_SUBTITLE =
        getString('noFavouriteItemsDescription') || EMPTY_LIST_FAVORITE_SUBTITLE;
    let TEXT_EMPTY_LIST_FILTER_TITLE = getString('noFilteredItemsTitle') || EMPTY_LIST_FILTER_TITLE;
    let TEXT_EMPTY_LIST_FILTER_SUBTITLE =
        getString('noFilteredItemsDescription') || EMPTY_LIST_FILTER_SUBTITLE;
    const LOAD_MORE_BUTTON = getString('loadMore') || 'LOAD MORE';

    const insetDivider =
        data.listType === 'institution' || data.listType === 'person' || data.listType === 'place';

    let listItems = data.displayItems;

    const displayMore = data.listItems.length >= data.currentIndex + OFFSET;
    const isMobile = window.innerWidth < palette.MIN_TABLET_INT;

    useEffect(() => {
        props.setTitle();
        setLoading(true);
        if (props && (props.prefix === '/attendee_list' || props.rootRoute === '/attendee_list')) {
            setIsAttendeeList(true);
        } else {
            getListInfo({
                currentIndex: 0,
                searchStr: '',
                myProgram: 'all',
            });
        }

        getLocalAppState((err, localAppState) => {
            if (err || !localAppState) {
                return;
            }

            navigation.current = localAppState.navigation;
        });
    }, [props.pageId]);

    const addIsExhibitorField = async items => {
        return await Promise.all(
            items.map(async item => {
                const typeObj = await getItemAsync('types', item.type);
                item.isExhibitor = typeObj && typeObj.singular === 'Exhibitor';
                if (
                    typeObj &&
                    typeObj.target &&
                    (typeObj.target.toLowerCase() === 'institution' ||
                        typeObj.target.toLowerCase() === 'institutions')
                ) {
                    const exhibitor = await makeItemAsync(item.id, 'institution');
                    item.showClickBooth =
                        exhibitor && exhibitor.typeParams && exhibitor.typeParams.showClickBooth;
                }
                return item;
            }),
        );
    };

    const getListInfo = initialData => {
        getListElements(props.pageId, null, async (err, result, page) => {
            if (err) {
                console.log(err);

                return;
            }
            const listInfo = page && page.params && page.params.info ? page.params.info : '';

            if (result) {
                let items = result && result.items && result.items.slice(0, OFFSET);

                // In grid layout there can be items with two sizes: normal and large
                // The large sized items should be displayed at the top of the list
                if (page.params && page.params.layout === 'grid') {
                    const largeCardItems = items.filter(
                        item => item.params && item.params.isLargeCard,
                    );
                    const normalCardItems = items.filter(
                        item => !(item.params && item.params.isLargeCard),
                    );
                    items =
                        largeCardItems.length || normalCardItems.length
                            ? Array.from(new Set(largeCardItems.concat(normalCardItems)))
                            : result.items.slice(0, OFFSET);
                }

                if (items && items.length > 0) {
                    items = await addIsExhibitorField(items);
                }

                setData({
                    ...data,
                    ...initialData,
                    displayItems: items,
                    listItems: result.items,
                    listType: result.type,
                    listInfo,
                    hasSearch: result.hasSearch,
                    accessRestrictions: page.accessRestrictions,
                    isPasswordProtected: page.isPasswordProtected,
                    password: page.password,
                    gridLayout: page.params && page.params.layout === 'grid',
                });
                setLoading(false);
                if (props.updateTitle) {
                    props.updateTitle(result.title);
                }
            } else {
                setData({
                    ...data,
                    ...initialData,
                    listInfo,
                });
                setLoading(false);
            }
        });
    };

    const loadMore = () => {
        const newIndex = data.currentIndex + OFFSET;
        const newItems = data.listItems.slice(newIndex, newIndex + OFFSET);
        const items = data.displayItems.concat(newItems);
        setData({
            ...data,
            displayItems: items,
            currentIndex: newIndex,
        });
    };

    const applyFilters = (search, favorites, initialData) => {
        setLoading(true);
        getListElements(props.pageId, search, async (err, result) => {
            if (err) {
                console.log(err);
                setLoading(false);
            } else {
                let filtered = await addIsExhibitorField(result.items);
                if (favorites === 'my_program') {
                    filterByFavorites(filtered, (err, favoriteItems) => {
                        if (favoriteItems && favoriteItems.length) {
                            const items = favoriteItems.slice(0, OFFSET);
                            setData({
                                ...data,
                                ...initialData,
                                listItems: favoriteItems,
                                displayItems: items,
                                currentIndex: 0,
                            });
                            setLoading(false);
                        } else {
                            setData({
                                ...data,
                                ...initialData,
                                listItems: [],
                            });
                            setLoading(false);
                        }
                    });
                } else {
                    const items = filtered.slice(0, OFFSET);
                    setData({
                        //  TODO: This could be refined. Do we have a regular expression somewhere?
                        ...data,
                        ...initialData,
                        listItems: filtered,
                        displayItems: items,
                        currentIndex: 0,
                        listType: result.type,
                    });
                    setLoading(false);
                }
            }
        });
    };

    const _onSearch = str => {
        applyFilters(str, data.myProgram, {
            searchStr: str,
        });
    };

    const onSearch = throttle(_onSearch, 100, {
        leading: true,
        trailing: false,
    });

    const favoriteFilter = option => {
        if (option !== data.myProgram) {
            setLoading(true);
            applyFilters(data.searchStr, option, {
                displayItems: [],
                currentIndex: 0,
                myProgram: option,
            });
        }
    };

    const onFocus = () => {
        if (!isMobile) {
            return;
        }

        setHideFilterDropdown(true);
    };

    const onBlur = () => {
        if (!isMobile) {
            return;
        }

        setHideFilterDropdown(false);
    };
    const timeArray = [];
    const renderListItem = (it, i) => {
        const item = timeslotToTimezone(it);
        const baseURL = `${props.rootRoute}/page/list/${props.pageId}/${
            item.category || data.listType
        }`;

        let params = item.params;
        if (typeof item.params === 'string') {
            params = JSON.parse(item.params);
        }
        let classifierIcon;
        if (params && params.icons && params.icons.length) {
            params.icons.forEach(item => {
                classifierIcon = <ClassifierIcon key={item} imageId={item} />;
            });
        }

        let styles = {};
        if (params && params.isLargeCard) {
            styles.gridColumn = 'span 2';
        }
        let url = '';

        if (data.listType === 'institution' && item.params && item.params.sponsorScreen) {
            const { sponsorScreen } = item.params;

            const sponsor =
                navigation.current &&
                navigation.current.find(item => item.to.indexOf(sponsorScreen) > -1);

            if (sponsor && sponsor.pageId) {
                url = props.rootRoute + '/page/sponsor/' + sponsor.pageId;
            }
        }

        let historyState;
        if (item.showClickBooth) {
            url = `/exhibitorbooth/${item.id}`;
            historyState = {
                fromLocation: props.location.pathname,
                prevPath: props.location.pathname,
            };
        }
        if (url === '') {
            url = `${baseURL}/${item.id}`;
        }
        if (item.time) {
            let start = moment.utc(item.start);
            if (!start.isValid()) {
                start = moment.unix(item.start);
            }
            const timeFormat = getFormatTime();
            const timetoDisplay = moment(start).format(timeFormat);
            const dateSettings = getString('datetime');
            const datePattern =
                dateSettings && dateSettings.dayMonthDateFormat
                    ? dateSettings.dayMonthDateFormat
                    : 'MMMM D';
            const day = getDateWithLocale(start);
            const dateToDisplay = day.format(datePattern);

            item.dateTime = {
                startTimeToDisplay: timetoDisplay,
                startDateToDisplay: dateToDisplay,
            };
            timeArray.push({ dateTime: item.dateTime });
        }

        if (item.eurekaId && isAuthenticated) {
            historyState = {
                personFallbackUrl: url,
            }
            url = `${props.rootRoute}/profile/${item.eurekaId}/${item.id}`;
        }

        return (
            <ListItemWithLik
                key={`ListItemWithLik_${item.id}`}
                i={i}
                item={item}
                url={url}
                styles={styles}
                classifierIcon={classifierIcon}
                insetDivider={insetDivider}
                data={data}
                rootRoute={props.rootRoute}
                historyState={historyState}
            />
        );
    };

    const content = () => {
        if (!data.displayItems.length && !loading) {
            if (data.listInfo) {
                return null;
            }
            if (data.myProgram === 'my_program' && (!data.searchStr || data.searchStr === '')) {
                return (
                    <EmptyState>
                        <h4>{TEXT_EMPTY_LIST_FAVORITE_TITLE}</h4>
                        <p>{TEXT_EMPTY_LIST_FAVORITE_SUBTITLE}</p>
                        <PlaceholderImage myprogram />
                    </EmptyState>
                );
            } else {
                return (
                    <EmptyState>
                        <h4>{TEXT_EMPTY_LIST_FILTER_TITLE}</h4>
                        <p>{TEXT_EMPTY_LIST_FILTER_SUBTITLE}</p>
                        <PlaceholderImage />
                    </EmptyState>
                );
            }
        } else {
            const ListsWrapperComponent = data.gridLayout ? ListsWrapper : React.Fragment;
            const Wrapper = data.gridLayout ? GridLayout : ListStyled;

            return (
                <ListsWrapperComponent>
                    <Wrapper className="" key={`list_${props.pageId}`}>
                        {listItems.map(renderListItem)}
                    </Wrapper>
                </ListsWrapperComponent>
            );
        }
    };

    return (
        <React.Fragment>
            {isAttendeeList ? (
                <AttendeeList isMenu {...props} />
            ) : (
                <AccessRestrictions
                    pageId={props.pageId}
                    accessRestrictions={data.accessRestrictions}
                    isPasswordProtected={data.isPasswordProtected}
                    password={data.password}
                    result={() => (
                        <React.Fragment>
                            {data.hasSearch && (
                                <FiltersContainer
                                    issticky={
                                        data.searchStr !== '' || props.navigationType === 'sideMenu'
                                    }
                                    top={
                                        data.searchStr !== '' &&
                                        props.navigationType !== 'sideMenu' &&
                                        !props.location.pathname.includes('/page/')
                                            ? '88px'
                                            : props.navigationType === 'sideMenu' && 0
                                    }
                                >
                                    <FiltersActionContainer>
                                        <SearchContainer>
                                            <Search
                                                action={onSearch}
                                                live={true}
                                                skey={props.pageId}
                                                onFocus={onFocus}
                                                onBlur={onBlur}
                                                isExpandableSearch={true}
                                                displayLeft={true}
                                            />
                                        </SearchContainer>
                                        {!hideFilterDropdown && (
                                            <ProgramTypeButtons
                                                action={favoriteFilter}
                                                myProgram={data.myProgram}
                                                useFavoritesTitle={data.listType !== 'timeslot'}
                                                withoutFloat
                                            />
                                        )}
                                    </FiltersActionContainer>
                                </FiltersContainer>
                            )}

                            {loading && <Loader />}

                            {!loading && (
                                <React.Fragment>
                                    {data.listInfo && (
                                        <ListInformation>{data.listInfo}</ListInformation>
                                    )}
                                    {content()}
                                    {displayMore && (
                                        <MoreButtonContainer>
                                            <Button
                                                onClick={loadMore}
                                                type={buttonTypes.GREY}
                                                text={LOAD_MORE_BUTTON}
                                            />
                                        </MoreButtonContainer>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                />
            )}
        </React.Fragment>
    );
};
export default withRouter(ListPage);
