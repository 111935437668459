import React from 'react';
import styled from 'styled-components';
import BasicDialog from '../../components/Dialog/BasicDialog';
import FontIcon from 'react-md/lib/FontIcons';
import { getString } from '../../services/api/store';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const IconWrapper = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
`;

const Icon = styled(FontIcon)`
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 32px !important;
`;

const Title = styled.p`
    font-family: 'Cabin', sans-serif;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    text-align: center;
    color: #000;
    margin-bottom: 16px;
`;

const SubTitle = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 1.6;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 32px;
`;

const AccessDeniedModal = ({ onClose }) => {
    const MODAL_TITLE = getString('noPermission', 'No permission');
    const MODAL_MSG = getString(
        'noPermissionVirtualRoom',
        'You do not have permission to enter this virtual room.',
    );

    return (
        <BasicDialog
            visible={true}
            onClose={onClose}
            options={[
                {
                    title: 'Dismiss',
                    variant: 'contained',
                    color: 'default',
                    onClick: onClose,
                    type: 'cancel',
                },
            ]}
        >
            <Wrapper>
                <IconWrapper>
                    <Icon>lock</Icon>
                </IconWrapper>

                <Title>{MODAL_TITLE}</Title>

                <SubTitle>{MODAL_MSG}</SubTitle>
            </Wrapper>
        </BasicDialog>
    );
};

export default AccessDeniedModal;
