import React from 'react';
import styled from 'styled-components';
import { Button, FontIcon } from 'react-md';
import get from 'lodash/get';

import * as palette from '../../../components/General/Variables';
import { withRouter } from 'react-router-dom';
import UserAvatar from '../../../components/General/UserAvatar';
import eventBus from '../../../utils/eventBus';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 8px 16px;
    ${props => props.border && 'border-bottom: solid 1px #edeef2;'}
`;

const ButtonStyled = styled(Button)`
    margin-top: 4px;
    margin-bottom: 4px;
    height: 40px !important;
    width: 40px !important;
    padding: 10px !important;
    vertical-align: middle;
`;

const BackButton = styled(ButtonStyled)`
    margin-right: 8px;
`;

const CloseButton = styled(ButtonStyled)`
    margin-left: auto;
`;

const Icon = styled(FontIcon)`
    color: rgba(0, 0, 0, 0.87) !important;
    vertical-align: top;
    font-size: 20px;
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

const UserWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
    border-radius: 50px;
    padding: 4px;

    &:hover {
        background: #f5f5f5;
    }
`;

const UserAvatarWrapper = styled.div`
    width: 32px;
    height: 32px;
    margin-right: 12px;
    cursor: pointer;
`;

const NavigationHeader = ({
    closePath,
    goBack,
    participant,
    title,
    isGroupChat,
    history,
    location,
}) => {
    const handleOnClose = () => {
        const openedFromNotificationCenter = get(
            location,
            'state.openedFromNotificationCenter',
            false,
        );

        history.push(closePath);

        // If the chat was opened from notification center,
        // the notifications list should be opened again when closing the chat.
        if (openedFromNotificationCenter) {
            eventBus.emit('openChatNotifications');
        }
    };

    return (
        <Wrapper border={!isGroupChat}>
            {goBack && (
                <BackButton icon>
                    <Icon onClick={goBack}>{palette.ICON_BACK}</Icon>
                </BackButton>
            )}
            {isGroupChat ? (
                <Title>{title}</Title>
            ) : (
                <UserWrapper
                    onClick={() => history.push(`${location.pathname}/profile/${participant.id}`)}
                >
                    <UserAvatarWrapper>
                        <UserAvatar user={participant} />
                    </UserAvatarWrapper>
                    <Title>{title}</Title>
                </UserWrapper>
            )}
            {closePath && (
                <CloseButton icon>
                    <Icon onClick={handleOnClose}>{palette.ICON_CLOSE}</Icon>
                </CloseButton>
            )}
        </Wrapper>
    );
};

export default withRouter(NavigationHeader);
