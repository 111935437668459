import React from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

import * as palette from '../../../components/General/Variables';
import { useTheme } from '../../../components/Theme/ThemeContext';
import SearchBar from '../../../components/TextInput/SearchBar';
import { getString } from '../../../services/api/store';

const TextAndActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const Title = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
`;

const ClearAllButton = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: ${props => props.color};
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        display: none;
    }
`;

const CloseButton = styled(FontIcon)`
    font-size: 24px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    cursor: pointer;

    @media only screen and (min-width: ${palette.MAX_PHONE}) {
        display: none;
    }
`;

const NotificationContentHeader = ({
    onMenuClose,
    onClearAll,
    displayClearAll,
    hasSearch,
    searchValue,
    onSearchChange,
    title,
}) => {
    const { theme } = useTheme();
    const CLEAR_ALL_BUTTON_TEXT = getString('clearAllButton') || 'Clear all';
    const CLOSE_BUTTON_TEXT = getString('closeButton') || 'close';
    return (
        <>
            <TextAndActionsWrapper>
                <Title>{title}</Title>
                {displayClearAll && (
                    <ClearAllButton onClick={onClearAll} color={theme.primary}>
                        {CLEAR_ALL_BUTTON_TEXT}
                    </ClearAllButton>
                )}
                <CloseButton onClick={onMenuClose}>{CLOSE_BUTTON_TEXT}</CloseButton>
            </TextAndActionsWrapper>
            {hasSearch && (
                <SearchBar
                    value={searchValue}
                    onChange={onSearchChange}
                    customStyle={{ marginBottom: '8px' }}
                />
            )}
        </>
    );
};

export default NotificationContentHeader;
