import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import throttle from 'lodash/throttle';
import * as palette from '../../../components/General/Variables';
import { TILE_GROUP_TYPES } from '../constants';
import VideoWidget from './VideoWidget';
import UpcomingSessionsWidget from './UpcomingSessionsWidget';
import Tile from './Tile';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    ${props =>
        (props.hasNews || !props.isDesktop) &&
        `
        flex-direction: column;
    `}

    & > * {
        flex: 0
            ${props =>
                props.hasNews || !props.isDesktop
                    ? props.chromeVersion90
                        ? '500px'
                        : '100%'
                    : 'calc(50% - 16px)'};
        height: fit-content;
    }
`;

const WidgetsWrapper = styled.div`
    height: ${props => (props.height ? `${props.height}px` : 'auto')};
    ${props =>
        !props.isFirefox && (props.height ? `max-height: ${props.height}px` : 'max-height: auto')};
    width: 100%;
    ${props => props.marginBottom && 'margin-bottom: 24px'};
`;

const getChromeVersion = () => {
    const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
};

const TilesAndWidgets = ({ tileGroups, hasNews, rootRoute, visibleFullSideMenu }) => {
    const [blockHeight, setBlockHeight] = useState(268);
    const isDesktop = window.innerWidth > palette.MIN_DESKTOP_INT;
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const chromeVersion = getChromeVersion();

    const computeTilesBlockHeight = () => {
        const elem = document.getElementById('feed-tiles-wrapper');

        if (!elem) {
            return;
        }

        const width = !hasNews && isDesktop ? elem.offsetWidth / 2 : elem.offsetWidth;

        return width / (16 / 9);
    };

    const updateTileBlockHeight = throttle(() => {
        const height = computeTilesBlockHeight();

        setBlockHeight(height + 24); // 24 is the margin between tiles
    }, 1000);

    useEffect(() => {
        window.addEventListener('resize', updateTileBlockHeight);

        return () => window.removeEventListener('resize', updateTileBlockHeight);
    }, []);

    useEffect(() => {
        updateTileBlockHeight();
    });

    useEffect(() => {
        updateTileBlockHeight();
    }, [visibleFullSideMenu]);

    const renderTiles = (tileGroup, index) => {
        const isVideo = tileGroup.type === TILE_GROUP_TYPES.VIDEO;
        const isUpcomingSessions = tileGroup.type === TILE_GROUP_TYPES.UPCOMING_SESSIONS;
        const isTile = !isVideo && !isUpcomingSessions;

        return (
            <WidgetsWrapper
                key={`tile-and-widgets-wrapper-${index}`}
                height={!isUpcomingSessions ? blockHeight : blockHeight - 25}
                marginBottom={isUpcomingSessions}
                isFirefox={isFirefox}
            >
                {isVideo && <VideoWidget tileGroup={tileGroup} />}
                {isUpcomingSessions && <UpcomingSessionsWidget tileGroup={tileGroup} />}
                {isTile && <Tile tileGroup={tileGroup} rootRoute={rootRoute} />}
            </WidgetsWrapper>
        );
    };

    return (
        <Wrapper
            id="feed-tiles-wrapper"
            hasNews={hasNews}
            isDesktop={isDesktop}
            chromeVersion90={chromeVersion >= 90}
        >
            {tileGroups.map(renderTiles)}
        </Wrapper>
    );
};

export default TilesAndWidgets;
