import React, { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

const SlideshowWrapper = styled.div`
    .react-slideshow-container {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 100%;

        &:hover {
            & .custom-arrow {
                opacity: 1;
            }
        }
    }

    .indicators {
        align-items: center;
    }

    .images-wrap {
        display: flex;
        align-items: center;
        & > .active {
            position: relative;
        }
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    border-radius: 8px;
`;

const Indicator = styled.div`
    position: absolute;
    right: 16px;
    bottom: ${props => (props.inArrowContainer ? '11px' : '16px')};
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #ffffff;
`;

const ArrowWrapper = styled.div`
    width: 200px;
    min-width: 200px;
    height: calc(100% - 5px);
    position: absolute;
    background: linear-gradient(270deg, rgba(40, 40, 40, 0.64) 0%, rgba(40, 40, 40, 8e-5) 100%);
    top: 0;
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
`;

const PrevArrowWrapper = styled(ArrowWrapper)`
    left: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
`;

const NextArrowWrapper = styled(ArrowWrapper)`
    right: 0;
`;

const StyledArrow = styled(FontIcon)`
    color: #ffffff !important;
    font-size: 32px !important;
`;

const HiddenDiv = styled.div`
    display: none;
`;

const Slideshow = ({ data }) => {
    const defaultIndex = 0;
    const [currentIndex, setCurrentIndex] = useState(defaultIndex);
    const [hoveredImage, setHoverImage] = useState(false);
    const upperBound = data.length - 1;
    const shouldDisplayPrevArrow = hoveredImage && currentIndex > 0;
    const shouldDisplayNextArrow = hoveredImage && currentIndex < upperBound;

    const onChange = (oldIndex, newIndex) => {
        setCurrentIndex(newIndex);
    };

    const PrevArrow = (
        <PrevArrowWrapper className="custom-arrow">
            <StyledArrow>chevron_right</StyledArrow>
        </PrevArrowWrapper>
    );

    const NextArrow = (
        <NextArrowWrapper className="custom-arrow">
            <StyledArrow>chevron_right</StyledArrow>
            <Indicator inArrowContainer={true}>{`${currentIndex + 1} / ${data.length}`}</Indicator>
        </NextArrowWrapper>
    );

    const additionalContent = data && data.length > 0 ? data[currentIndex].additionalContent : null;

    return (
        <SlideshowWrapper
            onMouseEnter={() => setHoverImage(true)}
            onMouseLeave={() => setHoverImage(false)}
        >
            <Slide
                defaultIndex={defaultIndex}
                onChange={onChange}
                transitionDuration={300}
                autoplay={false}
                prevArrow={shouldDisplayPrevArrow ? PrevArrow : <HiddenDiv />}
                nextArrow={shouldDisplayNextArrow ? NextArrow : <HiddenDiv />}
                indicators={false}
            >
                {data.map((obj, index) => (
                    <>
                        <Image src={obj.url} alt={index} />
                        {hoveredImage && <Indicator>{`${index + 1} / ${data.length}`}</Indicator>}
                    </>
                ))}
            </Slide>
            {additionalContent}
        </SlideshowWrapper>
    );
};

export default Slideshow;
