import backgroundDefaultImage from '../../../assets/images/banner-placeholder.png';
import { getInitials } from '../../VirtualSession/components/userInteraction/attendees/Attendees';
import React from 'react';
import {
    CustomAvatar, DropdownRelativeContainer,
    DropdownWrapper,
    GeneralImagesContainer,
    HeaderImgContainer,
    MainProfileDetails,
} from '../style';
import Dropdown from '../../../components/General/Dropdown';
import { getString } from '../../../services/api/store';
import BackgroundBanner from "../../../components/DetailHeader/BackgroundBanner";

const ProfileHeader = ({ profileData, header, onCancelRequest, friendshipStatus }) => {
    const dropdownItems = [
        // {
        //     text: 'Add to favorites',
        //     icon: 'favorite',
        //     onClick: () => {},
        // },
        {
            text: getString('deleteButton') || 'Remove contact',
            icon: 'delete',
            onClick: onCancelRequest,
            disabled: friendshipStatus !== 1
        },
    ];

    return (
        <React.Fragment>
            <GeneralImagesContainer>
                <BackgroundBanner
                    ref={header}
                    src={
                        profileData && profileData.coverUrl
                            ? profileData.coverUrl
                            : backgroundDefaultImage
                    }
                />
                <HeaderImgContainer>
                    {profileData.imageUrl ? (
                        <img src={profileData.imageUrl} alt="picture" />
                    ) : (
                        <CustomAvatar>
                            {getInitials(
                                profileData.firstName,
                                profileData.lastName,
                            )}
                        </CustomAvatar>
                    )}
                </HeaderImgContainer>
                <DropdownRelativeContainer>
                    <DropdownWrapper>
                        <Dropdown items={dropdownItems} />
                    </DropdownWrapper>
                </DropdownRelativeContainer>
            </GeneralImagesContainer>

            <MainProfileDetails>
                <div>
                    {profileData && profileData.title} {profileData.firstName}{' '}
                    {profileData.lastName}
                </div>
                <div>{profileData.jobTitle}</div>
                {profileData && profileData.companyName && <div>{profileData.companyName}</div>}
                <div>
                    {profileData &&
                    profileData.Country &&
                    profileData.Country.name}
                </div>
            </MainProfileDetails>
        </React.Fragment>
    );
};

export default ProfileHeader;
