import React, { useEffect, useState } from 'react';
import moment from 'moment';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import {
    getDateWithLocale,
    getDayMonthDateFormat,
    getFormatTime,
    getMyCheckins,
    makeItemAsync,
} from '../../services/api/data';
import { getObjectClassWithIdAsync } from '../../services/api/db';
import { Tab, TabsContainer } from '../../components/DateTabs';
import { useTheme } from '../../components/Theme/ThemeContext';
import Loader from '../../components/General/Loader';
import Placeholder from '../SearchPage/components/placeholder';
import ObjectListItem from '../../components/ObjectListItem';
import styled from 'styled-components';
import { List } from 'react-md';
import { Link } from 'react-router-dom';
import eventBus from '../../utils/eventBus';

const TAB_DATE_PATTERN = 'ddd DD';

const ListStyled = styled(List)`
    width: 100%;
    max-width: ${window.innerWidth}px;
`;

const LinkStyled = styled(Link)`
    text-decoration: none !important;
`;

const MyCheckinsPage = props => {
    const [items, setItems] = useState({});
    const [tabDates, setTabDates] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);

    const { theme } = useTheme();

    useEffect(() => {
        fetchData();
        props.setTitle();
    }, []);

    useEffect(() => {
        eventBus.on('refreshMyCheckins', fetchData);

        return () => eventBus.removeListener('refreshMyCheckins', fetchData);
    }, [activeTab]);

    const fetchData = async () => {
        setLoading(true);

        const checkins = await getMyCheckins();
        const detailedCheckinItems = await Promise.all(
            checkins.map(async checkin => {
                const { objectClass } = await getObjectClassWithIdAsync(checkin.id);
                const item = await makeItemAsync(checkin.id, objectClass);
                const tabDateLabel = moment(item.start).format(TAB_DATE_PATTERN);

                return {
                    ...item,
                    tabDateLabel,
                };
            }),
        );

        const groupedItems = groupBy(detailedCheckinItems, 'tabDateLabel');
        const uniqueStartDates = uniq(
            detailedCheckinItems.map(item => moment(item.start).format('L')), // dd/mm/yyyy
        );
        const tabDatesItems = uniqueStartDates.map(date => ({
            value: date,
            label: moment(date).format(TAB_DATE_PATTERN),
        }));
        const sortedTabDates = sortBy(tabDatesItems, [item => new Date(item.value)]);

        if (activeTab >= sortedTabDates.length) {
            setActiveTab(0);
        }

        setItems(groupedItems);
        setTabDates(sortedTabDates);
        setLoading(false);
    };

    const renderTabDate = (tabDate, index) => {
        const { label } = tabDate;
        const [upperValue, lowerValue] = label.split(' ');

        return (
            <Tab
                key={`checkin-date-${index}`}
                active={index === activeTab}
                color={theme.contrast}
                onClick={() => setActiveTab(index)}
            >
                <div>{upperValue}</div>
                <div>{lowerValue}</div>
            </Tab>
        );
    };

    const renderContent = () => {
        const itemsToRender = items[tabDates[activeTab].label] || [];

        return (
            <ListStyled className="">
                {itemsToRender.map(item => {
                    const timeFormat = getFormatTime();
                    const timeToDisplay = moment(item.start).format(timeFormat);
                    const datePattern = getDayMonthDateFormat();
                    const day = getDateWithLocale(item.start);
                    const dateToDisplay = day.format(datePattern);

                    item.dateTime = {
                        startTimeToDisplay: timeToDisplay,
                        startDateToDisplay: dateToDisplay,
                    };

                    const type = item.type.slice(0, -1);
                    const { rootRoute } = props;

                    return (
                        <LinkStyled to={`${rootRoute}/${type}/${item.id}`}>
                            <ObjectListItem item={item} type={type} />
                        </LinkStyled>
                    );
                })}
            </ListStyled>
        );
    };

    if (loading) {
        return <Loader />;
    }

    if (tabDates.length === 0) {
        return <Placeholder type="emptyList" introText="Nothing checked in yet..." />;
    }

    return (
        <>
            <TabsContainer>{tabDates.map(renderTabDate)}</TabsContainer>
            {renderContent()}
        </>
    );
};

export default MyCheckinsPage;
