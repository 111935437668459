import styled from 'styled-components';
import * as palette from '../General/Variables';
import { Button, SelectionControl } from 'react-md';

export const StyledInfo = styled.p`
    margin-top: ${props => props.margin || '0px'};
    font-size: 16px;
    text-align: center;
    color: ${palette.COLOR_TEXT};

    a {
        color: ${props => props.linkcolor};
    }
`;

export const StyledTitle = styled(StyledInfo)`
    font-size: 24px;
    font-weight: 500;
    margin: 20px 0;
`;

export const StyledError = styled.p`
    margin-top: 8px;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: red;
    text-align: center;
`;

export const SelectionControlStyled = styled(SelectionControl)`
    margin-top: ${props => !props.noMarginTop && '12px'};
    align-self: flex-start;

    .md-btn--icon {
        margin-left: -10px;
    }

    i {
        color: ${props => props.primarycolor} !important;
    }
`;

export const SmallLinkText = styled.div`
    color: ${palette.COLOR_TEXT};
    font-size: 15px;
`;

export const RegularLinkText = styled.div`
    color: ${palette.COLOR_TEXT};
    text-align: center;
    font-size: 16px;
`;

export const SmallLink = styled.a`
    color: ${props => props.linkcolor};
    font-size: 15px;
`;

export const RegularLink = styled.span`
    color: ${props => props.linkcolor};
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
`;

export const StyledForm = styled.form`
    min-height: ${props => (props.minHeight ? props.horizontalMargin : '450px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: ${palette.COLOR_TEXT} !important;

    .md-divider--text-field::after {
        background: ${props => props.maincolor};
    }

    .md-text--theme-primary {
        color: ${props => props.maincolor};
    }

    .md-text-field-message,
    .md-floating-label,
    input::placeholder,
    textarea::placeholder {
        font-size: 12px !important;
    }
`;

export const ThemeButton = styled(Button)`
    color: ${props => props.maincolor} !important;
    border-radius: 18px;
    text-transform: ${props => (props.texttransform ? props.texttransform : 'inherit')};
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;

    .md-btn,
    .md-icon-text {
        font-weight: 400;
    }

    .md-icon-text:last-child {
        padding-left: 10px;
    }
`;

export const SwapThemeButton = styled(Button)`
    background: ${props => props.maincolor} !important;
    border-radius: 18px;
    text-transform: inherit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;

    .md-btn,
    .md-icon-text {
        font-weight: 400;
    }

    .md-icon-text:last-child {
        padding-left: 10px;
    }

    &:hover {
        background: ${props => props.maincolor};
        opacity: 0.9;
    }

    &:disabled {
        background: grey !important;
        opacity: 0.9;
    }

    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        .md-btn {
            padding-left: 1px !important;
            padding-top: 2px !important;
        }
    }
`;

export const BlueButton = styled.div`
    width: ${props => (props.width ? props.width : '240px')};
    background-color: ${props => (props.color ? props.color : '#054289')};
    color: white;
    text-align: center;
    padding: 9px 18px;
    border-radius: 18px;
    margin-top: 50px;
    cursor: pointer;
`;

export const Space12 = styled.div`
    margin-top: 12px;
`;

export const Space24 = styled.div`
    margin-top: 24px;
`;

export const Space36 = styled.div`
    margin-top: 36px;
`;

export const IntroMargin = styled.div`
    margin-top: 160px;
`;

export const SyncImage = styled.div`
    top: 0px;
    margin-top: -30px;
    background-image: url('/images/auth_feat_sync_your_favourites.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 300px;
    max-width: 300px;
    height: 245px;
`;

export const GotEmailImage = styled.div`
    top: 0px;
    margin-top: -30px;
    background-image: url('/images/auth_you_have_got_mail.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 300px;
    max-width: 300px;
    height: 245px;
`;

export const SignedInImage = styled.div`
    top: 0px;
    margin-top: -30px;
    background-image: url('/images/signed_in.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 300px;
    max-width: 300px;
    height: 245px;
`;
