import React from 'react';
import Button from '@material-ui/core/Button';

import MaterialDialog from './MaterialDialog';
import { baseButtonStyle, cancelButtonStyle, saveButtonStyle } from './styles';
import { useTheme } from '../Theme/ThemeContext';

/**
 * @component
 * @description A basic small dialog (modal) component based on MaterialDialog component with some modifications made to size and how action buttons are displayed
 * @param {Object} props
 * @param {JSX} props.children content rendered inside Dialog
 * @param {Object} props.contentStyle additional style added to content
 * @param {Function} props.onClose function called when the Dialog is dismissed by pressing the Esc key or the X button at the top
 * @param {Array.<{title: String, variant: String, color: String, onClick: Function, type: string('save' | 'cancel')}>} props.options
 * List of Objects to create this Dialog buttons. Variants can be: 'contained', 'outlined' or 'text' (default) and color 'primary', 'secondary' or 'default'
 * @param {String} props.title title on the dialog. Can also be empty
 * @param {Boolean} props.visible when *false* the dialog is hidden and displayed when *true*
 */
const BasicDialog = ({
    children,
    contentStyle,
    onClose = () => {},
    options = [],
    title = '',
    visible = false,
    iconName,
}) => {
    const DialogButtons = () => {
        const { theme } = useTheme();
        return options.map(op => {
            let buttonStyle = baseButtonStyle;

            switch (op.type) {
                case 'save':
                    buttonStyle = {
                        ...buttonStyle,
                        ...saveButtonStyle,
                        backgroundColor: theme.primary,
                    };
                    break;
                case 'cancel':
                    buttonStyle = { ...buttonStyle, ...cancelButtonStyle };
                    break;
                case 'delete':
                    buttonStyle = {
                        ...buttonStyle,
                        ...saveButtonStyle,
                        backgroundColor: theme.contrast,
                    };
                    break;
                default:
                    buttonStyle = { textTransform: 'none' };
                    break;
            }

            return (
                <Button
                    key={`bt_${op.title}`}
                    color={op.color}
                    disableElevation
                    onClick={op.onClick}
                    style={buttonStyle}
                    variant={op.variant}
                    disabled={op.disabled}
                >
                    {op.title}
                </Button>
            );
        });
    };

    return (
        <MaterialDialog
            contentStyle={contentStyle}
            maxWidth="xs"
            onClose={onClose}
            options={DialogButtons()}
            title={title}
            visible={visible}
            iconName={iconName}
        >
            {children}
        </MaterialDialog>
    );
};

export default BasicDialog;
