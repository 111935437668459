import {getString} from "../../services/api/store";

export const UNAUTHORIZED_TITLE = getString('attendeesOnlyInstallEventWarningTitle', 'No access');
export const UNAUTHORIZED_DESCRIPTION = getString('attendeesOnlyInstallEventWarningMessage',
    'The email address you specified is not on the attendee list. Check with the event organisation if you think it should.');
export const UNAUTHORIZED_BUTTON_LABEL = 'Sign in with another account';

export const HIDDEN_PASSWORD_TITLE = 'Password required';
export const HIDDEN_PASSWORD_DESCRIPTION = 'To access this event, enter the password below.';
export const HIDDEN_PASSWORD_BUTTON_LABEL = 'Continue';
