import React, { useEffect, useState } from 'react';
import {
    Container,
    InteractivityCell,
    InteractivityGrid,
    SectionTitle,
    SectionText,
} from '../style';
import { makeItem, addRating } from '../../../../services/api/data';
import { getLocalAppState } from '../../../../services/api';
import { withRouter } from 'react-router-dom';
import StarsGroup from './StarsGroup';
import { getLocalItem } from '../../../../services/api/db';
import AnalyticsService from '../../../../features/analytics/services';
import { getString } from '../../../../services/api/store';
import {
    AchievementType,
    useAchievementActions,
} from '../../../Achievements/hooks/useAchievementActions';

const RatingCard = props => {
    const [rating, setRating] = useState(0);
    const [itemId, setItemId] = useState(null);
    const [itemName, setItemName] = useState(null);
    const { trackAchievement } = useAchievementActions();

    const { objectId, objectClass } = props.match.params;

    useEffect(() => {
        makeItem(objectId, objectClass, (err, objectItem) => {
            getLocalAppState(async (err, localAppState) => {
                if (err || !localAppState) {
                    return;
                }
                if (objectItem) {
                    setItemId(objectItem.id);
                    setItemName(objectItem.name);

                    await getLocalItem('ratings', objectItem.id, (err, data) => {
                        setRating(data && data.rate);
                    });
                }
            });
        });
    }, [props.match.params.objectId]);

    const setItemRating = (rate, deleted = false) => {
        const date = new Date();
        const timestamp = date.toISOString();
        const lastUpdate = `${date.getTime()}`;

        const rateObj = {
            objectId: itemId,
            rate: parseInt(rate, 10),
            title: itemName,
            lastUpdate,
            timestamp,
            deleted: deleted ? 1 : 0,
        };

        AnalyticsService.addSample('rating', rate, itemId);
        trackAchievement(AchievementType.RATE, itemId);

        return addRating(rateObj, () => {});
    };

    const onRatingPress = async value => {
        await setItemRating(value);
        setRating(value);
    };

    return (
        <InteractivityGrid>
            <InteractivityCell size={1} key="q&a">
                <Container>
                    <SectionTitle secondary={props.theme.secondary}>
                        {getString('rateDialogTitle') || 'Rate this session'}
                    </SectionTitle>
                    <SectionText>
                        {getString('rateDialogSubtitle', 'How did you experience this session?')}
                    </SectionText>
                    <StarsGroup
                        numberOfStars={5}
                        onChange={onRatingPress}
                        rate={rating}
                        theme={props.theme}
                    />
                </Container>
            </InteractivityCell>
        </InteractivityGrid>
    );
};

export default withRouter(RatingCard);
