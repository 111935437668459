import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as palette from './Variables';

const Container = styled.div`
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 17px 16px 0 0;
    width: ${props => (props.largeCard ? '342px' : '163px')};
    height: 249px;

    &:hover {
        box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
    }
    ${props =>
        props.inDrawer
            ? `
        margin: 8px 8px 0 0 !important;
        width: ${props.largeCard ? '342px' : '163px'} !important;
        height: 249px !important;
    `
            : `
            @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
                margin: 16px 16px 0 0;
                width: ${props => (props.largeCard ? '482px' : '233px')};
                height: 345px;
            }
             @media only screen and (max-width: ${palette.MIN_TABLET}) {
                margin: 16px 16px 0 0;
                width: ${props => (props.largeCard ? '482px' : '233px')};
                height: 345px;
            }
            
            @media only screen and (max-width: ${palette.MIN_PHONE}) {
                margin: 0 0 16px 0;
                width: ${props => (props.largeCard ? '342px' : '163px')};
                height: 249px;
            }
            @media only screen and (max-width: ${palette.MIN_PHONE_XS}) {
                margin: 0 0 7px 0;
                width: ${props => (props.largeCard ? '312px' : '153px')};
                height: 249px;
            }
            `}

    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        margin: 16px 16px 0 0;
        width: ${props => (props.largeCard ? '482px' : '233px')};
        height: 345px;
    }

    @media only screen and (max-width: ${palette.MIN_TABLET}) {
        margin: 16px 16px 0 0;
        width: ${props => (props.largeCard ? '482px' : '233px')};
        height: 345px;
    }
    
    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        margin: 0 0 16px 0;
        width: ${props => (props.largeCard ? '342px' : '163px')};
        height: 249px;
    }
   @media only screen and (max-width: ${palette.MIN_PHONE_XS}) {
        margin: 0 0 8px 0;
        width: ${props => (props.largeCard ? '312px' : '153px')};
        height: 249px;
    }
`;

const ImageContainer = styled.div`
    ${props =>
        props.largeCard
            ? `
        width: 100%;
    `
            : `
        width: 233px;
    `};
    background: #fff;
    height: 233px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props =>
        props.inDrawer
            ? `
       ${
           props.largeCard
               ? `
        width: 100%;
        height: 163px;
    `
               : `
        width: 163px;
        height: 163px;
    `
       }`
            : ``};
    @media only screen and (max-width: ${palette.MIN_DESKTOP}) {
        ${props =>
            props.largeCard
                ? `
            width: 100%;
        `
                : `
        width: 163px;
        `};
        height: 163px;
    }
    @media only screen and (max-width: ${palette.MIN_TABLET}) {
        ${props =>
            props.largeCard
                ? `
            width: 100%;
        `
                : `
            width: 233px;
        `};
        height: 233px;
    }
    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        ${props =>
            props.largeCard
                ? `
            width: 100%;
        `
                : `
        width: 163px;
        `};
        height: 163px;
    }
`;

const LargeCardImage = styled.div`
    background-image: url('${props => props.imageUrl}');
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
`;

const LogoImageInstitutions = styled.img`
    width: ${props => (props.imageHeightGTWidth ? '70%' : '100%')};
    max-height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
`;

const LogoImage = styled.img`
    max-height: 100%;
    width: ${props => (props.imageHeightGTWidth ? '100%' : 'unset')};
    object-fit: cover !important;
`;

const Content = styled.div`
    position: relative;
    min-height: 80px;
    padding: 12px;
    @media only screen and (min-width: ${palette.MAX_DESKTOP}) {
        padding: 14px 20px 20px 20px;
    }
`;

const PrimaryText = styled.div`
    font-family: Cabin, sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 8px;
    max-width: 80%;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    max-height: 48px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        font-size: ${props => (props.inDrawer ? '14px' : '16px')};
        font-weight: 500;
        line-height: 1.25;
    }
`;

const SecondaryText = styled(PrimaryText)`
    font-family: Roboto, sans-serif;
    color: #7c7c7c;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    width: ${props => (props.largeCard ? '25em' : props.inDrawer ? '5em' : '12em')};
    ${props =>
        props.twolines
            ? `
        display: -webkit-box;
        max-height: 48px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    `
            : `
         white-space: nowrap !important;
         display: block !important;
    `};

    @media only screen and (max-width: ${palette.MIN_PHONE}) {
        font-size: 12px;
    }
`;

const ListCard = ({ primaryText, secondaryText, imageUrl, isLogo, rightIcon, type }) => {
    const [imageHeightGTWidth, setImageHeightGTWidth] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = function () {
            setImageHeightGTWidth(img?.height > img?.width);
        };
    }, []);

    let imageElement = <p>No image</p>;
    if (imageUrl) {
        imageElement = isLogo ? (
            <>
                {type == 'institutions' ? (
                    <LogoImageInstitutions
                        src={imageUrl}
                        imageHeightGTWidth={imageHeightGTWidth}
                        alt="Logo"
                    />
                ) : (
                    <LogoImage src={imageUrl} alt="Logo" imageHeightGTWidth={imageHeightGTWidth} />
                )}
            </>
        ) : (
            <LargeCardImage imageUrl={imageUrl} />
        );
    }
    const drower = document.getElementById('simple-drawer-example');
    const inDrawer = drower && drower.offsetWidth === 480;

    const elContainer = document.getElementById(`container-element-${primaryText}`);
    const elTitle = document.getElementById(`title-element-${primaryText}`);
    let displaySubtitleOnTwoLines = false;
    if (elContainer && elTitle) {
        if (elContainer.offsetHeight / elTitle.offsetHeight > 3) {
            displaySubtitleOnTwoLines = true;
        }
    }

    return (
        <Container largeCard={!isLogo} inDrawer={!!inDrawer}>
            <ImageContainer largeCard={!isLogo} inDrawer={!!inDrawer}>
                {imageElement}
            </ImageContainer>
            <Content id={`container-element-${primaryText}`}>
                <PrimaryText
                    largeCard={!isLogo}
                    inDrawer={!!inDrawer}
                    id={`title-element-${primaryText}`}
                >
                    {primaryText}
                </PrimaryText>
                <SecondaryText
                    largeCard={!isLogo}
                    inDrawer={!!inDrawer}
                    twolines={displaySubtitleOnTwoLines}
                >
                    {secondaryText}
                </SecondaryText>
                {rightIcon}
            </Content>
        </Container>
    );
};

export default ListCard;
