import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { getVirtualEventSession } from '../../services/api/eureka';
import { useGlobalState } from '../../utils/container';
import BookmarkButton from '../General/BookmarkButton';
import ClassifierIconLabelComponent from '../Icons/ClassifierIconLabelComponent';
import InstitutionDetailHeader from './Institution';
import PersonDetailHeader from './Person';
import TimeslotVirtualSession from './TimeslotVirtualSession';
import TimeslotDetailHeader from './Timeslot';
import { Link } from 'react-router-dom';
import { useTheme } from '../Theme/ThemeContext';
import { Flex } from '../../scenes/common/Flex';
import { FontIcon } from 'react-md';
import HappeningNow from '../General/HappeningNow';
import Bookmark from '../General/Bookmark';

const HeaderWrapper = styled.div`
    color: rgba(0, 0, 0, 0.87);
    font-size: 24px;
    line-height: 28px;
    position: relative;
`;

const HeaderTitle = styled.div`
    padding-top: ${props =>
        props.type === 'timeslot' ? '16px' : props.type === 'person' ? '46px' : '16px;'};
    padding-left: 16px;
    padding-bottom: 8px;
    padding-right: ${props =>
        props.type !== 'person' && props.type !== 'institution' && props.type !== 'timeslot'
            ? '44px;'
            : '16px;'};
    text-align: ${props =>
        props.type === 'person' || props.type === 'institution' ? 'center;' : 'left;'};
    margin-top: ${props => (props.type === 'person' ? '30px' : '0px')};
    font-family: 'Cabin', sans-serif;
    font-size: 21px;
    line-height: 24px;
    font-style: normal;
    font-weight: bold;
    color: #1e1e1f;
`;

const HeaderSubtitle = styled.div`
    color: rgba(0, 0, 0, 0.6);
    text-align: ${props =>
        props.type === 'person' || props.type === 'institution' ? 'center;' : 'left;'};
    padding: 8px 16px 0px 16px;
    font-size: 15px;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
`;

const Banner = styled.div`
    width: 100%;
    height: 224px;
    padding: 0 16px;
    cursor: ${props => (props.pointer ? 'pointer' : 'auto')};

    img {
        width: 100%;
        border-radius: 8px;
    }
`;

const ContentWrapper = styled.div`
    position: relative;
`;

export const BookmarkIconStyled = styled.div`
    top: 48px;
    top: ${props => props.top};
    position: absolute;
    right: 0px;
`;

const SponsorBannerWrapper = styled.div`
    width: 100%;
    height: 50px;
    padding-left: 16px;
`;

const SponsorBanner = styled.img`
    height: 100%;
`;

const PlaceContainer = styled(Flex)`
  padding: 0 16px 24px 16px;
  color: rgba(28, 28, 30, 1);
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;

  i {
    color: rgba(28, 28, 30, 1) !important;
    font-size: 16px;
  !important;
    margin-right: 10px;
  }
`;

const LinkStyled = styled(Link)`
    text-decoration: none !important;

    div {
        div:hover {
            color: ${props => props.primary};
            text-decoration: underline;
        }
    }
`;

const NoLink = styled.div`
    padding-bottom: 0px;
    padding-top: 0px;
`;

export const ButtonsContainer = styled(Flex)`
    padding: 0 16px 15px 16px;

    & > *:not(:last-child) {
        margin-right: 8px;
    }
`;

const Header = props => {
    const {
        backgroundImage,
        clickMyCongress,
        endTime,
        favoriteStatus,
        id,
        params,
        picture,
        startTime,
        subtitle,
        time,
        title,
        type,
        typeParams,
        currentUserGroups,
        imageUrl,
        backgroundType,
        hasCheckin,
        notesButton,
        notesButtonLarge,
    } = props;
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;
    const [virtualEventSession, setVirtualEventSession] = useState(null);
    const { theme } = useTheme();

    const refreshVirtualEventSession = async () => {
        const virtualEventSession = await getVirtualEventSession(id);

        updateData({ virtualEventSession });
    };

    const updateData = data => {
        const { virtualEventSession } = data;

        if (
            virtualEventSession &&
            virtualEventSession.message !== 'Not Found' &&
            !isEmpty(virtualEventSession)
        ) {
            setVirtualEventSession(virtualEventSession);
        } else {
            setVirtualEventSession(null);
        }
    };

    useEffect(() => {
        (async () => {
            if (!id) {
                return;
            }
            await refreshVirtualEventSession();
            const { socket } = stateCtx;

            if (!socket) {
                return;
            }

            socket.on(`updateData_${id}`, updateData);
        })();
        return () => {
            if (socket) {
                socket.off(`updateData_${id}`, updateData);
            }
        };
    }, [id]);

    const classifierColor = params && params.color ? params.color : '';

    const bookmarkPosition = type === 'institution' ? '138px' : classifierColor ? '8px' : 0;

    const renderPlaces = (theme, places) =>
        places && places.items
            ? places.items.map(function (it) {
                  const url = props.matchUrl;
                  let LinkRouter = LinkStyled;
                  let linkTo = `${url}/place/${it.id}`;
                  if (it.floorplan && !theme.embed) {
                      linkTo = `/floorplan/${it.floorplan}/${it.id}`;
                  } else {
                      LinkRouter = NoLink;
                      linkTo = '';
                  }

                  return (
                      <LinkRouter
                          to={{
                              pathname: linkTo,
                              state: { prevPath: props.prevPath },
                          }}
                      >
                          <PlaceContainer primary={theme.primary}>
                              <FontIcon>room</FontIcon>
                              <div>{it.name}</div>
                          </PlaceContainer>
                      </LinkRouter>
                  );
              })
            : [];

    const PlacesList = () =>
        props.places &&
        props.places.length &&
        renderPlaces(theme, props && props.places && props.places[0]);

    return (
        <HeaderWrapper>
            {(type === 'timeslot' || type === 'programelement' || type === 'timeslots') && (
                <>
                    {backgroundType === 'image' && backgroundImage && (
                        <Banner
                            pointer={!!(params && params.backgroundUrl)}
                            onClick={
                                params && params.backgroundUrl
                                    ? () => window.open(params.backgroundUrl, '_blank')
                                    : null
                            }
                        >
                            <img src={backgroundImage} alt="Background Image" />
                        </Banner>
                    )}
                    <HappeningNow
                        start={startTime}
                        end={endTime}
                        color={theme.contrast}
                        margin={'16px 16px 0 16px'}
                    />
                    <HeaderTitle type={type}>{title}</HeaderTitle>
                    {subtitle && subtitle !== '' && (
                        <HeaderSubtitle type={type}>{subtitle}</HeaderSubtitle>
                    )}
                    {type === 'timeslot' && (
                        <TimeslotDetailHeader time={time} start={startTime} end={endTime} />
                    )}
                    <PlacesList />
                </>
            )}

            <ContentWrapper>
                {type === 'person' && (
                    <PersonDetailHeader
                        picture={picture}
                        backgroundImage={typeParams.backgroundImage}
                        favoriteStatus={favoriteStatus}
                        clickMyCongress={clickMyCongress}
                    />
                )}
                {type === 'institution' && (
                    <InstitutionDetailHeader
                        picture={picture}
                        backgroundImage={backgroundImage}
                        showClickBanner={typeParams.showClickBanner}
                        params={params}
                        favoriteStatus={favoriteStatus}
                        clickMyCongress={clickMyCongress}
                    />
                )}
                {type !== 'timeslot' &&
                    type !== 'programelement' &&
                    type !== 'timeslots' &&
                    type !== 'classifier' && <HeaderTitle type={type}>{title}</HeaderTitle>}
                {(type === 'timeslot' || type === 'programelement' || type === 'timeslots') && (
                    <TimeslotVirtualSession
                        time={time}
                        start={startTime}
                        end={endTime}
                        id={id}
                        params={params || {}}
                        virtualEventSession={virtualEventSession}
                        type={type}
                        favoriteStatus={favoriteStatus}
                        clickMyCongress={clickMyCongress}
                        notesButton={notesButton}
                        notesButtonLarge={notesButtonLarge}
                        typeParams={typeParams}
                        currentUserGroups={currentUserGroups}
                        hasCheckin={hasCheckin}
                    />
                )}
                {type !== 'timeslot' &&
                    type !== 'programelement' &&
                    type !== 'timeslots' &&
                    subtitle &&
                    subtitle !== '' && <HeaderSubtitle type={type}>{subtitle}</HeaderSubtitle>}
                {type === 'institution' && <PlacesList />}
                {type !== 'timeslot' &&
                    type !== 'programelement' &&
                    type !== 'timeslots' &&
                    type !== 'classifier' && (
                        <ButtonsContainer alignItems={'center'} justifyContent={'space-between'}>
                            <BookmarkButton
                                favoriteStatus={favoriteStatus}
                                clickMyCongress={clickMyCongress}
                            />

                            {notesButtonLarge}
                        </ButtonsContainer>
                    )}
                {type === 'classifier' && (
                    <>
                        <Flex width={'unset'} margin={'0 0 0 16px'}>
                            {(params?.icon || classifierColor) && (
                                <ClassifierIconLabelComponent classifierId={id} iconSize={'32px'} />
                            )}
                            <HeaderTitle type={type}>{title}</HeaderTitle>
                        </Flex>
                        <Bookmark
                            favoriteStatus={favoriteStatus}
                            clickMyCongress={clickMyCongress}
                            top={7}
                        />
                    </>
                )}
                {/*{imageUrl && type !== 'person' && type !== 'institution' && (*/}
                {/*    <SponsorBannerWrapper>*/}
                {/*        <SponsorBanner src={imageUrl} alt="Sponsor" />*/}
                {/*    </SponsorBannerWrapper>*/}
                {/*)}*/}
                {/*{classifierIcons.length > 0 &&*/}
                {/*    (type === 'timeslot' || type === 'programelement') && (*/}
                {/*        <IconsContainer>{classifierIcons}</IconsContainer>*/}
                {/*    )}*/}
            </ContentWrapper>
        </HeaderWrapper>
    );
};

export default Header;
