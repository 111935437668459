import axios from 'axios';
import { getLocalAppState } from '../../../services/api';

/**
 * Creates a new installation of an app.
 *
 * @param {Object} installation Installation of this app in the Conference Compass backend server.
 *
 * @return {Promise} The ongoing request.
 */
const storeInstallation = async (configuratorUrl, id, installation) => {
    const CONFIGURATOR_URL = configuratorUrl;
    const url = `${CONFIGURATOR_URL}/api/installation`;
    const requestConfig = {
        headers: {
            'x-event-id': id,
        },
    };

    const response = await axios.post(url, installation, requestConfig);
    return response.data;
};

const createInstallation = (configuratorUrl, id, installation) => {
    return new Promise((resolve, reject) => {
        getLocalAppState(async (err, appState) => {
            if (appState && appState.installationId) {
                if (err) {
                    reject(err);
                } else {
                    resolve({
                        status: 'update',
                        id: appState.installationId,
                    });
                }
            } else {
                const newInstallation = await storeInstallation(configuratorUrl, id, installation);
                resolve({
                    status: 'add',
                    id: newInstallation._id,
                });
            }
        });
    });
};

/**
 * Send samples to backend
 */
const sendSamples = async (
    samples,
    installationId = null,
    eventId = null,
    configuratorUrl = null,
) => {
    return new Promise((resolve, reject) => {
        getLocalAppState(async (err, appState) => {
            if (err) {
                reject(err);
            } else {
                const CONFIGURATOR_URL = configuratorUrl || appState.configuratorUrl;
                const url = `${CONFIGURATOR_URL}/api/general/analytics/`;
                const requestConfig = {
                    headers: {
                        'x-event-id': eventId || appState.id,
                    },
                };

                const data = {
                    installation: installationId || appState.installationId,
                    analyticSamples: samples,
                };

                try {
                    const response = await axios.post(url, data, requestConfig);
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            }
        });
    });
};

export { createInstallation, sendSamples };
