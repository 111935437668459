import styled from 'styled-components';
import { FontIcon } from 'react-md';
import { Flex } from '../../common/Flex';

export const Card = styled.div`
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(47, 51, 55, 0.25);
    background-color: #ffffff;
    margin: 16px;
    padding: 16px;
`;

export const ShowMore = styled.div`
    width: 100%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
    cursor: pointer;
`;

export const CardTitle = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.84);
    margin-bottom: 12px;
`;

export const CardButton = styled.button`
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: none;
    height: 36px;
    border-radius: 18px;
    background-color: ${props => (props.color ? props.color : '#d50000')};
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    margin: 0 5px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px -1px rgba(0, 0, 0, 0.4);
`;

export const CardDateTimeLocationWrapper = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 16px;
    div:nth-of-type(2) {
        margin-top: 4px;
    }
`;

export const ButtonIcon = styled(FontIcon)`
    color: #ffffff !important;
    margin-right: 8px;
`;

export const FlexButtonsWrapper = styled(Flex)`
    div {
        flex: 1;
    }
    & > :first-child {
        margin-right: 8px;
    }
`;

