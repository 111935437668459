import React, { useState, useEffect } from 'react';
import InteractivityContext from '../../../components/Interactivity/InteractivityContext';
import AuthUserContext from '../../../components/Session/AuthUserContext';
import ThemeContext from '../../../components/Theme/ThemeContext';
import QuestionsAndAnswers from './QuestionsAndAnswers';
import EvaluationCard from './Evaluation';
import VoteCard from './Vote';
import { withRouter } from 'react-router-dom';
import { makeItem } from '../../../services/api/data';
import RatingCard from './rating/Rating';
import { GroupChatCard } from '../../Talk/containers/GroupChatCard';
import { getLocalAppState } from '../../../services/api';
import {
    getVirtualEventPollSetsByEventAndItemIds,
    getVirtualEventSession,
} from '../../../services/api/eureka';
import { useGlobalState } from '../../../utils/container';

const InteractivityBar = props => {
    const [askActive, setAskActive] = useState(false);
    const [virtualSession, setVirtualSession] = useState(null);
    const [ratingActive, setRatingActive] = useState(false);
    const [evaluateActive, setEvaluateActive] = useState(false);
    const [chatActive, setChatActive] = useState(false);
    const [voteActive, setVoteActive] = useState(false);
    const [localAppState, setLocalAppState] = useState(null);
    const { objectId, objectClass } = props.match.params;
    const { matchUrl } = props;
    const stateCtx = useGlobalState();
    const { socket } = stateCtx;

    useEffect(() => {
        makeItem(objectId, objectClass, (err, objectItem) => {
            getLocalAppState(async (err, localAppState) => {
                if (err || !localAppState) {
                    return;
                }
                setLocalAppState(localAppState);
                if (objectItem && objectItem.interactivity && objectItem.interactivity.length > 0) {
                    const ask = objectItem.interactivity.find(item => item.id === 'questions');
                    const rating = objectItem.interactivity.find(item => item.id === 'rating');
                    const evaluate = objectItem.interactivity.find(
                        obj => obj.kind === 'evaluations',
                    );
                    const vote = objectItem.interactivity.find(item => item.id === 'polling');
                    const chat = objectItem.interactivity.find(item => item.id === 'chat');

                    setEvaluateActive(!!evaluate);
                    setRatingActive(!!rating);
                    setChatActive(!!chat);

                    if (vote) {
                        loadData(localAppState.eventId, objectItem.id);
                        socket.on(`pollRefresh_${objectItem.id}`, () =>
                            loadData(localAppState.eventId, objectItem.id),
                        );
                    } else {
                        setVoteActive(false);
                    }

                    const virtualEventSession = await getVirtualEventSession(
                        props.match.params.objectId,
                    );
                    setVirtualSession(virtualEventSession);

                    if (ask) {
                        setAskActive(ask.filled);
                        socket.on(`updateData_${props.match.params.objectId}`, updateData);
                    } else {
                        setAskActive(false);
                    }
                } else {
                    // Set to default state
                    setAskActive(false);
                    setRatingActive(false);
                    setEvaluateActive(false);
                    setChatActive(false);
                    setVoteActive(false);
                }
            });
        });
    }, [props.match.params.objectId]);

    const updateData = async data => {
        const { virtualEventSession } = data;

        setVirtualSession(virtualEventSession);
    };

    const loadData = async (eventId, itemId) => {
        const pollSets = await getVirtualEventPollSetsByEventAndItemIds(eventId, itemId);
        if (pollSets && pollSets[0]) {
            setVoteActive(pollSets[0].state === 'open');
        }
    };

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <InteractivityContext.Consumer>
                    {({ showSignIn }) => (
                        <ThemeContext.Consumer>
                            {({ theme }) => (
                                <>
                                    {voteActive && (
                                        <VoteCard theme={theme} localAppState={localAppState} />
                                    )}
                                    {askActive && (
                                        <QuestionsAndAnswers
                                            authUser={authUser}
                                            showSignIn={showSignIn}
                                            theme={theme}
                                            localAppState={localAppState}
                                            virtualSession={virtualSession} //virtualSession.showQA
                                        />
                                    )}
                                    {evaluateActive && (
                                        <EvaluationCard
                                            theme={theme}
                                            localAppState={localAppState}
                                            setShowEvaluationScreenExternal={
                                                props.setShowEvaluationScreenExternal
                                            }
                                            setEvaluationScreen={props.setEvaluationScreen}
                                        />
                                    )}
                                    {ratingActive && <RatingCard theme={theme} />}
                                    {chatActive && (
                                        <GroupChatCard itemId={objectId} matchUrl={matchUrl} />
                                    )}
                                </>
                            )}
                        </ThemeContext.Consumer>
                    )}
                </InteractivityContext.Consumer>
            )}
        </AuthUserContext.Consumer>
    );
};

export default withRouter(InteractivityBar);
