import React from 'react';

import * as StyledComponent from '../../style/modalsStyle';
import facebookLogo from '../../../../assets/social/icon-filled-facebook.png';
import linkedinLogo from '../../../../assets/social/icon-filled-linkedin.png';
import twitterLogo from '../../../../assets/social/icon-filled-twitter.png';
import * as palette from '../../../../components/General/Variables';

export const SocialLinks = ({ fieldValues }) => (
    <StyledComponent.SocialLinksContainer>
        {fieldValues.facebookLink && (
            <StyledComponent.SocialLink>
                <StyledComponent.LinkLogo src={facebookLogo} alt="Facebook" />
            </StyledComponent.SocialLink>
        )}
        {fieldValues.linkedinLink && (
            <StyledComponent.SocialLink>
                <StyledComponent.LinkLogo src={linkedinLogo} alt="LinkedIn" />
            </StyledComponent.SocialLink>
        )}
        {fieldValues.twitterLink && (
            <StyledComponent.SocialLink>
                <StyledComponent.LinkLogo src={twitterLogo} alt="LinkedIn" />
            </StyledComponent.SocialLink>
        )}
        {fieldValues.webLink && (
            <StyledComponent.SocialLink>
                <StyledComponent.LinkIcon>{palette.ICON_GLOBE}</StyledComponent.LinkIcon>
            </StyledComponent.SocialLink>
        )}
    </StyledComponent.SocialLinksContainer>
);
