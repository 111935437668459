import React, { useEffect, useState } from 'react';
import MenuButton from 'react-md/lib/Menus/MenuButton';
import { Button, FontIcon } from 'react-md';
import { CheckFontIcon } from '@react-md/material-icons';
import styled from 'styled-components';
import AgoraRTC from 'agora-rtc-sdk-ng';

import * as palette from '../../../../components/General/Variables';
import { useGlobalMutation, useGlobalState } from '../../../../utils/container';
import MutedBySystemIcon from '../../../../components/Icons/MutedBySystemIcon';

const Wrapper = styled.div`
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    display: flex;
    flex-flow: row;
    height: 80px;
    margin-top: 80px;
    left: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 4;
`;

const styles = {
    buttonStyle: {
        height: '48px',
        width: '48px',
        border: 'solid 1px #cccccc',
    },
};

const OptionsButton = styled.i`
    cursor: pointer;
    margin: 0 0 0 10px;
`;

const SettingsWrapper = styled.div`
    #settings-list {
        display: flex;
        flex-direction: column;
    }

    [id*='-toggle'] {
        background: #fff !important;
        color: #000 !important;
        box-shadow: none !important;
    }

    #settings-toggle {
        min-width: 40px !important;
        box-sizing: border-box;
        max-width: 40px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .md-list-tile {
        padding-left: 16px !important;
    }
`;

const Footer = props => {
    const mutationCtx = useGlobalMutation();
    const stateCtx = useGlobalState();
    const [isMicOn, setIsMicOn] = useState(true);
    const [isVidOn, setIsVidOn] = useState(true);
    const { config, cameraList, microphoneList, mutedBySystem } = stateCtx;

    const { id, timeslotId } = props.currentVirtualEventUser;

    useEffect(() => {
        const { isMicrophoneOn, isVideoOn } = props.currentVirtualEventUser;
        setIsMicOn(isMicrophoneOn);
        setIsVidOn(isVideoOn);
    }, [props.currentVirtualEventUser]);

    const toggleAudio = async () => {
        if (mutedBySystem) {
            mutationCtx.setMicMutedDialog(true);
            return;
        }

        const isNowMicOn = isMicOn;

        setIsMicOn(!isNowMicOn);

        await props.updateVirtualEventUser({
            id,
            isMicrophoneOn: !isNowMicOn,
        });

        if (props.socket) {
            props.socket.emit('updateData', { objectId: timeslotId });
        }
    };

    const toggleVideo = async () => {
        const isNowVidOn = isVidOn;
        setIsVidOn(!isNowVidOn);

        await props.updateVirtualEventUser({
            id,
            isVideoOn: !isNowVidOn,
        });

        if (props.socket) {
            props.socket.emit('updateData', { objectId: timeslotId });
        }
    };

    const cameraOptions = cameraList.map(camera => ({
        primaryText: camera.label,
        onClick: () => {
            mutationCtx.changeCamera(camera.deviceId);
        },
        rightIcon: config.cameraId === camera.deviceId ? <CheckFontIcon /> : undefined,
    }));
    const microphoneOptions = microphoneList.map(microphone => ({
        primaryText: microphone.label,
        onClick: () => {
            mutationCtx.changeMicrophone(microphone.deviceId);
        },
        rightIcon: config.microphoneId === microphone.deviceId ? <CheckFontIcon /> : undefined,
    }));

    AgoraRTC.onMicrophoneChanged = deviceInfo => {
        mutationCtx.startUsingDevices(
            undefined,
            deviceInfo.state === 'ACTIVE' ? deviceInfo.device.deviceId : undefined,
            undefined,
            deviceInfo.state === 'INACTIVE',
        );
    };

    AgoraRTC.onCameraChanged = deviceInfo => {
        mutationCtx.startUsingDevices(
            undefined,
            undefined,
            deviceInfo.state === 'ACTIVE' ? deviceInfo.device.deviceId : undefined,
            deviceInfo.state === 'INACTIVE',
        );
    };

    return (
        <Wrapper>
            <Button
                style={{
                    ...styles.buttonStyle,
                    marginRight: '15px',
                    backgroundColor: !config.cameraId
                        ? 'gray'
                        : isVidOn
                        ? 'transparent'
                        : '#d50000',
                    overflow: 'hidden',
                }}
                icon
                forceIconSize={32}
                disabled={!config.cameraId}
                onClick={toggleVideo}
            >
                <FontIcon
                    style={{
                        fontSize: '24px',
                        marginLeft: '1px',
                        color: isVidOn ? 'rgba(0,0,0,0.87)' : '#ffffff',
                    }}
                >
                    {isVidOn ? palette.ICON_CAMERA : palette.ICON_CAMERA_OFF}
                </FontIcon>
            </Button>
            <Button
                style={{
                    ...styles.buttonStyle,
                    backgroundColor: !config.microphoneId
                        ? 'gray'
                        : isMicOn || mutedBySystem
                        ? 'transparent'
                        : '#d50000',
                }}
                icon
                forceIconSize={32}
                onClick={toggleAudio}
            >
                {mutedBySystem && <MutedBySystemIcon>!</MutedBySystemIcon>}
                <FontIcon
                    style={{
                        fontSize: '24px',
                        marginLeft: '-6px',
                        color: isMicOn || mutedBySystem ? 'rgba(0,0,0,0.87)' : '#ffffff',
                    }}
                >
                    {isMicOn || mutedBySystem ? palette.ICON_MIC : palette.ICON_MIC_OFF}
                </FontIcon>
            </Button>
            <SettingsWrapper>
                <MenuButton
                    adjusted={0}
                    raised
                    primary
                    id="settings"
                    menuItems={[
                        <MenuButton
                            adjusted={0}
                            raised
                            primary
                            key="video-settings"
                            id="video-settings"
                            menuItems={cameraOptions}
                            simplifiedMenu={false}
                            anchor={{
                                x: MenuButton.HorizontalAnchors.RIGHT,
                                y: MenuButton.VerticalAnchors.TOP,
                            }}
                            repositionOnScroll={false}
                        >
                            Video Source
                        </MenuButton>,
                        <MenuButton
                            adjusted={0}
                            raised
                            primary
                            key="audio-settings"
                            id="audio-settings"
                            menuItems={microphoneOptions}
                            simplifiedMenu={false}
                            anchor={{
                                x: MenuButton.HorizontalAnchors.RIGHT,
                                y: MenuButton.VerticalAnchors.TOP,
                            }}
                            repositionOnScroll={false}
                        >
                            Audio Source
                        </MenuButton>,
                    ]}
                    simplifiedMenu={false}
                    anchor={{
                        x: MenuButton.HorizontalAnchors.RIGHT,
                        y: MenuButton.VerticalAnchors.TOP,
                    }}
                    repositionOnScroll={false}
                >
                    <OptionsButton className="icon-caret-down" />
                </MenuButton>
            </SettingsWrapper>
        </Wrapper>
    );
};

export default Footer;
