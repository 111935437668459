import React, { useState } from 'react';
import Auth from '../../services/api/auth';
import AuthenticationProtection from '../ProtectedPages/AuthenticationProtection';
import { HiddenPasswordModal } from './index';

const ProtectionWrapper = ({ eurekaOnly, attendingOnly, hiddenPassword, children }) => {
    const [_, setIsPasswordEntered] = useState(false);
    const user = Auth.getUser();
    const enteredHiddenPassword = localStorage.getItem('enteredHiddenPassword');

    if (eurekaOnly && !user) {
        return <AuthenticationProtection />;
    }

    if (hiddenPassword && (!enteredHiddenPassword || enteredHiddenPassword !== hiddenPassword)) {
        return (
            <HiddenPasswordModal
                password={hiddenPassword}
                onDone={() => setIsPasswordEntered(true)}
            />
        );
    }

    if (attendingOnly && !user) {
        return <AuthenticationProtection />;
    }

    return children;
};

export default ProtectionWrapper;
