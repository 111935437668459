import React from 'react';
import styled from 'styled-components';
import { InteractivityContext } from '../Interactivity';
import AuthUserContext from '../Session/AuthUserContext';
import ThemeContext from '../Theme/ThemeContext';
import * as palette from './Variables';
import { FontIcon } from 'react-md';

const IconDiv = styled.div.attrs({})`
    float: right;
    width: 40px;
    height: 40px;
    right: 16px;
    top: 11px;
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        right: 8px;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.04);
        border-radius: 20px;
    }

    &:active {
        background: rgba(0, 0, 0, 0.12);
        border-radius: 20px;
    }
`;

const Icon = styled(FontIcon)`
    font-size: 18px !important;
    color: ${props => (props.color ? props.color : '#000000')} !important;
    cursor: pointer;
`;

const BookmarkIcon = props => {
    if (!props.clickMyCongress) {
        return null;
    }
    return (
        <AuthUserContext.Consumer>
            {authUser => {
                return (
                    <InteractivityContext.Consumer>
                        {({ showSignIn }) => (
                            <ThemeContext.Consumer>
                                {({ theme }) => (
                                    <React.Fragment>
                                        {!theme.embed && !theme.kiosk && (
                                            // Icons' SVG code edited to remove fill color
                                            <IconDiv
                                                style={{ top: props.top + 'px' }}
                                                onClick={
                                                    authUser ? props.clickMyCongress : showSignIn
                                                }
                                            >
                                                <Icon
                                                    color={
                                                        props.favoriteStatus === 'checked' &&
                                                        theme.primary
                                                    }
                                                >
                                                    {props.favoriteStatus === 'checked'
                                                        ? 'bookmark'
                                                        : 'bookmark_border'}
                                                </Icon>
                                            </IconDiv>
                                        )}
                                    </React.Fragment>
                                )}
                            </ThemeContext.Consumer>
                        )}
                    </InteractivityContext.Consumer>
                );
            }}
        </AuthUserContext.Consumer>
    );
};

export default BookmarkIcon;
