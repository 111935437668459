import React from 'react';

import * as StyledComponent from '../../style/modalsStyle';
import backgroundPlaceholder from '../../../../assets/images/actorbg.png';
import * as palette from '../../../../components/General/Variables';

export const BackgroundAndAvatar = ({ theme, fieldValues, onImageChange, authUser }) => (
    <React.Fragment>
        <StyledComponent.BackgroundImageWrapper>
            <StyledComponent.BackgroundImage
                src={fieldValues.coverUrl ? fieldValues.coverUrl : backgroundPlaceholder}
            />
            <StyledComponent.BackgroundInputWrapper>
                <label htmlFor="background-image-upload">
                    <StyledComponent.AddImage primarycolor={theme.primary}>
                        {palette.ADD_PHOTO}
                    </StyledComponent.AddImage>
                </label>
                <input
                    id="background-image-upload"
                    name="coverUrl"
                    type="file"
                    accept="image/*"
                    onChange={onImageChange}
                />
            </StyledComponent.BackgroundInputWrapper>
        </StyledComponent.BackgroundImageWrapper>

        <StyledComponent.ProfileImageWrapper>
            <StyledComponent.EurekaAvatar
                avatarcolor={authUser.userColor}
                src={fieldValues.imageUrl}
                role="presentation"
            >
                {`${authUser.firstName[0]}${authUser.lastName[0]}`}
            </StyledComponent.EurekaAvatar>
            <StyledComponent.ProfileInputWrapper>
                <label htmlFor="profile-image-upload">
                    <StyledComponent.AddImage primarycolor={theme.primary}>
                        {palette.ADD_PHOTO}
                    </StyledComponent.AddImage>
                </label>
                <input
                    id="profile-image-upload"
                    name="imageUrl"
                    type="file"
                    accept="image/*"
                    onChange={onImageChange}
                />
            </StyledComponent.ProfileInputWrapper>
        </StyledComponent.ProfileImageWrapper>
    </React.Fragment>
);
