import moment from 'moment';

import talkManager from '../services';
import Buddy from './buddy';
import { getNickAsInt } from '../actions';

const STANZA_TYPE = Object.freeze({
    CHAT: 'chat',
    GROUP_CHAT: 'groupchat',
    ROSTER: 'roster',
});

const parseMessage = (stanza, isPrivate = true) => {
    const body = stanza.getChild('body');

    if (!body) {
        return null;
    }

    const messageText = body.getText();
    const { from } = stanza.attrs;
    const room = from.split('/')[0];
    let participantId = isPrivate ? from.split('@')[0] : from.split('/')[1];
    if (!isPrivate) {
        participantId = getNickAsInt(participantId);
    }

    let timestamp;

    const stanzaId = stanza.getChild('stanza-id');
    if (stanzaId) {
        timestamp = stanzaId.attrs.id;
    }

    const stanzaDelay = stanza.getChild('delay');
    if (stanzaDelay) {
        return null;
    }

    return {
        conversation: {
            id: parseInt(room.split('@')[0], 10),
            jid: room,
        },
        message: {
            id: parseFloat(timestamp, 10),
            txt: messageText,
            created_at: moment(parseFloat(timestamp, 10) / 1000).format(),
            timestamp,
            nick: participantId,
            participant: parseInt(participantId, 10),
        },
    };
};

const handleRoster = stanza => {
    const rosterObj = stanza.getChild('query').getChildren('item');
    let roster;

    if (rosterObj) {
        const buddiesJids = [];
        const buddies = rosterObj.map(item => {
            buddiesJids.push(item.attrs.jid);
            return new Buddy(item.attrs.jid, item.attrs.subscription);
        });

        roster = {
            buddiesJids,
            buddies,
        };
    }

    if (roster) {
        talkManager.handleRoster(roster);
    }
};

const handleMessage = stanza => {
    if (stanza.attrs.type === STANZA_TYPE.CHAT) {
        const messageData = parseMessage(stanza, true);
        talkManager.handleMessage(messageData);
    }

    if (stanza.attrs.type === STANZA_TYPE.GROUP_CHAT) {
        const messageData = parseMessage(stanza, false);
        if (messageData) {
            talkManager.handleGroupMessage(messageData);
        }
    }
};

const handlePresence = stanza => {
    const { from, to, type } = stanza.attrs;
    const { children } = stanza;

    // skip if from === to
    if (from === to) {
        return;
    }

    if (type === 'subscribe') {
        talkManager.acceptSubscription(from);
    } else {
        const isUserStatusUpdate = !children.find(
            c => c.attrs.xmlns === 'http://jabber.org/protocol/muc#user',
        );

        if (isUserStatusUpdate) {
            const participantId = parseInt(from.split('@')[0]);
            const online = type !== 'unavailable';

            talkManager.handlePresence({ participantId, online });
        } else {
            talkManager.sendCachedMessage();
        }
    }
};

const parseStanza = stanza => {
    if (stanza.is('iq') && stanza.attrs.type !== 'error') {
        if (stanza.attrs.id === 'roster_1' && stanza.attrs.type === 'result') {
            handleRoster(stanza);
        } else if (stanza.attrs.id === 'blocklist_1' && stanza.attrs.type === 'result') {
            const items = stanza
                .getChild('blocklist')
                .getChildren('item')
                .map(item => item.attrs.jid);
            if (items && items.length) {
                // talkManager.handleBlockList(items);
            }
        }
    }

    if (stanza.attrs.type === 'error') {
        console.log(
            ' >>>>>>>>>>>>>>>>>>>>>>>>>>>>> stanza error: ' + JSON.stringify(stanza, null, '    '),
        );
    }

    if (stanza.is('message')) {
        handleMessage(stanza);
    }

    if (stanza.is('presence')) {
        handlePresence(stanza);
    }
};

export default parseStanza;
