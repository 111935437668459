import React, {useState} from 'react';
import {DropdownMenu, TextField, SelectField, Tooltipped} from "react-md";
import styled from 'styled-components';
import {useTheme} from "../Theme/ThemeContext";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
  height: 40px;

  .md-list {
    width: fit-content;
    padding: 8px;
    border-radius: 6px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    margin-top: 6px;
  }

  .md-list-tile {
    border-radius: 8px;
  }

  .md-text-field-container {
    background-color: #efefef;
    border-radius: 6px;
    overflow: hidden;
  }

  .md-text-field-container--input input {
    padding: 0 12px;
    
    &::placeholder {
      font-size: 15px;
      line-height: 1.6;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  
  .md-divider {
    height: 0;
  }

  .md-divider--text-field-error {
    height: 3px;
    background: #f52a40;
  }

  .md-divider--text-field::after {
    height: 3px;
    background: ${props => props.color};
  }
`;

const ErrorMessage = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #f52a40;
`;

const CustomSelect = ({ listItems = [], placeholder, textFieldValue, error, tooltipError, disabled }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const { theme } = useTheme();

    const onVisibilityChange = newValue => !disabled && setDropdownVisible(newValue);

    return (
        <StyledDropdownMenu
            id={`textfield-dropdown-menu-${placeholder}`}
            menuItems={listItems}
            anchor={{
                x: DropdownMenu.HorizontalAnchors.INNER_LEFT,
                y: DropdownMenu.VerticalAnchors.BOTTOM,
            }}
            position={DropdownMenu.Positions.BELOW}
            onVisibilityChange={onVisibilityChange}
            visible={dropdownVisible}
            toggleQuery=".md-text-field-container"
            color={theme.primary}
            disabled={disabled}
        >
            <div>
                <CustomTooltip
                    visible={!!error && tooltipError}
                    text={error}
                >
                    <TextField
                        id={`dropdown-menu-textfield-${placeholder}`}
                        placeholder={placeholder}
                        value={textFieldValue}
                        error={!!error}
                        disabled={disabled}
                    />
                </CustomTooltip>
                {!!error && !tooltipError && <ErrorMessage>{error}</ErrorMessage>}
            </div>
        </StyledDropdownMenu>
    );
};

export default CustomSelect;