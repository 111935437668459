import styled from 'styled-components';
import { FontIcon } from '@react-md/icon';

export const WidgetFrame = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
`;

export const WidgetFrameWith2Cols = styled(WidgetFrame)`
    & > * {
        ${props => props.isFirefox && `height: 20vh !important`};
        flex: 0 calc(50% - 24px);
        width: calc(50% - 24px);
    }
`;

export const WidgetFrameWith2ColsMedium = styled(WidgetFrameWith2Cols)`
    & > * {
        ${props => props.isFirefox && `height: 28vh !important`};
        flex: 0 calc(50% - 24px);
        width: calc(50% - 24px);
    }
`;

export const WidgetFrameWith2ColsDirectionColumn = styled(WidgetFrameWith2Cols)`
    flex-direction: column;
    ${props => props.isFirefox && `height: inherit !important`};
`;

export const WidgetFrame1Tile = styled(WidgetFrame)`
    ${props => props.isFirefox && `height: inherit !important`};
    & > * {
        flex: 0 100%;
    }
`;

export const WidgetTile = styled.div`
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    background-color: ${props => props.backgroundColor || '#e6e6e6'};
    margin: 0 24px 24px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${props => props.color || 'inherit'};
    cursor: pointer;
    transition: box-shadow 0.3s;
    ${props => props.fullHeight && 'flex: 0 100%;'}
    ${props =>
        props.customheight && `height: ${props.customheight}; margin-bottom: 24px;`}

    &:hover {
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
    }
`;

export const UpcomingSessionsTile = styled.div`
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    margin: 0 24px 24px 0;
    transition: box-shadow 0.3s;
    padding: 12px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const SessionsContainer = styled.div`
    overflow: scroll;
`;

export const WidgetTileBackgroundImage = styled.div`
    border-radius: 4px;
    overflow: hidden;
    background-image: url('${props => props.imageUrl}');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;;
    background-position: center center;
    text-align: center;
`;

export const TileTitle = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin: 0 8px 24px 16px;
`;

export const TileIcon = styled(FontIcon)`
    font-size: 300%;
    margin-bottom: 10px;
    width: 42px;
`;

export const TileLabel = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
`;
