import React, { useEffect } from 'react';
import get from 'lodash/get';

import PresentationOrScreen from '../../common/PresentationOrScreen';
import { useGlobalMutation, useGlobalState } from '../../../../../utils/container';
import Voting from '../../vote/Voting';

const ShareContent = props => {
    const { isVotingActive, setIsVotingActive } = props;
    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();

    const { virtualEventSession, pollSets, mainMedia, mainMediaSwitchedByUser } = stateCtx;
    const mainIsSlides = mainMedia === 'slides';
    const showVoteResults = get(virtualEventSession, 'showVoteResults', false);
    const notBroadcasting = get(virtualEventSession, 'status') !== 'broadcasting';
    const polls = get(pollSets, 'Polls', []);
    const activePoll = polls.find(poll => poll.active);

    useEffect(() => {
        if (pollSets && !notBroadcasting && activePoll) {
            setIsVotingActive(true);
        } else {
            setIsVotingActive(false);
        }
    }, [isVotingActive, pollSets, notBroadcasting, activePoll, polls]);

    useEffect(() => {
        // we do the switch only if the main view is not slides and voting is active
        if (!mainIsSlides && pollSets && !notBroadcasting && activePoll && isVotingActive) {
            mutationCtx.switchMain();
        }
    }, [isVotingActive]);

    if (pollSets && !notBroadcasting && activePoll) {
        return (
            <Voting
                pollSets={pollSets}
                stateCtx={stateCtx}
                activePoll={activePoll}
                showVoteResults={showVoteResults}
                {...props}
            />
        );
    }

    return <PresentationOrScreen showVoteResults={showVoteResults} pollSets={pollSets} />;
};

export default ShareContent;
