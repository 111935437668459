import React from 'react';
import { Button, DialogContainer } from 'react-md';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import PersonListItem from '../../../components/ObjectListItem/Person';

const StyledButton = styled(Button)`
    color: #44bb90 !important;
`;

const StyledDialogContainer = styled(DialogContainer)`
    .md-list-item {
        list-style-type: none;
    }
`;

const UsersModal = ({ users, onClose, rootRoute, history }) => {
    const onUserClick = user => {
        onClose();
        history.push(`${rootRoute}/profile/${user.id}`);
    };

    return (
        <StyledDialogContainer
            id="users-list"
            visible={true}
            title="Likes"
            onHide={onClose}
            actions={[
                <StyledButton flat onClick={onClose}>
                    Close
                </StyledButton>,
            ]}
            width={600}
        >
            {users.map(user => (
                <div onClick={() => onUserClick(user)}>
                    <PersonListItem person={user} />
                </div>
            ))}
        </StyledDialogContainer>
    );
};

export default withRouter(UsersModal);
