import async from 'async';
import each from 'lodash/each';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { Cell } from 'react-md';
import styled from 'styled-components';
import Loader from '../../../components/General/Loader';
import NavigationBar from '../../../components/DetailHeader/NavigationBar.js';
import Search from '../../../components/General/Search';
import * as palette from '../../../components/General/Variables';
import Section from '../../DetailPage/Section';
import { getPlacesByType } from '../services/floorplan';
import { DetailDrawer } from '../../../components/General/DetailDrawer';

const StyledDetailDrawer = styled(DetailDrawer)`
    z-index: 10;
`;

const Header = styled(Cell)`
    margin: 0px;
    height: 80px;
    background-color: ${palette.COLOR_WHITE};
    width: 100% !important;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    z-index: 1;
    position: sticky;
    top: 0;

    @media only screen and (min-width: ${palette.MIN_DESKTOP}) {
        padding-top: 10px;
    }
`;

const Scrollable = styled.section.attrs({
    className: '',
})`
    height: calc(100% - 48px);
    overflow: auto;
`;

class FloorplanDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            places: {},
            visible: false,
            searchStr: '',
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            visible: nextProps.visible,
        };
    }

    componentDidMount() {
        this.setState({
            visible: this.props.visible,
        });
        this.getPlaces();
    }

    onSearch = str => {
        this.getPlaces(str);
    };

    getPlaces = str => {
        let places = {};
        if (this.props.sections && this.props.sections.length) {
            let types = this.props.sections;
            types = sortBy(types, o => o.title);
            async.eachSeries(
                types,
                (item, next) => {
                    getPlacesByType(item.typeId, (err, result) => {
                        if (!err) {
                            result = sortBy(result, ['orderingName', 'name']);
                            places[item.title] = filter(result, function (item) {
                                return str
                                    ? item.name.toLowerCase().includes(str.toLowerCase())
                                    : true;
                            });
                            next();
                        } else {
                            next(err, null);
                        }
                    });
                },
                err => {
                    if (!err) {
                        this.setState({
                            loading: false,
                            places: places,
                            searchStr: str,
                        });
                    }
                },
            );
        }
    };

    closeDrawer = () => {
        this.props.onClose();
    };

    render() {
        let sections = [];
        const displayDrawer = this.state.visible;
        each(Object.keys(this.state.places), (key, index) => {
            if (this.state.places[key] && this.state.places[key].length) {
                const section = (
                    <Section key={key} type={'place'} title={key} items={this.state.places[key]} />
                );
                sections.push(section);
            }
        });

        return (
            <StyledDetailDrawer>
                <NavigationBar
                    onClose={this.closeDrawer}
                    typeName={'Locations'}
                    background={palette.COLOR_WHITE}
                />

                <Scrollable>
                    <Header>
                        <Search
                            action={this.onSearch}
                            live={true}
                            startingtext={this.state.searchStr}
                            isExpandableSearch={true}
                            displayLeft={true}
                        />
                    </Header>
                    {this.state.loading && <Loader />}
                    {!this.state.loading && <div onClick={this.closeDrawer}>{sections}</div>}
                </Scrollable>
            </StyledDetailDrawer>
        );
    }
}

export default FloorplanDrawer;
