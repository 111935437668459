import styled, { css } from 'styled-components';
import * as palette from '../../../components/General/Variables';
import {
    Avatar,
    DialogContainer,
    DropdownMenu,
    FontIcon,
    IconSeparator,
    ListItem,
    TextField,
} from 'react-md';
import SelectField from 'react-md/lib/SelectFields';
import Toolbar from 'react-md/lib/Toolbars';
import SelectionControl from 'react-md/lib/SelectionControls';

export const DialogContainerStyled = styled(DialogContainer)`
    .md-background--card {
        background: #ebedf0 !important;
    }
    .md-dialog-content {
        padding: 0;
    }
`;

export const ProfileInfoText = styled.div`
    font-size: 14px;
    color: ${palette.COLOR_TEXT};
`;

export const StepsText = styled.div`
    font-family: 'Cabin', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    color: #d50000;
    width: 100%;
    text-transform: uppercase;
`;

export const SectionText = styled.div`
    font-family: 'Cabin', sans-serif;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    margin-top: 20px;
    margin-left: ${props => (props.noMarginLeft ? '0px' : '16px')};
    border-radius: 50%;
    font-size: 17px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    ${props =>
        props.noMarginTop &&
        `
        margin-top: -30px;
    `}
}
`;

export const SectionDetails = styled.div`
    color: rgba(0, 0, 0, 0.54);
    font-size: 15px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    margin-top: 8px;
    width: 100%;
`;

export const LinkIcon = styled(FontIcon)`
    width: 24px;
    height: 24px;
    color: rgba(0, 0, 0, 0.87) !important;
    margin-top: 2px;
`;

export const LinkLogo = styled.img`
    width: 24px;
    height: 24px;
`;

export const SocialLink = styled.div`
    width: 48px;
    height: 48px;
    margin: 12px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 1px #e2e4e7;
`;

export const SocialLinksContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

export const BackgroundImage = styled.img`
    width: 100%;
    border-radius: 6px;
`;

export const BackgroundInputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    right: 36px;
    cursor: pointer;
    transition: background-color 0.2s;

    input[type='file'] {
        display: none;
    }

    label {
        cursor: pointer;
    }
`;

export const BackgroundImageWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    max-height: 200px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 0 24px;
`;

export const ProfileImageWrapper = styled.div`
    width: 150px;
    height: 150px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .md-avatar-content {
        font-size: 50px;
    }
`;

export const AddImage = styled(FontIcon)`
    font-size: 30px;
    display: flex;
    color: ${props => props.primarycolor} !important;
    background-color: #fff;
    border-radius: 50%;
    border: 14px solid #fff;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

export const EurekaAvatar = styled(Avatar)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => (props.src ? 'transparent' : '#bfbfbf')} !important;
    border: 4px solid #fff !important;
    width: 150px !important;
    height: 150px !important;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    & .md-avatar {
        border: none;
    }
`;

export const ProfileInputWrapper = styled.div`
    width: 56px;
    height: 56px;
    font-size: 14px;
    position: absolute;
    top: 92px;
    right: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    input[type='file'] {
        display: none;
    }

    label {
        cursor: pointer;
    }
`;

const CommonFieldStyle = css`
    background-color: #efefef;
    border-radius: 6px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    padding: 13px;
    font-family: 'Roboto', sans-serif;
    margin-top: 16px;
    input {
        background: inherit;
        font-size: 15px;
        line-height: 1.07;
        color: #000000 !important;
    }
    hr {
        display: none;
    }
    label {
        margin-top: -16px;
        color: rgba(0, 0, 0, 0.38);
        line-height: 0.92;
        font-size: 13px;
        ${props => props.leftIcon && 'margin-left: 17px'};
    }
    .md-floating-label--floating {
        transform: translate3d(0, 30px, 0);
    }
    .md-text-field--floating-margin {
        margin-top: ${props => (props.afterSignUp ? '13px' : '26px')};
    }
`;

export const EditButton = styled.div`
    ${CommonFieldStyle}
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Cabin', sans-serif;
    cursor: pointer;
    i {
        margin-right: 8px;
    }
`;

export const StyledSelectField = styled(SelectField)`
    ${CommonFieldStyle}
    height: 56px;
    .md-select-field {
        margin-top: -10px;
    }
    .material-icons.md-icon {
        margin-top: -18px;
        margin-right: 0;
    }
    .md-text-field--floating-margin {
        margin-top: 26px;
    }
`;

export const LabelIcon = styled(FontIcon)`
    font-size: 12px !important;
    margin-left: 5px !important;
`;

export const StyledTextField = styled(TextField)`
    ${props =>
        props.maxRows && !props.fixedHeight
            ? `
        min-height: 56px;
    `
            : `
        height: 56px;
    `}
    ${props =>
        props.fixedHeight
            ? `
        min-height: ${props.fixedHeight}px;
    `
            : `
        height: 56px;
    `}
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    ${CommonFieldStyle}
    &:visited,
    &:active,
    &:visited,
    &:focus,
    &:focus-within {
        border: solid 2px rgba(0, 0, 0, 0.87);
        background: #fff;
    }

    .md-text-field-icon--positioned {
        margin-bottom: ${props => (props.afterSignUp ? '.5px' : '2px')} !important;
    }

    .md-text-field-message-container {
        font-size: 8px;
        line-height: 1.23;
        text-align: left;
        margin-top: 11px;
        color: #d50000;
    }

    .md-text-field:-webkit-autofill {
        box-shadow: unset;
    }
`;

export const FlexContainerWithTwoChildren = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div:first-of-type {
        flex: ${props => props.flex1};
        margin-right: 12px;
    }
    div:nth-of-type(2) {
        flex: ${props => props.flex2};
    }
`;

export const StyledForm = styled.form`
    margin-left: ${props => (props.horizontalMargin ? props.horizontalMargin : '20px')};
    margin-right: ${props => (props.horizontalMargin ? props.horizontalMargin : '20px')};
    min-height: ${props => (props.minHeight ? props.horizontalMargin : '450px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    color: ${palette.COLOR_TEXT} !important;

    .md-divider--text-field::after {
        background: ${props => props.maincolor};
    }
    .md-text--theme-primary {
        color: ${props => props.maincolor};
    }
    .md-text-field-message,
    .md-floating-label,
    input::placeholder,
    textarea::placeholder {
        font-size: 12px !important;
    }
`;

export const SaveButton = styled.div`
    width: 100%;
    background-color: ${props => (props.color ? props.color : '#054289')};
    color: white;
    text-align: center;
    margin-top: ${props => (props.marginTop ? props.marginTop : '55px')};
    margin-bottom: ${props => props.marginBottom};
    cursor: pointer;
    padding: 12px 0;
    border-radius: 6px;
    height: 40px;
    font-family: 'Cabin', sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
`;

export const StyledToolbar = styled(Toolbar)`
    .md-toolbar--action-right {
        margin: 0 !important;
    }
`;

// Settings
export const SettingsSectionText = styled(SectionText)`
    margin-top: 0;
`;

export const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
        margin-right: 19px;
    }
    &:first-of-type {
        justify-content: unset;
    }
`;

export const Divider = styled.div`
    height: 1px;
    background: #eee;
    width: 100%;
    margin: 16px 0 16px 56px;
`;

export const SectionContainer = styled.div`
    background: #fff;
    width: 100%;
    padding: 16px 24px;
    margin-top: 8px;
`;

export const Content = styled.div`
    width: 100%;
    padding-left: 56px;
`;

export const Switch = styled(SelectionControl)`
    .md-switch-track {
        width: 34px;
        height: 14px;
    }
    .md-switch-track--on {
        background: ${props => props.colorWithOpacity};
    }
    .md-switch-thumb {
        width: 20px;
        height: 20px;
    }
    .md-switch-thumb--on {
        background: ${props => props.color};
        transform: translate3d(20px, -4px, 0);
    }
`;

export const SavedIcon = styled(FontIcon)`
    width: 40px;
    height: 40px;
    font-size: 40px !important;
    color: ${props => props.primary} !important;
`;

export const SavedModalText = styled.div`
    color: rgba(0, 0, 0, 0.6);
`;

export const WideDivider = styled.div`
    width: 100%;
    height: 8px;
    background-color: #efefef;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    min-width: 40px;
    background-color: #efefef;
    border-radius: 50%;
`;

export const FormWrapper = styled.div`
    padding: 0 24px;
    width: 100%;
`;

export const iconStyle = {
    color: 'rgba(0, 0, 0, 0.87)',
    margin: 0,
};

export const StyledListItem = styled(ListItem)`
    img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin: 0 16px;
    }

    .md-tile-addon--avatar {
        height: 56px;
    }

    .md-list-tile,
    .md-tile-content--left-icon,
    .md-tile-content--left-avatar {
        padding: 0 16px 0 0 !important;
    }

    div:hover {
        background: #f5f5f5 !important;
    }

    .md-icon {
        font-size: 24px !important;
        margin: 0 16px;
    }

    .md-tile-text--primary {
        font-family: 'Cabin', sans-serif;
        font-size: ${props => (props.isTitlePrimaryText ? '24px' : '17px')};
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.18;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
    }

    .md-tile-text--secondary {
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.54);
        margin-top: 2px;
    }

    .md-list-tile--two-lines {
        ${props => props.isTitlePrimaryText && 'height: 80px'};
    }
`;

export const LeftIcon = styled(FontIcon)`
    margin-top: -8px !important;
`;

export const StyledIconSeparator = styled(IconSeparator)`
    padding: 0 !important;
    i {
        margin: 0 4px;
    }
`;

export const RightIcon = styled(FontIcon)`
    margin: 0 !important;
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
    border-radius: 20px;
    background-color: ${props => props.backgroundColor};
    color: ${props => (props.visible ? props.fontColor : props.contrastBlackOrWhite)};
    float: right;

    &:hover {
        background-color: ${props => (props.visible ? props.activeColor : props.hoverColor)};
    }

    .md-list {
        width: ${props => props.width || '400px'} !important;
        border-radius: 12px;
        padding: ${props => (props.disablePaddingBottom ? '8px 0 0 0' : '8px 0')};
        top: unset !important;
        max-height: unset;

        @media only screen and (max-width: ${palette.MAX_PHONE}) {
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            width: 100vw !important;
            height: 100vh !important;
            max-height: unset !important;
        }

        li {
            padding: 0 8px;
            div {
                border-radius: 8px;
                &:hover {
                    background-color: #ebedf0;
                    .md-avatar-content:hover {
                        background-color: #bfbfbf;
                        border: unset;
                        width: 56px;
                        height: 56px;
                    }
                }
            }
        }
        li:first-of-type {
            ${props =>
                props.isAuthenticated &&
                `
                 height: 72px;
            & > :first-child {
                height: 72px !important;
                border-radius: 8px;
                mergin-top: 2px;
            }
            `};
        }
    }

    .md-icon-separator {
        padding: 4px;
        height: 100%;
    }

    .md-icon {
        color: inherit !important;
    }

    .md-icon-text {
        padding: 0 !important;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
    }

    .md-icon-separator.md-icon-text {
        margin-left: 8px;
        font-weight: bold;
        ${props => props.light && 'color: inherit;'}
    }

    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        .md-icon-separator.md-icon-text {
            display: none;
        }
    }
`;
