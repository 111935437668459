import React from 'react';
import get from 'lodash/get';

import { ContainerLeft, MainScreen, MainScreenWrapper } from '../../../styles';
import Session from '../../common/Session';
import { useGlobalState } from '../../../../../utils/container';
import entities from '../../../constants/entities';
import useResize from '../../../hooks/useResize';
import ActiveVideo from '../common/MainVideo';
import PresentationOrScreen from '../../common/PresentationOrScreen';

const MainContent = props => {
    const stateCtx = useGlobalState();

    const { streams, virtualEventSession, virtualEventUser } = stateCtx;
    const { timeslot } = props;

    const screenWrapperRef = useResize();

    const mainMedia = get(virtualEventUser, 'mode') || 'video';
    const host = get(virtualEventSession, 'VirtualEventUsers', []).find(user => {
        return user.role === entities.virtualEventUserEntity.role.roundTableHost;
    });
    const hostHasShareStream =
        !host ||
        !!(streams || []).find(st => {
            return st.streamId === host.UserId + 10000000;
        });
    const shouldRenderVideo = mainMedia === 'video' && !hostHasShareStream;
    const prevPath = get(props.history, 'location.state.prevPath', null);

    return (
        <ContainerLeft size={8} tabletSize={8} phoneSize={12}>
            <MainScreenWrapper innerRef={screenWrapperRef}>
                <MainScreen size={12} tabletSize={12} video={shouldRenderVideo ? 1 : 0}>
                    {shouldRenderVideo ? (
                        <ActiveVideo timeslot={timeslot} />
                    ) : (
                        <PresentationOrScreen />
                    )}
                </MainScreen>
            </MainScreenWrapper>
            <Session
                sections={timeslot.sections || []}
                info={timeslot.info || {}}
                prefix={props.prefix}
                match={props.match}
                prevPath={prevPath}
            />
        </ContainerLeft>
    );
};

export default MainContent;
