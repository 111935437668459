import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    ButtonsContainer,
    Card,
    CardWrapper,
    CloseCardIcon,
    ErrorMessage,
    NameContainer,
    StyledTextField,
    Subtitle,
    Title,
} from '../style/signIn';
import { getString } from '../../../services/api/store';
import * as string from '../constants/strings';
import {
    SelectionControlStyled,
    SmallLink,
    SmallLinkText,
} from '../../../components/SignIn/styles';
import { Flex } from '../../../scenes/common/Flex';
import Button, { buttonTypes } from '../../common/Button';
import * as palette from '../../../components/General/Variables';

const Register = ({
    privacyPolicy,
    password,
    updatePassword,
    firstName,
    updateFirstName,
    register,
    lastName,
    updateLastName,
    theme,
    company,
    updateCompany,
    jobTitle,
    updateJobTitle,
    isValid,
    updatePrivacySettings,
    onKeyDown,
    eurekaOnly,
    attendingOnly,
    onClose,
    isModal,
}) => {
    const [continuePressed, setContinuePressed] = useState(false);
    const PRIVACY_MESSAGE =
        getString('createAccountPrivacyPolicyAgreedMessage') || string.PRIVACY_MESSAGE;
    const PRIVACY_BUTTON_LABEL =
        getString('createAccountPrivacyPolicyLinkTitle') || string.PRIVACY_BUTTON_LABEL;
    const TITLE_REGISTER = getString('createAccountScreenTitle') || string.CREATE_ACCOUNT_TITLE;
    const SUBTITLE_REGISTER =
        getString('profileCreateAccountSubtitle') || string.INPUT_REGISTER_MESSAGE;
    const CONTINUE_BUTTON_LABEL =
        getString('generalContinueButton') || string.CONTINUE_BUTTON_LABEL;
    const TEXT_INPUT_COMPANY = getString('profileCompany') || string.COMPANY_LABEL;
    const TEXT_INPUT_JOB_TITLE = getString('profileJobTitle') || string.JOB_TITLE_LABEL;
    const ERROR_FIELD_REQUIRED = getString('mandatoryFieldMessage') || string.FIELD_REQUIRED;
    const INPUT_LABEL_FIRST_NAME =
        getString('createAccountFirstNameTitle') || string.FIRST_NAME_LABEL;
    const INPUT_LABEL_LAST_NAME = getString('createAccountLastNameTitle') || string.LAST_NAME_LABEL;
    const TEXT_ERROR_REQUIRED_PASSWORD =
        getString('signInPasswordMissingMessage') || string.ERROR_MESSAGE_REQUIRED_PASSWORD;
    const TEXT_INPUT_PASSWORD = getString('passwordFieldHint') || string.PASSWORD_HINT;
    const createAccountPasswordRulesUppercase =
        getString('createAccountPasswordRulesUppercase') || string.TEXT_PASSWORD_UPPERCASE;
    const createAccountPasswordRulesLength =
        getString('createAccountPasswordRulesLength') || string.TEXT_PASSWORD_LENGTH;
    const createAccountPasswordRulesLowercase =
        getString('createAccountPasswordRulesLowercase') || string.TEXT_PASSWORD_LOWERCASE;
    const createAccountPasswordRulesNumber =
        getString('createAccountPasswordRulesNumber') || string.TEXT_PASSWORD_NUMBER;

    const { url } = privacyPolicy;

    let passwordErrorOnUserInput = '';
    if (!string.digitExists(password)) {
        passwordErrorOnUserInput += `${createAccountPasswordRulesNumber}<br/>`;
    }
    if (!string.lowercaseExists(password)) {
        passwordErrorOnUserInput += `${createAccountPasswordRulesLowercase}<br/>`;
    }
    if (!string.uppercaseExists(password)) {
        passwordErrorOnUserInput += `${createAccountPasswordRulesUppercase}<br/>`;
    }
    if (password.length < 8) {
        passwordErrorOnUserInput += createAccountPasswordRulesLength;
    }
    const labelsWithAsterisk = document.getElementsByTagName('label');

    useEffect(() => {
        if (labelsWithAsterisk?.length) {
            for (var i = 0; i < labelsWithAsterisk.length - 1; i++) {
                var text = labelsWithAsterisk[i].textContent;
                labelsWithAsterisk[i].innerHTML = text.replace(' *', '');
            }
        }
    }, [labelsWithAsterisk]);

    return (
        <CardWrapper>
            <Card
                customHeight={'596px'}
                customTop={'unset'}
                largeCardWithCustomHeight={true}
                overflow={'scroll'}
                isModal={isModal}
            >
                <Flex alignItems={'center'} justifyContent={'center'}>
                    <Title>{TITLE_REGISTER}</Title>
                    {!eurekaOnly && !attendingOnly && (
                        <CloseCardIcon onClick={onClose}>{palette.ICON_CLOSE}</CloseCardIcon>
                    )}
                </Flex>
                <Subtitle>{SUBTITLE_REGISTER}</Subtitle>
                <NameContainer>
                    <StyledTextField
                        value={firstName}
                        required
                        onChange={fnm => {
                            updateFirstName(fnm);
                        }}
                        id="register-first"
                        label={INPUT_LABEL_FIRST_NAME}
                        type="text"
                        errorText={ERROR_FIELD_REQUIRED}
                        error={continuePressed && firstName.length === 0}
                    />
                    <StyledTextField
                        value={lastName}
                        required
                        onChange={lnm => {
                            updateLastName(lnm);
                        }}
                        id="register-last"
                        label={INPUT_LABEL_LAST_NAME}
                        type="text"
                        errorText={ERROR_FIELD_REQUIRED}
                        error={continuePressed && lastName.length === 0}
                    />
                </NameContainer>
                <StyledTextField
                    value={password}
                    required
                    onChange={pass => {
                        updatePassword(pass, true);
                    }}
                    id="register-password"
                    label={TEXT_INPUT_PASSWORD}
                    type="password"
                    errorText={
                        password && passwordErrorOnUserInput ? '' : TEXT_ERROR_REQUIRED_PASSWORD
                    }
                    error={continuePressed && (!password || !!passwordErrorOnUserInput)}
                />
                {password && passwordErrorOnUserInput && (
                    <ErrorMessage>{ReactHtmlParser(passwordErrorOnUserInput)}</ErrorMessage>
                )}
                <StyledTextField
                    value={jobTitle}
                    required
                    error={continuePressed && !jobTitle}
                    onChange={job => {
                        updateJobTitle(job, true);
                    }}
                    id="register-job-title"
                    label={TEXT_INPUT_JOB_TITLE}
                    type="text"
                    errorText={ERROR_FIELD_REQUIRED}
                />
                <StyledTextField
                    value={company}
                    required
                    error={continuePressed && !company}
                    onChange={company => {
                        updateCompany(company, true);
                    }}
                    id="register-company"
                    label={TEXT_INPUT_COMPANY}
                    type="text"
                    errorText={ERROR_FIELD_REQUIRED}
                />
                <SelectionControlStyled
                    id="checkbox-privacy"
                    name="privacy"
                    onChange={prv => {
                        updatePrivacySettings(prv);
                    }}
                    noMarginTop={true}
                    label={
                        <SmallLinkText>
                            {`${PRIVACY_MESSAGE} `}
                            <SmallLink
                                linkcolor={theme.primary}
                                href={url ? url : `https://www.iubenda.com/privacy-policy/51127541`}
                                target="_blank"
                            >
                                {PRIVACY_BUTTON_LABEL}
                            </SmallLink>
                        </SmallLinkText>
                    }
                    type="checkbox"
                    value="privacyAgreed"
                    primarycolor={theme.primary}
                    onKeyDown={e => {
                        if (isValid) {
                            onKeyDown(e, register);
                        }
                    }}
                />
                <ButtonsContainer primarycolor={theme.primary}>
                    <Button
                        type={buttonTypes.GREEN_LONG}
                        background={theme.primary}
                        text={CONTINUE_BUTTON_LABEL}
                        onClick={() => {
                            setContinuePressed(true);
                            !passwordErrorOnUserInput && register();
                        }}
                        data-qa="button-register-continue"
                    />
                </ButtonsContainer>
            </Card>
        </CardWrapper>
    );
};

export default Register;
