const PUBLIC_URL = process.env.PUBLIC_URL || '/';
const NODE_ENV = process.env.NODE_ENV || 'development';
const PACKAGE_RELEASE_VERSION = 'trixie-webapp@' + process.env.npm_package_version;
const USE_LOCAL = process.env.REACT_APP_USE_LOCAL === 'true';
const USE_CONFIGURATOR = process.env.REACT_APP_USE_LOCAL === 'true';
const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION;
const APP_VERSION = process.env.REACT_APP_VERSION;

const EUREKA_URL = process.env.REACT_APP_EUREKA_URL || 'https://eureka.dev.indrina.com';
const EUREKA_TALK_HOST = process.env.REACT_APP_EUREKA_TALK_HOST || 'devtalk.dev.indrina.com';
const APPSYNC_CONFIG = {
    aws_project_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
};

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;

export {
    NODE_ENV,
    PUBLIC_URL,
    EUREKA_URL,
    EUREKA_TALK_HOST,
    SENTRY_DSN,
    SENTRY_ENVIRONMENT,
    PACKAGE_RELEASE_VERSION,
    USE_LOCAL,
    USE_CONFIGURATOR,
    APPSYNC_CONFIG,
    BUILD_VERSION,
    APP_VERSION,
};
