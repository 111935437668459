import React from 'react';
import styled from 'styled-components';
import * as palette from '../General/Variables.js';
import AuthUserContext from '../Session/AuthUserContext';
import { getString } from '../../services/api/store.js';
import { InteractivityContext } from '../Interactivity';

const ProfileContainer = styled.div`
    margin-top: -4px !important;
    position: relative;
    z-index: 1;
    width: 100%;
    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        margin-top: -8px !important;
    }
`;

const ProfileBackground = styled.div`
    background-color: ${palette.COLOR_GREY};
    background-image: url('/images/banner-placeholder.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.3;
    width: 100%;
    height: 100%;
`;

const EurekaIntroText = styled.div`
    padding-top: 16px;
    padding-right: 22px;
    padding-bottom: 16px;
    padding-left: 26px;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        padding-top: 26px;
    }
`;

const INTRO_TEXT_HEADER =
    'Sign in with your Eureka account to save your personal programme, sync it with other devices and make notes.';

class EurekaHeader extends React.Component {
    render() {
        let TEXT_EUREKA_INTRO_HEADER = getString('eurekaIntroHeader') || INTRO_TEXT_HEADER;

        return (
            <AuthUserContext.Consumer>
                {authUser => {
                    return (
                        <ProfileContainer>
                            {!authUser && <ProfileBackground />}
                            {/*{!authUser && (*/}
                            {/*    <InteractivityContext.Consumer>*/}
                            {/*        {({ showSignIn }) => (*/}
                            {/*            <EurekaIntroText onClick={showSignIn}>*/}
                            {/*                {TEXT_EUREKA_INTRO_HEADER}*/}
                            {/*            </EurekaIntroText>*/}
                            {/*        )}*/}
                            {/*    </InteractivityContext.Consumer>*/}
                            {/*)}*/}
                        </ProfileContainer>
                    );
                }}
            </AuthUserContext.Consumer>
        );
    }
}

export default EurekaHeader;
