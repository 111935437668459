import styled from 'styled-components';
import * as palette from '../General/Variables';

export const StyledInfo = styled.p`
    margin-top: ${props => props.margin || '0px'};
    font-size: 16px;
    text-align: center;
    color: ${palette.COLOR_TEXT};

    a {
        color: ${props => props.linkcolor};
    }
`;

export const StyledTitle = styled(StyledInfo)`
    font-size: 24px;
    font-weight: 500;
    margin: 20px 0;
`;

export const BlueButton = styled.div`
    width: 240px;
    background-color: #054289;
    color: white;
    text-align: center;
    padding: 9px;
    border-radius: 18px;
    margin-top: 50px;
    cursor: pointer;
`;

export const NoAccessImage = styled.div`
    top: 0px;
    margin-top: -30px;
    background-image: url('/images/no_access.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    width: 300px;
    max-width: 300px;
    height: 245px;
`;
