import React, { useState, useContext, useEffect } from 'react';
import { DialogContainer, SelectField } from 'react-md';
import styled from 'styled-components';
import Button, { buttonTypes } from '../../common/Button';

import { TimezoneContext } from '../context';
import ThemeContext from '../../../components/Theme/ThemeContext';
import * as palette from '../../../components/General/Variables';
import { getString } from '../../../services/api/store';
import { ModalContent } from '../../../components/Dialog/styles';
import BasicDialog from '../../../components/Dialog/BasicDialog';

const EditorWrapper = styled.div`
    .md-list-tile {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
`;

const TimezoneHandlers = () => {
    const [dialogTimezone, setDialogTimezone] = useState('local');

    const {
        introDialogVisible,
        setIntroDialogVisible,
        selectDialogVisible,
        setSelectDialogVisible,
        selectedTimezone,
        updateTimezoneSettings,
        getLocalTimezoneName,
        getEventTimezoneName,
    } = useContext(TimezoneContext);

    useEffect(() => {
        setDialogTimezone(selectedTimezone);
    }, [selectDialogVisible]);

    const { theme } = useContext(ThemeContext);

    const changeTimezoneToEvent = async () => {
        saveTimezone('event');
        setIntroDialogVisible(false);
    };

    const onCloseSelectDialog = () => {
        setSelectDialogVisible(false);
    };

    const updateTimezone = zone => {
        setDialogTimezone(zone);
    };

    const saveTimezone = async value => {
        let toSet = value;
        if (toSet === undefined || toSet === null) {
            toSet = dialogTimezone;
        }

        await updateTimezoneSettings(toSet);
        setSelectDialogVisible(false);
    };
    const TIMEZONE_INTRO_DIALOG_TEXT =
        getString('timezoneIntroDialogSubtitle') ||
        'Choose whether you want to display the times within this event in your local timezone, or the event timezone. You can always change this later in the settings.';
    const TIMEZONE_INTRO_DIALOG_TITLE = getString('timezoneIntroDialogTitle') || 'Choose timezone';
    const TIMEZONE_SELECT_DIALOG_TITLE =
        getString('settingsTimezoneActionTitle') || 'Select timezone';
    const TIMEZONE_LOCAL_LABEL = getString('settingsTimezoneLocal') || 'Local';
    const TIMEZONE_EVENT_LABEL = getString('settingsTimezoneEvent') || 'Event';

    const options = [
        {
            label: `${TIMEZONE_LOCAL_LABEL} (${getLocalTimezoneName()})`,
            value: 'local',
        },
        {
            label: `${TIMEZONE_EVENT_LABEL} (${getEventTimezoneName()})`,
            value: 'event',
        },
    ];

    const selectDialogActions = [
        <Button
            onClick={onCloseSelectDialog}
            type={buttonTypes.GREY}
            text={getString('cancel') || 'cancel'}
            style={{ marginRight: '8px' }}
        />,
        <Button
            onClick={() => saveTimezone(dialogTimezone)}
            type={buttonTypes.GREEN}
            background={theme.primary}
            text={getString('saveButton') || 'Save'}
        />,
    ];

    return (
        <>
            <BasicDialog
                title={TIMEZONE_INTRO_DIALOG_TITLE}
                iconName="airplanemode_active"
                visible={introDialogVisible}
                onClose={() => setIntroDialogVisible(false)}
                options={[
                    {
                        title: getString('timezoneUseLocal') || 'Use local timezone',
                        variant: 'text',
                        type: 'save',
                        onClick: () => setIntroDialogVisible(false),
                    },
                    {
                        title: getString('timezoneUseEvent') || 'Use event timezone',
                        variant: 'text',
                        type: 'cancel',
                        onClick: changeTimezoneToEvent,
                    },
                ]}
            >
                <ModalContent padding="0 24px">{TIMEZONE_INTRO_DIALOG_TEXT}</ModalContent>
            </BasicDialog>
            <DialogContainer
                id="password-dialog"
                title={TIMEZONE_SELECT_DIALOG_TITLE}
                visible={selectDialogVisible}
                width={palette.DIALOG_WIDTH}
                aria-describedby="timezone edit dialog"
                modal
                initialFocus={''}
                focusOnMount={false}
                containFocus={false}
                footerStyle={{ padding: '16px' }}
                actions={selectDialogActions}
                stackedActions={false}
                contentClassName="md-grid"
                contentStyle={{}}
            >
                <EditorWrapper>
                    <SelectField
                        style={{ width: palette.DIALOG_WIDTH - 60 }}
                        label="Timezone"
                        id="select-timezone"
                        fullWidth
                        sameWidth
                        className="md-cell md-cell--12"
                        menuItems={options}
                        simplifiedMenu={true}
                        value={dialogTimezone}
                        onChange={value => {
                            updateTimezone(value);
                        }}
                    />
                </EditorWrapper>
            </DialogContainer>
        </>
    );
};

export default TimezoneHandlers;
