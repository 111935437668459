import React, { useContext } from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';
import moment from 'moment';

import { default as Store } from '../../services/api/store';
import { getDateWithLocale, getFormatDate, getFormatTime } from '../../services/api/data';
import { TimezoneContext } from '../../scenes/Timezone/context';
import { Flex } from '../../scenes/common/Flex';

const HeaderTime = styled.div`
    padding: 16px 16px 24px 16px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: start;
`;

const DateTimeContainer = styled(Flex)`
    color: rgba(28, 28, 30, 1);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    i {
        color: rgba(28, 28, 30, 1) !important;
        font-size: 16px; !important;
        margin-right: 10px;
    }
`;

function TimeslotDetailHeader(props) {
    const { isAppointment } = props;
    const timezone = Store.argument.offsetTimezone;
    const { timeslotToTimezone } = useContext(TimezoneContext);
    let timeslotWithTimezone = props;
    if (isAppointment) {
        timeslotWithTimezone = timeslotToTimezone(props);
    }
    let startTime = moment.utc(timeslotWithTimezone.start);
    if (!startTime.isValid()) {
        startTime = moment.unix(timeslotWithTimezone.start);
    }
    startTime = startTime.utcOffset(timezone, true);
    let endTime = moment.utc(timeslotWithTimezone.end);
    if (!endTime.isValid()) {
        endTime = moment.unix(timeslotWithTimezone.end);
    }
    endTime = endTime.utcOffset(timezone, true);
    const timeFormat = getFormatTime();
    const startWithFormat = moment(startTime).format(timeFormat);
    const endWithFormat = moment(endTime).format(timeFormat);
    const dateSettings = getFormatDate();
    const day = getDateWithLocale(timeslotWithTimezone.start);

    return (
        <HeaderTime>
            <Flex justifyContent={'space-between'} width={'100%'}>
                <DateTimeContainer>
                    <FontIcon>event</FontIcon>
                    <div>{day.format(dateSettings)}</div>
                </DateTimeContainer>
                <DateTimeContainer>
                    <FontIcon>access_time</FontIcon>
                    <div>{`${startWithFormat} - ${endWithFormat}`}</div>
                </DateTimeContainer>
            </Flex>
        </HeaderTime>
    );
}

export default TimeslotDetailHeader;
