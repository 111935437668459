import React, { useEffect, useState } from 'react';
import async from 'async';
import moment from 'moment';
import 'moment/min/locales.min';
import {
    BackgroundTimetable,
    ProgramTypeButtonsContainer,
    SearchContainer,
    TabElement,
    Timezone,
    TimezoneInRuler,
} from './style/style';
import { withRouter } from 'react-router-dom';

import Filter from '../../components/Filter';
import ProgramTypeButtons from '../../components/General/ProgramTypeButtons';
import Search from '../../components/General/Search';
import * as palette from '../../components/General/Variables';
import ThemeContext from '../../components/Theme/ThemeContext';
import { WithTimezone } from '../Timezone/context';
import {
    filterProgram,
    getAllProgramFavorites,
    getFilterTypes,
    getGroupingOptions,
    getMarkedAsFavorites,
    makeItem,
} from '../../services/api/data';
import { default as Store, getString } from '../../services/api/store.js';
import ListView from './components/ListView';
import Timetable from './components/timetable';
import { getDays, getSessionsFromDay } from './services/programme';
import { getItem } from '../../services/api/graphQlRepository';
import AccessRestrictions from '../../AccessRestrictions';
import ProgrammeTabsAndToolbar from './components/ProgrammeTabsAndToolbar';
import { programmeViewTypes } from './constants/index';
import VerticalTimetable from './components/verticalTimetable';

const Programme = props => {
    const [tabsData, setTabsData] = useState({
        elements: [],
        timerange: {},
    });

    const [activeTab, setActiveTab] = useState(0);
    const [days, setDays] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState([]);
    const [skipTimeframes, setSkipTimeframes] = useState(false);
    const [page, setPage] = useState({});
    const [pageId, setPageId] = useState(props.pageId);
    const [timezoneSetting, setTimezoneSetting] = useState('local');
    const [filterTypes, setFilterTypes] = useState([]);
    const [grouping, setGrouping] = useState(null);
    const [openViewDropDown, setOpenViewDropDown] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [viewType, setViewType] = useState(programmeViewTypes.HORIZONTAL_VIEW);
    const { timeslotToTimezone, selectedTimezone } = props.timezone;

    useEffect(() => {
        getProgramInfo();
        if (props.disableScroll) {
            props.disableScroll(true);
        }

        return () => {
            if (props.disableScroll) {
                props.disableScroll(false);
            }
            if (props.handleSidepanel) {
                props.handleSidepanel(false);
            }
        };
    }, []);

    useEffect(() => {
        if (props.handleSidepanel) {
            props.handleSidepanel(
                props.match.url !== props.history.location.pathname || showFilter,
            );
        }
        if (props.match.url !== props.history.location.pathname) {
            setShowFilter(false);
        }
    }, [props.history.location.pathname]);

    useEffect(() => {
        if (pageId !== props.pageId || timezoneSetting !== props.timezoneSetting) {
            const force = true;
            getProgramInfo(force);
            setTimezoneSetting(props.timezone.selectedTimezone);
            setPageId(props.pageId);
        }

        const { pathname } = props.location;
        const splittedPathname = pathname.split('/');
        const type = splittedPathname[splittedPathname.length - 2];
        const timeslotId = splittedPathname[splittedPathname.length - 1];

        if (type === 'timeslot' && timeslotId !== timeslotId) {
            makeItem(timeslotId, 'timeslot', (err, item) => {
                if (err) {
                    console.log(err);
                    return;
                }
                item = timeslotToTimezone(item);
                const dayIndex = getDayIndex(days, item.start);
                const currentActiveTab = dayIndex === -1 ? 0 : dayIndex;

                if (currentActiveTab !== activeTab) {
                    onTabClick(currentActiveTab);
                    setActiveTab(currentActiveTab);
                }
            });
        }
    }, [props.pageId, props.timezone.selectedTimezone, props.timezone.introDialogVisible]);

    const getDayIndex = (days, inputDate) => {
        let date;
        if (!inputDate) {
            date = moment();
        } else {
            date = moment(inputDate);
        }

        const { selectedTimezone } = props.timezone;
        if (selectedTimezone === 'event') {
            const timezone = Store.argument.offsetTimezone;
            date = date.utcOffset(timezone);
        }

        return days.findIndex(day => moment.utc(day.start).isSame(date, 'day'));
    };

    const getProgramInfo = force => {
        let empty = {
            elements: [],
            timerange: {},
        };
        let skipTimeframesVar = false;

        async.waterfall(
            [
                function (callback) {
                    if (!force && page.id === pageId) {
                        return;
                    }

                    getItem('pages', pageId, (err, page) => {
                        if (err || !page) {
                            return callback();
                        }

                        if (page && page.params && page.params.webLayout) {
                            if (page.params.webLayout === 'horizontalTimetable') {
                                setViewType(programmeViewTypes.HORIZONTAL_VIEW);
                            } else if (page.params.webLayout === 'verticalTimetable') {
                                setViewType(programmeViewTypes.VERTICAL_VIEW);
                            } else if (page.params.webLayout === 'list') {
                                setViewType(programmeViewTypes.LIST_VIEW);
                            }
                        }
                        setPage(page);
                        callback();
                    });
                },
                function (callback) {
                    getDays(pageId, timeslotToTimezone, (err, days, skipTimeframes) => {
                        if (err) {
                            callback(err);
                        } else {
                            setSkipTimeframes(skipTimeframes);
                            skipTimeframesVar = skipTimeframes;
                            let data = days.map(day => {
                                return empty;
                            });

                            callback(null, data, days);
                        }
                    });
                },
                function (program, days, callback) {
                    getFilterTypes(pageId, (err, filterTypes) => {
                        if (err) {
                            callback(err);
                        } else {
                            callback(null, program, days, filterTypes);
                        }
                    });
                },
                function (program, days, filterTypes, callback) {
                    getGroupingOptions(pageId, (err, grouping) => {
                        if (err) {
                            callback(err);
                        } else {
                            callback(null, program, days, filterTypes, grouping);
                        }
                    });
                },
                function (program, days, filterTypes, grouping, callback) {
                    setTabsData(program);
                    setDays(days);
                    setLoading(true);
                    setFilterTypes(filterTypes);
                    setGrouping(grouping);
                    const dayIndex = getDayIndex(days);
                    const activeTab = dayIndex === -1 ? 0 : dayIndex;
                    const a = days.map((day, indx) =>
                        onTabClick(indx, days, grouping, true, skipTimeframesVar),
                    );
                    onTabClick(activeTab, days, grouping, false, skipTimeframesVar);
                    callback(null, days);
                },
                function (days, callback) {
                    const dayIndex = getDayIndex(days);
                    const activeTab = dayIndex === -1 ? 0 : dayIndex;
                    setActiveTab(activeTab);
                    callback(null, activeTab);
                },
            ],
            function (err, index = 0) {
                if (err) {
                    console.log(err);
                }
                // onTabClick(index);
            },
        );
    };

    const displayFilter = show => {
        setShowFilter(show);
        if (props.handleSidepanel) {
            props.handleSidepanel(show);
        }
        if (show) {
            props.history.push(props.match.url);
        }
    };

    const onTabClick = (
        indx,
        initialDays,
        initialGrouping,
        excludeActiveTab,
        skipTimeframesInitial,
    ) => {
        setLoading(true);
        const day = (initialDays && initialDays[indx]) || days[indx];
        const allDays = initialDays || days;
        if (!day) {
            setLoading(false);
            return;
        }
        getSessionsFromDay(
            day,
            skipTimeframesInitial || skipTimeframes,
            initialGrouping || grouping,
            timeslotToTimezone,
            (err, sessions, timerange) => {
                if (err) {
                    console.log(err);
                } else {
                    let data = tabsData;
                    data[indx] = {
                        elements: applyFilters(filters, sessions),
                        fullElements: sessions,
                        timerange: timerange,
                    };
                    if (
                        data &&
                        data[indx] &&
                        filters &&
                        filters.myProgram &&
                        filters.myProgram === 'my_program'
                    ) {
                        filterCallback(filters, indx, data);
                        return;
                    }
                    if (Object.keys(data).length === allDays?.length + 2) {
                        setTabsData(data);
                    }
                    if (!excludeActiveTab) {
                        setActiveTab(indx);
                    }
                    setLoading(false);
                }
            },
        );
    };

    const applyFilters = (filter, data, favorites, markedAsFavorites) => {
        return filterProgram(filter, data, favorites, markedAsFavorites);
    };

    const changeViewType = type => {
        onTabClick(activeTab);
        setViewType(type);
    };

    const filterCallback = (filter, forcedIndex, forcedData) => {
        const indx = forcedIndex || activeTab;
        if (
            (tabsData[indx] && tabsData[indx].elements && tabsData[indx].elements.length) ||
            forcedData
        ) {
            const data = forcedData || tabsData;

            if (filter && filter.myProgram && filter.myProgram === 'my_program') {
                getMarkedAsFavorites(data[indx].fullElements, (err, marked) => {
                    getAllProgramFavorites((err, favorites) => {
                        const data = tabsData;
                        let activeIndex = activeTab;
                        const newData = Object.keys(tabsData).map((key, index) => {
                            if (data[index] && data[index].elements) {
                                data[index].elements = applyFilters(
                                    filter,
                                    data[index].fullElements,
                                    favorites,
                                    marked,
                                );
                                const displayTab = data[index].elements?.find(
                                    item => item.items.length,
                                );
                                if (displayTab) {
                                    activeIndex = index;
                                }
                            }
                            return data[index];
                        });
                        setTabsData(newData);
                        setActiveTab(activeIndex);
                        setLoading(false);
                        setFilters(filter);
                    });
                });
            } else {
                const data = tabsData;
                let activeIndex = activeTab;
                const newData = Object.keys(tabsData).map((key, index) => {
                    if (data[index] && data[index].elements) {
                        data[index].elements = applyFilters(filter, data[index].fullElements);
                        const displayTab = data[index].elements?.find(item => item.items.length);
                        if (displayTab) {
                            activeIndex = index;
                        }
                    }
                    return data[index];
                });
                setTabsData(newData);
                setActiveTab(activeIndex);
                setLoading(false);
                setFilters(filter);
            }
        }
    };

    const renderProgramme = theme => {
        let dateSettings = getString('datetime');
        let datePattern =
            dateSettings && dateSettings.dayMonthDateFormat
                ? dateSettings.dayMonthDateFormat
                : 'D MMMM';
        let locale = dateSettings && dateSettings.locale ? dateSettings.locale : 'en';

        const data = tabsData[activeTab];
        let activeTabIndex = activeTab;
        const tabs = days.map((elem, index) => {
            let day = moment.utc(elem.start);
            day.locale(locale);

            const label = elem.name + ' ' + day.format(datePattern);

            const { elements } = tabsData[index];
            const displayTab = elements?.find(item => item.items.length);
            if (!displayTab) {
                return <p />;
            }

            return (
                <TabElement
                    key={`${elem.name}-${elem.id}`}
                    label={label}
                    primary={theme.primary}
                    active={activeTabIndex === index}
                />
            );
        });

        const renderTimezoneInfo = () => {
            const { getTimezoneLabel, showTimezoneSelectDialog } = props.timezone;
            if (viewType === programmeViewTypes.LIST_VIEW)
                return <Timezone onClick={showTimezoneSelectDialog}>{getTimezoneLabel()}</Timezone>;
            return (
                <TimezoneInRuler onClick={showTimezoneSelectDialog} viewType={viewType}>
                    {getTimezoneLabel()}
                </TimezoneInRuler>
            );
        };

        const renderTimetable = handleClickOutside => (
            <BackgroundTimetable>
                {showFilter && (
                    <Filter
                        action={filterCallback}
                        selectedFilters={filters}
                        filterTypes={filterTypes}
                        closeFilter={() => displayFilter(false)}
                    />
                )}
                <Timetable
                    items={data.elements}
                    rangeInterval={data.timerange}
                    matchUrl={props.match.url}
                    filters={filters}
                    selectedTimezone={selectedTimezone}
                    renderTimezoneInfo={renderTimezoneInfo}
                    handleClickOutside={handleClickOutside}
                    activeTab={activeTab}
                />
            </BackgroundTimetable>
        );

        const renderVerticalTimetable = handleClickOutside => (
            <BackgroundTimetable>
                {showFilter && (
                    <Filter
                        action={filterCallback}
                        selectedFilters={filters}
                        filterTypes={filterTypes}
                        closeFilter={() => displayFilter(false)}
                    />
                )}
                <VerticalTimetable
                    items={data.elements}
                    rangeInterval={data.timerange}
                    matchUrl={props.match.url}
                    filters={filters}
                    selectedTimezone={selectedTimezone}
                    renderTimezoneInfo={renderTimezoneInfo}
                    activeTab={activeTab}
                    handleClickOutside={handleClickOutside}
                />
            </BackgroundTimetable>
        );

        const searchCallback = text => {
            filterCallback({
                ...filters,
                text,
            });
        };

        const favoriteFilter = myProgram => {
            filterCallback({
                ...filters,
                myProgram,
            });
        };

        const getNumberOfAppliedFilters = () => {
            if (filters && filters.filters) {
                const filter = filters.filters;
                let count = 0;
                Object.keys(filter).forEach(key => (count += filter[key].length));
                return count;
            }
        };

        const renderListView = () => {
            const isMobile = palette.MIN_TABLET_INT && window.innerWidth < palette.MIN_TABLET_INT;

            let filterContainers = [
                <ProgramTypeButtonsContainer key="0" size={2}>
                    <ProgramTypeButtons
                        alignment={isMobile ? 'left' : 'right'}
                        action={myProgram => {
                            favoriteFilter(myProgram);
                        }}
                        myProgram={filters.myProgram || 'all'}
                        useFavoritesTitle={false}
                    />
                </ProgramTypeButtonsContainer>,
                <SearchContainer key="1" size={10}>
                    <Search
                        action={text => {
                            searchCallback(text);
                        }}
                        live={true}
                        skey={pageId}
                    />
                </SearchContainer>,
            ];

            if (!isMobile) {
                filterContainers.reverse();
            }

            return (
                <BackgroundTimetable>
                    {showFilter && (
                        <Filter
                            action={filterCallback}
                            selectedFilters={filters}
                            filterTypes={filterTypes}
                            closeFilter={() => displayFilter(false)}
                        />
                    )}
                    <ListView
                        items={data.elements}
                        range={data.timerange}
                        matchUrl={props.match.url}
                        filters={filters}
                        groupingOption={grouping.groupingOption}
                    />
                </BackgroundTimetable>
            );
        };

        return (
            <ProgrammeTabsAndToolbar
                tabs={tabs}
                activeTab={activeTab}
                loading={loading}
                openViewDropDown={openViewDropDown}
                skipTimeframes={skipTimeframes}
                renderListView={renderListView}
                renderTimetable={renderTimetable}
                renderTimezoneInfo={renderTimezoneInfo}
                renderVerticalTimetable={renderVerticalTimetable}
                onTabClick={onTabClick}
                action={filterCallback}
                selectedFilters={filters}
                viewType={viewType}
                changeViewType={changeViewType}
                onVisibilityChange={() => setOpenViewDropDown(!openViewDropDown)}
                setShowFilter={() => displayFilter(!showFilter)}
                isSidepanelOpened={props.isSidepanelOpened || showFilter}
                numberOfAppliedFilters={getNumberOfAppliedFilters()}
            />
        );
    };

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <React.Fragment>
                    <AccessRestrictions
                        pageId={pageId}
                        accessRestrictions={page.accessRestrictions}
                        isPasswordProtected={page.isPasswordProtected}
                        password={page.password}
                        result={() => renderProgramme(theme)}
                    />
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

export default withRouter(WithTimezone(Programme));
