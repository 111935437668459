import { PadddingLeftRight16px } from '../../common/Padding';
import React from 'react';
import { MarginTop16px } from '../../common/Margin';
import Button, { buttonTypes } from '../../common/Button';
import { getString } from '../../../services/api/store';
import * as palette from '../../../components/General/Variables';
import { Icon, SectionTitle } from '../style/index';
import { AddressContactItem, ContactItem } from '../style';
import ReactHtmlParser from 'react-html-parser';
import { SectionDescription } from '../../DetailPage/InfoBar';

const ProfileInformationContact = ({
    theme,
    profileData,
    showMore,
    readAll,
    setReadAll,
    text,
    renderRepresentativeOfList,
}) => {
    const TEXT_REPRESENTATIVE_ROLE = getString('representativeRole') || 'Representative of';
    const hasContact = profileData && profileData.Contact;
    const hasContactInfo =
        hasContact &&
        (profileData.Contact.phone ||
            profileData.Contact.email ||
            profileData.Contact.streetName ||
            profileData.Contact.streetNumber ||
            profileData.Contact.city ||
            profileData.Contact.zip);

    const hasAddress =
        hasContact &&
        (profileData.Contact.streetName ||
            profileData.Contact.streetNumber ||
            profileData.Contact.city ||
            profileData.Contact.zip);

    return (
        <PadddingLeftRight16px>
            {profileData &&
                profileData.representativeOf &&
                profileData.representativeOf.length > 0 && (
                    <>
                        <SectionTitle>{TEXT_REPRESENTATIVE_ROLE}</SectionTitle>
                        {renderRepresentativeOfList()}
                    </>
                )}
            {profileData && profileData.headline && (
                <>
                    <SectionTitle>Biography</SectionTitle>
                    <SectionDescription primary={theme.primary} contrast={theme.contrast}>
                        {ReactHtmlParser(text)}
                    </SectionDescription>
                    <MarginTop16px />
                    {showMore && (
                        <Button
                            type={buttonTypes.GREY_LONG}
                            background={theme.primary}
                            text={
                                !readAll
                                    ? getString('announcementsReadMore') || 'Read More'
                                    : getString('announcementsReadLess') || 'Read less'
                            }
                            onClick={() => setReadAll(!readAll)}
                        />
                    )}
                </>
            )}
            {hasContactInfo && (
                <>
                    {hasAddress && (
                        <AddressContactItem bold={hasContact && profileData.Contact.streetName}>
                            <ContactItem noMargin={true}>
                                <Icon>{palette.ICON_LOCATION_ON}</Icon>
                                <div>
                                    <span>
                                        {hasContact && profileData.Contact.streetName}
                                        {hasContact && profileData.Contact.streetNumber && (
                                            <>&nbsp; {profileData.Contact.streetNumber}</>
                                        )}
                                    </span>
                                    <br />
                                    <span>{hasContact && profileData.Contact.city}</span>
                                    {hasContact && profileData.Contact.city && <>,&nbsp;</>}
                                    <span>{hasContact && profileData.Contact.zip}</span>
                                </div>
                            </ContactItem>
                        </AddressContactItem>
                    )}
                </>
            )}
        </PadddingLeftRight16px>
    );
};

export default ProfileInformationContact;
