import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontIcon } from 'react-md';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useTheme } from '../../components/Theme/ThemeContext';

export const TabsHeader = styled(Tabs)`
    div {
        flex: 1;
        height: ${props => (props.topNavigationTabs ? '56px' : '48px')};

        button {
            min-width: unset !important;
            height: ${props => (props.topNavigationTabs ? '48px' : '40px')};
            min-height: ${props => (props.topNavigationTabs ? '48px' : '40px')};
            width: ${props => (props.isSmallTab ? '80%' : '100%')} !important;
            margin: 4px 0;

            span:first-of-type {
                flex-direction: row !important;

                & > div {
                    width: fit-content;
                    flex: unset;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
`;

export const TabsContainer = styled(Paper)`
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: unset !important;
    display: flex;
    flex-direction: column;

    @keyframes expandRight {
        0% {
            transform: translateX(180px);
            opacity: 0;
        }
        100% {
            transform: translateX(0px);
            opacity: 1;
        }
    }
    @keyframes expandLeft {
        0% {
            transform: translateX(-280px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    & > div:nth-of-type(2) {
        height: ${props => props.containerHeight};
        overflow: scroll;
        animation: ${props =>
            (props.animationDirection === 'left' && `expandRight .3s ease-in forwards`) ||
            (props.animationDirection === 'right' && 'expandLeft .3s ease-out forwards')};
        transition: all cubic-bezier(0.1, 0.7, 1, 0.1) 300ms;
    }
`;

export const TabElement = styled(Tab)`
    font-family: 'Roboto', sans-serif;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: ${props => (props.topNavigationTabs ? 'rgba(0, 0, 0, 0.6)' : '#000000')} !important;
    cursor: pointer;
    transition: opacity 0.1s;
    opacity: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-transform: none !important;
    width: ${props => (props.isSmallTab ? '80%' : '100%')} !important;
    border-radius: 8px !important;

    ${props =>
        props.isSmallTab &&
        `
    &:first-of-type {
      margin-left: 16px !important;
    }`}
    &:not(&.active):hover {
        background: ${props => props.color + '10' || '#00a933' + '10'};
        color: ${props => props.color || '#00a933'} !important;
        width: ${props => (props.isSmallTab ? '80%' : '100%')} !important;
    }

    &:active {
        color: ${props => props.color || '#00a933'} !important;
        background: ${props => props.color + '30' || '#00a933' + '30'} !important;
    }

    &.active {
        color: ${props => props.color || '#00a933'} !important;
        font-weight: bold;
    }
`;

const StyledChip = styled.span`
    text-align: center;
    font-family: 'Cabin', sans-serif;
    font-size: 10px;
    color: #fff;
    background-color: ${props => props.primary};
    padding: 2px;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    position: absolute;
    bottom: 24px;
    left: 39%;
    line-height: 12px;
`;

export const TabStyledFontIcon = styled(FontIcon)`
    margin: ${props => (props.displayIconWithoutLabel ? '0 auto' : '0 12px 0 0')} !important;
    color: inherit !important;
    ${props => props.topNavigationTabs && 'font-size: 24px !important'};
`;

const ContentWrapper = styled.div`
    flex: 1;
    position: relative;

    & > :first-child {
        top: 0;
        height: 100%;
    }
`;

const TabsComponent = ({
    data,
    isSmallTab,
    containerHeight,
    onMouseEnter,
    onMouseLeave,
    currentTab = 0,
    checkContentAllTabs,
    topNavigationTabs,
}) => {
    const [activeTab, setActiveTab] = useState(currentTab);
    const [previousActiveTab, setPreviousActiveTab] = useState(0);
    const { theme } = useTheme();
    const onTabClick = value => {
        setPreviousActiveTab(activeTab);
        setActiveTab(value);
    };

    useEffect(() => {
        if (currentTab) {
            setActiveTab(currentTab);
        }
        if (data.length === 1) {
            setActiveTab(0);
        }
    }, [currentTab]);

    useEffect(() => {
        if (data.length === 1) {
            setActiveTab(0);
        }
    }, [data.length]);

    if (!data || data.length === 0) {
        return null;
    }
    return (
        <TabsContainer
            animationDirection={
                previousActiveTab !== activeTab &&
                (previousActiveTab < activeTab ? 'left' : 'right')
            }
            containerHeight={containerHeight}
            square
            onMouseEnter={it => {
                if (onMouseEnter) {
                    onMouseEnter(it);
                }
            }}
            onMouseLeave={it => {
                if (onMouseLeave) {
                    onMouseLeave(it);
                }
            }}
        >
            <TabsHeader
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                isSmallTab={isSmallTab}
                aria-label="disabled tabs example"
                topNavigationTabs={topNavigationTabs}
                TabIndicatorProps={
                    isSmallTab
                        ? {
                              style: {
                                  width: `${80 / data.length}%`,
                                  marginLeft: '16px',
                                  borderRadius: '2px 2px 0px 0px',
                              },
                          }
                        : {
                              style: {
                                  borderRadius: '2px 2px 0px 0px',
                              },
                          }
                }
            >
                {data.map((tab, index) => (
                    <div key={`tab=${index}`}>
                        <TabElement
                            topNavigationTabs={topNavigationTabs}
                            className={activeTab === Number(index) && 'active'}
                            onClick={it => {
                                if (tab.onClick) {
                                    tab.onClick(it);
                                }
                                onTabClick(index);
                            }}
                            onMouseEnter={it => {
                                if (tab.onMouseEnter) {
                                    tab.onMouseEnter(it);
                                }
                            }}
                            onMouseLeave={it => {
                                if (tab.onMouseLeave) {
                                    tab.onMouseLeave(it);
                                }
                            }}
                            key={`tab-element-${index}`}
                            isSmallTab={isSmallTab}
                            color={theme.primary}
                            label={
                                <div>
                                    {tab.label}
                                    {tab.chip && (
                                        <StyledChip primary={theme.contrast}>{tab.chip}</StyledChip>
                                    )}
                                </div>
                            }
                            disableTouchRipple
                            icon={
                                tab.labelIcon && (
                                    <TabStyledFontIcon
                                        iconClassName={'material-icons material-icons-round'}
                                        displayIconWithoutLabel={!tab.label}
                                        topNavigationTabs={topNavigationTabs}
                                    >
                                        {tab.labelIcon}
                                    </TabStyledFontIcon>
                                )
                            }
                        />
                    </div>
                ))}
            </TabsHeader>
            <ContentWrapper>
                <ContentComponent
                    index={activeTab}
                    data={data}
                    checkContentAllTabs={checkContentAllTabs}
                />
            </ContentWrapper>
        </TabsContainer>
    );
};

const ContentComponent = React.memo(
    ({ data, index, checkContentAllTabs }) => {
        const tab = data[index];

        if (!tab) {
            return null;
        }

        const Content = tab.content;

        if (typeof Content === 'function') {
            return <Content />;
        }

        if (typeof Content === 'object') {
            return Content;
        }
        return null;
    },
    (prevProps, nextProps) => {
        const chatPrevProps = prevProps.data.find(item => item.id === 'user-interaction-chat');
        const chatNextProps = nextProps.data.find(item => item.id === 'user-interaction-chat');
        const qaPrevProps = prevProps.data.find(item => item.id === 'user-interaction-qa');
        const qaNextProps = nextProps.data.find(item => item.id === 'user-interaction-qa');
        const attendeesPrevProps = prevProps.data.find(
            item => item.id === 'user-interaction-attendees',
        );
        const attendeesNextProps = nextProps.data.find(
            item => item.id === 'user-interaction-attendees',
        );
        const isChatChipModified =
            chatPrevProps && chatNextProps && chatPrevProps.chip !== chatNextProps.chip;
        const isQaChipModified =
            qaPrevProps && qaNextProps && qaPrevProps.chip !== qaNextProps.chip;
        const isAttendeeLabelModified = attendeesPrevProps?.label !== attendeesNextProps?.label;
        const shouldNotRerender = isChatChipModified || isQaChipModified || isAttendeeLabelModified;

        // We should always rerender when we are switching tabs
        if (prevProps.index !== nextProps.index) {
            return false;
        }
        if (shouldNotRerender) {
            return true;
        }
        if (
            nextProps.data.length === 1 &&
            nextProps.data[0].checkContentOnly &&
            prevProps.data[0].checkContentOnly
        ) {
            return (
                JSON.stringify(prevProps.data.content) === JSON.stringify(nextProps.data.content) &&
                prevProps.data.length === nextProps.data.length &&
                prevProps.index === nextProps.index
            );
        }

        if (nextProps.checkContentAllTabs) {
            return (
                JSON.stringify(prevProps.data.content) === JSON.stringify(nextProps.data.content) &&
                prevProps.data.length === nextProps.data.length &&
                prevProps.index === nextProps.index
            );
        }
        return (
            JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
            prevProps.data.length === nextProps.data.length &&
            prevProps.index === nextProps.index
        );
    },
);

const TabsWithMemo = React.memo(
    props => {
        return <TabsComponent {...props} />;
    },
    (prevProps, nextProps) => {
        return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
    },
);

const TabsContiner = props => {
    if (props.useMemo) {
        return <TabsWithMemo {...props} />;
    } else {
        return <TabsComponent {...props} />;
    }
};

export default TabsContiner;
