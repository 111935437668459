import async from 'async';
import sortBy from 'lodash/sortBy';
import { saveNote } from '../../../services/api/data';

import { findAllLocal, getItemWithTypeInfo, getLocalItem } from '../../../services/api/db';

const getNote = function (objectId, next) {
    getLocalItem('notes', objectId, next);
};

const getAllNotes = function (next) {
    findAllLocal(
        'notes',
        item => {
            return item.action === 'Add';
        },
        (err, notes) => {
            if (notes && notes.length) {
                async.eachSeries(
                    notes,
                    (item, cb) => {
                        if (!item.objectClass) {
                            cb();
                        } else {
                            getItemWithTypeInfo(
                                item.objectClass,
                                item.objectId,
                                (err, withType) => {
                                    item.object = {
                                        type: withType.type,
                                        name: withType.name,
                                    };
                                    cb(err);
                                },
                            );
                        }
                    },
                    err => {
                        notes = sortBy(notes, o => o.object.name);
                        notes = sortBy(notes, o => o.object.type.plural);
                        next(err, notes);
                    },
                );
            } else {
                next(err, []);
            }
        },
    );
};

export { getNote, saveNote, getAllNotes };
