import { API, graphqlOperation } from 'aws-amplify';
import isEmpty from 'lodash/isEmpty';
import hash from 'object-hash';
import * as queries from '../../graphql/queries';
import { batchDecode, objectDecode } from '../graphqlHelper';
import { getCacheItem, setCacheItem } from './graphQlCache';
import { getLocalAppStateAsync } from './db';

const encodedFields = [
    'affiliations',
    'classifications',
    'classifiers',
    'invisibleSections',
    'invites',
    'links',
    'locations',
    'lockedFields',
    'navigation',
    'params',
    'related',
    'relatedOf',
    'resources',
    'roles',
    'rolesOf',
    'sections',
    'tags',
    'value',
    'webpages',
    'banner',
];

const generateQueryName = category => {
    let queryName = `get${category.replace(/^./, category[0].toUpperCase())}`;

    if (queries[queryName]) {
        return queryName;
    }

    return queryName + 's';
};

export const getItem = async (category, key, cb, forceQuery = false) => {
    if (!category || !key) {
        cb(`Invalid query for category: ${category} and key: ${key}`);
    }

    let queryName = generateQueryName(category);
    // Check the cache for the items
    const cacheKey = `${queryName}_${key}`;
    const cachedItem = await getCacheItem(cacheKey, forceQuery);
    if (cachedItem !== null) {
        return cb(null, cachedItem);
    }

    let query = queries[queryName];
    if (!query) {
        return cb(`Function ${queryName} not found`, null);
    }

    const response = await API.graphql(graphqlOperation(query, { id: key })).catch(err => {
        console.log(err, query, key);
    });

    if (response && response.data) {
        const result = response.data[queryName] || response.data[queryName + 's'];
        if (result && result.id) {
            const decoded = objectDecode(result, encodedFields);

            setCacheItem(cacheKey, decoded);

            cb(null, decoded);
        } else {
            setCacheItem(cacheKey, '');
            // return null for empty object
            cb(null, null);
        }
    } else {
        setCacheItem(cacheKey, '');

        cb(null, null);
    }
};

export const getItemAsync = (category, key, forceQuery) => {
    return new Promise((resolve, reject) => {
        getItem(
            category,
            key,
            (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            },
            forceQuery,
        );
    });
};

// Get batch from any table of "Timeslots", "Programelements", "Persons", "Institutions", "Classifiers", "Images", "Places", "Links", "Types";
// data:[{target:"classifiers",ids:["5b5b268ecb62a3432348058b","5b5b268ecb62a3432548058b","5bbf123a882f9c734cd8c60b"]},{target:"links",ids:["5b5b268ecb62a3432548058b","5bbf123a882f9c734cd8c60b"]}]

export const batchGet = async (input, forceQuery = false) => {
    if (isEmpty(input) || isEmpty(input.data)) {
        return {};
    }

    // Check the cache for the items
    const cacheKey = hash(input);
    const cachedItem = await getCacheItem(cacheKey, forceQuery);
    if (cachedItem) {
        return cachedItem;
    }

    try {
        const {
            data: { batchGet: result },
        } = await API.graphql(graphqlOperation(queries.batchGet, input));
        const decoded = batchDecode(input, result, encodedFields);
        // Save this to cache
        setCacheItem(cacheKey, decoded);
        return decoded;
    } catch (e) {
        setCacheItem(cacheKey, '');

        return {};
    }
};

export const executeQuery = async (queryName, params, forceQuery = false) => {
    if (isEmpty(queryName) || isEmpty(params)) {
        return null;
    }

    const cacheKey = `${queryName}_${JSON.stringify(params)}`;
    const cachedItem = await getCacheItem(cacheKey, forceQuery);
    if (cachedItem) {
        return cachedItem;
    }

    const query = queries[queryName] || queries[queryName + 's'];
    if (!query) {
        console.log(`Function ${queryName} not found`);
        return null;
    }

    try {
        const response = await API.graphql(graphqlOperation(query, params));
        if (response && response.data) {
            const result = response.data[queryName] || response.data[queryName + 's'];
            let decodedResult;
            if (result && result.items && result.items.length) {
                decodedResult = result.items.map(item => objectDecode(item, encodedFields));
            } else if (result && result.id) {
                decodedResult = objectDecode(result, encodedFields);
            } else {
                decodedResult = result && result.items ? [] : null;
            }

            setCacheItem(cacheKey, decodedResult || '');
            return decodedResult;
        }
    } catch (err) {
        console.log(err);
        return { error: { message: err?.errors[0]?.message || 'error' } };
    }
};

export const executeQueryWithCallback = async (queryName, params, callback, forceQuery = false) => {
    const result = await executeQuery(queryName, params, forceQuery);

    callback(null, result);
};

export const executeListQueryWithCallBack = async (
    queryName,
    params,
    callback,
    forceQuery = false,
) => {
    const result = await executeListQuery(queryName, params, forceQuery);

    return callback(null, result);
};

export const executeListQuery = async (
    queryName,
    params,
    forceQuery = false,
    keepEventInParams = false,
    additionalFilter = null,
) => {
    if (isEmpty(queryName) || isEmpty(params)) {
        return null;
    }

    const cacheKey = `${queryName}_${JSON.stringify(params)}`;
    const cachedItem = await getCacheItem(cacheKey, forceQuery);
    if (cachedItem) {
        return cachedItem;
    }

    const query = queries[queryName] || queries[queryName + 's'];
    if (!query) {
        console.log(`Function ${queryName} not found`);
        return null;
    }

    const localConfig = (await getLocalAppStateAsync()) || {};
    const event = localConfig.id;

    const results = [];
    let nextToken = null;

    do {
        if (!keepEventInParams) {
            // in case there are filter queries that still add the event, just so the query won't fail
            delete params.event;
        }

        const response = await API.graphql(
            graphqlOperation(query, {
                event,
                filter: isEmpty(params) ? null : params,
                nextToken,
                limit: 1000,
            }),
        ).catch(err => {
            console.log(err);

            return null;
        });

        if (response && response.data) {
            nextToken = response.data.nextToken;

            const result = response.data[queryName] || response.data[queryName + 's'];
            let decodedItems = result.items.map(item => objectDecode(item, encodedFields));
            if (
                additionalFilter &&
                additionalFilter.byLocationId &&
                additionalFilter.byLocationId.length
            ) {
                decodedItems = decodedItems.filter(timeslot =>
                    timeslot.locations.some(
                        location => location._id === additionalFilter.byLocationId,
                    ),
                );
            }

            nextToken = result.nextToken;

            results.push(...decodedItems);
        } else {
            nextToken = null;
        }
    } while (nextToken);

    setCacheItem(cacheKey, results || '');

    return results;
};
