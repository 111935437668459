import { getString } from '../../services/api/store';

export const DIALOG_TITLE = 'Sign in to Eureka';
export const DIALOG_MESSAGE =
    'Sign in to add items to your favourites, make notes and synchronize these across devices.';
export const DIALOG_USING_EMAIL = 'Or using your email address:';
export const DIALOG_CONFIRM_BUTTON = 'Sign in';
export const DIALOG_CANCEL_BUTTON = 'CANCEL';
export const INPUT_LABEL_EMAIL = 'Email address';
export const INPUT_LABEL_PASSWORD = 'Enter your password';
export const FORGOT_PASSWORD_TITLE = 'Forgot password?';
export const NO_ACCOUNT_MESSAGE = 'No account yet? ';
export const CREATE_ACCOUNT_TITLE = 'Create one here';
export const ERROR_MESSAGE_WRONG_PASSWORD = 'Password is incorrect';
export const ERROR_MESSAGE_REQUIRED_PASSWORD = 'Please enter your password';
export const ERROR_MESSAGE_WRONG_EMAIL = getString(
    'signInEmailErrorMessage',
    'Please make sure you have entered a valid email',
);
export const ERROR_MESSAGE_DEFAULT = 'There was an error signing you in. Please try again.';
export const INPUT_EMAIL_MESSAGE =
    'Enter your email address below. If you have an account, you can enter your password after, otherwise you can create one.';
export const INPUT_PASSWORD_MESSAGE = 'Enter the password for your account';
export const INPUT_REGISTER_MESSAGE =
    'Add your name and a password, and review our Privacy Policy to create an account. ';
export const INPUT_PASSWORD_HELP =
    'Password should have at least six characters and include uppercase, lowercase and numbers.';

export const EVENT_LOGIN_TITLE = 'Sign in to access this event';
export const EVENT_LOGIN_DESCRIPTION = 'To access this event, you need to be signed in.';
export const ATTENDEES_LOGIN_TITLE = 'Event access is restricted';
export const ATTENDEES_LOGIN_DESCRIPTION =
    'Only attendees can access this event. Sign in to verify if you’re on the list.';

export const SIGNED_IN_TITLE = 'You have been successfully signed in';
export const SIGNED_IN_DESCRIPTION =
    'To make sure other people can find you, check your profile and visibility settings.';
export const SIGNED_IN_PROFILE_TEXT = 'Check profile and settings.';

export const ALL_SET_TITLE = 'You’re all set!';
export const ALL_SET_DESCRIPTION =
    'Your favorites will be synced across devices and you can now start connecting to other attendees.';
export const ALL_SET_BUTTON_LABEL = 'Take me home';

export const validatePassword = string => {
    const regExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])/;
    return regExp.test(string);
};

export const validateEmail = email => {
    // eslint-disable-next-line
    const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
};

export default getString;

// //Translation strings
// let TEXT_DIALOG_MESSAGE = getString("loginMainTextWebApp") || DIALOG_MESSAGE;
// let TEXT_DIALOG_USING_EMAIL = getString('emailLoginText') || DIALOG_USING_EMAIL;
// let TEXT_DIALOG_CANCEL = getString("cancel") || DIALOG_CANCEL_BUTTON ;
// let TEXT_DIALOG_SIGNIN = getString("signInButton") || DIALOG_CONFIRM_BUTTON ;
// let TEXT_INPUT_EMAIL = getString("signInEmail") || INPUT_LABEL_EMAIL;
// let TEXT_INPUT_PASSWORD = getString("signInPassword") || INPUT_LABEL_PASSWORD;
// let TEXT_FORGOT_PASSWORD = getString("signInForgotPasswordButton") || FORGOT_PASSWORD_TITLE;
// let TEXT_ERROR_WRONG_PASSWORD = getString("passwordModalError") || ERROR_MESSAGE_WRONG_PASSWORD;
// let TEXT_ERROR_DEFAULT = getString("loginSignInErrorDialogMessage") || ERROR_MESSAGE_DEFAULT;
//
// let TEXT_DIALOG_TITLE = getString("signInDialogTitle") || DIALOG_TITLE;
// let TEXT_NO_ACCOUNT_MESSAGE = getString("noAccountYetTitle") || NO_ACCOUNT_MESSAGE;
// let TEXT_CREATE_ACCOUNT = getString("createAccountTitle") || CREATE_ACCOUNT_TITLE;
