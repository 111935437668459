import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { default as Store, getString } from '../../services/api/store';
import ThemeContext from '../Theme/ThemeContext';
import { WithTimezone } from '../../scenes/Timezone/context';

const StyledChip = styled.div`
    text-align: center;
    font-family: 'Cabin', sans-serif;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    background-color: ${props => props.color};
    border-radius: 10px;
    width: 42px;
    min-width: 42px;
    height: 16px;
    line-height: 1.8;
    white-space: nowrap;
    letter-spacing: 0.42px;
    margin: ${props => props.margin || '0 6px 0 0'};
`;

const StyledTimelineChip = styled(StyledChip)`
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    font-weight: 500;
    border-radius: 4px;
    line-height: 21px;
    height: 20px;
`;

const HAPPENING_NOW_INFO = 'NOW';

const HappeningNow = props => {
    let isVisible;
    let TEXT_NOW = getString('now') || HAPPENING_NOW_INFO;

    //08-15 14:45:00 Z
    if (props.start && props.end) {
        let timezone = Store.argument.offsetTimezone;
        const { selectedTimezone } = props.timezone;
        if (selectedTimezone !== 'event') {
            timezone = moment().utcOffset();
        }
        var start = moment.utc(props.start).utcOffset(timezone, true);
        var end = moment.utc(props.end).utcOffset(timezone, true);
        var now = moment.utc();

        isVisible = now.isBetween(start, end, null, '[]');
    }

    if (isVisible) {
        return (
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <React.Fragment>
                        <StyledTimelineChip color={theme.contrast} margin={props.margin}>
                            {TEXT_NOW}
                        </StyledTimelineChip>
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    } else {
        return null;
    }
};

export default WithTimezone(HappeningNow);
