import React from 'react';

import { InteractivityContext } from '../Interactivity';
import AuthUserContext from '../Session/AuthUserContext';
import ThemeContext from '../Theme/ThemeContext';
import { getString } from '../../services/api/store';
import Button, { buttonTypes } from '../../scenes/common/Button';

const BookmarkIcon = props => (
    <AuthUserContext.Consumer>
        {authUser => {
            if (!props.clickMyCongress) return;
            return (
                <InteractivityContext.Consumer>
                    {({ showSignIn }) => (
                        <ThemeContext.Consumer>
                            {({ theme }) => (
                                <React.Fragment>
                                    {!theme.embed && !theme.kiosk && (
                                        <Button
                                            type={
                                                props.smallBookmarkButton
                                                    ? buttonTypes.GREY
                                                    : buttonTypes.GREY_LONG
                                            }
                                            background={theme.primary}
                                            icon={
                                                props.favoriteStatus === 'checked'
                                                    ? 'bookmark'
                                                    : 'bookmark_border'
                                            }
                                            onClick={authUser ? props.clickMyCongress : showSignIn}
                                            iconCustomColor={
                                                props.favoriteStatus === 'checked' && theme.primary
                                            }
                                            tooltip={
                                                props.favoriteStatus === 'checked'
                                                    ? getString(
                                                          'removeFromFavorites',
                                                          'Remove from favorites',
                                                      )
                                                    : getString(
                                                          'saveToFavorites',
                                                          'Save to favorites',
                                                      )
                                            }
                                            text={
                                                props.smallBookmarkButton
                                                    ? ''
                                                    : props.favoriteStatus === 'checked'
                                                    ? getString('savedButton') || 'Saved'
                                                    : getString('saveButton') || 'Save'
                                            }
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </ThemeContext.Consumer>
                    )}
                </InteractivityContext.Consumer>
            );
        }}
    </AuthUserContext.Consumer>
);

export default BookmarkIcon;
