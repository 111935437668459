export const TEN_MILL = 10000000;
export const TWENTY_MILL = 20000000;
export const THIRTY_MILL = 30000000;
export const FORTY_MILL = 40000000;
export const FIFTY_MILL = 50000000;
export const LOW_STREAM_CONFIGURATION = {
    width: 160,
    height: 120,
    frameRate: 15,
    bitrateMin: 30,
    bitrateMax: 120
};
export const MEDIUM_STREAM_CONFIGURATION = {
    width: 424,
    height: 240,
    frameRate: 15,
    bitrateMin: 154,
    bitrateMax: 380
};
export const HIGH_STREAM_CONFIGURATION = {
    width: 848,
    height: 480,
    frameRate: 15,
    bitrateMin: 430,
    bitrateMax: 1000
};
export const ROUND_TABLE_CONFIGURATIONS = {
    HIGH: {
        width: 1280,
        height: 720,
        frameRate: 15,
        bitrateMin: 800,
        bitrateMax: 2000
    },
    MEDIUM: {
        width: 640,
        height: 360,
        frameRate: 15,
        bitrateMin: 280,
        bitrateMax: 680
    },
    LOW: {
        width: 424,
        height: 240,
        frameRate: 15,
        bitrateMin: 154,
        bitrateMax: 380
    },
    VLOW: {
        width: 320,
        height: 180,
        frameRate: 15,
        bitrateMin: 100,
        bitrateMax: 250
    },
};

export default {
    TEN_MILL,
    TWENTY_MILL,
    THIRTY_MILL,
    FORTY_MILL,
    FIFTY_MILL,
    LOW_STREAM_CONFIGURATION,
    MEDIUM_STREAM_CONFIGURATION,
    HIGH_STREAM_CONFIGURATION,
    ROUND_TABLE_CONFIGURATIONS,
};
