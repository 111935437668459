import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import DetailPage from '../../scenes/DetailPage';
import FloorplanPage from '../../scenes/Floorplan';
import ListPage from '../../scenes/ListPage';
import MenuPage from '../../scenes/MenuPage';
import MyNotesPage from '../../scenes/MyNotesPage';
import ProgrammePage from '../../scenes/Programme';
import Feed from '../../scenes/Feed';
import LandingPage from '../../scenes/LandingPage/containers/LandingPage';
import SearchPage from '../../scenes/SearchPage';
import TalkRoom from '../../scenes/TalkRoom';
import PrivateChat from '../../scenes/Talk/containers/PrivateChat';
import Sponsor from '../../scenes/Sponsor';
import TabsPage from '../../scenes/TabsPage';
import ModeratedSession from '../../scenes/VirtualSession/components/moderatedSession/ModeratedSession';
import RoundTableSession from '../../scenes/VirtualSession/components/roundTable/VirtualRoundTableSession';
import WebPage from '../../scenes/WebPage';
import { getString } from '../../services/api/store';
import PageSelector from '../PageSelector';
import MyContactsPage from '../../scenes/MyContactsPage';
import UserProfile from '../../scenes/UserProfile/containers/UserProfile';
import withTracker from '../../features/analytics/hoc/withAnalytics';
import VideoPage from '../../scenes/VideoPage';
import AppointmentDetailPage from '../../scenes/Appointments/containers/AppointmentDetailPage';
import SocialFeed from '../../scenes/SocialFeed/containers/SocialFeed';
import AttendeeList from '../../scenes/Attendees';
import MyBookmarksPage from '../../scenes/MyBookmarksPage';
import SignIn from '../../scenes/SignInPage/components';
import AuthenticationProtection from '../ProtectedPages/AuthenticationProtection';
import PostEditor from '../../scenes/SocialFeed/containers/PostEditor';
import ExhibitorPage from '../../scenes/ExhibitorPage/containers/ExhibitorPage';
import VirtualRoom from '../../scenes/VirtualSession/components/virtualRoom/VirtualRoom';
import ObjectPage from '../../scenes/ObjectPage';
import UpcomingSessionsDetailPage from '../../scenes/LandingPage/containers/UpcomingSessionsDetailPage';
import AchievementsPage from '../../scenes/Achievements/containers';
import { executeQuery } from '../../services/api/graphQlRepository';
import MyCheckinsPage from '../../scenes/MyCheckinsPage';

class Routes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nav: props.nav,
        };
    }

    componentDidMount() {
        (async () => {
            const pages = await executeQuery('getPagesWithEventAndName', {
                event: this.props.eventId,
            });

            if (pages && pages.length) {
                this.setState({ pages });
            }
        })();
    }

    renderRoute = (elem, Rendercomponent, index, componentType, extraParams) => {
        let basePath = extraParams ? `${elem.to}/:${extraParams}` : elem.to;
        if (componentType === 'video') {
            basePath = `${elem.to}/:objectClass/:objectId`;
        }

        return (
            <React.Fragment key={`${index}_route`}>
                <Route
                    path={basePath}
                    render={routeProps => {
                        const root = extraParams
                            ? `${elem.to}/${routeProps.match.params[extraParams]}`
                            : elem.to;

                        return (
                            <React.Fragment>
                                {Rendercomponent && (
                                    <Rendercomponent
                                        {...routeProps}
                                        pageId={elem.pageId}
                                        setTitle={() => this.props.setTitle(elem.title)}
                                        disableScroll={this.props.disableScroll}
                                        rootRoute={root}
                                        prefix={root}
                                        closePath={root}
                                        goBack={routeProps.history.goBack}
                                        handleSidepanel={this.props.handleSidepanel}
                                        isSidepanelOpened={this.props.isSidepanelOpened}
                                        navigationType={this.props.navigationType}
                                        visibleFullSideMenu={this.props.visibleFullSideMenu}
                                    />
                                )}

                                <Switch>
                                    <Route
                                        path={`${basePath}/add`}
                                        render={routeProps => (
                                            <AuthenticationProtection
                                                goBack={routeProps.history.goBack}
                                            >
                                                <PostEditor
                                                    {...routeProps}
                                                    goBack={routeProps.history.goBack}
                                                />
                                            </AuthenticationProtection>
                                        )}
                                    />
                                    {componentType === 'floorplan' && (
                                        <Route
                                            path={`${basePath}/:floorplan/:place?/:objectClass?/:objectId?`}
                                            render={routeProps => (
                                                <Rendercomponent
                                                    {...routeProps}
                                                    pageId={elem.pageId}
                                                    setTitle={() => this.props.setTitle(elem.title)}
                                                    disableScroll={this.props.disableScroll}
                                                    rootRoute={root}
                                                    handleSidepanel={this.props.handleSidepanel}
                                                    isSidepanelOpened={this.props.isSidepanelOpened}
                                                    navigationType={this.props.navigationType}
                                                    visibleFullSideMenu={
                                                        this.props.visibleFullSideMenu
                                                    }
                                                />
                                            )}
                                        />
                                    )}
                                    <Route
                                        path={`${basePath}/talkRoom/:objectId`}
                                        render={props => (
                                            <TalkRoom
                                                {...props}
                                                goBack={routeProps.history.goBack}
                                                setTitle={() => this.props.setTitle(elem.title)}
                                                closePath={root}
                                                rootRoute={root}
                                            />
                                        )}
                                        exact
                                    />
                                    <Route
                                        path={`${basePath}/:id?/profile/:userId/:personLinkedId?`}
                                        render={routeProps => (
                                            <UserProfile
                                                {...routeProps}
                                                rootRoute={root}
                                                goBack={routeProps.history.goBack}
                                                onClose={routeProps.history.goBack}
                                            />
                                        )}
                                    />

                                    <Route
                                        path={`${basePath}/privateChat/:conversationId`}
                                        render={routeProps => (
                                            <PrivateChat
                                                {...routeProps}
                                                rootRoute={root}
                                                goBack={routeProps.history.goBack}
                                                closePath={root}
                                            />
                                        )}
                                        exact
                                    />
                                    <Route
                                        path={`${basePath}/talkRoom/:objectId/profile/:userId/:personLinkedId?`}
                                        exact
                                        render={props => (
                                            <UserProfile
                                                {...props}
                                                rootRoute={elem.to}
                                                goBack={routeProps.history.goBack}
                                                prefix={elem.to}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/appointment/:objectId`}
                                        render={routeProps => (
                                            <AppointmentDetailPage
                                                {...routeProps}
                                                rootRoute={root}
                                                goBack={routeProps.history.goBack}
                                                closeLink={root}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/privateChat/:userId`}
                                        render={routeProps => (
                                            <PrivateChat
                                                {...routeProps}
                                                goBack={routeProps.history.goBack}
                                                rootRoute={root}
                                            />
                                        )}
                                        exact
                                    />
                                    <Route
                                        path={`${basePath}/privateChat/:conversationId/profile/:userId`}
                                        render={routeProps => (
                                            <UserProfile
                                                {...routeProps}
                                                rootRoute={root}
                                                goBack={routeProps.history.goBack}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/edit/:id`}
                                        render={routeProps => (
                                            <AuthenticationProtection
                                                goBack={routeProps.history.goBack}
                                            >
                                                <PostEditor
                                                    {...routeProps}
                                                    goBack={routeProps.history.goBack}
                                                />
                                            </AuthenticationProtection>
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/talkRoom/:objectId/profile/:userId/:personLinkedId?`}
                                        render={props => (
                                            <UserProfile
                                                {...props}
                                                rootRoute={root}
                                                goBack={routeProps.history.goBack}
                                                prefix={root}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/page/:pageType/profile/:userId/:personLinkedId?`}
                                        render={routeProps => (
                                            <UserProfile
                                                {...routeProps}
                                                rootRoute={root}
                                                goBack={routeProps.history.goBack}
                                                prefix={
                                                    elem.to +
                                                    '/page/' +
                                                    routeProps.match.params.pageType
                                                }
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/page/:pageType/appointment/:objectId`}
                                        render={routeProps => (
                                            <AppointmentDetailPage
                                                {...routeProps}
                                                rootRoute={root}
                                                goBack={routeProps.history.goBack}
                                                prefix={
                                                    elem.to +
                                                    '/page/' +
                                                    routeProps.match.params.pageType
                                                }
                                                closeLink={elem.to}
                                            />
                                        )}
                                    />

                                    <Route
                                        path={`${basePath}/page/:pageType/privateChat/:userId`}
                                        render={routeProps => (
                                            <PrivateChat
                                                {...routeProps}
                                                goBack={routeProps.history.goBack}
                                                rootRoute={root}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/page/:pageType/:pageId/profile/:userId/:personLinkedId?`}
                                        render={routeProps => (
                                            <UserProfile
                                                {...routeProps}
                                                rootRoute={root}
                                                goBack={routeProps.history.goBack}
                                                prefix={
                                                    elem.to +
                                                    '/page/' +
                                                    routeProps.match.params.pageType +
                                                    '/' +
                                                    routeProps.match.params.pageId
                                                }
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/page/:pageType/:pageId/privateChat/:userId`}
                                        render={routeProps => (
                                            <PrivateChat
                                                {...routeProps}
                                                goBack={routeProps.history.goBack}
                                                rootRoute={root}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/page/:pageType/:pageId/talkRoom/:objectId`}
                                        render={routeProps => (
                                            <TalkRoom
                                                {...routeProps}
                                                goBack={routeProps.history.goBack}
                                                closePath={root}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/upcomingSessions`}
                                        render={routeProps => (
                                            <UpcomingSessionsDetailPage
                                                goBack={routeProps.history.goBack}
                                                closeLink={elem.to}
                                                rootRoute={root}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/page/:pageType/:pageId/details/:objectClass/:objectId`}
                                        exact
                                        render={routeProps => (
                                            <PageSelector
                                                {...routeProps}
                                                rootRoute={root}
                                                detailPage
                                                matchUrl={routeProps.match.url}
                                                pageId={routeProps.match.params.objectId}
                                                pageType={routeProps.match.params.objectClass}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/page/:pageType/:pageId/:objectClass/:objectId`}
                                        render={props => (
                                            <DetailPage
                                                {...props}
                                                closeLink={root}
                                                prefix={
                                                    elem.to +
                                                    '/page/' +
                                                    props.match.params.pageType +
                                                    '/' +
                                                    props.match.params.pageId
                                                }
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${basePath}/page/:pageType/:pageId/:childrenId?`}
                                        render={routeProps => (
                                            <PageSelector
                                                {...routeProps}
                                                rootRoute={root}
                                                detailPage
                                                matchUrl={routeProps.match.url}
                                                pageId={routeProps.match.params.pageId}
                                                pageType={routeProps.match.params.pageType}
                                                childrenId={routeProps.match.params.childrenId}
                                            />
                                        )}
                                    />
                                    {componentType !== 'floorplan' && (
                                        <Route
                                            path={`${basePath}/:objectClass/:objectId?`}
                                            render={props => (
                                                <DetailPage
                                                    {...props}
                                                    closeLink={root}
                                                    prefix={root}
                                                />
                                            )}
                                        />
                                    )}
                                </Switch>
                            </React.Fragment>
                        );
                    }}
                />
            </React.Fragment>
        );
    };

    setComponentByType = type => {
        switch (type) {
            case 'floorplan': {
                return { Rendercomponent: FloorplanPage };
            }
            case 'search': {
                return { Rendercomponent: SearchPage };
            }
            case 'list': {
                return { Rendercomponent: ListPage };
            }
            case 'menu': {
                return { Rendercomponent: MenuPage };
            }
            case 'programme': {
                return { Rendercomponent: ProgrammePage };
            }
            case 'tabs': {
                return { Rendercomponent: TabsPage };
            }
            case 'webpage': {
                return { Rendercomponent: WebPage };
            }
            case 'sponsor': {
                return { Rendercomponent: Sponsor };
            }
            case 'Feed': {
                return { Rendercomponent: Feed };
            }
            case 'Landing': {
                return { Rendercomponent: LandingPage };
            }
            case 'socialFeed': {
                return { Rendercomponent: SocialFeed };
            }
            case 'attendees': {
                return { Rendercomponent: AttendeeList };
            }
            case 'mybookmarks': {
                return { Rendercomponent: MyBookmarksPage };
            }
            case 'mycontacts': {
                return { Rendercomponent: MyContactsPage };
            }
            case 'mynotes': {
                return { Rendercomponent: MyNotesPage };
            }
            case 'video': {
                return { Rendercomponent: VideoPage };
            }
            case 'virtual-session-round': {
                return { Rendercomponent: RoundTableSession };
            }
            case 'virtual-session': {
                return { Rendercomponent: ModeratedSession };
            }
            case 'virtual-session-room': {
                return { Rendercomponent: VirtualRoom };
            }
            case 'exhibitorbooth': {
                return { Rendercomponent: ExhibitorPage };
            }
            case 'object': {
                return { Rendercomponent: ObjectPage };
            }
            case 'achievements': {
                return { Rendercomponent: AchievementsPage };
            }
            case 'checkins': {
                return { Rendercomponent: MyCheckinsPage };
            }
            default: {
                break;
            }
        }
    };

    render() {
        const { nav, pages } = this.state;
        const TITLE_MY_CONVERSATIONS = getString('talkWindowTitle') || 'My Conversations';
        const TITLE_MY_CONTACTS = getString('contactsWindowTitle') || 'My Contacts';
        const TITLE_MY_NOTES = getString('myNotes') || 'My Notes';
        const TITLE_MY_BOOKMARKS = getString('myFavorites', 'Bookmarks');
        const TITLE_VIRTUAL_SESSION = getString('virtualSession') || 'Virtual Session';
        const TITLE_MY_CHECKINS = getString('myCheckinsTitle', 'My check ins');
        const navigationCustomItems = [
            {
                name: 'mybookmarks',
                title: TITLE_MY_BOOKMARKS,
            },
            {
                name: 'myconversations',
                title: TITLE_MY_CONVERSATIONS,
            },
            {
                name: 'mycontacts',
                title: TITLE_MY_CONTACTS,
            },
            {
                name: 'mynotes',
                title: TITLE_MY_NOTES,
            },
            {
                name: 'video',
            },
            {
                name: 'search',
                title: 'Search',
                pageId: '5db0358bf4d01d313c664587', //TODO remove this hardoced pageId
            },
            {
                name: 'virtual-session-round',
                title: TITLE_VIRTUAL_SESSION,
                extraParams: 'timeslotId',
            },
            {
                name: 'virtual-session-test',
                title: TITLE_VIRTUAL_SESSION,
                extraParams: 'timeslotId',
            },
            {
                name: 'virtual-session',
                title: TITLE_VIRTUAL_SESSION,
                extraParams: 'timeslotId',
            },
            {
                name: 'virtual-session-room',
                title: TITLE_VIRTUAL_SESSION,
                extraParams: 'timeslotId',
            },
            {
                name: 'exhibitorbooth',
                extraParams: 'exhibitorId',
            },
            {
                name: 'achievements',
                title: 'Achievements',
            },
            {
                name: 'checkins',
                title: TITLE_MY_CHECKINS,
            },
        ];
        navigationCustomItems.forEach(item => {
            if (nav && nav.length && !nav.find(navElem => navElem.type === item.name)) {
                const navObject = {
                    to: `/${item.name}`,
                    type: item.name,
                    title: item.title,
                    pageId: item.pageId,
                    extraParams: item.extraParams,
                    hidden: true,
                };
                nav.push(navObject);
            }
        });

        if (pages && pages.length) {
            pages.forEach(item => {
                if (nav && nav.length && !nav.find(navElem => navElem.to === `/${item.name}`)) {
                    const navObject = {
                        to: `/${item.name}`,
                        type: item.kind,
                        title: item.params && item.params.title,
                        pageId: item.id,
                        extraParams: item.extraParams,
                        hidden: true,
                    };
                    nav.push(navObject);
                }
            });
        }

        let routes = nav.map((elem, index) => {
            let Rendercomponent;

            const componentObject = this.setComponentByType(elem.type);
            Rendercomponent = componentObject && componentObject.Rendercomponent;

            return this.renderRoute(elem, Rendercomponent, index, elem.type, elem.extraParams);
        });

        return (
            <>
                <Route exact path="/sign-in" render={() => <SignIn />} />
                <Route exact path="/" render={() => <Redirect to={nav[0].to} />} />
                {routes}
            </>
        );
    }
}

export default withTracker(Routes);
