import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { FontIcon } from 'react-md';
import styled, { keyframes } from 'styled-components';
import MediaPlayerSlider from '../General/MediaPlayerSlider';
import { useGlobalState, useGlobalMutation } from '../../utils/container';
import ThemeContext from '../Theme/ThemeContext';
import useViewRecordingAnalytics from "../../hooks/useViewRecordingAnalytics";

export const ActionButtonsWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    height: 98%;
`;

const Wrapper = styled.div`
    width: 100%;
    height: auto;
`;

const PlayerWrapper = styled.div`
    cursor: ${props => (props.primary ? 'pointer' : 'default')};
    position: relative;
    width: 100%;
    height: 100%;

    video {
        border-radius: 8px;
        object-fit: fill;
    }
`;

const PlayIconWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    width: 72px;
    height: 72px;
    background-color: #2d2d2d;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: ${props => (props.contrast ? props.contrast : '#ef2040')};
    }
`;

const PlayStopIconWrapper = styled.div`
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    background-color: #2d2d2d;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 28px -13px 0px 38px;
    &:hover {
        background-color: ${props => (props.contrast ? props.contrast : '#ef2040')};
    }
`;

const PlayIcon = styled(FontIcon)`
    font-size: 36px !important;
    color: #ffffff !important;
`;

const DurationWrapper = styled.div`
    display: flex;
    justify-content: start;
    width: ${props => (props.largeWrapper ? '122px' : '88px')};
    height: 22px;
    border-radius: 4px;
    background-color: #2d2d2d;
    padding: 4px 8px;
    margin-top: 4px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const Toolbar = styled.div`
    position: absolute;
    z-index: 20;
    bottom: 0;
    width: 100%;
    display: flex;
    height: 69px;
    padding-right: 16px;
    align-items: center;
    visibility: ${props => (props.out ? 'hidden' : 'visible')};
    animation: ${props => (props.out ? fadeOut : fadeIn)} ${props => (props.out ? '1s' : '.7s')}
        linear;
    transition: visibility 1s linear;
`;

const ToolbarTracker = styled.div`
    flex: 1;
    height: 100%;
`;

const Duration = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
`;

const Tooltip = styled.div`
    width: fit-content;
    height: 21px;
    background-color: #ffffff;
    position: absolute;
    bottom: 66px;
    left: ${props => props.tooltipLeft}px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 21;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    padding: 0 2px;

    &:after {
        content: '';
        width: 0;
        height: 0;
        left: 17px;
        top: 17px;
        position: absolute;
        border: 3px solid #fff;
        transform: rotate(135deg);
        transition: border 0.4s ease-in-out;
    }
`;

const PROGRESS_INTERVAL = 100;

const MediaPlayer = ({ url, primary, muted, viewRecordingSessionId }) => {
    const playerRef = useRef(null);

    const stateCtx = useGlobalState();
    const mutationCtx = useGlobalMutation();
    const { playing, playedSeconds, primaryPlayerRef, secondaryPlayerRef } = stateCtx;

    const [duration, setDuration] = useState(0);
    const [showToolbar, setShowToolbar] = useState(false);
    const [showTime, setShowTime] = useState(null);
    const [tooltipLeft, setTooltipLeft] = useState(null);
    const mouseOverToolbar = useRef(null);
    const loadedSeconds = useRef(null);
    const videoStarted = useRef(null);
    const usingSlider = useRef(false);

    const { increaseViewedTime } = useViewRecordingAnalytics(viewRecordingSessionId);

    useEffect(() => {
        if (primary) {
            mutationCtx.setPrimaryPlayerRef(playerRef);
        } else {
            mutationCtx.setSecondaryPlayerRef(playerRef);
        }
    }, []);

    useEffect(() => {
        // Resume video from playedSeconds when mainMedia is switched
        const { playedSeconds } = stateCtx;

        if (playedSeconds) {
            playerRef.current.seekTo(playedSeconds, 'seconds');
        }
    }, [muted]);

    const roundValue = value => (value >= 0 ? Math.round(value * 10) / 10 : 0);

    const onPlayClick = () => {
        if (!videoStarted.current) {
            videoStarted.current = true;
        }
        if (playing) {
            mutationCtx.stopPlaying();
        } else {
            mutationCtx.startPlaying();
        }
    };

    const onProgress = progress => {
        if (!primary) {
            return;
        }
        loadedSeconds.current = progress.loadedSeconds;
        const { playedSeconds } = progress;
        const roundedValue = roundValue(playedSeconds);

        mutationCtx.setPlayedSeconds(roundedValue);

        if (playing && !usingSlider.current) {
            increaseViewedTime(PROGRESS_INTERVAL / 1000);
        }
    };

    const onDuration = duration => {
        setDuration(duration);
    };

    const onEnded = () => mutationCtx.stopPlaying();

    const convertSecondsToTimeString = (seconds, totalDuration) => {
        let videoLength;
        const totalVideoLength = new Date(totalDuration * 1000).toISOString().substr(11, 8);
        if (seconds !== totalDuration) {
            videoLength = new Date(seconds * 1000).toISOString().substr(11, 8);
        } else {
            videoLength = totalVideoLength;
        }

        if (totalVideoLength.substring(0, 3) === '00:') {
            videoLength = videoLength.substring(3);
        }
        return videoLength;
    };

    const onSliderValueChanged = (value, event) => {
        usingSlider.current = true;
        if (event && event.type) {
            const roundedValue = roundValue(value);
            // Pause video while dragging slider
            if (event && event.type === 'mousemove') {
                mutationCtx.stopPlaying();
            }

            primaryPlayerRef.current.seekTo(roundedValue, 'seconds');
            secondaryPlayerRef &&
                secondaryPlayerRef.current &&
                secondaryPlayerRef.current.seekTo(roundedValue, 'seconds');
            mutationCtx.setPlayedSeconds(roundedValue);

            if (event.type === 'mouseup') {
                mutationCtx.startPlaying();
            }
            if (event.type === 'mousedown' && playing) {
                setTimeout(() => {
                    mutationCtx.startPlaying();
                }, 200);
            } else {
                setTimeout(() => {
                    mutationCtx.stopPlaying();
                }, 200);
            }
        } else {
            setTooltipLeft(value.clientX - 46);
            if (
                sliderElem &&
                sliderElem[0] &&
                sliderElem[0].offsetWidth &&
                sliderElem[0].matches(':hover')
            ) {
                const a =
                    ((value.clientX - 111) / sliderElem[0].offsetWidth) * Math.round(duration);
                const roundedValue = roundValue(a);
                setShowTime(roundedValue);
            }
        }

        setTimeout(() => {
            usingSlider.current = false;
        }, 200);
    };
    const playedSecondsDuration = convertSecondsToTimeString(playedSeconds, duration);
    const totalDuration = convertSecondsToTimeString(duration, duration);
    const sliderElem = document.getElementsByClassName('md-slider-track');

    const stopShowingToolbar = () =>
        !mouseOverToolbar.current &&
        setTimeout(() => {
            setShowToolbar(false);
        }, 3000);

    return (
        <ThemeContext.Consumer>
            {({ theme }) => (
                <Wrapper
                    onMouseEnter={() => {
                        setShowToolbar(true);
                        stopShowingToolbar();
                    }}
                    onMouseMove={() => {
                        setShowToolbar(true);
                        stopShowingToolbar();
                    }}
                    onMouseLeave={stopShowingToolbar}
                >
                    <PlayerWrapper primary={primary} onClick={primary && onPlayClick}>
                        <ReactPlayer
                            url={url}
                            playing={playing}
                            width={'100%'}
                            height={'100%'}
                            onProgress={onProgress}
                            onDuration={onDuration}
                            onEnded={onEnded}
                            progressInterval={PROGRESS_INTERVAL}
                            ref={playerRef}
                            muted={muted}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        onContextMenu: e => e.preventDefault(),
                                    },
                                },
                            }}
                        />
                        {showTime > 0 &&
                            mouseOverToolbar.current &&
                            sliderElem &&
                            sliderElem[0] &&
                            sliderElem[0].matches(':hover') && (
                                <Tooltip tooltipLeft={tooltipLeft}>
                                    {convertSecondsToTimeString(showTime, duration)}
                                </Tooltip>
                            )}
                        {!playing && !videoStarted.current && (
                            <ActionButtonsWrapper>
                                {primary && (
                                    <PlayIconWrapper contrast={theme.contrast}>
                                        <PlayIcon onClick={onPlayClick}>play_arrow</PlayIcon>
                                    </PlayIconWrapper>
                                )}
                            </ActionButtonsWrapper>
                        )}
                        {primary && (
                            <Toolbar
                                out={!showToolbar}
                                onMouseEnter={() => {
                                    mouseOverToolbar.current = true;
                                }}
                                onMouseLeave={() => {
                                    mouseOverToolbar.current = false;
                                }}
                            >
                                <PlayStopIconWrapper contrast={theme.contrast}>
                                    <PlayIcon onClick={onPlayClick}>
                                        {playing ? 'pause' : 'play_arrow'}
                                    </PlayIcon>
                                </PlayStopIconWrapper>
                                <ToolbarTracker>
                                    <MediaPlayerSlider
                                        ref={mouseOverToolbar}
                                        min={0}
                                        max={roundValue(duration)}
                                        step={0.1}
                                        value={playedSeconds}
                                        onChange={onSliderValueChanged}
                                        onMouseMove={onSliderValueChanged}
                                        onMouseLeave={() => setShowTime(null)}
                                        theme={theme}
                                        loadedSeconds={loadedSeconds.current}
                                    />
                                    <DurationWrapper largeWrapper={totalDuration.length > 5}>
                                        <Duration>{`${playedSecondsDuration} /`}</Duration>
                                        <Duration>&nbsp;{totalDuration}</Duration>
                                    </DurationWrapper>
                                </ToolbarTracker>
                            </Toolbar>
                        )}
                    </PlayerWrapper>
                </Wrapper>
            )}
        </ThemeContext.Consumer>
    );
};

export default MediaPlayer;
