import * as React from 'react';
import styled from 'styled-components';
import { Avatar, FontIcon } from 'react-md';

import styles from './styles';
import * as palette from '../../General/Variables';
import { useTheme } from '../../Theme/ThemeContext';
import { getString } from '../../../services/api/store';
import { messageBubbleStyleType } from '../constants';

const CustomAvatar = styled(Avatar)`
    border: unset;
    cursor: pointer;
    background: #bfbfbf !important;

    & .md-avatar {
        border: none;
    }

    &.md-avatar--default {
        ${props => props.noborder && 'background: transparent'};
    }

    @media only screen and (max-width: ${palette.MAX_TABLET}) {
        margin-top: -3px;
    }
`;

const FailedContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: ${palette.COLOR_WHITE} !important;
`;

const FailedIcon = styled(FontIcon)`
    margin-right: 3px;
    opacity: 0.8;
    color: inherit !important;
    font-size: 12px !important;
`;

const FailedText = styled.span`
    color: inherit !important;
    font-size: 11px;
    font-style: italic;
    opacity: 0.8;
    text-align: left;
    text-transform: uppercase;
`;

const defaultBubbleStyles = {
    userBubble: {},
    chatbubble: {},
    text: {},
};
const { userBubble, chatbubble, text } = defaultBubbleStyles;
const userBubbleStyle = {
    ...styles.chatbubble,
    ...styles.chatbubbleOrientationNormal,
    ...chatbubble,
    ...userBubble,
};
const friendBubbleStyle = {
    ...styles.chatbubble,
    ...styles.recipientChatbubble,
    ...styles.recipientChatbubbleOrientationNormal,
    ...chatbubble,
    ...userBubble,
    marginLeft: '48px',
};
const friendBubbleStylePositionTop = {
    borderRadius: '20px 20px 20px 8px',
};
const friendBubbleStylePositionMiddle = {
    borderRadius: '8px 20px 20px 8px',
};
const friendBubbleStylePositionBottom = {
    borderRadius: '8px 20px 20px 20px',
};
const myBubbleStylePositionTop = {
    borderRadius: '20px 20px 8px 20px',
};
const myBubbleStylePositionMiddle = {
    borderRadius: '20px 8px 8px 20px',
};
const myBubbleStylePositionBottom = {
    borderRadius: '20px 8px 20px 20px',
};

const systemBubbleStyle = {
    ...styles.systemBubble,
};

const messageDefaultStyle = {
    color: palette.COLOR_WHITE,
    fontSize: 15,
    fontWeight: '400',
    margin: 0,
    overflowWrap: 'break-word',
};

const defaultTimeStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: 11,
    marginTop: '4px',
    marginBottom: '0px',
    textAlign: 'left',
};

const userTimeStyle = {
    ...defaultTimeStyle,
    color: '#ffffff',
};

const representativeStyle = {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: 11,
    marginTop: '0',
    marginBottom: '0',
    textAlign: 'left',
    textTransform: 'uppercase',
    lineHeight: '16px',
};

const userAndTimeWrapperStyle = { display: 'flex' };
const userAndTimeSpaceStyle = { width: '20px' };

const getAdditionalBubblePositionStyles = message => {
    const myMessage = message.id === 0;
    switch (message.bubbleStyle) {
        case messageBubbleStyleType.TOP:
            return myMessage ? myBubbleStylePositionTop : friendBubbleStylePositionTop;
        case messageBubbleStyleType.MIDDLE:
            return myMessage ? myBubbleStylePositionMiddle : friendBubbleStylePositionMiddle;
        case messageBubbleStyleType.BOTTOM:
            return myMessage ? myBubbleStylePositionBottom : friendBubbleStylePositionBottom;
        default:
            return {};
    }
};

const ChatBubble = ({ message, showAvatar, avatarClick, showSenderName }) => {
    const getChatBubbleStyles = () => {
        const baseStyles =
            message.id === 0
                ? {
                      ...userBubbleStyle,
                      backgroundColor: theme.primary,
                  }
                : friendBubbleStyle;
        const additionalBubblePositionStyles = getAdditionalBubblePositionStyles(message);

        return {
            ...baseStyles,
            ...additionalBubblePositionStyles,
        };
    };

    const failedErrorMessage =
        getString('chatMessageFailedDelivery') || 'The following message couldn’t be sent:';
    const { theme } = useTheme();
    // message.id 0 is reserved for blue
    const chatBubbleStyles = getChatBubbleStyles();
    let messageStyle =
        message.id === 0
            ? messageDefaultStyle
            : {
                  ...messageDefaultStyle,
                  color: palette.COLOR_TEXT,
              };
    if (message.big) {
        messageStyle = { ...messageStyle, fontSize: 32 };
    }
    const messageTimeStyle = message.id === 0 ? userTimeStyle : defaultTimeStyle;
    const userNameStyle = { ...messageTimeStyle, marginRight: 4 };
    const { user } = message;
    const displaySenderName = message.id !== 0 && user && user.displayName;
    const messageBody = () => (
        <>
            {message.big && <p />}
            {message.failed && (
                <FailedContainer>
                    <FailedIcon>{'block'}</FailedIcon>
                    <FailedText>{failedErrorMessage}</FailedText>
                </FailedContainer>
            )}
            <p style={messageStyle}>{message.body}</p>
            {message.big && <p />}
            {/*
                Friends messages are shown with their names below message body
                so we have to wrap name and time in a extra div
            */}
            {displaySenderName && (
                <>
                    <div style={userAndTimeWrapperStyle}>
                        <p style={userNameStyle}>{user.displayName} </p>
                        <p style={messageTimeStyle}> {message.createdAt}</p>
                    </div>
                    {message.representative && (
                        <p style={representativeStyle}>{message.representative}</p>
                    )}
                </>
            )}
            {/* otherwise just display time (right aligned) for blue bubbles */}
            {!displaySenderName && <p style={messageTimeStyle}> {message.createdAt}</p>}
        </>
    );

    const renderMessage = hasAvatar => {
        // System messages are not displayed in bubbles
        if (message.system) {
            return (
                <div style={systemBubbleStyle}>
                    <p>{message.body}</p>
                </div>
            );
        }

        if (hasAvatar) {
            return (
                <div style={{ display: 'flex' }}>
                    {user.imageUrl ? (
                        <CustomAvatar src={user.imageUrl} onClick={avatarClick} noborder={1} />
                    ) : (
                        <CustomAvatar color={user.color} onClick={avatarClick}>
                            {user.initials}
                        </CustomAvatar>
                    )}
                    <div style={{ ...chatBubbleStyles, marginTop: 0, marginLeft: '8px' }}>
                        {messageBody()}
                    </div>
                </div>
            );
        } else {
            return <div style={chatBubbleStyles}>{messageBody()}</div>;
        }
    };

    return <div style={{ ...styles.chatbubbleWrapper }}>{renderMessage(showAvatar)}</div>;
};

export default ChatBubble;
